import * as _yup from "yup";

import validations from "app/shared/validations";
import { IUser } from "./types";

export const USER_REQUIRED_FIELDS = _yup.object<IUser>({
    name: _yup.string().required('Adınızı boş bırakamazsınız'),
    surname: _yup.string().required('Soyadınızı boş bırakamazsınız'),
    email: validations.email,
    user_type: _yup.number().required('Kullanıcı tipi seçmelisiniz'),
});


import { IQueryBuilder, QueryBuilderEntity } from "./types";

/**
 * Query builder convert to model schema
 */
export function mapQueryBuilderFromEntity(queryBuilderEntity: QueryBuilderEntity): IQueryBuilder {
    return {
        id: queryBuilderEntity.id,
        contentType: queryBuilderEntity.contentTypeId,
        showcase: queryBuilderEntity.showcaseId,
        whereCriteria: queryBuilderEntity.whereCriteria,
        pageIndex: queryBuilderEntity.pageIndex,
        pageSize: queryBuilderEntity.pageSize,
        mappingFields: queryBuilderEntity.mappingFields,
        orderFields: queryBuilderEntity.orderBy,
        createdBy: queryBuilderEntity.createdBy,
        updatedBy: queryBuilderEntity.modifiedBy,
        createdAt: queryBuilderEntity.createdOn,
        updatedAt: queryBuilderEntity.modifiedOn,
        status: queryBuilderEntity.status
    }
}

/**
 * Query builder convert to entity schema
 */
export function mapQueryBuilderFromModel(queryBuilderModel: IQueryBuilder): QueryBuilderEntity {
    return {
        id: queryBuilderModel?.id || "",
        contentTypeId: queryBuilderModel.contentType,
        showcaseId: queryBuilderModel.showcase || null,
        pageIndex: queryBuilderModel.pageIndex,
        pageSize: queryBuilderModel.pageSize,
        mappingFields: queryBuilderModel.mappingFields,
        whereCriteria: queryBuilderModel.whereCriteria,
        orderBy: queryBuilderModel.orderFields,
        createdBy: queryBuilderModel.createdBy,
        modifiedBy: queryBuilderModel.updatedBy,
        createdOn: queryBuilderModel.createdAt,
        modifiedOn: queryBuilderModel.updatedAt,
        status: queryBuilderModel.status
    }
}
import axios, { AxiosError } from "axios";
import cookie from "react-cookies";

import { getIdentityEndpoint } from "app/utils/service";
import { store } from "app/redux";
import { signOut } from "app/services/identity/function";
import { encodedParams } from "app/shared/functions";

export function createAxiosResponseInterceptor(error: AxiosError) {
    const url = getIdentityEndpoint("connect/token");
    const accessToken = cookie.load('accessToken');

    return new Promise((resolve) => {
        if (error.response && error.response?.status === 401) {
            if (accessToken !== store.getState().system.accessToken) {
                // onLogout();
                return resolve(false);
            }
            else {
                const refreshToken = cookie.load('refreshToken');

                if (refreshToken) {
                    return axios.post(url, encodedParams({
                        "client_id": "android",
                        "client_secret": "android",
                        "grant_type": "refresh_token",
                        "refresh_token": refreshToken
                    }), {
                        headers: {
                            'Accept': '*/*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                        .then((response) => {
                            if (response.data?.access_token) {
                                cookie.save('accessToken', response.data.access_token || "", { path: '/' })
                                cookie.save('refreshToken', response.data.refresh_token || "", { path: '/' })

                                resolve(response.data.access_token)
                            }
                            else {
                                signOut();
                            }
                        })
                        .catch(err => {
                            signOut();
                        });
                }
                else {
                    signOut();
                }
            }
        }
        resolve(false);
        return error.response;
    })

}

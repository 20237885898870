import * as React from 'react';
import { Provider } from "react-redux";
import { initializeIcons } from '@uifabric/icons';

import { store } from 'app/redux';

import 'assets/scss/main.scss';
import 'assets/css/icomoon/style.css';
import 'assets/css/bootstrap.min.css';
import 'assets/css/font-awesome.min.css';

import Router from 'containers/Router';

initializeIcons();

export default class App extends React.Component {

    onUnload = (e: Event) => {
        return e.returnValue;
    };

    componentDidMount() {
        window.addEventListener('beforeunload', this.onUnload);
    }

    render() {
        return (
            <Provider store={store}>
                <Router />
            </Provider>
        )
    }
}


import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { TextField, Dropdown, PrimaryButton } from "office-ui-fabric-react";
import { IListState, IList, IListFilter, StatusEnum } from "app/shared";
import moment from "moment";
import { deletePost, getExcel, getPosts, IPost } from "app/services/post";
import { getContentType, IContentType } from "app/services/content-type";
import {
  deleteRecovery,
  getRecoveries,
  updateRecovery,
} from "app/services/recovery";
import { RootState } from "app/redux/reducer";
import { toggleConfirmDialog } from "app/redux/system/actions";
import { castThunkAction } from "app/utils/casting";
import BasicTable from "components/customs/BasicTable";
import {
  createContentTypeCategoriesLink,
  createContentTypePostEditLink,
  createContentTypePostNewLink,
} from "app/utils/linking";
import PageTitle from "components/customs/PageTitle";
import CustomDayPickerInput from "components/customs/DayPickerInput";
import PageLayout from "containers/PageLayout";
import { getFilePath } from "app/utils/service";

import ImagePlaceholder from "assets/images/placeholder.png";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function mapStateToProps(
  state: RootState,
  ownProps: RouteComponentProps<{ contentTypeId: string }>
) {
  return {
    ...ownProps,
    contentTypeId: ownProps.match.params.contentTypeId,
    language: state.system.language,
    pageData: state.page.pageData as IList<IPost[]>,
    contentType: state.contentType.contentType,
    defaultLanguage: state.system.defaultLanguage,
  };
}

const handleExcelDownload = (data: any) => {
  let tempt = data;

  if (tempt) {
    tempt.map((item: any, index: number) => {
      if (typeof item.metaFields.firmaFaaliyetAlanlari === "object") {
        let x = JSON.stringify(item.metaFields.firmaFaaliyetAlanlari);
        let y = x.replace(/[\[\]"]+/g, "");
        tempt[index].metaFields.firmaFaaliyetAlanlari = y;
      }
    });
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const headers = [
    "İçerik Adı",
    "Ülke",
    "Web Sitesi",
    "Firma Faaliyet Alanları",
    "Firma Adresi",
    "Stant No",
    "Salon No",
    "Firma Telefonu",
  ];
  const formattedData: any = [];
  formattedData.push(headers); // Push headers as the first row

  tempt.forEach((item: any) => {
    const rowData = [item.title];
    Object.values(item.metaFields).forEach((value) => {
      // Check if value is a string and starts with "="
      if (typeof value === "string" && value.startsWith("=")) {
        // Remove the "=" character from the beginning
        rowData.push(value.slice(1));
      } else {
        rowData.push(value);
      }
    });
    formattedData.push(rowData);
  });

  const ws = XLSX.utils.aoa_to_sheet(formattedData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Exhibitor Content List");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const excelData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(excelData, `exhibitor_content_list-${moment().format('DD.MM.YYYY-HH:mm')}` + fileExtension);
};

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getPosts,
      deletePost,
      toggleConfirmDialog,
      getContentType,
      getRecoveries,
      getExcel,
      updateRecovery,
      deleteRecovery,
    },
    dispatch
  );
}

type IListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class ListPost extends Component<IListProps, IListState> {
  state = {
    type: "post",
    filterObject: {
      title: "",
      status: 1,
      isPrivate: undefined,
      language: this.props.defaultLanguage,
    },
    pagerObject: {
      page: 1,
      descending: false,
      pageSize: localStorage.getItem("pageSize")
        ? parseInt(localStorage.getItem("pageSize") as string)
        : 10,
    },
    activeStatus: StatusEnum.Published,
  };

  onDownloadToExcel = async () => {
    const data: Promise<IPost[]> = this.props.getExcel({
      page: 1,
      descending: false,
      pageSize: 9999,
      status:1,
      language: this.props.defaultLanguage,
      contentTypeId: "62b5a3a6f071c7cce66c8db1",
    }) as any;
    data
      .then((response) => {
        handleExcelDownload(response);
        // if (response && response.length > 0) {
        //   const columns = FileXLSX.getColumnsForData(response);
        //   FileXLSX.download(
        //     `Vize-sorgulama-log-raporu-${moment().format('DD.MM.YYYY-HH:mm')}`,
        //     response,
        //     columns
        //   );
        // }
      })
      .then(() => this.searchList());
  };

  UNSAFE_componentWillMount() {
    this.makeRequest({ ...this.state.pagerObject, ...this.state.filterObject });
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onEnterSearch, true);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onEnterSearch, true);
  }

  onEnterSearch = (e: any) => {
    if (e.key === "Enter") {
      this.searchList();
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: IListProps) {
    if (
      nextProps.contentTypeId &&
      nextProps.contentTypeId !== this.props.contentTypeId
    ) {
      this.setState(
        {
          filterObject: {
            title: "",
            status: 1,
            createdOn: "",
            language: this.props.defaultLanguage,
          },
          pagerObject: {
            page: 1,
            descending: false,
            pageSize: localStorage.getItem("pageSize")
              ? parseInt(localStorage.getItem("pageSize") as string)
              : 10,
          },
        },
        () =>
          this.makeRequest(
            { ...this.state.pagerObject, ...this.state.filterObject },
            nextProps.contentTypeId
          )
      );
    }
  }

  makeRequest(options: IListFilter, contentTypeId?: string) {
    if (this.state.filterObject.status === StatusEnum.Deleted) {
      castThunkAction(this.props.getRecoveries(options, this.state.type)).then(
        () => {
          this.setState({
            activeStatus: StatusEnum.Deleted,
          });
        }
      );
    } else {
      castThunkAction<IContentType>(
        this.props.getContentType(
          contentTypeId || this.props.contentTypeId,
          true
        )
      ).then(() => {
        castThunkAction(
          this.props.getPosts(
            options,
            contentTypeId || this.props.contentTypeId
          )
        ).then(() => {
          this.setState({
            activeStatus: this.state.filterObject.status,
          });
        });
      });
    }
  }

  searchList() {
    this.setState(
      {
        pagerObject: {
          page: 1,
          descending: false,
          pageSize: localStorage.getItem("pageSize")
            ? parseInt(localStorage.getItem("pageSize") as string)
            : 10,
        },
      },
      () => {
        this.makeRequest({
          ...this.state.pagerObject,
          ...this.state.filterObject,
        });
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-2"
          label="Başlık İle Arama"
          value={this.state.filterObject?.title || ""}
          onChange={(event: any) => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                title: event.target.value,
              },
            });
          }}
        />
        <Dropdown
          options={[
            { key: "all", text: "Tümü" },
            { key: "false", text: "Gösterilenler" },
            { key: "true", text: "Gizlenilenler" },
          ]}
          className="custom-dropdown col-md-2"
          label="Gösterim Durumuna Göre Arama"
          placeholder="Seçiniz"
          selectedKey={
            typeof this.state.filterObject?.isPrivate === "undefined"
              ? "all"
              : this.state.filterObject.isPrivate
          }
          onChange={(event: any, option: any) => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                isPrivate: option?.key === "all" ? undefined : option?.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: StatusEnum.Draft, text: "Taslak" },
            { key: StatusEnum.Published, text: "Yayında" },
            { key: StatusEnum.Deleted, text: "Silinmiş" },
          ]}
          className="custom-dropdown col-md-2"
          label="İçerik Durumuna Göre Arama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterObject?.status}
          onChange={(event: any, option: any) => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <CustomDayPickerInput
          onChange={(createdOn: string) =>
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                createdOn,
              },
            })
          }
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => this.searchList()}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "37px",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        totalCount={this.props.pageData.totalCount}
        columns={[
          {
            columnName: "title",
            text: "İçerik Adı",
            col: "col-md-9",
            onRender: (item) => {
              return (
                <Link
                  to={createContentTypePostEditLink(
                    this.props.contentTypeId,
                    item.url as string
                  )}
                >
                  {item.title}
                </Link>
              );
            },
          },
          {
            columnName: "profileImage",
            text: "İçerik Görseli",
            col: "col-md-1",
            onRender: (item: IPost) => {
              return (
                <img
                  className="fluid-image"
                  src={`${
                    item.thumbnail?.src
                      ? getFilePath() + item.thumbnail?.src
                      : ImagePlaceholder
                  }`}
                  alt="Öne Çıkan Görsel"
                  style={{
                    height: 27.5,
                    objectFit: "cover",
                    marginBottom: -10,
                  }}
                />
              );
            },
          },
          {
            columnName: "ddate",
            text: "İçerik Düzenleme Tarihi",
            col: "col-md-2",
            onRender: (item) => {
              return (
                <React.Fragment>
                  <span className="status">
                    {item?.status === 2
                      ? "Taslak"
                      : item?.status === 1
                      ? "Yayımlanmış"
                      : "Silinmiş"}
                  </span>
                  <span className="date">
                    {moment(item?.updateAt || item?.createdAt).format(
                      "DD.MM.YYYY HH:mm"
                    )}
                  </span>
                </React.Fragment>
              );
            },
          },
        ]}
        items={this.props.pageData.list}
        renderOperations={(item: IPost) =>
          this.state.activeStatus !== StatusEnum.Deleted ? (
            <>
              <Link
                to={createContentTypePostEditLink(
                  this.props.contentTypeId,
                  item.url as string
                )}
              >
                <i className="icon-edit-outline"></i> Düzenle
              </Link>
              <button
                onClick={() => {
                  this.props.toggleConfirmDialog(true, {
                    callback: () => {
                      castThunkAction(
                        this.props.deletePost(item.id as string)
                      ).then((res) => {
                        if (res) {
                          this.makeRequest({
                            ...this.state.pagerObject,
                            ...this.state.filterObject,
                          });
                        }
                      });
                    },
                  });
                }}
              >
                <i className="icon-trash-outline"></i> Sil
              </button>
            </>
          ) : (
            <>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => {
                  castThunkAction(
                    this.props.updateRecovery(
                      this.state.type,
                      item.id as string
                    )
                  ).then((res) => {
                    if (res) {
                      this.searchList();
                    }
                  });
                }}
              >
                <i className="icon-trash-outline"></i> Kurtar
              </a>
              <button
                onClick={() => {
                  this.props.toggleConfirmDialog(true, {
                    callback: () => {
                      castThunkAction(
                        this.props.deleteRecovery(item.id as string)
                      ).then((res) => {
                        if (res) {
                          this.searchList();
                        }
                      });
                    },
                  });
                }}
              >
                <i className="icon-trash-outline"></i> Kalıcı Olarak Sil
              </button>
            </>
          )
        }
        showPager={true}
        pageCount={this.props.pageData.pageCount}
        onChangePageSize={(pageSize) => {
          this.setState(
            { pagerObject: { ...this.state.pagerObject, pageSize } },
            () => {
              this.makeRequest({
                ...this.state.pagerObject,
                ...this.state.filterObject,
                pageSize,
              });
              localStorage.setItem("pageSize", pageSize.toString());
            }
          );
        }}
        onChangePager={(pageNumber) => {
          this.setState(
            { pagerObject: { ...this.state.pagerObject, page: pageNumber } },
            () =>
              this.makeRequest({
                ...this.state.pagerObject,
                ...this.state.filterObject,
                page: pageNumber,
              })
          );
        }}
        pageIndex={this.state.pagerObject.page - 1}
        showExcel={this.props.contentTypeId === "62b5a3a6f071c7cce66c8db1"?true:false }
        onDownload={() => this.onDownloadToExcel()}
      />
    );
  }

  render() {
    return (
      <PageLayout
        history={this.props.history}
        match={this.props.match}
        location={this.props.location}
      >
        <div className="page push-all">
          <PageTitle
            title="İçerik Yönetimi"
            subTitle="İlgili içerik tipine ait tüm içerikleri aşağıdan görüntüleyebilir, düzenleme yapabilir veya yeni bir içerik ekleyebilirsiniz."
            titleRenderer={() => (
              <span className="edit-title">
                İçerik Yönetimi:
                <strong>{this.props.contentType?.title || "-"}</strong>
              </span>
            )}
            backUrl="/"
            showBack
            history={this.props.history}
          />
          <div className="absolute-create absolute-wrapper row">
            <PrimaryButton
              onClick={() => {
                this.props.history.push(
                  createContentTypeCategoriesLink(
                    this.props.contentTypeId,
                    true
                  )
                );
              }}
              iconProps={{ iconName: "AppIconDefaultList" }}
              text="Kategori Yönetimi"
              styles={{
                root: {
                  background: "rgb(45 136 146)",
                  borderColor: "rgb(45 136 146)",
                  color: "#fff",
                },
                rootHovered: {
                  background: "rgb(56 157 168)",
                  borderColor: "rgb(56 157 168)",
                },
                rootPressed: {
                  background: "rgb(56 157 168)",
                  borderColor: "rgb(56 157 168)",
                },
                rootDisabled: {
                  background: "#ececec",
                },
              }}
            />
            <PrimaryButton
              onClick={() =>
                this.props.history.push(
                  createContentTypePostNewLink(this.props.contentTypeId)
                )
              }
              iconProps={{ iconName: "Add" }}
              text="Yeni İçerik Ekle"
              styles={{
                root: {
                  background: "#62a98e",
                  borderColor: "#5b9e85",
                  color: "#fff",
                },
                rootHovered: {
                  background: "#5b9e85",
                  borderColor: "#5b9e85",
                },
                rootDisabled: {
                  background: "#ececec",
                },
              }}
            />
          </div>
          {this.renderFilter()}
          {this.renderList()}
        </div>
      </PageLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPost);

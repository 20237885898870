import ComponentDescription from "components/customs/ComponentDescription";
import ComponentHeader from "components/cms/ComponentHeader";
import { HrDefinitions } from "./HrDefinitions";

export class Hr extends HrDefinitions {
    render() {
        const { className, designing } = this.props;

        return (
            <div
                data-schema-id={this.props.id}
                className={["fa-Hr fa-draggable", designing && "designing", className]
                    .filter((e) => !!e)
                    .join(" ")}
            >
                {designing && (
                    <ComponentHeader
                        componentEntry={this.props.entry}
                        componentSchemaId={this.props.id as string}
                    />
                )}
                <ComponentDescription
                    node={this.props.schemaNode}
                    entry={this.props.entry}
                />
            </div>
        );
    }
}

import { DefaultButton } from 'office-ui-fabric-react';
import { Component } from 'react';
import _ from "lodash";
import { ReactSortable } from "react-sortablejs";
import { MetaGalleryModal } from "../../Modals/MetaGalleryModal";
import { BaseMetaFieldsProps } from './types';
import { IMetaGallery } from 'app/services/content-type';
import { validateUrl } from 'app/shared/functions';
import { getFilePath } from 'app/utils/service';

import ImagePlaceholder from "assets/images/placeholder.png";

interface IMetaMultiState {
    modalIndex: number;
}

export default class MetaPhotoGallery extends Component<BaseMetaFieldsProps<IMetaGallery[]>, IMetaMultiState> {

    state = {
        modalIndex: -1
    }

    renderModal(metaData: IMetaGallery, index: number) {
        return (
            <MetaGalleryModal
                show={this.state.modalIndex === index ? true : false}
                onDismiss={() => {
                    this.setState({
                        modalIndex: -1
                    })
                }}
                onSave={(data) => {
                    const clonedData = _.cloneDeep(this.props.meta.data) || [];
                    clonedData[index] = data as IMetaGallery;
                    this.props.onChange(clonedData);
                }}
                data={metaData}
            />
        )
    }

    renderFileList() {
        return (
            <div className="file-list multi row">
                {this.props.meta.data && this.props.meta.data.length > 0 ? (
                    <ReactSortable
                        list={(this.props.meta.data || []).map((item, index) => ({ ...item, id: index })) || []}
                        setList={(newState) => this.props.onChange(newState)}
                        animation={200}
                        className="row"
                        style={{ width: "100%" }}
                    >
                        {this.props.meta.data.map((item, index) => {
                            const splitted = item.src?.split(".");
                            const imageSource = !validateUrl(item.thumbnail) ?
                                getFilePath() + item.thumbnail +
                                (!splitted || splitted[splitted?.length - 1] !== "svg" ? "?width=200&height=133" : "")
                                : item.thumbnail;
                            return (
                                <div key={index} className="file-item col-md-2">
                                    {this.renderModal(item, index)}
                                    <div className="file-item-wrapper">
                                        <img onClick={() => {
                                            this.setState({
                                                modalIndex: index
                                            })
                                        }}
                                            src={item.src ? imageSource : ImagePlaceholder}
                                            alt={item.alt}
                                        />
                                        <span
                                            className="edit"
                                            onClick={() => {
                                                this.setState({
                                                    modalIndex: index
                                                })
                                            }}
                                        >
                                            <i className="icon-edit"></i>
                                        </span>
                                        <div onClick={() => {
                                            const list = _.cloneDeep(this.props.meta.data || []);
                                            list.splice(index, 1);
                                            this.props.onChange(list);
                                        }}
                                            className="remove"
                                        >
                                            <i className="icon-delete"></i>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </ReactSortable>
                ) : (
                    <div className="not-found row">
                        <i className="icon-info"></i>
                        Herhangi bir eklenmiş galeri öğesi bulunamadı.
                    </div>
                )}
            </div>
        )
    }

    render() {
        return (
            <div className="meta-file">
                <DefaultButton
                    iconProps={{ iconName: "Add" }}
                    text="Yeni Galeri Öğesi Ekle"
                    styles={{
                        root: {
                            fontSize: "10.5px !important",
                            background: "#fff"
                        },
                        icon: {
                            fontSize: 12,
                            fontWeight: 500
                        }
                    }}
                    onClick={() => {
                        const clonedData = _.cloneDeep(this.props.meta.data) || [];
                        clonedData.push({
                            alt: "",
                            src: "",
                            subcaption: "",
                            thumbnail: "",
                            caption: "",
                            external: false
                        })
                        this.props.onChange(clonedData);
                    }}
                />
                {this.renderFileList()}
            </div>
        )
    }
}

import { LayoutScreenDesignerDefinitions } from './LayoutScreenDefinitions';
import LayoutScreenDesigner from './LayoutScreenDesigner';

export class LayoutScreen extends LayoutScreenDesignerDefinitions {
    render() {
        return (
            <div
                data-schema-id={this.props.id}
                className={`fa-draggable fa-Scene row ${this.props.designing ? "designing" : ""} is-default`}
                style={{
                    minHeight: this.props.designing ? "300px" : "auto",
                    position: "relative",
                }}
            >
                <LayoutScreenDesigner {...this.props} />
            </div>
        );
    }
}

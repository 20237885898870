import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "app/redux/reducer";
import { Dispatch, bindActionCreators } from "redux";
import { History } from "history";
import { setModalData, toggleModal } from "app/redux/modal/actions";
import {
    ComboBox,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDropdownOption,
    PrimaryButton,
    TextField,
} from "office-ui-fabric-react";
import AbsoluteLoader from '../Loader/AbsoluteLoader';
import slugify from 'slugify';
import FileManager from '../FileManager/FileManager';
import { castThunkAction } from 'app/utils/casting';
import { createCategory, getCategories, mapCategoryForList, updateCategory } from "app/services/category";
import { FileTypes } from "app/services/file-manager";
import { getContentType } from "app/services/content-type";
import { getFilePath } from "app/utils/service";

interface OwnProps {
    history: History<any>;
}

function mapStateToProps(state: RootState) {
    return {
        ...state.modal.category,
        language: state.system.language,
        modalLoading: state.system.modalLoading,
        contentType: state.contentType.contentType,
        categories: state.category.categories
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators(
            {
                setModalData,
                toggleModal,
                createCategory,
                updateCategory,
                getContentType,
                getCategories
            },
            dispatch
        ),
    };
}

type CategoryModalProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    OwnProps;

interface CategoryModalState {
    showModal: boolean;
}

class CategoryModal extends Component<CategoryModalProps, CategoryModalState> {

    state = {
        showModal: false
    }

    UNSAFE_componentWillReceiveProps(nextProps: CategoryModalProps) {
        if (this.props.show !== nextProps.show && nextProps.show) {
            if (nextProps.contentType?.id) {
                this.props.getCategories(nextProps.contentType.id as string, true)
            }
        }
    }

    onClose() {
        if (this.props.callback) {
            this.props.callback()
        }
        this.props.toggleModal("category", {});
    }

    onSave() {
        castThunkAction(this.props.data.id ? this.props.updateCategory(this.props.data) : this.props.createCategory(this.props.data)).then(res => {
            if (res) {
                this.props.getCategories(this.props.data.contentType);
                this.onClose();
            }
        })
    }

    onChangeData(key: string, value: any) {
        this.props.setModalData("category", {
            ...this.props.data,
            [key]: value
        })
    }

    getCategoryOptions(): IDropdownOption[] {
        let options: IDropdownOption[] = [{ key: "", text: "Üst Kategori Yok" }];
        const categories = mapCategoryForList(this.props.categories?.list || []);
        options = [...options, ...categories.map(item => ({ key: item.id as string, text: item.title }))].map(item => {
            if (item.key === this.props.data.id) {
                return {
                    ...item,
                    disabled: true
                }
            }
            return item;
        })
        return options;
    }

    renderFileManager() {
        return (
            <FileManager
                show={this.state.showModal}
                onDismiss={() => this.setState({ showModal: false })}
                onSelectedFile={(file) => {
                    this.onChangeData("profileImage", { src: getFilePath() + file.fileName, alt: file.fileName })
                }}
                fileType={FileTypes.Image}
            />
        )
    }

    // renderImage() {
    //     const image = this.props.data.profileImage;
    //     return (
    //         <div className="image-field row">
    //             {image && (
    //                 <img className="image-string-field" src={image?.src} alt="" />
    //             )}
    //             <TextField
    //                 className="custom-textfield col no-padding"
    //                 value={image?.src}
    //                 onChange={(event: any) => this.onChangeData("profileImage", { src: event.target.value, alt: event.target.value })}
    //             />
    //             <DefaultButton
    //                 className="custom-button"
    //                 text="Görüntüle"
    //                 style={{ marginTop: 2.5 }}
    //                 disabled={!image?.src}
    //                 onClick={() => {
    //                     window.open(image?.src, "_blank")
    //                 }}
    //             />
    //             <PrimaryButton
    //                 className="custom-button"
    //                 text="Görsel Seç"
    //                 style={{ marginTop: 2.5 }}
    //                 onClick={() => {
    //                     this.setState({ showModal: true })
    //                 }}
    //                 styles={{
    //                     root: {
    //                         background: "#33926e !important",
    //                         borderColor: "#33926e !important"
    //                     },
    //                     rootDisabled: {
    //                         opacity: 0.3,
    //                         background: "#ececec",
    //                         color: "#000"
    //                     }
    //                 }}
    //             />
    //         </div>
    //     );
    // }

    renderForm() {
        return (
            <div className="modal-form">
                <div className="row reverse-margin">
                    <div className="item col-md-12">
                        <TextField
                            placeholder="Kategori Adı"
                            value={this.props.data.title || ""}
                            label="Kategori Adı"
                            className="custom-textfield border"
                            onChange={(event: any) => this.props.setModalData("category", { ...this.props.data, title: event.target.value, slug: slugify(event.target.value, { lower: true, replacement: "-" }) })}
                        />
                    </div>
                    {/* <div className="item col-md-6">
                        <TextField
                            placeholder="Kategori Adresi (Slug)"
                            value={this.props.data.slug || ""}
                            label="Kategori Adresi (Slug)"
                            className="custom-textfield border"
                            onChange={(event: any) => this.onChangeData("slug", slugify(event.target.value, { lower: true, replacement: "-" }))}
                        />
                    </div> */}
                </div>
                <div className="item">
                    <ComboBox
                        label="Üst Kategori"
                        allowFreeform
                        autoComplete="on"
                        selectedKey={this.props.data.parent || ""}
                        className="custom-combobox border"
                        onChange={(_: any, option) => this.onChangeData("parent", option?.key as string)}
                        options={this.getCategoryOptions()}
                        calloutProps={{ className: "custom-combobox-callout" }}
                    />
                </div>
                <div className="item">
                    {/* <Label>Kategori Görseli</Label> */}
                    {/* {this.renderImage()} */}
                    {/* {this.renderFileManager()} */}
                </div>
                {/* <div className="item">
                    <TextField
                        placeholder="Kategori Açıklaması"
                        value={this.props.data.description || ""}
                        label="Kategori Açıklaması"
                        multiline
                        resizable={false}
                        className="custom-textfield border"
                        onChange={(event: any) => this.onChangeData("description", event.target.value)}
                    />
                </div> */}
            </div>
        )
    }

    render() {
        const { data, show, modalLoading } = this.props;

        if (!show) return null;

        return (
            <Dialog
                hidden={!show}
                onDismiss={() => this.onClose()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: data.id ? "Kategori Düzenle" : "Yeni Kategori Ekle",
                    closeButtonAriaLabel: "Kapat",
                    subText:
                        "Geçerli içerik tipine sahip içeriklerde kullanılmak üzere, belirlediğiniz kriterlerde bir kategori ekleyin ya da düzenleyin."
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: true,
                    styles: { main: { maxWidth: "1000px !important" } },
                }}
            >
                <AbsoluteLoader show={modalLoading} />
                {this.renderForm()}
                <DialogFooter>
                    <DefaultButton
                        onClick={() => this.onClose()}
                        text="İptal"
                    />
                    <PrimaryButton
                        onClick={() => this.onSave()}
                        text={data.id ? "Değişiklikleri Kaydet" : "Oluşturmayı Tamamla"}
                        disabled={(!data.title || !data.brandId || modalLoading) ? true : false}
                    />
                </DialogFooter>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);

export interface IFileManagerListPagerObject {
    page: number;
    pageSize: number;
    descending: boolean;
    fileName: string;
    fileExtensions: string;
    bucketName: string;
}

export interface FileEntity {
    mimeType: string,
    fileName: string,
    fileExtension: string,
    fileSize: string,
    bucket: string;
    uploadedBy: string,
    deletedOn: string,
    id: string,
    ownerID: string,
    modifiedOn: string;
}

export enum FileTypes {
    All = ".png,.jpg,.pdf,.svg,.jpeg,.xlsx,.mp4,.mp3,.vmw,.pdf,.docx,.txt",
    Image = ".png,.jpg,.jpeg,.svg,.webp",
    Video = ".mp4,.vmw,.ogg,.ogv,.webm",
    File = ".mp3,.docx,.txt,.pdf,.xlsx,.zip"
}

export interface IFile {
    id?: string;
    fileName: string;
    fileExtension?: string;
    fileSize?: string;
    createdBy?: string;
    updatedAt?: string;
}

export interface IFileManager {
    show: boolean;
    onDismiss: () => void;
    fileType?: FileTypes;
    onSelectedFile?: (file: IFile) => void;
    onSelectedFiles?: (files: IFile[]) => void;
    activeFile?: IFile;
    activeFiles?: IFile[];
    multipleSelection?: boolean;
    disableSelect?: boolean;
}

export interface IFileManagerState {
    uploadScreenVisibility: boolean;
    filePanelVisibility: boolean;
    loaderVisibility: boolean;
    fileList: IFile[];
    activeFiles: IFile[];
    filterObject: {
        pageSize: number;
        totalPage: number,
        pageIndex: number;
        fileExtensions: FileTypes;
        fileName: string;
    };
    buckets: string[];
    activeBucket: string;
    filePanelData: IFile;
}

export interface BucketEntity {
    name: string;
}
import * as React from "react";
import { BaseFieldProps } from "./types";
import { PureDropdownField } from "./Pure/PureDropdownField";
import { HttpStatusCode } from 'app/shared';
import { MenuService } from "app/services/menu";

export interface MenuFieldState {
    menuList: { key: string; text: string; }[];
}

export default class MenuField extends React.Component<BaseFieldProps, MenuFieldState> {

    state = {
        menuList: []
    }

    componentDidMount() {
        this.getMenus();
    }

    async getMenus() {
        const service = new MenuService();
        const response = await service.getMenus({ page: 1, pageSize: 200, descending: false });
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map((item) => {
                return { key: item.id, text: item.title }
            });
            this.setState({
                menuList: mapped
            })
        }

    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        return (
            <PureDropdownField
                label={this.props.property.name}
                value={this.props.value?.id}
                onChange={value => this.props.onChange({ id: value, type: "navigation", items: [] })}
                property={this.props.property}
                text={this.props.property.text as string}
                description={this.props.property.description as string}
                customOptions={this.state.menuList || []}
            />
        );
    }
}

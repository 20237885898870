import { Component } from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";


export interface AbsoluteLoaderProps {
    show: boolean;
}

export default class AbsoluteLoader extends Component<AbsoluteLoaderProps> {

    static defaultProps = {
        show: false,
        label: "",
        className: ""
    };

    render() {
        const { show } = this.props;
        return (
            <div
                className={[show && "active", "loader absolute accordion"].filter(e => !!e).join(" ")}>
                <Spinner
                    {...this.props}
                    size={SpinnerSize["large"]}
                />
            </div>
        );
    }
}


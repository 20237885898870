import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { History } from "history";
import {
  SchemaSourceOptions,
  SchemaSourceTypes,
  SchemaTree,
} from "app/library/layout-builder";
import { PostService } from "./service";
import { RootState } from "app/redux/reducer";
import {
  showMessage,
  toggleConfirmDialog,
  toggleLoader,
} from "app/redux/system/actions";
import { initialPageData } from "app/redux/page/actions";
import { MessageType } from "app/redux/system/types";
import { IMeta } from "../content-type";
import { mapPostFromEntity, mapPostFromModel } from "./mapping";
import { IPost, IPostt } from "./types";
import { HttpStatusCode, IList, IListFilter } from "app/shared";
import { createContentTypePostEditLink } from "app/utils/linking";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getPost(
  postId: string,
  defaultMetaFields: IMeta[],
  language?: string,
  notFill?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    dispatch(toggleLoader());
    const response = await service.getPost(
      postId,
      language || getState().system.defaultLanguage
    );
    if (response.status === HttpStatusCode.OK) {
      const mapped = mapPostFromEntity(response.data.attributes["post"] || {});

      if (!notFill) {
        if (defaultMetaFields && defaultMetaFields.length > 0) {
          defaultMetaFields.forEach((defaultMeta) => {
            const postMeta = (mapped.metaFields || []).find(
              (x) => x.name === defaultMeta.name
            );
            if (!postMeta && defaultMeta.default) {
              mapped.metaFields.push({
                ...defaultMeta,
                data: defaultMeta.default,
              });
            }
          });
        }
        dispatch(initialPageData<IPost>(mapped));
      }
      dispatch(toggleLoader());
      return true;
    }
    dispatch(toggleLoader());
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

export function getPostByLanguage(
  pageData: IPost,
  language: string,
  isOverride?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    dispatch(toggleLoader());
    const response = await service.getPost(
      pageData.url as string,
      isOverride ? getState().system.defaultLanguage : language
    );
    if (response.status === HttpStatusCode.OK) {
      const mapped = mapPostFromEntity(response.data.attributes["post"] || {});
      const data = {
        ...mapped,
        language,
        id: isOverride ? pageData.id : mapped.id,
        url: isOverride ? `${language}/${mapped.url}` : mapped.url,
      };
      dispatch(initialPageData<IPost>(data));
      if (isOverride) {
        dispatch(updatePost(data));
      }
      dispatch(toggleLoader());
      return true;
    } else {
      dispatch(
        toggleConfirmDialog(true, {
          title: "Uyarı!",
          text: `Geçerli dil'e (${language}) ait bir içerik bulunamadı. Varsayılan dil üzerinden seçtiğiniz dile göre içerik oluşturulsun mu?`,
          button: {
            approveText: "Varsayılan Dil'den Oluştur",
          },
          callback: async () => {
            if (language !== getState().system.defaultLanguage) {
              const mainResponse = await service.getPost(
                pageData.url as string,
                getState().system.defaultLanguage
              );
              if (mainResponse.status === HttpStatusCode.OK) {
                const mainMapped = mapPostFromEntity(
                  mainResponse.data.attributes["post"] || {}
                );
                dispatch(
                  createPost({
                    ...mainMapped,
                    id: "",
                    language,
                    url: `${language}/${mainMapped.url}`,
                  })
                ).then((res) => {
                  if (res) {
                    dispatch(getPost(res.url as string, [], language));
                  }
                });
              }
            }
            return true;
          },
        })
      );
      dispatch(toggleLoader());
      return false;
    }
  };
}

export function getPostSlug(slug: string): ThunkResult<Promise<string>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    const response = await service.getPostSlug(slug);
    if (response.status === HttpStatusCode.OK) {
      return response.data.attributes["url"];
    }
    return "";
  };
}

export function getPosts(
  options: IListFilter,
  contentTypeId: string
): ThunkResult<Promise<boolean>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    dispatch(toggleLoader());
    const response = await service.getPosts(options, contentTypeId);
    dispatch(toggleLoader());
    if (response.status === HttpStatusCode.OK) {
      const mapped = (response?.data?.attributes?.list || []).map((item) =>
        mapPostFromEntity(item)
      );
      dispatch(
        initialPageData<IList<IPost[]>>({
          list: mapped,
          pageSize: response.data.attributes.pageSize,
          totalCount: response.data.attributes.total,
          pageCount: Math.ceil(
            response.data.attributes.total / response.data.attributes.pageSize
          ),
        })
      );
      return true;
    }
    return false;
  };
}

export function getPostsById(
  postIds: string[]
): ThunkResult<Promise<IPost[] | null>> {
  return async (dispatch) => {
    const service = new PostService();
    dispatch(toggleLoader());
    const response = await service.getPostsById(postIds);
    dispatch(toggleLoader());
    if (response.status === HttpStatusCode.OK) {
      const mapped = (response?.data?.attributes?.list || []).map((item) =>
        mapPostFromEntity(item)
      );
      return mapped;
    }
    return null;
  };
}

export function getPostsForPanel(
  contentTypeId: string,
  language?: string
): ThunkResult<Promise<IPost[]>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    const response = await service.getPosts(
      {
        page: 1,
        pageSize: 100,
        descending: false,
        language: language || getState().system.defaultLanguage,
      },
      contentTypeId
    );
    if (response.status === HttpStatusCode.OK) {
      const mapped = (response?.data?.attributes?.list || []).map((item) =>
        mapPostFromEntity(item)
      );
      return mapped;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return [];
  };
}

export function createPost(
  request: IPost,
  fromClone?: boolean
): ThunkResult<Promise<IPost | null>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    if (!fromClone) {
      dispatch(toggleLoader());
    }
    const schemaTree = SchemaTree.parse(request.schema);
    const sources = getSources(schemaTree);
    const queryIds: string[] = (sources || [])
      .filter(
        (item, index) =>
          sources.indexOf(item) === index &&
          item.type === SchemaSourceTypes.LocalData
      )
      .map((x) => x.ref as string);
    const dynamicIds: string[] = (sources || [])
      .filter((item) => item.type === SchemaSourceTypes.Dynamics)
      .map((x) => x.ref as string);
    const dynamics = (request.dynamics || []).filter((item) =>
      dynamicIds.includes(item.id)
    );
    const response = await service.createPost(
      mapPostFromModel({ ...request, queryIds, dynamics })
    );
    if (!fromClone) {
      dispatch(toggleLoader());
    }
    if (response.status === HttpStatusCode.OK) {
      return mapPostFromEntity(response.data.attributes["post"] || {});
    }
    return null;
  };
}

export function duplicatePost(
  slug: string
): ThunkResult<Promise<IPost | null>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    dispatch(toggleLoader());
    const response = await service.duplicatePost(slug);
    dispatch(toggleLoader());
    if (response.status === HttpStatusCode.OK) {
      return mapPostFromEntity(response.data.attributes["post"] || {});
    }
    return null;
  };
}

export function updatePost(
  request: IPost,
  history?: History
): ThunkResult<Promise<boolean>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    const url = (getState().page.pageData as IPost)?.initalUrl;
    dispatch(toggleLoader());
    const schemaTree = SchemaTree.parse(request.schema);
    const sources = getSources(schemaTree);
    const queryIds: string[] = (sources || [])
      .filter(
        (item, index) =>
          sources.indexOf(item) === index &&
          item.type === SchemaSourceTypes.LocalData
      )
      .map((x) => x.ref as string);
    const dynamicIds: string[] = (sources || [])
      .filter((item) => item.type === SchemaSourceTypes.Dynamics)
      .map((x) => x.ref as string);
    const dynamics = (request.dynamics || []).filter((item) =>
      dynamicIds.includes(item.id)
    );
    const response = await service.updatePost(
      mapPostFromModel({ ...request, queryIds, dynamics })
    );
    dispatch(toggleLoader());
    if (response.status === HttpStatusCode.OK) {
      if (url !== request.url && history) {
        history.push(
          createContentTypePostEditLink(request.contentType, request.url)
        );
      }
      return true;
    }
    return false;
  };
}

export function deletePost(postId: string): ThunkResult<Promise<boolean>> {
  return async (dispatch, getState) => {
    const service = new PostService();
    dispatch(toggleLoader());
    const response = await service.deletePost(postId);
    dispatch(toggleLoader());
    if (response.status === HttpStatusCode.OK) {
      return true;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

function getSources(schemaTree: SchemaTree): SchemaSourceOptions[] {
  const sources: SchemaSourceOptions[] = [];
  (schemaTree.getDistinctNodes() || []).forEach((item) => {
    Object.keys(item.getSource()).forEach((source) => {
      const current = item.getSource()[source];
      if (
        current &&
        current.ref &&
        (current.type === SchemaSourceTypes.LocalData ||
          current.type === SchemaSourceTypes.Dynamics)
      ) {
        sources.push(current);
      }
    });
  });
  return sources;
}

export function getExcel(
  options: IListFilter
): ThunkResult<Promise<object[] | null>> {
  return async (dispatch) => {
    const service = new PostService();
    dispatch(toggleLoader());
    const response = (await service.getExcel(options)) as any;
    const list = response?.attributes?.list || [];
    dispatch(toggleLoader());
    const requiredFields = [
      "ulke",
      "web",
      "firmaFaaliyetAlanlari",
      "firmaAdres",
      "stantNo",
      "salonNo",
      "firmaTelefonu",
    ];
    const formattedData = list.map((item: any) => {
      const newObject = {
        title: item.title,
        metaFields: {},
      };
      requiredFields.forEach((field) => {
        const existingField = item.metaFields.find(
          (mf: any) => mf.name === field
        );
        if (existingField) {
          if (existingField.data && typeof existingField.data === "object") {
            newObject.metaFields[existingField.name] =
              existingField.data.length > 1
                ? existingField.data.map(
                    (item: any) => item.title || item.href || "-"
                  )
                : existingField.data?.title || existingField.data?.href || "-";
          } else {
            newObject.metaFields[existingField.name] =
              existingField.data || "-";
          }
        } else {
          newObject.metaFields[field] = "-";
        }
      });

      return newObject;
    });
    if (list.length > 0) {
      dispatch(
        initialPageData<IList<IPostt[]>>({
          list: formattedData,
          pageSize: response.attributes.pageSize,
          totalCount: response.attributes.total,
          pageCount: Math.ceil(
            response.attributes.total / response.attributes.pageSize
          ),
        })
      );
      return formattedData;
    }
    return null;
  };
}

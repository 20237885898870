import { Component } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { History } from "history";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
    TextField
} from "office-ui-fabric-react";
import AbsoluteLoader from '../Loader/AbsoluteLoader';
import slugify from "slugify";
import { RootState } from "app/redux/reducer";
import { setModalData, toggleModal } from "app/redux/modal/actions";

interface OwnProps {
    history: History<any>;
}

function mapStateToProps(state: RootState) {
    return {
        ...state.modal.bucket,
        language: state.system.language,
        modalLoading: state.system.modalLoading
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators(
            {
                setModalData,
                toggleModal
            },
            dispatch
        ),
    };
}

type BucketModalProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    OwnProps;

class BucketModal extends Component<BucketModalProps> {

    onClose(isCreated?: boolean) {
        if (isCreated && this.props.callback) {
            this.props.callback(this.props.data);
        }
        this.props.toggleModal("bucket", {});
    }

    onSave() {
        this.onClose(true);
    }

    onChangeData(key: string, value: any) {
        this.props.setModalData("bucket", {
            ...this.props.data,
            [key]: value
        })
    }

    renderForm() {
        return (
            <div className="modal-form">
                <div className="row reverse-margin">
                    <div className="item col-md-12">
                        <TextField
                            placeholder="Klasör Adı"
                            value={this.props.data.bucketName || ""}
                            label="Klasör Adı"
                            className="custom-textfield border"
                            onChange={(event: any) => this.onChangeData("bucketName", slugify(event.target.value, { replacement: "-", lower: true }))}
                        />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { data, show, modalLoading } = this.props;

        if (!show) return null;

        return (
            <Dialog
                hidden={!show}
                onDismiss={() => this.onClose()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Yeni Klasör Ekle",
                    closeButtonAriaLabel: "Kapat",
                    subText:
                        "Aşağıdaki bilgileri doldurarak belirlediğiniz kriterlerde bir klasör ekleyin ya da düzenleyin."
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: true,
                    styles: { main: { maxWidth: "400px !important", minWidth: "400px !important" } },
                }}
            >
                <AbsoluteLoader show={modalLoading} />
                {this.renderForm()}
                <DialogFooter>
                    <DefaultButton
                        onClick={() => this.onClose()}
                        text="İptal"
                    />
                    <PrimaryButton
                        onClick={() => this.onSave()}
                        text="Ekle"
                        disabled={(!data.bucketName || modalLoading) ? true : false}
                    />
                </DialogFooter>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketModal);

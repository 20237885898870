import {
    PropertyDefinition, EntryOptions
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";
import { CmsReactComponent, CmsReactComponentProps } from "../CmsReactComponent";
import { ImageChildrenDefinitions } from '../definitions';
import { ImageProps } from '../types';

export class ImageDefinitions extends CmsReactComponent<ImageProps & CmsReactComponentProps> {

    static schemaName = "image";

    static propertyDefinitions: PropertyDefinition[] = [
        ...ImageChildrenDefinitions
        // {
        //     name: "anchor",
        //     mapping: {
        //         type: PropertyMappingType.LinkWizard,
        //     }
        // }
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.SingleImage,
                // MetaTypeEnum.Link,
                MetaTypeEnum.Text
            ]
        }
    }
}

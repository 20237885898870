import { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from "app/redux/reducer";
import { Dispatch, bindActionCreators } from 'redux';
import PageTitle from 'components/customs/PageTitle';
import { TextField, MessageBar, MessageBarType, PrimaryButton, Toggle, DefaultButton } from 'office-ui-fabric-react';
import { RouteComponentProps } from 'react-router-dom';

import { setPageData, setPageErrors, initialPageData } from 'app/redux/page/actions';
import * as _yup from "yup";
import { castThunkAction } from 'app/utils/casting';
import { toggleConfirmDialog, setLayout } from 'app/redux/system/actions';
import moment from "moment"
import { deleteLayout, duplicateLayout, getLayout, getLayoutByLanguage, ILayout, updateLayout } from 'app/services/layout';
import FormAccordion from 'components/customs/FormAccordion';
import { Designer } from 'components/customs/Designer';
import Sidebar from 'components/customs/Sidebar/Sidebar';
import PageLayout from 'containers/PageLayout';
import { getConfiguration, IThemeSettings } from 'app/services/configuration';
import TranslatorPostBar from 'components/customs/TranslatorBar/TranslatorPostBar';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<{ layoutId: string }>) {
    return {
        ...ownProps,
        defaultLanguage: state.system.defaultLanguage,
        layoutId: ownProps.match.params.layoutId,
        language: state.system.language,
        pageData: state.page.pageData as ILayout,
        pageErrors: state.page.pageErrors,
        isLayout: state.system.isLayout
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            setPageData,
            setPageErrors,
            initialPageData,
            getLayout,
            updateLayout,
            deleteLayout,
            toggleConfirmDialog,
            setLayout,
            getLayoutByLanguage,
            getConfiguration,
            duplicateLayout
        }, dispatch)
    };
}

type EditLayoutProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface OwnState {
    messageType: MessageBarType;
    configurationData: IThemeSettings;
}

class EditLayout extends Component<EditLayoutProps, OwnState> {

    state = {
        messageType: MessageBarType.warning,
        configurationData: {} as IThemeSettings
    }

    UNSAFE_componentWillMount() {
        castThunkAction<IThemeSettings>(this.props.getConfiguration(true)).then(configuration => {
            if (configuration) {
                this.setState({
                    configurationData: configuration
                })
            }
            this.props.getLayout(this.props.layoutId);
        })
    }

    componentDidMount() {
        this.props.setLayout(true);
    }

    componentWillUnmount() {
        this.props.setLayout(false);
    }

    onChangeLanguage(language: string) {
        if (language !== this.props.pageData.language) {
            this.props.getLayoutByLanguage(this.props.pageData, language);
        }
    }

    onCloneLanguage() {
        this.props.getLayoutByLanguage(this.props.pageData, this.props.pageData.language, true)
    }


    onDuplicateLayout() {
        castThunkAction<ILayout>(this.props.duplicateLayout(this.props.pageData)).then(res => {
            if (res) {
                this.props.history.push(`/layout/edit/${res.id}`);
                this.props.initialPageData(res);
            }
        })
    }

    renderTextByMessageType() {
        switch (this.state.messageType) {
            case MessageBarType.success:
                return "Kayıt işlemi başarıyla gerçekleşmiştir."
            case MessageBarType.error:
                return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin."
            default:
                return "Aşağıdaki değerler üzerinden düzenleme yapabilirsiniz."
        }
    }

    renderMessageBar() {
        return (
            <MessageBar
                messageBarType={this.state.messageType}
                isMultiline={false}
            >
                {this.renderTextByMessageType()}
            </MessageBar>
        )
    }

    onDelete() {
        this.props.toggleConfirmDialog(true, {
            callback: () => {
                let layoutData = this.props.pageData;
                if (this.props.pageData.language !== this.props.defaultLanguage) {
                    castThunkAction<ILayout>(this.props.getLayout(this.props.pageData.id as string, this.props.defaultLanguage, true)).then(response => {
                        layoutData = response;
                        castThunkAction(this.props.deleteLayout(this.props.pageData.id as string)).then(res => {
                            if (res) {
                                this.props.initialPageData(layoutData)
                            }
                        });
                    });
                }
                else {
                    castThunkAction(this.props.deleteLayout(this.props.pageData.id as string)).then(res => {
                        if (res) {
                            this.props.history.push("/layouts")
                        }
                    })
                }

            }
        })
    }

    /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
    onSubmit(isDraft?: boolean) {
        const {
            pageData,
            setPageErrors
        } = this.props;
        const validationSchema = _yup.object({
            title: _yup.string().required(
                "Bu alan zorunludur!"
            )
        });
        let formErrors = {};
        validationSchema
            .validate(pageData, {
                abortEarly: false
            })
            .then(() => {
                setPageErrors({});
                castThunkAction(this.props.updateLayout(this.props.pageData)).then(res => {
                    window.scrollTo({ top: 0 });
                    this.setState({
                        messageType: res ? MessageBarType.success : MessageBarType.error
                    })
                })

            })
            .catch(err => {
                err.inner.forEach((e: any) => {
                    const { path, message } = e;
                    formErrors[path] = message;
                });
                setPageErrors(formErrors);
            });
    };

    renderTranslatorBar() {
        if (!this.state.configurationData?.config?.languages || this.state.configurationData?.config?.languages.length <= 1) return null;
        return (
            <TranslatorPostBar
                languages={this.state.configurationData?.config?.languages?.map(item => item.code?.split("-")[0])}
                onChange={(language) => this.onChangeLanguage(language)}
                toggleDialog={this.props.toggleConfirmDialog}
                current={this.props.pageData.language}
                onClone={() => this.onCloneLanguage()}
                default={this.props.defaultLanguage}
            />
        )
    }

    renderForm() {
        const { pageData, pageErrors, setPageData } = this.props;
        return (
            <div className="form-groups row">
                <div className="form-group col-md-12">
                    <FormAccordion title="Genel Bilgiler">
                        <div className="form-wrapper">
                            <div className="item row">
                                <TextField
                                    placeholder="Layout Adı"
                                    value={pageData.title || ""}
                                    label="Layout Adı"
                                    className="custom-textfield border"
                                    errorMessage={pageErrors.title}
                                    onChange={(event: any) => setPageData("title", event.target.value)}
                                />
                            </div>
                            <div className="item row">
                                <TextField
                                    placeholder="Layout Açıklaması"
                                    value={pageData.description || ""}
                                    label="Layout Açıklaması"
                                    multiline
                                    resizable={false}
                                    errorMessage={pageErrors.description}
                                    className="custom-textfield border"
                                    onChange={(event: any) => setPageData("description", event.target.value)}
                                />
                            </div>
                            <div className="item row">
                                <Toggle
                                    checked={pageData.theme === "dark" ? true : false}
                                    label="Layout Teması"
                                    className="custom-toggle"
                                    onText="Koyu Tema"
                                    offText="Açık Tema"
                                    onChange={(event: any, checked) => setPageData("theme", checked ? "dark" : "light")}
                                />
                            </div>
                        </div>
                    </FormAccordion>
                </div>
            </div>
        )
    }

    render() {
        const { pageData } = this.props;
        return (
            <PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
                <div className="page push-all">
                    <PageTitle
                        title="Düzenle"
                        titleRenderer={
                            () => (
                                <span className="edit-title">
                                    Düzenle:
                                    <strong>{this.props.pageData?.title || "-"}</strong>
                                </span>
                            )
                        }
                        showBack
                        backUrl="/layouts"
                        history={this.props.history}
                        subTitle="Aşağıdan geçerli layout'u düzenleyebilirsiniz."
                    />
                    <div className="absolute-create absolute-wrapper row">
                        <DefaultButton
                            onClick={() => this.onDuplicateLayout()}
                            iconProps={{ iconName: "CPlusPlusLanguage" }}
                            text="Layoutu Klonla"
                        />
                        <PrimaryButton
                            onClick={() => this.props.history.push("/layout/create")}
                            iconProps={{ iconName: "Add" }}
                            text="Yeni Layout Ekle"
                            styles={{
                                root: {
                                    background: "#62a98e",
                                    borderColor: "#5b9e85"
                                },
                                rootHovered: {
                                    background: "#5b9e85",
                                    borderColor: "#5b9e85"
                                },
                                rootDisabled: {
                                    background: "#ececec"
                                }
                            }}
                        />
                    </div>
                    {this.renderTranslatorBar()}
                    <div className="content row">
                        <div className="left col no-padding">
                            {this.renderMessageBar()}
                            {this.renderForm()}
                            <Designer />
                        </div>
                        <Sidebar
                            publishBar={{
                                onSave: isDraft => this.onSubmit(isDraft),
                                onDelete: () => this.onDelete(),
                                status: pageData.status,
                                hidePreviewButton: true,
                                isEdit: true,
                                userLabel: pageData.updatedBy ? 'Ekleyen Kullanıcı:' : 'Düzenleyen Kullanıcı:',
                                publishDateText: pageData.updatedBy ? 'Yayınlanma Tarihi:' : 'Eklenme Tarihi:',
                                dateText: moment((pageData.updatedAt ? pageData.updatedAt : pageData.createdAt)).format('DD.MM.YYYY, HH:mm')

                            }}
                            archiveBar={{
                                hide: true
                            }}
                            categoryBar={{
                                hide: true
                            }}
                            layoutBar={{
                                hide: true
                            }}
                            thumbnailBar={{
                                hide: true
                            }}
                        />
                    </div>
                </div>
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLayout);
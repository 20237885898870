import * as React from "react";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { DateUtils } from 'react-day-picker';
import moment from "moment";
import 'react-day-picker/lib/style.css';

import { BaseFieldProps } from "./types";

let debounceTimeout: number | NodeJS.Timeout = 0;

// Kendisine gelen Property ismine ve değerine göre DateTime field render eder.
export default class DateTimeField extends React.Component<BaseFieldProps> {

    handleDayChange = (date: Date) => {
        if (debounceTimeout) clearTimeout(debounceTimeout as number);
        debounceTimeout = setTimeout(() => {
            this.props.onChange(convertCallbackDate(date))
        }, 600)
    }

    getHours() {
        const date = this.props.value || moment().toISOString()
        const value = moment(date).utc().get("hours")
        return value || 0
    }

    getMinutes() {
        const date = this.props.value || moment().toISOString()
        const value = moment(date).get("minutes")
        return value || 0
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        const selectedDate = this.props.value ? moment(this.props.value).toDate() : undefined;

        return (
            <div className="property-item row">
                <div title={this.props.property?.text} className="property-text">
                    <div className="title">Tarih</div>
                    <div className="sub-title">Tarih</div>
                </div>
                <div className="col">
                    <div>
                        <div className="col-md-12 no-padding">
                            <DayPickerInput
                                value={selectedDate}
                                placeholder=""
                                format="dd.MM.yyyy"
                                onDayChange={this.handleDayChange}
                                dayPickerProps={{
                                    locale: "tr",
                                    months: MONTHS["tr"],
                                    weekdaysLong: WEEKDAYS_LONG["tr"],
                                    weekdaysShort: WEEKDAYS_SHORT["tr"],
                                    firstDayOfWeek: FIRST_DAY_OF_WEEK["tr"],
                                    labels: LABELS["tr"],
                                    disabledDays: {
                                        before: new Date(2024, 9, 22),
                                        after: new Date(2024, 9, 26),
                                    },
                                }}
                                parseDate={parseDate}
                                formatDate={formatDate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function parseDate(str: string, format: string) {
    const parsed = dateFnsParse(str, format, new Date());
    if (DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
}

function formatDate(date: Date, format: string, locale: string) {
    return dateFnsFormat(date, format);
}

function convertCallbackDate(date: Date) {
    const formatted = moment(date).toISOString();
    // const splitted = formatted.split(".");
    return formatted
}

const WEEKDAYS_SHORT = {
    tr: ['Pz', 'Pt', 'Sa', 'Ca', 'Pe', 'Cu', 'Ct'],
};
const MONTHS = {
    tr: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
    ]
};

const WEEKDAYS_LONG = {
    tr: [
        'Pazar',
        'Pazartesi',
        'Salı',
        'Çarşamba',
        'Perşembe',
        'Cuma',
        'Cumartesi',
    ],
};

const FIRST_DAY_OF_WEEK = {
    tr: 1,
};

const LABELS = {
    tr: { nextMonth: 'Sonraki Hafta', previousMonth: 'Önceki Hafta' },
};

import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

import { ColumnSettingsDefinition, ImageChildrenDefinitions } from 'components/cms/definitions';

export class ExhibitorListDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "exhibitor-list";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "exhibitors",
            description: "Exhibitors Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: 'companyname',
                        text: 'Firma Adı',
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: 'companylogo',
                        text: 'Firma Logosu',
                        mapping: {
                            type: PropertyMappingType.ParentObject,
                            children: ImageChildrenDefinitions
                        }
                    },
                    {
                        name: 'companyfieldsofactivity',
                        text: 'Firma Faaliyet Alanları',
                        mapping: {
                            type: PropertyMappingType.ArrayData
                        }
                    },
                    {
                        name: 'country',
                        text: 'Ülke',
                        mapping: {
                            type: PropertyMappingType.Object,
                        }
                    },
                    {
                        name: 'hallno',
                        text: 'Salon No',
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: 'standno',
                        text: 'Stant No',
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: 'anchor',
                        text: 'Url',
                        mapping: {
                            type: PropertyMappingType.LinkWizard
                        }
                    },
                ],
            },
            group: {
                key: "data",
                title: "Veriler",
                withObject: false,
            }
        },
        ...ColumnSettingsDefinition
    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: 'companyname',
                text: 'Firma Adı',
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: 'companylogo',
                text: 'Firma Logosu',
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: 'companyfieldsofactivity',
                text: 'Firma Faaliyet Alanları',
                types: [MetaTypeEnum.ComboBox],
            },
            {
                name: 'country',
                text: 'Ülke',
                types: [MetaTypeEnum.Dropdown],
            },
            {
                name: 'hallno',
                text: 'Salon No',
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: 'standno',
                text: 'Stant No',
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "anchor",
                text: "Url",
                types: [MetaTypeEnum.Link],
            },
        ],
    };
}

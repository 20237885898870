import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { MediaListProps } from "./types";
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

import { CardPropertyDefinitions, ColumnSettingsDefinition } from 'components/cms/definitions';

export class MediaListDefinitions extends CmsReactComponent<MediaListProps> {
    static schemaName = "media-list";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "data",
            description: "Media List Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: CardPropertyDefinitions,
            },
            group: {
                key: "data",
                title: "Kart Verileri",
                withObject: false,
            }
        },
        ...ColumnSettingsDefinition
    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: "title",
                text: "Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "subtitle",
                text: "Alt Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "anchor",
                text: "URL",
                types: [MetaTypeEnum.Link]
            },
        ],
    };
}

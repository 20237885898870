import cookie from "react-cookies";

import { store } from "app/redux";
import { RootState } from "app/redux/reducer";
import { UserRole, UserType } from "app/services/user";

export function isFileStorage(filePath: string): boolean {
    const storage = getFilePath();
    return filePath?.includes(storage) ? true : false
}

export function getFilePath() {
    return process.env.REACT_APP_CDN + "/" as string;
}

export function getCurrentSiteUi() {
    return process.env.REACT_APP_WEB
}

export function getEndpoint(suffix: string) {
    return process.env.REACT_APP_API + suffix
}

export function getCdnEndpoint(suffix: string) {
    return process.env.REACT_APP_CDN_API + suffix
}

export function getIdentityEndpoint(suffix: string) {
    return process.env.REACT_APP_API + suffix
}

export function checkUser() {
    const token = cookie.load("accessToken");
    const userInformation = cookie.load("userInformation");

    if (token && userInformation) {
        return true;
    }

    return false;
}

export function getUserType() {
    const state: RootState = store.getState();
    const user = state.system?.userInfo || {};

    if (user.user_type) {
        return user.user_type;
    }

    return UserType.SuperAdminUser;
}

export function getUserRole() {
    const state: RootState = store.getState();
    const user = state.system?.userInfo || {};

    if (user.role) {
        return user.role;
    }

    return [UserRole.SuperAdminUser];
}

export function checkUserRole(role: UserRole) {
    const state: RootState = store.getState();
    const user = state.system?.userInfo || {};

    if (user.role?.includes(role)) {
        return true;
    }
    return false;
}

export function isSystemUser() {
    return checkUserRole(UserRole.SuperAdminUser);
}

export function isEstablishmentUser() {
    return checkUserRole(UserRole.EstablishmentAuthorizedUser);
}

export function isAuthorizedUser() {
    return checkUserRole(UserRole.FirstApprovalAuthority) || checkUserRole(UserRole.SecondApprovalAuthority)
}

import { ThemeRoutes } from "app/shared"

export const themeSettingsMenu = [{
    key: "general",
    text: "Genel Özellikler",
    description: "Aşağıdan SEO işlemleri için başlıkların ön ve arkasına gelecek metni ve logo ayarlarını yapılandırabilirsiniz.",
    icon: "fas fa-cogs"
},
{
    key: "color",
    text: "Renk Yönetimi",
    description: "Aşağıdan koyu ve açık tema'ların renk ayarlarını yapılandırabilirsiniz.",
    icon: "fas fa-tint"
},
{
    key: "routes",
    text: "Sayfa Ayarları",
    description: "Aşağıdan statik (varsayılan) sayfaların post karşılıklarını ayarlayabilirsiniz.",
    icon: "fas fa-train"
},
{
    key: "locale",
    text: "Metinler",
    icon: "fas fa-font"
},
{
    key: "social",
    text: "Sosyal Medya Adresleri",
    icon: "fab fa-twitter"
},
{
    key: "languages",
    text: "Dil Seçenekleri",
    icon: "fas fa-globe"
}]

export const staticRoutes = [{
    key: ThemeRoutes.Home,
    text: "Anasayfa"
},
{
    key: ThemeRoutes.StandEnquiry,
    text: "Stand Enquiry"
},
{
    key: ThemeRoutes.Daily,
    text: "Daily"
}
]

export const defaultLanguages = ["en", "tr", "fr", "de", "nl", "ar", "ru", "uk", "es", "zh"]

export const defaultThemeLanguage = [{ name: "EN", nativeName: "English", code: "en" }];
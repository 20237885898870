import * as React from "react";
import { BaseFieldProps } from "./types";
import {
    SchemaNode,
    PropertyMappingType,
    PropertyDefinition
} from "app/library/layout-builder";
import StringField from "./StringField";
import VideoField from "./VideoField";
import ImageField from "./ImageField";
import IconField from "./IconField";
import ToggleField from "./ToggleField";
import ColorField from "./ColorField";
import AlignField from "./AlignField";
import DropdownField from "./DropdownField";
import LinkField from './LinkField';
import HTMLField from './HTMLField';
import MenuField from './MenuField';
import SrcSetField from './SrcSetField';
import FileField from "./FileField";

interface ParentObjectFieldProps extends BaseFieldProps {
    parent?: string;
}

export default class ParentObjectField extends React.Component<ParentObjectFieldProps> {

    onChangeProperty(key: string, value: any) {
        this.props.value[key] = value;
        this.props.onChange(this.props.value);
    }

    renderField(
        prop: PropertyDefinition,
        value: any,
        node: SchemaNode,
        innerPropKey: number
    ) {
        switch (prop.mapping.type) {
            case PropertyMappingType.String:
            case PropertyMappingType.Number:
            case PropertyMappingType.Any:
            case PropertyMappingType.Textarea:
                return (
                    <StringField
                        property={prop}
                        value={value}
                        multiline={prop.mapping.type === PropertyMappingType.Textarea}
                        key={innerPropKey}
                        index={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        type={prop.mapping.type === PropertyMappingType.Number ? "number" : "text"}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Video:
                return (
                    <VideoField
                        property={prop}
                        node={node}
                        index={innerPropKey}
                        value={value}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Image:
                return (
                    <ImageField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.File:
                return (
                    <FileField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Icon:
                return (
                    <IconField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Boolean:
                return (
                    <ToggleField
                        property={prop}
                        value={value}
                        key={innerPropKey}
                        index={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Color:
                return (
                    <ColorField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.LinkWizard:
                return (
                    <LinkField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Html:
                return (
                    <HTMLField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Alignment: {
                return (
                    <AlignField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            }
            case PropertyMappingType.Object: {
                return (
                    <DropdownField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            }
            case PropertyMappingType.ParentObject: {
                return (
                    <ParentObjectField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}


                    />
                );
            }
            case PropertyMappingType.Menu: {
                return (
                    <MenuField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            }
            case PropertyMappingType.SrcSet: {
                return (
                    <SrcSetField
                        property={prop}
                        value={value}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            }
            default:
                return null;
        }
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        console.log("this", this.props);
        console.log("value", this.props.value);

        return (
            <div className="parent-object-field">
                <ToggleField
                    property={this.props.property}
                    value={this.props.value}
                    onChange={value => {
                        this.props.onChange(value ? {} : null)
                    }}
                    schema={this.props.schema}
                    node={this.props.node}
                    index={this.props.index}
                    offText="Ayarları Yapılandır"
                    onText="Ayarları İptal Et"
                    language={this.props.language}

                />
                {this.props.value && (
                    <div className="content">
                        <div className="property-list">
                            {(this.props.property?.mapping.children || []).map((innerProp, innerPropIndex) =>
                                this.renderField(
                                    innerProp,
                                    this.props.value[innerProp.name],
                                    this.props.node,
                                    innerPropIndex
                                )
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from "lodash";

import { ComboBox, DefaultButton, Dropdown, IDropdownOption, Label, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, TextField, Toggle } from 'office-ui-fabric-react';
import { defaultLanguages, defaultThemeLanguage, staticRoutes, themeSettingsMenu } from './constants';
import { getPostsForPanel, IPost } from 'app/services/post';
import { createConfiguration, getConfiguration, getConfigurationByLanguage, IThemeLocale, IThemeSettings, ThemeSettingsLanguage, updateConfiguration } from 'app/services/configuration';
import { RootState } from 'app/redux/reducer';
import { setPageData } from 'app/redux/page/actions';
import { castThunkAction } from 'app/utils/casting';
import { ThemeRoutes } from 'app/shared';
import ImagePicker from 'components/customs/ImagePicker';
import MSColorPicker from 'components/customs/MSColorPicker';
import PageTitle from 'components/customs/PageTitle';
import PageLayout from 'containers/PageLayout';
import { RouteComponentProps } from 'react-router-dom';
import TranslatorPostBar from 'components/customs/TranslatorBar/TranslatorPostBar';
import { toggleConfirmDialog } from 'app/redux/system/actions';
import { LANGS } from 'app/shared/constants/langs';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
    return {
        ...ownProps,
        language: state.system.language,
        pageData: state.page.pageData as IThemeSettings,
        contentTypes: state.contentType.contentTypes?.list || [],
        defaultLanguage: state.system.defaultLanguage
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            setPageData,
            createConfiguration,
            getConfiguration,
            updateConfiguration,
            getPostsForPanel,
            toggleConfirmDialog,
            getConfigurationByLanguage
        }, dispatch)
    };
}

interface ThemeSettingsState {
    activeMenu: string;
    messageType: MessageBarType;
    posts: IPost[];
}

type ThemeSettingsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ThemeSettings extends Component<ThemeSettingsProps, ThemeSettingsState> {

    state = {
        activeMenu: "general",
        messageType: MessageBarType.warning,
        posts: [] as IPost[]
    }

    UNSAFE_componentWillMount() {
        this.props.getConfiguration();
    }

    upsertConfiguration() {
        castThunkAction(this.props.getConfiguration(true)).then(res => {
            if (!res) {
                castThunkAction(this.props.createConfiguration(this.props.pageData)).then(response => {
                    this.setState({
                        messageType: response ? MessageBarType.success : MessageBarType.error
                    })
                })
            }
            else {
                castThunkAction(this.props.updateConfiguration(this.props.pageData)).then(response => {
                    this.setState({
                        messageType: response ? MessageBarType.success : MessageBarType.error
                    })
                })
            }
        })
    }

    onChangeData(key: string, value: any) {
        this.props.setPageData("config", {
            ...this.props.pageData.config,
            [key]: value
        })
    }

    onChangeLanguage(language: string) {
        if (language !== this.props.pageData.language) {
            this.setState({
                activeMenu: "general"
            }, () => {
                this.props.getConfigurationByLanguage(this.props.pageData.config, language);
            })
        }
    }

    onCloneLanguage() {
        this.props.getConfigurationByLanguage(this.props.pageData.config, this.props.pageData.language, true);
    }

    onStaticPageItemChange(name: string, key: string, value: string) {

        const routes = _.cloneDeep(this.props.pageData.config.routes || {})
        const current = routes[name]

        if (!current) {
            routes[name] = { contentType: "", url: "" }
        }
        routes[name][key] = value;
        this.onChangeData("routes", routes)
    }

    onStaticPivotClick(item: PivotItem) {
        const current = this.props.pageData?.config?.routes[item.props.itemKey as string]

        if (current && current.contentType) {
            this.getPosts(current.contentType);
        }
    }

    onClickLeftMenuItem(key: string) {
        this.setState({
            activeMenu: key
        }, () => {
            if (key === "routes") {
                const current = this.props.pageData?.config?.routes ? this.props.pageData?.config?.routes[ThemeRoutes.Home] : null;

                if (current && current.contentType) {
                    this.getPosts(current.contentType);
                }
            }
        })
    }

    getPosts(contentTypeId: string, language?: string) {
        castThunkAction<IPost[]>(this.props.getPostsForPanel(contentTypeId, language || this.props.pageData.language || this.props.defaultLanguage)).then(res => {
            this.setState({
                posts: res
            })
        })
    }

    renderStaticPagePicker(name: string) {
        console.log("this", this.props.pageData);

        const current = this.props.pageData?.config.routes ? this.props.pageData?.config.routes[name] : {} as { contentType: string; url: string; }
        return (
            <div className="static-page-picker row">
                <ComboBox
                    className="custom-combobox col-md-12"
                    label="İçerik Tipi Seçiniz"
                    selectedKey={current?.contentType}
                    disabled={!this.props.contentTypes || this.props.contentTypes.length === 0}
                    autoComplete="on"
                    allowFreeform
                    options={(this.props.contentTypes || []).map((item, index) => ({ key: item.id as string, text: item.title }))}
                    onChange={(_, opt) => {
                        this.onStaticPageItemChange(name, "contentType", opt?.key as string)
                        this.getPosts(opt?.key as string);
                    }}
                />
                <ComboBox
                    className="custom-combobox col-md-12"
                    label="Sayfa Seçiniz"
                    selectedKey={current?.url}
                    disabled={!this.state.posts || this.state.posts.length === 0}
                    autoComplete="on"
                    allowFreeform
                    options={(this.state.posts || []).map((item, index) => ({ key: item.url as string, text: item.title }))}
                    onChange={(_, opt) => {
                        this.onStaticPageItemChange(name, "url", opt?.key as string)
                    }}
                />
            </div>
        )
    }

    renderTextByMessageType() {
        switch (this.state.messageType) {
            case MessageBarType.success:
                return "Tema ayarlarını güncelleme işlemi başarıyla gerçekleşmiştir."
            case MessageBarType.error:
                return "Kaydetme işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin."
            default:
                return "Genel Özellikler ve Renk Yönetimi üzerinde değişiklikler yapabilirsiniz."
        }
    }

    renderMessageBar() {
        return (
            <MessageBar
                messageBarType={this.state.messageType}
                isMultiline={false}
            >
                {this.renderTextByMessageType()}
            </MessageBar>
        )
    }

    renderLeftMenu() {
        return (
            <div className="theme-settings-menu col-md-2 no-padding">
                <div className="theme-settings-menu-wrapper">
                    {themeSettingsMenu.map((item, index) => (
                        <div key={index} onClick={() => this.onClickLeftMenuItem(item.key)} className={`item ${item.key === this.state.activeMenu ? "active" : ""}`}>
                            <i className={item.icon}></i>
                            {item.text}
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    renderGeneralSettings() {
        return (
            <div className="theme-general-setting">
                <div className="row reverse-margin">
                    <TextField
                        className="custom-textfield col-md-6"
                        label="Site Başlığı (Sayfa Başlıklarının Başına)"
                        value={this.props.pageData?.config?.settings?.prefix}
                        onChange={(event: any) => {
                            this.onChangeData("settings", {
                                ...this.props.pageData?.config?.settings,
                                prefix: event.target.value
                            })
                        }}
                    />
                    <TextField
                        className="custom-textfield col-md-6"
                        label="Site Başlığı (Sayfa Başlıklarının Sonuna)"
                        value={this.props.pageData?.config?.settings?.suffix}
                        onChange={(event: any) => {
                            this.onChangeData("settings", {
                                ...this.props.pageData?.config?.settings,
                                suffix: event.target.value
                            })
                        }}
                    />
                </div>
                <div style={{ marginTop: "5px" }} className="file-item">
                    <Label>
                        Açık Tema Logosu
                    </Label>
                    <ImagePicker
                        image={this.props.pageData?.config?.themes?.light?.logo || ""}
                        onChange={(image) => {
                            this.onChangeData("themes", {
                                ...this.props.pageData?.config?.themes,
                                light: {
                                    ...this.props.pageData?.config?.themes?.light,
                                    logo: image
                                }
                            })
                        }}
                    />
                </div>
                <div className="file-item">
                    <Label>
                        Koyu Tema Logosu
                    </Label>
                    <ImagePicker
                        image={this.props.pageData?.config?.themes?.dark?.logo || ""}
                        onChange={(image) => {
                            this.onChangeData("themes", {
                                ...this.props.pageData?.config?.themes,
                                dark: {
                                    ...this.props.pageData?.config?.themes?.dark,
                                    logo: image
                                }
                            })
                        }}
                    />
                </div>
            </div >
        )
    }

    renderSocial() {
        return (
            <div className="theme-general-setting">
                <div className="row reverse-margin">
                    <TextField
                        className="custom-textfield col-md-12"
                        label="Instagram"
                        value={this.props.pageData?.config?.social?.instagram}
                        onChange={(event: any) => {
                            this.onChangeData("social", {
                                ...this.props.pageData?.config?.social,
                                instagram: event.target.value
                            })
                        }}
                    />
                    <TextField
                        className="custom-textfield col-md-12"
                        label="Twitter"
                        value={this.props.pageData?.config?.social?.twitter}
                        onChange={(event: any) => {
                            this.onChangeData("social", {
                                ...this.props.pageData?.config?.social,
                                twitter: event.target.value
                            })
                        }}
                    />
                    <TextField
                        className="custom-textfield col-md-12"
                        label="Facebook"
                        value={this.props.pageData?.config?.social?.facebook}
                        onChange={(event: any) => {
                            this.onChangeData("social", {
                                ...this.props.pageData?.config?.social,
                                facebook: event.target.value
                            })
                        }}
                    />
                    <TextField
                        className="custom-textfield col-md-12"
                        label="Youtube"
                        value={this.props.pageData?.config?.social?.youtube}
                        onChange={(event: any) => {
                            this.onChangeData("social", {
                                ...this.props.pageData?.config?.social,
                                youtube: event.target.value
                            })
                        }}
                    />
                    <TextField
                        className="custom-textfield col-md-12"
                        label="Linkedin"
                        value={this.props.pageData?.config?.social?.linkedin}
                        onChange={(event: any) => {
                            this.onChangeData("social", {
                                ...this.props.pageData?.config?.social,
                                linkedin: event.target.value
                            })
                        }}
                    />
                    <TextField
                        className="custom-textfield col-md-12"
                        label="Whatsapp"
                        value={this.props.pageData?.config?.social?.whatsapp}
                        onChange={(event: any) => {
                            this.onChangeData("social", {
                                ...this.props.pageData?.config?.social,
                                whatsapp: event.target.value
                            })
                        }}
                    />
                    <TextField
                        className="custom-textfield col-md-12"
                        label="Spotify"
                        value={this.props.pageData?.config?.social?.spotify}
                        onChange={(event: any) => {
                            this.onChangeData("social", {
                                ...this.props.pageData?.config?.social,
                                spotify: event.target.value
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    renderColorPickerWithParent(themeType: "light" | "dark", name: string, parent: string) {
        const themeTypeObject = this.props.pageData?.config?.themes ? this.props.pageData?.config?.themes[themeType] as any : {};
        const themeColors = themeTypeObject?.colors ? themeTypeObject.colors : {};
        return (
            <MSColorPicker
                onChangeColor={(color: string) =>
                    this.onChangeData("themes", {
                        ...this.props.pageData?.config?.themes,
                        [themeType]: {
                            ...themeColors,
                            colors: {
                                ...themeColors,
                                [parent]: {
                                    ...this.props.pageData?.config?.themes[themeType].colors[parent],
                                    [name]: color
                                }
                            }
                        }
                    })
                }
                color={(themeColors && themeColors[parent]) ? themeColors[parent][name] : "#000"}
            />
        )
    }

    renderColorPicker(themeType: "light" | "dark", name: string) {
        const themeTypeObject = this.props.pageData?.config?.themes ? this.props.pageData?.config?.themes[themeType] as any : {};
        const themeColors = themeTypeObject?.colors ? themeTypeObject.colors : {};
        return (
            <MSColorPicker
                onChangeColor={(color: string) =>
                    this.onChangeData("themes", {
                        ...this.props.pageData?.config?.themes,
                        [themeType]: {
                            ...themeTypeObject,
                            colors: {
                                ...themeColors,
                                [name]: color
                            }
                        }
                    })
                }
                color={themeColors ? themeColors[name] : "#000"}
            />
        )
    }

    renderColorGroupByType(key: "light" | "dark", colorType: string, colorTitle?: string) {
        if (colorType === "neutral") {
            return (
                <React.Fragment>
                    <Label>Bağımsız Renkler - 900</Label>
                    {this.renderColorPickerWithParent(key, "900", "neutral")}
                    <Label>Bağımsız Renkler - 800</Label>
                    {this.renderColorPickerWithParent(key, "800", "neutral")}
                    <Label>Bağımsız Renkler - 700</Label>
                    {this.renderColorPickerWithParent(key, "700", "neutral")}
                    <Label>Bağımsız Renkler - 600</Label>
                    {this.renderColorPickerWithParent(key, "600", "neutral")}
                    <Label>Bağımsız Renkler - 500</Label>
                    {this.renderColorPickerWithParent(key, "500", "neutral")}
                    <Label>Bağımsız Renkler - 400</Label>
                    {this.renderColorPickerWithParent(key, "400", "neutral")}
                    <Label>Bağımsız Renkler - 300</Label>
                    {this.renderColorPickerWithParent(key, "300", "neutral")}
                    <Label>Bağımsız Renkler - 200</Label>
                    {this.renderColorPickerWithParent(key, "200", "neutral")}
                    <Label>Bağımsız Renkler - 100</Label>
                    {this.renderColorPickerWithParent(key, "100", "neutral")}
                    <Label>Bağımsız Renkler - 50</Label>
                    {this.renderColorPickerWithParent(key, "50", "neutral")}
                    <Label>Bağımsız Renkler - 40</Label>
                    {this.renderColorPickerWithParent(key, "40", "neutral")}
                    <Label>Bağımsız Renkler - 30</Label>
                    {this.renderColorPickerWithParent(key, "30", "neutral")}
                    <Label>Bağımsız Renkler - 20</Label>
                    {this.renderColorPickerWithParent(key, "20", "neutral")}
                    <Label>Bağımsız Renkler - 10</Label>
                    {this.renderColorPickerWithParent(key, "10", "neutral")}
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <Label>{colorTitle} Renkler - 50</Label>
                {this.renderColorPickerWithParent(key, "50", colorType)}
                <Label>{colorTitle} Renkler - 75</Label>
                {this.renderColorPickerWithParent(key, "75", colorType)}
                <Label>{colorTitle} Renkler - 500</Label>
                {this.renderColorPickerWithParent(key, "500", colorType)}
                <Label>{colorTitle} Renkler - 400</Label>
                {this.renderColorPickerWithParent(key, "400", colorType)}
                <Label>{colorTitle} Renkler - 300</Label>
                {this.renderColorPickerWithParent(key, "300", colorType)}
                <Label>{colorTitle} Renkler - 100</Label>
                {this.renderColorPickerWithParent(key, "100", colorType)}
            </React.Fragment>
        )
    }

    renderColors(dark?: boolean) {
        const key = dark ? "dark" : "light";
        return (
            <Pivot>
                <PivotItem headerText="Birincil Renkler">
                    {this.renderColorGroupByType(key, "primary")}
                </PivotItem>
                <PivotItem headerText="Bağımsız Renkler">
                    {this.renderColorGroupByType(key, "neutral")}
                </PivotItem>
                <PivotItem headerText="Kırmızı Renkler">
                    {this.renderColorGroupByType(key, "red", "Kırmızı")}
                </PivotItem>
                <PivotItem headerText="Sarı Renkler">
                    {this.renderColorGroupByType(key, "yellow", "Sarı")}
                </PivotItem>
                <PivotItem headerText="Mavi Renkler">
                    {this.renderColorGroupByType(key, "blue", "Mavi")}
                </PivotItem>
                <PivotItem headerText="Yeşil Renkler">
                    {this.renderColorGroupByType(key, "green", "Yeşil")}
                </PivotItem>
                <PivotItem headerText="Pembe Renkler">
                    {this.renderColorGroupByType(key, "purple", "Pembe")}
                </PivotItem>
            </Pivot>
        )
    }

    renderColorProperties() {
        return (
            <div className="theme-color-properties">
                <Pivot>
                    <PivotItem headerText="Koyu Tema">
                        {this.renderColors(true)}
                    </PivotItem>
                    <PivotItem headerText="Açık Tema">
                        {this.renderColors()}
                    </PivotItem>
                </Pivot>
            </div>
        )
    }


    getLanguages() {
        const options: IDropdownOption[] = [];
        _.keys(LANGS).forEach(key => {
            if (defaultLanguages.includes(key)) {
                options.push({
                    key,
                    text: `${LANGS[key].name} (${LANGS[key].nativeName})`
                })
            }
        })
        return options;
    }

    onRenderOption = (option: IDropdownOption | undefined) => {
        if (!option) return null;
        return (
            <div className="dropdown-option">
                {option.key && (
                    <img src={require(`assets/images/flags/${option?.key}.png`).default} alt="" />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    onRenderTitle = (options: IDropdownOption[] | undefined) => {
        if (!options) return null;
        const option = options[0];
        return (
            <div className="dropdown-title">
                {option.key && (
                    <img src={require(`assets/images/flags/${option?.key}.png`).default} alt="" />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    renderTranslatorBar() {
        console.log("this.props.pageData.config?.languages", this.props.pageData.config?.languages)

        return (
            <TranslatorPostBar
                languages={(this.props.pageData.config?.languages || []).map(item => item.code?.split("-")[0])}
                onChange={(language) => this.onChangeLanguage(language)}
                onClone={() => this.onCloneLanguage()}
                toggleDialog={this.props.toggleConfirmDialog}
                current={this.props.pageData.language || this.props.defaultLanguage}
                default={this.props.defaultLanguage}
            />
        )
    }

    renderLanguagePicker(item: ThemeSettingsLanguage, index: number) {
        return (
            <div className="language-picker">
                <Dropdown
                    className="custom-dropdown"
                    calloutProps={{ className: "custom-dropdown-callout" }}
                    onRenderTitle={(options) => this.onRenderTitle(options)}
                    onRenderOption={(option) => this.onRenderOption(option)}
                    label="Dil Seçeneği"
                    options={this.getLanguages()}
                    selectedKey={item.code?.split("-")[0] || ""}
                    onChange={(_ev, opt) => {
                        const languages = _.cloneDeep(this.props.pageData?.config?.languages || []);
                        const lang = LANGS[opt?.key as string]

                        languages[index] = {
                            code: lang.nativeCode,
                            name: lang.nativeCode?.split("-")[0]?.toUpperCase(),
                            nativeName: lang.nativeName
                        }

                        this.onChangeData("languages", languages)
                    }}
                />
            </div>
        )
    }

    renderTextItem(item: IThemeLocale, index: number) {
        const data = _.cloneDeep(this.props.pageData?.config?.locale || []);

        return (
            <div className="theme-text-item row" key={index}>
                <TextField
                    className="custom-textfield col"
                    label="Metin Adı"
                    value={item.key || ""}
                    onChange={(event: any) => {
                        data[index].key = event.target.value;
                        this.onChangeData("locale", data);
                    }}
                />
                <TextField
                    className="custom-textfield col"
                    label="Metin Değeri"
                    value={item.value || ""}
                    onChange={(event: any) => {
                        data[index].value = event.target.value;
                        this.onChangeData("locale", data);
                    }}
                />
                {data.length > 1 && (
                    <i
                        className="icon-close1 remove"
                        onClick={() => {
                            data.splice(index, 1);
                            this.onChangeData("locale", data)
                        }}
                    >
                    </i>
                )}
            </div>
        )
    }

    renderTexts() {
        const data = this.props.pageData?.config?.locale || [];

        return (
            <div className="theme-general-settings" style={{ margin: "10px 0px" }}>
                {data.map((item: IThemeLocale, index: number) => (
                    this.renderTextItem(item, index)
                ))}
                <DefaultButton
                    className="custom-button"
                    text="Yeni Metin Ekle"
                    onClick={() => {
                        data.push({ key: "", value: "" });
                        this.onChangeData("locale", data)
                    }}
                />
            </div>
        )
    }

    renderLanguages() {
        const languages = !_.isArray(this.props.pageData.config?.languages) ? defaultThemeLanguage : this.props.pageData.config?.languages || defaultThemeLanguage;

        console.log("languages", languages);

        return (
            <div className="theme-general-settings">
                <Toggle
                    className="custom-toggle"
                    label="Dil Altyapısı UI Üzerinde Aktif Edilsin Mi?"
                    styles={{
                        label: {
                            marginLeft: "-5px !important"
                        },
                        container: {
                            marginLeft: "-5px !important",
                            marginTop: "1px !important",
                            marginBottom: "15px !important"
                        }
                    }}
                    checked={this.props.pageData?.config?.translation}
                    onChange={(_, checked) => {
                        this.onChangeData("translation", checked)
                    }}
                />
                <div style={{ marginTop: 10, marginBottom: "-5px" }} className="ms-Label">İçerikler Üzerinde Kullanılacak Dil'leri Yönetin</div>
                <div className="language-list">
                    {languages.map((item, index) => (
                        <div className="language-list-item row">
                            {this.renderLanguagePicker(item, index)}
                            <i
                                className="icon-close1 remove"
                                onClick={() => {
                                    const languages = _.cloneDeep(this.props.pageData.config?.languages);

                                    languages.splice(index, 1);
                                    this.onChangeData("languages", languages)
                                }}
                            >
                            </i>
                        </div>
                    ))}
                    <DefaultButton
                        className="custom-button"
                        text="Yeni Dil Ekle"
                        onClick={() => {
                            const languages: ThemeSettingsLanguage[] = !_.isArray(this.props.pageData.config?.languages) ? defaultThemeLanguage : _.cloneDeep(this.props.pageData.config?.languages) || defaultThemeLanguage;

                            languages.push({ name: "", nativeName: "", code: "" });
                            this.onChangeData("languages", languages)
                        }}
                    />
                </div>
            </div>
        )
    }


    renderRoutes() {
        return (
            <div className="theme-color-properties theme-routes">
                <Pivot>
                    {staticRoutes.map((item, index) => (
                        <PivotItem key={index} headerText={item.text}>
                            {this.renderStaticPagePicker(item.key)}
                        </PivotItem>
                    ))}
                </Pivot>
            </div>
        )
    }

    renderContentByType() {
        switch (this.state.activeMenu) {
            case "general":
                return this.renderGeneralSettings()
            case "color":
                return this.renderColorProperties()
            case "routes":
                return this.renderRoutes()
            case "languages":
                return this.renderLanguages()
            case "locale":
                return this.renderTexts()
            case "social":
                return this.renderSocial()
            default:
                return null;
        }
    }

    renderMenuContent() {
        return (
            <div className="theme-settings-content col-md-10">
                <div className="title">
                    {themeSettingsMenu.find(x => x.key === this.state.activeMenu)?.text}
                    <div className="sub-text">
                        {themeSettingsMenu.find(x => x.key === this.state.activeMenu)?.description}
                    </div>
                </div>
                <div className="scrollable-content">
                    {this.renderContentByType()}
                </div>
            </div>
        )
    }

    render() {
        return (
            <PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
                <div className="page push-all">
                    <PageTitle
                        title="Tema Ayarları"
                        subTitle="Geçerli uygulamanın tüm sistemsel ayarlarını ve görsel özelliklerini yönetebilirsiniz."
                    />
                    <div className="content row">
                        <div className="left col no-padding">
                            {this.renderMessageBar()}
                            {this.renderTranslatorBar()}
                        </div>
                        <div className="theme-settings row">
                            {this.renderLeftMenu()}
                            {this.renderMenuContent()}
                        </div>
                        <PrimaryButton
                            text="Tüm Değişiklikleri Kaydet"
                            onClick={() => {
                                this.upsertConfiguration();
                                window.scrollTo(0, 0);
                            }}
                            className="m-left-auto"
                            styles={{
                                root: {
                                    fontSize: "12px",
                                    marginTop: "10px"
                                }
                            }}
                        />
                    </div>
                </div>
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSettings);
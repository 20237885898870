import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { HrProps } from "./types";
import {
    PropertyMappingType,
    PropertyDefinition,
} from "app/library/layout-builder";

export class HrDefinitions extends CmsReactComponent<HrProps> {
    static defaultProps = {};

    static schemaName = "horizontal-rule";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "color",
            mapping: {
                type: PropertyMappingType.Color,
            },
            text: "Çizgi Rengi",
        },
        {
            name: "align",
            mapping: {
                type: PropertyMappingType.Alignment,
            },
            text: "HR Hizalama",
        },

        {
            name: "width",
            mapping: {
                type: PropertyMappingType.String,
            },
            text: "HR Genişliği",
        },
    ];

}

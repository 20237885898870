import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { ParagraphProps } from "./types";
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

export class ParagraphDefinitions extends CmsReactComponent<ParagraphProps> {

    static schemaName = "paragraph";
    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "children",
            text: "Paragraf Yazısı",
            mapping: {
                type: PropertyMappingType.Textarea
            },
        },
        {
            name: "passive",
            text: "Pasif Mi?",
            mapping: {
                type: PropertyMappingType.Boolean
            }
        }
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.Text,
                MetaTypeEnum.TextArea,
                MetaTypeEnum.Editor,
                MetaTypeEnum.Date
            ]
        }
    }
}

import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";
import { EntryOptions } from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

export class SponsorListWithTabsDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "sponsor-list-with-tabs";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "tabsboolean",
            text: "Tab Olsun mu?",
            mapping: {
                type: PropertyMappingType.Boolean,
            },
        },
        {
            name: "tablist",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        text: "Başlık",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                    },
                    {
                        name: "data",
                        description: "Firma Galerisi Elemanları",
                        mapping: {
                            type: PropertyMappingType.ArrayData,
                            keys: [
                                {
                                    name: 'title',
                                    text: 'Başlık',
                                    mapping: {
                                        type: PropertyMappingType.String
                                    }
                                },
                                {
                                    name: 'description',
                                    text: 'Açıklama',
                                    mapping: {
                                        type: PropertyMappingType.String
                                    }
                                },
                                {
                                    name: 'image',
                                    text: 'İkon',
                                    mapping: {
                                        type: PropertyMappingType.Image,
                                    }
                                },
                                {
                                    name: 'anchor',
                                    text: 'Link',
                                    mapping: {
                                        type: PropertyMappingType.LinkWizard
                                    }
                                },
                            ],
                        }
                    }
                ]
            },
            default: []
        }
    ];

    static options: EntryOptions = {
        // meta: {
        //     use: true,
        //     allowedTypes: [
        //         MetaTypeEnum.MultiImage,
        //         MetaTypeEnum.PhotoGallery
        //     ]
        // },
        mappingFields: [
            // {
            //     name: "tabsboolean",
            //     text: "Tab Oluşsun mu?",
            //     types: [MetaTypeEnum.],
            // },
            {
                name: "title",
                text: "Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: 'description',
                text: 'Açıklama',
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "image",
                text: "Logo",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: 'anchor',
                text: 'Link',
                types: [MetaTypeEnum.Link],
            },
        ]
    };
}
import { CmsReactComponent } from 'components/cms/CmsReactComponent';

import { EntryOptions, PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { SignatureEventProps } from './types';
import { MetaTypeEnum } from 'app/shared/enums';


export class SignatureEventDefinitions extends CmsReactComponent<SignatureEventProps> {
  static defaultProps = {};

  static schemaName = "signature-event";

  static propertyDefinitions: PropertyDefinition[] = [
   
    {
      name: "events",
      mapping: {
        type: PropertyMappingType.ArrayData,
        keys: [
         
          {
            name: "title",
            mapping: {
              type: PropertyMappingType.Textarea,
            },
            text: "Başlık",
          },
         
          {
            name: "eventlists",
            mapping: {
              type: PropertyMappingType.ArrayData,
              keys: [
               
                {
                  name: "title",
                  mapping: {
                    type: PropertyMappingType.Textarea,
                  },
                  text: "Firmalar",
                },
                {
                  name: "date",
                  mapping: {
                    type: PropertyMappingType.DateTime2,
                  },
                  text: "Tarih",
                },
                {
                  name: "time",
                  mapping: {
                    type: PropertyMappingType.String,
                  },
                  text: "Zaman",
                },
                {
                  name: "day",
                  mapping: {
                    type: PropertyMappingType.String,
                  },
                  text: "Gün",
                },
                {
                  name: 'category',
                  text: 'Etkinlik Alanı',
                  mapping: {
                    type: PropertyMappingType.Object,
                    oneOf: [
                      {
                        key: 5,
                        text: "İmza Töreni Alanı 1",
                      },
                      {
                        key: 6,
                        text: "İmza Töreni Alanı 2",
                      },
                      {
                        key: 7,
                        text: "Sunum Alanı",
                      },
                    ],
                  },
                },
              ]
            },
            group: {
              key: "data",
              title: "Veriler",
              withObject: false,
          }
          }
        ]
      },
      default: []
    }
  ]
  static options: EntryOptions = {
    meta: {
        use: false,
        allowedTypes: [],
    },
    mappingFields: [
        {
            name: "title",
            text: "Başlık",
            types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
        },
        {
            name: 'companyName',
            text: 'Firma Adı',
            types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
        },
        {
            name: 'category',
            text: 'Etkinlik Alanı',
            types: [MetaTypeEnum.Dropdown],
        },
        {
            name: "date",
            text: "Tarih",
            types: [MetaTypeEnum.Date, MetaTypeEnum.Date],
        },
        {
            name: 'time',
            text: 'Zaman',
            types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
        },
        {
          name: 'day',
          text: 'Gün',
          types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
      },
    ],
};
}

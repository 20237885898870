import { combineReducers } from "redux";
import { systemReducer } from './system/reducer';
import { modalReducer } from './modal/reducer';
import { designerReducer } from './designer/reducer';
import { pageReducer } from './page/reducer';
import { contentTypeReducer } from './content-type/reducer';
import { categoryReducer } from './category/reducer';

export const rootReducer = combineReducers({
	system: systemReducer,
	page: pageReducer,
	modal: modalReducer,
	designer: designerReducer,
	contentType: contentTypeReducer,
	category: categoryReducer
});

export type RootState = ReturnType<typeof rootReducer>;
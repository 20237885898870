import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";

export class HomeSahaMatchDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "home-saha-match";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "sahaNews",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        text: "Başlık",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                    },
                    {
                        name: "image",
                        text: "Resim",
                        mapping: {
                            type: PropertyMappingType.Image,
                        },
                    },
                    {
                        name: "anchor",
                        description: "URL",
                        mapping: {
                            type: PropertyMappingType.LinkWizard
                        },
                        default: null,
                    },
                ]
            },
            default: []
        }
    ];
}

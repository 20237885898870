import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { VideoProps } from "./types";
import {
    PropertyDefinition, EntryOptions
} from "app/library/layout-builder";
import { MetaTypeEnum } from 'app/shared';
import { VideoDefinition } from '../definitions';

export class VideoDefinitions extends CmsReactComponent<VideoProps> {
    static defaultProps = {
        formats: {
            mp4: "",
            ogg: "",
            webm: "",
        },
    };
    static schemaName = "video";
    static propertyDefinitions: PropertyDefinition[] = VideoDefinition;

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.SingleVideo
            ]
        }
    }
}

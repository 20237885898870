import { Component } from 'react'
import { ComboBox, DefaultButton, Dialog, DialogType, Dropdown, DropdownMenuItemType, IDropdownOption, Label, TextField } from 'office-ui-fabric-react';
import _ from "lodash";

import { filterTypes } from './constants';
import { PropertyDefinition, PropertyMappingType, SchemaNode, SchemaSourceTypes } from 'app/library/layout-builder';
import { DynamicFilterTypes, IDynamicFilter, IDynamicFilterOrder, IDynamicFilterParam, MetaTypeEnum } from 'app/shared';
import { IContentType, IMeta, ISpec } from 'app/services/content-type';
import AutoComplete from '../AutoComplete';

interface FilterWizardModalProps {
    show: boolean;
    filter: IDynamicFilter;
    data: IDropdownOption[];
    contentTypes: IContentType[];
    node: SchemaNode;
    onChange: (filter: IDynamicFilter) => void;
    onDismiss: () => void;
}

export default class FilterWizardModal extends Component<FilterWizardModalProps> {

    getParams() {
        let params = (this.props.filter.params || []).filter(x => x.name !== "contentTypeId" && x.name !== "pageSize" && x.name !== "pageIndex");
        if (params.length === 0) {
            params = [
                { name: "", type: "" as DynamicFilterTypes, ref: "" }
            ] as IDynamicFilterParam[];
        }
        return params;
    }

    getParamsWithoutContentType() {
        let params = (this.props.filter.params || []).filter(x => x.name !== "contentTypeId");
        if (params.length === 0) {
            params = [
                { name: "", type: "" as DynamicFilterTypes, ref: "" }
            ] as IDynamicFilterParam[];
        }
        return params;
    }

    getContentTypeId() {
        return (this.props.filter?.params || []).find(x => x.type === DynamicFilterTypes.ContentType) || null;
    }

    onChangeParamObject(value: IDynamicFilterParam, index: number) {
        const clonedParams = _.cloneDeep(this.props.filter.params);
        if (!clonedParams[index]) {
            if (index === -1) {
                clonedParams.push(value)
            }
            else {
                clonedParams[index] = value;
            }
        }
        else {
            clonedParams[index] = value;
        }
        this.props.onChange({ ...this.props.filter, params: clonedParams })
    }


    onChangeParam(key: string, value: string, index: number) {
        const clonedParams = _.cloneDeep(this.props.filter.params);

        if (!clonedParams[index]) {

            if (index === -1) {
                clonedParams.push({ [key]: value })
            }
            else {
                clonedParams[index] = {
                    [key]: value
                }
            }
        }
        else {
            clonedParams[index][key] = value;
        }

        this.props.onChange({ ...this.props.filter, params: clonedParams })
    }

    onChangeOrder(key: string, value: string, index: number) {
        const clonedOrders = _.cloneDeep(this.props.filter.orders || []);

        if (!clonedOrders[index]) {
            if (key !== "value") {
                clonedOrders[index] = {
                    name: value,
                    value: "desc"
                }
            }
            else {
                clonedOrders[index] = {
                    [key]: value
                }
            }
        }
        else {
            if (key === "name") {
                const isSpecification = value.split(".")[0] === "spec" ? true : false;
                clonedOrders[index] = {
                    name: value,
                    value: isSpecification ? "desc" : "asc"
                }
            }
            else {
                clonedOrders[index]["value"] = value;
            }
        }

        this.props.onChange({ ...this.props.filter, orders: clonedOrders })
    }

    getOrderOptions(): IDropdownOption[] {

        let options: IDropdownOption[] = [
            {
                key: "postFields",
                text: "İçerik Alanları",
                itemType: DropdownMenuItemType.Header
            },
            {
                key: "title",
                text: "İçerik Başlığı"
            },
            {
                key: "pricing.discountedPrice",
                text: "Ürün Fiyatı"
            },
            {
                key: "pricing.discount",
                text: "İndirim Oranı"
            },
            {
                key: "specFields",
                text: "Spesifikasyonlar",
                itemType: DropdownMenuItemType.Header
            }
        ];

        return options;
    }

    getProperty(name: string) {
        return (this.props.filter.params || []).find(x => x.name === name) as IDynamicFilterParam;
    }

    getContentType() {
        const contentTypeReference = (this.props.filter?.params || []).find(x => x.type === DynamicFilterTypes.ContentType);
        if (contentTypeReference) {
            const contentType = (this.props.contentTypes || []).find(x => x.id === contentTypeReference.ref);
            if (contentType) {
                return contentType
            }
        }
        return null;
    }

    getMetaFields(metaFields: IMeta[], type?: MetaTypeEnum) {
        let fields = metaFields.map(x => ({ name: x.name, type: x.type }));
        fields.forEach(item => {
            if (item.type === MetaTypeEnum.Dropdown) {
                fields = [...fields, { name: `${item.name}.id`, type: item.type }, { name: `${item.name}.title`, type: item.type }]
            }
            if (item.type === MetaTypeEnum.SingleImage) {
                fields = [
                    ...fields,
                    { name: `${item.name}.src`, type: item.type },
                    { name: `${item.name}.alt`, type: item.type },
                    { name: `${item.name}.title`, type: item.type },
                    { name: `${item.name}.srcset`, type: item.type }
                ]
            }
        })
        return fields.filter(item => item.type === type).map(item => item.name);
    }

    getSpecFields(specFields: ISpec[], type?: MetaTypeEnum) {
        let fields = specFields.map(x => ({ name: x.name, type: x.type }));
        fields.forEach(item => {
            if (item.type === MetaTypeEnum.Dropdown) {
                fields = [...fields, { name: `${item.name}.id`, type: item.type }, { name: `${item.name}.title`, type: item.type }]
            }
        })
        return fields.map(item => item.name);
    }

    getContentTypeMetaFields(type?: MetaTypeEnum) {
        const contentType = this.getContentType();
        if (contentType) {
            return this.getMetaFields(contentType.metaFields, type);
        }
        return []
    }

    getContentTypeSpecFields(type?: MetaTypeEnum) {
        const contentType = this.getContentType()
        if (contentType) {
            return this.getSpecFields(contentType.specifications, type);
        }
        return []
    }

    getFieldValues() {
        const postFields = ["post.id", "post.title", "post.profileImage", "post.pricing", "post.url"];
        const metaFields = this.getContentTypeMetaFields().map(x => "meta." + x);
        const specFields = this.getContentTypeSpecFields().map(x => "spec." + x);
        return [...postFields, ...metaFields, ...specFields];
    }

    getMappingValues(metaType: MetaTypeEnum, sourceType: DynamicFilterTypes) {
        let postFields = [
            { type: MetaTypeEnum.Text, text: "id" },
            { type: MetaTypeEnum.Text, text: "title" },
            { type: MetaTypeEnum.SingleImage, text: "profileImage" },
            { type: MetaTypeEnum.SingleImage, text: "profileImage.src" },
            { type: MetaTypeEnum.Text, text: "profileImage.title" },
            { type: MetaTypeEnum.Text, text: "profileImage.alt" },
            { type: MetaTypeEnum.SingleImage, text: "profileImage.srcset" },
            { type: MetaTypeEnum.Pricing, text: "pricing" },
            { type: MetaTypeEnum.Link, text: "url" }
        ];

        let values = postFields.map(x => x.text);

        if (sourceType === DynamicFilterTypes.Meta) {
            let metaFields = this.getContentTypeMetaFields(metaType);
            values = metaFields;
        }
        else if (sourceType === DynamicFilterTypes.Spec) {
            let specFields = this.getContentTypeSpecFields(metaType);
            values = specFields;
        }

        return values
    }

    getBindingTypes(property?: PropertyDefinition): IDropdownOption[] {

        let options: IDropdownOption[] = [{ key: "", text: "Statik" }];

        if (this.props.node.getOption("meta")?.use) {
            options = [...options, { key: SchemaSourceTypes.Post, text: "İçerik (Post)" }, { key: SchemaSourceTypes.Meta, text: "Meta" }, { key: SchemaSourceTypes.Spec, text: "Spesifikasyon" },]
        }

        if (property && (property.mapping.type === PropertyMappingType.Array || property.mapping.type === PropertyMappingType.ArrayData || property.mapping.type === PropertyMappingType.ProductData)) {
            options = [...options, { key: SchemaSourceTypes.LocalData, text: "Özel Servis Sorgusu" }, { key: SchemaSourceTypes.LocalData, text: "İç Veri Sorgusu" }, { key: SchemaSourceTypes.Dynamics, text: "Dinamik Filtre" }]
        }

        return options;

    }

    renderBindingValue(item: IDynamicFilterParam, index: number) {
        switch (item.type) {
            case DynamicFilterTypes.Spec:
            case DynamicFilterTypes.Meta:
            case DynamicFilterTypes.Post:
                return (
                    <AutoComplete
                        placeholder="Filter yapılacak bir değer seçin"
                        selectedValue={item.ref as string || ""}
                        onSelected={(value) => {
                            this.onChangeParam("ref", value, index)
                        }}
                        disabled={!item.type}
                        filterData={[]}
                    />
                )
            default:
                return (
                    <TextField
                        placeholder="Filter yapılacak bir değer girin"
                        value={item.ref as string || ""}
                        onChange={(event: any) => {
                            this.onChangeParam("ref", event.target.value, index);
                        }}
                        disabled={!item.type}
                        className="custom-textfield"
                    />
                )
        }
    }

    renderBinding(item: IDynamicFilterParam, index: number) {
        return (
            <div className="col no-padding">
                <div className="binding row">
                    <Dropdown
                        options={filterTypes}
                        label="Filtre Doldurma Tipi"
                        className="custom-dropdown col-md-3"
                        calloutProps={{ className: "custom-dropdown-callout" }}
                        style={{ marginTop: "-1px" }}
                        selectedKey={item.type}
                        disabled={!item.name}
                        onChange={(_, option) => {
                            this.onChangeParamObject({
                                ...item,
                                type: option?.key as DynamicFilterTypes,
                                ref: ""

                            }, index)
                        }}
                    />
                    <div className="col no-padding">
                        <Label style={{ marginBottom: "1px !important" }}>Filtre Yapılacak Değer</Label>
                        {this.renderBindingValue(item, index)}
                    </div>
                </div>
            </div>
        )
    }

    renderFields() {
        return (
            <ComboBox
                label="Görüntülenecek Alanlar"
                className="custom-combobox"
                calloutProps={{ className: "custom-combobox-callout" }}
                options={this.getFieldValues().map((item) => ({ key: item as string, text: item }))}
                onChange={(ev, option) => {
                    const fields = _.cloneDeep(this.props.filter?.fields || []);
                    const findIndex = fields.findIndex(x => x === option?.key);
                    if (findIndex !== -1) {
                        fields.splice(findIndex, 1);
                    }
                    else {
                        fields.push(option?.key as string);
                    }
                    this.props.onChange({ ...this.props.filter, fields })
                }}
                multiSelect
                selectedKey={this.props.filter?.fields || []}
            />
        )
    }

    renderPagerOptions() {
        return (
            <div className="wizard-item mapping-list">
                {/* <div className="title">Sayfalama Ayarları
                    <div className="text">Verilerin sayfa başına veri sayısını ve hangi sayfadan başlayacağını belirleyebilirsiniz.</div>
                </div> */}
                <div className="wizard-item-content">
                    <div style={{ marginTop: 10 }} className="row">
                        <TextField
                            className="small-element custom-textfield col"
                            styles={{ root: { marginLeft: -15 } }}
                            label="Sayfa Başına Çekilecek Veri Sayısı"
                            type="number"
                            onChange={(event: any) => {
                                const findIndex = (this.props.filter.params || []).findIndex(x => x.name === "pageSize");
                                this.onChangeParamObject({
                                    name: "pageSize",
                                    type: DynamicFilterTypes.Static,
                                    ref: parseInt(event.target.value)
                                }, findIndex)
                            }}
                            value={this.getProperty("pageSize")?.ref as string || "0"}
                        />
                        <TextField
                            className="small-element custom-textfield col no-padding"
                            label="İçerikler Hangi Sayfadan Başlayacak?"
                            type="number"
                            onChange={(event: any) => {
                                const value = parseInt(event.target.value);
                                const findIndex = (this.props.filter.params || []).findIndex(x => x.name === "pageIndex");
                                this.onChangeParamObject({
                                    name: "pageIndex",
                                    type: DynamicFilterTypes.Static,
                                    ref: value > 0 ? value - 1 : 0
                                }, findIndex)
                            }}
                            value={(this.getProperty("pageIndex")?.ref as string) + 1 || "1"}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderOrders() {
        const orders = [
            ...this.props.filter.orders || [],
            {
                name: "",
                value: "asc"
            }
        ];
        return (
            <div style={{ paddingTop: 5 }} className="wizard-item-content">
                <div className="title">Sıralama Ayarları</div>
                {orders.map((item: IDynamicFilterOrder, index) => {
                    const isSpecification = item.name?.split(".")[0] === "spec" ? true : false;

                    return (
                        <div key={index} className="mapping row">
                            <ComboBox
                                className="small-element custom-combobox col no-padding"
                                calloutProps={{ className: "custom-combobox-callout" }}
                                label="Sıralama Yapılacak Alan"
                                selectedKey={item.name}
                                options={this.getOrderOptions()}
                                onChange={(_, opt) => {
                                    this.onChangeOrder("name", opt?.key as string, index)
                                }}
                                autoComplete="on"
                                allowFreeform
                            />
                            <ComboBox
                                className="small-element custom-combobox col"
                                calloutProps={{ className: "custom-combobox-callout" }}
                                label="Sıralama Tipi (Ascending - Descending)"
                                selectedKey={item.value}
                                disabled={isSpecification}
                                options={[
                                    {
                                        key: "asc",
                                        text: "Ascending (A-Z)"
                                    },
                                    {
                                        key: "desc",
                                        text: "Descending (Z-A)"
                                    }
                                ]}
                                onChange={(_, opt) => {
                                    this.onChangeOrder("value", opt?.key as string, index)
                                }}
                                autoComplete="on"
                                allowFreeform
                            />
                            <span
                                className={(index + 1) !== orders.length ? "remove active" : "remove"}
                                onClick={() => {
                                    const clonedOrders = _.cloneDeep(this.props.filter.orders);
                                    clonedOrders.splice(index, 1);
                                    this.props.onChange({ ...this.props.filter, orders: clonedOrders })
                                }}
                            >
                                <i className="icon-close1"></i>
                            </span>
                        </div>
                    )
                })}
            </div>
        )
    }

    renderSettings() {
        return (
            <div className="wizard-item mapping-list">
                <div style={{ paddingTop: 15 }} className="wizard-item-content">
                    <Dropdown
                        label="Filtre'yi Dolduracak İçerik Tipi"
                        className="custom-dropdown"
                        calloutProps={{ className: "custom-dropdown-callout" }}
                        options={(this.props.contentTypes || []).map((item) => ({ key: item.id as string, text: item.title }))}
                        onChange={(_, option) => {
                            const findIndex = (this.props.filter.params || []).findIndex(x => x.name === "contentTypeId");
                            this.onChangeParamObject({
                                name: "contentTypeId",
                                type: DynamicFilterTypes.ContentType,
                                ref: option?.key as string,
                            }, findIndex)
                        }}
                        selectedKey={this.getProperty("contentTypeId")?.ref || ""}
                    />
                    <div className="title">Filtre Öğeleri ve Özellikler</div>
                    {this.getParams().map((item: IDynamicFilterParam) => {
                        const index = (this.props.filter.params || []).findIndex(x => x.name === item.name);
                        return (
                            <div key={index} className="mapping row">
                                <ComboBox
                                    className="small-element custom-combobox col-md-3 no-padding"
                                    calloutProps={{ className: "custom-combobox-callout" }}
                                    label={`Filtrelenecek Alan Adı (${item.name || "-"})`}
                                    selectedKey={item.name}
                                    options={[]}
                                    onChange={(_, opt) => {
                                        this.onChangeParam("name", opt?.key as string, index)
                                    }}
                                    autoComplete="on"
                                    allowFreeform
                                />
                                <div className="col">
                                    {this.renderBinding(item, index)}
                                </div>
                                <span
                                    className={index !== 1 ? "remove active" : "remove"}
                                    onClick={() => {
                                        const clonedParams = this.getContentTypeId() ? _.cloneDeep([this.getContentTypeId() as IDynamicFilterParam, ...this.getParamsWithoutContentType()]) : _.cloneDeep(this.getParamsWithoutContentType());
                                        clonedParams.splice(index, 1);
                                        this.props.onChange({ ...this.props.filter, params: clonedParams })
                                    }}
                                >
                                    <i className="icon-close1"></i>
                                </span>
                            </div>
                        )
                    })}
                    <DefaultButton
                        className="custom-button small-button"
                        onClick={() => {
                            const clonedParams = this.getContentTypeId() ? _.cloneDeep([this.getContentTypeId() as IDynamicFilterParam, ...this.getParamsWithoutContentType()]) : _.cloneDeep(this.getParamsWithoutContentType());
                            clonedParams.push({
                                name: "",
                                type: "" as DynamicFilterTypes,
                                ref: ""
                            })
                            this.props.onChange({ ...this.props.filter, params: clonedParams })
                        }}
                        style={{ marginTop: 10 }}
                        text="Yeni Parametre"
                    />
                </div>
                {this.renderPagerOptions()}
                {this.renderFields()}
                {this.renderOrders()}
            </div>
        )
    }

    renderModal() {
        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Filtre Sihirbazı",
                    closeButtonAriaLabel: "Kapat",
                    subText:
                        "İgili özelliğin verilerini; meta, spesifikasyon ya da sorgular ile özel bir filtre oluşturarak doldurabilirsiniz. Eşleme yapmak işlemi için bir eşleme tipi ve değer karşılıklarını seçin."
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: false,
                    styles: { main: { maxWidth: "1100px !important", minWidth: "700px !important" } },
                }}
            >
                {this.renderSettings()}
            </Dialog>
        )
    }

    render() {

        if (!this.props.show) return null;

        return this.renderModal()
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import {
  PropertyDefinition,
  PropertyMappingType,
} from "app/library/layout-builder";
import { PopupProps } from "./types";

export class PopupDefinitions extends CmsReactComponent<PopupProps> {
  static defaultProps = {};

  static schemaName = "popup";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "title",
      text: "Başlık",
      mapping: {
        type: PropertyMappingType.String,
      },
    },
    {
      name: "content",
      text: "İçerik",
      mapping: {
        type: PropertyMappingType.Html,
      },
    },
    {
      name: "isDisableCookie",
      text: "Cookie ayarları devre dışı bırakılsın mı?",
      mapping: {
        type: PropertyMappingType.Boolean,
      },
    },
    {
      name: "isDark",
      text: "Koyu görünüm",
      mapping: {
        type: PropertyMappingType.Boolean,
      },
    },
  ];
}

import _ from "lodash";

import { convertToPureSchema, validateUrl } from "app/shared/functions";
import { MetaTypeEnum } from "app/shared";
import { IMetaLink } from "../content-type";
import { IPost, IPostMetaSpec, IPostt, PostEntity, PostMetaSpecEntity } from "./types"

/**
 * Post entity schema convert to model
 */
export function mapPostFromEntity(postEntity: PostEntity): IPost {
    return {
        id: postEntity.id,
        title: postEntity.title,
        contentType: postEntity.contentTypeId,
        url: postEntity.url,
        order: postEntity.order,
        initalUrl: postEntity.url,
        thumbnail: postEntity.profileImage,
        layout: postEntity.layoutId,
        language: postEntity.language || "en",
        relationId: postEntity.languageRelationId || "",
        keywords: postEntity.keywords,
        specFields: postEntity.spec,
        metaFields: mapPostMetaFromEntity(postEntity.metaFields),
        schema: postEntity.designSchemaAdmin,
        status: postEntity.status,
        queryIds: postEntity.queryIds,
        seo: postEntity.seo,
        integrationId: postEntity.integrationId,
        dynamics: postEntity.dynamics,
        isPrivate: postEntity.isPrivate,
        createdBy: postEntity.createdBy,
        updatedBy: postEntity.modifiedBy,
        createdAt: postEntity.createdOn || postEntity.CreatedOn,
        updatedAt: postEntity.modifiedOn || postEntity.ModifiedOn,
        searchArea : postEntity.searchArea || postEntity.searchArea,
    }
}

/**
 * Post model schema convert to entity
 */
export function mapPostFromModel(postModel: IPost): PostEntity {
    return {
        id: postModel.id || "",
        title: postModel.title,
        status: postModel.status,
        contentTypeId: postModel.contentType,
        url: postModel.url,
        order: postModel.order,
        queryIds: postModel.queryIds,
        profileImage: postModel.thumbnail,
        layoutId: postModel.layout,
        keywords: postModel.keywords,
        language: postModel.language || "en",
        languageRelationId: postModel.relationId || null,
        spec: postModel.specFields,
        integrationId: postModel.integrationId,
        metaFields: mapPostMetaFromModel(postModel.metaFields),
        designSchemaAdmin: postModel.schema,
        designSchemaPureUi: convertToPureSchema(postModel.schema),
        seo: postModel.seo,
        dynamics: postModel.dynamics,
        isPrivate: postModel.isPrivate,
        searchArea : postModel.searchArea ,
    }
}

/**
 * Meta entity schema convert to model
 */
export function mapPostMetaFromEntity(metaEntity: PostMetaSpecEntity[]): IPostMetaSpec[] {
    return (metaEntity || []).map(item => {
        let data = item.data;
        if (item.type === MetaTypeEnum.Link) {
            data = item.data as IMetaLink;
            const external = validateUrl(data.href) || data.href === "#" ? true : false;
            data.external = external;
        }
        return {
            type: item.type,
            name: item.name,
            contentType: item.contentType,
            data
        }
    })
}

/**
 * Meta model convert to entity schema
 */
export function mapPostMetaFromModel(metaModel: IPostMetaSpec[]): PostMetaSpecEntity[] {
    return (metaModel || []).map(item => {
        let data = _.cloneDeep(item.data);
        if (item.type === MetaTypeEnum.Link && typeof data?.external === "boolean") {
            delete data.external;
        }
        return {
            type: item.type,
            name: item.name,
            contentType: item.contentType,
            data
        }
    })
}
export function mapContractedInstitutionForExcel(entity: any) : IPostt{
    const columnTitles = entity.title;
    
    const metaFieldNames = entity?.metaFields?.map((metaField: any) => metaField?.data);

    return {
        [columnTitles]: metaFieldNames,
    }
  }
  

  
import React from 'react'
import { LayoutContainerDefinitions } from './LayoutContainerDefinitions'
import ComponentHeader from 'components/cms/ComponentHeader';

export default class LayoutContainerDesigner extends LayoutContainerDefinitions {

    renderInformation() {
        return (
            <div className="blank-wrapper">
                <div className="blank-box">
                    <i className="icon-t-column"></i>
                    <div className="title">Sayfa İçeriği</div>
                    <div className="sub-text">
                        Layout'un seçildiği herhangi bir içerik bu alanda gözükecektir.
                     </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderInformation()}
                <ComponentHeader
                    componentEntry={this.props.entry}
                    componentSchemaId={this.props.id as string}
                    componentIsNotEditable
                />
            </React.Fragment>
        )
    }
}

import { Component } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from 'office-ui-fabric-react';
import { ILinkWizard } from 'app/shared';
import PureLinkField from '../Fields/Pure/PureLinkField';

interface LinkWizardModalProps {
    show: boolean;
    data: ILinkWizard | null;
    contentType?: string;
    onSave: (data: ILinkWizard | null) => void;
    onSaveContentType?: (contentType: string) => void;
    onDismiss: () => void;
    language: string;
}

interface LinkWizardModalState {
    data: ILinkWizard | null;
}

export default class LinkWizardModal extends Component<LinkWizardModalProps, LinkWizardModalState> {

    state = {
        data: this.props.data || null
    }

    render() {
        if (!this.props.show) return null;
        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Link Sihirbazı",
                    closeButtonAriaLabel: 'Kapat',
                    subText: "Aşağıdan link tipinizi belirleyebilir, ve link ayarlamalarınızı yapabilirsiniz.",
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: false,
                    styles: { main: { maxWidth: "1000px !important" } }
                }}
            >
                <PureLinkField
                    anchor={this.state.data}
                    onChange={(data) => this.setState({ data })}
                    language={this.props.language}
                // onChangeContentType={(contentType: string) => this.props.onSaveContentType ? this.props.onSaveContentType(contentType) : {}}
                // contentType={this.props.contentType as string}
                />
                <DialogFooter>
                    <DefaultButton onClick={() => this.props.onDismiss()} text="İptal" />
                    <PrimaryButton onClick={() => this.props.onSave(this.state.data)}
                        text="Kaydet"
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}

import * as React from "react";
import { BaseFieldProps } from "./types";
import { PureDropdownField } from "./Pure/PureDropdownField";
import { HttpStatusCode } from 'app/shared';
import { ContentTypeService, mapContentTypeFromEntity } from "app/services/content-type";

interface ContentTypeFieldState {
    values: { key: string; text: string; }[];
}
// Kendisine gelen Property ismine ve değerine göre Content Type field render eder.
export default class ContentTypeField extends React.Component<BaseFieldProps, ContentTypeFieldState> {

    state = {
        values: []
    }

    async UNSAFE_componentWillMount() {
        const service = new ContentTypeService();
        const response = await service.getContentTypes({ page: 1, pageSize: 100, descending: false });
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response.data.attributes?.list || []).map(item => mapContentTypeFromEntity(item))
            const values = mapped.map(item => ({ key: item?.id as string, text: item.title }))
            this.setState({
                values
            })
        }
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        return (
            <PureDropdownField
                label="İçerik Tipi"
                value={this.props.value}
                onChange={value => this.props.onChange(value)}
                property={this.props.property}
                text={this.props.property.text as string}
                customOptions={this.state.values}
                description="Geçerli element'in hangi içerik tipine ait olduğunu belirleyin."
            />
        );
    }
}

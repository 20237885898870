import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

import { ColumnSettingsDefinition } from 'components/cms/definitions';

export class VisitingHoursDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "visiting-hours";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "days",
            description: "Visiting Hours Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [{
                    name: 'month',
                    text: 'Ay',
                    mapping: {
                        type: PropertyMappingType.String
                    }
                },
                {
                    name: 'date',
                    text: 'Tarih',
                    mapping: {
                        type: PropertyMappingType.String,
                    }
                },
                {
                    name: 'day',
                    text: 'Gün',
                    mapping: {
                        type: PropertyMappingType.String
                    }
                },
                {
                    name: 'hour',
                    text: 'Saat Aralığı',
                    mapping: {
                        type: PropertyMappingType.String
                    }
                }],
            },
            group: {
                key: "data",
                title: "Veriler",
                withObject: false,
            }
        },
        ...ColumnSettingsDefinition
    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: "month",
                text: "Ay",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "date",
                text: "Tarih",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "day",
                text: "Gün",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "hour",
                text: "Saat Aralığı",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
        ],
    };
}

import { Component } from 'react'
import { ICategory } from 'app/services/category';
import { ILayout } from 'app/services/layout';
import { ProfileImage } from 'app/shared';
import { PublishBar, CategoryBar, ArchiveBar, LayoutBar } from '.';
import ThumbnailBar from './ThumbnailBar';

export interface ILayoutBar {
    hide?: boolean;
    layout?: string;
    onChangeLayout?: (layout: string) => void;
    layoutData?: ILayout[];
}

export interface IThumbnailBar {
    hide?: boolean;
    thumbnail?: ProfileImage;
    onSave?: (data: ProfileImage) => void;
    onClickThumbnail?: () => void;
    onDeleteThumbnail?: () => void;
}

export interface IPublishBar {
    hide?: boolean;
    onSave?: (isDraft?: boolean) => void;
    onClickPreview?: () => void;
    onDelete?: () => void;
    showOrder?: boolean;
    order?: number;
    onChangeOrderIndex?: (order: number) => void;
    disabledSaveButton?: boolean;
    hideDeleteButton?: boolean;
    hidePreviewButton?: boolean;
    statusText?: string;
    buttonText?: string;
    userLabel?: string
    dateText?: string,
    publishDateText?: string,
    isEdit?: boolean,
    userName?: string,
    status?: number
    showIntegration?: boolean;
    integrationId?: string;
    onChangeIntegrationId?: (value: string) => void;
}

export interface IArchiveBar {
    hide?: boolean;
}

export interface ICategoryBar {
    hide?: boolean;
    categories?: ICategory[];
    value?: string;
    onChangeCategory?: (category: string | null) => void;
    onToggleModal?: () => void;
}

export interface ISidebar {
    publishBar?: IPublishBar;
    layoutBar?: ILayoutBar;
    thumbnailBar?: IThumbnailBar;
    archiveBar?: ILayoutBar;
    categoryBar?: ICategoryBar;
}

export default class Sidebar extends Component<ISidebar> {
    render() {
        return (
            <aside className="page-sidebar">
                <PublishBar
                    {...this.props.publishBar as IPublishBar}
                />
                {!this.props.categoryBar?.hide && (
                    <CategoryBar {...this.props.categoryBar as ICategoryBar} />
                )}
                {(!this.props.archiveBar?.hide && (
                    <ArchiveBar {...this.props.archiveBar as IArchiveBar} />
                ))}
                {!this.props.layoutBar?.hide && (
                    <LayoutBar {...this.props.layoutBar as ILayoutBar} />
                )}
                {!this.props.thumbnailBar?.hide && (
                    <ThumbnailBar {...this.props.thumbnailBar as IThumbnailBar} />
                )}
            </aside>
        )
    }
}

// import React from "react";
import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    PropertyDefinition
} from "app/library/layout-builder";

export class ProfileDefinitions extends CmsReactComponent<CmsReactComponentProps> {

    static schemaName = "profile";

    static propertyDefinitions: PropertyDefinition[] = [];

}

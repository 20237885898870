import { ColumnProps } from "./types";
import {
  PropertyDefinition,
} from "app/library/layout-builder";
import { ColumnPropertyDefinitions } from '../definitions';
import { CmsReactComponent } from "../CmsReactComponent";

export class ColumnDefinitions extends CmsReactComponent<ColumnProps> {
  static schemaName = "column";

  static propertyDefinitions: PropertyDefinition[] = ColumnPropertyDefinitions

}

import { Component } from "react";
import { Modal, Icon, DefaultButton } from "office-ui-fabric-react/lib/index";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { hideMessage } from "app/redux/system/actions";
import { RootState } from "app/redux/reducer";
import { MessageType } from "app/redux/system/types";

function mapStateToProps(state: RootState) {
	return {
		...state.system.message
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		...bindActionCreators({
			hideMessage
		}, dispatch)
	};
}

class MessageBox extends Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> {

	static defaultProps = {
		show: false,
	};

	renderTypeStringByNumber(messageType: number) {
		switch (messageType) {
			case MessageType.ERROR:
				return "error";
			case MessageType.SUCCESS:
				return "success";
			case MessageType.WARNING:
				return "warning";
			default:
				return "";
		}
	}

	render() {
		const { show, messageType, hideMessage, messageTitle, messageContent } = this.props;
		if (!show) {
			return null;
		}
		return (
			<Modal
				className={`warning ${this.renderTypeStringByNumber(messageType as number)}`}
				isOpen={true}
				onDismiss={() => hideMessage()}
				styles={{
					layer: {
						zIndex: "99999999 !important"
					}
				}}
			>
				<div className="modal-header row">
					<div className="title">
						{messageTitle}
					</div>
					<span onClick={() => hideMessage()} className="close">
						<Icon iconName="ChromeClose" />
					</span>
				</div>
				<div style={{ paddingBottom: 0 }} className="description modal-content">
					{messageContent}
					{this.props.onRender ? this.props.onRender() : null}
					{!this.props.hideFooter && (
						<div className="modal-indicators">
							<DefaultButton
								style={{ marginTop: 15 }}
								text="Pencereyi Kapat"
								onClick={() => hideMessage()}
							/>
						</div>
					)}
				</div>
			</Modal>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);
import { ContainerEntry, SchemaNode, SchemaObject } from 'app/library/layout-builder';
import { ICategory } from 'app/services/category';
import { IUser } from 'app/services/user';
import { ICustomServiceWizard, IPost } from 'app/services/post';
import { IQueryBuilder } from 'app/services/query-builder';
import { IDynamicFilter } from 'app/shared';
import { IBucket } from 'app/services/configuration';
import { IRole } from 'app/services/identity';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SET_MODAL_ERRORS = 'SET_MODAL_ERRORS';

export type ModalNames = "properties" | "bucket" | "localDataWizard" | "customServiceWizard" | "category" | "user" | "role";

interface ToggleModalAction {
	type: typeof TOGGLE_MODAL;
	id: ModalNames;
	data: any;
	callback?: () => void;
}

interface SetModalDataAction {
	type: typeof SET_MODAL_DATA;
	id: ModalNames;
	data: any;
}

interface SetModalErrorsAction {
	type: typeof SET_MODAL_ERRORS;
	id: ModalNames;
	errors: any;
}

interface ModalInnerState<T> {
	show: boolean;
	data: T;
	errors?: T;
	callback?: (T?: any) => void;
}

export interface PropertiesModalData {
	node: SchemaNode;
	entry: ContainerEntry;
	schema: SchemaObject[];
	dynamics: IDynamicFilter[];
}

export interface LocalDataWizardModalData {
	query: IQueryBuilder;
	node: SchemaNode;
	callback?: (data: IQueryBuilder) => void;
}

export interface ProductPickerModalData {
	contentType: string;
	contentTypeTitle?: string;
	productId: string;
	productTitle: string;
	post: IPost;
	forceClose?: boolean;
}

export interface ModalState {
	properties: ModalInnerState<PropertiesModalData>;
	localDataWizard: ModalInnerState<LocalDataWizardModalData>;
	customServiceWizard: ModalInnerState<ICustomServiceWizard>;
	category: ModalInnerState<ICategory>;
	user: ModalInnerState<IUser>;
	role: ModalInnerState<IRole>;
	bucket: ModalInnerState<IBucket>;
}

export type ModalActionTypes = ToggleModalAction | SetModalDataAction | SetModalErrorsAction;
import * as React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { bindActionCreators, Dispatch } from 'redux';
import cookie from "react-cookies";
import { connect } from 'react-redux';
import { History } from "history";

import { RootState } from 'app/redux/reducer';
import { setDefaultLanguage, tokenControl } from "app/redux/system/actions"

import LogoIcon from "assets/images/logo-icon.png";
import { signOut } from "app/services/identity/function";
import { ComboBox } from "office-ui-fabric-react";
import { LANGS } from "app/shared/constants/langs";
import { langs } from "./TranslatorBar/constants";

interface OwnProps {
    history: History<any>,
}

function mapStateToProps(state: RootState) {
    const userInformation = cookie.load("userInformation");

    return {
        language: state.system.language,
        defaultLanguage: state.system.defaultLanguage,
        userInfo: userInformation ? userInformation : state.system.userInfo,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            tokenControl,
            setDefaultLanguage
        }, dispatch)
    };
}

export interface HeaderState {
    showProfile: boolean
}

type HeaderProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            showProfile: false,
        };
    }

    renderProfile() {
        const { userInfo } = this.props;
        let fullName: string = userInfo.fullName ? userInfo.fullName.slice(0, 2).toUpperCase() : "";

        return (
            <div className="item header-profile">
                <div
                    onClick={() =>
                        this.setState(prevState => ({
                            showProfile: !prevState.showProfile
                        }))
                    }
                    className="info"
                >
                    <div className="email">{userInfo && userInfo.username}</div>
                    <div className="name">{userInfo && userInfo.fullName}</div>
                </div>
                <span className="profile-icon" onClick={() =>
                    this.setState(prevState => ({
                        showProfile: !prevState.showProfile
                    }))
                }>
                    {fullName}
                </span>
                <div className={this.state.showProfile ? "profile-wrapper active" : "profile-wrapper"}>
                    <div className="profile-indicator-list">
                        <div
                            onClick={() => {
                                this.setState({ showProfile: false }, () => {
                                    signOut();
                                });
                            }}
                            className="item">
                            <span>
                                <i className="icon-signout" />
                                Çıkış Yap
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderDefaultLanguagePicker() {
        return (
            <div className="site-picker item row">
                <span className="text">Varsayılan İçerik Dili</span>
                <ComboBox
                    className="dark custom-combobox"
                    calloutProps={{ className: "custom-combobox-callout" }}
                    options={_.keys(langs).map(x => ({ key: x, text: `${LANGS[x].name} (${langs[x].nativeName})` }))}
                    allowFreeform
                    autoComplete="on"
                    selectedKey={this.props.defaultLanguage}
                    onChange={(_, option) => {
                        if (option && option.key) {
                            window.location.href = "/";
                            this.props.setDefaultLanguage(option.key as string);
                        }
                    }}
                />
            </div>
        )
    }

    render() {
        return (
            <header className="row no-margin">
                <figure className="logo">
                    <Link to="/" className="link">
                        <img style={{ width: 25, marginTop: -5, marginBottom: -10 }} src={LogoIcon} alt="Saha Expo" />
                        <strong style={{ marginLeft: 10, fontSize: 13, marginTop: 5 }}>İçerik Yönetim Sistemi</strong>
                        <span className="badge" onClick={() => window.open(process.env.REACT_APP_WEB, "_blank")}>
                            Siteyi Görüntüle
                        </span>
                    </Link>
                </figure>
                <div className="header-tools m-left-auto row">
                    {this.renderDefaultLanguagePicker()}
                    {this.renderProfile()}
                </div>
            </header>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);


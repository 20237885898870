export const SRC_SIZES = [
    {
        key: "1024px",
        text: "1024px (Tablet)"
    },
    {
        key: "768px",
        text: "768px (Small Tablet)"
    },
    {
        key: "640px",
        text: "640px (Mobile)"
    },
    {
        key: "480px",
        text: "480px (Small Mobile)"
    }
]
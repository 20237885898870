import { ThunkAction } from "redux-thunk";
import { RootState } from "app/redux/reducer";
import { Action } from "redux";
import { toggleConfirmDialog, toggleLoader } from "app/redux/system/actions";

import { initialPageData } from "app/redux/page/actions";
import { ConfigurationService } from "./service";
import { IThemeConfig, IThemeSettings, ThemeSettingsEntity } from "./types";
import { mapConfigurationFromEntity, mapConfigurationFromModel } from "./mapping";
import { HttpStatusCode } from "app/shared";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getConfiguration(notFill?: boolean): ThunkResult<Promise<IThemeSettings | null>> {
    return async (dispatch, getState) => {
        const service = new ConfigurationService();
        dispatch(toggleLoader());
        const response = await service.getConfiguration(getState().system.defaultLanguage);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapConfigurationFromEntity(response.data.attributes["configuration"] || {});
            if (!notFill) {
                dispatch(initialPageData<IThemeSettings>(mapped));
            }
            return mapped;
        }
        if (response.status === HttpStatusCode.NOT_FOUND) {
            dispatch(initialPageData({}))
        }
        // dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return null;
    };
}

export function getConfigurationByLanguage(request: IThemeConfig, language: string, isOverride?: boolean): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new ConfigurationService();
        dispatch(toggleLoader());
        const response = await service.getConfiguration(isOverride ? getState().system.defaultLanguage : language);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            const mapped = response.data.attributes["configuration"] as ThemeSettingsEntity || {};
            const data = { config: { ...request, lang: language } as IThemeConfig, language }

            dispatch(initialPageData<IThemeSettings>(mapConfigurationFromEntity({ language, config: { admin: { ...mapped.config.admin, lang: language }, ui: { ...mapped.config.ui, lang: language } } })));
            if (isOverride) {
                dispatch(updateConfiguration(data));
            }
            return true;
        }
        else {
            dispatch(toggleConfirmDialog(true, {
                title: "Uyarı!",
                text: `Geçerli dil'e (${language}) ait bir tema ayarı bulunamadı. Varsayılan dil üzerinden seçtiğiniz dile göre tema ayarı oluşturulsun mu?`,
                button: {
                    approveText: "Varsayılan Dil'den Oluştur",
                },
                callback: () => {
                    const data = { config: { ...request, lang: language } as IThemeConfig, language }
                    dispatch(createConfiguration(data));
                    dispatch(initialPageData(data));
                    return true;
                }
            }))
        }
        return false;
    };
}

export function createConfiguration(request: IThemeSettings): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new ConfigurationService();
        dispatch(toggleLoader());
        const response = await service.createConfiguration(mapConfigurationFromModel({ ...request, language: request.language || "en" }));
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        return false;
    };
}

export function updateConfiguration(request: IThemeSettings): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new ConfigurationService();
        dispatch(toggleLoader());
        const response = await service.updateConfiguration(mapConfigurationFromModel({ ...request, language: request.language || "en" }));
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        return false;
    };
}

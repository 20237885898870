import ComponentDescription from 'components/customs/ComponentDescription';
import ComponentHeader from 'components/cms/ComponentHeader';
import { ProfileDefinitions } from './ProfileDefinitions';

export class Profile extends ProfileDefinitions {

    render() {
        const { className, designing } = this.props;

        if (this.props.hideRuntime && !this.props.designing) return null;

        return (
            <div
                data-schema-id={this.props.id}
                className={["fa-Button disable-inside fa-draggable", designing && "designing", className].filter(e => !!e).join(" ")}>
                {designing && (
                    <ComponentHeader
                        componentEntry={this.props.entry}
                        componentSchemaId={this.props.id as string}
                        componentIsNotEditable
                    />
                )}
                <ComponentDescription
                    node={this.props.schemaNode}
                    entry={this.props.entry}
                />
            </div>
        )
    }
}

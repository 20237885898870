import { SectionDefinitions } from "./SectionDefinitions";
import SectionDesigner from "./SectionDesigner";

export class Section extends SectionDefinitions {
    render() {

        const title = this.props.schemaNode?.getProp("title");

        return (
            <div
                data-schema-id={this.props.id}
                className={`fa-draggable fa-Section row ${this.props.designing ? "designing" : ""}`}
                style={{
                    minHeight: this.props.designing ? "300px" : "auto",
                    position: "relative",
                }}
            >
                {this.props.designing ? (
                    <SectionDesigner {...this.props} title={title} />
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}

export const DESIGNER_COMPONENTS: IDesignerComponents[] = [
    {
        group: "layout",
        name: "Layout",
        sortableGroup: "layout",
        items: [
            {
                name: "Row",
                icon: "container",
                schema: "row",
            },
            {
                name: "Container",
                icon: "container",
                schema: "container",
            },
            {
                name: "Page Content",
                icon: "container",
                schema: "page-content",
            },
            {
                name: "Pure Content",
                icon: "container",
                schema: "pure-content",
            },
            {
                name: "12/12",
                icon: "md-12",
                schema: "column",
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
                size: 12,
            },
            {
                name: "9/12",
                icon: "md-9",
                schema: "column",
                size: 9,
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
            },
            {
                name: "8/12",
                icon: "md-8",
                schema: "column",
                size: 8,
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
            },
            {
                name: "6/12",
                icon: "md-6",
                schema: "column",
                size: 6,
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
            },
            {
                name: "4/12",
                icon: "md-4",
                schema: "column",
                size: 4,
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
            },
            {
                name: "3/12",
                icon: "md-3",
                schema: "column",
                size: 3,
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
            },
            {
                name: "Section",
                icon: "section",
                schema: "section",
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
            },
            {
                name: "Popup",
                icon: "section",
                schema: "popup",
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
            },
            {
                name: "Scrollbar",
                icon: "section",
                schema: "scrollbar",
                description: "Yalnızca <strong>genel içerik, layout container</strong> ya da <strong>column</strong> sürükleyebilirsiniz",
            }
        ],
    },
    {
        group: "content",
        name: "Genel İçerikler",
        sortableGroup: "droppable",
        items: [
            {
                name: "Accordion",
                icon: "button",
                schema: "accordion",
                description: "Accordion componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Button",
                icon: "button",
                schema: "button",
                description: "Button componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Title",
                icon: "heading",
                schema: "heading",
                description: "Title componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Page Header",
                icon: "heading",
                schema: "page-header",
                description: "Page Header componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Paragraph",
                icon: "paragraph",
                schema: "paragraph",
                description: "Paragraph componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Icon",
                icon: "icon",
                schema: "icon",
                description: "Icon componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Icon List",
                icon: "iconlist",
                schema: "icon-list",
                description: "Icon List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "HTML",
                icon: "html",
                schema: "html",
                description: "HTML componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "CSS Style",
                icon: "button",
                schema: "style",
                description: "CSS Style componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Image",
                icon: "image",
                schema: "image",
                description: "Image componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Photo Gallery",
                icon: "fancybox",
                schema: "photo-gallery",
                description: "Photo Gallery componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Video",
                icon: "video",
                schema: "video",
                description: "Video componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Iframe",
                icon: "iframe",
                schema: "iframe",
                description: "Iframe componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Seperator",
                icon: "horizantalrule",
                schema: "horizontal-rule",
                description: "Seperator componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Slideshow",
                icon: "slideshow",
                schema: "slideshow",
                description: "Slideshow componentinin açıklaması bulunmamaktadır."
            },
        ],
    },
    {
        group: "custom",
        name: "Özel Bileşenler",
        sortableGroup: "droppable",
        items: [
            {
                name: "Main Slider Show",
                icon: "slideshow",
                schema: "main-slideshow",
                description: "Main Slider Show componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Header",
                icon: "header",
                schema: "header",
                description: "Header componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Footer",
                icon: "footer",
                schema: "footer",
                description: "Footer componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Card",
                icon: "card",
                schema: "card",
                description: "Card componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Card List",
                icon: "cardlist",
                schema: "card-list",
                description: "Card List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Card Slider",
                icon: "slideshow",
                schema: "card-slider",
                description: "Card Slider componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Media List",
                icon: "iconlist",
                schema: "media-list",
                description: "Media List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Image Card",
                icon: "fancybox",
                schema: "image-card",
                description: "Image Card componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Image Card List",
                icon: "fancybox",
                schema: "image-card-list",
                description: "Image Card List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "News",
                icon: "fancybox",
                schema: "news",
                description: "News componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Redirection",
                icon: "button",
                schema: "redirect",
                description: "Redirection componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Home About Us",
                icon: "section",
                schema: "home-about-us",
                description: "About Us componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Home Saha Match",
                icon: "section",
                schema: "home-saha-match",
                description: "Saha Match componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Home Virtual Exhibition",
                icon: "section",
                schema: "home-virtual-exhibition",
                description: "Virtual Exhibition componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Home Why Exhibit",
                icon: "section",
                schema: "home-why-exhibit",
                description: "Why Exhibit componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Home Activities",
                icon: "section",
                schema: "home-activities",
                description: "Activities componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Home Contact Us",
                icon: "section",
                schema: "home-contact-us",
                description: "Contact Us componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Home Main Sponsor",
                icon: "section",
                schema: "home-main-sponsor",
                description: "Main Sponsor componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Home Highlights",
                icon: "section",
                schema: "home-highlights",
                description: "Highlights componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Hero",
                icon: "card",
                schema: "hero",
                description: "Hero componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Exhibition Calendar",
                icon: "card",
                schema: "exhibition-calendar",
                description: "Exhibition Calendar componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Fair Session",
                icon: "card",
                schema: "fair-session",
                description: "Fair Session componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Signature Event",
                icon: "card",
                schema: "signature-event",
                description: "Signature Event componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Company Presentation",
                icon: "card",
                schema: "company-presentation",
                description: "Company Presentation componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Stand Enquiry Form",
                icon: "card",
                schema: "stand-enquiry-form",
                description: "Stand Enquiry Form componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Hotel List",
                icon: "card",
                schema: "hotel-list",
                description: "Hotel List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Material List",
                icon: "card",
                schema: "material-list",
                description: "Material List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Exhibitor List",
                icon: "card",
                schema: "exhibitor-list",
                description: "Exhibitor List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Exhibitor Detail",
                icon: "card",
                schema: "exhibitor-detail",
                description: "Exhibitor Detail componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Visiting Hours",
                icon: "card",
                schema: "visiting-hours",
                description: "Visiting Hours componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Venue List",
                icon: "card",
                schema: "venue-list",
                description: "Venue List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Gallery Slideshow",
                icon: "card",
                schema: "gallery-slideshow",
                description: "Gallery Slideshow componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Presentation Form",
                icon: "card",
                schema: "presentation-form",
                description: "Presentation Form componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Supporter Form",
                icon: "card",
                schema: "supporter-form",
                description: "Supporter Form componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Supporter List",
                icon: "card",
                schema: "supporter-list",
                description: "Supporter List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Supporter List for All",
                icon: "card",
                schema: "supporter-list-for-all",
                description: "Supporter List for All componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Supporter List with Slider",
                icon: "card",
                schema: "supporter-list-with-slider",
                description: "Supporter List with Slider componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Sponsor List",
                icon: "card",
                schema: "sponsor-list",
                description: "Sponsor List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Sponsorship List for All",
                icon: "card",
                schema: "sponsorship-list-for-all",
                description: "Sponsorship List for All componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Sponsorship List for Press",
                icon: "card",
                schema: "sponsorship-list-for-press",
                description: "Sponsorship List for Press componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Sponsorship List with Tabs",
                icon: "card",
                schema: "sponsorship-list-with-tabs",
                description: "Sponsorship List with Tabs componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Sponsor List with Tabs",
                icon: "card",
                schema: "sponsor-list-with-tabs",
                description: "Sponsor List with Tabs componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Contact Form",
                icon: "card",
                schema: "contact-form",
                description: "Contact Form componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Press List",
                icon: "card",
                schema: "press-list",
                description: "Press List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Features",
                icon: "card",
                schema: "features",
                description: "Features componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Breadcrumbs",
                icon: "card",
                schema: "breadcrumbs",
                description: "Breadcrumbs componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Photo Gallery with Tabs",
                icon: "card",
                schema: "photo-gallery-with-tabs",
                description: "Photo Gallery with Tabs componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Hall List",
                icon: "card",
                schema: "hall-list",
                description: "Hall List componentinin açıklaması bulunmamaktadır."
            },
            {
                name: "Special Press News",
                icon: "card",
                schema: "special-press-news",
                description: "Special Press News componentinin açıklaması bulunmamaktadır."
            }
        ],
    }
];

export interface IDesignerItems {
    name: string;
    icon: string;
    schema: string;
    size?: number,
    description?: string
}

export interface IDesignerComponents {
    group: string;
    name: string;
    sortableGroup: string;
    items: IDesignerItems[];
}
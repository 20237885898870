import { DefaultButton } from 'office-ui-fabric-react';
import { Component } from 'react';
import { MetaTypeEnum, ProfileImage } from 'app/shared';
import _ from "lodash";
import { ReactSortable } from "react-sortablejs";
import { BaseMetaFieldsProps } from './types';
import { IMetaImage } from 'app/services/content-type';
import { FileTypes } from 'app/services/file-manager';
import { validateUrl } from 'app/shared/functions';
import { ImageSettingsModal } from '../../Modals';
import FileManager from '../../FileManager/FileManager';
import { getFilePath } from 'app/utils/service';

import ImageVideo from "assets/images/type-video.jpg";

interface IMetaMultiImageState {
    showModal: boolean;
    showImageSettingsModal: number;
}

export default class MetaMultiImage extends Component<BaseMetaFieldsProps<IMetaImage[]>, IMetaMultiImageState> {

    state = {
        showModal: false,
        showImageSettingsModal: -1
    }

    getFileText() {
        if (this.props.type === MetaTypeEnum.MultiImage)
            return "Görsel / Görseller"
        else
            return "Video / Videolar"
    }

    getFileSimpleText() {
        if (this.props.type === MetaTypeEnum.MultiImage)
            return "Görsel"
        else
            return "Video"
    }

    renderFileByType(item: IMetaImage) {
        const file = item.src || "";
        const splitted = file?.split(".");
        const fileSource = validateUrl(file) ? file : (getFilePath() + file + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=200&height=200" : ""));
        if (this.props.type === MetaTypeEnum.MultiImage)
            return <img src={fileSource} alt={item.alt} />
        else
            return <img src={ImageVideo} alt={item.alt} />
    }

    renderImageSettingsModal(item: ProfileImage, index: number) {
        return (
            <ImageSettingsModal
                show={this.state.showImageSettingsModal === index ? true : false}
                data={item}
                onSave={(data) => {
                    const list = _.cloneDeep(this.props.meta.data || []);
                    if (list[index]) {
                        list[index] = data as IMetaImage;
                        this.props.onChange(list);
                    }
                }}
                onDismiss={() => this.setState({ showImageSettingsModal: -1 })}
            />
        )
    }

    render() {
        return (
            <div className="meta-file">
                <FileManager
                    show={this.state.showModal}
                    onDismiss={() => this.setState({ showModal: false })}
                    onSelectedFiles={(files) => {
                        const selectedFiles = files.map(x => ({ src: x.fileName, alt: x.fileName, title: x.fileName }))
                        console.log("selectedFiles", selectedFiles);
                        this.props.onChange(selectedFiles);
                    }}
                    activeFiles={(this.props.meta.data || []).map(x => ({
                        id: "",
                        fileName: x.src.replace(getFilePath(), "") || "",
                        fileExtension: "",
                        fileSize: ""
                    }))}
                    multipleSelection={true}
                    fileType={this.props.type === MetaTypeEnum.MultiImage ? FileTypes.Image : FileTypes.Video}
                />
                <DefaultButton
                    iconProps={{ iconName: "Touch" }}
                    text={this.getFileText() + " Seç"}
                    styles={{
                        root: {
                            fontSize: "10.5px !important",
                            background: "#fff"
                        }
                    }}
                    onClick={() => {
                        this.setState({
                            showModal: true
                        })
                    }}
                />
                <div className="file-list multi row">
                    {this.props.meta.data && this.props.meta.data.length > 0 ? (
                        <ReactSortable
                            list={(this.props.meta.data || []).map((item, index) => ({ ...item, id: index })) || []}
                            setList={(newState) => this.props.onChange(newState)}
                            animation={200}
                            className="row"
                            style={{ width: "100%" }}
                        >
                            {this.props.meta.data.map((item, index) => (
                                <div key={index} className="file-item col-md-2">
                                    {item.src && (
                                        <div className="file-item-wrapper">
                                            <div onClick={() => this.setState({ showImageSettingsModal: index })} className="edit no-margin">
                                                <i className="icon-edit"></i>
                                            </div>
                                            {this.renderImageSettingsModal(item as ProfileImage, index)}
                                            {this.renderFileByType(item)}
                                            <div className="title">
                                                {item?.alt}
                                            </div>
                                            <div onClick={() => {
                                                const list = _.cloneDeep(this.props.meta.data || []);
                                                list.splice(index, 1);
                                                this.props.onChange(list);
                                            }} className="remove">
                                                <i className="icon-delete"></i>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </ReactSortable>
                    ) : (
                        <div className="not-found row">
                            <i className="icon-info"></i>
                                Herhangi bir {this.getFileSimpleText().toLowerCase()} eklenmemiştir.
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

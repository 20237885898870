import { api } from 'app/utils/api';
import { InventoryClient } from 'app/library/client';
import { ResponseInventoryContentBase, ResponseInventoryListBase } from "app/shared";
// import { HttpStatusCode } from 'app/shared';
import { IUser, IUserFilter } from './types';
import { convertToQueryString } from 'app/shared/functions';
import { getEndpoint } from 'app/utils/service';

export class UserService {

    /** It get all Users */
    async getUsers(options: IUserFilter): Promise<ResponseInventoryListBase<IUser[]>> {
        return InventoryClient.getClient().get(getEndpoint(api.prefixes.user.list) + `?${convertToQueryString(options)}`);
    }

    /** It get spesific User by User id */
    async getUser(userId: string): Promise<ResponseInventoryContentBase<IUser>> {
        return InventoryClient.getClient().get(getEndpoint(api.prefixes.user.detail) + userId);
    }

    /** It creates User by request */
    async createUser(request: IUser): Promise<ResponseInventoryContentBase<boolean>> {
        return InventoryClient.getClient().post(getEndpoint(api.prefixes.user.list), request);
    }

    /** It updates spesific User by id */
    async updateUser(request: IUser): Promise<ResponseInventoryContentBase<boolean>> {
        return InventoryClient.getClient().put(getEndpoint(api.prefixes.user.list), request);
    }

    /** It deletes spesific User by id */
    async deleteUser(id: string): Promise<ResponseInventoryContentBase<boolean>> {
        return InventoryClient.getClient().delete(getEndpoint(api.prefixes.user.main), { id });
    }
}

import { DefaultButton } from "office-ui-fabric-react";
import * as React from "react";
import LinkWizardModal from "../Modals/LinkWizardModal";
import { BaseFieldProps } from './types';

interface LinkFieldState {
    showModal: boolean;
}

export default class LinkField extends React.Component<BaseFieldProps, LinkFieldState> {

    state = {
        showModal: false
    }

    renderModal() {
        return (
            <LinkWizardModal
                show={this.state.showModal}
                data={this.props.value}
                onSave={(data) => {

                    this.setState({
                        showModal: false
                    }, () => {
                        this.props.onChange(data)
                    })
                }}
                onDismiss={() => {
                    this.setState({
                        showModal: false
                    })
                }}
                language={this.props.language}
            />
        )
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        return (
            <div className="property-item row">
                {this.renderModal()}
                <div className="property-text">
                    <div className="title">{this.props.property.text || "Link Sihirbazı"}</div>
                    <div className="sub-title">Sağ taraftan link sihirbazını açarak, link yönetimlerini yapabilirsiniz.</div>
                </div>
                <div className="col">
                    <DefaultButton
                        iconProps={{ iconName: "Touch" }}
                        className="custom-button"
                        text="Link Sihirbazını Aç"
                        onClick={() => {
                            this.setState({
                                showModal: true
                            })
                        }}
                    />
                </div>
            </div>

        );
    }
}

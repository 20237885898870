import { Component } from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { IMetaFile } from 'app/services/content-type';
import { FileTypes } from 'app/services/file-manager';
import { MetaTypeEnum, ProfileImage } from 'app/shared';
import { validateUrl } from 'app/shared/functions';
import FileManager from '../../FileManager/FileManager';
import { ImageSettingsModal } from '../../Modals';
import { BaseMetaFieldsProps } from './types';
import { getFilePath } from 'app/utils/service';

import ImageVideo from "assets/images/type-video.jpg";


interface IMetaSingleState {
    showModal: boolean;
    showImageSettingsModal: boolean;
}

export default class MetaSingleFile extends Component<BaseMetaFieldsProps<IMetaFile>, IMetaSingleState> {

    state = {
        showModal: false,
        showImageSettingsModal: false
    }

    getFileText() {
        if (this.props.type === MetaTypeEnum.SingleImage)
            return "Görsel"
        else
            return "Video"
    }

    renderFileByType() {
        const file = this.props.meta.data ? this.props.meta.data?.src : "";
        const splitted = file?.split(".");
        const fileSource = validateUrl(file) ? file : (getFilePath() + file + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=200&height=200" : ""));
        if (this.props.type === MetaTypeEnum.SingleImage)
            return <img src={fileSource} alt={this.props.meta.data?.alt} />
        else
            return <img src={ImageVideo} alt={this.props.meta.data?.alt} />
    }

    renderImageSettingsModal() {
        return (
            <ImageSettingsModal
                show={this.state.showImageSettingsModal}
                data={this.props.meta.data as ProfileImage}
                onSave={(data) => this.props.onChange(data as ProfileImage)}
                onDismiss={() => this.setState({ showImageSettingsModal: false })}
            />
        )
    }

    render() {
        return (
            <div className="meta-file">
                <FileManager
                    show={this.state.showModal}
                    onDismiss={() => this.setState({ showModal: false })}
                    onSelectedFile={(file) => {
                        this.props.onChange({ src: file.fileName, alt: file.fileName, title: file.fileName })
                    }}
                    activeFile={{
                        id: "",
                        fileName: this.props.meta.data?.src || "",
                        fileExtension: "",
                        fileSize: ""
                    }}
                    fileType={this.props.type === MetaTypeEnum.SingleImage ? FileTypes.Image : FileTypes.Video}
                />
                <DefaultButton
                    iconProps={{ iconName: "Touch" }}
                    text={this.getFileText() + " Seç"}
                    styles={{
                        root: {
                            fontSize: "10.5px !important",
                            background: "#fff"
                        }
                    }}
                    onClick={() => {
                        this.setState({
                            showModal: true
                        })
                    }}
                />
                <div className="file-list row">
                    <div className="file-item">
                        {this.props.meta.data?.src ? (
                            <div className="file-item-wrapper">
                                <div onClick={() => this.setState({ showImageSettingsModal: true })} className="edit">
                                    <i className="icon-edit"></i>
                                </div>
                                {this.renderImageSettingsModal()}
                                {this.renderFileByType()}
                                <div className="title">
                                    {this.props.meta.data?.alt}
                                </div>
                                <div onClick={() => this.props.onChange({ src: "", alt: "" })} className="remove">
                                    <i className="icon-delete"></i>
                                </div>
                            </div>
                        ) : (
                            <div className="not-found row">
                                <i className="icon-info"></i>
                                        Herhangi bir {this.getFileText().toLowerCase()} eklenmemiştir.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { ExhibitionCalendarProps } from "./types";
import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";

export class ExhibitionCalendarDefinitions extends CmsReactComponent<ExhibitionCalendarProps> {
  static defaultProps = {};

  static schemaName = "exhibition-calendar";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "title",
      mapping: {
        type: PropertyMappingType.String,
      },
      text: "Başlık",
    },
    {
      name: "days",
      mapping: {
        type: PropertyMappingType.ArrayData,
        keys: [
          {
            name: "title",
            mapping: {
              type: PropertyMappingType.String,
            },
            text: "Başlık",
          },
          {
            name: "date",
            mapping: {
              type: PropertyMappingType.String,
            },
            text: "Tarih",
          },
          {
            name: "day",
            mapping: {
              type: PropertyMappingType.String,
            },
            text: "Gün",
          },
          {
            name: "times",
            mapping: {
              type: PropertyMappingType.ArrayData,
              keys: [
                {
                  name: "title",
                  mapping: {
                    type: PropertyMappingType.String,
                  },
                  text: "Başlık",
                },
                {
                  name: "description",
                  mapping: {
                    type: PropertyMappingType.String,
                  },
                  text: "Açıklama",
                },
                {
                  name: "place",
                  mapping: {
                    type: PropertyMappingType.String,
                  },
                  text: "Yer",
                },
              ]
            },
            default: []
          }
        ]
      },
      default: []
    }
  ]
}

import { Component } from 'react'
import _ from "lodash";
import { Checkbox } from 'office-ui-fabric-react'

import FormAccordion from '../FormAccordion'
import { ICategoryBar } from './Sidebar'
import { ICategory } from 'app/services/category';

interface ICategoryBarState {
	activeAccordions: string[];
}

export default class CategoryBar extends Component<ICategoryBar, ICategoryBarState> {

	state = {
		activeAccordions: []
	}

	findAccordion(id: string) {
		return this.state.activeAccordions.findIndex(x => x === id);
	}

	renderCategories(categories: ICategory[]) {
		return (
			<div className="category-items">
				{categories.length > 0 ? (
					categories.map((item, index) => (
						<div key={index} className="category-item row">
							{item.children && item.children.length ? (
								<span
									onClick={() => {
										const accordions = _.cloneDeep(this.state.activeAccordions) as string[];
										if (this.findAccordion(item.id as string) === -1) {
											accordions.push(item?.id as string);
										}
										else {
											accordions.splice(this.findAccordion(item.id as string), 1)
										}
										this.setState({
											activeAccordions: accordions
										})
									}}
									className="collapse"
								>
									{item.children && item.children.length > 0 && (
										<i className={this.findAccordion(item.id as string) !== -1 ? "fas fa-minus" : "fas fa-plus"}></i>
									)}
								</span>
							) : null}
							<Checkbox
								key={index}
								className="custom-checkbox"
								label={item.title}
								checked={this.props.value === item.id ? true : false}
								onChange={(ev, checked) => {
									if (this.props.onChangeCategory) {
										if (checked) {
											this.props.onChangeCategory(item.id as string)
										}
										else {
											this.props.onChangeCategory(null)
										}
									}
									// if (this.props.onChangeCategories) {
									// 	const categories = _.cloneDeep(this.props.value) || [];
									// 	if (checked) {
									// 		categories.push(item.id as string)
									// 	}
									// 	else {
									// 		const findIndex = categories.findIndex(x => x === item.id);
									// 		if (findIndex !== -1) {
									// 			categories.splice(findIndex, 1)
									// 		}
									// 	}
									// 	this.props.onChangeCategories(categories)
									// }
								}}
								styles={{
									checkbox: {
										width: 17,
										height: 17,
										borderColor: "#747474"
									},
									checkmark: {
										fontSize: 11
									},
									text: {
										fontSize: 12,
										fontWeight: "500",
										position: "relative",
										top: "-1px"
									}
								}}
							/>
							{item.children && item.children.length && this.findAccordion(item.id as string) !== -1 ? (
								<div className="children">
									{this.renderCategories(item.children)}
								</div>
							) : ""}

						</div>
					))
				) : (
					<span className="not-found row">
						<i className="icon-info"></i> Eklenmiş bir kategori bulunmadı.
					</span>
				)
				}
			</div>
		)
	}


	render() {
		const categories = this.props.categories || [];
		return (
			<div className="bar-category">
				<FormAccordion title="Kategoriler">
					<div className="content">
						{this.renderCategories(categories)}
					</div>
					<div className="footer row">
						<div onClick={() => this.props.onToggleModal && this.props.onToggleModal()} className="trigger m-left-auto">
							Yeni Kategori Ekle
						</div>
					</div>
				</FormAccordion>
			</div>
		)
	}
}

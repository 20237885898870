import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { SlideshowProps } from "./types";
import {
    EntryOptions,
    PropertyDefinition
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";
import { SlideshowDefinition } from '../definitions';


export class SlideshowDefinitions extends CmsReactComponent<SlideshowProps> {

    static schemaName = "slideshow";
    static propertyDefinitions: PropertyDefinition[] = SlideshowDefinition

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [
                MetaTypeEnum.Text,
                MetaTypeEnum.TextArea,
                MetaTypeEnum.Editor,
            ]
        }
    }
}

import { api } from 'app/utils/api';
import { convertToQueryString } from 'app/shared/functions';
import { HttpClient } from 'app/library/client';
import { IListFilter, ResponseBase, ResponseListBase } from "app/shared";
import { RecoveryEntity } from './types';
import { getEndpoint } from 'app/utils/service';

export class RecoveryService {

    /** It gets all recovery. */
    async getRecoverys(options: IListFilter, contentType: string): Promise<ResponseBase<ResponseListBase<RecoveryEntity[]>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.recovery.list) + `${contentType}?${convertToQueryString({ ...options, status: '' })}`);
    }

    async updateRecovery(contentType: string, id: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().put(getEndpoint(api.prefixes.recovery.restore) + `/${contentType}/${id}`, { status: 1 });
    }

    /** It deletes spesific recovery by id */
    async deleteRecovery(recoveryId: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().delete(getEndpoint(api.prefixes.recovery.delete) + recoveryId);
    }
}

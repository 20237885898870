import { EntityInventoryBase } from "app/shared";

export enum IdentityUserType {
  SuperAdminUser = 1,
  Admin = 2,
  EstablishmentAuthorizedUser = 3,
  TouristUser = 4,
  User = 5,
}

export interface IdentityLoginRequest {
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
  username?: string;
  password: string;
  email?: string;
}

export interface IdentityLoginResponse {
  access_token: string;
  refresh_token: string;
}

export interface IdentityUserResponse {
  name: string;
  username: string;
  email: string;
  role: string | string[];
  AdminUserId: string;
}

export interface IVerifyRequest {
  email: string;
}

export interface UserSignInResponseEntity {
  type: string;
  attributes: {
    access_token: string;
    user: {
      fullName: string;
      username: string;
      id: string;
    }
  }
}

export interface UserInfoResponseEntity {
  type: string;
  attributes: {
    access_token: string;
    admin: UserInfoEntity;
  }
}

export interface UserInfoEntity {
  fullName?: string;
  username?: string;
  id?: string;
  createdOn?: string,
  modifiedOn?: string,
}

export enum IdentityLoginStatusEnum {
  ApplicationValidate = 1,
}
export enum RoleModalTypeEnum {
  permission = "permission",
  add = "add",
  edit = "edit",
}

export enum RoleTypeEnum {
  permissions = "permissions",
  specialPermissions = "specialPermissions",
}

export interface IRole {
  id: number;
  name: string;
  code: string;
  modalType?: RoleModalTypeEnum;
  modalData?: RolePermissionEntity;
}

export interface RoleEntity extends EntityInventoryBase {
  id: number;
  name: string;
  code?: string;
}

export interface RoleItemEntity {
  name: string;
  read: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface SpecialPermissionItemEntity {
  name: string;
  actionCode: string;
  isActive: boolean;
}

export interface IPermissionSpecialPermissionResponse
  extends RolePermissionEntity {
  dataCount: number;
  success: boolean;
  statusCode: number;
  errorMessage: string;
  successMessage: string;
}

export interface RolePermissionEntity {
  permissions: RoleItemEntity[];
  specialPermissions: SpecialPermissionItemEntity[];
  roleId: number;
}

import { createContentTypePostsLink } from "app/utils/linking"
import { mapCategoryFromEntity } from "../category"
import { ContentTypeEntity, IContentType } from "./types"
import { IMenuItem } from "app/shared"

/**
 * Content type entity schema convert to model
 */
export function mapContentTypeFromEntity(contentTypeEntity: ContentTypeEntity): IContentType {
    return {
        id: contentTypeEntity.id,
        layout: contentTypeEntity.defaultLayoutId,
        metaFields: contentTypeEntity.metaFields,
        removable: contentTypeEntity.removable,
        title: contentTypeEntity.title,
        key: contentTypeEntity.key,
        categories: (contentTypeEntity.categories || []).map(item => mapCategoryFromEntity(item)),
        status: contentTypeEntity.status,
        createdBy: contentTypeEntity.createdBy,
        updatedBy: contentTypeEntity.modifiedBy,
        filters: [],
        quickFilters: [],
        specifications: [],
        createdAt: contentTypeEntity.createdOn || contentTypeEntity.CreatedOn,
        updatedAt: contentTypeEntity.modifiedOn || contentTypeEntity.ModifiedOn
    }
}

/**
 * Content type model to panel model
 */
export function mapContentTypePanelFromModel(contentTypeModel: IContentType): IMenuItem {
    return {
        key: contentTypeModel.id as string,
        title: contentTypeModel.title,
        url: createContentTypePostsLink(contentTypeModel.id as string),
        icon: "fas fa-puzzle-piece",
        text: "Dinamik olarak eklenen bir içerik tipidir. İçerikleri görüntüleyebilirsiniz.",
        children: []
    }
}

/**
 * Content type model schema convert to entity
 */
export function mapContentTypeFromModel(contentTypeModel: IContentType): ContentTypeEntity {
    return {
        id: contentTypeModel.id || "",
        defaultLayoutId: contentTypeModel.layout as string,
        key: contentTypeModel.key,
        metaFields: (contentTypeModel.metaFields || []).map(x => ({ name: x.name, title: x.title, type: x.type, column: x.column, bindings: x.bindings as { [key: string]: any }, default: x.default })),
        filters: [],
        quickFilters: [],
        specifications: [],
        removable: contentTypeModel.removable,
        status: contentTypeModel.status,
        title: contentTypeModel.title
    }
}

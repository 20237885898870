import React from 'react'
import { PureContentDefinitions } from './PureContentDefinitions'
import ComponentHeader from 'components/cms/ComponentHeader';
import Droppable from 'components/customs/Designer/Droppable';

export default class PureContentDesigner extends PureContentDefinitions {


    render() {
        return (
            <React.Fragment>
                <ComponentHeader
                    componentEntry={this.props.entry}
                    componentSchemaId={this.props.id as string}
                />
                <Droppable
                    style={{ display: "flex", flexDirection: "row" }}
                    elementId={this.props.id as string}
                    dragDropContext={(this.props as any).dragDropContext}
                    className={this.props.children && this.props.children.length > 0 ? "fa-droppable-row" : "fa-droppable-row row"}
                    groupName="layout"
                    disableSort={true}
                    elementType="column">
                    {this.props.children}
                </Droppable>
            </React.Fragment>
        )
    }
}

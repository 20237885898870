import { Component } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Label, PrimaryButton, TextField } from 'office-ui-fabric-react';
import _ from 'lodash';
import FileManager from '../FileManager/FileManager';
import { IMetaFile } from 'app/services/content-type';

interface FileSettingsModalProps {
    show: boolean;
    data: IMetaFile | null;
    onSave: (data: IMetaFile | null) => void;
    onDismiss: () => void;
}

interface MetaGalleryModalState {
    data: IMetaFile | null;
    activeModalIndex: number;
    activeProfileModal: string;
}

export default class FileSettingsModal extends Component<FileSettingsModalProps, MetaGalleryModalState> {

    state = {
        data: this.props.data || null,
        activeModalIndex: -1,
        activeProfileModal: ""
    }

    UNSAFE_componentWillReceiveProps(nextProps: FileSettingsModalProps) {
        if (nextProps.show && nextProps.show !== this.props.show) {
            this.setState({
                data: nextProps.data
            })
        }
    }

    getData(): IMetaFile {
        if (!this.state.data || _.keys(this.state.data)?.length === 0) {
            return {
                src: "",
                title: "",
                type: 1
            }
        }
        return this.state.data;
    }

    renderFileManager() {
        return (
            <FileManager
                show={(this.state.activeModalIndex !== -1 || this.state.activeProfileModal) ? true : false}
                onDismiss={() => this.setState({ activeModalIndex: -1, activeProfileModal: "" })}
                onSelectedFile={(file) => {
                    const data = this.getData()

                    data[this.state.activeProfileModal] = file.fileName;
                    data.title = file.fileName;
                    data.type = 1
                    this.setState({
                        data
                    })
                }}
            />
        )
    }

    renderFile() {
        const image = this.state.data ? this.state.data.src : "";

        return (
            <div style={{ marginTop: "-2px" }} className="image-field source-field row">
                <TextField
                    className="custom-textfield col no-padding"
                    value={image}
                    onChange={(event: any) => {
                        const data = this.getData()
                        data["src"] = event.target.value
                        this.setState({ data })
                    }}
                />
                <PrimaryButton
                    className="custom-button"
                    text="Dosya Seç"
                    onClick={() => {
                        this.setState({ activeProfileModal: "src" })
                    }}
                    styles={{
                        root: {
                            background: "#33926e !important",
                            borderColor: "#33926e !important"
                        },
                        rootDisabled: {
                            opacity: 0.3,
                            background: "#ececec",
                            color: "#000"
                        }
                    }}
                />
            </div>
        );
    }

    renderMobileFile() {
        const image = this.state.data ? this.state.data.mobile : "";

        return (
            <div style={{ marginTop: "-2px" }} className="image-field source-field row">
                <TextField
                    className="custom-textfield col no-padding"
                    value={image}
                    onChange={(event: any) => {
                        const data = this.getData()
                        data["mobile"] = event.target.value
                        this.setState({ data })
                    }}
                />
                <PrimaryButton
                    className="custom-button"
                    text="Dosya Seç"
                    onClick={() => {
                        this.setState({ activeProfileModal: "mobile" })
                    }}
                    styles={{
                        root: {
                            background: "#33926e !important",
                            borderColor: "#33926e !important"
                        },
                        rootDisabled: {
                            opacity: 0.3,
                            background: "#ececec",
                            color: "#000"
                        }
                    }}
                />
            </div>
        );
    }

    renderForm() {
        return (
            <div className="thumb-title">
                <Label>Dosya</Label>
                {this.renderFile()}
                <Label>Mobil Dosya</Label>
                {this.renderMobileFile()}
                <div className="row">
                    <TextField
                        className="custom-textfield col-md-6"
                        label="Dosya Başlık"
                        styles={{ root: { paddingRight: "10px !important", paddingLeft: "0px !important" } }}
                        value={this.state.data?.title}
                        onChange={(event: any) => {
                            const data = this.getData()
                            data["title"] = event.target.value
                            this.setState({ data })
                        }}
                    />
                    <Dropdown
                        className="custom-dropdown col-md-6"
                        label="Dosya Tipi"
                        selectedKey={this.state.data?.type}
                        onChange={(_, option) => {
                            const data = this.getData()

                            data["type"] = parseInt(option?.key as string)
                            this.setState({ data })
                        }}
                        options={[{
                            key: 1,
                            text: "Görsel"
                        },
                        {
                            key: 2,
                            text: "Video"
                        }]}
                    />
                </div>

            </div>
        )
    }

    render() {
        if (!this.props.show) return null;

        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Dosya Ayarları",
                    closeButtonAriaLabel: 'Kapat',
                    subText: "Dosya ayarlarını yapılandırabilirsiniz.",
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: true,
                    styles: { main: { maxWidth: "1500px !important", minWidth: "1000px !important" } }
                }}
            >
                {this.renderForm()}
                {this.renderFileManager()}
                <DialogFooter>
                    <DefaultButton onClick={() => {
                        this.setState({
                            data: {} as IMetaFile
                        }, () => {
                            this.props.onDismiss();
                        })
                    }} text="İptal" />
                    <PrimaryButton
                        onClick={() => {
                            this.props.onSave(this.state.data);
                            this.props.onDismiss();
                            this.setState({
                                data: {} as IMetaFile
                            })

                        }}
                        text="Kaydet"
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}

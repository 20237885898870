// import { EntryOptions, PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
// import { MetaTypeEnum } from "app/shared";
import { CmsReactComponent } from "components/cms/CmsReactComponent";
// import { ImageChildrenDefinitions } from "components/cms/definitions";
import { SpecialPressNewsProps } from "./types";

export class SpecialPressNewsDefinitions extends CmsReactComponent<SpecialPressNewsProps> {
    static schemaName = "special-press-news";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "title",
            text: "Başlık",
            mapping: {
                type: PropertyMappingType.String
            },
            default: ""
        },
        {
            name: "image",
            text: "Görsel",
            mapping: {
                type: PropertyMappingType.Image
            }
        },
        {
            name: "document",
            text: "Belge",
            mapping: {
                type: PropertyMappingType.File
            }
        }
    ];

    // static options: EntryOptions = {
    //     meta: {
    //         use: false,
    //         allowedTypes: [],
    //     },
    //     mappingFields: [
    //         {
    //             name: "title",
    //             text: "Başlık",
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //         {
    //             name: "image",
    //             text: "Görsel",
    //             types: [MetaTypeEnum.SingleImage],
    //         },
    //         {
    //             name: "document",
    //             text: "Belge",
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //     ],
    // };
}

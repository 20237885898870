import { Icon } from 'office-ui-fabric-react';
import { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import IconValues from 'components/cms/Icon/IconValues';
import { setPanelAccordion } from 'app/redux/designer/actions';
import { RootState } from 'app/redux/reducer';
import { getActiveAccordion } from 'app/redux/selectors';

export interface IconPanelProps {
    show: boolean;
    onDismiss: () => void;
    onSave: (icon: string) => void;
}

function mapStateToProps(state: RootState) {
    return {
        activeAccordions: state.designer.activeAccordions
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            setPanelAccordion
        },
        dispatch
    );
}

type AllProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IconPanelProps;

class IconPanel extends Component<AllProps> {
    render() {
        if (!this.props.show) return null;
        return (
            <div
                className="icon-panel active"
            >
                <div className="panel-head row">
                    <div className="title">
                        İkonlar
          			  <span className="sub-title">Tıkla ve bir ikon seç!</span>
                    </div>
                    <span
                        className="close"
                        onClick={() => this.props.onDismiss()}
                    >
                        <Icon iconName="ChromeClose" />
                    </span>
                </div>
                <div className="fa-Accordion">
                    {Object.keys(IconValues).map((item, key) => (
                        <div
                            key={key}
                            className={
                                getActiveAccordion(this.props.activeAccordions, item) === item
                                    ? "fa-Accordion-item active"
                                    : "fa-Accordion-item"
                            }
                        >
                            <div
                                onClick={() => this.props.setPanelAccordion(item)}
                                className="title"
                            >
                                {item}
                            </div>
                            {getActiveAccordion(this.props.activeAccordions, item) ===
                                item && (
                                    <div className="content">
                                        <div className="icon-list row">
                                            {IconValues[item].map((iconItem: string, index: number) => (
                                                <div
                                                    onClick={() => {
                                                        this.props.onSave(iconItem);
                                                        this.props.onDismiss();
                                                    }}
                                                    key={index}
                                                    className="item col-md-2"
                                                >
                                                    <i className={iconItem}></i>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IconPanel);

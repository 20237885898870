import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import _ from "lodash";

import { SchemaSourceOptions, SchemaSourceTypes, SchemaTree } from "app/library/layout-builder";
import { showMessage, toggleConfirmDialog, toggleLoader } from "app/redux/system/actions";
import { LayoutService } from "./service";
import { MessageType } from "app/redux/system/types";
import { RootState } from "app/redux/reducer";
import { initialPageData } from "app/redux/page/actions";
import { ILayout } from "./types";
import { HttpStatusCode, IList, IListFilter } from "app/shared";
import { mapLayoutFromEntity, mapLayoutFromModel } from "./mapping";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getLayout(layoutId: string, language?: string, notFill?: boolean): ThunkResult<Promise<any>> {
    return async (dispatch, getState) => {
        const service = new LayoutService();
        dispatch(toggleLoader());
        const response = await service.getLayout(layoutId, language || getState().system.defaultLanguage);
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapLayoutFromEntity(response.data.attributes["layout"] || {});
            dispatch(initialPageData<ILayout>(mapped));
            dispatch(toggleLoader());
            return true;
        };
        dispatch(toggleLoader());
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}

export function getLayoutByLanguage(pageData: ILayout, language: string, isOverride?: boolean): ThunkResult<Promise<any>> {
    return async (dispatch, getState) => {
        const service = new LayoutService();
        dispatch(toggleLoader());
        const response = await service.getLayout(pageData.id as string, isOverride ? getState().system.defaultLanguage : language);
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapLayoutFromEntity(response.data.attributes["layout"] || {});
            const data = { ...mapped, language: language, id: isOverride ? pageData.id : mapped.id };
            dispatch(initialPageData<ILayout>(data));
            if (isOverride) {
                dispatch(updateLayout(data));
            }
            dispatch(toggleLoader());
            return true;
        }
        else {
            dispatch(toggleConfirmDialog(true, {
                title: "Uyarı!",
                text: `Geçerli dil'e (${language}) ait bir layout bulunamadı. Varsayılan dil üzerinden seçtiğiniz dile göre layout oluşturulsun mu?`,
                button: {
                    approveText: "Varsayılan Dil'den Oluştur"
                },
                callback: () => {
                    dispatch(createLayout({ ...pageData, id: "", title: `${pageData.title} (${language.toUpperCase()})`, language })).then(res => {
                        if (res) {
                            dispatch(getLayout(res.id as string, language));
                        }
                    });
                    return true;
                }
            }))
        }
        dispatch(toggleLoader());
        return false;
    };
}

export function getLayouts(options: IListFilter): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new LayoutService();
        dispatch(toggleLoader());
        const response = await service.getLayouts(options);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map((item) =>
                mapLayoutFromEntity(item)
            );
            dispatch(initialPageData<IList<ILayout[]>>({
                list: mapped,
                pageSize: response.data.attributes.pageSize,
                totalCount: response.data.attributes.total,
                pageCount: Math.ceil(response.data.attributes.total / response.data.attributes.pageSize)
            }));
            return true;
        }
        return false;
    };
}

export function getLayoutsForLocal(language: string): ThunkResult<Promise<ILayout[]>> {
    return async (dispatch, getState) => {
        const service = new LayoutService();
        const response = await service.getLayouts({ page: 1, pageSize: 100, descending: false, language });
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map((item) =>
                mapLayoutFromEntity(item)
            );
            return mapped;
        }
        return [];
    };
}

export function createLayout(request: ILayout, fromClone?: boolean): ThunkResult<Promise<ILayout | null>> {
    return async (dispatch, getState) => {
        const service = new LayoutService();
        if (!fromClone) {
            dispatch(toggleLoader());
        }
        const schemaTree = SchemaTree.parse(request.schema);
        const sources = getSources(schemaTree);
        const queryIds: string[] = (sources || []).filter((item, index) => sources.indexOf(item) === index && item.type === SchemaSourceTypes.LocalData).map(x => x.ref as string);
        const dynamicIds: string[] = (sources || []).filter(item => item.type === SchemaSourceTypes.Dynamics).map(x => x.ref as string);
        const dynamics = _.isArray(request?.dynamics) ? (request?.dynamics || []).filter(item => dynamicIds.includes(item.id)) : [];
        const response = await service.createLayout(mapLayoutFromModel({ ...request, dynamics, queryIds }));
        if (!fromClone) {
            dispatch(toggleLoader());
        }
        if (response.status === HttpStatusCode.OK) {
            return mapLayoutFromEntity(response.data.attributes["layout"] || {});
        }
        return null;
    };
}

export function duplicateLayout(request: ILayout): ThunkResult<Promise<ILayout | null>> {
    return async (dispatch) => {
        const service = new LayoutService();
        dispatch(toggleLoader());

        const schemaTree = SchemaTree.parse(request.schema);
        const sources = getSources(schemaTree);
        const queryIds: string[] = (sources || []).filter((item, index) => sources.indexOf(item) === index && item.type === SchemaSourceTypes.LocalData).map(x => x.ref as string);
        const dynamicIds: string[] = (sources || []).filter(item => item.type === SchemaSourceTypes.Dynamics).map(x => x.ref as string);
        const dynamics = _.isArray(request?.dynamics) ? (request?.dynamics || []).filter(item => dynamicIds.includes(item.id)) : [];

        request = {
            ...request,
            id: "",
            relationId: "",
            title: `${request.title} (Cloned)`
        }

        const response = await service.createLayout(mapLayoutFromModel({ ...request, dynamics, queryIds }));

        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return mapLayoutFromEntity(response.data.attributes["layout"] || {});
        }
        return null;
    };
}

export function updateLayout(request: ILayout): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new LayoutService();
        dispatch(toggleLoader());
        const schemaTree = SchemaTree.parse(request.schema);
        const sources = getSources(schemaTree);
        const queryIds: string[] = (sources || []).filter((item, index) => sources.indexOf(item) === index && item.type === SchemaSourceTypes.LocalData).map(x => x.ref as string);
        const dynamicIds: string[] = (sources || []).filter(item => item.type === SchemaSourceTypes.Dynamics).map(x => x.ref as string);
        const dynamics = _.isArray(request?.dynamics) ? (request?.dynamics || []).filter(item => dynamicIds.includes(item.id)) : [];
        const response = await service.updateLayout(mapLayoutFromModel({ ...request, dynamics, queryIds }));
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        return false;
    };
}

export function deleteLayout(layoutId: string): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new LayoutService();
        dispatch(toggleLoader());
        const response = await service.deleteLayout(layoutId);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}

function getSources(schemaTree: SchemaTree): SchemaSourceOptions[] {
    const sources: SchemaSourceOptions[] = [];
    (schemaTree.getDistinctNodes() || []).forEach(item => {
        Object.keys(item.getSource()).forEach(source => {
            const current = item.getSource()[source];
            if (current && current.ref && (current.type === SchemaSourceTypes.LocalData || current.type === SchemaSourceTypes.Dynamics)) {
                sources.push(current)
            }
        })
    })
    return sources;
}
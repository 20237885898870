import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

export class HallListDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "hall-list";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "halls",
            description: "Hall List Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [{
                    name: 'title',
                    text: 'Başlık',
                    mapping: {
                        type: PropertyMappingType.String
                    }
                },
                {
                    name: 'image',
                    text: 'Görsel',
                    mapping: {
                        type: PropertyMappingType.Image
                    }
                },
                {
                    name: 'modalImage',
                    text: 'Modal için Büyük Görsel',
                    mapping: {
                        type: PropertyMappingType.Image
                    }
                }]
            },
            group: {
                key: "data",
                title: "Veriler",
                withObject: false
            }
        }
    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: "title",
                text: "Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea]
            },
            {
                name: "image",
                text: "Görsel",
                types: [MetaTypeEnum.SingleImage]
            },
            {
                name: 'modalImage',
                text: 'Modal için Büyük Görsel',
                types: [MetaTypeEnum.SingleImage]
            }
        ]
    };
}

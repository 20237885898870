export const seoMetaNames = [
    {
        key: "title",
        description: "SEO İçerik Başlığı",
        type: "title"
    },
    {
        key: "description",
        description: "SEO İçerik Açıklaması (160 karakterden kısa)",
        type: "name"
    },
    {
        key: "keywords",
        description: "SEO İçerik Anahtar Kelimeleri (Virgüller ile ayırınız)",
        type: "name"
    },
    {
        key: "og:title",
        description: "Open Graph Başlık",
        type: "property"
    },
    {
        key: "og:url",
        description: "Open Graph URL",
        type: "property"
    },
    {
        key: "og:image",
        description: "Open Graph Görsel",
        type: "property"
    },
    {
        key: "og:description",
        description: "Open Graph Açıklaması",
        type: "property"
    },
    {
        key: "og:site_name",
        description: "Open Graph Site Adı",
        type: "property"
    },
    {
        key: "article:section",
        description: "İçerik Section",
        type: "property"
    },
    {
        key: "article:tag",
        description: "İçerik Tag",
        type: "property"
    },
    {
        key: "name",
        description: "Google Başlık",
        type: "itemProp"
    },
    {
        key: "description",
        description: "Google Açıklama",
        type: "itemProp"
    },
    {
        key: "image",
        description: "Google Görsel",
        type: "itemProp"
    },
    {
        key: "twitter:card",
        description: "Twitter Kart Tipi",
        type: "name",
        default: "summary_large_image"
    },
    {
        key: "twitter:site",
        description: "Twitter Site",
        type: "name"
    },
    {
        key: "twitter:title",
        description: "Twitter Başlık",
        type: "name"
    },
    {
        key: "twitter:description",
        description: "Twitter Açıklama",
        type: "name"
    },
    {
        key: "twitter:image:src",
        description: "Twitter Görsel (280x150)",
        type: "name"
    }
]
import { Component } from 'react';
import { BaseMetaFieldsProps } from './types';
import _ from "lodash";
import { TextField } from 'office-ui-fabric-react';

export default class MetaMultiText extends Component<BaseMetaFieldsProps<string[]>>  {

    onRemoveItem(index: number) {
        const list = _.cloneDeep(this.props.meta.data) || [];
        list.splice(index, 1);
        this.props.onChange(list);
    }

    onChangeItem(value: string, index: number) {
        const list = _.cloneDeep(this.props.meta.data) || [];
        if (!list[index]) {
            list.push(value)
        }
        else {
            list[index] = value;
        }
        this.props.onChange(list)
    }

    renderList() {
        const list = [...(this.props.meta?.data || []), ""]
        return (
            list.map((item, index) => (
                <div key={index} className="meta-text-item row">
                    <TextField
                        placeholder="Bir yazı giriniz.."
                        value={item as string || ""}
                        className="custom-textfield border col no-padding"
                        resizable={false}
                        onChange={(event: any) => this.onChangeItem(event.target.value, index)}
                    />
                    {(
                        <span
                            className={index === (list.length - 1) ? "passive remove" : "remove"}
                            onClick={() => this.onRemoveItem(index)}
                        >
                            <i className="icon-close1"></i>
                        </span>
                    )}
                </div>
            ))
        )
    }

    render() {
        return (
            <div className="meta-text-list">
                {this.renderList()}
            </div>
        )
    }
}

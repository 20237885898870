import * as React from "react";
import { BaseFieldProps } from "./types";
import { PureDropdownField } from "./Pure/PureDropdownField";

// Kendisine gelen Property ismine ve değerine göre Dropdown render eder.
export default class DropdownField extends React.Component<BaseFieldProps> {
  render() {
    if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
    return (
      <PureDropdownField
        label={this.props.property.name}
        value={this.props.value}
        onChange={value => this.props.onChange(value)}
        property={this.props.property}
        text={this.props.property.text as string}
        description={this.props.property.description as string}
      />
    );
  }
}

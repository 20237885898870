import { api } from 'app/utils/api';
import { convertToQueryString } from 'app/shared/functions';
import { HttpClient } from 'app/library/client';
import { IListFilter, ResponseBase, ResponseListBase, ResponseContentBase } from "app/shared";
import { LayoutEntity } from './types';
import { getEndpoint } from 'app/utils/service';

export class LayoutService {

    /** It gets all layout. */
    async getLayouts(options: IListFilter): Promise<ResponseBase<ResponseListBase<LayoutEntity[]>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.layout.list) + `?${convertToQueryString(options)}`);
    }

    /** It get spesific layout by layout id */
    async getLayout(layoutId: string, language?: string): Promise<ResponseBase<ResponseContentBase<LayoutEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.layout.main) + layoutId + `?language=${language || "en"}`);
    }

    /** It creates layout by request */
    async createLayout(request: LayoutEntity): Promise<ResponseBase<ResponseContentBase<LayoutEntity>>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.layout.main), request);
    }

    /** It updates spesific layout by id */
    async updateLayout(request: LayoutEntity): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().patch(getEndpoint(api.prefixes.layout.main) + request.id, request);
    }

    /** It deletes spesific layout by id */
    async deleteLayout(layoutId: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().delete(getEndpoint(api.prefixes.layout.main) + layoutId);
    }
}

export enum StatusEnum {
    Deleted = 0,
    Published = 1,
    Draft = 2
}

export enum MetaTypeEnum {
    Text = 1,
    TextArea = 2,
    MultiText = 3,
    Number = 4,
    Date = 5,
    Checkbox = 6,
    Dropdown = 7,
    ComboBox = 8,
    Contributor = 9,
    SingleImage = 10,
    MultiImage = 11,
    Editor = 12,
    Link = 13,
    Color = 14,
    Pricing = 15,
    SingleVideo = 16,
    PhotoGallery = 17,
    MultiFile = 18,
    PhotoSlider = 19,
    File = 20
}

export enum PostFieldsEnum {
    Image = 1,
    Text = 2,
    TextArray = 3,
    Url = 4
}

export enum ShippingStatus {
    Confirmed = 0,
    GettingReady = 1,
    Shipped = 2,
    Delivered = 3,
    Canceled = 4
}

export enum ThemeRoutes {
    Home = "homepage",
    StandEnquiry = "standEnquiry",
    Daily = "daily",
}

export enum DynamicFilterTypes {
    Static = "static",
    Meta = "meta",
    Spec = "spec",
    Post = "post",
    ContentType = "contentType",
    Brand = "brand",
    ProductType = "productType",
    Showcase = "showcase",
}

export enum FilterType {
    Brand = "brand",
    ContentType = "contentType",
    Category = "category"
}

export type LinkTargetTypes = "_blank" | "_parent" | "_self" | "_top";
export type RelationTypes = "alternate" | "author" | "external";

export enum HttpStatusCode {
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500,
}

export enum Language {
    Turkish = "tr",
    English = "en",
    Russian = "ru",
    Arabic = "ar",
    German = "de",
    Dutch = "nl"
}

export enum MenuProperty {
    Frequently = "frequently",
    FileManager = "file-manager",
    ContentType = "content-type",
    Highlight = "highlight"
}

export enum RouteKey {
    Home = "home",
    ContentTypeManagement = "content-type-management",
    ContentTypes = "content-types",
    Layout = "layout",
    Menu = "menu",
    Users = "users",
    Roles = "roles",
    ThemeSettings = "theme-settings",
    FileManagement = "file-management"
}

import * as React from "react";

import { BaseFieldProps } from '../types';
import BaseDynamicFilter from "./BaseDynamicFilter";

// Kendisine gelen Property ismine ve değerine göre Image Field render eder.
export default class DynamicFilterField extends React.Component<BaseFieldProps> {

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        return (
            <div className="property-item row">
                <div title={this.props.property.name} className="property-text">
                    <div className="title">{this.props.property.text || this.props.property.name.split(/(?=[A-Z])/).map(item => item.charAt(0).toUpperCase() + item.slice(1) + " ")}</div>
                    <div className="sub-title">{this.props.property.description || this.props.property.name}</div>
                </div>
                <div style={{ marginTop: "-5px" }} className="col">
                    <BaseDynamicFilter
                        value={this.props.value}
                        onChange={(data) => this.props.onChange(data)}
                    />
                </div>
            </div>
        );
    }
}

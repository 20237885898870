import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { CardSliderProps } from "./types";
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

import { CardPropertyDefinitions, SlideshowDefinition } from 'components/cms/definitions';

export class CardSliderDefinitions extends CmsReactComponent<CardSliderProps> {
    static schemaName = "card-slider";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "cards",
            description: "Kart List Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: CardPropertyDefinitions,
            },
            group: {
                key: "data",
                title: "Kart Verileri",
                withObject: false,
            }
        },
        {
            name: "settings",
            text: "Ayarlar",
            description: "Slayt Ayarları",
            mapping: {
                type: PropertyMappingType.ParentObject,
                children: SlideshowDefinition,
            },
            group: {
                key: "slider",
                title: "Slayt Gösterisi Ayarları",
                withObject: true,
            }
        },
        {
            name: "width",
            text: "Genişlik",
            description: "Kart Görsel Genişliği",
            mapping: {
                type: PropertyMappingType.Number
            }
        },
        {
            name: "height",
            text: "Yükseklik",
            description: "Kart Görsel Yüksekliği",
            mapping: {
                type: PropertyMappingType.Number
            }
        }
    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: "title",
                text: "Card Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "description",
                text: "Card Açıklaması",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "image",
                text: "Card Görsel",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "disabled",
                text: "Disabled",
                types: [MetaTypeEnum.Checkbox],
            },
            {
                name: "anchor",
                text: "Card URL",
                types: [MetaTypeEnum.Link]
            },
        ],
    };
}

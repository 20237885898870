import { SchemaObject, SchemaNode } from "../schema";
import { MetaTypeEnum } from "app/shared";
import { SchemaSourceTypes } from "../schema/node";
import { SchemaService } from "app/services/schema";
import { BaseFieldProps } from "components/customs/Fields/types";

/** Element flow descriptor */
export interface EventDescriptor {
    type: string;
    name: string;
}

/** Element flow events */
export interface EventDefinition {
    name: string;
    descriptor?: EventDescriptor[];
}

/** Element properties's visibility types */
export enum PropertyMappingType {
    String = 1, // String type with input
    Number = 2, // Number type with input
    Boolean = 3, // True / False type with checkbox
    Array = 4, // List type with each item type
    Object = 5, // Object type with dropdown
    Color = 6, // Color type with color picker
    Icon = 7, // Icon type with icon picker
    Image = 8, // Image type with image picker
    Video = 9, // Video type with video picker
    Textarea = 10, // Textarea type with input
    Alignment = 11, // Alignment type with alignment picker
    LinkWizard = 12, // Url type with input
    ArrayData = 13, // List type with dynamic list item type
    ParentObject = 14, // Parent objects its has sub-property, used with children.
    Html = 15, // HTML type with html editor
    Menu = 16, // Menu items field
    Website = 17, // Web site picker field
    SrcSet = 18,
    Contributor = 19,
    Pricing = 20,
    DateTime = 21,
    DateTime2 = 30,
    ContentType = 22,
    Brand = 23,
    Css = 24,
    ProductData = 25,
    Product = 26,
    ContentTypes = 27,
    DynamicFilter = 28,
    File = 29,
    Custom = 98, // Custom rendering
    Any = 99, // Any
}

/** Custom right panel field function, return react node with current data */
export type FieldRendererFunction = (data: FieldRendererData) => any;

/** Custom right panel array fields function, return react node with current data */
export type FieldsRendererFunction = (data: FieldsRendererData) => any;

/** Returns dynamic array fields by spesific key on component definiton */
export type GetKeysFunction = (
    fieldProps: BaseFieldProps
) => PropertyDefinition[];

/** Set schema modal data */
export type SetData = (schema: SchemaObject[]) => any;

/** Hide property by property condition */
export type ConditionalHideFunction = (props: BaseFieldProps) => boolean;

/** Object type (e.g. { key: "formapps", text: "Formapps" }) */
export interface PropertyDefinitionOneOf {
    key: string | number;
    text: string;
}

/** Custom right panel field callback properties */
export interface FieldRendererData {
    fieldProps: BaseFieldProps;
    item?: any;
    property?: PropertyDefinition;
    index?: number;
    setData?: SetData;
}

/** Custom right panel array fields callback properties */
export interface FieldsRendererData {
    fieldProps: BaseFieldProps;
    schemaService: typeof SchemaService;
}

/** Entry Options Definitions */
export type DefinitionKeys =
    | "advanceMode"
    | "bindingProperty"
    | "customMapping"
    | "mappingProperty"
    | "externalDesign";

/** Fill options (only uses single property on element) */
export interface OptionsDefinitions {
    bindingProperty?: string; // which field (array) will fill?
    mappingProperty?: string; // which on field (array) will mapping?
    advanceMode?: boolean; // advance component mode
    onChangeMode?: (schemaNode: SchemaNode) => SchemaNode; // advance mode changed
    onDragged?: (schemaNode: SchemaNode) => SchemaNode[]; // on component dragged
    customMapping?: PropertyDefinition[]; // custom flow mapping
    externalDesign?: boolean; // component displays another design page
}

/** Define all entry options definition (fill settings, flow mapping, etc..) */
export interface EntryOptions {
    meta?: {
        use: boolean;
        allowedTypes: MetaTypeEnum[];
    };
    wizard?: {
        group: boolean;
        single: boolean;
    };
    defaultSource?: SchemaSourceTypes;
    mappingFields?: { name: string; text: string; types: MetaTypeEnum[] }[];
}

/** Element mapping type definitions */
export interface PropertyDefinitonMapping {
    type: PropertyMappingType; // mapping type
    keys?: PropertyDefinition[]; // array keys
    getKeys?: GetKeysFunction; // array dynamic keys (on another property)
    children?: PropertyDefinition[]; // property childrens with only parent object type
    oneOf?: PropertyDefinitionOneOf[]; // object keys
    fieldRenderer?: FieldRendererFunction; // custom field render
    showPropertyText?: boolean; // show or hide property text if custom field renderer active
    fieldsRenderer?: FieldsRendererFunction; // custom (array) fields renderer
}

/** Element group property object */
export interface PropertyGroupObject {
    key: string;
    title: string;
    withObject?: boolean;
}

/** Element property definitions */
export interface PropertyDefinition {
    name: string;
    text?: string;
    description?: string;
    default?: any;
    group?: PropertyGroupObject;
    initialFlowValue?: boolean;
    mapping: PropertyDefinitonMapping;
    conditionalHide?: ConditionalHideFunction;
}



/** Element entry render type on container */
export enum ContainerEntryEnum {
    Component = 1,
    Schema = 2,
}

/** Element container entry operations */
export class ContainerEntry {
    component: React.ComponentClass;
    name: string | undefined;
    type: string;
    entryType: ContainerEntryEnum;
    events: EventDefinition[] = [];
    properties: PropertyDefinition[] = [];
    options: EntryOptions = {};
    children: SchemaObject[] = [];

    constructor(
        component: React.ComponentClass,
        name: string,
        type: string,
        entryType: ContainerEntryEnum,
        events: EventDefinition[] = [],
        properties: PropertyDefinition[] = [],
        children: SchemaObject[] = [],
        options?: EntryOptions,
    ) {
        this.component = component;
        this.name = name;
        this.type = type;
        this.entryType = entryType;
        this.events = events;
        this.properties = [...properties,
        { name: "hideRuntime", text: "Çalışırken Gizle", mapping: { type: PropertyMappingType.Boolean } },
        { name: "className", text: "Özel Sınıf (className)", mapping: { type: PropertyMappingType.String } }];
        this.children = children;
        this.options = options as EntryOptions;
    }

    /** Create a node instance on container with current type and properties */
    static buildFromComponent(component: React.ComponentClass) {
        let entry = new ContainerEntry(
            component,
            (component as any).schemaName,
            (component as any).schemaType,
            ContainerEntryEnum.Component,
            (component as any).events,
            (component as any)?.propertyDefinitions,
            (component as any)?.children,
            (component as any)?.options
        );
        return entry;
    }
}

import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";
import { EntryOptions } from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";
import { PhotoGalleryDefinition } from "components/cms/definitions";

export class PhotoGalleryWithTabsDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "photo-gallery-with-tabs";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "tablist",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        text: "Başlık",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                    },
                    {
                        name: "data",
                        description: "Fotoğraf Galerisi Elemanları",
                        mapping: {
                            type: PropertyMappingType.ArrayData,
                            keys: PhotoGalleryDefinition
                        }
                    }
                ]
            },
            default: []
        }
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.MultiImage,
                MetaTypeEnum.PhotoGallery
            ]
        },
        mappingFields: [
            {
                name: "thumbnail",
                text: "Görsel Öne Çıkarılmış",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "src",
                text: "Görsel Yolu",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "alt",
                text: "Görsel Alt Etiketi",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "caption",
                text: "Görsel Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "subcaption",
                text: "Görsel Alt Başlık",
                types: [MetaTypeEnum.TextArea, MetaTypeEnum.Text, MetaTypeEnum.Editor]
            }
        ]
    };
}
import { Component } from 'react';
import { connect } from 'react-redux';
import qs from "querystring";
import { RouteComponentProps } from 'react-router-dom';

import { bindActionCreators, Dispatch } from 'redux';
import { PrimaryButton } from 'office-ui-fabric-react';
import { deleteCategory, getCategories, getCategory, ICategory, mapCategoryForList } from 'app/services/category';
import { getContentType, IContentType } from 'app/services/content-type';
import { RootState } from 'app/redux/reducer';
import { toggleConfirmDialog } from 'app/redux/system/actions';
import { setModalData, toggleModal } from 'app/redux/modal/actions';
import { castThunkAction } from 'app/utils/casting';
import BasicTable from 'components/customs/BasicTable';
import PageTitle from 'components/customs/PageTitle';
import PageLayout from 'containers/PageLayout';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<{ contentTypeId: string }>) {
    return {
        ...ownProps,
        contentTypeId: ownProps.match.params.contentTypeId,
        language: state.system.language,
        categories: state.category.categories,
        contentType: state.contentType.contentType
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        deleteCategory,
        getCategory,
        getContentType,
        toggleConfirmDialog,
        toggleModal,
        getCategories,
        setModalData
    }, dispatch);
}

type IListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ListCategory extends Component<IListProps> {

    componentDidMount() {
        this.getContentType();
    }

    UNSAFE_componentWillReceiveProps(nextProps: IListProps) {
        if (nextProps.contentTypeId && nextProps.contentTypeId !== this.props.contentTypeId) {
            this.getContentType(nextProps.contentTypeId);
        }
    }

    getContentType(contentTypeId?: string) {
        castThunkAction<IContentType>(this.props.getContentType(contentTypeId || this.props.contentTypeId, true)).then(() => this.props.getCategories(this.props.contentTypeId))
    }

    renderList() {
        return (
            <BasicTable

                totalCount={this.props.categories.totalCount}
                columns={[
                    {
                        columnName: "title",
                        text: "Kategori Adı",
                        col: "col-md-6"
                    },
                    {
                        columnName: "description",
                        text: "Kategori Açıklaması",
                        col: "col-md-6"
                    }
                ]}
                items={mapCategoryForList(this.props.categories?.list || [])}
                renderOperations={(item: ICategory) => (
                    <>
                        <button
                            className="more"
                            onClick={() => {
                                this.props.toggleModal("category", { id: "1" });
                                castThunkAction<ICategory>(this.props.getCategory(item.id as string)).then(res => {
                                    if (!res) {
                                        this.props.toggleModal("category");
                                    }
                                    this.props.setModalData("category", { ...res, contentType: this.props.contentTypeId })
                                })
                            }}
                        >
                            <i className="icon-edit-outline"></i> Düzenle
                        </button>
                        <button
                            onClick={() => {
                                this.props.toggleConfirmDialog(true, {
                                    callback: () => {
                                        castThunkAction(this.props.deleteCategory(item.id as string)).then(res => {
                                            if (res) {
                                                this.getContentType()
                                            }
                                        })
                                    }
                                })
                            }}
                        >
                            <i className="icon-trash-outline"></i>
                            Sil
                        </button>
                    </>
                )}
            />
        )
    }

    render() {
        return (
            <PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
                <div className="page push-all">
                    <PageTitle
                        title="Kategori Yönetimi"
                        subTitle="İlgili içerik tipine ait tüm kategorileri aşağıdan görüntüleyebilir, düzenleme yapabilir veya yeni bir kategori ekleyebilirsiniz."
                        titleRenderer={
                            () => (
                                <span className="edit-title">
                                    Kategori Yönetimi:
                                    <strong>{this.props.contentType?.title || "-"}</strong>
                                </span>
                            )
                        }
                        backUrl={(qs.parse(this.props.history?.location.search.replace("?", ""))?.fromPost === "false" ? "/content-type/edit/" : "/posts/") + this.props.contentTypeId}
                        showBack
                        history={this.props.history}
                    />
                    <PrimaryButton
                        onClick={() => {
                            this.props.toggleModal("category", { contentType: this.props.contentTypeId })
                        }}
                        iconProps={{ iconName: "Add" }}
                        className="absolute-create"
                        text="Yeni Kategori Ekle"
                        styles={{
                            root: {
                                background: "#62a98e",
                                borderColor: "#5b9e85",
                                color: "#fff"
                            },
                            rootHovered: {
                                background: "#5b9e85",
                                borderColor: "#5b9e85"
                            },
                            rootDisabled: {
                                background: "#ececec"
                            }
                        }}
                    />
                    {this.renderList()}
                </div>
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListCategory);
import * as React from "react";
import Sortable from "sortablejs";
import { container } from "components/cms/container";
import { SchemaService } from "app/services/schema";
import DragDropContext from './DragDropContext';

interface DroppableProps {
    elementId: string | null;
    elementType?: string;
    dragDropContext: React.RefObject<DragDropContext>;
    style?: any;
    className?: string;
    groupName?: string;
    disableSort?: boolean;
}

export default class Droppable extends React.Component<DroppableProps> {
    containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        Sortable.create(this.containerRef.current as HTMLDivElement, {
            group: {
                name: "droppable",
                pull: (to: Sortable, from: Sortable, element) => {
                    const fromType = from.el.getAttribute('data-schema-type');
                    const toType = to.el.getAttribute('data-schema-type');

                    if (fromType === "column" && (toType === "column" || toType === "container" || toType === "grid")) {
                        return true;
                    }

                    if (fromType === "container" && (toType === "column" || toType === "container" || toType === "grid")) {
                        return true;
                    }

                    if (fromType === "root" && (toType === "grid" || toType === "root"))
                        return true;

                    if (fromType === "grid" && (toType === "root" || toType === "grid" || toType === "column"))
                        return true;

                    return false;
                }
            },
            sort: !this.props.disableSort,
            animation: 150,
            swapThreshold: 100,
            fallbackOnBody: true,
            delay: 50,
            onSort: (event: any) => {
                if (event.to.getAttribute('data-schema-id') === this.props.elementId) {
                    const fromId = event.from.getAttribute('data-schema-id');
                    const type = event.clone.getAttribute('data-schema-type');
                    const elementId = event.clone.getAttribute('data-schema-id');
                    const toId = event.to.getAttribute('data-schema-id');
                    const size = event.clone.getAttribute('data-schema-size');

                    if ((!type || type.length === 0) && !fromId) {
                        return event.item.parentNode ? event.item.parentNode.removeChild(event.item) : "";
                    }

                    if (!fromId || fromId.length === 0) {
                        if (event.item.parentNode) {
                            event.item.parentNode.removeChild(event.item);
                        }

                        const containerEntry = container.resolve(type as string);
                        let defaultProps: { [key: string]: string | object | number } = {};
                        containerEntry.properties.forEach(def => {
                            if (def.group && def.group.withObject) {
                                const groupDefaults = defaultProps[def.group.key] as object;
                                defaultProps = {
                                    ...defaultProps,
                                    [def.group.key]: {
                                        ...groupDefaults,
                                        [def.name]: def.default
                                    }
                                }
                            }
                            else {
                                defaultProps = {
                                    ...defaultProps,
                                    [def.name]: def.default
                                }
                            }
                        })

                        if (type === "column" && toId === "root") {
                            return false;
                        }
                        if (toId === "root") {
                            SchemaService.insertRoot(type as string, { ...defaultProps, sizes: size ? { lg: Number(size), xs: Number(size), sm: Number(size), md: Number(size), xl: Number(size) } : defaultProps.sizes }, event.newIndex as number, containerEntry.options, containerEntry.type);
                            return;
                        } else {
                            SchemaService.insertAt(toId as string, type as string, { ...defaultProps, sizes: size ? { lg: Number(size), xs: Number(size), sm: Number(size), md: Number(size), xl: Number(size) } : defaultProps.sizes }, event.newIndex as number, undefined, containerEntry.options, undefined, containerEntry.type);
                            return;
                        }
                    }

                    if (fromId === "root" && toId === "root") {
                        SchemaService.moveRoot(event.oldIndex as number, event.newIndex as number);
                        return;
                    }
                    console.log({ fromId, type, toId });
                    SchemaService.moveElement(fromId, toId as string, elementId as string, event.oldIndex as number, event.newIndex as number);
                }
            }
        });
    }

    render() {
        return (
            <div
                style={{ width: "100%", height: "100%", minHeight: "100px", ...(this.props.style || {}) }}
                className={["droppable-area", ...([this.props.className || null])].filter(x => x).join(" ")}
                data-schema-id={this.props.elementId}
                data-schema-type={this.props.elementType || ""}
                ref={this.containerRef}>
                {this.props.children}
            </div>
        );
    }
}
import { Component } from 'react'
import { ComboBox } from 'office-ui-fabric-react'
import FormAccordion from '../FormAccordion'
import { ILayoutBar } from "./Sidebar";

export default class LayoutBar extends Component<ILayoutBar> {

    render() {
        return (
            <div className="bar-category">
                <FormAccordion title="Layout">
                    <div className="content">
                        <ComboBox
                            className="custom-combobox"
                            allowFreeform
                            autoComplete="on"
                            calloutProps={{ className: "custom-combobox-callout" }}
                            selectedKey={this.props.layout}
                            onChange={(_, opt) => this.props.onChangeLayout ? this.props.onChangeLayout(opt?.key as string) : {}}
                            options={(this.props.layoutData || []).map(x => ({ key: x.id as string, text: x.title }))}
                        />
                    </div>
                </FormAccordion>
            </div>
        )
    }
}

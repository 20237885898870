import { Component } from 'react'
import { Dropdown, DefaultButton, IDropdownOption, Icon } from 'office-ui-fabric-react'
import _ from "lodash";

import { langs } from "./constants";
import { toggleConfirmDialog } from 'app/redux/system/actions';

export interface TranslatorBarProps {
    languages: string[];
    current?: string;
    default?: string;
    onChange?: (language: string) => void;
    onClone?: () => void;
    toggleDialog?: typeof toggleConfirmDialog;
}

export default class TranslatorPostBar extends Component<TranslatorBarProps> {

    getLanguages() {
        let options: IDropdownOption[] = [];

        _.keys(langs).forEach(key => {
            if (_.isArray(this.props.languages) && this.props.languages.includes(key)) {
                options.push({
                    key,
                    text: `${langs[key].name} (${langs[key].nativeName})`
                })
            }
        });

        const findDefault = options.find(x => x.key === this.props.default);

        if (!findDefault) {
            options.push({
                key: this.props.default as string,
                text: `${langs[this.props.default as string].name} (${langs[this.props.default as string].nativeName})`
            })
        }

        const findEnglish = options.find(x => x.key === "en");

        if (!findEnglish) {
            options.push({
                key: "en",
                text: `${langs["en"].name} (${langs["en"].nativeName})`
            })
        }

        return options;
    }

    renderCloneButton() {
        if (this.props.current !== this.props.default) {
            return (
                <DefaultButton
                    text="Varsayılan Dil'den Aktar"
                    onClick={() => {
                        if (this.props.toggleDialog) {
                            this.props.toggleDialog(true, {
                                title: "Onaylıyor Musunuz?",
                                button: {
                                    approveText: "İçeriği Aktar"
                                },
                                text: `Geçerli içerik (${this.props.current}), varsayılan dil (${this.props.default || "en"}) üzerinden kopyalanacaktır. Tüm içerik varsayılan dile göre güncellenecektir. İşlem geri alınamaz. Emin misiniz?`,
                                callback: () => {
                                    if (this.props.onClone) {
                                        this.props.onClone();
                                    }
                                }
                            });
                        }
                    }}
                    iconProps={{ iconName: "Translate" }}
                    className="custom-button"
                />
            )
        }
        return null;
    }

    onRenderOption = (option: IDropdownOption | undefined) => {
        if (!option) return null;
        return (
            <div className="dropdown-option">
                {option.key && (
                    <img src={require(`assets/images/flags/${option?.key}.png`).default} alt="" />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    onRenderTitle = (options: IDropdownOption[] | undefined) => {
        if (!options) return null;
        const option = options[0];
        return (
            <div className="dropdown-title">
                {option.key && (
                    <img src={require(`assets/images/flags/${option?.key}.png`).default} alt="" />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    renderDropdown() {
        return (
            <div className="language-picker row">
                <span className="label">Görüntülenen Dil</span>
                <Dropdown
                    className="custom-dropdown"
                    calloutProps={{ className: "custom-dropdown-callout" }}
                    onRenderTitle={(options) => this.onRenderTitle(options)}
                    onRenderOption={(option) => this.onRenderOption(option)}
                    options={this.getLanguages()}
                    selectedKey={this.props.current || this.props.default || "en"}
                    onChange={(_, opt) => {
                        if (this.props.onChange) {
                            this.props.onChange(opt?.key as string);
                        }
                    }}
                />
            </div>
        )
    }

    render() {
        return (
            <div className="translator-bar row">
                <Icon iconName="GlobeFavorite" />
                <div className="title">Dil İşlemleri</div>
                <div className="text">Sağ taraftan geçerli içeriği, dile göre değiştirebilirsiniz.</div>
                <div className="m-left-auto row">
                    {this.renderCloneButton()}
                    {this.renderDropdown()}
                </div>
            </div>
        )
    }
}

import { api } from 'app/utils/api';
import { convertToQueryString } from 'app/shared/functions';
import { HttpClient } from 'app/library/client';
import { IListFilter, ResponseBase, ResponseContentBase, ResponseListBase } from "app/shared";
import { MenuEntity } from './types';
import { getEndpoint } from 'app/utils/service';

export class MenuService {

    /** It gets all menu. */
    async getMenus(options: IListFilter): Promise<ResponseBase<ResponseListBase<MenuEntity[]>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.menu.list) + `?${convertToQueryString(options)}`);
    }

    /** It get spesific menu by menu id */
    async getMenu(menuId: string, language: string): Promise<ResponseBase<ResponseContentBase<MenuEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.menu.main) + menuId + `?language=${language || "en"}`);
    }

    /** It creates menu by request */
    async createMenu(request: MenuEntity): Promise<ResponseBase<ResponseContentBase<MenuEntity>>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.menu.main), request);
    }

    /** It updates spesific menu by id */
    async updateMenu(request: MenuEntity): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().patch(getEndpoint(api.prefixes.menu.main) + request.id, request);
    }

    /** It deletes spesific menu by id */
    async deleteMenu(menuId: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().delete(getEndpoint(api.prefixes.menu.main) + menuId);
    }
}

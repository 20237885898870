import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    PropertyDefinition,
} from "app/library/layout-builder";

export class PresentationFormDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "presentation-form";

    static propertyDefinitions: PropertyDefinition[] = [];
}

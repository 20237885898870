export interface PageStore {
	pageData: any;
	pageErrors: any;
}

export const INITIAL_PAGE_DATA = 'INITIAL_PAGE_DATA';
export const SET_PAGE_DATA = 'SET_PAGE_DATA';
export const SET_PAGE_ERRORS = 'SET_PAGE_ERRORS';
export const RESET_PAGE_DATA = 'RESET_PAGE_DATA';

interface InitialPageData {
	type: typeof INITIAL_PAGE_DATA;
	pageData: any;
}

interface SetPageData {
	type: typeof SET_PAGE_DATA;
	key: string;
	value: string;
}

interface SetPageErrors {
	type: typeof SET_PAGE_ERRORS;
	pageErrors: any;
}

interface ResetPage {
	type: typeof RESET_PAGE_DATA;
}

export type PageActionTypes = InitialPageData | SetPageData | SetPageErrors | ResetPage;
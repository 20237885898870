import { IRecovery, RecoveryEntity } from "./types";

/**
 * Recovery entity schema convert to model
 */
export function mapRecoveryFromEntity(recoveryEntity: RecoveryEntity): IRecovery {
    return {
        id: recoveryEntity.id,
        title: recoveryEntity.title,
        contentType: recoveryEntity.contentType,
        updatedBy: recoveryEntity.modifiedBy,
        createdAt: recoveryEntity.createdOn || recoveryEntity.CreatedOn,
        updatedAt: recoveryEntity.modifiedOn || recoveryEntity.ModifiedOn,
        status: 0
    }
}



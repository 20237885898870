import * as React from "react";
import { TextField } from 'office-ui-fabric-react';
import clsx from "clsx";

export type OnChangeStringField = (value: any) => void;
export type OnChangeWidgetStringField = (value: any) => void;

export interface PureStringFieldProps {
    label: string;
    value: any;
    onChange: OnChangeStringField;
    multiline?: boolean;
    customRender?: Function;
    type?: string;
    text: string;
    description: string;
    className?: string;
    imageSource?: string;
    iconSource?: string;
}

export class PureStringField extends React.Component<PureStringFieldProps> {

    render() {
        return (
            <div className={clsx("property-item row", this.props.className)}>
                <div title={this.props.label} className="property-text">
                    <div className="title">{this.props.text || this.props.label.split(/(?=[A-Z])/).map(item => item.charAt(0).toUpperCase() + item.slice(1) + " ")}</div>
                    <div className="sub-title">{this.props.description || this.props.label}</div>
                </div>
                <div className="col">
                    {this.props.imageSource && (
                        <img className="image-string-field" src={this.props.imageSource} alt="" />
                    )}
                    {this.props.iconSource && (
                        <i style={{ background: "rgb(51 146 110)", padding: "8.5px", color: "#fff" }} className={this.props.iconSource}></i>
                    )}
                    <TextField
                        value={this.props.value}
                        className="custom-textfield"
                        style={{ resize: "vertical" }}
                        styles={{ fieldGroup: { height: this.props.multiline ? "auto !important" : "30px" } }}
                        multiline={this.props.multiline}

                        resizable={this.props.multiline ? true : false}
                        onChange={e => {
                            const value = (e.target as HTMLInputElement).value;
                            this.props.onChange(this.props.type === "number" ? parseInt(value) : value);
                        }}
                        type={this.props.type || "text"}
                    />
                    {this.props.customRender && this.props.customRender()}
                </div>
            </div>
        );
    }
}

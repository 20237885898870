export function createContentTypePostsLink(contentTypeId: string) {
    return `/posts/${contentTypeId}`;
}

export function createContentTypeCategoriesLink(contentTypeId: string, fromPost?: boolean) {
    return `/categories/${contentTypeId}?fromPost=${fromPost || false}`;
}

export function createContentTypePostEditLink(contentTypeId: string, postId: string) {
    return `/post/${contentTypeId}/edit?url=${postId}`;
}

export function createContentTypePostNewLink(contentTypeId: string) {
    return `/post/${contentTypeId}/create`;
}

export function createOrderViewLink(orderId: string) {
    return `/e-commerce/order/${orderId}`
}

export function createMemberViewLink(memberId: string) {
    return `/e-commerce/member/${memberId}`
}

export function createHospitalEditlink(hospitalId: string) {
    return `/hospital/edit/${hospitalId}`;
}
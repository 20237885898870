import React from "react";
import { ITag, Label, TagPicker } from "office-ui-fabric-react";
import { HttpStatusCode } from 'app/shared';
import { IPost, mapPostFromEntity, PostService } from "app/services/post";

import ImageIcon from "assets/images/placeholder.png"

interface ProductPickerState {
    loading: boolean;
    products: IPost[];
    selectedProduct: IPost | null;
}

interface ProductPickerProps {
    contentType: string;
    onSelected: (product: IPost) => void;
    data: IPost;
    disabled?: boolean;
    isPage?: boolean;
    label?: string;
    className?: string;
}

export default class ProductPicker extends React.Component<ProductPickerProps, ProductPickerState> {

    state = {
        loading: false,
        products: [] as IPost[],
        selectedProduct: {} as IPost
    };

    searchTimeout: number | NodeJS.Timeout = 0;

    onFilterChange = (filterText: string) => {

        clearTimeout(this.searchTimeout as number);

        if (!filterText ||
            filterText.length < 2) {
            return false;
        }

        return this.filterOnProducts(filterText);
    };

    filterOnProducts(filterText: string) {
        return new Promise((resolve, reject) => {
            this.searchTimeout = setTimeout(async () => {
                this.setState({
                    loading: true
                });
                const service = new PostService();
                const response = await service.getPosts({ descending: false, page: 1, pageSize: 100, isPrivate: "false", title: filterText || "" }, this.props.contentType) || [];
                if (response.status === HttpStatusCode.OK) {
                    const mapped = (response.data.attributes.list || []).map(item => mapPostFromEntity(item))
                    resolve(mapped)
                }
                reject()
            }, 1000);
        })

    }

    onItemSelected = (item: ITag | IPost) => {
        this.props.onSelected(item as IPost);
    };

    renderSuggestionsItem = (item: ITag | IPost) => {
        const data = item as IPost;
        return (
            <div className="sp-suggestion-item">
                <div className="sg-item-figure">
                    <img
                        className="table-image"
                        src={ImageIcon}
                        width="40"
                        height="30"
                        alt="Ürün"
                    />
                </div>
                <div className="sg-item-article">
                    <div className="name">{data.title}</div>
                    {!this.props.isPage && (
                        <React.Fragment>
                            <div className="dimension">
                                <strong>Stok Kodu: </strong> {(data.specFields || []).find(x => x.name === "stokKodu")?.data || "-"}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    };

    renderItem = () => {
        if (!this.props.data || Object.keys(this.props.data).length <= 0) return null
        const item = this.props.data;
        return (
            <div key={item?.id} className="stock-picker-item">
                {item.title}
                <div
                    onClick={() => this.setState({ selectedProduct: {} as IPost }, () => this.props.onSelected({} as IPost))}
                    className="remove"
                >
                    <i className="icon-close1" />
                </div>
            </div>
        );
    };

    render() {
        const product = Object.keys(this.props.data).length > 0 ? this.props.data : null;
        return (
            <div className={this.props.className ? `stock-picker-container ${this.props.className}` : "stock-picker-container"}>
                {!this.props.isPage && (
                    <Label>Ürün Seçiniz</Label>
                )}
                {this.props.label && (
                    <Label>{this.props.label}</Label>
                )}
                <TagPicker
                    inputProps={{
                        placeholder: "Arama Yapınız",
                        autoFocus: true
                    }}
                    onRenderSuggestionsItem={this.renderSuggestionsItem}
                    onRenderItem={(): any => this.renderItem()}
                    onResolveSuggestions={(filterText: string): any => this.onFilterChange(filterText)}
                    onItemSelected={(data): any => this.onItemSelected(data as ITag)}
                    className={product ? "stock-picker selected" : "stock-picker"}
                    pickerSuggestionsProps={{
                        suggestionsHeaderText: "Tümünde Ara",
                        noResultsFoundText: "Sonuç Bulunamadı.",
                        className: "stock-picker-suggestions"
                    }}
                    disabled={this.props.disabled}
                    selectedItems={product ? [{ ...product, key: product?.id as string, name: product?.title, }] : undefined}
                />
            </div>
        );
    }
}


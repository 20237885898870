import * as React from "react";
import { BaseFieldProps } from "./types";
import { Icon } from "office-ui-fabric-react";
import {
    SchemaNode,
    PropertyMappingType,
    PropertyDefinition
} from "app/library/layout-builder";
import StringField from "./StringField";
import VideoField from "./VideoField";
import ImageField from "./ImageField";
import IconField from "./IconField";
import ToggleField from "./ToggleField";
import ColorField from "./ColorField";
import AlignField from "./AlignField";
import DropdownField from "./DropdownField";
import { capitalize } from 'lodash';
import ParentObjectField from './ParentObjectField';
import LinkField from './LinkField';
import HTMLField from './HTMLField';
import WebsiteField from './WebsiteField';
import SrcSetField from './SrcSetField';
import DateTimeField from "./DateTimeField";
import DateTimeField2 from "./DateTimeField2";
import { PureProductField } from './Pure/PureProductField';
import { toggleModal } from 'app/redux/modal/actions';
import { ArrayField } from '.';
import { ReactSortable } from "react-sortablejs";
import DynamicFilterField from "./DynamicFilter/DynamicFilterField";
import BrandField from "./BrandField";
import FileField from "./FileField";

export interface AccordionFieldProps extends BaseFieldProps {
    node: SchemaNode;
    sortableMode: boolean;
    toggleModal: typeof toggleModal;
}

export interface AccordionFieldState {
    activeAccordion: string;
    iconPanelActive: string;
}

// Kendisine gelen Property ismine ve değerine göre Accordion Item'ları render eder.

export default class AccordionField extends React.Component<AccordionFieldProps, AccordionFieldState> {

    state = {
        activeAccordion: "",
        iconPanelActive: ""
    }

    onChangeProperty(key: string, value: any, index: number) {
        this.props.value[index][key] = value;
        this.props.onChange(this.props.value);
    }

    renderField(
        prop: PropertyDefinition,
        item: object,
        node: SchemaNode,
        index: number,
        innerPropKey: number
    ) {
        switch (prop.mapping.type) {
            case PropertyMappingType.String:
            case PropertyMappingType.Number:
            case PropertyMappingType.Any:
            case PropertyMappingType.Textarea:
                return (
                    <StringField
                        property={prop}
                        value={item[prop.name]}
                        multiline={prop.mapping.type === PropertyMappingType.Textarea}
                        key={innerPropKey}
                        index={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value, index)}
                        type={prop.mapping.type === PropertyMappingType.Number ? "number" : "text"}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}
                    />
                );
            case PropertyMappingType.Video:
                return (
                    <VideoField
                        property={prop}
                        node={node}
                        value={item[prop.name]}
                        key={innerPropKey}
                        index={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Image:
                return (
                    <ImageField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        index={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.File:
                return (
                    <FileField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        index={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Icon:
                return (
                    <IconField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        index={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value, index)}
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Boolean:
                return (
                    <ToggleField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        index={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Color:
                return (
                    <ColorField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.DynamicFilter:
                return (
                    <DynamicFilterField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.LinkWizard:
                return (
                    <LinkField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Html:
                return (
                    <HTMLField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}
                    />
                );
            case PropertyMappingType.Brand:
                return (
                    <BrandField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}
                    />
                );
            case PropertyMappingType.Alignment:
                return (
                    <AlignField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.ArrayData:
            case PropertyMappingType.ProductData:
                return (
                    <ArrayField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        children
                        language={this.props.language}


                    />
                );
            case PropertyMappingType.Object:
                return (
                    <DropdownField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.ParentObject:
                return (
                    <ParentObjectField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={node}
                        index={innerPropKey}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Website:
                return (
                    <WebsiteField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={node}
                        index={innerPropKey}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.SrcSet:
                return (
                    <SrcSetField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={node}
                        index={innerPropKey}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.DateTime:
                return (
                    <DateTimeField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={node}
                        index={innerPropKey}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.DateTime2:
                return (
                    <DateTimeField2
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={node}
                        index={innerPropKey}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Product:
                return (
                    <PureProductField
                        value={item[prop.name]}
                        onToggleProductPicker={this.props.toggleModal}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                    />
                );
            default:
                return null;
        }
    }

    renderItems() {
        const sahaNews = this.props.property.name === "sahaNews";
        return (this.props.value || []).map((item: any, key: number) => (
            <div
                key={key}
                className={
                    this.state.activeAccordion ===
                        this.props.property.name + key
                        ? "fa-Accordion-item active"
                        : "fa-Accordion-item"
                }
            >
                <div className="title row">
                    <div
                        onClick={() => {
                            this.setState(
                                {
                                    iconPanelActive: ""
                                },
                                () => {
                                    this.setState({ activeAccordion: this.state.activeAccordion !== this.props.property.name + key ? this.props.property.name + key : "" })
                                }
                            );
                        }}
                        className="text col no-padding"
                    >
                        {capitalize(this.props.property.name + " " + (key + 1)) + ` (${(item?.title || "-")})`}

                    </div>
                   {!sahaNews &&
                    <Icon
                        onClick={() => {
                            this.props.value.splice(key, 1);
                            this.props.onChange(this.props.value);
                        }}
                        className="m-left-auto remove-accordion"
                        iconName="ChromeClose"
                    />
                   }
                </div>
                {this.state.activeAccordion ===
                    this.props.property.name + key && (
                        <div className="content">
                            <div className="property-list">
                                {(this.props.property?.mapping?.getKeys ? this.props.property.mapping.getKeys(this.props) : this.props.property?.mapping.keys || []).map((innerProp, innerPropIndex) =>
                                    this.renderField(
                                        innerProp,
                                        item,
                                        this.props.node,
                                        key,
                                        innerPropIndex
                                    )
                                )}
                            </div>
                        </div>
                    )}
            </div>
        ))
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        return (
            <div className="external-items">
                <div className="fa-Accordion">
                    {this.props.sortableMode ? (
                        <ReactSortable
                            list={this.props.value || []}
                            setList={(newValue: any[]) => this.props.onChange(newValue)}
                            animation={150}
                        >
                            {this.renderItems()}
                        </ReactSortable>
                    ) : this.renderItems()}
                </div>
            </div>
        );
    }
}


import { Component } from 'react';

import { IMetaDate } from 'app/services/content-type';
import { BaseMetaFieldsProps } from './types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { DateUtils } from 'react-day-picker';
import moment from "moment";
import 'react-day-picker/lib/style.css';

let debounceTimeout: number | NodeJS.Timeout = 0;

export default class MetaDate extends Component<BaseMetaFieldsProps<IMetaDate>> {

    handleDayChange = (date: Date) => {
        if (debounceTimeout) clearTimeout(debounceTimeout as number);
        debounceTimeout = setTimeout(() => {
            this.props.onChange(convertCallbackDate(date))
        }, 600)
    }

    render() {
        return (
            <DayPickerInput
                value={this.props.meta.data?.fullDate || dateFnsFormat(new Date(), "dd.MM.yyyy")}
                placeholder={`${dateFnsFormat(new Date(), "dd.MM.yyyy")}`}
                format="dd.MM.yyyy"
                onDayChange={this.handleDayChange}
                dayPickerProps={{
                    locale: "tr",
                    months: MONTHS["tr"],
                    weekdaysLong: WEEKDAYS_LONG["tr"],
                    weekdaysShort: WEEKDAYS_SHORT["tr"],
                    firstDayOfWeek: FIRST_DAY_OF_WEEK["tr"],
                    labels: LABELS["tr"]
                }}
                parseDate={parseDate}
                formatDate={formatDate}
            />
        )
    }
}


function parseDate(str: string, format: string) {
    const parsed = dateFnsParse(str, format, new Date());
    if (DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
}

function formatDate(date: Date, format: string, locale: string) {
    return dateFnsFormat(date, format);
}

function convertCallbackDate(date: Date) {
    const formatted = moment(date).format("DD.MM.YYYY");
    const splitted = formatted.split(".");
    return {
        day: parseInt(splitted[0]),
        month: parseInt(splitted[1]),
        year: parseInt(splitted[2]),
        fullDate: formatted
    }
}

const WEEKDAYS_SHORT = {
    tr: ['Pz', 'Pt', 'Sa', 'Ca', 'Pe', 'Cu', 'Ct'],
};
const MONTHS = {
    tr: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
    ]
};

const WEEKDAYS_LONG = {
    tr: [
        'Pazar',
        'Pazartesi',
        'Salı',
        'Çarşamba',
        'Perşembe',
        'Cuma',
        'Cumartesi',
    ],
};

const FIRST_DAY_OF_WEEK = {
    tr: 1,
};

const LABELS = {
    tr: { nextMonth: 'Sonraki Hafta', previousMonth: 'Önceki Hafta' },
};
import { DynamicFilterTypes } from "app/shared";

export const filterTypes = [
    {
        key: DynamicFilterTypes.Static,
        text: "Statik"
    },
    {
        key: DynamicFilterTypes.Post,
        text: "İçerik"
    },
    {
        key: DynamicFilterTypes.Meta,
        text: "Meta"
    }
]


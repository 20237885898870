import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
  PropertyDefinition,
} from "app/library/layout-builder";
import { getMenuDefinitions } from 'components/cms/definitions';

export class FeaturesDefinitions extends CmsReactComponent<CmsReactComponentProps> {
  static defaultProps = {};

  static schemaName = "features";
  static schemaType = "navigation-component";

  static propertyDefinitions: PropertyDefinition[] = [
    ...getMenuDefinitions("menu", "Navigasyon"),
  ];
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { MetaTypeEnum } from "app/shared";
import { FooterProps } from "./types";
import { EntryOptions, PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { getMenuDefinitions } from 'components/cms/definitions';


export class FooterDefinitions extends CmsReactComponent<FooterProps> {
  static defaultProps = {};

  static schemaName = "footer";
  static schemaType = "navigation-component";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "sahaistanbullogo",
      text: "Saha Istanbul logo",
      mapping: {
        type: PropertyMappingType.Image
      }
    },
    {
      name: "ifmlogo",
      text: "İfm logo",
      mapping: {
        type: PropertyMappingType.Image
      }
    },
    ...getMenuDefinitions("menu1", "Menü 1"),
    ...getMenuDefinitions("menu2", "Menü 2"),
  ];

  static options: EntryOptions = {
    meta: {
      use: false,
      allowedTypes: [],
    },
    mappingFields: [
      {
        name: "sahaistanbullogo",
        text: "Saha Istanbul logo",
        types: [MetaTypeEnum.SingleImage]
      },
      {
        name: "ifmlogo",
        text: "İfm logo",
        types: [MetaTypeEnum.SingleImage]
      },
    ],
  };
}
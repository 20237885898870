import { ContainerEntry, SchemaNode } from 'app/library/layout-builder';
import { ICategory } from 'app/services/category';
import { IUser } from 'app/services/user';
import { IQueryBuilder } from 'app/services/query-builder';
import { ModalState, ModalActionTypes, TOGGLE_MODAL, SET_MODAL_DATA, SET_MODAL_ERRORS } from './types';
import { IBucket } from 'app/services/configuration';
import { IRole } from 'app/services/identity';

const initialState: ModalState = {
	properties: {
		show: false,
		data: {
			node: {} as SchemaNode,
			entry: {} as ContainerEntry,
			schema: [],
			dynamics: []
		}
	},
	localDataWizard: {
		show: false,
		data: {
			query: {} as IQueryBuilder,
			node: {} as SchemaNode
		}
	},
	customServiceWizard: {
		show: false,
		data: {
			service: "",
			mapping: [],
			node: {} as SchemaNode
		}
	},
	category: {
		show: false,
		data: {} as ICategory
	},
	user: {
		show: false,
		data: {} as IUser
	},
	bucket: {
		show: false,
		data: {} as IBucket
	},
	role: {
		show: false,
		data: {} as IRole
	}
};

export function modalReducer(state = initialState, action: ModalActionTypes): ModalState {
	switch (action.type) {
		case TOGGLE_MODAL:
			return {
				...state,
				[action.id]: {
					show: !state[action.id].show,
					data: action.data,
					errors: {},
					callback: action.callback
				}
			};
		case SET_MODAL_DATA:
			return {
				...state,
				[action.id]: {
					...state[action.id],
					data: action.data
				}
			};
		case SET_MODAL_ERRORS:
			return {
				...state,
				[action.id]: {
					...state[action.id],
					errors: action.errors
				}
			};
		default:
			return state;
	}
}
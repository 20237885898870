import _ from "lodash";
import { IThemeLocale, IThemeSettings, ThemeSettingsEntity } from "./types";

export function mapConfigurationFromModel(data: IThemeSettings): ThemeSettingsEntity {
    const configuration = data.config;

    const model = {
        themes: {
            dark: {
                colors: configuration?.themes?.dark?.colors || {},
                logo: configuration?.themes?.dark?.logo || "",
            },
            light: {
                colors: configuration?.themes?.light?.colors || {},
                logo: configuration?.themes?.light?.logo || "",
            }
        },
        status: data.status,
        settings: {
            prefix: configuration?.settings?.prefix || "",
            suffix: configuration?.settings?.suffix || "",
        },
        routes: configuration?.routes || {},
        styles: configuration?.styles,
        languages: configuration?.languages,
        locale: configuration?.locale || [],
        social: configuration?.social || {},
        translation: configuration?.translation || false,
        lang: data.language || "en",
    };
    let routes = {};
    let locale = {};

    if (model.routes && _.keys(model.routes).length > 0) {
        _.keys(model.routes).forEach(key => {
            routes[key] = model.routes[key].url
        })
    }

    if (model.locale && model.locale.length > 0) {
        model.locale.forEach((item: IThemeLocale) => {
            if (item.key) {
                locale[item.key] = item.value
            }
        })
    }

    return {
        config: {
            admin: model,
            ui: {
                ...model,
                routes,
                locale
            }
        },
        language: data.language
    }
}

export function mapConfigurationFromEntity(data: ThemeSettingsEntity): IThemeSettings {
    return {
        config: {
            ...data.config?.admin || {},
            languages: data.config?.admin?.languages && _.isArray(data.config?.admin.languages) ? data.config?.admin.languages : [{ name: "EN", nativeName: "English", code: "en" }],
            locale: _.isArray(data.config?.admin?.locale) ? data.config.admin.locale : [{ key: "", value: "" }]
        },
        language: data.language || "en"
    }
}
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { QueryBuilderService } from "./service";
import { RootState } from "app/redux/reducer";
import { showMessage, toggleLoader } from "app/redux/system/actions";
import { MessageType } from "app/redux/system/types";
import { IQueryBuilder } from "./types";
import { mapQueryBuilderFromEntity, mapQueryBuilderFromModel } from "./mapping";
import { HttpStatusCode } from "app/shared";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getQuery(queryId: string): ThunkResult<Promise<IQueryBuilder | null>> {
    return async (dispatch) => {
        const service = new QueryBuilderService();
        dispatch(toggleLoader());
        const response = await service.getQuery(queryId);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapQueryBuilderFromEntity(response?.data?.attributes?.contentType)
            return mapped;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return null;
    };
}

export function createQuery(request: IQueryBuilder): ThunkResult<Promise<IQueryBuilder | null>> {
    return async (dispatch) => {
        const service = new QueryBuilderService();
        dispatch(toggleLoader());
        const response = await service.createQuery(mapQueryBuilderFromModel({ ...request, id: "" }));
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return mapQueryBuilderFromEntity(response?.data?.attributes?.contentType || {});
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return null;
    };
}
import { LayoutContainerDefinitions } from "./LayoutContainerDefinitions";
import LayoutContainerDesigner from "./LayoutContainerDesigner";

export class LayoutContainer extends LayoutContainerDefinitions {
  render() {
    return (
      <div
        data-schema-type="page-content"
        data-schema-id={this.props.id}
        className={
          this.props.designing
            ? "designing fa-draggable fa-Layout-Container"
            : "fa-draggable"
        }
        style={{
          minHeight: this.props.designing ? "300px" : "auto",
          padding: "0px",
          position: "relative",
        }}
      >
        {this.props.designing ? (
          <LayoutContainerDesigner {...this.props} />
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

import { PageHeaderDefinitions } from "./PageHeaderDefinitions";
import ComponentHeader from "components/cms/ComponentHeader";
import ComponentDescription from "components/customs/ComponentDescription";

export class PageHeader extends PageHeaderDefinitions {
    render() {
        const { className, designing } = this.props;
        return (
            <div
                data-schema-id={this.props.id}
                className={[
                    "fa-draggable fa-PageHeaderList",
                    designing && "designing",
                    className,
                ]
                    .filter((e) => !!e)
                    .join(" ")}
            >
               {designing && (
                    <ComponentHeader
                        componentEntry={this.props.entry}
                        componentSchemaId={this.props.id as string}
                    />
                )}
                <ComponentDescription
                    node={this.props.schemaNode}
                    entry={this.props.entry}
                />
            </div>
        );
    }
}

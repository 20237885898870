import { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from "app/redux/reducer";
import { bindActionCreators, Dispatch } from 'redux';
import PageTitle from 'components/customs/PageTitle';
import { Link, RouteComponentProps } from 'react-router-dom';
import BasicTable from 'components/customs/BasicTable'; import { TextField, Dropdown, PrimaryButton } from 'office-ui-fabric-react';
import { IListState, IList, IListFilter, StatusEnum } from 'app/shared';
import { castThunkAction } from 'app/utils/casting';
import { toggleConfirmDialog } from 'app/redux/system/actions';
import { deleteContentType, getContentTypes, IContentType } from 'app/services/content-type';
import { deleteRecovery, getRecoveries, IRecovery, updateRecovery } from 'app/services/recovery';
import PageLayout from 'containers/PageLayout';
import CustomDayPickerInput from 'components/customs/DayPickerInput';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
	return {
		...ownProps,
		language: state.system.language,
		contentTypes: state.contentType.contentTypes as IList<IContentType[]>,
		pageData: state.page.pageData as IList<IRecovery[]>
	}
}

function mapDispatchToProps(dispatch: Dispatch) {
	return bindActionCreators({
		getContentTypes,
		getRecoveries,
		updateRecovery,
		deleteRecovery,
		deleteContentType,
		toggleConfirmDialog,
	}, dispatch);
}

type IListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ListContentType extends Component<IListProps, IListState> {

	state = {
		type: "contenttype",
		filterObject: {
			title: "",
			status: 1,
			createdOn: ""
		},
		pagerObject: {
			page: 1,
			descending: false,
			pageSize: 10
		},
		activeStatus: StatusEnum.Published
	}

	componentDidMount() {
		this.makeRequest({ ...this.state.pagerObject, ...this.state.filterObject });
		window.addEventListener("keydown", this.onEnterSearch, true);
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.onEnterSearch, true);
	}

	onEnterSearch = (e: any) => {
		if (e.key === "Enter") {
			this.searchList();
		}
	};

	getList() {
		if (this.state.activeStatus !== StatusEnum.Deleted) {
			return this.props.contentTypes
		}
		return this.props.pageData
	}

	searchList() {
		this.setState({
			pagerObject: {
				page: 1,
				descending: false,
				pageSize: 10
			},
		}, () => {
			this.makeRequest({
				...this.state.pagerObject,
				...this.state.filterObject
			})
		})
	}

	makeRequest(options: IListFilter) {
		if (this.state.filterObject.status === StatusEnum.Deleted) {
			castThunkAction(this.props.getRecoveries(options, this.state.type))
				.then(() => {
					this.setState({
						activeStatus: StatusEnum.Deleted,
					})
				})
		} else {
			castThunkAction(this.props.getContentTypes(options)).then(() => {
				this.setState({
					activeStatus: this.state.filterObject.status,
				})
			})
		}
	}

	renderFilter() {
		return (
			<div className="table-filter row">
				<TextField
					className="custom-textfield col-md-2"
					label="Başlık İle Arama"
					value={this.state.filterObject?.title || ""}
					onChange={(event: any) => {
						this.setState({
							filterObject: {
								...this.state.filterObject,
								"title": event.target.value
							}
						})
					}}
				/>
				<Dropdown
					options={[
						{ key: StatusEnum.Draft, text: "Taslak" },
						{ key: StatusEnum.Published, text: "Yayında" },
						{ key: StatusEnum.Deleted, text: "Silinmiş" }
					]}
					className="custom-dropdown col-md-2"
					label="Duruma Göre Arama"
					placeholder="Seçiniz"
					selectedKey={this.state.filterObject?.status}
					onChange={(event: any, option: any) => {
						this.setState({
							filterObject: {
								...this.state.filterObject,
								"status": option.key
							}
						})
					}}
					calloutProps={{
						className: "custom-dropdown-callout"
					}}
				/>
				<CustomDayPickerInput onChange={(createdOn: string) => this.setState({
					filterObject: {
						...this.state.filterObject,
						createdOn
					}
				})} />
				<PrimaryButton
					text="Arama Yap"
					className="custom-button"
					onClick={() => {
						this.searchList();
					}}
					styles={{
						root: {
							fontSize: "11px",
							marginTop: "27px",
							height: "37px"
						}
					}}
				/>
			</div>
		)
	}




	renderList() {
		return (
			<BasicTable
				totalCount={this.getList().totalCount}
				columns={[
					{
						columnName: "title",
						text: "İçerik Tipi Adı",
						col: "col-md-12"
					}
				]}
				items={this.getList().list}
				renderOperations={(item: IContentType) => {
					return (this.state.activeStatus !== StatusEnum.Deleted) ?
						<>
							<Link to={`/content-type/edit/${item.id}`}><i className="icon-edit-outline"></i> Düzenle</Link>
							{item.removable && (
								<button onClick={() => {
									this.props.toggleConfirmDialog(true, {
										callback: () => {
											castThunkAction(this.props.deleteContentType(item.id as string)).then(res => {
												if (res) {
													this.makeRequest({ ...this.state.pagerObject, ...this.state.filterObject })
												}
											})
										}
									})
								}}><i className="icon-trash-outline"></i> Sil</button>
							)}
						</>

						: <>
							{/* eslint-disable-next-line */}
							<a onClick={() => {
								castThunkAction(this.props.updateRecovery(this.state.type, item.id as string)).then(res => {
									if (res) {
										this.searchList()
									}
								})

							}}><i className="icon-trash-outline"></i> Kurtar</a>
							<button onClick={() => {
								this.props.toggleConfirmDialog(true, {
									callback: () => {
										castThunkAction(this.props.deleteRecovery(item.id as string)).then(res => {
											if (res) {
												this.searchList()
											}
										})
									}
								})
							}}><i className="icon-trash-outline"></i> Kalıcı Olarak Sil</button>
						</>
				}}
				showPager={true}
				pageCount={this.getList().pageCount}
				onChangePageSize={(pageSize) => {
					this.setState({
						pagerObject: {
							...this.state.pagerObject,
							pageSize
						}
					}, () =>
						this.makeRequest({
							...this.state.pagerObject,
							...this.state.filterObject,
							pageSize
						})
					)
				}}
				onChangePager={(pageNumber) => {
					this.setState({ pagerObject: { ...this.state.pagerObject, page: pageNumber } }, () => this.makeRequest({
						...this.state.pagerObject,
						...this.state.filterObject,
						page: pageNumber
					}))
				}}
				pageIndex={this.state.pagerObject.page - 1}
			/>
		)
	}

	render() {
		return (
			<PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
				<div className="page push-all">
					<PageTitle
						title="İçerik Tipi Yönetimi"
						subTitle="Tüm içerik tiplerini aşağıdan görüntüleyebilir, düzenleme yapabilir veya yeni bir içerik tipi ekleyebilirsiniz."
						backUrl="/"
						showBack
						history={this.props.history}
					/>
					<PrimaryButton
						onClick={() => this.props.history.push("/content-type/create")}
						iconProps={{ iconName: "Add" }}
						className="absolute-create"
						text="Yeni İçerik Tipi Ekle"
						styles={{
							root: {
								background: "#62a98e",
								borderColor: "#5b9e85",
								color: "#fff"
							},
							rootHovered: {
								background: "#5b9e85",
								borderColor: "#5b9e85"
							},
							rootDisabled: {
								background: "#ececec"
							}
						}}
					/>
					{this.renderFilter()}
					{this.renderList()}
				</div>
			</PageLayout>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContentType);
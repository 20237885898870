import React from 'react';

class UndertakingText extends React.Component {
  render() {
    return (
      <div className="page" style={{ margin: '0 auto', maxWidth: '1100px', minHeight: '100vh', background: "#fff" }}>
        <div className="auth-page-header">
          <div className="auth-page-brand">Üye Kurum Etik Beyan Taahhütnamesi</div>
        </div>
        <br />
        <style>{`
            li, p {
              font-size: 14px;
              margin-top: 6px;
              line-height: 1.5;
            }
        `}</style>
        <p>
          USHAŞ tarafından yönetilen www.turkishhealthcare.com portalına üye kurum olarak dahil olmayı istediğimizi, üye kurum olarak;
        </p>

        <li>Yürürlükteki mevzuat hükümlerine, uluslararası anlaşmalar çerçevesinde belirlenen kurallar ile tüm etik kurallara  uymayı, yasal ve etik düzenlemeleri ihlâl etmemeyi, buna aykırılık nedeniyle doğacak idari, mali, hukuki ve cezai tüm sorumlulukları üstlendiğimizi,</li>
        <li>Sağlık Bakanlığı Sağlık Etik Komisyonunca bildirilen “Sağlık Meslek Mensupları için Temel Etik İlkeler” konusunda duyarlı olacağımızı,</li>
        <li>Sağlık Turizmi Portalı’nda kurumumuz adına beyan ettiğimiz bilgilerin doğrulunu ve güncelliği konusunda duyarlı olacağımızı, doğru ve güncel olmayan bilgi paylaşımından doğacak zararlardan dolayı USHAŞ’ın bir sorumluluğu olmadığını bildiğimizi,</li>
        <li>Kurumumuza dair bilgilerin doğruluğuna yönelik olarak yapılacak her türlü denetimi kabul ettiğimizi, farklılıklar tespit edilmesi halinde uygulanacak tüm müeyyideleri peşinen kabul ettiğimizi,</li>
        <li>Sağlık Turizmi Portalı’nda sunmayı taahhüt ettiğimiz sağlık hizmetlerinin tüm sorumluluğunu üstlendiğimizi,</li>
        <li>Uluslararası platformlarda ulusal ve uluslararası Sağlık Turizmi politikalarımızı ve stratejilerimizi destekleyeceğimizi,</li>
        <li>Faaliyetlerimizde ülke itibarına zarar verebilecek etkinliklerden kaçınacağımızı, bunlardan doğabilecek tüm zararlardan doğrudan sorumlu olduğumuzu,</li>
        <li>Sağlık tesisimize ilişkin sağlık turizmi yetki belgesi başta olmak üzere, ruhsat, işletme belgesi, akreditasyonlar, bilgi girişi ve değişikliklerinden sorumlu personeller vb. tüm hususlarda değişiklikleri, değişiklik oluştuktan sonra en geç 7 gün içerisinde USHAŞ’a bildireceğimizi,</li>
        <li>Sağlık Turizmi Portalı’nda yayınlanacak üye kurum içeriklerine yönelik düzenlemelere ve kurallara uyacağımızı,</li>
        <li>Sağlık Turizmi Portalı’nda tanıtım amacıyla yayınlanması planlanan içeriklere özel her türlü tanıtım materyalinin sağlanması, bilgi/belge temini ve teknik altyapının oluşturulması konusunda USHAŞ ile tam bir işbirliği içinde bulunacağımızı, bunlarla ilgili USHAŞ’ın taleplerine tam ve zamanında cevap vereceğimizi,</li>
        <li>Sağlık Turizmi Portalı’nda kurumumuzun tanıtımı için tarafımızdan yönlendirilecek içeriklerin (yazı, resim, fotoğraf, grafik, haber, yorum bilgi vb.) yayımlanmasından doğacak cezai sorumluluğun tarafımıza ait olduğunu bildiğimizi,</li>
        <li>USHAŞ’ın içeriklerinin yayınlanmasına yönelik kararlarda tek söz sahibi olduğunu, gönderilen tanıtım içeriklerini gerekçe göstermeden reddebileceğini bildiğimizi,</li>
        <li>Kurumumuzun Sağlık Turizmi Portalı’na kabul sürecinde tek yetkilinin USHAŞ olduğunu ve gerekçesiz olarak başvurumuzun reddedilebileceğini bildiğimizi,</li>
        <li>Sağlık Turizmi Portalı’nda yayınlanacak paydaş içeriklerinin yayınlanma zamanı, süresi, sırası vb. gibi organizasyonel kararların USHAŞ’a ait olduğunu bildiğimizi,</li>
        <li>USHAŞ’ın üye kurumlarla yapılan anlaşmaları dilediği zaman tek taraflı olarak değiştirme veya güncelleme hakkına sahip olduğunu, yapılacak değişiklikleri önceden kabul ettiğimizi,</li>
        <li>Portal üzerinde her türlü bilgiyi sağlamaya ve değişiklikleri yapmaya kişisel bilgileri USHAŞ’a bildirilen çalışanlarımızın yetkili olduğunu ve yaptıkları işlemlerden Kurumumuzun sorumlu olduğunu,</li>

        Kabul ve beyan ederiz.
      </div>
    );
  }
}

export default UndertakingText;

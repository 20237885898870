import { Component } from 'react';
import { Dropdown, PrimaryButton, Icon, TextField, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { FilePond } from 'react-filepond';
import cookie from "react-cookies";
import 'filepond/dist/filepond.min.css';
import { bindActionCreators, Dispatch } from 'redux';
import moment from 'moment';
import _ from "lodash";

import { HttpStatusCode } from 'app/shared';
import { fileTypes } from './constants';
import { api } from 'app/utils/api';
import { showMessage, toggleConfirmDialog } from 'app/redux/system/actions';
import { FileManagerService, FileTypes, IFile, IFileManager, IFileManagerListPagerObject, IFileManagerState, mapFileFromEntity } from 'app/services/file-manager';
import { getCdnEndpoint, getFilePath } from 'app/utils/service';

import ImageFile from "assets/images/type-file.jpg"
import ImageVideo from "assets/images/type-video.jpg"
import { RootState } from 'app/redux/reducer';
import { toggleModal } from 'app/redux/modal/actions';
import { MessageType } from 'app/redux/system/types';
import { connect } from 'react-redux';


function mapStateToProps(state: RootState, ownProps: IFileManager) {
    return ownProps;
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            toggleModal,
            toggleConfirmDialog,
            showMessage
        }, dispatch)
    };
}

type FileManagerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class FileManager extends Component<FileManagerProps, IFileManagerState> {

    state = {
        uploadScreenVisibility: false,
        filePanelVisibility: false,
        loaderVisibility: false,
        fileList: [] as IFile[],
        activeFiles: [] as IFile[],
        filterObject: {
            pageSize: 40,
            totalPage: 1,
            fileExtensions: this.props.fileType as FileTypes || FileTypes.All,
            pageIndex: 0,
            fileName: ""
        },
        buckets: [] as string[],
        activeBucket: "other",
        filePanelData: {} as IFile
    };

    UNSAFE_componentWillReceiveProps(nextProps: IFileManager) {
        if (nextProps.show && nextProps.show !== this.props.show) {
            this.setState({
                activeFiles: nextProps.activeFile ? [nextProps.activeFile] : nextProps.activeFiles || [],
                loaderVisibility: true
            }, () => {
                this.getBucketList().then(() => {
                    this.getFileList().then(() => this.setState({ loaderVisibility: false }));
                });
            })
        }
    }

    async getBucketList() {
        const fileManagerService = new FileManagerService();

        const response = await fileManagerService.getBucketList();
        const mapped = (response.data?.attributes?.list || [{ name: "other" }]).map((item) => item.name);

        this.setState({
            buckets: mapped
        })
    }

    setActiveFileData(item: IFile, onlyShow?: boolean, onlyFill?: boolean) {
        if (this.state.filePanelData.id === item.id && !onlyShow) {
            this.setState({
                filePanelVisibility: false,
                filePanelData: {} as IFile
            })
        }
        else {
            this.setState({
                filePanelVisibility: onlyFill ? false : true,
                filePanelData: item
            })
        }
    };

    onTransferedFile = (file: IFile) => {
        if (file) {
            this.setState({
                fileList: [file, ...this.state.fileList]
            })
        }
    };

    async onRemoveFile(file: IFile) {
        const { fileList } = this.state;
        const fileManagerService = new FileManagerService();
        this.setState({ loaderVisibility: true })
        const response = await fileManagerService.deleteFile(file.fileName);
        if (response && response.status === HttpStatusCode.OK) {
            this.toggleFileActive(file);
            fileList.splice(this.findFileIndexInAllFiles(file), 1);
            this.setState({
                fileList,
                loaderVisibility: false,
                filePanelData: {} as IFile,
                filePanelVisibility: false
            })
        }
        else {
            this.setState({ loaderVisibility: false });
        }
    }

    onSelectedFiles = (selectedFiles: IFile[]) => {
        this.props.onDismiss();
        if (this.props.onSelectedFiles) {
            this.props.onSelectedFiles(selectedFiles);
        }
        if (this.props.onSelectedFile) {
            this.props.onSelectedFile(selectedFiles.length > 0 ? selectedFiles[0] : {} as IFile);
        }
    }

    onClickPreviousPage = () => {
        const { filterObject } = this.state;
        if (filterObject.pageIndex > 0) {
            this.setState({ loaderVisibility: true })
            filterObject.pageIndex -= 1;
            this.setState({
                filterObject
            }, () => {
                this.getFileList().then(r => {
                    this.setState({ loaderVisibility: false })
                })
            })
        }

    }

    onClickNextPage = () => {
        const { filterObject } = this.state;
        if (filterObject.pageIndex + 1 < filterObject.totalPage) {
            this.setState({ loaderVisibility: true });
            filterObject.pageIndex += 1;
            this.setState({
                filterObject
            }, () => {
                this.getFileList().then(r => {
                    this.setState({ loaderVisibility: false })
                })
            })
        }
    };

    onFilterByKey(key: string, value: any) {
        const { filterObject } = this.state;
        filterObject.pageIndex = 0;
        filterObject[key] = value;
        this.setState({
            filterObject
        })
    };

    onSearchInFiles = () => {
        this.setState({ loaderVisibility: true });
        this.getFileList().then(r => {
            this.setState({ loaderVisibility: false });
        })
    }


    onDismiss = () => {
        this.props.onDismiss();
    }

    async onDeleteBucket() {
        const fileManagerService = new FileManagerService();

        this.setState({
            loaderVisibility: true
        });

        const response = await fileManagerService.deleteFile(this.state.activeBucket);

        if (response.status === HttpStatusCode.OK) {
            this.onChangeBucket("other", this.state.activeBucket);
        }
        else {
            this.setState({
                loaderVisibility: false
            }, () => {
                this.props.showMessage("Silmeye çalıştığınız klasör'ün içerisinde dosyalar bulunduğundan silinemedi. Klasör'ün içerisindeki dosyaları silip, tekrar deneyebilirsiniz.", "Bir Hata Oluştu!", MessageType.ERROR)
            })
        }
    }

    onChangeBucket(bucketName: string, removedBucket?: string) {
        let buckets = _.cloneDeep(this.state.buckets);

        if (removedBucket) {
            buckets = buckets.filter(x => x !== removedBucket);
        }

        this.setState({
            activeBucket: bucketName,
            loaderVisibility: true,
            filterObject: {
                ...this.state.filterObject,
                pageIndex: 0
            },
            buckets
        }, () => {
            this.getFileList().then(() => {
                this.setState({ loaderVisibility: false })
            });
        })
    }


    findFileType(extension: string): FileTypes {
        if (FileTypes.File.includes(extension)) {
            return FileTypes.File
        }
        if (FileTypes.Video.includes(extension)) {
            return FileTypes.Video
        }
        return FileTypes.Image
    }

    findFileTypeName(fileType: FileTypes): string {
        if (fileType === FileTypes.File) {
            return "Dosya Öğeleri"
        }
        if (fileType === FileTypes.Video) {
            return "Videolar"
        }
        if (fileType === FileTypes.Image) {
            return "Görseller"
        }
        return "Tümü"
    }

    findFileInActiveFiles(file: IFile) {
        return (this.state.activeFiles || []).find(item => item.fileName === file.fileName);
    };

    findFileIndexInActiveFiles(file: IFile) {
        return (this.state.activeFiles || []).findIndex(item => item.fileName === file.fileName);
    };

    findFileIndexInAllFiles(file: IFile) {
        return (this.state.fileList || []).findIndex(item => item.fileName === file.fileName);
    };

    toggleFileActive(file: IFile) {
        let { activeFiles } = this.state;
        const { multipleSelection } = this.props;
        if (this.findFileInActiveFiles(file)) {
            activeFiles.splice(this.findFileIndexInActiveFiles(file), 1);
            this.setActiveFileData(file, false, true);
        }
        else {
            if (multipleSelection) {
                activeFiles.push(file);
            }
            else {
                activeFiles = [file]
                this.setActiveFileData(file, false, true);
            }
        }
        this.setState({
            activeFiles
        })
    }

    async getFileList() {
        const { filterObject, activeBucket } = this.state;
        const fileManagerService = new FileManagerService();
        const request: IFileManagerListPagerObject = {
            page: filterObject.pageIndex + 1,
            fileExtensions: filterObject.fileExtensions,
            pageSize: filterObject.pageSize,
            fileName: filterObject.fileName,
            descending: true,
            bucketName: activeBucket
        }
        const response = await fileManagerService.getFiles(request);
        return new Promise((resolve, reject) => {
            if (response && response.status === HttpStatusCode.OK) {
                const mapped = (response.data.attributes.files || []).map(item => mapFileFromEntity(item));
                this.setState({
                    fileList: mapped,
                    filterObject: { ...filterObject, totalPage: Math.ceil(response.data.attributes.total / response.data.attributes.pageSize) }
                });
                resolve(mapped);
            }
        });
    }

    renderSearch() {
        const { filterObject } = this.state;
        return (
            <div className="bucket-search row">
                <div className="name row">
                    <div className="title">Dosya Adı</div>
                    <TextField
                        onChange={(e: any) => this.onFilterByKey("fileName", e.target?.value)}
                        value={filterObject.fileName || ""}
                        className="custom-textfield"
                        placeholder="Dosya Adı"
                    />
                </div>
                <div className="name row">
                    <div className="title">Dosya Tipi</div>
                    <Dropdown
                        className="custom-dropdown"
                        calloutProps={{ className: "custom-dropdown-callout" }}
                        disabled={this.props.fileType ? true : false}
                        placeholder="Type"
                        selectedKey={filterObject.fileExtensions || ""}
                        onChange={(e, value) => this.onFilterByKey("fileExtensions", value?.key)}
                        options={fileTypes}
                    />
                </div>
                <PrimaryButton onClick={this.onSearchInFiles} text="Arama Yap" iconProps={{ iconName: "Search" }} />
            </div>
        )
    }

    renderFileList() {
        const { fileList } = this.state;
        return (
            <div className="bucket-files row">
                {(fileList || []).map((item, index) => (
                    <div
                        key={index}
                        className={this.findFileInActiveFiles(item) ? "item active" : "item"}>
                        <div
                            className="item-wrapper"
                        >
                            <Icon onClick={event => this.setActiveFileData(item)} className="info" iconName="Info" />
                            <Icon className="check" iconName="CheckMark" />
                            <figure onClick={() => this.toggleFileActive(item)} className="full-image">
                                {this.findFileType(item?.fileExtension as string) === FileTypes.Video ? (
                                    <img src={ImageVideo} alt={item.fileName} />
                                ) :
                                    this.findFileType(item?.fileExtension as string) === FileTypes.File ? (
                                        <img src={ImageFile} alt={item.fileName} />
                                    ) : <img src={getFilePath() + item.fileName + (item.fileExtension !== ".svg" ? "?width=350&height=350" : "")} alt={item.fileName} />
                                }
                            </figure>
                            <div onClick={() => this.toggleFileActive(item)} className="title">
                                <span className="text">{item.fileName}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    renderHeader() {
        const { uploadScreenVisibility } = this.state;
        return (
            <div className="bucket-header row">
                <div className="title row">
                    <span className="text">
                        Klasör
                    </span>
                    <span className="active">
                        <Dropdown
                            options={(this.state.buckets || []).map(item => ({ key: item, text: item || "Tüm Klasörler" }))}
                            className="custom-dropdown dark"
                            selectedKey={this.state.activeBucket}
                            onChange={(_, option) => this.onChangeBucket(option?.key as string)}
                            calloutProps={{
                                className: "custom-dropdown-callout"
                            }}
                        />
                    </span>
                    {this.state.activeBucket && this.state.activeBucket !== "other" && (
                        <span
                            className="delete"
                            onClick={() => {
                                this.props.toggleConfirmDialog(true, {
                                    title: "Klasör Siliniyor",
                                    text: "Seçmiş olduğunuz klasör, eğer içerisinde herhangi bir dosya yok ise silinecek. Bu işlem geri alınamaz. Geçerli Klasör'ü silmek istiyor musunuz?",
                                    callback: () => this.onDeleteBucket()
                                })
                            }}
                        >
                            Geçerli Klasörü Sil
                        </span>
                    )}
                    <span
                        className="new"
                        onClick={() => {
                            this.props.toggleModal("bucket", { bucketName: "" }, (data) => {
                                if (!this.state.buckets.includes(data)) {
                                    this.setState({
                                        buckets: [...this.state.buckets, data.bucketName],
                                        activeBucket: data.bucketName,
                                        uploadScreenVisibility: false,
                                        loaderVisibility: true,
                                        filterObject: {
                                            pageSize: 40,
                                            totalPage: 1,
                                            fileExtensions: this.props.fileType || FileTypes.All,
                                            pageIndex: 0,
                                            fileName: ""
                                        }
                                    }, () => {
                                        this.getFileList().then(() => {
                                            this.setState({ loaderVisibility: false })
                                        });
                                    })
                                }
                            })
                        }}
                    >
                        Yeni Klasör Ekle
                    </span>
                </div>
                {!uploadScreenVisibility ? (
                    <PrimaryButton
                        onClick={() => this.setState({ uploadScreenVisibility: true })}
                        text="Dosya ya da Dosyalar Yükle"
                        iconProps={{ iconName: "CloudUpload" }}
                    />
                ) : (
                    <PrimaryButton
                        onClick={() => {
                            this.setState({
                                filterObject: {
                                    pageSize: 40,
                                    totalPage: 1,
                                    fileExtensions: this.props.fileType || FileTypes.All,
                                    pageIndex: 0,
                                    fileName: ""
                                }
                            }, () => {
                                this.setState({ uploadScreenVisibility: false });
                                this.onSearchInFiles();
                            })
                        }}
                        text="Dosyalara Geri Dön"
                        iconProps={{ iconName: "Back" }}
                    />
                )}

            </div>
        )
    }


    renderFooter() {
        const { activeFiles, filterObject } = this.state;
        return (
            <div className="bucket-footer row">
                <div className="pager row">
                    <div onClick={this.onClickPreviousPage} className="pager-indicator prev-page">
                        <Icon iconName="ChevronLeft" />
                    </div>
                    <div onClick={this.onClickNextPage} className="pager-indicator next-page">
                        <Icon iconName="ChevronRight" />
                    </div>
                    <span className="info">
                        {filterObject.pageIndex + 1} toplam {filterObject.totalPage}
                    </span>
                </div>
                <div className="bucket-indicators row">
                    <button disabled={this.props.disableSelect} onClick={() => this.onSelectedFiles(activeFiles)} className="select">
                        Seçimi Kaydet
					</button>
                </div>
            </div>
        )
    }

    renderUploadScreen() {
        const { uploadScreenVisibility } = this.state;
        if (uploadScreenVisibility) {
            return (
                <div className="upload-screen">
                    <div className="title">
                        Dosya ya da Dosyalar Yükle (En Fazla 100 Dosya)
                    <div className="sub-text">  Bilgisayarınız üzerinden dosya seçebilir ya da sürükleyebilirsiniz.</div>
                    </div>
                    <FilePond
                        // files={this.state.uploadFiles}
                        //@ts-ignore
                        allowImagePreview={false}
                        allowMultiple={true}
                        maxFiles={100}
                        labelIdle={`<i class='fas fa-upload'></i> Bu alana herhangi bir dosya sürükleyebilirsiniz. <span class='upload-label'> Bilgisayardan Seç </span>`}
                        name="files"
                        server={{
                            process: {
                                headers: {
                                    Authorization: `Bearer ${cookie.load("accessToken")}`
                                },
                                url: getCdnEndpoint(`${api.prefixes.storage.upload}/${this.state.activeBucket || "local"}`),
                                onload: (response: any) => {
                                    const res = JSON.parse(response);
                                    if (res.result) {
                                        this.onTransferedFile(res.result)
                                    }
                                    return "Success";
                                }
                            }
                        }}
                    />
                </div>
            )
        }
        return null;
    }

    renderFilePanel() {
        const { filePanelVisibility, filePanelData } = this.state;
        console.log("filePanelData", filePanelData);
        if (filePanelVisibility) {
            return (
                <div className="file-information">
                    <div className="title row">
                        Dosya Bilgisi
                    <div onClick={() => this.setState({ filePanelVisibility: false })} className="close">
                            <Icon iconName="ChromeClose" />
                        </div>
                    </div>
                    <div className="information-list">
                        <div className="item">
                            <div className="text">Dosya Adı</div>
                            <TextField className="custom-textfield" readOnly value={filePanelData.fileName} />
                        </div>
                        <div className="item">
                            <div className="text">Dosya Yolu</div>
                            <TextField className="custom-textfield" readOnly multiline resizable={false} value={getFilePath() + filePanelData.fileName} />
                        </div>
                        {this.findFileType(filePanelData?.fileExtension as string) !== FileTypes.File && (
                            <div className="item">
                                <div className="text">Önizleme</div>
                                <figure className="full-image">
                                    {this.findFileType(filePanelData?.fileExtension as string) === FileTypes.Video ? (
                                        <video width="100%" height="100%" controls>
                                            <source src={getFilePath() + filePanelData.fileName} type="video/mp4" />
                                        </video>
                                    ) : <img src={getFilePath() + filePanelData.fileName} alt={filePanelData.fileName} />
                                    }
                                </figure>
                            </div>
                        )}
                        <div className="item">
                            <div className="text">Byout</div>
                            <div className="value">{filePanelData.fileSize}</div>
                        </div>
                        <div className="item">
                            <div className="text">Son Değiştirme Tarihi</div>
                            <div className="value">{moment(filePanelData.updatedAt).format("DD.MM.YYYY HH:mm")}</div>
                        </div>
                        <div className="item-indicators row">
                            <button onClick={() => window.open(getFilePath() + filePanelData.fileName, "_blank")} className="view">
                                Görüntüle
                            </button>
                            <button onClick={() => this.onRemoveFile(filePanelData)} className="delete">
                                Sil
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    };

    renderLoader() {
        const { loaderVisibility } = this.state;
        return (
            <div
                className={[loaderVisibility && "active", "loader"].filter(e => !!e).join(" ")}>
                <Spinner
                    {...this.props}
                    size={SpinnerSize["large"]}
                />
            </div>
        )
    }

    render() {
        const { show } = this.props;
        if (!show) {
            return null;
        }
        console.log("this", this.props);
        return (
            <div className="fa-FileManager">
                <div className="fa-FileManager-wrapper">
                    <div className="fa-FileManager-title row">
                        <div className="title">
                            Dosya Yönetim Sistemi
                            <div className="sub-text">
                                Yeni bir dosya yükleyebilir, varolan dosyalarınızı görüntüleyebilir ya da seçim yapabilirsiniz!
                            </div>
                        </div>
                        <div onClick={this.onDismiss} className="close">
                            <Icon iconName="ChromeClose" />
                        </div>
                    </div>
                    <div className="fa-FileManager-body">
                        {this.renderLoader()}
                        <div className="bucket-wrapper col">
                            {this.renderUploadScreen()}
                            {this.renderFilePanel()}
                            {this.renderHeader()}
                            {this.renderSearch()}
                            {this.renderFileList()}
                            {this.renderFooter()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FileManager);
import { EntryOptions, PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";
import { CmsReactComponent } from "components/cms/CmsReactComponent";
import { ImageChildrenDefinitions } from "components/cms/definitions";
import { BoardSidebarProps } from "./types";

export class BoardSidebarDefinitions extends CmsReactComponent<BoardSidebarProps> {
    static schemaName = "board-sidebar";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "autoplay",
            mapping: {
                type: PropertyMappingType.Boolean
            },
            group: {
                key: "settings",
                title: "Slider Ayarları",
                withObject: true
            },
            default: 4
        },
        {
            name: "autoplaySpeed",
            mapping: {
                type: PropertyMappingType.Number
            },
            group: {
                key: "settings",
                title: "Slider Ayarları",
                withObject: true
            },
            default: 4
        },
        {
            name: "data",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                        default: "",
                        text: "Haber Başlığı",
                    },
                    {
                        name: "description",
                        mapping: {
                            type: PropertyMappingType.Html,
                        },
                        default: "",
                        text: "Haber Açıklaması",
                    },
                    {
                        name: "image",
                        mapping: {
                            type: PropertyMappingType.ParentObject,
                            children: ImageChildrenDefinitions
                        },
                        default: { src: "", alt: "" },
                        text: "Görsel",
                    },
                    {
                        name: "thumb",
                        mapping: {
                            type: PropertyMappingType.ParentObject,
                            children: ImageChildrenDefinitions
                        },
                        default: { src: "", alt: "" },
                        text: "Küçük Görsel",
                    },
                    {
                        name: "anchor",
                        mapping: {
                            type: PropertyMappingType.LinkWizard,
                        },
                    },
                    {
                        name: "className",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                        text: "Özel Sınıf",
                    }
                ]
            },
            default: []
        },

    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: "title",
                text: "Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "region",
                text: "Bölge",
                types: [MetaTypeEnum.Dropdown],
            },
            {
                name: "description",
                text: "Açıklama",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea, MetaTypeEnum.Editor],
            },
            {
                name: "image",
                text: "Görsel",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "thumb",
                text: "Küçük Görsel",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "anchor",
                text: "URL",
                types: [MetaTypeEnum.Link]
            },
        ],
    };
}

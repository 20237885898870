import clsx from 'clsx';
import { Label } from 'office-ui-fabric-react';
import React from 'react';
import ReactInputMask from 'react-input-mask';


interface IMaskInputProps {
    className?: string;
    label?: string;
    maskTemplate: string;
    onChange: (value: string) => void;
    placeholder?: string;
    type?: string;
    value?: string;
    errorMessage?: string;
    description?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export default class MaskInput extends React.Component<IMaskInputProps> {

    renderLabel() {
        if (!this.props.label) return null;

        return (
            <Label>{this.props.label}</Label>
        )
    }

    render() {
        const { value, className, onChange, maskTemplate, placeholder, errorMessage, description, disabled, type, style } = this.props;

        return (
            <div
                className={clsx("mask-input", className, errorMessage && "is-error", disabled && "is-disabled")}
                style={style}
            >
                {this.renderLabel()}
                <ReactInputMask
                    mask={maskTemplate}
                    value={value}
                    type={type}
                    onChange={(event) => !disabled ? onChange(event.target.value) : {}}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                {description && !disabled && (
                    <span className="description-message">{description}</span>
                )}
                {errorMessage && (
                    <span className="error-message">{errorMessage}</span>
                )}
            </div>
        )
    }
}

import { Component } from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { IMetaFile } from 'app/services/content-type';
import { ProfileImage } from 'app/shared';
import FileManager from '../../FileManager/FileManager';
import { BaseMetaFieldsProps } from './types';

import ImageFile from "assets/images/type-file.jpg";
import FileSettingsModal from 'components/customs/Modals/FileSettingsModal';


interface IMetaSingleState {
    showModal: boolean;
    showImageSettingsModal: boolean;
}

export default class MetaFile extends Component<BaseMetaFieldsProps<IMetaFile>, IMetaSingleState> {

    state = {
        showModal: false,
        showImageSettingsModal: false
    }

    renderFileByType() {
        return <img src={ImageFile} alt={this.props.meta.data?.alt} />
    }

    renderImageSettingsModal() {
        return (
            <FileSettingsModal
                show={this.state.showImageSettingsModal}
                data={this.props.meta.data as ProfileImage}
                onSave={(data) => this.props.onChange(data as ProfileImage)}
                onDismiss={() => this.setState({ showImageSettingsModal: false })}
            />
        )
    }

    render() {
        return (
            <div className="meta-file">
                <FileManager
                    show={this.state.showModal}
                    onDismiss={() => this.setState({ showModal: false })}
                    onSelectedFile={(file) => {
                        this.props.onChange({ src: file.fileName, alt: file.fileName, title: file.fileName })
                    }}
                    activeFile={{
                        id: "",
                        fileName: this.props.meta.data?.src || "",
                        fileExtension: "",
                        fileSize: ""
                    }}
                />
                <DefaultButton
                    iconProps={{ iconName: "Touch" }}
                    text="Dosya Seç"
                    styles={{
                        root: {
                            fontSize: "10.5px !important",
                            background: "#fff"
                        }
                    }}
                    onClick={() => {
                        this.setState({
                            showModal: true
                        })
                    }}
                />
                <div className="file-list row">
                    <div className="file-item">
                        {this.props.meta.data?.src ? (
                            <div className="file-item-wrapper">
                                <div onClick={() => this.setState({ showImageSettingsModal: true })} className="edit">
                                    <i className="icon-edit"></i>
                                </div>
                                {this.renderImageSettingsModal()}
                                {this.renderFileByType()}
                                <div className="title">
                                    {this.props.meta.data?.title || this.props.meta.data?.alt}
                                </div>
                                <div onClick={() => this.props.onChange({ src: "", alt: "" })} className="remove">
                                    <i className="icon-delete"></i>
                                </div>
                            </div>
                        ) : (
                            <div className="not-found row">
                                <i className="icon-info"></i>
                                Herhangi bir dosya eklenmemiştir.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { IconListProps } from "./types";
import {
    PropertyDefinition,
    PropertyDefinitionOneOf,
    PropertyMappingType,
} from "app/library/layout-builder";
const sizes: PropertyDefinitionOneOf[] = [
    {
        key: "small",
        text: "small",
    },
    {
        key: "medium",
        text: "medium",
    },
    {
        key: "large",
        text: "large",
    },
    {
        key: "xlarge",
        text: "xlarge",
    },
];


export class IconListDefinitions extends CmsReactComponent<IconListProps> {
    static schemaName = "icon-list";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "data",
            text: "İkon Listesi",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "text",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                        default: "",
                        text: "Text",
                    },
                    {
                        name: "name",
                        mapping: {
                            type: PropertyMappingType.Icon,
                        },
                        default: "icon-instagram",
                        text: "İkon Adı",
                    },
                    {
                        name: "size",
                        mapping: {
                            type: PropertyMappingType.Object,
                            oneOf: sizes,
                        },
                        default: "medium",
                        text: "İkon Boyutu",
                    },
                    {
                        name: "anchor",
                        mapping: {
                            type: PropertyMappingType.LinkWizard,
                        },
                    },
                    {
                        name: "color",
                        mapping: {
                            type: PropertyMappingType.Color,
                        },
                        text: "İkon Renk",
                    }]
            },
            default: []
        },

    ];


}

import React, { Component } from "react";
import FormAccordion from "../FormAccordion";
import { PivotItem, Pivot } from "office-ui-fabric-react";
import SortableJS from "sortablejs";
import Sortable from "./Sortable";
import DragDropContext from "./DragDropContext";
import { render } from "app/library/layout-builder";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from "app/redux/reducer";
import { setDesignerMode } from "app/redux/designer/actions";
import { container } from "components/cms/container";
import { DESIGNER_COMPONENTS } from "app/shared/constants";

function mapStateToProps(state: RootState) {
    return {
        designSchema: state.page.pageData?.schema,
        designerMode: state.designer.designerMode
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            setDesignerMode
        }, dispatch)
    };
}

type DesignerProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

class Designer extends Component<DesignerProps> {
    dragDropContextRef = React.createRef<DragDropContext>();
    designAreaRef = React.createRef<HTMLDivElement>();

    componentDidUpdate() {
        if (this.dragDropContextRef.current) {
            this.dragDropContextRef.current.initializeContext();
        }
    }

    componentDidMount() {
        this.dragDropContextRef.current?.initializeContext();
    }

    componentWillUnmount() {
        if (this.props.designerMode) {
            this.props.setDesignerMode(!this.props.designerMode);
            document.body.classList.remove("designer-mode");
        }
    }

    getSortableOptions(group: string): SortableJS.Options {
        return {
            group: {
                pull: (to: SortableJS, _) => {
                    const type = to.el.getAttribute("data-schema-type");

                    console.log("to", type);

                    if (to.el.className === "kit-list row") {
                        return false;
                    }

                    if (type === "root") {
                        return false;
                    }

                    if (
                        group === "layout" &&
                        (type === "grid" ||
                            type === "root" ||
                            type === "column" ||
                            type === "container")
                    ) {
                        console.log("Layout to Grid or Root");
                        return "clone";
                    }


                    if (
                        group !== "layout" &&
                        type !== "grid" &&
                        type !== "root"
                    ) {
                        console.log("From isn't layout. ");
                        return "clone";
                    }

                    return false;

                },
                name: "droppable",
            },
            sort: false,
            ghostClass: "dragging-element",
            animation: 150,
            swapThreshold: 0
        };
    }

    renderComponentsByType(type: string) {
        const items = DESIGNER_COMPONENTS.find((item) => item.group === type)?.items;
        return (
            <Sortable
                className="designer-components row"
                sortableOptions={this.getSortableOptions(type)}
            >
                {(items || []).map((item, index) => (
                    <div
                        key={index}
                        className={`fa-sortable-item item size-${item.size || 0}`}
                        data-schema-size={item?.size || ""}
                        data-schema-type={item.schema}
                    >
                        <div className="icon">
                            <img
                                src={`${require(`assets/images/f_${item.icon}.svg`).default}`}
                                alt={item.name}
                            />
                        </div>
                        <div className="text">{item.name}</div>
                    </div>
                ))}
            </Sortable>
        );
    }

    renderDesignerHeader() {
        return (
            <div className="designer-header">
                <Pivot>
                    <PivotItem headerText="Kapsayıcılar">
                        {this.renderComponentsByType("layout")}
                    </PivotItem>
                    <PivotItem headerText="Genel İçerikler">
                        {this.renderComponentsByType("content")}
                    </PivotItem>
                    <PivotItem headerText="Özel Bileşenler">
                        {this.renderComponentsByType("custom")}
                    </PivotItem>
                </Pivot>
                <div
                    className="designer-mode-indicator row"
                    onClick={() => {
                        this.props.setDesignerMode(!this.props.designerMode);
                        if (this.props.designerMode) {
                            document.body.classList.remove("designer-mode")
                        }
                        else {
                            document.body.classList.add("designer-mode")
                        }
                    }}
                >
                    <i className={this.props.designerMode ? "fas fa-expand-arrows-alt" : "fas fa-expand"}></i>
                    <div className="text">{this.props.designerMode ? "Small Screen" : "Full Screen"}</div>
                </div>
            </div>
        );
    }

    renderDesignerContent() {
        return (
            <div
                style={{ position: "relative" }}
                ref={this.designAreaRef}
                className="designer-content droppable-area"
            >
                {render(this.props.designSchema || [], container, {
                    dragDropContext: this.dragDropContextRef,
                    designing: true
                })}
            </div>
        );
    }

    render() {
        return (
            <FormAccordion title="Görsel İçerik Düzenleyicisi">
                <div className="designer">
                    {this.renderDesignerHeader()}
                    {this.renderDesignerContent()}
                </div>
            </FormAccordion>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Designer);

import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import cookie from "react-cookies";
import { ResponseBase } from "app/shared";
import { createAxiosResponseInterceptor } from "./interceptor";

export class InventoryClient {

    static _authenticatedClient?: InventoryClient;
    static _authenticatedInventoryClient?: InventoryClient;

    private axiosClient: AxiosInstance;

    post(url: string, request: any): Promise<any> {
        return this.axiosClient.post(url, request).then(res => {
            return res.data;
        }).catch(err => {
            console.log("console.log", err);

            return { data: null, status: err?.response?.status };
        })
    }

    put(url: string, request: any): Promise<any> {
        return this.axiosClient.put(url, request).then(res => {
            return res.data;
        }).catch(err => {
            return { data: null, status: err?.response?.status };
        })
    }

    patch(url: string, request: any): Promise<any> {
        return this.axiosClient.patch(url, request).then(res => {
            return res.data;
        }).catch(err => {
            return { data: null, status: err?.response?.status };
        })
    }

    get(url: string): Promise<any> {
        return this.axiosClient.get(url).then(res => {
            return res.data;
        }).catch(err => {
            return { data: null, status: err?.response?.status };
        })
    }

    delete(url: string, request?: any): Promise<any> {
        return this.axiosClient.delete(url, { data: request }).then(res => {
            return res.data;
        }).catch(err => {
            return { data: null, status: err?.response?.status };
        })
    }

    static getClient(): InventoryClient {

        if (!this._authenticatedClient) {
            const client = new InventoryClient();

            client.axiosClient = axios.create();
            client.axiosClient.interceptors.request.use(
                async config => {
                    config.headers = {
                        'Authorization': `Bearer ${cookie.load("accessToken")}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Accept-Language': "tr-TR"
                    }
                    return config;
                },
                (error) => {
                    Promise.reject(error)
                });

            client.axiosClient.interceptors.response.use(
                (response: AxiosResponse<ResponseBase<object>>) => response,
                (error: AxiosError) => {
                    return createAxiosResponseInterceptor(error).then((token) => {
                        if (token) {
                            client.axiosClient.defaults.headers['Authorization'] = "Bearer " + token;
                            error.config.headers['Authorization'] = "Bearer " + token;

                            return client.axiosClient(error.config);
                        }
                        return error.response;
                    })
                }
            )

            this._authenticatedClient = client;
        }

        return this._authenticatedClient;
    }

    static getStorageClient() {
        const client = new InventoryClient();

        client.axiosClient = axios.create();
        client.axiosClient.interceptors.request.use(
            async config => {
                config.headers = {
                    'Authorization': `Bearer ${cookie.load("accessToken")}`,
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
                return config;
            },
            (error) => {
                Promise.reject(error)
            });

        client.axiosClient.interceptors.response.use(
            (response: AxiosResponse<ResponseBase<object>>) => response,
            (error: AxiosError) => {
                return createAxiosResponseInterceptor(error).then((token) => {
                    if (token) {
                        client.axiosClient.defaults.headers['Authorization'] = "Bearer " + token;
                        error.config.headers['Authorization'] = "Bearer " + token;

                        return client.axiosClient(error.config);
                    }
                    return error.response;
                })
            }
        )

        return client;
    }

}

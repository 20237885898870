import { DefaultButton } from 'office-ui-fabric-react';
import { Component } from 'react';
import _ from "lodash";
import { ReactSortable } from "react-sortablejs";
import { BaseMetaFieldsProps } from './types';
import { IMetaSliderGallery } from 'app/services/content-type';
import { validateUrl } from 'app/shared/functions';
import { getFilePath } from 'app/utils/service';

import ImagePlaceholder from "assets/images/placeholder.png";
import { MetaSliderGalleryModal } from 'components/customs/Modals/MetaSliderGalleryModal';
import { ILinkWizard } from 'app/shared';

interface IMetaMultiState {
    modalIndex: number;
}

export default class MetaPhotoSlider extends Component<BaseMetaFieldsProps<IMetaSliderGallery[]>, IMetaMultiState> {

    state = {
        modalIndex: -1
    }

    renderModal(metaData: IMetaSliderGallery, index: number) {
        return (
            <MetaSliderGalleryModal
                show={this.state.modalIndex === index ? true : false}
                onDismiss={() => {
                    this.setState({
                        modalIndex: -1
                    })
                }}
                onSave={(data) => {
                    const clonedData = _.cloneDeep(this.props.meta.data) || [];
                    clonedData[index] = data as IMetaSliderGallery;
                    this.props.onChange(clonedData);
                }}
                data={metaData}
                language="en"
            />
        )
    }

    renderFileList() {
        return (
            <div className="file-list multi row">
                {this.props.meta.data && this.props.meta.data.length > 0 ? (
                    <ReactSortable
                        list={(this.props.meta.data || []).map((item, index) => ({ ...item, id: index })) || []}
                        setList={(newState) => this.props.onChange(newState)}
                        animation={200}
                        className="row"
                        style={{ width: "100%" }}
                    >
                        {this.props.meta.data.map((item, index) => {
                            const splitted = item.image?.src?.split(".");
                            const imageSource = !validateUrl(item.image.src) ?
                                getFilePath() + item.image.src +
                                (!splitted || splitted[splitted?.length - 1] !== "svg" ? "?width=200&height=133" : "")
                                : item.image.src;
                            return (
                                <div key={index} className="file-item col-md-2">
                                    {this.renderModal(item, index)}
                                    <div className="file-item-wrapper">
                                        <img onClick={() => {
                                            this.setState({
                                                modalIndex: index
                                            })
                                        }}
                                            src={item.image.src ? imageSource : ImagePlaceholder}
                                            alt={item.title}
                                        />
                                        <span
                                            className="edit"
                                            onClick={() => {
                                                this.setState({
                                                    modalIndex: index
                                                })
                                            }}
                                        >
                                            <i className="icon-edit"></i>
                                        </span>
                                        <div onClick={() => {
                                            const list = _.cloneDeep(this.props.meta.data || []);
                                            list.splice(index, 1);
                                            this.props.onChange(list);
                                        }}
                                            className="remove"
                                        >
                                            <i className="icon-delete"></i>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </ReactSortable>
                ) : (
                    <div className="not-found row">
                        <i className="icon-info"></i>
                        Herhangi bir eklenmiş slider öğesi bulunamadı.
                    </div>
                )}
            </div>
        )
    }

    render() {
        return (
            <div className="meta-file">
                <DefaultButton
                    iconProps={{ iconName: "Add" }}
                    text="Yeni Slider Öğesi Ekle"
                    styles={{
                        root: {
                            fontSize: "10.5px !important",
                            background: "#fff"
                        },
                        icon: {
                            fontSize: 12,
                            fontWeight: 500
                        }
                    }}
                    onClick={() => {
                        const clonedData = _.cloneDeep(this.props.meta.data) || [];
                        clonedData.push({
                            title: "",
                            image: {
                                alt: "",
                                src: ""
                            },
                            anchor: {

                            } as ILinkWizard
                        })
                        this.props.onChange(clonedData);
                    }}
                />
                {this.renderFileList()}
            </div>
        )
    }
}

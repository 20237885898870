import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

import Header from 'components/customs/Header';
import LeftPanel from 'components/customs/LeftPanel';

export default class PageLayout extends React.Component<RouteComponentProps> {

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Saha Expo - İçerik Yönetim Sistemi</title>
                </Helmet>
                <Header history={this.props.history} />
                <div className="row">
                    <LeftPanel history={this.props.history} />
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

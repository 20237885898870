import { api } from 'app/utils/api';
import { convertToQueryString } from 'app/shared/functions';
import { HttpClient, InventoryClient } from 'app/library/client';
import { IListFilter, ResponseBase, ResponseListBase, ResponseContentBase } from "app/shared";
import { PostEntity, PosttEntity } from './types';
import { getEndpoint } from 'app/utils/service';

export class PostService {

    /** It gets all post. */
    async getPosts(options: IListFilter, contentTypeId: string): Promise<ResponseBase<ResponseListBase<PostEntity[]>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.post.list) + `?${convertToQueryString({ ...options, contentTypeId })}`);
    }

    /** It get spesific post by post url */
    async getPost(slug: string, language?: string): Promise<ResponseBase<ResponseContentBase<PostEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.post.admin) + `?url=${slug}&language=${language || "en"}`);
    }

    /** It get spesific post by post id */
    async getPostById(postId: string, language?: string): Promise<ResponseBase<ResponseContentBase<PostEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.post.admin) + `?id=${postId}&language=${language || "en"}`);
    }

    /** It get spesific post by post id */
    async getPostsById(postIds: string[]): Promise<ResponseBase<ResponseContentBase<PostEntity[]>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.post.multiple) + postIds.join(","));
    }

    /** It creates post by request */
    async createPost(request: PostEntity): Promise<ResponseBase<ResponseContentBase<PostEntity>>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.post.main), request);
    }

    /** It creates same post but another url from current id */
    async duplicatePost(slug: string): Promise<ResponseBase<ResponseContentBase<PostEntity>>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.post.duplicate), { url: slug });
    }

    /** It updates spesific post by id */
    async updatePost(request: PostEntity): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().patch(getEndpoint(api.prefixes.post.main) + request.id, request);
    }

    /** It check post slug validate */
    async getPostSlug(slug: string): Promise<ResponseBase<ResponseContentBase<any>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.post.url) + slug);
    }

    /** It deletes spesific post by id */
    async deletePost(postId: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().delete(getEndpoint(api.prefixes.post.main) + postId);
    }

      async getExcel(filter: IListFilter): Promise<ResponseBase<ResponseListBase<PosttEntity[]>>> {
        return InventoryClient.getClient().get(getEndpoint(api.prefixes.post.list) + `?${convertToQueryString(filter)}`);
    }

}

import * as React from "react";
import _ from "lodash";
import { ComboBox, IDropdownOption, TextField } from 'office-ui-fabric-react';

import { FilterField, FilterType, HttpStatusCode } from "app/shared";
import { CategoryService } from "app/services/category";
import { IPost, mapPostFromEntity, PostService } from "app/services/post";
import ProductPicker from "../../Modals/Operations/ProductPicker";

interface DynamicFilterItemState {
    data: IDropdownOption[];
    post: IPost;
}

type OnChangeFilterFieldCallback = (key: string, value: string, index: number) => void;
type OnChangeFilterFieldDataCallback = (value: FilterField, index: number) => void;
type OnRemoveFilterFieldCallback = (index: number) => void;

interface DynamicFilterItemProps {
    data: FilterField;
    onChange: OnChangeFilterFieldCallback;
    onChangeObject: OnChangeFilterFieldDataCallback;
    onRemove: OnRemoveFilterFieldCallback;
    index: number;
    specificationTypes: IDropdownOption[];
    hideTitle?: boolean;
}

// Kendisine gelen Property ismine ve değerine göre Image Field render eder.
export class DynamicFilterItem extends React.Component<DynamicFilterItemProps, DynamicFilterItemState> {

    state = {
        data: [],
        post: {} as IPost
    }

    componentDidMount() {
        const item = this.props.data;
        if (item.type) {
            this.getValues(item.type, item.collection as FilterType)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: DynamicFilterItemProps) {
        if (nextProps.data.type && nextProps.data.type !== this.props.data.type) {
            const item = nextProps.data;
            this.getValues(item.type, item.collection as FilterType)
        }
    }

    getValues(value: string, type?: FilterType) {

        if (type === FilterType.ContentType) {
            if (this.props.data.collection === FilterType.ContentType && this.props.data.value) {
                this.getPost(this.props.data.value)
            }
            else {
                this.getPosts(value);
            }
        }
        else if (type === FilterType.Category) {
            this.getCategories();
        }
    }

    async getCategories() {
        const service = new CategoryService();
        const response = await service.getCategories();
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map(item => ({ key: item.id, text: item.title }));
            const sorted = _.sortBy(mapped, ["text"], ["asc"]);
            this.setState({
                data: sorted
            })
        }
    }

    async getPost(id: string) {
        const service = new PostService();
        const response = await service.getPostById(id);
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapPostFromEntity(response.data?.attributes["post"] || {})
            this.setState({
                post: mapped
            })
        }
    }

    async getPosts(contentTypeId: string) {
        const service = new PostService();
        const response = await service.getPosts({ page: 1, pageSize: 200, descending: false }, contentTypeId);
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map(item => ({ key: item.id, text: item.title }));
            const sorted = _.sortBy(mapped, ["text"], ["asc"]);
            this.setState({
                data: sorted
            })
        }
    }

    onChangeData(key: string, value: any, type?: FilterType) {
        if (key === "type") {
            if (value) {
                this.getValues(value, type);
                this.props.onChangeObject({
                    ...this.props.data,
                    collection: type as FilterType,
                    type: value

                }, this.props.index)
            }
        }
        else {
            this.props.onChange(key, value, this.props.index);
        }
    }

    renderValue() {
        const item = this.props.data;

        return (
            <React.Fragment>
                <ComboBox
                    placeholder=""
                    disabled={!item.name}
                    selectedKey={item.type}
                    label="Değer Tipi (Doldurulacak Tip)"
                    className={`custom-combobox border col-md-${this.props.hideTitle ? "4" : "3"}`}
                    calloutProps={{ className: "custom-combobox-callout" }}
                    onChange={(event: any, option) => {
                        this.onChangeData("type", option?.key as string, (option as any)?.data)
                    }}
                    allowFreeform
                    autoComplete="on"
                    options={this.props.specificationTypes}
                />

                {item.collection === FilterType.ContentType ? (
                    <ProductPicker
                        contentType={item.type}
                        disabled={!item.type}
                        className={`col-md-${this.props.hideTitle ? "4" : "3"}`}
                        label="Filtrelenecek Değer"
                        onSelected={(data) => {
                            this.setState({
                                post: data
                            }, () => {
                                this.onChangeData("value", data?.id as string)
                            })
                        }}
                        data={this.state.post || {}}
                        isPage
                    />
                ) : (
                    <ComboBox
                        placeholder=""
                        disabled={!item.type || this.state.data.length === 0}
                        selectedKey={item.value}
                        label="Filtrelenecek Değer"
                        className={`custom-combobox border col-md-${this.props.hideTitle ? "4" : "3"}`}
                        calloutProps={{ className: "custom-combobox-callout" }}
                        onChange={(event: any, option) => {
                            this.onChangeData("value", option?.key as string)
                        }}
                        allowFreeform
                        autoComplete="on"
                        options={this.state.data}
                    />
                )}

            </React.Fragment>
        )
    }

    renderItem() {
        const item = this.props.data;

        return (
            <div
                className="meta-form filter-form row"
            >
                {!this.props.hideTitle && (
                    <TextField
                        className="custom-textfield col-md-3"
                        label="Filtre Başlık"
                        value={item.title}
                        onChange={(event: any) => {
                            this.onChangeData("title", event.target.value)
                        }}
                    />
                )}
                {this.renderValue()}
                <span
                    onClick={() => {
                        this.props.onRemove(this.props.index)
                    }}
                    className="active remove col-md-1"
                >
                    <i className="icon-close1"></i>
                </span>
            </div>
        )

    }

    render() {
        return this.renderItem()
    }
}

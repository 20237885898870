import { Component } from 'react'
import { DefaultButton } from 'office-ui-fabric-react';
import { ProfileImage } from 'app/shared';
import FormAccordion from '../FormAccordion'
import { ImageSettingsModal } from '../Modals';
import { IThumbnailBar } from "./Sidebar";
import { getFilePath } from 'app/utils/service';

import ImagePlaceholder from "assets/images/placeholder.png";

interface IThumbnailBarState {
    showModal: boolean;
}

export default class ThumbnailBar extends Component<IThumbnailBar, IThumbnailBarState> {

    state = {
        showModal: false
    }

    renderSourceSettings() {
        return (
            <ImageSettingsModal
                show={this.state.showModal}
                onDismiss={() => this.setState({ showModal: false })}
                data={this.props.thumbnail as ProfileImage}
                onSave={(data) => {
                    if (this.props.onSave && data) {
                        this.props.onSave(data)
                    }
                }}
            />
        )
    }

    render() {
        return (
            <div className="bar-category thumbnail-bar">
                <FormAccordion title="Öne Çıkarılan Görsel">
                    <div className="content">
                        <div
                            className="property-image"
                        >
                            <img
                                onClick={() => this.props.onClickThumbnail ? this.props.onClickThumbnail() : {}}
                                className="fluid-image"
                                src={`${this.props.thumbnail?.src ? (getFilePath() + this.props.thumbnail.src) : ImagePlaceholder}`}

                                alt="Öne Çıkan Görsel"
                            />
                            {this.props.thumbnail?.src && (
                                <span
                                    onClick={() => this.props.onDeleteThumbnail ? this.props.onDeleteThumbnail() : {}}
                                    className="remove"
                                >
                                    <i className="far fa-trash-alt"></i>
                                </span>
                            )}
                        </div>
                        <DefaultButton
                            iconProps={{ iconName: "Touch" }}
                            className="custom-button"
                            text="Görsel Seç"
                            onClick={() => this.props.onClickThumbnail ? this.props.onClickThumbnail() : {}}
                        />
                    </div>
                    <div className="footer row">
                        {this.renderSourceSettings()}
                        <div onClick={() => this.setState({ showModal: true })} className="trigger m-left-auto">
                            Görsel Ayarları
						</div>
                    </div>
                </FormAccordion>
            </div>
        )
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { IframeProps } from "./types";
import {
  PropertyMappingType,
  PropertyDefinition, EntryOptions
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

export class IframeDefinitions extends CmsReactComponent<IframeProps> {
  static defaultProps = {};

  static schemaName = "iframe";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "src",
      mapping: {
        type: PropertyMappingType.String,
      },
      text: "Iframe URL",
    },
    {
      name: "align",
      mapping: {
        type: PropertyMappingType.Alignment,
      },
      text: "Iframe Hizalama",
    },
    {
      name: "name",
      mapping: {
        type: PropertyMappingType.String,
      },
      text: "Iframe Adı",
    },
    {
      name: "width",
      mapping: {
        type: PropertyMappingType.String,
      },
      text: "Iframe Genişliği",
    },
    {
      name: "height",
      mapping: {
        type: PropertyMappingType.String,
      },
      text: "Iframe Yüksekliği",
    }
  ];

  static options: EntryOptions = {
    meta: {
      use: true,
      allowedTypes: [
        MetaTypeEnum.Text,
        MetaTypeEnum.TextArea,
      ]
    }
  }
}

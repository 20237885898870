import { MENU_ITEMS } from "../constants/menu";
import { RouteKey } from "../enums";
import { IMenuItem } from "../models";

export function getDistinctMenuItems() {
    const items = MENU_ITEMS.reduce((prev: IMenuItem[], current) => { prev = [...prev, current, ...current.children || []]; return prev; }, []);

    return items;
}

export function getMenuItems() {
    const permissions = getMenuPermissions();

    const items = MENU_ITEMS.filter((item) => {
        if (permissions?.includes(item.key)) {
            item.children = (item.children || []).filter(children => {
                if (permissions?.includes(children.key)) {
                    return children;
                }
                return null;
            })

            return item;
        }
        return null;
    });

    return items;
}

export function getPageDefinition(url: string): IMenuItem | null {
    return getDistinctMenuItems().find(x => x.url === url) || null
}

/** temporary, it's will delete when included role services */
export function getMenuPermissions(): string[] {
    return [
        RouteKey.Home,
        RouteKey.ContentTypeManagement,
        RouteKey.ContentTypes,
        RouteKey.Layout,
        RouteKey.Menu,
        RouteKey.Users,
        RouteKey.ThemeSettings,
        RouteKey.FileManagement
    ]
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from "app/redux/reducer";
import { bindActionCreators, Dispatch } from 'redux';
import PageTitle from 'components/customs/PageTitle';
import { Link, RouteComponentProps } from 'react-router-dom';
import { TextField, Dropdown, PrimaryButton } from 'office-ui-fabric-react';

import BasicTable from 'components/customs/BasicTable';
import { IListState, IList, IListFilter, StatusEnum } from 'app/shared';
import { castThunkAction } from 'app/utils/casting';
import { toggleConfirmDialog } from 'app/redux/system/actions';
import DayPickerInput from "components/customs/DayPickerInput"
import { deleteLayout, getLayouts, ILayout } from 'app/services/layout';
import { deleteRecovery, getRecoveries, updateRecovery } from 'app/services/recovery';
import PageLayout from 'containers/PageLayout';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
    return {
        ...ownProps,
        language: state.system.language,
        pageData: state.page.pageData as IList<ILayout[]>,
        defaultLanguage: state.system.defaultLanguage
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        getLayouts,
        deleteLayout,
        toggleConfirmDialog,
        getRecoveries,
        updateRecovery,
        deleteRecovery
    }, dispatch);
}

type ILayoutProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ListLayout extends Component<ILayoutProps, IListState> {

    state = {
        type: "layout",
        filterObject: {
            title: "",
            status: 1,
            createdOn: "",
            language: this.props.defaultLanguage
        },
        pagerObject: {
            page: 1,
            descending: false,
            pageSize: 10
        },
        activeStatus: StatusEnum.Published
    }

    componentDidMount() {
        this.makeRequest({ ...this.state.pagerObject, ...this.state.filterObject });
        window.addEventListener("keydown", this.onEnterSearch, true);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onEnterSearch, true);
    }

    onEnterSearch = (e: any) => {
        if (e.key === "Enter") {
            this.searchList();
        }
    };

    makeRequest(options: IListFilter) {
        if (this.state.filterObject.status === StatusEnum.Deleted) {
            castThunkAction(this.props.getRecoveries(options, this.state.type))
                .then(() => {
                    this.setState({
                        activeStatus: StatusEnum.Deleted,
                    })
                })
        } else {
            castThunkAction(this.props.getLayouts(options)).then(() => {
                this.setState({
                    activeStatus: this.state.filterObject.status,
                })
            })
        }
    }

    searchList() {
        this.setState({
            pagerObject: {
                page: 1,
                descending: false,
                pageSize: 10
            },
        }, () => {
            this.makeRequest({
                ...this.state.pagerObject,
                ...this.state.filterObject
            })
        })
    }

    renderFilter() {
        return (
            <div className="table-filter row">
                <TextField
                    className="custom-textfield col-md-2"
                    label="Başlık İle Arama"
                    value={this.state.filterObject?.title || ""}
                    onChange={(event: any) => {
                        this.setState({
                            filterObject: {
                                ...this.state.filterObject,
                                "title": event.target.value
                            }
                        })
                    }}
                />
                <Dropdown
                    options={[
                        { key: StatusEnum.Draft, text: "Taslak" },
                        { key: StatusEnum.Published, text: "Yayında" },
                        { key: StatusEnum.Deleted, text: "Silinmiş" }
                    ]}
                    className="custom-dropdown col-md-2"
                    label="Duruma Göre Arama"
                    placeholder="Seçiniz"
                    selectedKey={this.state.filterObject?.status}
                    onChange={(event: any, option: any) => {
                        this.setState({
                            filterObject: {
                                ...this.state.filterObject,
                                "status": option.key
                            }
                        })
                    }}
                    calloutProps={{
                        className: "custom-dropdown-callout"
                    }}
                />
                <DayPickerInput onChange={(createdOn: string) => this.setState({
                    filterObject: {
                        ...this.state.filterObject,
                        createdOn
                    }
                })} />
                <PrimaryButton
                    text="Arama Yap"
                    className="custom-button"
                    onClick={() => this.searchList()}
                    styles={{
                        root: {
                            fontSize: "11px",
                            marginTop: "27px",
                            height: "37px"
                        }
                    }}
                />
            </div>
        )
    }

    renderList() {
        return (
            <BasicTable
                totalCount={this.props.pageData.totalCount}

                columns={[
                    {
                        columnName: "title",
                        text: "Layout Adı",
                        col: "col-md-6"
                    },
                    {
                        columnName: "description",
                        text: "Layout Açıklaması",
                        col: "col-md-6"
                    }
                ]}
                items={this.props.pageData.list}
                renderOperations={(item: ILayout) => this.state.activeStatus !== StatusEnum.Deleted ? (
                    <React.Fragment>
                        <Link to={`/layout/edit/${item.id}`}><i className="icon-edit-outline"></i> Düzenle</Link>
                        <button onClick={() => {
                            this.props.toggleConfirmDialog(true, {
                                callback: () => {
                                    castThunkAction(this.props.deleteLayout(item.id as string)).then(res => {
                                        if (res) {
                                            this.makeRequest({ ...this.state.pagerObject, ...this.state.filterObject })
                                        }
                                    })
                                }
                            })
                        }}><i className="icon-trash-outline"></i> Sil</button>
                    </React.Fragment>
                ) : <>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {
                        castThunkAction(this.props.updateRecovery(this.state.type, item.id as string)).then(res => {
                            if (res) {
                                this.searchList()
                            }
                        })

                    }}><i className="icon-trash-outline"></i> Kurtar</a>
                    <button onClick={() => {
                        this.props.toggleConfirmDialog(true, {
                            callback: () => {
                                castThunkAction(this.props.deleteRecovery(item.id as string)).then(res => {
                                    if (res) {
                                        this.searchList()
                                    }
                                })
                            }
                        })
                    }}><i className="icon-trash-outline"></i> Kalıcı Olarak Sil</button>
                </>}
                showPager={true}
                pageCount={this.props.pageData.pageCount}
                onChangePageSize={(pageSize) => {
                    this.setState({ pagerObject: { ...this.state.pagerObject, pageSize } }, () => this.makeRequest({
                        ...this.state.pagerObject,
                        ...this.state.filterObject,
                        pageSize
                    }))
                }}
                onChangePager={(pageNumber) => {
                    this.setState({ pagerObject: { ...this.state.pagerObject, page: pageNumber } }, () => this.makeRequest({
                        ...this.state.pagerObject,
                        ...this.state.filterObject,
                        page: pageNumber
                    }))
                }}
                pageIndex={this.state.pagerObject.page - 1}
            />
        )
    }

    render() {
        return (
            <PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
                <div className="page push-all">
                    <PageTitle
                        title="Layout Yönetimi"
                        subTitle="Tüm layoutları aşağıdan görüntüleyebilir, düzenleme yapabilir veya yeni bir layout ekleyebilirsiniz."
                        backUrl="/"
                        showBack
                        history={this.props.history}
                    />
                    <PrimaryButton
                        onClick={() => this.props.history.push("/layout/create")}
                        iconProps={{ iconName: "Add" }}
                        className="absolute-create"
                        text="Yeni Layout Ekle"
                        styles={{
                            root: {
                                background: "#62a98e",
                                borderColor: "#5b9e85",
                                color: "#fff"
                            },
                            rootHovered: {
                                background: "#5b9e85",
                                borderColor: "#5b9e85"
                            },
                            rootDisabled: {
                                background: "#ececec"
                            }
                        }}
                    />
                    {this.renderFilter()}
                    {this.renderList()}
                </div>
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListLayout);
import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

import { ColumnSettingsDefinition, ImageChildrenDefinitions } from 'components/cms/definitions';

export class MaterialListDefitinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "material-list";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "materials",
            description: "Materials Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [{
                    name: 'title',
                    text: 'Başlık',
                    mapping: {
                        type: PropertyMappingType.String
                    }
                },
                {
                    name: 'image',
                    text: 'Görsel',
                    mapping: {
                        type: PropertyMappingType.ParentObject,
                        children: ImageChildrenDefinitions
                    }
                },
                {
                    name: 'code',
                    text: 'Ürün Kodu',
                    mapping: {
                        type: PropertyMappingType.String
                    }
                },
                {
                    name: 'size',
                    text: 'Boyut',
                    mapping: {
                        type: PropertyMappingType.String
                    }
                }],
            },
            group: {
                key: "data",
                title: "Veriler",
                withObject: false,
            }
        },
        ...ColumnSettingsDefinition
    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: "title",
                text: "Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "image",
                text: "Görsel",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "code",
                text: "Ürün Kodu",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "size",
                text: "Boyut",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
        ],
    };
}

import {
    SchemaObject,
    SchemaTree,
    SchemaNode,
    EntryOptions,
    SchemaSource,
    SchemaSourceTypes,
} from "app/library/layout-builder";
import { store } from "app/redux";
import { RootState } from "app/redux/reducer";
import { initialPageData, setPageData } from "app/redux/page/actions";
import { setActiveElement } from "app/redux/designer/actions";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { IDynamicFilter } from "app/shared";
import { IPost } from "../post";

export class SchemaService {
    static setActiveElement(elementId: string) {
        store.dispatch(setActiveElement(elementId));
    }

    static changeProperty(element: string, prop: string, value: any) {
        console.log(element, prop, value);
        const state: RootState = store.getState();
        const pageSchema: SchemaObject[] = state.page.pageData?.schema || [];
        const schemaTree = _.cloneDeep(SchemaTree.parse(pageSchema));
        const node = schemaTree.search(element);
        console.log(schemaTree)
        if (node) {
            node.setProp(prop, value);
            store.dispatch(setPageData("schema", schemaTree.toArray()));
        }
    }

    static changeProperties(element: string, props: any, source?: SchemaSource) {
        const state: RootState = store.getState();
        const pageSchema: SchemaObject[] = state.page.pageData?.schema || [];
        const schemaTree = _.cloneDeep(SchemaTree.parse(pageSchema));
        const node = schemaTree.search(element);
        if (node) {
            node.setProps(props);
            if (source) {
                node.setSourceObject(source);
            }
            store.dispatch(setPageData("schema", schemaTree.toArray()));
        }
    }

    static removeNode(id: string) {
        const state: RootState = store.getState();
        const pageSchema: SchemaObject[] = state.page.pageData?.schema || [];
        const schemaTree = SchemaTree.parse(pageSchema);
        const node = schemaTree.search(id);

        if (node) {
            if (node.parent === null) {
                let index = schemaTree.rootNodes.findIndex(
                    (x) => x.getDefinition("id") === id
                );
                schemaTree.removeChildrenAt(index);
            } else {
                let index = node.parent.children.findIndex(
                    (x) => x.getDefinition("id") === id
                );
                node.parent.removeChildren(index);
            }

            store.dispatch(setPageData("schema", schemaTree.toArray()));
        }
    }

    static insertRoot(
        type: string,
        props: object,
        index: number,
        options: EntryOptions,
        schemaType: string
    ): string {
        const state: RootState = store.getState();
        const pageSchema: SchemaObject[] = state.page.pageData?.schema || [];
        const schemaTree = SchemaTree.parse(pageSchema);
        const node = new SchemaNode(null);
        const sameNodes = schemaTree.searchBy((r) => r.name === type);

        node
            .setType(type)
            .setProps(props)
            .setOptions(options || {})
            .setDefinitions({
                id: uuidv4(),
                name: type + ((sameNodes.length || 0) + 1 || ""),
            });

        if (schemaType) {
            node.setSchemaType(schemaType)
        }

        schemaTree.insertChildren(index, node);
        store.dispatch(setPageData("schema", schemaTree.toArray()));
        // store.dispatch(setActiveElement(node.getDefinition('id')));

        return node.getDefinition("id");
    }

    static moveRoot(oldIndex: number, newIndex: number) {
        const state: RootState = store.getState();
        const pageSchema: SchemaObject[] = state.page.pageData?.schema || [];
        const schemaTree = SchemaTree.parse(pageSchema);
        schemaTree.moveChildren(oldIndex, newIndex);

        store.dispatch(setPageData("schema", schemaTree.toArray()));
    }

    static moveElement(
        fromId: string,
        toId: string,
        nodeId: string,
        oldIndex: number,
        newIndex: number
    ) {
        console.log("MOVE ELEMENT", fromId, toId, nodeId, oldIndex, newIndex);
        const state: RootState = store.getState();
        const pageSchema: SchemaObject[] = state.page.pageData?.schema || [];
        const schemaTree = SchemaTree.parse(pageSchema);
        const node = schemaTree.search(nodeId);

        if (node) {
            if (fromId === "root") {
                schemaTree.removeChildrenAt(oldIndex);
            } else {
                node.parent?.removeChildren(oldIndex);
            }

            if (toId === "root") {
                schemaTree.insertChildren(newIndex, node);
            } else {
                const toNode = schemaTree.search(toId);
                console.log({ fromId, toId, nodeId, oldIndex, newIndex, toNode });
                if (toNode) {
                    toNode.insertChildren(newIndex, node);
                }
            }

            store.dispatch(setPageData("schema", schemaTree.toArray()));
        }
    }

    static generateChildrens(children: SchemaNode[], count?: number): { dynamics: IDynamicFilter[], children: SchemaNode[] } {
        const state: RootState = store.getState();
        const page = state.page.pageData as IPost;
        const dynamics = _.cloneDeep(page.dynamics) || [] as IDynamicFilter[];
        const pageSchema: SchemaObject[] = page?.schema || [];
        const schemaTree = SchemaTree.parse(pageSchema);
        let latest = 0;
        let childrenCount = count ? count : 0;

        children.forEach((childrenItem) => {
            childrenCount = childrenCount + 1;
            const sameNodes = schemaTree.searchBy(
                (r) => r.name === childrenItem.name
            );
            sameNodes.forEach((x) => {
                const match = x.getName().match(/\d+$/);
                if (match) {
                    const endingNumber = parseInt(match[0], 10);

                    if (endingNumber + 1 > latest) {
                        latest = endingNumber + 1;
                    }
                }
            });
            const clonedProps = _.cloneDeep(childrenItem.props);
            const clonedSource = _.cloneDeep(childrenItem.source);

            if (clonedSource && _.keys(clonedSource).length > 0) {
                _.keys(clonedSource).forEach(key => {
                    const current = clonedSource[key];
                    if (current.type === SchemaSourceTypes.Dynamics) {
                        const dynamicIndex = dynamics.findIndex(x => x.id === current.ref);
                        if (dynamicIndex !== -1) {
                            const generatedId = uuidv4();
                            clonedSource[key].ref = generatedId;
                            dynamics.push({
                                ...dynamics[dynamicIndex],
                                id: generatedId
                            })
                        }
                    }
                })
            }

            childrenItem.setDefinitions({
                id: uuidv4(),
                name: childrenItem.name + (latest + childrenCount + ""),
            }).setProps(clonedProps).setSourceObject(clonedSource);

            if (childrenItem.children && childrenItem.children.length > 0) {
                this.generateChildrens(childrenItem.children, childrenCount);
            }

        });
        return {
            dynamics,
            children
        };
    }

    static insertAt(
        parentId: string,
        type: string,
        props: object,
        index: number,
        children?: SchemaNode[],
        options?: EntryOptions,
        source?: SchemaSource,
        schemaType?: string
    ) {
        const state: RootState = store.getState();
        const page = state.page.pageData as IPost;
        const pageSchema: SchemaObject[] = page.schema || [];
        const schemaTree = SchemaTree.parse(pageSchema);
        const parentNode = schemaTree.search(parentId);
        let dynamics = _.cloneDeep(page.dynamics) || [] as IDynamicFilter[];

        if (parentNode) {
            const node = new SchemaNode(parentNode);
            const sameNodes = schemaTree.searchBy((r) => r.name === type);
            const clonedProps = _.cloneDeep(props);
            const clonedSource = _.cloneDeep(source);

            if (clonedSource && _.keys(clonedSource).length > 0) {
                _.keys(clonedSource).forEach(key => {
                    const current = clonedSource[key];
                    if (current.type === SchemaSourceTypes.Dynamics) {
                        const dynamicIndex = dynamics.findIndex(x => x.id === current.ref);
                        if (dynamicIndex !== -1) {
                            const generatedId = uuidv4();
                            clonedSource[key].ref = generatedId;
                            dynamics.push({
                                ...dynamics[dynamicIndex],
                                id: generatedId
                            })
                        }
                    }
                })
            }

            node
                .setType(type)
                .setProps(clonedProps)
                .setOptions(options || {})
                .setDefinitions({
                    id: uuidv4(),
                    name: type + ((sameNodes.length || 0) + 1 || ""),
                })
                .setSourceObject(clonedSource || {});

            if (schemaType) {
                node.setSchemaType(schemaType)
            }

            if (children && children.length > 0) {
                const generated = this.generateChildrens(children);
                node.appendChildrens(generated.children);
                dynamics = generated.dynamics;
            }

            parentNode.insertChildren(index, node);

            store.dispatch(initialPageData({
                ...page,
                schema: schemaTree.toArray(),
                dynamics
            }));

            return node.getDefinition("id");
        }
    }
}

import { ModalActionTypes, TOGGLE_MODAL, SET_MODAL_DATA, ModalNames, SET_MODAL_ERRORS } from './types';

export function toggleModal<T>(id: ModalNames, data?: T, callback?: (T?: any) => void): ModalActionTypes {
	return {
		type: TOGGLE_MODAL,
		data: data ? data : {},
		id,
		callback
	};
}

export function setModalData<T>(id: ModalNames, data: T): ModalActionTypes {
	return {
		type: SET_MODAL_DATA,
		data,
		id
	};
}

export function setModalErrors<T>(id: ModalNames, errors: T): ModalActionTypes {
	return {
		type: SET_MODAL_ERRORS,
		errors,
		id
	};
}
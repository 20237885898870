import { ComboBox } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { api } from 'app/utils/api';
import { IMetaBindingObject, IMetaDropdown, MetaBindingObjectTypes } from 'app/services/content-type';
import { IPost, mapPostFromEntity, PostService } from 'app/services/post';
import { ModalNames } from 'app/redux/modal/types';
import { HttpStatusCode } from 'app/shared';
import { BaseMetaFieldsProps } from './types';
import { HttpClient } from 'app/library/client';
import { getEndpoint } from 'app/utils/service';

interface ICustomServiceValue {
    id: string;
    title: string;
}

interface MetaDropdownState {
    posts: IPost[];
    customServiceValues: ICustomServiceValue[]
}

export default class MetaDropdown extends Component<BaseMetaFieldsProps<IMetaDropdown>, MetaDropdownState> {

    state = {
        posts: [] as IPost[],
        customServiceValues: [] as ICustomServiceValue[],
    }

    UNSAFE_componentWillMount() {
        this.makeRequests()
    }

    UNSAFE_componentWillReceiveProps(nextProps: BaseMetaFieldsProps<IMetaDropdown>) {
        if (nextProps.language !== this.props.language) {
            this.makeRequests(nextProps.language || 'tr')
        }
    }

    async makeRequests(language?: string) {
        if (this.props.meta.bindings) {
            if (this.props.meta.bindings.type === MetaBindingObjectTypes.ContentType && this.props.meta.bindings.contentTypeId) {
                const service = new PostService();
                const response = await service.getPosts({ page: 1, pageSize: 10000, descending: false, language: language || 'tr' }, this.props.meta.bindings.contentTypeId)
                if (response.status === HttpStatusCode.OK) {
                    const list = response.data.attributes?.list || [];
                    const mapped = list.map(item => mapPostFromEntity(item));
                    this.setState({
                        posts: mapped
                    })
                }
            }
            if (this.props.meta.bindings.type === MetaBindingObjectTypes.CustomService && this.props.meta.bindings?.endpointName) {
                const endpoint = this.props.meta.bindings.endpointName === "category" ? api.prefixes.category.list + this.props.contentTypeId : api.prefixes[this.props.meta.bindings.endpointName].list
                const response = await HttpClient.getClient().get(getEndpoint(endpoint) + `?page=1&pageSize=100&descending=false`);
                if (response.status === HttpStatusCode.OK) {
                    const mapped = response.data.attributes["list"] || []
                    this.setState({
                        customServiceValues: mapped || []
                    })
                }
            }
        }
    }

    getItems() {
        if (this.props.meta.bindings) {
            if (this.props.meta.bindings?.type === MetaBindingObjectTypes.Custom) {
                return this.props.meta.bindings.customOptions || []
            }
            else if (this.props.meta.bindings?.type === MetaBindingObjectTypes.CustomService) {
                return (this.state.customServiceValues || []).map(item => ({ key: item.id as string, text: item.title as string }))
            }
            else {
                return (this.state.posts || []).map(item => ({ key: item.id as string, text: item.title, data: { url: item.url } }))
            }
        }
        return [];
    }

    renderQuickInsertion() {
        const { type, endpointName, specificationTypeId } = this.props.meta.bindings || {} as IMetaBindingObject;
        if (type !== MetaBindingObjectTypes.Custom && type !== MetaBindingObjectTypes.ContentType) {
            let modalData = {};
            if (specificationTypeId) {
                modalData["specificationTypeId"] = specificationTypeId;
            }
            if (endpointName && endpointName === "category") {
                modalData["contentType"] = specificationTypeId;
            }
            return (
                <div onClick={() => this.props.toggleModal((endpointName as ModalNames) || "specification", modalData, () => this.makeRequests())} className="quick-insertion">
                    <i className="fas fa-hat-wizard"></i>
                    Hızlı Ekle
                </div>
            )
        }
        return null;
    }

    render() {
        const items = this.getItems();

        return (
            <React.Fragment>
                {this.renderQuickInsertion()}
                <ComboBox
                    placeholder={this.props.meta.title}
                    selectedKey={this.props.meta?.data?.id as string || ""}
                    className="custom-combobox"
                    autoComplete="on"
                    allowFreeform
                    disabled={(!items || items.length === 0) && !this.props.meta.data}
                    onChange={(_, option) => this.props.onChange({
                        id: option?.key as string,
                        title: option?.text as string,
                        url: option?.data?.url || ""
                    })}
                    options={items}
                    calloutProps={{ className: "custom-combobox-callout" }}
                />
            </React.Fragment>
        )
    }
}

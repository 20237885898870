import React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from 'app/redux/reducer';
import { toggleModal } from 'app/redux/modal/actions';
import { connect } from 'react-redux';
import { SchemaNode } from 'app/library/layout-builder';
import { IDynamicFilter } from 'app/shared';
import FilterWizardModal from './FilterWizardModal';

interface OwnProps {
    filter: IDynamicFilter;
    node: SchemaNode;
    onChange: (data: IDynamicFilter) => void;
}

function mapStateToProps(state: RootState) {
    return {
        contentTypes: state.contentType.contentTypes?.list || []
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            toggleModal
        },
        dispatch
    );
}

type FilterWizardFieldProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & OwnProps;

interface FilterWizardFieldState {
    showModal: boolean;
}

class FilterWizardField extends React.Component<FilterWizardFieldProps, FilterWizardFieldState> {

    state = {
        showModal: false
    }

    handleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    onChange(data: IDynamicFilter) {
        this.props.onChange(data);
    }

    render() {
        return (
            <div className="data-wizard-field row">
                <FilterWizardModal
                    show={this.state.showModal}
                    filter={this.props.filter}
                    contentTypes={this.props.contentTypes}
                    data={[]}
                    node={this.props.node}
                    onChange={(data) => this.onChange(data)}
                    onDismiss={() => this.setState({ showModal: false })}
                />
                <DefaultButton
                    iconProps={{ iconName: "LinkedDatabase" }}
                    className="custom-button"
                    text="Filtre Sihirbazını Aç"
                    styles={{
                        root: {
                            border: "1px solid #ccc",
                            height: "29px",
                            marginTop: "-1px"
                        }
                    }}
                    style={{
                        fontSize: 9
                    }}
                    onClick={() => this.handleModal()}
                />
                {this.props.filter?.id && (
                    <span className="filled row">
                        <i className="icon-checkmark"></i>
                        Filtre Oluşturuldu!
                        {/* <span className="query-id">
                            ({this.props.filter.id})
                        </span> */}
                    </span>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterWizardField);

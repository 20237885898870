import { ThunkAction } from "redux-thunk";
import { RootState } from "app/redux/reducer";
import { Action } from "redux";
import { showMessage, toggleLoader, togglePanelLoader } from "app/redux/system/actions";

import { initialPageData } from "app/redux/page/actions";
import { MessageType } from 'app/redux/system/types';
import { setContentType, setContentTypes } from 'app/redux/content-type/actions';
import _ from "lodash";
import { ContentTypeService } from "./service";
import { ContentTypeTree, IContentType } from "./types";
import { mapContentTypeFromEntity, mapContentTypeFromModel } from "./mapping";
import { HttpStatusCode, IListFilter } from "app/shared";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getContentType(contentTypeId: string, fromPost?: boolean, noDispatch?: boolean): ThunkResult<Promise<IContentType | null>> {
    return async (dispatch, getState) => {
        const service = new ContentTypeService();
        dispatch(toggleLoader());
        const response = await service.getContentType(contentTypeId);
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapContentTypeFromEntity(response.data.attributes["contentType"] || {});
            if (!noDispatch) {
                if (!fromPost) {
                    dispatch(initialPageData<IContentType>(mapped));
                }
                else {
                    dispatch(setContentType(mapped))
                }
            }
            dispatch(toggleLoader());
            return mapped;
        }
        dispatch(toggleLoader());
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return null;
    };
}

export function getContentTypes(options: IListFilter, fromPanel?: boolean): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new ContentTypeService();
        fromPanel ? dispatch(togglePanelLoader()) : dispatch(toggleLoader());
        const response = await service.getContentTypes(options);
        fromPanel ? dispatch(togglePanelLoader()) : dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map((item) =>
                mapContentTypeFromEntity(item)
            );
            dispatch(setContentTypes({
                list: mapped,
                pageSize: response.data.attributes.pageSize,
                totalCount: response.data.attributes.total,
                pageCount: Math.ceil(response.data.attributes.total / response.data.attributes.pageSize)
            }));
            return true;
        }
        return false;
    };
}

export function getContentTypePosts(): ThunkResult<Promise<ContentTypeTree[]>> {
    return async (dispatch, getState) => {
        const service = new ContentTypeService();
        const response = await service.getContentTypePosts({ page: 1, pageSize: 100, descending: false });
        if (response.status === HttpStatusCode.OK) {
            return response.data.attributes.list || [];
        }
        return [];
    };
}

export function createContentType(request: IContentType, fromClone?: boolean): ThunkResult<Promise<IContentType | null | boolean>> {
    return async (dispatch, getState) => {
        const service = new ContentTypeService();
        if (!fromClone) {
            dispatch(toggleLoader());
        }
        const response = await service.createContentType(mapContentTypeFromModel(request));
        if (response.status === HttpStatusCode.OK) {
            if (fromClone) {
                return true;
            }
            else {
                dispatch(toggleLoader());
                const mapped = mapContentTypeFromEntity(response.data.attributes["contentType"] || {});
                const instance = _.cloneDeep(getState().contentType?.contentTypes || []);
                instance.list.push(mapped);
                dispatch(setContentTypes(instance));
                return mapped;
            }
        }
        dispatch(toggleLoader());
        return null;
    };
}

export function updateContentType(request: IContentType): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new ContentTypeService();
        dispatch(toggleLoader());
        const response = await service.updateContentType(mapContentTypeFromModel(request));
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        return false;
    };
}

export function deleteContentType(contentTypeId: string): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new ContentTypeService();
        dispatch(toggleLoader());
        const response = await service.deleteContentType(contentTypeId);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}

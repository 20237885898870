import React from "react";
import Autosuggest from 'react-autosuggest';

interface AutoCompleteProps {
    placeholder: string;
    selectedValue: string;
    onSelected: (value: string) => void;
    filterData: string[];
    disabled?: boolean;
}

export default class AutoComplete extends React.Component<AutoCompleteProps> {

    state = {
        suggestions: [],
        filterText: this.props.selectedValue || ""
    }

    UNSAFE_componentWillReceiveProps(nextProps: AutoCompleteProps) {
        if (nextProps.selectedValue !== this.props.selectedValue) {
            this.setState({
                filterText: nextProps.selectedValue
            })
        }
    }

    getSuggestions = (value: string) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return this.props.selectedValue ? this.props.filterData : (this.props.filterData || []).filter(string =>
            string.toLowerCase().slice(0, inputLength) === inputValue
        );
    }

    getSuggestionValue = (suggestion: string) => suggestion;

    renderSuggestion = (suggestion: string) => (
        <div className="input-suggested">
            {suggestion}
        </div>
    );

    onChange = (_: any, { newValue }: { newValue: string }) => {
        this.setState({
            filterText: newValue
        }, () => {
            if (newValue === "") {
                this.props.onSelected("");
            }
        })
    };

    onSuggestionsFetchRequested = ({ value }: { value: string }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { suggestions } = this.state;

        const inputProps = {
            placeholder: this.props.placeholder,
            value: this.state.filterText,
            onChange: this.onChange,
            readOnly: this.props.disabled,
            className: this.props.selectedValue && this.props.selectedValue.split(".")[0] === "meta" ? `react-autosuggest__input meta ${this.props.disabled ? "disabled" : ""}` : `react-autosuggest__input ${this.props.disabled ? "disabled" : ""}`
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                onSuggestionSelected={(event, data) => this.props.onSelected(data.suggestion)}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                alwaysRenderSuggestions
                focusInputOnSuggestionClick={true}
            />
        );
    }
}
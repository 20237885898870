import { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from "app/redux/reducer";
import { Dispatch, bindActionCreators } from 'redux';
import PageTitle from 'components/customs/PageTitle';
import { TextField, MessageBar, MessageBarType, PrimaryButton, ComboBox } from 'office-ui-fabric-react';
import { setPageData, setPageErrors, initialPageData } from 'app/redux/page/actions';
import * as _yup from "yup";
import { StatusEnum, MetaTypeEnum } from 'app/shared';
import { castThunkAction } from 'app/utils/casting';
import _ from "lodash";
import { ReactSortable } from "react-sortablejs";
import { toggleModal } from 'app/redux/modal/actions';
import { createContentType, IContentType, IMeta, ISpec } from 'app/services/content-type';
import { getLayoutsForLocal, ILayout } from 'app/services/layout';
import PageLayout from 'containers/PageLayout';
import { RouteComponentProps } from 'react-router-dom';
import FormAccordion from 'components/customs/FormAccordion';
import MetaModal from 'components/customs/Modals/MetaModal';
import { getMeta } from 'components/customs/MetaFields/Fields/constants';
import Sidebar from 'components/customs/Sidebar/Sidebar';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
    return {
        ...ownProps,
        language: state.system.language,
        pageData: state.page.pageData as IContentType,
        pageErrors: state.page.pageErrors,
        contentTypes: state.contentType.contentTypes?.list || []
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            setPageData,
            setPageErrors,
            initialPageData,
            createContentType,
            getLayoutsForLocal,
            toggleModal
        }, dispatch)
    };
}

type CreateContentTypeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface OwnState {
    messageType: MessageBarType;
    layoutData: ILayout[];
    metaModal: { show: boolean; data?: IMeta | null; index?: number };
    specModal: { show: boolean; data?: ISpec | null; index?: number };
}

class CreateContentType extends Component<CreateContentTypeProps, OwnState> {

    state = {
        messageType: MessageBarType.warning,
        layoutData: [] as ILayout[],
        metaModal: {
            show: false,
            data: null,
            index: -1
        },
        specModal: {
            show: false,
            data: null,
            index: -1
        }
    }

    componentDidMount() {
        this.resetData();
        castThunkAction<ILayout[]>(this.props.getLayoutsForLocal("en")).then(response => {
            this.setState({
                layoutData: response
            })
        })
    }

    _updateMeta(data: IMeta, index: number) {
        this.setState({
            metaModal: {
                show: false
            }
        }, () => {
            const metaFields = _.cloneDeep(this.props.pageData.metaFields || []);
            metaFields[index] = data;
            this.props.setPageData("metaFields", metaFields);
        })
    }

    _insertMeta(data: IMeta) {
        this.setState({
            metaModal: {
                show: false
            }
        }, () => {
            const metaFields = _.cloneDeep(this.props.pageData.metaFields || []);
            metaFields.push(data);
            this.props.setPageData("metaFields", metaFields);
        })
    }

    _removeMeta(index: number) {
        this.setState({
            metaModal: {
                show: false
            }
        }, () => {
            const metaFields = _.cloneDeep(this.props.pageData.metaFields || []);
            metaFields.splice(index, 1)
            this.props.setPageData("metaFields", metaFields);
        })
    }


    _updateSpec(data: ISpec, index: number) {
        this.setState({
            specModal: {
                show: false
            }
        }, () => {
            const specifications = _.cloneDeep(this.props.pageData.specifications || []);
            specifications[index] = data;
            this.props.setPageData("specifications", specifications);
        })
    }

    _insertSpec(data: ISpec) {
        this.setState({
            specModal: {
                show: false
            }
        }, () => {
            const specifications = _.cloneDeep(this.props.pageData.specifications || []);
            specifications.push(data);
            this.props.setPageData("specifications", specifications);
        })
    }

    _removeSpec(index: number) {
        this.setState({
            specModal: {
                show: false
            }
        }, () => {
            const specifications = _.cloneDeep(this.props.pageData.specifications || []);
            const filters = _.cloneDeep(this.props.pageData.filters || []);
            const quickFilters = _.cloneDeep(this.props.pageData.quickFilters || []);
            const spec = specifications[index];
            const filterSpecIndex = filters.findIndex(x => x.name === spec.name);
            const quickFilterSpecIndex = filters.findIndex(x => x.name === spec.name);
            specifications.splice(index, 1);
            if (filterSpecIndex !== -1) {
                filters.splice(filterSpecIndex, 1);
            }
            if (quickFilterSpecIndex !== -1) {
                quickFilters.splice(quickFilterSpecIndex, 1);
            }
            this.props.initialPageData({
                ...this.props.pageData,
                specifications,
                filters,
                quickFilters
            });
        })
    }

    resetData() {
        this.setState({
            messageType: MessageBarType.warning
        }, () => {
            this.props.initialPageData({});
        })
    }

    renderTextByMessageType() {
        switch (this.state.messageType) {
            case MessageBarType.success:
                return "Kaydetme işlemi başarıyla gerçekleşmiştir. Düzenleme sayfasına yönlendiriliyorsunuz..."
            case MessageBarType.error:
                return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin."
            default:
                return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!"
        }
    }

    renderMessageBar() {
        return (
            <MessageBar
                messageBarType={this.state.messageType}
                isMultiline={false}
            >
                {this.renderTextByMessageType()}
            </MessageBar>
        )
    }

    /**
     * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
     * formErrors Objesini Dolduracak Action'u Tetikler.
     */
    onSubmit(isDraft?: boolean) {
        const {
            pageData,
            setPageErrors
        } = this.props;
        const validationSchema = _yup.object({
            title: _yup.string().required(
                "Bu alan zorunludur!"
            )
        });
        let formErrors = {};
        validationSchema
            .validate(pageData, {
                abortEarly: false
            })
            .then(() => {
                setPageErrors({});
                castThunkAction<IContentType>(this.props.createContentType({
                    ...this.props.pageData,
                    status: isDraft ? StatusEnum.Draft : StatusEnum.Published,
                    removable: true,
                    layout: typeof pageData.layout === "string" && !pageData.layout ? undefined : pageData.layout
                })).then(res => {
                    this.setState({
                        messageType: res?.id ? MessageBarType.success : MessageBarType.error
                    }, () => {
                        if (res?.id) {
                            setTimeout(() => {
                                this.props.history.push(`/content-type/edit/${res.id}`)
                            }, 1200)
                        }
                    })
                })

            })
            .catch(err => {
                err.inner.forEach((e: any) => {
                    const { path, message } = e;
                    formErrors[path] = message;
                });
                setPageErrors(formErrors);
            });
    };

    renderMetaFields() {
        return (
            <div style={{ paddingTop: 0 }} className="form-group col-md-12">
                <FormAccordion title="Metalar">
                    <MetaModal
                        show={this.state.metaModal.show}
                        onDismiss={() => this.setState({
                            metaModal: {
                                show: false
                            }
                        })}
                        onSave={(data) => this.state.metaModal.data ? this._updateMeta(data, this.state.metaModal.index) : this._insertMeta(data)}
                        data={this.state.metaModal.data}
                        contentTypes={this.props.contentTypes}
                        isUpdateMode={this.state.metaModal.data ? true : false}
                        toggleModal={this.props.toggleModal}
                    />
                    <div className="form-wrapper">
                        <MessageBar
                            isMultiline={false}
                            styles={{ root: { marginTop: "0px !important", background: "#e7f0f6" } }}
                            messageBarType={MessageBarType.info}
                            actions={
                                <PrimaryButton
                                    text="Yeni Ekle"
                                    onClick={() => this.setState({ metaModal: { show: true } })}
                                    styles={{
                                        root: {
                                            fontSize: 11,
                                            height: 24,
                                            padding: "0px",
                                            marginRight: "-3.5px"
                                        }
                                    }}
                                />
                            }
                        >
                            Eklenmiş metaları aşağıdan görüntüleyebilir, dilerseniz yeni meta ekleyebilirsiniz.
                        </MessageBar>
                        <div className="meta-constant-list row">
                            <ReactSortable
                                list={(this.props.pageData.metaFields || []).map((item, index) => ({ ...item, id: index })) || []}
                                setList={(newState) => this.props.setPageData("metaFields", newState)}
                                animation={100}
                                className="row"
                                style={{ width: "100%" }}
                            >
                                {(this.props.pageData.metaFields || []).map((item, index) => (
                                    <div
                                        style={{ cursor: "move" }}
                                        key={index}
                                        className="meta-item large green col-md-2"
                                    >
                                        <div className="meta-item-wrapper row">
                                            <div onClick={() => this.setState({ metaModal: { show: true, data: item, index } })}
                                                className="icon">
                                                <div className="default">
                                                    <i className={`${getMeta(item.type as MetaTypeEnum)?.icon}`}></i>
                                                </div>
                                            </div>
                                            <div onClick={() => this.setState({ metaModal: { show: true, data: item, index } })}
                                                className="text">
                                                {item.title}
                                                <div className="sub-text">
                                                    {getMeta(item.type as MetaTypeEnum).title} - {item.column} / 12
                                                </div>
                                            </div>
                                            <div onClick={() => this._removeMeta(index)} style={{ cursor: "pointer" }} className="remove">
                                                <i className="icon-close1"></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    </div>
                </FormAccordion>
            </div>
        )
    }

    renderForm() {
        const { pageData, pageErrors, setPageData } = this.props;
        return (
            <div className="form-groups row">
                <div className="form-group col-md-12">
                    <FormAccordion title="Genel Bilgiler">
                        <div className="form-wrapper">
                            <div className="item row">
                                <TextField
                                    placeholder="İçerik Tipi Adı"
                                    value={pageData.title || ""}
                                    label="İçerik Tipi Adı"
                                    className="custom-textfield border"
                                    errorMessage={pageErrors.title}
                                    onChange={(event: any) => setPageData("title", event.target.value)}
                                />
                            </div>
                            <div className="item row">
                                <ComboBox
                                    selectedKey={pageData.layout || ""}
                                    label="Varsayılan Layout"
                                    className="custom-combobox border"
                                    calloutProps={{
                                        className: "custom-combobox-callout"
                                    }}
                                    allowFreeform
                                    errorMessage={pageErrors.layout}
                                    onChange={(_, option) => setPageData("layout", option?.key || "")}
                                    options={(this.state.layoutData || []).map(x => ({ key: x.id as string, text: x.title }))}
                                />
                            </div>
                        </div>
                    </FormAccordion>
                </div>
                {this.renderMetaFields()}
            </div>
        )
    }

    render() {
        return (
            <PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
                <div className="page push-all">
                    <PageTitle
                        title="Yeni İçerik Tipi Ekle"
                        titleRenderer={
                            () => (
                                <span className="edit-title">
                                    Yönet:
                                    <strong>Yeni İçerik Tipi Ekle</strong>
                                </span>
                            )
                        }
                        showBack
                        backUrl="/content-types"
                        history={this.props.history}
                        subTitle="Yeni bir içerik tipi eklemek için aşağıdaki bilgileri doldurunuz, dilerseniz taslak olarakta oluşturabilirsiniz."
                    />
                    <PrimaryButton
                        onClick={() => { this.resetData() }}
                        iconProps={{ iconName: "Add" }}
                        className="absolute-create"
                        text="Yeni İçerik Tipi Ekle"
                        styles={{
                            root: {
                                background: "#62a98e",
                                borderColor: "#5b9e85",
                                color: "#fff"
                            },
                            rootHovered: {
                                background: "#5b9e85",
                                borderColor: "#5b9e85"
                            },
                            rootDisabled: {
                                background: "#ececec"
                            }
                        }}
                    />
                    <div className="content row">
                        <div className="left col no-padding">
                            {this.renderMessageBar()}
                            {this.renderForm()}
                        </div>
                        <Sidebar
                            layoutBar={{
                                hide: true
                            }}
                            categoryBar={{
                                hide: true
                            }}
                            publishBar={{
                                onSave: (isDraft) => this.onSubmit(isDraft),
                                disabledSaveButton: this.state.messageType === MessageBarType.success ? true : false,
                                hideDeleteButton: true,
                                hidePreviewButton: true
                            }}
                            archiveBar={{
                                hide: true
                            }}
                            thumbnailBar={{
                                hide: true
                            }}
                        />
                    </div>
                </div>
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateContentType);

import React from "react";
import { ContainerEntry, SchemaNode } from 'app/library/layout-builder';
import { DESIGNER_COMPONENTS, IDesignerItems } from 'app/shared/constants';

interface ComponentDescriptionProps {
    entry?: ContainerEntry;
    node?: SchemaNode;
    text?: string
}

export default class ComponentDescription extends React.Component<
    ComponentDescriptionProps
> {

    getComponentDefinition() {
        const distinctItems = DESIGNER_COMPONENTS.reduce((prev: IDesignerItems[], current) => prev = [...prev, ...current.items], [])
        return distinctItems.find(x => x.schema === this.props.entry?.name);
    }

    render() {
        const componentDefinition = this.getComponentDefinition();
        return (
            <div className="blank-wrapper">
                <div className="blank-box">
                    {/* <i className={`icon-f_${componentDefinition?.icon}`}></i> */}
                    {componentDefinition?.icon && (
                        <img
                            src={`${require(`assets/images/f_${componentDefinition?.icon}.svg`).default}`}
                            alt={componentDefinition?.icon}
                        />
                    )}
                    <div className="title">{this.props.text || componentDefinition?.name}</div>
                    <div
                        dangerouslySetInnerHTML={{ __html: this.props.node?.getDefinition("description") || componentDefinition?.description }}
                        className="sub-text"
                    />
                </div>
            </div>
        );
    }
}










import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { RecoveryService } from "./service";
import { RootState } from "app/redux/reducer";
import { showMessage, toggleLoader } from "app/redux/system/actions";
import { initialPageData } from "app/redux/page/actions";
import { MessageType } from "app/redux/system/types";
import { HttpStatusCode, IList, IListFilter } from "app/shared";
import { IRecovery } from "./types";
import { mapRecoveryFromEntity } from "./mapping";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;


export function getRecoveries(options: IListFilter, contentType: string): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new RecoveryService();
        dispatch(toggleLoader());
        const response = await service.getRecoverys(options, contentType);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map((item) =>
                mapRecoveryFromEntity(item)
            );
            dispatch(initialPageData<IList<IRecovery[]>>({
                list: mapped,
                pageSize: response.data.attributes.pageSize,
                totalCount: response.data.attributes.total,
                pageCount: Math.ceil(response.data.attributes.total / response.data.attributes.pageSize)
            }));
            return true;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}

export function updateRecovery(contentType: string, id: string): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new RecoveryService();
        dispatch(toggleLoader());
        const response = await service.updateRecovery(contentType, id);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        return false;
    };
}

export function deleteRecovery(recoveryId: string): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new RecoveryService();
        dispatch(toggleLoader());
        const response = await service.deleteRecovery(recoveryId);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}
import { RowDefinitions } from "./RowDefinitions";
import RowDesigner from "./RowDesigner";

export class Row extends RowDefinitions {
    render() {
        return (
            <div
                data-schema-id={this.props.id}
                className={`fa-draggable fa-Grid row ${this.props.designing ? "designing" : ""
                    } ${this.props.defaultElement ? "is-default" : ""}`}
                style={{
                    minHeight: this.props.designing ? "100px" : "auto",
                    position: "relative",
                }}
            >
                {this.props.designing ? (
                    <RowDesigner {...this.props} />
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}

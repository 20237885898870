import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";

export class HomeVirtualExhibitionDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "home-virtual-exhibition";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "title",
            text: "Başlık",
            mapping: {
                type: PropertyMappingType.String
            },
        },
        {
            name: 'slogan',
            description: 'Slogan',
            mapping: {
                type: PropertyMappingType.String
            },
        },
        {
            name: 'daterange',
            description: 'Tarih Aralığı',
            mapping: {
                type: PropertyMappingType.String
            },
        },
        {
            name: "exploretextimage",
            text: "Explore metin Image",
            mapping: {
                type: PropertyMappingType.Image
            }
        },
        {
            name: "video",
            text: "Video",
            mapping: {
                type: PropertyMappingType.Video
            },
        },
        {
            name: "mobilevideo",
            text: "Mobile Video",
            mapping: {
                type: PropertyMappingType.Video
            }
        },
        {
            name: "videoposter",
            text: "Video Poster",
            mapping: {
                type: PropertyMappingType.Image
            },
        },
        {
            name: 'anchortext',
            description: 'URL Metni',
            mapping: {
                type: PropertyMappingType.String
            },
        },
        {
            name: "anchor",
            description: "URL",
            mapping: {
                type: PropertyMappingType.LinkWizard
            },
            default: null,
        },
    ];
}

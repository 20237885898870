import { api } from 'app/utils/api';
import { getEndpoint } from 'app/utils/service';
import { HttpClient } from 'app/library/client';
import { ResponseBase, ResponseContentBase, ResponseListBase } from "app/shared";
import { CategoryEntity } from './types';

export class CategoryService {

    /** It get spesific category by category id */
    async getCategory(categoryId: string): Promise<ResponseBase<ResponseContentBase<CategoryEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.category.main) + categoryId);
    }

    /** It get all categories by content type */
    async getCategories(contentTypeId?: string): Promise<ResponseBase<ResponseListBase<CategoryEntity[]>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.category.list) + (contentTypeId || ""));
    }

    /** It creates category by request */
    async createCategory(request: CategoryEntity): Promise<ResponseBase<ResponseContentBase<CategoryEntity>>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.category.main), request);
    }

    /** It updates spesific category by id */
    async updateCategory(request: CategoryEntity): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().patch(getEndpoint(api.prefixes.category.main) + request.id, request);
    }

    /** It check category slug validate */
    async getCategorySlug(slug: string): Promise<ResponseBase<ResponseContentBase<any>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.category.url) + slug);
    }

    /** It deletes spesific category by id */
    async deleteCategory(categoryId: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().delete(getEndpoint(api.prefixes.category.main) + categoryId);
    }
}

import { DefaultButton } from 'office-ui-fabric-react'
import { Component } from 'react'
import { FileTypes } from 'app/services/file-manager'
import FileManager from './FileManager/FileManager'
import { getFilePath, isFileStorage } from 'app/utils/service'

import ImageIcon from "assets/images/placeholder.png"

interface ImagePickerProps {
    image: string;
    onChange: (image: string) => void;
}

interface ImagePickerState {
    showModal: boolean;
}

export default class ImagePicker extends Component<ImagePickerProps, ImagePickerState> {

    state = {
        showModal: false
    }

    renderFile() {
        const splitted = this.props.image?.split(".");
        const imageSource = isFileStorage(this.props.image) ? this.props.image + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=200&height=200" : "") : (getFilePath() + this.props.image);
        return (
            <img
                src={this.props.image ? imageSource : ImageIcon}
                alt={this.props.image} />
        );
    }

    render() {
        return (
            <div className="meta-file">
                <FileManager
                    show={this.state.showModal}
                    onDismiss={() => this.setState({ showModal: false })}
                    onSelectedFile={(file) => {
                        this.props.onChange(file.fileName)
                    }}
                    activeFile={{
                        id: "",
                        fileName: this.props.image || "",
                        fileExtension: "",
                        fileSize: ""
                    }}
                    fileType={FileTypes.Image}
                />
                <DefaultButton
                    iconProps={{ iconName: "Touch" }}
                    text="Görsel Seç"
                    styles={{
                        root: {
                            fontSize: "10.5px !important",
                            background: "#fff"
                        }
                    }}
                    onClick={() => {
                        this.setState({
                            showModal: true
                        })
                    }}
                />
                <div className="file-list row">
                    <div className="file-item">
                        <div className="file-item-wrapper">
                            {this.renderFile()}
                            {this.props.image && (
                                <div onClick={() => this.props.onChange("")} className="remove">
                                    <i className="icon-delete"></i>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { FairSessionProps } from "./types";
import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";

export class FairSessionDefinitions extends CmsReactComponent<FairSessionProps> {
  static defaultProps = {};

  static schemaName = "fair-session";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "title",
      mapping: {
        type: PropertyMappingType.String,
      },
      text: "Başlık",
    },
    {
      name: "panels",
      mapping: {
        type: PropertyMappingType.ArrayData,
        keys: [
          {
            name: "title",
            mapping: {
              type: PropertyMappingType.String,
            },
            text: "Başlık",
          },
          {
            name: "description",
            mapping: {
              type: PropertyMappingType.Textarea,
            },
            text: "Açıklama",
          },
          {
            name: "date",
            mapping: {
              type: PropertyMappingType.String,
            },
            text: "Tarih",
          },
          {
            name: "time",
            mapping: {
              type: PropertyMappingType.String,
            },
            text: "Zaman",
          },
          {
            name: "day",
            mapping: {
              type: PropertyMappingType.String,
            },
            text: "Gün",
          },
          {
            name: "poster",
            mapping: {
              type: PropertyMappingType.Image,
            },
            text: "Poster",
          },
          {
            name: "onlinevideo",
            mapping: {
              type: PropertyMappingType.String,
            },
            text: "Youtube Video Kodu",
          },
          {
            name: "panelists",
            mapping: {
              type: PropertyMappingType.ArrayData,
              keys: [
                // {
                //   name: "photo",
                //   mapping: {
                //     type: PropertyMappingType.Image,
                //   },
                //   text: "Fotoğraf",
                // },
                {
                  name: "fullname",
                  mapping: {
                    type: PropertyMappingType.String,
                  },
                  text: "Tam Adı",
                },
                {
                  name: "description",
                  mapping: {
                    type: PropertyMappingType.String,
                  },
                  text: "Açıklama",
                },
                {
                  name: "moderator",
                  mapping: {
                    type: PropertyMappingType.Boolean,
                  },
                  text: "Moderatör mü?",
                },
              ]
            },
            default: []
          }
        ]
      },
      default: []
    }
  ]
}

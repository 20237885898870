import {
    FileEntity,
    IFile
} from "./types";

/**
 * File entity convert to model schema
 */
export function mapFileFromEntity(fileEntity: FileEntity): IFile {
    return {
        id: fileEntity.id,
        createdBy: fileEntity.uploadedBy,
        updatedAt: fileEntity.modifiedOn,
        fileName: `${fileEntity.bucket}/${fileEntity.fileName}`,
        fileSize: fileEntity.fileSize,
        fileExtension: fileEntity.fileExtension
    }
}
import React from "react";
import ComponentDescription from "components/customs/ComponentDescription";
import ComponentHeader from "components/cms/ComponentHeader";
import { ContentGridDefinitions } from "./ContentGridDefinitions";

export class ContentGrid extends ContentGridDefinitions {
    render() {
        const { className, designing } = this.props;

        if (this.props.hideRuntime && !this.props.designing) return null;

        return (
            <React.Fragment>
                <div data-schema-id={this.props.id}
                    className={["fa-Html disable-inside fa-draggable", designing && "designing", className].filter(e => !!e).join(" ")}>
                    {designing && (
                        <ComponentHeader
                            componentEntry={this.props.entry}
                            componentSchemaId={this.props.id as string}
                        />
                    )}
                    <ComponentDescription
                        node={this.props.schemaNode}
                        entry={this.props.entry}
                    />
                </div>
            </React.Fragment>
        );
    }
}

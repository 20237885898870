import { DefaultButton } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { IMetaLink } from 'app/services/content-type';
import LinkWizardModal from '../../Modals/LinkWizardModal';
import { BaseMetaFieldsProps } from './types';

interface IMetaLinkState {
    showModal: boolean;
}

export default class MetaLinkWizard extends Component<BaseMetaFieldsProps<IMetaLink>, IMetaLinkState> {

    state = {
        showModal: false
    }

    renderLinkWizardModal() {
        if (!this.state.showModal) return null;
        return (
            <LinkWizardModal
                show={this.state.showModal}
                onSave={(data) => {
                    this.props.onChange(data as IMetaLink);
                    this.setState({
                        showModal: false
                    })
                }}
                data={this.props.meta.data || null}
                onDismiss={() => this.setState({ showModal: false })}
                contentType={this.props.meta.contentType as string}
                onSaveContentType={(contentType) => this.props.onChangeContentType ? this.props.onChangeContentType(contentType) : {}}
                language={this.props.language || "en"}
            />
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderLinkWizardModal()}
                <DefaultButton
                    text="Link Sihirbazını Aç"
                    iconProps={{ iconName: "Touch" }}
                    styles={{
                        root: {
                            fontSize: "11px !important"
                        }
                    }}
                    onClick={() => {
                        this.setState({
                            showModal: true
                        })
                    }}
                />
            </React.Fragment>
        )
    }
}

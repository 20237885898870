import ComponentHeader from "components/cms/ComponentHeader";
import ComponentDescription from "components/customs/ComponentDescription";
import { SponsorListWithTabsDefinitions } from "./SponsorListWithTabsDefinitions";

export class SponsorListWithTabs extends SponsorListWithTabsDefinitions {
    render() {
        const style = this.props.designing
            ? {
                minHeight: "100px",
                padding: "0px",
            }
            : {
                minHeight: "auto",
                padding: "10px",
            };

        return (
            <div
                data-schema-id={this.props.id}
                className={`fa-draggable fa-News ${this.props.designing ? "designing" : ""
                    }`}
                style={{ ...style, position: "relative" }}
            >
                <ComponentHeader
                    componentEntry={this.props.entry}
                    componentSchemaId={this.props.id as string}
                />

                <ComponentDescription
                    node={this.props.schemaNode}
                    entry={this.props.entry}
                />
            </div>
        );
    }
}

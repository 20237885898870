import React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { toggleModal } from 'app/redux/modal/actions';
import { IKeyValue } from 'app/shared';
import { ICustomServiceWizard } from 'app/services/post';

interface CustomServiceWizardFieldProps {
    data: ICustomServiceWizard;
    toggleModal: typeof toggleModal;
}

export default class CustomServiceWizardField extends React.Component<CustomServiceWizardFieldProps> {

    onChange(data: ICustomServiceWizard) {
        this.props.toggleModal("customServiceWizard", {})
    }

    render() {
        return (
            <div className="data-wizard-field row">
                <DefaultButton
                    iconProps={{ iconName: "LinkedDatabase" }}
                    className="custom-button"
                    text="Özel Servis Sihirbazını Aç"
                    styles={{
                        root: {
                            border: "1px solid #ccc",
                            height: "29px",
                            marginTop: "-1px"
                        }
                    }}
                    style={{
                        fontSize: 9
                    }}
                    onClick={() => {
                        this.props.toggleModal<ICustomServiceWizard>("customServiceWizard", {
                            mapping: (this.props.data.node.getOption("mappingFields") || []).map((item: IKeyValue) =>
                                ({ key: item.key, value: "" })
                            ),
                            node: this.props.data.node,
                            service: ""
                        })
                    }}
                />
            </div>
        )
    }
}


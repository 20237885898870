import GridContainerDesigner from './GridContainerDesigner';
import { GridContainerDefinitions } from './GridContainerDefinitions';

export class GridContainer extends GridContainerDefinitions {

    render() {
        return (
            <div
                data-schema-type="container"
                data-schema-id={this.props.id}
                className={this.props.designing ? "designing fa-draggable fa-Container" : "fa-draggable fa-Container"}
                style={{ minHeight: "100px", maxWidth: this.props.designing ? "100%" : this.props.maxWidth + "px", padding: "0px", position: "relative" }}>
                {this.props.designing ? (
                    <GridContainerDesigner {...this.props} />
                ) : this.props.children}
            </div>
        );
    }
}


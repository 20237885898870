import * as React from "react";
import { BaseFieldProps } from "./types";
import { PropertyMappingType } from "app/library/layout-builder";
import { PrimaryButton, Toggle } from "office-ui-fabric-react";
import FormItemsField from "./FormItemsField";
import AccordionField from "./AccordionField";
import ProductDataField from "./ProductDataField";
import { capitalize } from 'lodash';
import { toggleModal } from 'app/redux/modal/actions';
import { SchemaService } from "app/services/schema";

interface ArrayFieldState {
    sortableMode: boolean;
}

// Kendisine gelen Property ismine ve değerine göre Array Field render eder.
export default class ArrayField extends React.Component<BaseFieldProps, ArrayFieldState> {

    state = {
        sortableMode: false
    }

    renderFields() {
        switch (this.props.property.mapping.type) {
            case PropertyMappingType.ArrayData:
                return (
                    <AccordionField
                        property={this.props.property}
                        node={this.props.node}
                        value={this.props.value}
                        index={this.props.index}
                        schema={this.props.schema}
                        onChange={value => this.props.onChange(value)}
                        toggleModal={this.props.toggleModal as typeof toggleModal}
                        sortableMode={this.state.sortableMode}
                        language={this.props.language}
                    />
                );
            case PropertyMappingType.ProductData:
                return (
                    <ProductDataField
                        property={this.props.property}
                        node={this.props.node}
                        value={this.props.value}
                        index={this.props.index}
                        schema={this.props.schema}
                        onChange={value => this.props.onChange(value)}
                        language={this.props.language}
                    />
                );
            default:
                return (
                    <FormItemsField
                        property={this.props.property}
                        node={this.props.node}
                        value={this.props.value}
                        index={this.props.index}
                        schema={this.props.schema}
                        onChange={value => this.props.onChange(value)}
                        toggleModal={this.props.toggleModal as typeof toggleModal}
                        language={this.props.language}
                    />
                )
        }
    }

    renderArrayFields() {

        const keys = this.props.property?.mapping?.getKeys ? this.props.property.mapping.getKeys(this.props) : this.props.property?.mapping?.keys || [];
        const sahaNewsLength = this.props?.node?.props?.sahaNews?.length;
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        return (
            <div className="external-panel external-data-panel active">
                {this.props.property.mapping?.fieldsRenderer ? (
                    this.props.property.mapping.fieldsRenderer(
                        {
                            fieldProps: {
                                property: this.props.property,
                                node: this.props.node,
                                onChange: this.props.onChange,
                                value: this.props.value,
                                schema: this.props.schema,
                                index: this.props.index,
                                language: this.props.language
                            },
                            schemaService: SchemaService
                        }
                    )
                ) : (
                    <React.Fragment>
                        <div className="external-items-head row">
                            <div className="property-item row">
                                <div title={this.props.property.name} className="property-text">
                                    <div className="title">{this.props.property.text || capitalize(this.props.property.name)}</div>
                                    <div className="sub-title">{this.props.property.description || this.props.property.name}</div>
                                </div>
                                <div className="col row">
                                    {typeof sahaNewsLength === 'undefined'  &&
                                        <PrimaryButton
                                            iconProps={{ iconName: "CalculatorAddition" }}
                                            className="custom-button"
                                            text="Yeni Ekle"
                                            onClick={() => {
                                                const newItem = keys.reduce(
                                                    (prev, x) => {
                                                        return {
                                                            ...prev,
                                                            [x.name]: x.default || undefined
                                                        };
                                                    },
                                                    {}
                                                );
                                                this.props.onChange([...(this.props.value || ""), newItem]);
                                            }}
                                            styles={{
                                                root: {
                                                    fontSize: 11,
                                                    background: "#33926e !important",
                                                    borderColor: "#33926e !important"
                                                }
                                            }}
                                        />
                                    }
                                    { sahaNewsLength < 6 &&
                                        <PrimaryButton
                                            iconProps={{ iconName: "CalculatorAddition" }}
                                            className="custom-button"
                                            text="Yeni Ekle"
                                            onClick={() => {
                                                const newItem = keys.reduce(
                                                    (prev, x) => {
                                                        return {
                                                            ...prev,
                                                            [x.name]: x.default || undefined
                                                        };
                                                    },
                                                    {}
                                                );
                                                this.props.onChange([...(this.props.value || ""), newItem]);
                                            }}
                                            styles={{
                                                root: {
                                                    fontSize: 11,
                                                    background: "#33926e !important",
                                                    borderColor: "#33926e !important"
                                                }
                                            }}
                                        />
                                    }
                                    {this.props.property.mapping.type === PropertyMappingType.ArrayData && (
                                        <Toggle
                                            className="custom-toggle m-left-auto"
                                            onChange={(_, checked) => this.setState({ sortableMode: checked as boolean })}
                                            checked={this.state.sortableMode}
                                            label="Sıralama Modu"
                                            inlineLabel
                                            styles={{
                                                root: {
                                                    marginBottom: "-5px",
                                                    marginTop: "-1px !important"
                                                },
                                                container: {
                                                    marginTop: "-1px !important"
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
                }
                {this.renderFields()}
            </div>
        );
    }

    render() {
        return (
            this.renderArrayFields()
        );
    }
}


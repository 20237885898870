import {
    DesignerState,
    DesignerActionTypes,
    SET_ACTIVE_ELEMENT,
    SET_DESIGNER_DIMENSION,
    SET_PREVIEW_DIMENSON, SET_PANEL_ACCORDION, SET_DESIGNER_MODE
} from "./types";
import _ from "lodash";

const initialState: DesignerState = {
    designerDimension: "desktop",
    previewDimension: "desktop",
    activeElement: "",
    designerMode: false,
    activeAccordions: ["itemProps", "Genel"]
};

export function designerReducer(
    state = initialState,
    action: DesignerActionTypes
): DesignerState {
    switch (action.type) {
        case SET_ACTIVE_ELEMENT:
            return {
                ...state,
                activeElement: action.id || ""
            };
        case SET_DESIGNER_DIMENSION:
            return {
                ...state,
                designerDimension: action.dimension
            };
        case SET_DESIGNER_MODE:
            return {
                ...state,
                designerMode: action.designerMode
            };
        case SET_PREVIEW_DIMENSON:
            return {
                ...state,
                previewDimension: action.dimension
            };
        case SET_PANEL_ACCORDION:
            let accordions = _.cloneDeep(state.activeAccordions);
            let findAccordionIndex = accordions.findIndex(
                (item: string) => item === action.value
            );
            if (findAccordionIndex !== -1) {
                accordions.splice(findAccordionIndex, 1);
            } else {
                if (action.single) {
                    accordions = [action.value]
                }
                else {
                    accordions.push(action.value, accordions);
                }
            }
            return {
                ...state,
                activeAccordions: accordions
            };
        default:
            return state;
    }
}

import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";

export class HomeMainSponsorDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "home-main-sponsor";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "title",
            text: "Ana Başlık",
            mapping: {
                type: PropertyMappingType.String
            },
        }, {
            name: "slides",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        text: "Başlık",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                    },
                    {
                        name: "image",
                        text: "Resim",
                        mapping: {
                            type: PropertyMappingType.Image,
                        },
                    },

                ]
            },
            default: []
        }
    ];
}

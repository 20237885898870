import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { HeroProps } from "./types";
import {
  EntryOptions,
  PropertyDefinition, PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from 'app/shared';

export class HeroDefinitions extends CmsReactComponent<HeroProps> {
  static defaultProps = {};

  static schemaName = "hero";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "title",
      text: "Başlık",
      mapping: {
        type: PropertyMappingType.String
      }
    },
    {
      name: "image",
      text: "Arkaplan Resim",
      mapping: {
        type: PropertyMappingType.Image
      }
    },
    {
      name: "video",
      text: "Arkaplan Video",
      mapping: {
        type: PropertyMappingType.Video
      }
    },
    {
      name: "mobilevideo",
      text: "Arkaplan Mobil Video",
      mapping: {
        type: PropertyMappingType.Video
      }
    },
  ];

  static options: EntryOptions = {
    meta: {
      use: true,
      allowedTypes: [
        MetaTypeEnum.Text,
        MetaTypeEnum.SingleImage,
        MetaTypeEnum.SingleVideo
      ]
    }
  };
}
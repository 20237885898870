import * as React from "react";
import { PureStringField } from "./Pure/PureStringField";
import { PrimaryButton } from "office-ui-fabric-react";
import { BaseFieldProps } from './types';
import FileManager from '../FileManager/FileManager';
import { FileTypes } from "app/services/file-manager";
import { getFilePath } from "app/utils/service";

interface VideoFieldState {
    fileManagerActive: boolean;
}

// Kendisine gelen Property ismine ve değerine göre Video render eder.
export default class VideoField extends React.Component<BaseFieldProps, VideoFieldState> {

    constructor(props: BaseFieldProps) {
        super(props);
        this.state = {
            fileManagerActive: false
        };
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        return (
            <React.Fragment>
                <FileManager
                    activeFile={this.props.value}
                    onSelectedFile={(file) => {
                        this.props.onChange(getFilePath() + file.fileName)
                    }}
                    fileType={FileTypes.Video}
                    show={this.state.fileManagerActive}
                    onDismiss={() => {
                        this.setState({
                            fileManagerActive: false
                        })
                    }}
                />
                <PureStringField
                    label={this.props.property.name}
                    text={this.props.property.text as string}
                    description={this.props.property.description as string}
                    value={this.props.value}
                    onChange={value => this.props.onChange(value)}
                    className="video-field"
                    customRender={() => {
                        return (
                            <PrimaryButton
                                className="custom-button m-left-auto"
                                text="Video Seç"
                                style={{ marginTop: 2.5 }}
                                onClick={() => {
                                    this.setState({
                                        fileManagerActive: true
                                    }, () => {
                                        document.querySelector(".external-panel")?.classList.add("fade");
                                    });
                                }}
                            />
                        )
                    }}
                />
            </React.Fragment>
        );
    }
}

export const GSM_CODES: number[] = [
    501,
    505,
    506,
    507,
    530,
    531,
    532,
    533,
    534,
    535,
    536,
    537,
    538,
    539,
    540,
    541,
    542,
    543,
    544,
    545,
    546,
    547,
    548,
    549,
    551,
    552,
    553,
    554,
    555,
    559,
    561,
];

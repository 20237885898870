import { Component } from 'react';
import { TextField } from 'office-ui-fabric-react';
import { MetaTypeEnum } from 'app/shared';
import { BaseMetaFieldsProps } from './types';

export default class MetaTextField extends Component<BaseMetaFieldsProps<string | number>> {
    render() {
        const isNumber = this.props.meta?.type === MetaTypeEnum.Number ? true : false;
        return (
            <TextField
                placeholder={this.props.meta.title}
                value={this.props.meta?.data as string || ""}
                type={isNumber ? "number" : "text"}
                className="custom-textfield border"
                resizable={false}
                multiline={this.props.type === MetaTypeEnum.TextArea ? true : false}
                onChange={(event: any) => this.props.onChange(isNumber ? parseInt(event.target.value) : event.target.value)}
            />
        )
    }
}

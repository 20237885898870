import React from 'react'
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';

import { History } from "history";

import { RootState } from 'app/redux/reducer';
import { tokenControl } from 'app/redux/system/actions';
import { checkUser } from 'app/utils/service';
import { checkToken } from 'app/services/identity';
import { ROUTES } from 'app/routes';
import VerificationLoader from 'components/customs/Loader/VerificationLoader';
import Login from 'pages/Identity/Login';
import { DefaultButton, Icon } from 'office-ui-fabric-react';

interface OwnProps {
	history: History<any>,
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
	return {
		...ownProps,
		language: state.system.language,
		userInfo: state.system.userInfo,
		verificationLoading: state.system.verificationLoading,
		tokenStatus: state.system.tokenStatus
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		...bindActionCreators({
			tokenControl,
			checkToken
		}, dispatch)
	};
}

type AuthenticationProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

class Authentication extends React.Component<AuthenticationProps> {

	UNSAFE_componentWillMount() {
		if (checkUser()) {
			this.props.tokenControl(true);
			this.props.checkToken();
		}
		else {
			this.props.tokenControl(false);
		}
	}

	renderAuthorizationError() {
		return (
			<div className="auth-error">
				<div className="auth-error-wrapper">
					<div className="auth-error-title row">
						<Icon iconName="Warning" />
						Erişim Hatası
					</div>
					<div className="auth-error-text">
						<p>
							Bu sayfaya erişim izniniz bulunmamaktadır. Geri dönerek işlemlerinize devam edebilirsiniz.
						</p>
						<DefaultButton
							className="custom-button"
							text="Geri Dön"
							iconProps={{ iconName: "Back" }}
							onClick={() => this.props.history.go(-1)}
						/>
					</div>
				</div>
			</div>
		)
	}

	renderChildren() {
		const route = ROUTES.find(x => x.path === this.props.location.pathname);

		if (this.props.tokenStatus) {
			if (route?.public) {
				this.props.history.push("/")
				return null;
			}
			// if (!route?.role || getMenuPermissions().includes(route.role)) {

			// }
			// return this.renderAuthorizationError()
			return this.props.children;
		}
		else {
			if (route?.public) {
				return <Route {...route} />
			}
			return <Login history={this.props.history} />
		}
	}

	render() {
		return (
			<>
				<VerificationLoader show={this.props.verificationLoading} />
				{this.renderChildren()}
			</>
		)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authentication));
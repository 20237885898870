import { api } from 'app/utils/api';
import { convertToQueryString } from 'app/shared/functions';
import { HttpClient } from 'app/library/client';
import { ResponseBase, ResponseFileManagerListBase, ResponseListBase } from "app/shared";
import { BucketEntity, FileEntity, IFileManagerListPagerObject } from './types';
import { getCdnEndpoint } from 'app/utils/service';

export class FileManagerService {

    /** It gets all files. */
    async getFiles(options: IFileManagerListPagerObject): Promise<ResponseBase<ResponseFileManagerListBase<FileEntity[]>>> {
        return HttpClient.getClient().get(getCdnEndpoint(api.prefixes.storage.list) + `?${convertToQueryString(options)}`);
    }

    /** It deletes spesific file by id */
    async deleteFile(fileId: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().delete(getCdnEndpoint(`/${fileId}`));
    }

    /** It gets all buckets. */
    async getBucketList(): Promise<ResponseBase<ResponseListBase<BucketEntity[]>>> {
        return HttpClient.getClient().get(getCdnEndpoint(api.prefixes.storage.buckets));
    }

    /** It deletes spesific bucket by id */
    async deleteBucket(bucketId: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().delete(getCdnEndpoint(`/${bucketId}`));
    }
}

import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";

export class BreadcrumbsDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "breadcrumbs";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "items",
            description: "Breadcrumbs Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [{
                    name: 'anchor',
                    text: 'Link',
                    mapping: {
                        type: PropertyMappingType.LinkWizard
                    }
                },
                ],
            },
            group: {
                key: "data",
                title: "Veriler",
                withObject: false,
            }
        },
    ];
}

import { EntryOptions, PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";
import { ImageChildrenDefinitions } from "components/cms/definitions";

export class MainSlideshowDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "main-slideshow";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "autoplay",
            mapping: {
                type: PropertyMappingType.Boolean
            },
            group: {
                key: "settings",
                title: "Slider Ayarları",
                withObject: true
            },
            default: 4
        },
        {
            name: "autoplaySpeed",
            mapping: {
                type: PropertyMappingType.Number
            },
            group: {
                key: "settings",
                title: "Slider Ayarları",
                withObject: true
            },
            default: 4
        },
        {
            name: "slides",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        text: "Başlık",
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: "subtitle",
                        text: "Alt Başlık",
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: "category",
                        text: "Kategori",
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: "date",
                        text: "Tarih",
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: "image",
                        text: "Resim",
                        mapping: {
                            type: PropertyMappingType.ParentObject,
                            children: ImageChildrenDefinitions
                        }
                    },
                    {
                        name: "video",
                        text: "Video",
                        mapping: {
                            type: PropertyMappingType.Video
                        }
                    },
                    {
                        name: "mobilevideo",
                        text: "Mobile Video",
                        mapping: {
                            type: PropertyMappingType.Video
                        }
                    },
                    {
                        name: "anchor",
                        mapping: {
                            type: PropertyMappingType.LinkWizard
                        }
                    }
                ]
            },
            default: []
        },

    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: "title",
                text: "Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea]
            },
            {
                name: "subtitle",
                text: "Alt Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea]
            },
            {
                name: "category",
                text: "Kategori",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea]
            },
            {
                name: "date",
                text: "Tarih",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea]
            },
            {
                name: "image",
                text: "Resim",
                types: [MetaTypeEnum.SingleImage]
            },
            {
                name: "video",
                text: "Video",
                types: [MetaTypeEnum.SingleVideo]
            },
            {
                name: "mobilevideo",
                text: "Mobile Video",
                types: [MetaTypeEnum.SingleVideo]
            },
            {
                name: "anchor",
                text: "URL",
                types: [MetaTypeEnum.Link]
            },
        ],
    };
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { PageHeaderProps } from "./types";
import { MetaTypeEnum } from "app/shared";
import { ImageChildrenDefinitions, PhotoGalleryDefinition } from 'components/cms/definitions';

export class PageHeaderDefinitions extends CmsReactComponent<PageHeaderProps> {
    static defaultProps = {};

    static schemaName = "page-header";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "title",
            mapping: {
                type: PropertyMappingType.String,
            },
            default: "",
            text: "Başlık",
        },
        {
            name: "image",
            mapping: {
                type: PropertyMappingType.ParentObject,
                children: ImageChildrenDefinitions,
            },
            description: "Görsel",
        },
        {
            name: "breadcrumbs",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "text",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                        default: "",
                        text: "Metin",
                    },
                    {
                        name: "href",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                        default: "",
                        text: "Link",
                    }
                ]
            },
            default: []
        },
        {
            name: "slides",
            mapping: {
                type: PropertyMappingType.ArrayData,
                children: PhotoGalleryDefinition
            },
            default: []
        },
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.Text,
                MetaTypeEnum.TextArea,
                MetaTypeEnum.SingleImage,
                MetaTypeEnum.PhotoGallery
            ],
        },
        mappingFields: [
            {
                name: "thumbnail",
                text: "Görsel Öne Çıkarılmış",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "src",
                text: "Görsel Yolu",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "alt",
                text: "Görsel Alt Etiketi",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "caption",
                text: "Görsel Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "subcaption",
                text: "Görsel Alt Başlık",
                types: [MetaTypeEnum.TextArea, MetaTypeEnum.Text, MetaTypeEnum.Editor]
            }

        ]
    };
}

import React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RootState } from 'app/redux/reducer';
import { toggleConfirmDialog } from 'app/redux/system/actions';
import { TextField } from 'office-ui-fabric-react';

function mapStateToProps(state: RootState) {
    return {
        ...state.system.confirm
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        toggleConfirmDialog
    }, dispatch);
}

type ConfirmBoxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface ConfirmBoxState {
    cancelReason: string;
}

class ConfirmBox extends React.Component<ConfirmBoxProps, ConfirmBoxState> {

    state = {
        cancelReason: ""
    }

    _labelId: string = getId('dialogLabel');
    _subTextId: string = getId('subTextLabel');

    render() {
        return (
            <div>
                <Dialog
                    hidden={!this.props.show}
                    onDismiss={() => this.props.toggleConfirmDialog(false, { callback: () => { } })}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: this.props.properties?.title || 'Uyarı',
                        closeButtonAriaLabel: 'Kapat',
                        subText: this.props.properties?.contentRenderer ? "" : (this.props.properties?.text || "Geçerli kaydı silmek istiyor musunuz? Eğer kaydı silmek istemiyorsanız 'İptal' butonuna basın. Eğer silme işlemini tamamlamak istiyorsanız, 'Kaydı Sil' butonuna basınız."),
                    }}
                    modalProps={{
                        className: "confirm",
                        titleAriaId: this._labelId,
                        subtitleAriaId: this._subTextId,
                        isBlocking: false,
                        styles: { main: { maxWidth: "400px !important" } }
                    }}
                >
                    {this.props.properties?.contentRenderer && this.props.properties.contentRenderer()}
                    {this.props.properties?.reason && (
                        <TextField
                            placeholder="İptal Sebebi"
                            value={this.state.cancelReason}
                            label="İptal Sebebi"
                            className="custom-textfield border"
                            onChange={(event: any) => this.setState({ cancelReason: event.target.value })}
                        />
                    )}
                    {!this.props.properties?.hideButtons && (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => {
                                    this.setState({
                                        cancelReason: ""
                                    }, () => {
                                        this.props.properties?.callback && this.props.properties.callback({ reason: this.state.cancelReason }); this.props.toggleConfirmDialog(false, { callback: () => { } })
                                    })
                                }}
                                text={this.props.properties?.button?.approveText || "Kaydı Sil"}
                            />
                            <DefaultButton onClick={() => this.props.toggleConfirmDialog(false, {})} text={this.props.properties?.button?.cancelText || "İptal"} />
                        </DialogFooter>
                    )}

                </Dialog>
            </div >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBox);

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { IconProps } from "./types";
import {
    PropertyDefinition,
    PropertyMappingType,
    PropertyDefinitionOneOf, EntryOptions
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";
const sizes: PropertyDefinitionOneOf[] = [
    {
        key: "small",
        text: "small",
    },
    {
        key: "medium",
        text: "medium",
    },
    {
        key: "large",
        text: "large",
    },
    {
        key: "xlarge",
        text: "xlarge",
    },
];
export class IconDefinitions extends CmsReactComponent<IconProps> {
    static defaultProps = {
        selectable: true,
    };

    static schemaName = "icon";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "name",
            mapping: {
                type: PropertyMappingType.Icon,
            },
            default: "icon-instagram",
            text: "İkon Adı",
        },
        {
            name: "label",
            mapping: {
                type: PropertyMappingType.String,
            },
            default: "",
            text: "Etiket",
        },
        {
            name: "size",
            mapping: {
                type: PropertyMappingType.Object,
                oneOf: sizes,
            },
            default: "medium",
            text: "İkon Boyutu",
        },
        {
            name: "fill",
            mapping: {
                type: PropertyMappingType.Color,
            },
            text: "İkon Renk",
        },
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.Text,
                MetaTypeEnum.TextArea,
            ]
        }
    }
}

import { ICategory } from 'app/services/category';
import { IList } from 'app/shared';
import { CategoryActionTypes, CategoryStorage, SET_CATEGORIES } from './types';

const initialState: CategoryStorage = {
    categories: {} as IList<ICategory[]>,
};

export function categoryReducer(state = initialState, action: CategoryActionTypes): CategoryStorage {
    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            };
        default:
            return state;
    }
}
import { ICategory } from 'app/services/category';
import { IList } from 'app/shared';
import { CategoryActionTypes, SET_CATEGORIES } from './types';

/**
 * Global olarak bulunan Kategoriler Datasını Set Eder.
 */
export function setCategories(categories: IList<ICategory[]>): CategoryActionTypes {
    return {
        type: SET_CATEGORIES,
        categories
    }
}

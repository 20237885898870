import { DefaultButton } from 'office-ui-fabric-react';
import { Component } from 'react';
import _ from "lodash";
import { ReactSortable } from "react-sortablejs";
import { BaseMetaFieldsProps } from './types';
import { IMetaFile } from 'app/services/content-type';
import { validateUrl } from 'app/shared/functions';
import FileManager from '../../FileManager/FileManager';
import { getFilePath } from 'app/utils/service';

import ImageVideo from "assets/images/type-video.jpg";
import FileSettingsModal from 'components/customs/Modals/FileSettingsModal';

interface IMetaMultiState {
    showModal: boolean;
    showImageSettingsModal: number;
}

export default class MetaMultiFile extends Component<BaseMetaFieldsProps<IMetaFile[]>, IMetaMultiState> {

    state = {
        showModal: false,
        showImageSettingsModal: -1
    }

    renderFileByType(item: IMetaFile) {
        const file = item.src || "";
        const splitted = file?.split(".");
        const fileSource = validateUrl(file) ? file : (getFilePath() + file + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=200&height=200" : ""));

        if (item.type === 1)
            return <img src={fileSource} alt={item.title} />
        else
            return <img src={ImageVideo} alt={item.title} />
    }

    renderFileSettingsModal(item: IMetaFile, index: number) {
        return (
            <FileSettingsModal
                show={this.state.showImageSettingsModal === index ? true : false}
                data={item}
                onSave={(data) => {
                    const list = _.cloneDeep(this.props.meta.data || []);
                    if (list[index]) {
                        list[index] = data as IMetaFile;
                        this.props.onChange(list);
                    }
                }}
                onDismiss={() => this.setState({ showImageSettingsModal: -1 })}
            />
        )
    }

    render() {
        return (
            <div className="meta-file">
                <FileManager
                    show={this.state.showModal}
                    onDismiss={() => this.setState({ showModal: false })}
                    onSelectedFile={(file) => {
                        const files = _.cloneDeep(this.props.meta.data || []);

                        files.push({
                            type: file.fileName?.includes("mp4") ? 2 : 1,
                            src: file.fileName,
                            title: file.fileName
                        })

                        this.props.onChange(files);
                    }}
                />
                <DefaultButton
                    iconProps={{ iconName: "Touch" }}
                    text="Dosya Seç"
                    styles={{
                        root: {
                            fontSize: "10.5px !important",
                            background: "#fff"
                        }
                    }}
                    onClick={() => {
                        this.setState({
                            showModal: true
                        })
                    }}
                />
                <div className="file-list multi row">
                    {this.props.meta.data && this.props.meta.data.length > 0 ? (
                        <ReactSortable
                            list={(this.props.meta.data || []).map((item, index) => ({ ...item, id: index })) || []}
                            setList={(newState) => this.props.onChange(newState)}
                            animation={200}
                            className="row"
                            style={{ width: "100%" }}
                        >
                            {this.props.meta.data.map((item, index) => (
                                <div key={index} className="file-item col-md-2">
                                    {item.src && (
                                        <div className="file-item-wrapper">
                                            <div onClick={() => this.setState({ showImageSettingsModal: index })} className="edit no-margin">
                                                <i className="icon-edit"></i>
                                            </div>
                                            {this.renderFileSettingsModal(item as IMetaFile, index)}
                                            {this.renderFileByType(item)}
                                            <div className="title">
                                                {item?.title || item?.src || "-"}
                                            </div>
                                            <div onClick={() => {
                                                const list = _.cloneDeep(this.props.meta.data || []);
                                                list.splice(index, 1);
                                                this.props.onChange(list);
                                            }} className="remove">
                                                <i className="icon-delete"></i>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </ReactSortable>
                    ) : (
                        <div className="not-found row">
                            <i className="icon-info"></i>
                                Herhangi bir dosya eklenmemiştir.
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

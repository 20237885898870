import { ColumnDefinitions } from "./ColumnDefinitions";
import ColumnDesigner from "./ColumnDesigner";

export class Column extends ColumnDefinitions {
    render() {

        const isMobile = this.checkDimension("mobile");
        const isTablet = this.checkDimension("tablet");

        const desktopClass = "fa-Col-" + (this.props.sizes?.md || "none");
        const tabletClass = "fa-Col-tb-" + (this.props.sizes?.sm || "none");
        const mobileClass = "fa-Col-mb-" + (this.props.sizes?.xs || "none");

        if (
            (this.props.hideRuntime ||
                (isMobile && this.props.hideOnMobile) ||
                (isTablet && this.props.hideOnTablet)) &&
            !this.props.designing
        )
            return null;

        const style = this.props.designing
            ? {
                minHeight: "100px",
                padding: "0px",
            }
            : {
                minHeight: "auto",
                padding: "10px",
            };

        return (
            <div
                data-schema-type="column"
                data-schema-id={this.props.id}
                className={`${desktopClass} ${tabletClass} ${mobileClass} fa-draggable fa-Col ${this.props.designing ? "designing" : ""
                    } ${this.props.defaultElement ? "is-default" : ""}`}
                style={{ ...style, position: "relative" }}
            >
                {this.props.designing ? (
                    <ColumnDesigner {...this.props} />
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}

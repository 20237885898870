import { ModelBase, RequestInventoryListBase } from "app/shared";

export interface IUser extends ModelBase {
  username?: string;
  name: string;
  password?: any;
  surname: string;
  email: string;
  country_id?: string;
  birth_date?: any;
  gender_id?: any;
  identity_number?: any;
  passport_number?: any;
  nationality?: any;
  phone_number?: string;
  address?: any;
  city_id?: any;
  city_name?: any;
  privacy_aggrement?: any;
  user_type: UserType;
  profile_picture?: any;
  job_description?: any;
  login_type?: number;
  passport_inssung_country_id?: any;
  native_language_id?: any;
  establishment_id?: string;
  job_title?: any;
  zip_code?: any;
  all_doctor_see_information_permission?: any;
  examined_doctor_see_information_permission?: any;
  ministryof_health_doctor_see_information_permission?: any;
  role?: UserRole[];
  id?: string;
  created_at?: Date;
  updated_at?: any;
  created_by?: string;
  updated_by?: any;
  index?: number;
  created_date?: string;
  updated_date?: any;
}

export interface IUserFilter extends RequestInventoryListBase {
  surname?: string;
  username?: string;
  userType?: UserType;
  email?: string;
}

export enum UserType {
  SuperAdminUser = 1,
  Admin = 2,
  EstablishmentAuthorizedUser = 3,
  FirstApprovalAuthority = 10,
  SecondApprovalAuthority = 11,
  Acente = 12
}

export enum UserRole {
  SuperAdminUser = "adminapi_superadmin",
  Admin = "adminapi_admin",
  EstablishmentAuthorizedUser = "adminapi_establishmentuser",
  FirstApprovalAuthority = "adminapi_firstapprovalauthority",
  SecondApprovalAuthority = "adminapi_secondapprovalauthority",
}

export enum LoginType {
  Google = 1,
  Linkedin = 2,
  Facebook = 3,
  Manuel = 4
}
import * as React from "react";
import { TextField } from 'office-ui-fabric-react';
import { toggleModal } from 'app/redux/modal/actions';
import { ProductPickerModalData } from 'app/redux/modal/types';
import { AnchorProps, ProductProps } from "components/cms/types";

export type OnChangeField = (value: any) => void;

export interface PureProductFieldProps {
    value: any;
    onChange: OnChangeField;
    onToggleProductPicker: typeof toggleModal;
}

export class PureProductField extends React.Component<PureProductFieldProps> {

    onChangeProperty(data: ProductPickerModalData) {
        const model: ProductProps = {};
        const spot = data.post.specFields?.find(x => x.name === "spot");
        const contributors = data.post.specFields?.find(x => x.name === "eserKatilimcilari");
        const label = data.post.specFields?.find(x => x.name === "satisTipi");
        const color = data.post.metaFields?.find(x => x.name === "color");

        if (data) {
            model.id = data.post.id;
            model.title = data.post.title;
            model.anchor = {
                href: data.post.url
            } as AnchorProps;
            model.image = data.post.thumbnail;
            if (label) {
                model.label = label?.data;
            }
            if (contributors && spot) {
                model.summary = spot?.data;
                model.contributor = contributors?.data;
            }
            if (color) {
                model.color = color?.data;
            }
        }
        this.props.onChange(model)
    }

    render() {
        return (
            <div className="property-item no-border row">
                <div title="Stok Seçiniz" className="property-text">
                    <div className="title">Stok Seçiniz</div>
                    <div className="sub-title">Geçerli içerik tipine göre bir ürün stoğu seçiniz.</div>
                </div>
                <TextField
                    className="custom-textfield col-md-3"
                    readOnly
                    value={this.props.value?.title}
                />
                <div className="col no-padding">
                    {/* <PrimaryButton
                        iconProps={{ iconName: "Touch" }}
                        className="custom-button"
                        text="Stok Seç"
                        onClick={() => {
                            this.props.onToggleProductPicker("productPicker", {}, (data) => this.onChangeProperty(data));
                        }}
                    /> */}
                </div>
            </div>
        );
    }
}

import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { History } from 'history';
import VerificationCode from 'react-verification-code-input';
import { Helmet } from 'react-helmet';

import { RootState } from 'app/redux/reducer';
import { setPageData, setPageErrors } from 'app/redux/page/actions';
import { setUserInfo, showMessage, tokenControl } from 'app/redux/system/actions';

import CountDown from './CountDown';
import { MessageType } from 'app/redux/system/types';
import { connectToken, IdentityLoginRequest, IdentityLoginResponse, IdentityService } from 'app/services/identity';
import { castThunkAction } from 'app/utils/casting';
import AuthLayout from 'containers/AuthLayout';
import AbsoluteLoader from 'components/customs/Loader/AbsoluteLoader';
import { SIGN_REQUEST_FIELDS } from 'app/services/identity/validation';

interface OwnProps {
  history: History<any>;
}

function mapStateToProps(state: RootState) {
  return {
    pageData: state.page.pageData as IdentityLoginRequest,
    pageErrors: state.page.pageErrors,
    userInfo: state.system.userInfo,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    setPageData,
    setPageErrors,
    setUserInfo,
    tokenControl,
    showMessage,
    connectToken
  }, dispatch);
}

type VerifyProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

interface VerifyState {
  verifyStatus: string;
  verifyLoader: boolean;
  verifyTime: number;
  loginError: string;
  loginLoader: boolean;
}

class Verify extends React.Component<VerifyProps, VerifyState> {

  intervalTime: NodeJS.Timeout;

  state = {
    verifyStatus: "",
    verifyLoader: false,
    verifyTime: 30,
    loginError: "",
    loginLoader: false
  }

  UNSAFE_componentWillMount() {
    if (!this.props.pageData?.email) {
      this.props.history.push("/login");
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onEnterSubmit, true);
    this.onVerifyInterval();
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onEnterSubmit, true);
    clearInterval(this.intervalTime);
  }

  onEnterSubmit = (e: any) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  onVerifyInterval() {
    this.setState({
      verifyLoader: false,
      verifyStatus: 'kod gönderildi, tekrar kontrol edebilirsiniz.'
    })

    this.intervalTime = setInterval(() => {
      let time = this.state.verifyTime - 1;
      if (time) {
        this.setState({
          verifyTime: time
        })
      }
      else {
        clearInterval(this.intervalTime)
        this.setState({
          verifyStatus: '',
          verifyTime: 30
        })
      }

    }, 1000)
  }

  onTimeout() {
    this.setState({
      loginError: "Doğrulama kodu için süreniz doldu. Giriş ekranına yönleniyorsunuz...",
      loginLoader: true
    }, () => {
      setTimeout(() => {
        this.props.setPageData("email", "");
        this.props.history.push("/login");
      }, 1000)
    })
  }

  async onVerify(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault()

    this.setState({
      verifyLoader: true
    })

    const service = new IdentityService();
    const response = await service.sendVerification({ email: this.props.pageData?.email || "" });

    if (response?.data.success) {
      this.onVerifyInterval()
    }
    else {
      this.setState({
        loginError: response?.data?.error_message || "Lütfen doğrulama kodunuzu kontrol ediniz.",
        loginLoader: false
      }, () => {
        this.props.showMessage(this.state.loginError, "Doğrulama Hatası", MessageType.ERROR)
      })
    }
  }

  async onLogin() {
    castThunkAction<IdentityLoginResponse>(this.props.connectToken({
      username: this.props.pageData.email || "",
      password: this.props.pageData.password,
      client_id: "android",
      client_secret: "android",
      grant_type: "password"
    })).then(response => {
      console.log("test", response);
      if (response) {
        this.setState({
          loginError: "",
          loginLoader: false
        }, () => {
          this.props.history.push("/");
        })
      }
      else {
        this.setState({
          loginError: "",
          loginLoader: false
        })
      }
    })

  }

  /** Formu kontrol ederek giriş yapılacak servise yönlendirilir. */
  async onSubmit() {
    const {
      pageData,
      setPageErrors
    } = this.props;
    const validationSchema = SIGN_REQUEST_FIELDS
    let formErrors = {};

    if (this.state.loginLoader)
      return;

    validationSchema
      .validate(pageData, {
        abortEarly: false
      })
      .then(() => {
        setPageErrors({});
        this.setState({
          loginLoader: true
        }, () => this.onLogin())
      })
      .catch(err => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setPageErrors(formErrors);
      });
  }

  renderInformation() {
    const { verifyTime, verifyStatus } = this.state;

    return (
      <div className="information" style={{ paddingBottom: 40 }}>
        <div className="text">
          <span className="inner-text">
            {verifyStatus ? verifyStatus : 'doğrulama kodu size ulaşmadıysa'}
          </span>
        </div>
        <a
          className={verifyStatus ? "disabled" : ""}
          href="/verifiy"
          onClick={(e) => !verifyStatus ? this.onVerify(e) : {}}>
          TEKRAR DOĞRULAMA KODU AL {verifyStatus ? `(${verifyTime})` : ""}
        </a>
      </div>
    )
  }

  render() {
    return (
      <AuthLayout title="DOĞRULAMA KODU">
        <Helmet>
          <title>Doğrulama - Sağlık Turizm - Bilgi Giriş Sistemi</title>
        </Helmet>
        <p className="message">Lütfen e-mail adresinize gönderilen 6 haneli doğrulama kodunu girin.</p>
        <VerificationCode
          fieldWidth={65}
          fieldHeight={65}
          className="verification-code"
          onChange={(value: string) => this.props.setPageData("password", value)}
        />
        <div className="auth-page-footer">
          <AbsoluteLoader show={this.state.loginLoader} />
          <button
            className={this.state.loginLoader || this.props.pageData?.password?.length !== 6 ? "passive" : ""}
            onClick={() => !this.state.loginLoader ? this.onSubmit() : {}}
          >
            DOĞRULA VE GİRİŞ YAP
          </button>
          <div style={{ minHeight: 18, margin: "15px 0px" }}>
            {this.state.verifyLoader ? null : <CountDown onTimeout={() => this.onTimeout()} />}
          </div>
          {this.renderInformation()}
        </div>
      </AuthLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Verify);

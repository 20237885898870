import { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Dispatch, bindActionCreators } from "redux";
import { History } from "history";

import {
    DefaultButton,
    Icon,
    Modal,
    Pivot,
    PivotItem,
    PrimaryButton,
} from "office-ui-fabric-react";
import {
    ContainerEntry,
    PropertyDefinition,
    PropertyMappingType,
    SchemaNode,
    SchemaSourceOptions,
    SchemaSourceTypes,
    SchemaTree,
} from "app/library/layout-builder";
import {
    AlignField,
    ArrayField,
    ColorField,
    DropdownField,
    IconField,
    ImageField,
    StringField,
    ToggleField,
    VideoField,
} from "../Fields";
import CustomField from "../Fields/CustomField";
import _ from "lodash";
import ParentObjectField from '../Fields/ParentObjectField';
import LinkField from '../Fields/LinkField';
import HTMLField from '../Fields/HTMLField';
import { DESIGNER_COMPONENTS, IDesignerComponents, IDesignerItems } from 'app/shared/constants';
import MenuField from '../Fields/MenuField';
import WebsiteField from '../Fields/WebsiteField';
import SrcSetField from '../Fields/SrcSetField';
import ContentTypeField from '../Fields/ContentTypeField';
import BrandField from '../Fields/BrandField';
import DateTimeField from "../Fields/DateTimeField";
import DateTimeField2 from "../Fields/DateTimeField2";
import CssField from '../Fields/CssField';
import MappingModal from "./MappingModal";
import ContentTypesField from "../Fields/ContentTypesField";
import { IPost } from "app/services/post";
import { ILayout } from "app/services/layout";
import { IDynamicFilter } from "app/shared";
import { RootState } from "app/redux/reducer";
import { setModalData, toggleModal } from "app/redux/modal/actions";
import { setPanelAccordion } from "app/redux/designer/actions";
import { initialPageData } from "app/redux/page/actions";
import { PropertiesModalData } from "app/redux/modal/types";
import FileField from "../Fields/FileField";

interface OwnProps {
    history: History<any>;
}

function mapStateToProps(state: RootState) {
    return {
        ...state.modal.properties,
        language: state.system.language,
        activeAccordions: state.designer.activeAccordions,
        pageData: state.page.pageData as IPost | ILayout,
        contentType: state.contentType.contentType,
        contentTypes: state.contentType.contentTypes?.list || [],
        isLayout: state.system.isLayout
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators(
            {
                setModalData,
                toggleModal,
                setPanelAccordion,
                initialPageData
            },
            dispatch
        ),
    };
}

type PropertiesModalProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    OwnProps;

interface PropertiesModalState {
    showMappingModal: boolean;
}

class PropertiesModal extends Component<PropertiesModalProps, PropertiesModalState> {

    state = {
        showMappingModal: false
    }

    onClose() {
        this.props.toggleModal("properties", {});
    }

    onSave() {
        this.props.initialPageData({
            ...this.props.pageData as IPost,
            schema: this.props.data.schema,
            dynamics: this.props.data.dynamics
        });
        this.props.toggleModal("properties", {});
    }

    groupByGroupKey(array: PropertyDefinition[]) {
        return _.groupBy(array, property => {
            return property?.group?.title;
        });
    }

    getActiveAccordion(activeAccordions: any[], value: string) {
        return activeAccordions.find((item) => item === value);
    }

    onChangeSource = (prop: string, name: "type" | "ref" | "relation", value: any) => {
        const schemaTree = SchemaTree.parse(this.props.data.schema || []);
        let node = schemaTree.search(
            this.props.data.node.getDefinition("id")
        ) as SchemaNode;

        if (name === "type") {
            node.setSourceObject({ ...(node.getSource() || {}), [prop]: { type: value } });
        }
        else {
            node.setSource(prop, name, value);
        }

        this.props.setModalData<PropertiesModalData>("properties", {
            ...this.props.data,
            schema: schemaTree.toArray(),
            node
        });
    }

    onChangeDynamics = (data: IDynamicFilter, prop: string) => {
        const schemaTree = SchemaTree.parse(this.props.data.schema || []);
        let clonedDynamics = _.cloneDeep(this.props.data.dynamics || []);
        let node = schemaTree.search(
            this.props.data.node.getDefinition("id")
        ) as SchemaNode;

        if (!data.id) {
            data.id = uuidv4()
        }

        if (!_.isArray(clonedDynamics)) {
            clonedDynamics = [];
        }

        const dynamicIndex = clonedDynamics.findIndex(x => x.id === data.id);

        if (dynamicIndex !== -1) {
            clonedDynamics[dynamicIndex] = data;
        }
        else {
            clonedDynamics.push(data);
        }

        node.setSourceObject({
            ...node.source,
            [prop]: {
                type: SchemaSourceTypes.Dynamics,
                ref: data.id
            }
        });

        console.log("node", node.source);

        this.props.setModalData<PropertiesModalData>("properties", {
            ...this.props.data,
            schema: schemaTree.toArray(),
            node,
            dynamics: clonedDynamics
        });
    }

    onChangeSourceObject = (prop: string, object: SchemaSourceOptions) => {
        const schemaTree = SchemaTree.parse(this.props.data.schema || []);
        let node = schemaTree.search(
            this.props.data.node.getDefinition("id")
        ) as SchemaNode;
        if (object) {
            node.setSourceObject({ ...(node.getSource() || {}), [prop]: { ...(node.getSource() || {})[prop], ...object } })
        }
        this.props.setModalData<PropertiesModalData>("properties", {
            ...this.props.data,
            schema: schemaTree.toArray(),
            node
        });
    }

    onChangeProperty(name: string, value: any, parent?: string) {
        const schemaTree = SchemaTree.parse(this.props.data.schema || []);
        let node = schemaTree.search(
            this.props.data.node.getDefinition("id")
        ) as SchemaNode;
        if (parent) {
            node.setProp(parent, { ...node.getProp(parent), [name]: value })
        }
        else {
            node.setProp(name, value);
        }
        this.props.setModalData<PropertiesModalData>("properties", {
            ...this.props.data,
            schema: schemaTree.toArray(),
            node,
        });
    }

    renderMappingModal() {
        return (
            <MappingModal
                isLayout={this.props.isLayout}
                onChangeDynamics={this.onChangeDynamics}
                onChangeSource={this.onChangeSource}
                onChangeSourceObject={this.onChangeSourceObject}
                node={this.props.data.node}
                entry={this.props.data.entry}
                metaFields={this.props.contentType?.metaFields || []}
                specFields={this.props.contentType?.specifications || []}
                contentTypes={this.props.contentTypes || []}
                show={this.state.showMappingModal}
                dynamics={this.props.data.dynamics || []}
                onDismiss={() => this.setState({ showMappingModal: false })}
                toggleModal={this.props.toggleModal}
            />
        )
    }

    renderMappingIndicator() {
        const source = this.props.data?.node?.getSource() || {};
        const mapped = _.keys(source).filter(key => source[key]?.type && source[key]?.ref)?.length > 0 ? true : false;
        const arrayProps = (this.props.data?.entry?.properties || []).filter(x => x.mapping.type === PropertyMappingType.Array || x.mapping.type === PropertyMappingType.ArrayData || x.mapping.type === PropertyMappingType.ProductData)
        if (this.props.data?.node.getOption("meta")?.use || arrayProps.length > 0) {
            return (
                <div className="property-list source">
                    <div className="property-item row">
                        <div title="Veri İşlemleri" className="property-text" style={{ alignSelf: "center" }}>
                            <div className="title">Veri İşlemleri</div>
                            <div className="sub-title">
                                İlgili elementi meta, spesifikasyon ya da sorgular ile doldurabilirsiniz. Bunun için yandaki butona tıklayınız.
                         </div>
                        </div>
                        <div className="col">
                            <div className="chooice-group">
                                <div
                                    className={mapped ? "chooice-item row" : "chooice-item passive row"}
                                    onClick={() => this.setState({ showMappingModal: true })}
                                >
                                    <div className="icon">
                                        <div className="default">
                                            <Icon iconName="ColumnRightTwoThirdsEdit" />
                                        </div>
                                        <div className="checkmark">
                                            <i className="icon-checkmark"></i>
                                        </div>
                                    </div>
                                    <div className="text">
                                        Veri Eşleme
                                        <div className="sub-text">
                                            İgili elementin geçerli özelliklerini; meta, spesifikasyon ya da sorgular ile doldurabilirsiniz.
                                    </div>
                                        <span className="brand">
                                            {mapped ? "Aktif" : "Pasif"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    renderField(node: SchemaNode, prop: PropertyDefinition, key: number, parent?: string) {
        switch (prop.mapping.type) {
            case PropertyMappingType.String:
            case PropertyMappingType.Any:
            case PropertyMappingType.Number:
            case PropertyMappingType.Textarea:
                return (
                    <StringField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        multiline={prop?.mapping?.type === PropertyMappingType.Textarea}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        type={
                            prop?.mapping?.type === PropertyMappingType.Number
                                ? "number"
                                : "text"
                        }
                        node={node}
                        index={key}
                        schema={this.props.pageData?.schema || []}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Video:
                return (
                    <VideoField
                        property={prop}
                        node={node}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        index={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.File:
                return (
                    <FileField
                        property={prop}
                        node={node}
                        index={key}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Image:
                return (
                    <ImageField
                        property={prop}
                        node={node}
                        index={key}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Icon:
                return (
                    <IconField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        node={node}
                        index={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Boolean:
                return (
                    <ToggleField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        node={node}
                        index={key}
                        schema={this.props.pageData?.schema || []}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Color:
                return (
                    <ColorField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Alignment: {
                return (
                    <AlignField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            }
            case PropertyMappingType.Array:
            case PropertyMappingType.ProductData:
            case PropertyMappingType.ArrayData: {
                return (
                    <ArrayField
                        property={prop}
                        node={node}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        index={key}
                        toggleModal={this.props.toggleModal}
                        language={this.props.pageData?.language}
                    />
                );
            }
            case PropertyMappingType.Object:
                return (
                    <DropdownField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.LinkWizard:
                return (
                    <LinkField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Html:
                return (
                    <HTMLField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Custom:
                return (
                    <CustomField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        setModalData={this.props.setModalData}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.ParentObject:
                return (
                    <ParentObjectField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        parent={parent}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                )
            case PropertyMappingType.Menu:
                return (
                    <MenuField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                )
            case PropertyMappingType.Website:
                return (
                    <WebsiteField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                )
            case PropertyMappingType.ContentType:
                return (
                    <ContentTypeField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.ContentTypes:
                return (
                    <ContentTypesField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Brand:
                return (
                    <BrandField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.Css:
                return (
                    <CssField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                );
            case PropertyMappingType.SrcSet:
                return (
                    <SrcSetField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                )
            case PropertyMappingType.DateTime:
                return (
                    <DateTimeField
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                )
            case PropertyMappingType.DateTime2:
                return (
                    <DateTimeField2
                        property={prop}
                        value={parent ? (node.getProp(parent) || {})[prop.name] : node.getProp(prop.name)}
                        key={key}
                        onChange={(value) => this.onChangeProperty(prop.name, value, parent)}
                        schema={this.props.pageData?.schema || []}
                        node={node}
                        index={key}
                        language={this.props.pageData?.language}
                    />
                )
            default:
                return null;
        }
    }

    renderGrouppedProperties(grouppedProps: { [key: string]: PropertyDefinition[] }, groupKey: string, node: SchemaNode) {
        return (
            _.keys(grouppedProps).length > 0 && (
                <div className="property-list">
                    {grouppedProps[groupKey].map((x: PropertyDefinition, index: number) =>
                        this.renderField(
                            node as SchemaNode,
                            x as PropertyDefinition,
                            index,
                            x.group?.withObject ? x.group?.key : ""
                        )
                    )}
                </div>
            )
        )
    }

    renderProperties(node: SchemaNode | null, entry: ContainerEntry | null) {
        // const tab = (this.props.tabs || []).filter(x => x.tabType === TabTypeEnum.ELEMENT && x.id === this.props.activeElement);
        let grouppedProps: { [key: string]: PropertyDefinition[] } = {};
        const withoutGroupProps: PropertyDefinition[] = [];
        if (entry && node) {
            grouppedProps = this.groupByGroupKey(entry.properties);
            entry.properties.forEach((item) => {
                if (!item?.group?.key) {
                    withoutGroupProps.push(item);
                }
            });
        }
        return (
            <div className="property-container">
                {this.renderMappingIndicator()}
                {this.renderMappingModal()}
                <Pivot>
                    {withoutGroupProps.length > 0 && (
                        <PivotItem headerText="Genel Özellikler">
                            <div className="property-list">
                                {withoutGroupProps.map((x, key) =>
                                    this.renderField(node as SchemaNode, x, key)
                                )}
                            </div>
                        </PivotItem>
                    )}
                    {_.keys(grouppedProps).length > 0 && (
                        _.keys(grouppedProps).map((groupKey, groupIndex) => {
                            if (groupKey !== "undefined") {
                                return (
                                    <PivotItem key={groupIndex} headerText={groupKey}>
                                        {this.renderGrouppedProperties(grouppedProps, groupKey, node as SchemaNode)}
                                    </PivotItem>
                                )
                            }
                            return null;
                        }))}
                </Pivot>
            </div>
        );
    }

    render() {
        const { data, show } = this.props;


        if (!show) return null;

        console.log("[Node Props]]", data?.node?.getProps(), data?.node?.getSource())

        const distinctDesignerComponents: IDesignerItems[] = DESIGNER_COMPONENTS.reduce((prev: IDesignerItems[], current: IDesignerComponents) => {
            return [...prev, ...current.items]
        }, []);
        const componentTitle = distinctDesignerComponents.find(x => x.schema === data.node?.getType())?.name || "-";
        return (
            <Modal isOpen={true} className="general-modal custom full-modal">
                <div className="title-container">
                    <div className="title-left">
                        <div className="title">
                            {componentTitle}  ({data.node?.getDefinition("name")})

                        </div>
                        <div className="description">
                            Seçtiğiniz elementin ayarlarını aşağıdan yönetebilirsiniz.
                         </div>
                    </div>
                    <div className="title-right" onClick={() => this.onClose()}>
                        <Icon iconName="ChromeClose" />
                    </div>
                </div>
                <div className="modal-content">
                    <div className="modal-scrollable-content">
                        {this.renderProperties(data.node, data.entry)}
                    </div>
                    <div
                        className="modal-indicators"
                        style={{
                            padding: "15px",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <PrimaryButton
                            text="Kaydet"
                            styles={{ root: { marginLeft: "10px", marginTop: "10px" } }}
                            onClick={() => this.onSave()}
                        />
                        <DefaultButton
                            text="İptal"
                            styles={{ root: { marginLeft: "10px", marginTop: "10px" } }}
                            onClick={() => this.onClose()}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesModal);

import { EntryOptions, PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";
import { CmsReactComponent } from "components/cms/CmsReactComponent";
import { ImageChildrenDefinitions } from "components/cms/definitions";
import { BoardPanelProps } from "./types";

export class BoardPanelDefinitions extends CmsReactComponent<BoardPanelProps> {
    static schemaName = "board-panel";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "content",
            mapping: {
                type: PropertyMappingType.Html,
            },
            text: "Açıklama",
        },
        {
            name: "members",
            mapping: {
                type: PropertyMappingType.Html,
            },
            text: "Kurul Üyeleri",
        },
        {
            name: "plaqueCode",
            mapping: {
                type: PropertyMappingType.String,
            },
            text: "Plaka Kodu",
        },
        {
            name: "meetings",
            text: "Toplantılar",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                        default: "",
                        text: "Toplantı Başlığı",
                    },

                    {
                        name: "image",
                        mapping: {
                            type: PropertyMappingType.ParentObject,
                            children: ImageChildrenDefinitions
                        },
                        default: { src: "", alt: "" },
                        text: "Görsel",
                    },
                    {
                        name: "anchor",
                        mapping: {
                            type: PropertyMappingType.LinkWizard,
                        },
                    },
                ]
            },
            default: []
        },

    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [MetaTypeEnum.Text, MetaTypeEnum.TextArea, MetaTypeEnum.Editor, MetaTypeEnum.Link],
        },
        mappingFields: [
            {
                name: "title",
                text: "Toplantı Başlığı",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "image",
                text: "Görsel",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "anchor",
                text: "URL",
                types: [MetaTypeEnum.Link]
            },
        ],
    };
}

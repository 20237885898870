import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { SectionProps } from "./types";
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { ContainerSizesOneOf } from '../defaults';
import { MetaTypeEnum } from 'app/shared';
import { ImageChildrenDefinitions } from '../definitions';

export class SectionDefinitions extends CmsReactComponent<SectionProps> {
    static schemaName = "section";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "title",
            text: "Başlık",
            mapping: {
                type: PropertyMappingType.String,
            },
        },
        {
            name: "subtitle",
            text: "Alt Başlık",
            mapping: {
                type: PropertyMappingType.String,
            },
        },
        {
            name: "description",
            text: "Açıklama",
            mapping: {
                type: PropertyMappingType.Textarea
            },
        },
        {
            name: "logo",
            text: "Logo",
            mapping: {
                type: PropertyMappingType.ParentObject,
                children: ImageChildrenDefinitions
            }
        },
        {
            name: "content",
            text: "İçerik",
            mapping: {
                type: PropertyMappingType.Textarea,
            },
        },
        {
            name: "anchor",
            mapping: {
                type: PropertyMappingType.LinkWizard
            }
        },
        {
            name: "size",
            mapping: {
                type: PropertyMappingType.Object,
                oneOf: ContainerSizesOneOf,
            },
            text: "Boyutlandırma",
            group: {
                key: "container",
                title: "Kapsayıcı Ayarları",
                withObject: true
            }
        },
        {
            name: "align",
            mapping: {
                type: PropertyMappingType.Alignment
            },
            text: "Hizalandırma"
        },
        {
            name: "capitalize",
            mapping: {
                type: PropertyMappingType.Boolean
            },
            text: "İlk Harfler Büyük Olarak Kullanılsın Mı?"
        }
    ];


    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
        }
    };
}



import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { SearchBox } from "office-ui-fabric-react";

import { RootState } from 'app/redux/reducer';
import { History } from "history";
import { Dispatch, bindActionCreators } from 'redux';
import { setLeftPanel } from 'app/redux/system/actions';
import FileManager from './FileManager/FileManager';
import { getContentTypes, mapContentTypePanelFromModel } from "app/services/content-type";
import { getMenuItems } from "app/shared/functions/menu";
import { IMenuItem, MenuProperty, RouteKey } from "app/shared";
import clsx from "clsx";

interface OwnProps {
    history: History<any>;
    isContentTypePage?: boolean;
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
    return {
        ...ownProps,
        userInfo: state.system.userInfo,
        language: state.system.language,
        designerMode: state.designer.designerMode,
        leftPanel: state.system.leftPanel,
        panelLoading: state.system.panelLoading,
        contentTypes: state.contentType.contentTypes,
        isAuthorized: state.system.tokenStatus,
        counter: state.system.counter
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        setLeftPanel,
        getContentTypes,
    }, dispatch);
}

interface LeftPanelState {
    searchValue: string;
    showModal: boolean;
}

type LeftPanelProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

class LeftPanel extends React.Component<LeftPanelProps, LeftPanelState> {


    constructor(props: LeftPanelProps) {
        super(props);

        this.state = {
            "searchValue": "",
            "showModal": false
        };
    }

    UNSAFE_componentWillMount() {
        if (!this.props.isContentTypePage) {
            this.props.getContentTypes({
                page: 1,
                pageSize: 100,
                descending: false,
                status: 1
            }, true)
        }
    }

    renderPanel() {
        const contentTypes = (this.props.contentTypes.list || []).map(x => mapContentTypePanelFromModel(x));
        const items = this.state.searchValue.length > 0 ? getMenuItems().filter(x => x.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) >= 0) : getMenuItems();
        const contentTypeIndex = items.findIndex(x => x.key === RouteKey.ContentTypes);

        if (contentTypeIndex !== -1) {
            items[contentTypeIndex].children = contentTypes;
        }

        return (
            <div className="panel-inner">
                <div className="panel-head row">
                    <div className="title">
                        Yönetim
						<span className="sub-title">
                            Yönetim sayfalarını aşağıdan görüntüleyebilirsiniz.
						</span>
                    </div>
                </div>
                <div className="panel-search">
                    <SearchBox
                        className="custom-searchbox"
                        placeholder="Ara"
                        value={this.state.searchValue}
                        onChange={(e: any) => this.setState({ searchValue: e?.target.value || "" })} />
                </div>
                <div className="panel-list">
                    {items.map((item, index) => {
                        return (
                            <div
                                className={clsx(
                                    "item",
                                    item.properties?.includes(MenuProperty.FileManager) && "file-manager",
                                    item.properties?.includes(MenuProperty.ContentType) && "content-type"
                                )}
                                onClick={() => {
                                    if (item.properties?.includes(MenuProperty.FileManager)) {
                                        this.setState({ showModal: true })
                                    }
                                    window.scrollTo({ top: 0 })
                                }}
                                key={index}
                            >
                                {!item.properties?.includes(MenuProperty.FileManager) && item.url ? (
                                    <Link
                                        to={item.url}
                                    >
                                        <i className={item.icon}></i>
                                        {item.title}
                                        {item.counter && this.props.counter[item.counter] !== 0 && (
                                            <span className="badge-count">
                                                {this.props.counter[item.counter]}
                                            </span>
                                        )}
                                        {item.children && item.children.length > 0 && (
                                            <i className="fas fa-angle-right parent"></i>
                                        )}
                                    </Link>
                                ) : (
                                    <div
                                        className="row"
                                    >
                                        <i className={item.icon}></i>
                                        {item.title}
                                        {item.children && item.children.length > 0 && (
                                            <i className="fas fa-angle-right parent"></i>
                                        )}
                                    </div>
                                )}

                                {this.renderExternalPanel(item)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderExternalPanel(item: IMenuItem) {
        if (item.children && item.children.length > 0) {
            return (
                <div className="external-panel-list">
                    <div className="panel-head">
                        <div className="title">
                            {item.title}
                            <span className="sub-title">{item.text}</span>
                        </div>
                    </div>
                    <div className="panel-list">
                        {item.children.map((childrenItem, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        window.scrollTo({ top: 0 });
                                    }}
                                    className="item"
                                    key={index}
                                >
                                    <Link to={childrenItem.url ? childrenItem.url : "/"}>
                                        <i className={childrenItem.icon}></i>
                                        {childrenItem.title}
                                        {childrenItem.counter && this.props.counter[childrenItem.counter] !== 0 && (
                                            <span className="badge-count">
                                                {this.props.counter[childrenItem.counter]}
                                            </span>
                                        )}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )
        };

        return null;
    }

    renderFileManager() {
        return (
            <FileManager
                show={this.state.showModal}
                onDismiss={() => this.setState({ showModal: false })}
                disableSelect
            />
        )
    }

    render() {
        if (this.props.designerMode) return null;

        return (
            <React.Fragment>
                {this.renderFileManager()}
                <div className="left-panel">
                    <div className="left-panel-wrapper row">
                        {this.renderPanel()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
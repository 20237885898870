import { DefaultButton, Dropdown, Icon, Label, Modal, PrimaryButton, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { RootState } from 'app/redux/reducer';
import { bindActionCreators, Dispatch } from 'redux';
import { setModalData, toggleModal } from 'app/redux/modal/actions';
import { connect } from 'react-redux';
import { MetaTypeEnum } from 'app/shared';
import _ from "lodash";
import AutoComplete from '../AutoComplete';
import { customServices } from './constants';
import AbsoluteLoader from '../Loader/AbsoluteLoader';
import { ICustomServiceWizard } from 'app/services/post';
import { sleep } from 'app/shared/functions';

function mapStateToProps(state: RootState) {
    return {
        data: state.modal.customServiceWizard.data,
        show: state.modal.customServiceWizard.show
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            toggleModal,
            setModalData
        },
        dispatch
    );
}

type ModalProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface ModalState {
    service: {
        loader: boolean;
        response: any[];
    }
}

class CustomServiceWizardModal extends React.Component<ModalProps, ModalState> {

    state = {
        service: {
            loader: false,
            response: []
        }
    }

    onClose() {
        this.props.toggleModal("customServiceWizard", {});
    }

    _runQuery() {
        this.setState({
            service: {
                loader: true,
                response: []
            }
        }, async () => {
            await sleep(1500);
            this.setState({
                service: {
                    loader: false,
                    response: ["data.title", "data.image", "data.url"]
                }
            })
        })
    }

    setData(key: string, value: any) {
        this.props.setModalData<ICustomServiceWizard>("customServiceWizard", {
            ...this.props.data,
            [key]: value
        })
    }

    setMapping(value: string, index: number) {
        let mapping = _.cloneDeep(this.props.data.mapping);

        mapping[index].value = value;
        this.setData("mapping", mapping);
    }

    renderService() {
        return (
            <div className="wizard-item">
                <div className="title">Bir Özel Servis Seçiniz
                    <div className="text">Seçtiğiniz özel servis'i çalıştırdıktan sonra, eşleme işlemi yapacağınız değerler, servisin sonucuna göre oluşacaktır.</div>
                </div>
                <div style={{ marginTop: 6 }} className="wizard-item-content row">
                    <Dropdown
                        className="custom-dropdown col no-padding"
                        calloutProps={{
                            className: "custom-dropdown-callout"
                        }}
                        options={customServices}
                        selectedKey={this.props.data.service || ""}
                        onChange={(_, opt) => {
                            this.setData("service", opt?.key as string);
                        }}
                    />
                    <div className="button-wrapper">
                        <PrimaryButton
                            iconProps={{ iconName: "Play" }}
                            className="custom-button"
                            text="Servisi Çalıştır"
                            styles={{
                                root: {
                                    height: "30px",
                                    marginLeft: 10
                                }
                            }}
                            style={{
                                fontSize: 9
                            }}
                            onClick={() => this._runQuery()}
                        />
                        <AbsoluteLoader show={this.state.service.loader} className="small" />
                    </div>

                </div>
            </div>
        )
    }

    renderMapping() {
        console.log("this", this.props.data);
        return (
            <div className="wizard-item mapping-list">
                <div className="title">Veri Eşleme
                <div className="text">Veriler eşlediğiniz karşılıklara göre dönecektir.</div>
                </div>
                <div style={{ marginTop: 6 }} className="wizard-item-content">
                    {(this.props.data.node.getOption("mappingFields") || []).map((nodeMapping: { name: string, text: string, types: MetaTypeEnum[] }, index: number) => {
                        return (
                            <div key={index} className="mapping row">
                                <TextField
                                    className="small-element custom-textfield col"
                                    label={nodeMapping.text}
                                    readOnly
                                    value={nodeMapping.name}
                                />
                                <div className="col">
                                    <Label style={{ marginBottom: "1px !important" }}>Eşlenecek Değer</Label>
                                    <AutoComplete
                                        disabled={this.state.service.loader || !this.state.service.response || this.state.service.response.length === 0}
                                        placeholder="Eşlenecek bir değer seçin"
                                        selectedValue={this.props.data.mapping?.find(x => x.key === nodeMapping.name)?.value || ""}
                                        onSelected={(value) => {
                                            const fields = _.cloneDeep(this.props.data.mapping || []);
                                            const findIndex = fields?.findIndex(x => x.key === nodeMapping.name);

                                            if (findIndex !== -1) {
                                                fields[findIndex].value = value;
                                            }
                                            else {
                                                fields.push({
                                                    key: nodeMapping.name,
                                                    value
                                                })
                                            }
                                            this.setData("mapping", fields);
                                        }}

                                        filterData={this.state.service.response}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderModalContent() {
        return (
            <div className="data-wizard-content">
                {this.renderService()}
                {this.renderMapping()}
            </div>
        )
    }

    render() {
        if (!this.props.show) return null;
        return (
            <Modal isOpen={true} className="general-modal custom full-modal data-wizard-modal">
                <div className="title-container">
                    <div className="title-left">
                        <div className="title">
                            Özel Servis - Sorgu Sihirbazı
                        </div>
                        <div className="description">
                            Aşağıdan kriterleri ayarlayarak verinizi yönetebilirsiniz.
                    </div>
                    </div>
                    <div className="title-right" onClick={() => this.onClose()}>
                        <Icon iconName="ChromeClose" />
                    </div>
                </div>
                <div className="modal-content">
                    <div className="modal-scrollable-content">
                        {this.renderModalContent()}
                    </div>
                    <div className="modal-indicators" style={{ padding: "15px", display: "flex", justifyContent: "flex-end" }}>
                        <PrimaryButton
                            text="Sihirbazı Tamamla" styles={{ root: { marginLeft: "10px", marginTop: "10px" } }}
                            onClick={() => {
                                this.onClose();
                            }}
                        />
                        <DefaultButton text="İptal" styles={{ root: { marginLeft: "10px", marginTop: "10px" } }} onClick={() => this.onClose()} />
                    </div>
                </div>
            </Modal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomServiceWizardModal);

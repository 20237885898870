import * as React from "react";
import { FieldRendererData } from 'app/library/layout-builder';
import { setModalData } from 'app/redux/modal/actions';
import { BaseFieldProps } from "./types";

interface CustomFieldProps {
    setModalData: typeof setModalData;
}

// Kendisine gelen Function'a göre Custom field render eder.
export default class CustomField extends React.Component<BaseFieldProps & CustomFieldProps> {
    render() {

        if (!this.props.property.mapping.fieldRenderer) return null;

        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;


        const data: FieldRendererData = {
            fieldProps: this.props,
            property: this.props.property,
            setData: (schema) => this.props.setModalData("properties", schema)
        };
        if (this.props.property.mapping.showPropertyText) {
            return (
                <div className="property-item row">
                    <div title={this.props.property.name} className="property-text">
                        <div className="title">{this.props.property.text || this.props.property.name.split(/(?=[A-Z])/).map(item => item.charAt(0).toUpperCase() + item.slice(1) + " ")}</div>
                        <div className="sub-title">{this.props.property.description || this.props.property.name}</div>
                    </div>
                    <div className="col">
                        {this.props.property?.mapping.fieldRenderer(data)}
                    </div>
                </div>
            )
        }
        return this.props.property?.mapping.fieldRenderer(data)
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType
} from "app/library/layout-builder";
import { PhotoGalleryProps } from "./types";
import { MetaTypeEnum } from "app/shared";
import { PhotoGalleryDefinition } from '../definitions';

export class PhotoGalleryDefinitions extends CmsReactComponent<PhotoGalleryProps> {
    static defaultProps = {};

    static schemaName = "photo-gallery";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "data",
            description: "Fotoğraf Galerisi Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: PhotoGalleryDefinition
            }
        }
    ];


    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.MultiImage,
                MetaTypeEnum.PhotoGallery
            ]
        },
        mappingFields: [
            {
                name: "thumbnail",
                text: "Görsel Öne Çıkarılmış",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "src",
                text: "Görsel Yolu",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: "alt",
                text: "Görsel Alt Etiketi",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "caption",
                text: "Görsel Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "subcaption",
                text: "Görsel Alt Başlık",
                types: [MetaTypeEnum.TextArea, MetaTypeEnum.Text, MetaTypeEnum.Editor]
            }

        ]
    };
}

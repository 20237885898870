import * as React from "react";
import { BaseFieldProps } from "./types";
import { PureColorField } from "./Pure/PureColorField";

// Kendisine gelen Property ismine ve değerine göre Color Field render eder.
export default class ColorField extends React.Component<BaseFieldProps> {
  render() {
    if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

    return (
      <PureColorField
        label={this.props.property.name}
        value={this.props.value}
        onChange={value => this.props.onChange(value)}
        text={this.props.property.text as string}
        description={this.props.property.description as string}
      />
    );
  }
}

import * as React from "react";
import { BaseFieldProps } from "./types";
import { PureDropdownField } from "./Pure/PureDropdownField";

// Kendisine gelen Property ismine ve değerine göre Website field render eder.
export default class WebsiteField extends React.Component<BaseFieldProps> {
    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        return (
            <PureDropdownField
                label="Website"
                value={this.props.value}
                onChange={value => this.props.onChange(value)}
                property={this.props.property}
                text={this.props.property.text as string}
                description="Geçerli element'in hangi web siteye göre gözükeceğini belirleyin."
            />
        );
    }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import {
    ContainerEntry,
    SchemaNode,
    SchemaTree,
} from "app/library/layout-builder";
import { container } from "./container";
import _ from "lodash";
import { IDynamicFilter } from "app/shared";
import { SchemaService } from "app/services/schema";
import { RootState } from "app/redux/reducer";
import { setPageData } from "app/redux/page/actions";
import { toggleModal } from "app/redux/modal/actions";
import { copyComponent } from "app/redux/system/actions";
import { PropertiesModalData } from "app/redux/modal/types";
import { DESIGNER_COMPONENTS, IDesignerItems } from "app/shared/constants";

interface OwnProps {
    componentSchemaId: string;
    componentType?: string;
    componentEntry?: ContainerEntry;
    titleRender?: () => {};
    componentIsColumn?: any;
    componentIsDefault?: boolean;
    componentIsNotEditable?: boolean;
    componentSize?: string | number;
}

function mapStateToProps(state: RootState) {
    return {
        designSchema: state.page.pageData?.schema || [],
        dynamics: state.page.pageData?.dynamics as IDynamicFilter[] || [],
        copiedComponent: state.system.copiedComponent || {}
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators(
            {
                setPageData,
                toggleModal,
                copyComponent
            },
            dispatch
        ),
    };
}

type ComponentHeaderProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    OwnProps;

interface ComponentHeaderState {
    copyStatus: boolean;
}

class ComponentHeader extends Component<ComponentHeaderProps, ComponentHeaderState> {

    state = {
        copyStatus: false
    }

    getComponentDefinition() {
        const distinctItems = DESIGNER_COMPONENTS.reduce((prev: IDesignerItems[], current) => prev = [...prev, ...current.items], [])
        return distinctItems.find(x => x.schema === this.props.componentEntry?.name);
    }

    onCopyComponent() {
        const schemaTree = SchemaTree.parse(this.props.designSchema || []);
        const node = schemaTree.search(this.props.componentSchemaId) as SchemaNode;
        this.setState({
            copyStatus: true
        }, () => {
            this.props.copyComponent(node);
        })
    }

    onPasteComponent() {
        SchemaService.changeProperties(this.props.componentSchemaId, this.props.copiedComponent.getProps(), this.props.copiedComponent?.getSource() || null);
        this.props.copyComponent({} as SchemaNode);
    }

    onChangeProperty(name: string, value: any) {
        SchemaService.changeProperty(this.props.componentSchemaId, name, value);
    }

    onRemove() {
        // const schemaTree = SchemaTree.parse(this.props.designSchema || []);
        // const node = schemaTree.search(this.props.componentSchemaId) as SchemaNode;
        // let filterId = "";
        // if (node.source && _.keys(node.source).length > 0) {
        //     _.keys(node.source).forEach(prop => {
        //         if (node.source[prop]?.ref && node.source[prop]?.type === SchemaSourceTypes.Dynamics) {
        //             filterId = node.source[prop].ref as string;
        //         }
        //     })
        // }
        // if (filterId) {
        //     const dynamics = _.cloneDeep(this.props.dynamics);
        //     const findIndex = dynamics.findIndex(x => x.id === filterId);
        //     if (findIndex !== -1) {
        //         dynamics.splice(findIndex, 1);
        //         this.props.setPageData("dynamics", dynamics);
        //     }
        // }
        SchemaService.removeNode(this.props.componentSchemaId);
    }

    onClone() {
        const schemaTree = SchemaTree.parse(this.props.designSchema || []);
        const node = schemaTree.search(this.props.componentSchemaId) as SchemaNode;
        const schemaType = node.name || "";
        const parentId = node.parent?.definitions?.id || "";
        const getCurrentIndex = (node.parent?.children || []).findIndex(
            (item) => item.definitions.id === this.props.componentSchemaId
        );
        if (this.props.componentSchemaId && parentId && getCurrentIndex !== -1) {
            SchemaService.insertAt(
                parentId,
                schemaType,
                node.props,
                getCurrentIndex + 1,
                node.children,
                node.options,
                node.source
            );
        }
    }

    render() {
        const schemaTree = SchemaTree.parse(this.props.designSchema || []);
        const node = schemaTree.search(this.props.componentSchemaId) as SchemaNode;
        const entry = (container.entries || []).find(
            (x) => x.name === node.getType()
        ) as ContainerEntry;

        return (
            <div className="component-header row">
                {!this.props.componentIsDefault && (
                    <React.Fragment>
                        {!this.props.componentIsNotEditable && (
                            <React.Fragment>
                                <span
                                    onClick={() => {
                                        this.props.toggleModal<PropertiesModalData>("properties", {
                                            node,
                                            entry,
                                            schema: _.cloneDeep(this.props.designSchema),
                                            dynamics: this.props.dynamics
                                        });
                                    }}
                                    title="Özellikleri Yönet"
                                    className="settings-properties"
                                >
                                    <i className="icon-properties"></i>
                                </span>
                                <span
                                    title="Klonla"
                                    className="clone-indicator"
                                    onClick={() => this.onClone()}
                                >
                                    <i className="far fa-clone"></i>
                                </span>
                            </React.Fragment>
                        )}
                        <span
                            title="Sil"
                            className="remove-indicator"
                            onClick={() => this.onRemove()}
                        >
                            <i className="icon-close1"></i>
                        </span>
                    </React.Fragment>
                )}

                {this.props.componentIsColumn && (
                    <React.Fragment>
                        <span
                            title="Sütun'u Küçült"
                            onClick={() =>
                                this.onChangeProperty(
                                    "sizes",
                                    this.props.componentSize &&
                                        this.props.componentSize <= 12 &&
                                        this.props.componentSize > 1
                                        ? {
                                            lg: (parseInt(this.props.componentSize as string) - 1),
                                            xl: (parseInt(this.props.componentSize as string) - 1),
                                            xs: (parseInt(this.props.componentSize as string) - 1),
                                            sm: (parseInt(this.props.componentSize as string) - 1),
                                            md: (parseInt(this.props.componentSize as string) - 1),
                                        }
                                        : { lg: 1, xs: 1, sm: 1, md: 1, xl: 1 }
                                )
                            }
                            className="decrase-column"
                        >
                            <i
                                style={{ transform: "rotate(180deg)", display: "inline-block" }}
                                className="icon-arrowleft"
                            ></i>
                        </span>
                        <span
                            onClick={() =>
                                this.onChangeProperty(
                                    "sizes",
                                    this.props.componentSize &&
                                        this.props.componentSize >= 1 &&
                                        this.props.componentSize < 12
                                        ? {
                                            lg: (parseInt(this.props.componentSize as string) + 1),
                                            xl: (parseInt(this.props.componentSize as string) + 1),
                                            xs: (parseInt(this.props.componentSize as string) + 1),
                                            sm: (parseInt(this.props.componentSize as string) + 1),
                                            md: (parseInt(this.props.componentSize as string) + 1)
                                        }
                                        : { lg: 12, xs: 12, sm: 12, md: 12, xl: 12 }
                                )
                            }
                            title="Sütun'u Büyült"
                            className="increase-column"
                        >
                            <i className="icon-arrowleft"></i>
                        </span>
                    </React.Fragment>
                )}
                <div className="title">
                    <span
                        onClick={() => this.onCopyComponent()}
                        className="schema-copy"
                    >
                        {this.state.copyStatus ? "Kopyalandı" : "Şemayı Kopyala"}
                    </span>
                    {_.keys(this.props.copiedComponent)?.length > 0 &&
                        this.props.copiedComponent.getType() === node.getType() && (
                            <span
                                onClick={() => this.onPasteComponent()}
                                className="schema-paste"
                            >
                                Şemayı Yapıştır
                            </span>
                        )}
                    {this.props.titleRender
                        ? this.props.titleRender()
                        : this.getComponentDefinition()?.name}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentHeader);

import { api } from 'app/utils/api';
import { getEndpoint } from 'app/utils/service';
import { HttpClient } from 'app/library/client';
import { ResponseBase, ResponseContentBase } from "app/shared";
import { ThemeSettingsEntity } from './types';

export class ConfigurationService {

    /** It get configuration data */
    async getConfiguration(language?: string): Promise<ResponseBase<ResponseContentBase<ThemeSettingsEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.configuration.admin + `?language=${language || "en"}`));
    }

    /** It creates configuration */
    async createConfiguration(request: ThemeSettingsEntity): Promise<ResponseBase<ResponseContentBase<ThemeSettingsEntity>>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.configuration.main), request);
    }

    /** It updates configuration */
    async updateConfiguration(request: ThemeSettingsEntity): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().patch(getEndpoint(api.prefixes.configuration.main), request);
    }
}

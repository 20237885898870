import ComponentDescription from "components/customs/ComponentDescription";
import ComponentHeader from "components/cms/ComponentHeader";
import { SlideshowDefinitions } from "./SlideshowDefinitions";

export class Slideshow extends SlideshowDefinitions {
  render() {
    const { className, designing } = this.props;


    return (
      <p
        data-schema-id={this.props.id}
        className={[
          "fa-slideshow disable-inside fa-draggable",
          designing && "designing",
          className,
        ]
          .filter((e) => !!e)
          .join(" ")}
      >
        {designing && (
          <ComponentHeader
            componentEntry={this.props.entry}
            componentSchemaId={this.props.id as string}
          />
        )}
        <ComponentDescription
          node={this.props.schemaNode}
          entry={this.props.entry}
        />
      </p>
    );
  }
}

import { Component } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, Label, PrimaryButton, TextField } from 'office-ui-fabric-react';
import FileManager from '../FileManager/FileManager';
import { IMetaSliderGallery } from 'app/services/content-type';
import { FileTypes } from 'app/services/file-manager';
import { getFilePath } from 'app/utils/service';
import { validateUrl } from 'app/shared/functions';
import LinkWizardModal from './LinkWizardModal';
import { ILinkWizard } from 'app/shared';

interface MetaSliderGalleryModalProps {
    show: boolean;
    data: IMetaSliderGallery | null;
    onSave: (data: IMetaSliderGallery | null) => void;
    onDismiss: () => void;
    language: string;
}

interface MetaSliderGalleryModalState {
    data: IMetaSliderGallery | null;
    activeFileModal: string;
    showLinkModal: boolean;
}

export class MetaSliderGalleryModal extends Component<MetaSliderGalleryModalProps, MetaSliderGalleryModalState> {

    state = {
        data: this.props.data || null,
        activeFileModal: "",
        showLinkModal: false
    }

    renderFileManager() {
        return (
            <FileManager
                show={this.state.activeFileModal ? true : false}
                onDismiss={() => this.setState({ activeFileModal: "" })}
                onSelectedFile={(file) => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            [this.state.activeFileModal]: {
                                src: file.fileName,
                                alt: file.fileName
                            }
                        } as IMetaSliderGallery
                    })
                }}
                fileType={FileTypes.Image}
            />
        )
    }

    renderLinkWizard() {
        return (
            <LinkWizardModal
                show={this.state.showLinkModal}
                data={this.state.data?.anchor as ILinkWizard}
                onSave={(data) => {

                    this.setState({
                        showLinkModal: false,
                        data: {
                            ...this.state.data as IMetaSliderGallery,
                            anchor: data as ILinkWizard
                        }
                    })
                }}
                onDismiss={() => {
                    this.setState({
                        showLinkModal: false
                    })
                }}
                language={this.props.language}
            />
        )
    }

    renderImage(field: string) {
        const image = this.state.data ? this.state.data[field]?.src : "";
        const splitted = image?.split(".");
        const imageSource = validateUrl(image) ? image : (getFilePath() + image + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=200&height=200" : ""));

        return (
            <div className="image-field row">
                {image && (
                    <img className="image-string-field" src={imageSource} alt="" />
                )}
                <TextField
                    className="custom-textfield col no-padding"
                    value={image}
                    onChange={(event: any) => {
                        this.setState({ data: { ...this.state.data, [field]: { src: event.target.value as string } } as IMetaSliderGallery })
                    }}
                />
                <DefaultButton
                    className="custom-button"
                    text="Görüntüle"
                    style={{ marginTop: 2.5 }}
                    disabled={!imageSource}
                    onClick={() => {
                        window.open(imageSource, "_blank")
                    }}
                />
                <PrimaryButton
                    className="custom-button"
                    text="Görsel Seç"
                    style={{ marginTop: 2.5 }}
                    onClick={() => {
                        this.setState({ activeFileModal: field })
                    }}
                    styles={{
                        root: {
                            background: "#33926e !important",
                            borderColor: "#33926e !important"
                        },
                        rootDisabled: {
                            opacity: 0.3,
                            background: "#ececec",
                            color: "#000"
                        }
                    }}
                />
            </div>
        );
    }

    renderForm() {
        return (
            <div className="meta-form">
                <TextField
                    label="Fotoğraf Başlığı"
                    className="custom-textfield"
                    value={this.state.data?.title}
                    onChange={(event: any) => {
                        this.setState({ data: { ...this.state.data, title: event.target?.value as string, image: { ...this.state.data?.image, alt: event.target?.value as string } } as IMetaSliderGallery })
                    }}
                />
                <Label>Fotoğraf *</Label>
                {this.renderImage("image")}
                <Label>Öne Çıkarılmış Fotoğraf (Opsiyonel)</Label>
                {this.renderImage("thumbnail")}
                <Label>URL</Label>
                {this.renderLinkWizard()}
                <DefaultButton
                    iconProps={{ iconName: "Touch" }}
                    className="custom-button"
                    text="Link Sihirbazını Aç"
                    onClick={() => {
                        this.setState({
                            showLinkModal: true
                        })
                    }}
                />
            </div>
        )
    }

    render() {
        if (!this.props.show) return null;

        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Slider Öğesini Düzenle",
                    closeButtonAriaLabel: 'Kapat',
                    subText: "Aşağıdan slider öğesi özelliklerini güncelleyebilirsiniz. Slider görselini girmek zorunludur.",
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: false,
                    styles: { main: { maxWidth: "1000px !important" } }
                }}
            >
                {this.renderForm()}
                {this.renderFileManager()}
                <DialogFooter>
                    <DefaultButton onClick={() => this.props.onDismiss()} text="İptal" />
                    <PrimaryButton
                        disabled={!this.state.data?.image?.src}
                        onClick={() => {
                            this.props.onSave(this.state.data);
                            this.props.onDismiss();
                        }}
                        text="Kaydet"
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}

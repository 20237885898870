import React from 'react'
import { CmsReactComponent } from 'components/cms/CmsReactComponent'

import { ScrollbarProps } from './types'
import ComponentHeader from 'components/cms/ComponentHeader';
import Droppable from 'components/customs/Designer/Droppable';

export default class ScrollbarDesigner extends CmsReactComponent<ScrollbarProps> {

    renderInformation() {
        if ((this.props.children && this.props.children.length > 0)) {
            return null;
        }
        return (
            <div className="blank-wrapper">
                <div className="blank-box">
                    <i className="icon-f_section"></i>
                    <div className="title">Scrollbar</div>
                    <div className="sub-text">
                        Bu alana sürükleme yapabilirsin.
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderInformation()}
                <ComponentHeader
                    componentEntry={this.props.entry}
                    componentSchemaId={this.props.id as string}
                />
                <Droppable
                    style={{ alignContent: "baseline", paddingBottom: "50px" }}
                    elementId={this.props.id as string}
                    dragDropContext={(this.props as any).dragDropContext}
                    className={this.props.children && this.props.children.length > 0 ? "fa-droppable-row" : "fa-droppable-row row align-center justify-center"}
                    groupName="layout"
                    elementType="column"
                >
                    {this.props.children}
                </Droppable>
            </React.Fragment>
        )
    }
}

import React from 'react'
import { CmsReactComponent } from 'components/cms/CmsReactComponent'

import { RowProps } from './types'
import ComponentHeader from 'components/cms/ComponentHeader';
import Droppable from 'components/customs/Designer/Droppable';

export default class RowDesigner extends CmsReactComponent<RowProps> {

    renderInformation() {
        if ((this.props.children && this.props.children.length > 0) || !this.props.defaultElement) {
            return null;
        }
        return (
            <div className="blank-wrapper">
                <div className="blank-box">
                    <i className="icon-t-grid"></i>
                    <div className="title">Grid (Row)</div>
                    <div className="sub-text">
                        Yalnızca <strong>column</strong> ya da <strong>container</strong> sürükleyebilirsiniz.
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderInformation()}
                <ComponentHeader
                    componentEntry={this.props.entry}
                    componentSchemaId={this.props.id as string}
                    componentIsDefault={this.props.defaultElement}
                />
                <Droppable
                    style={{ display: "flex", flexDirection: "row" }}
                    elementId={this.props.id as string}
                    dragDropContext={(this.props as any).dragDropContext}
                    className={this.props.children && this.props.children.length > 0 ? "fa-droppable-row" : "fa-droppable-row row"}
                    groupName="layout"
                    disableSort={true}
                    elementType="grid">
                    {this.props.children}
                </Droppable>
            </React.Fragment>
        )
    }
}

import { Component } from 'react';

import { BaseMetaFieldsProps } from './types';
import MSColorPicker from "../../MSColorPicker";

export default class MetaColor extends Component<BaseMetaFieldsProps<string>> {
    render() {
        return (
            <MSColorPicker
                onChangeColor={(color: any) => this.props.onChange(color)}
                color={this.props.meta?.data as string || ""}
            />
        )
    }
}

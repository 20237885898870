import *  as _yup from 'yup';

import { GSM_CODES } from 'app/shared/constants/gsm';

const validations = {
    phone: _yup.string()
        .test('equal', 'Geçersiz bir telefon numarası girdiniz lütfen kontrol edin', (val) => {
            const value = (val || '').replace(/\s|_/g, '')

            return GSM_CODES.includes(parseInt(value.slice(0, 3)))
        })
        .test('length', 'Telefon numarası 10 haneli olmalıdır', (val) => {
            const value = (val || '').replace(/\s|_/g, '')

            return value.length === 10
        })
        .required('Telefon bilgisi boş bırakamazsınız'),
    name: _yup.string()
        .test('length', 'Adınız 3 karakterden fazla olmalıdır', (val) => {
            const value = (val || '').replace(/_/g, '')

            return value.length > 2
        })
        .required('Adınızı boş bırakamazsınız'),
    surname: _yup.string()
        .test('length', 'Soyadınız 2 karakterden fazla olmalıdır', (val) => {
            const value = (val || '').replace(/_/g, '')

            return value.length > 1
        })
        .required('Soyadınızı boş bırakamazsınız'),
    identification: _yup.string()
        .test('length', 'TC kimlik numarası 11 haneli olmalıdır', (val) => {
            const value = (val || '').replace(/_/g, '')

            return value.length === 11
        })
        .required('TC kimlik numaranızı boş bırakamazsınız'),
    birthyear: _yup.string()
        .test('equal', 'Geçersiz doğum yılı, tekrar kontrol edin', (val) => {
            const value = val ? parseInt(val) : 0

            return value > 1900 && value < new Date().getFullYear()
        })
        .test('length', 'Doğum yılın 4 haneli olmalıdır', (val) => {
            const value = (val || '').replace(/_/g, '')

            return value.length === 4
        })
        .required('Doğum yılını boş bırakamazsınız'),
    carPlate: _yup.string()
        .test('value', "Geçerli bir araç plakası giriniz", (val) => {
            const value = val?.replace(/\s+/g, '').toUpperCase();
            const regex = /^(0[1-9]|[1-7][0-9]|8[01])(([A-PR-VYZ])(\d{4,5})|([A-PR-VYZ]{2})(\d{3,4})|([A-PR-VYZ]{3})(\d{2,3}))$/;

            return value?.match(regex) != null;
        })
        .required('Araç plakasını boş bırakamazsınız'),
    vkn: _yup.string()
        .test('length', 'Vergi Kimlik No (VKN) 10 haneli olmalı', (val) => {
            return (val || '').length === 10
        })
        .test('onlyNumber', 'Vergi Kimlik No (VKN) yalnızca rakamlardan oluşmalı', (val) => {
            return /^[0-9]*$/.test(val || '')
        })
        .required('Vergi Kimlik No (VKN) boş bırakamazsınız'),
    email: _yup.string()
        .required(
            "Mail adresini boş bırakamazsınız"
        )
        .email("Lütfen geçerli bir mail adresi giriniz"),
}

export default validations

import * as React from "react";
import { PrimaryButton } from "office-ui-fabric-react";
import { PureStringField } from "./Pure/PureStringField";
import { BaseFieldProps } from './types';
import IconPanel from '../IconPanel';

export interface IconFieldProps extends BaseFieldProps {
    isFixed?: boolean;
    isCallout?: boolean;
}

interface IconFieldState {
    showModal: boolean;
}

// Kendisine gelen Property ismine ve değerine göre Icon Field render eder.
export default class IconField extends React.Component<IconFieldProps, IconFieldState> {

    state = {
        showModal: false
    };

    renderIconManager() {
        return (
            <IconPanel
                show={this.state.showModal}
                onDismiss={() => this.setState({ showModal: false })}
                onSave={(icon) => {
                    this.props.onChange(icon);
                }}
            />
        );
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        return (
            <PureStringField
                label={this.props.property.name}
                text={this.props.property.text as string}
                description={this.props.property.description as string}
                value={this.props.value}
                onChange={value => this.props.onChange(value)}
                className="icon-field"
                iconSource={this.props.value}
                customRender={() => {
                    return (
                        <div style={{ marginLeft: "auto" }} className="property-alignment row">
                            {this.renderIconManager()}
                            <PrimaryButton
                                className="custom-button"
                                text="İkon Seç"
                                style={{ marginTop: 2.5 }}
                                onClick={() => {
                                    this.setState({
                                        showModal: true
                                    });
                                }}
                            />
                        </div>
                    );
                }}

            />
        );
    }
}


import ComponentDescription from "components/customs/ComponentDescription";
import ComponentHeader from "components/cms/ComponentHeader";
import { VideoDefinitions } from "./VideoDefinitions";

export class Video extends VideoDefinitions {
  render() {
    const { className, designing } = this.props;
    if (this.props.hideRuntime && !this.props.designing) return null;

    return (
      <div
        data-schema-id={this.props.id}
        className={[
          "fa-Video fa-draggable",
          designing && "designing",
          className,
        ]
          .filter((e) => !!e)
          .join(" ")}
      >
        {designing && (
          <ComponentHeader
            componentEntry={this.props.entry}
            componentSchemaId={this.props.id as string}
          />
        )}
        <ComponentDescription
          node={this.props.schemaNode}
          entry={this.props.entry}
        />
      </div>
    );
  }
}

import React from 'react'
import ComponentDescription from 'components/customs/ComponentDescription';
import Droppable from 'components/customs/Designer/Droppable';
import ComponentHeader from 'components/cms/ComponentHeader';
import { ColumnDefinitions } from './ColumnDefinitions';

export default class ColumnDesigner extends ColumnDefinitions {

    renderInformation() {
        if ((this.props.children && this.props.children.length > 0) || !this.props.defaultElement) {
            return null;
        }
        return (
            <ComponentDescription
                node={this.props.schemaNode}
                entry={this.props.entry}
            />
        )
    }

    render() {
        const noSize = !this.props.sizes?.sm || !this.props.sizes?.md || !this.props.sizes?.xs;

        return (
            <React.Fragment>
                {this.renderInformation()}
                <ComponentHeader
                    componentEntry={this.props.entry}

                    key={this.props.id}
                    titleRender={() => {
                        return (
                            noSize ? "Auto" : `${this.props.sizes?.md} / 12`
                        )
                    }}
                    componentSchemaId={this.props.id as string}
                    componentIsColumn={!this.props.defaultElement}
                    componentSize={this.props.sizes?.md}
                    componentIsDefault={this.props.defaultElement}
                />
                <Droppable
                    elementId={this.props.id as string}
                    elementType="column"
                    dragDropContext={(this.props as any).dragDropContext}
                    style={{ alignContent: "baseline", paddingBottom: "50px" }}
                    className="fa-droppable-act row">
                    {this.props.children}
                </Droppable>
            </React.Fragment>
        )
    }
}

import * as React from "react";
import FileManager from '../FileManager/FileManager';
import { BaseFieldProps } from './types';
import { PureStringField } from './Pure/PureStringField';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import { FileTypes } from "app/services/file-manager";
import { validateUrl } from "app/shared/functions";
import { getFilePath } from "app/utils/service";

interface ImageFieldState {
    fileManagerActive: boolean;
}

// Kendisine gelen Property ismine ve değerine göre Image Field render eder.
export default class ImageField extends React.Component<
    BaseFieldProps,
    ImageFieldState
> {
    constructor(props: BaseFieldProps) {
        super(props);
        this.state = {
            fileManagerActive: false
        };
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        const file = this.props.value;
        const splitted = file?.split(".");
        const imageSource = validateUrl(file) ? file : (getFilePath() + file + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=200&height=200" : ""));
        return (
            <React.Fragment>
                <FileManager
                    activeFile={this.props.value}
                    onSelectedFile={(file) => {
                        this.props.onChange(file.fileName)
                    }}
                    fileType={FileTypes.Image}
                    show={this.state.fileManagerActive}
                    onDismiss={() => {
                        this.setState({
                            fileManagerActive: false
                        })
                    }}
                />
                <div className="row">
                    <PureStringField
                        label={this.props.property.name}
                        text={this.props.property.text as string}
                        description={this.props.property.description as string}
                        value={this.props.value}
                        onChange={value => this.props.onChange(value)}
                        className="image-field"
                        imageSource={this.props.value ? imageSource : ""}
                        customRender={() => {
                            return (
                                <div style={{ marginLeft: "auto" }} className="row">
                                    <DefaultButton
                                        className="custom-button"
                                        text="Görüntüle"
                                        style={{ marginTop: 2.5 }}
                                        disabled={!this.props.value}
                                        onClick={() => {
                                            window.open(imageSource, "_blank")
                                        }}
                                    />
                                    <PrimaryButton
                                        className="custom-button"
                                        text="Görsel Seç"
                                        style={{ marginTop: 2.5 }}
                                        onClick={() => {
                                            this.setState({
                                                fileManagerActive: true
                                            }, () => {
                                                document.querySelector(".external-panel")?.classList.add("fade");
                                            });
                                        }}
                                    />
                                </div>
                            )
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

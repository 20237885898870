import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { PropertyDefinition } from "app/library/layout-builder";
import { StandEnquiryFormProps } from './types';

export class StandEnquiryFormDefinitions extends CmsReactComponent<StandEnquiryFormProps> {
  static defaultProps = {};

  static schemaName = "stand-enquiry-form";

  static propertyDefinitions: PropertyDefinition[] = []
}

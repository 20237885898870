import { ICategory } from 'app/services/category';
import { IList } from 'app/shared';

export interface CategoryStorage {
    categories: IList<ICategory[]>;
}

export const SET_CATEGORIES = 'SET_CATEGORIES';

interface SetCategories {
    type: typeof SET_CATEGORIES;
    categories: IList<ICategory[]>;
}

export type CategoryActionTypes = SetCategories;
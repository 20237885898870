import { SchemaObject } from "app/library/layout-builder";

export const DEFAULT_SCHEMA: SchemaObject[] = [{
    definitions: {
        id: "layoutScreen1",
        name: "layoutScreen1",
    },
    name: "layout-screen",
    props: {
        defaultElement: true
    },
    options: {},
    source: {},
    children: []
}];
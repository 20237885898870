import { IContentType } from 'app/services/content-type';
import { IList } from 'app/shared';

export interface ContentTypeStore {
    contentType: IContentType;
    contentTypes: IList<IContentType[]>;
}

export const SET_CONTENT_TYPES = 'SET_CONTENT_TYPES';
export const SET_CONTENT_TYPE = 'SET_CONTENT_TYPE';

interface SetContentTypes {
    type: typeof SET_CONTENT_TYPES;
    contentTypes: IList<IContentType[]>;
}

interface SetContentType {
    type: typeof SET_CONTENT_TYPE;
    contentType: IContentType;
}

export type ContentTypeActionTypes = SetContentTypes | SetContentType;
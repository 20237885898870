import { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getContentTypes } from 'app/services/content-type';
import { RootState } from 'app/redux/reducer';
import PageTitle from 'components/customs/PageTitle';
import PageLayout from 'containers/PageLayout';
import { getMenuItems, getPageDefinition } from 'app/shared/functions/menu';
import { MenuProperty } from 'app/shared';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
    return {
        ...ownProps,
        userInfo: state.system.userInfo,
        language: state.system.language,
        contentTypes: state.contentType.contentTypes,
        counter: state.system.counter
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            getContentTypes
        }, dispatch)
    };
}

type DashboardProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Dashboard extends Component<DashboardProps> {

    renderContentManagementItems() {
        return (
            <div className="dashboard-items dashboard-items-content-types row">
                <div className="title">
                    Hızlı Erişim | İçerik Yönetim Ekranları
                    <div className="title-text">Aşağıdan içerik yönetimi için sık kullanılan yönetim ekranlarına ulaşabilirsiniz.</div>
                </div>
                <div className="childrens row">
                    {getMenuItems().filter(x => x.properties?.includes(MenuProperty.Frequently)).map((item, index) => (
                        <div key={index} className={`dashboard-item col-md-3`}>
                            <Link className="row" to={item.url ? item.url : "/"}>
                                <div className="icon">
                                    <i className={item.icon}></i>
                                </div>
                                <div className="title">
                                    {item.title}
                                    <div className="sub-text">
                                        {item.text}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    render() {
        const activeTab = getPageDefinition("/");

        return (
            <PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
                <div className="page push-all">
                    <PageTitle
                        title="Saha Expo | İçerik Yönetim Sistemi"
                        subTitle={activeTab?.text || "Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."}
                    />
                    {this.renderContentManagementItems()}
                </div>
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
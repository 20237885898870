import * as React from "react";
import { DefaultButton, IDropdownOption } from 'office-ui-fabric-react';
import _ from 'lodash';

import { DynamicFilterItem } from "./DynamicFilterItem";

import { FilterField, FilterType, HttpStatusCode } from "app/shared";
import { ContentTypeService } from "app/services/content-type";

interface BaseDynamicFilterState {
    specificationTypes: IDropdownOption[];
}

interface BaseDynamicFilterProps {
    value: FilterField[];
    onChange: (value: FilterField[]) => void;
    hideTitle?: boolean;
}

// Kendisine gelen Property ismine ve değerine göre render eder.
export default class BaseDynamicFilter extends React.Component<BaseDynamicFilterProps, BaseDynamicFilterState> {

    state = {
        specificationTypes: []
    }

    componentDidMount() {
        this.getContentTypes();
    }

    async getContentTypes() {
        const service = new ContentTypeService();
        const response = await service.getContentTypes({ page: 1, pageSize: 100, descending: false });
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map(item => ({ key: item.id, text: item.title, data: FilterType.ContentType }));
            this.setState({
                specificationTypes: [...this.state.specificationTypes, ...mapped]
            })
        }
    }

    getTypes() {
        let options: IDropdownOption[] = [{
            key: FilterType.Brand,
            text: "Markalar",
            data: FilterType.Brand
        },
        {
            key: FilterType.Category,
            text: "Kategoriler",
            data: FilterType.Category
        }];

        options = [...options, ...this.state.specificationTypes];

        options = _.sortBy(options, ["text"], ["asc"])

        return options;
    }

    onChangeData = (key: string, value: string, index: number) => {
        const list = _.cloneDeep(this.props.value) || [];
        if (list) {
            if (!list[index]) {
                list[index][key] = value;
            }
            list[index][key] = value;
            this.props.onChange(list);
        }
    }

    onChangeObject = (data: FilterField, index: number) => {
        const list = _.cloneDeep(this.props.value) || [];
        if (list) {
            if (!list[index]) {
                list[index] = data;
            }
            list[index] = data;
            this.props.onChange(list);
        }
    }

    onRemove = (index: number) => {
        const list = _.cloneDeep(this.props.value) || [];
        if (list) {
            list.splice(index, 1)
            this.props.onChange(list)
        }
    }

    renderItems() {
        return (this.props.value || []).map((item: FilterField, index: number) => {
            return (
                <DynamicFilterItem
                    key={index}
                    data={item}
                    index={index}
                    onChange={this.onChangeData}
                    onChangeObject={this.onChangeObject}
                    onRemove={this.onRemove}
                    specificationTypes={this.getTypes()}
                    hideTitle={this.props.hideTitle}
                />

            )
        })
    }

    renderButton() {
        return (
            <DefaultButton
                text="Yeni Filtre Ekle"
                style={{ marginTop: "7.5px" }}
                onClick={() => {
                    const list = this.props.value || [];
                    list.push({ title: "", name: "", type: "", value: "", collection: "" });
                    this.props.onChange(list)
                }}
            />
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderItems()}
                {this.renderButton()}
            </React.Fragment>
        );
    }
}

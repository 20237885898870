import { ComboBox, DefaultButton, Dropdown, Icon, Label, Modal, PrimaryButton, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { IGlobalDataWizardMapping } from './types';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { MetaTypeEnum } from 'app/shared';
import _ from "lodash";
import AutoComplete from '../AutoComplete';
import { constructLocalDataWizard, querySortOptions } from './constants';
import { getContentType, IContentType } from 'app/services/content-type';
import { getQuery, IQueryBuilder } from 'app/services/query-builder';
import { IShowcase } from 'app/services/post';
import { RootState } from 'app/redux/reducer';
import { setModalData, toggleModal } from 'app/redux/modal/actions';
import { castThunkAction } from 'app/utils/casting';
import { LocalDataWizardModalData } from 'app/redux/modal/types';

function mapStateToProps(state: RootState) {
    return {
        data: state.modal.localDataWizard.data,
        show: state.modal.localDataWizard.show,
        contentTypes: state.contentType.contentTypes
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            toggleModal,
            setModalData,
            getContentType,
            getQuery
        },
        dispatch
    );
}

type ModalProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface ModalState {
    activeContentType: IContentType;
    showcases: IShowcase[];
}

class LocalDataWizardModal extends React.Component<ModalProps, ModalState> {

    state = {
        activeContentType: {} as IContentType,
        showcases: [] as IShowcase[]
    }

    componentWillReceiveProps(nextProps: ModalProps) {
        if (nextProps.show && nextProps.show !== this.props.show) {
            if (nextProps.data.query.id) {
                castThunkAction<IQueryBuilder>(this.props.getQuery(nextProps.data.query.id)).then(queryBuilderResponse => {
                    this.props.setModalData("localDataWizard", {
                        ...this.props.data,
                        query: queryBuilderResponse || constructLocalDataWizard()
                    });
                    if (queryBuilderResponse) {
                        castThunkAction<IContentType>(this.props.getContentType(queryBuilderResponse.contentType, false, true)).then(contentTypeResponse => {
                            this.setState({
                                activeContentType: contentTypeResponse
                            })
                        })
                    }
                })
            }
        }
    }

    onClose() {
        this.props.toggleModal("localDataWizard", {});
    }

    onSave() {
        if (this.props.data.callback)
            this.props.data.callback(this.props.data.query);
    }

    getPostFields() {
        return [{
            name: "post.id",
            type: MetaTypeEnum.Text
        },
        {
            name: "post.title",
            type: MetaTypeEnum.Text
        },
        {
            name: "post.url",
            type: MetaTypeEnum.Link
        },
        {
            name: "post.profileImage",
            type: MetaTypeEnum.SingleImage
        },
        {
            name: "post.pricing",
            type: MetaTypeEnum.Pricing
        }]
    }

    getMappingValues(types: MetaTypeEnum[]): string[] {
        let array = [...this.getPostFields().filter(x => types.includes(x.type)).map(x => x.name)];
        if (this.state.activeContentType.metaFields?.length > 0) {
            array = [...array, ...(this.state.activeContentType?.metaFields || []).filter(x => types.includes(x.type as MetaTypeEnum)).map(x => "meta." + x.name)]
        };
        if (this.state.activeContentType.specifications?.length > 0) {
            array = [...array, ...(this.state.activeContentType?.specifications || []).filter(x => types.includes(x.type as MetaTypeEnum)).map(x => "spec." + x.name)]
        };
        return array;
    }

    setData(key: string, value: any) {
        this.props.setModalData<LocalDataWizardModalData>("localDataWizard", {
            ...this.props.data,
            query: {
                ...this.props.data.query,
                [key]: value
            }
        })
    }

    renderMappingForm(mapping: IGlobalDataWizardMapping, onChange: (key: string, value: any) => void) {
        return (
            <div className="mapping row">
                <TextField
                    className="small-element custom-textfield col"
                    label="Card Başlık"
                    value={mapping["title"]}
                    onChange={(event: any) => {
                        onChange("title", event.target.value)
                    }}
                />
                <TextField
                    className="small-element custom-textfield col"
                    label="Card Açıklama"
                    value={mapping["description"]}
                    onChange={(event: any) => {
                        onChange("description", event.target.value)
                    }}
                />
                <TextField
                    className="small-element custom-textfield col"
                    label="Card Görsel"
                    value={mapping["image"]}
                    onChange={(event: any) => {
                        onChange("image", event.target.value)
                    }}
                />
            </div>
        )
    }

    renderContentTypeSelector() {
        return (
            <div className="wizard-item">
                <div className="title">Bir İçerik Tipi Seçiniz
                    <div className="text">Seçtiğiniz içerik tipi'nin içeriği ve meta'ları veri eşleme'deki değer kısmının karşılığı olacaktır.</div>
                </div>
                <div style={{ marginTop: 6 }} className="wizard-item-content">
                    <Dropdown
                        className="custom-dropdown"
                        calloutProps={{
                            className: "custom-dropdown-callout"
                        }}
                        options={(this.props.contentTypes.list || []).map(item => ({ key: item.id as string, text: item.title }))}
                        selectedKey={this.props.data.query?.contentType || ""}
                        onChange={(_, opt) => {
                            this.props.setModalData("localDataWizard", {
                                ...this.props.data,
                                query: {
                                    ...constructLocalDataWizard(),
                                    id: this.props.data.query.id,
                                    contentType: opt?.key,
                                    mappingFields: (this.props.data.query.mappingFields || []).map(x => ({ key: x.key, value: "" }))
                                }
                            });
                            castThunkAction<IContentType>(this.props.getContentType(opt?.key as string, false, true)).then(contentTypeResponse => {
                                this.setState({
                                    activeContentType: contentTypeResponse
                                })
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    renderShowcaseSelector() {
        return (
            <div className="wizard-item col-md-6">
                <div className="title">Bir Vitrin Seçiniz (Opsiyonel)
                    <div className="text">İçeriklerinizi, bir vitrin üzerinde seçilen kayıtlara göre getirebilirsiniz.</div>
                </div>
                <div style={{ marginTop: 6 }} className="wizard-item-content">
                    <ComboBox
                        className="custom-combobox"
                        calloutProps={{
                            className: "custom-combobox-callout"
                        }}
                        options={(this.state.showcases || []).map(item => ({ key: item.id as string, text: item.title }))}
                        selectedKey={this.props.data.query?.showcase || ""}
                        onChange={(_, opt) => {
                            this.props.setModalData("localDataWizard", {
                                ...this.props.data,
                                query: {
                                    ...this.props.data.query,
                                    showcase: opt?.key
                                }
                            });
                        }}
                        allowFreeform
                    />
                </div>
            </div>
        )
    }

    renderMapping() {
        return (
            <div className="wizard-item mapping-list">
                <div className="title">Veri Eşleme
                    <div className="text">Veriler eşlediğiniz karşılıklara göre dönecektir.</div>
                </div>
                <div style={{ marginTop: 6 }} className="wizard-item-content">
                    {(this.props.data.node.getOption("mappingFields") || []).map((nodeMapping: { name: string, text: string, types: MetaTypeEnum[] }, index: number) => {
                        return (
                            <div key={index} className="mapping row">
                                <TextField
                                    className="small-element custom-textfield col"
                                    label={nodeMapping.text}
                                    readOnly
                                    value={nodeMapping.name}
                                />
                                <div className="col">
                                    <Label style={{ marginBottom: "1px !important" }}>Eşlenecek Değer</Label>
                                    <AutoComplete
                                        placeholder="Eşlenecek bir değer seçin"
                                        selectedValue={this.props.data.query?.mappingFields?.find(x => x.key === nodeMapping.name)?.value || ""}
                                        onSelected={(value) => {
                                            const fields = _.cloneDeep(this.props.data.query?.mappingFields || []);
                                            const findIndex = fields?.findIndex(x => x.key === nodeMapping.name);
                                            if (findIndex !== -1) {
                                                fields[findIndex].value = value;
                                            }
                                            else {
                                                fields.push({
                                                    key: nodeMapping.name,
                                                    value
                                                })
                                            }
                                            this.setData("mappingFields", fields);
                                        }}
                                        filterData={this.getMappingValues(nodeMapping.types)}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderPagerOptions() {
        return (
            <div className="wizard-item mapping-list">
                <div className="title">Sayfalama Ayarları
                    <div className="text">Verilerin sayfa başına veri sayısını ve hangi sayfadan başlayacağını belirleyebilirsiniz.</div>
                </div>
                <div className="wizard-item-content">
                    <div style={{ marginTop: 10 }} className=" row">
                        <TextField
                            className="small-element custom-textfield col"
                            label="Sayfa Başına Çekilecek Veri Sayısı"
                            type="number"
                            onChange={(event: any) => {
                                this.setData("pageSize", parseInt(event.target.value))
                            }}
                            value={this.props.data.query?.pageSize || "0" as any}
                        />
                        <TextField
                            className="small-element custom-textfield col"
                            label="İçerikler Hangi Sayfadan Başlayacak?"
                            type="number"
                            onChange={(event: any) => {
                                const value = parseInt(event.target.value);
                                this.setData("pageIndex", value > 0 ? value - 1 : 0)
                            }}
                            value={(this.props.data.query?.pageIndex + 1 || "1") as string}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderSortOptions() {
        const orderFields = [...this.props.data.query?.orderFields || [], { field: "", sort: "Asc" }]
        return (
            <div className="wizard-item mapping-list">
                <div className="title">Sıralama Ayarları
                    <div className="text">Verilerin sıralama yöntemini ayarlayabilirsiniz.</div>
                </div>
                <div className="wizard-item-content">
                    {orderFields.map((item, index) => {
                        return (
                            <div key={index} className="mapping row">
                                <Dropdown
                                    className="custom-dropdown col"
                                    calloutProps={{
                                        className: "custom-dropdown-callout"
                                    }}
                                    label="Sıralama Değeri"
                                    options={querySortOptions}
                                    selectedKey={item.field || ""}
                                    onChange={(ev, opt) => {
                                        const fields = _.cloneDeep(this.props.data.query.orderFields || []);
                                        if (fields[index]) {
                                            fields[index].field = opt?.key as string;
                                        }
                                        else {
                                            fields[index] = {
                                                field: opt?.key as string,
                                                sort: "Asc"
                                            }
                                        }
                                        this.setData("orderFields", fields);
                                    }}
                                />
                                <Dropdown
                                    className="custom-dropdown col"
                                    calloutProps={{
                                        className: "custom-dropdown-callout"
                                    }}
                                    disabled={!item.field || item.field === 'random'}
                                    label="Sıralama Yöntemi"
                                    options={[{ key: "Asc", text: "Ascending (A - Z)" }, { key: "Desc", text: "Descending (Z - A)" }]}
                                    selectedKey={item.sort || ""}
                                    onChange={(ev, opt) => {
                                        const fields = _.cloneDeep(this.props.data.query.orderFields || []);
                                        fields[index].sort = opt?.key as string;
                                        this.setData("orderFields", fields);
                                    }}
                                />
                                <div onClick={() => {
                                    const fields = _.cloneDeep(this.props.data.query.orderFields);
                                    fields.splice(index, 1);
                                    this.setData("orderFields", fields);
                                }} className="remove">
                                    <i className="icon-close1"></i>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        )
    }

    renderModalContent() {
        return (
            <div className="data-wizard-content">
                {this.renderContentTypeSelector()}
                {this.renderMapping()}
                {this.renderPagerOptions()}
                {this.renderSortOptions()}
            </div>
        )
    }

    render() {
        if (!this.props.show) return null;
        return (
            <Modal isOpen={true} className="general-modal custom full-modal data-wizard-modal">
                <div className="title-container">
                    <div className="title-left">
                        <div className="title">
                            İç Veri - Sorgu Sihirbazı
                        </div>
                        <div className="description">
                            Aşağıdan kriterleri ayarlayarak verinizi yönetebilirsiniz.
                        </div>
                    </div>
                    <div className="title-right" onClick={() => this.onClose()}>
                        <Icon iconName="ChromeClose" />
                    </div>
                </div>
                <div className="modal-content">
                    <div className="modal-scrollable-content">
                        {this.renderModalContent()}
                    </div>
                    <div className="modal-indicators" style={{ padding: "15px", display: "flex", justifyContent: "flex-end" }}>
                        <PrimaryButton
                            text="Sihirbazı Tamamla" styles={{ root: { marginLeft: "10px", marginTop: "10px" } }}
                            onClick={() => {
                                this.onSave();
                            }}
                        />
                        <DefaultButton text="İptal" styles={{ root: { marginLeft: "10px", marginTop: "10px" } }} onClick={() => this.onClose()} />
                    </div>
                </div>
            </Modal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalDataWizardModal);

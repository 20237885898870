import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";
import { ContentGridProps } from './types';

export class ContentGridDefinitions extends CmsReactComponent<ContentGridProps> {

    static schemaName = "content-grid";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "firstContent",
            text: "Birincil İçerik",
            mapping: {
                type: PropertyMappingType.Html
            },
            default: "",
        },
        {
            name: "secondContent",
            text: "İkincil İçerik",
            mapping: {
                type: PropertyMappingType.Html
            },
            default: "",
        }
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [MetaTypeEnum.Editor, MetaTypeEnum.Text, MetaTypeEnum.TextArea]
        },
    };
}

import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { ROUTES } from 'app/routes';
import Loader from 'components/customs/Loader/Loader';
import MessageBox from 'components/customs/MessageBox';
import ConfirmBox from 'components/customs/ConfirmBox';
import Authentication from './Authentication';


export default class Router extends React.Component {

  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Authentication>
          {ROUTES.map((route, index) => (
            <Route key={index} exact={route.exact} path={route.path} component={route.component} />
          ))}
        </Authentication>
        <Loader />
        <MessageBox />
        <ConfirmBox />
      </BrowserRouter>
    )
  }
}

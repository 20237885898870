import React, { Component } from 'react'
import { DefaultButton, PrimaryButton, TextField } from 'office-ui-fabric-react'
import FormAccordion from '../FormAccordion'
import { IPublishBar } from './Sidebar'
import { StatusEnum } from 'app/shared';

class PublishBar extends Component<IPublishBar> {

	render() {
		return (
			<div className="bar-category">
				<FormAccordion title="Yayımla">
					<React.Fragment>
						<div className="content">
							<div className="top-buttons row">
								<DefaultButton
									text="Taslak Olarak Kaydet"
									style={{ margin: !this.props.hidePreviewButton ? "0" : "0 auto 0 0" }}
									onClick={() => this.props.onSave ? this.props.onSave(true) : {}}
								/>
								{!this.props.hidePreviewButton && (
									<DefaultButton
										onClick={() => this.props.onClickPreview ? this.props.onClickPreview() : {}}
										text="Önizleme"
									/>
								)}
							</div>
							{this.props.showOrder && (
								<div className="item">
									<div className="ms-Label">
										İçerik Sıralaması
									</div>
									<TextField
										className="custom-textfield"
										type="number"
										styles={{ root: { marginTop: "5px !important" } }}
										value={this.props.order?.toString()}
										onChange={(event: any) => this.props.onChangeOrderIndex ? this.props.onChangeOrderIndex(parseInt(event.target.value)) : {}}
									/>
								</div>
							)}
							{this.props.showIntegration && (
								<div className="item">
									<div className="ms-Label">
										Entegrasyon Değeri
								</div>
									<TextField
										className="custom-textfield"
										styles={{ root: { marginTop: "5px !important" } }}
										value={this.props.integrationId?.toString()}
										onChange={(event: any) => this.props.onChangeIntegrationId ? this.props.onChangeIntegrationId(event.target.value) : {}}
									/>
								</div>
							)}
							<div className="item row">
								<i className="icon-test"></i>
								Durum: <strong>{this.props.status === 2 ? "Taslak" : "Yayımda" || "Yayımlanmamış"}</strong>
							</div>
							<div className="item row">
								<i className="icon-profile"></i>
								{this.props.userLabel || "Ekleyen Kullanıcı:"}
								<strong>{this.props.userName ? this.props.userName : "-"}</strong>
							</div>
							<div className="item row">
								<i className="icon-date-start"></i>
								{this.props.publishDateText || "Yayınlanma tarihi:"} <strong>{this.props.dateText || "Hemen"}</strong>
							</div>
						</div>
						<div className="footer row">
							{(!this.props.hideDeleteButton && (
								<div onClick={() => this.props.onDelete ? this.props.onDelete() : {}} className="trigger red">
									Çöp Kutusuna Taşı
								</div>
							))}
							<PrimaryButton
								text={this.props.status === StatusEnum.Published ? "Güncelle" : "Yayımla"}
								onClick={() => this.props.onSave ? this.props.onSave() : {}}
								className="m-left-auto"
								disabled={this.props.disabledSaveButton}
							/>
						</div>
					</React.Fragment>
				</FormAccordion>
			</div>
		)
	}
}

export default PublishBar;
import { Component } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Label, PrimaryButton, TextField } from 'office-ui-fabric-react';
import _ from 'lodash';
import { ProfileImage } from 'app/shared';
import FileManager from '../FileManager/FileManager';
import { FileTypes, IFile } from 'app/services/file-manager';
import { getFilePath } from 'app/utils/service';
import { validateUrl } from 'app/shared/functions';
import { SRC_SIZES } from 'app/shared/constants';

interface ImageSettingsModalProps {
    show: boolean;
    data: ProfileImage | null;
    onSave: (data: ProfileImage | null) => void;
    onDismiss: () => void;
}

interface MetaGalleryModalState {
    data: ProfileImage | null;
    activeModalIndex: number;
    activeProfileModal: boolean;
}

export default class ImageSettingsModal extends Component<ImageSettingsModalProps, MetaGalleryModalState> {

    state = {
        data: this.props.data || null,
        activeModalIndex: -1,
        activeProfileModal: false
    }

    UNSAFE_componentWillReceiveProps(nextProps: ImageSettingsModalProps) {
        if (nextProps.show && nextProps.show !== this.props.show) {
            this.setState({
                data: nextProps.data
            })
        }
    }

    getFileByIndex(index: number): string {
        return this.state.data ? (this.state.data?.srcset || [])[index]?.src : ""
    }

    getData(): ProfileImage {
        if (!this.state.data || _.keys(this.state.data)?.length === 0) {
            return {
                src: "",
                title: "",
                alt: "",
                srcset: []
            }
        }
        return this.state.data;
    }

    renderFileManager() {
        return (
            <FileManager
                show={(this.state.activeModalIndex !== -1 || this.state.activeProfileModal) ? true : false}
                onDismiss={() => this.setState({ activeModalIndex: -1, activeProfileModal: false })}
                activeFile={this.state.data ? { fileName: this.state.activeProfileModal ? this.state.data?.src : this.getFileByIndex(this.state.activeModalIndex) } : {} as IFile}
                onSelectedFile={(file) => {
                    const data = this.getData()
                    if (this.state.activeProfileModal) {
                        data.src = file.fileName;
                        data.alt = file.fileName;
                        data.title = file.fileName;
                    }
                    else {
                        const list = data?.srcset || [];
                        if (list) {
                            if (!list[this.state.activeModalIndex]) {
                                list[this.state.activeModalIndex] = { src: "", width: "" }
                            }
                            list[this.state.activeModalIndex]["src"] = file.fileName;
                        }
                    }
                    this.setState({
                        data
                    })

                }}
                fileType={FileTypes.Image}
            />
        )
    }

    renderProfileImage() {
        const image = this.state.data ? this.state.data.src : "";
        const splitted = image?.split(".");
        const imageSource = validateUrl(image) ? image : (getFilePath() + image + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=50&height=50" : ""));
        return (
            <div style={{ marginTop: "-2px" }} className="image-field source-field row">
                {image && (
                    <img className="image-string-field" src={imageSource} alt="" />
                )}
                <TextField
                    className="custom-textfield col no-padding"
                    value={image}
                    onChange={(event: any) => {
                        const data = this.getData()
                        data["src"] = event.target.value
                        this.setState({ data })
                    }}
                />
                <PrimaryButton
                    className="custom-button"
                    text="Görsel Seç"
                    onClick={() => {
                        this.setState({ activeProfileModal: true })
                    }}
                    styles={{
                        root: {
                            background: "#33926e !important",
                            borderColor: "#33926e !important"
                        },
                        rootDisabled: {
                            opacity: 0.3,
                            background: "#ececec",
                            color: "#000"
                        }
                    }}
                />
            </div>
        );
    }

    renderImage(index: number) {
        const image = this.state.data ? (this.state.data?.srcset || [])[index]?.src : "";
        const splitted = image?.split(".");
        const imageSource = validateUrl(image) ? image : (getFilePath() + image + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=50&height=50" : ""));
        return (
            <div style={{ marginTop: "-2px" }} className="image-field source-field row">
                {image && (
                    <img className="image-string-field" src={imageSource} alt="" />
                )}
                <TextField
                    className="custom-textfield col no-padding"
                    value={image}
                    onChange={(event: any) => {
                        const data = _.cloneDeep(this.state.data);
                        const list = data?.srcset || [];
                        if (list) {
                            if (!list[index]) {
                                list[index] = { width: "", src: "" }
                            }
                            list[index]["src"] = event.target.value;
                            this.setState({ data })
                        }
                    }}
                />
                <PrimaryButton
                    className="custom-button"
                    text="Görsel Seç"
                    style={{ marginTop: 2.5 }}
                    onClick={() => {
                        this.setState({ activeModalIndex: index })
                    }}
                    styles={{
                        root: {
                            background: "#33926e !important",
                            borderColor: "#33926e !important"
                        },
                        rootDisabled: {
                            opacity: 0.3,
                            background: "#ececec",
                            color: "#000"
                        }
                    }}
                />
                <span
                    onClick={() => {
                        const data = _.cloneDeep(this.state.data);
                        const list = data?.srcset || [];
                        if (list) {
                            list.splice(index, 1)
                            this.setState({ data })
                        }
                    }}
                    className="active remove"
                >
                    <i className="icon-delete"></i>
                </span>
            </div>
        );
    }

    renderItems() {
        return (this.getData().srcset || []).map((item, index) => {
            return (
                <div
                    key={index}
                    className="meta-form row"
                >
                    <Dropdown
                        label="Görsel Genişliği (ÖR: 1024px)"
                        className="custom-dropdown col no-padding"
                        selectedKey={item.width}
                        onChange={(event, option) => {
                            const data = _.cloneDeep(this.state.data);
                            const list = data?.srcset;
                            if (list) {
                                if (!list[index]) {
                                    list[index] = { width: "", src: "" }
                                }
                                list[index]["width"] = option?.key as string;
                                console.log("list", list)
                                this.setState({ data })
                            }
                        }}
                        calloutProps={{ className: "custom-dropdown-callout" }}
                        options={SRC_SIZES}
                    />
                    <div className="col-md-9">
                        <Label>Görsel Adresi</Label>
                        {this.renderImage(index)}
                    </div>
                </div>
            )
        })
    }

    renderButton() {
        return (
            <DefaultButton
                text="Çözünürlük & Görsel Ekle"
                style={{ marginTop: "7.5px" }}
                onClick={() => this.setState({ data: { ...this.getData(), srcset: [...(this.getData().srcset || []), { src: "", width: "" }] } })}
            />
        )
    }

    renderForm() {
        return (
            <div className="thumb-title">
                <Label>Ana Görsel</Label>
                {this.renderProfileImage()}
                <div className="row">
                    <TextField
                        className="custom-textfield col-md-6"
                        label="Görsel Başlık"
                        styles={{ root: { paddingRight: "10px !important", paddingLeft: "0px !important" } }}
                        value={this.state.data?.title}
                        onChange={(event: any) => {
                            const data = this.getData()
                            data["title"] = event.target.value
                            this.setState({ data })
                        }}
                    />
                    <TextField
                        className="custom-textfield col-md-6 no-padding"
                        label="Görsel Alt Etiketi"
                        value={this.state.data?.alt}
                        onChange={(event: any) => {
                            const data = this.getData()
                            data["alt"] = event.target.value
                            this.setState({ data })
                        }}
                    />
                </div>

            </div>
        )
    }

    render() {
        if (!this.props.show) return null;

        console.log("this", this.state.data, this.props.data);
        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Görsel Ayarları",
                    closeButtonAriaLabel: 'Kapat',
                    subText: "Görsel ayarlarını ve çözünürlüğe göre görünümlerini yapılandırabilirsiniz.",
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: true,
                    styles: { main: { maxWidth: "1500px !important", minWidth: "1000px !important" } }
                }}
            >
                {this.renderForm()}
                {this.renderItems()}
                {this.renderButton()}
                {this.renderFileManager()}
                <DialogFooter>
                    <DefaultButton onClick={() => {
                        this.setState({
                            data: {} as ProfileImage
                        }, () => {
                            this.props.onDismiss();
                        })
                    }} text="İptal" />
                    <PrimaryButton
                        onClick={() => {
                            this.props.onSave(this.state.data);
                            this.props.onDismiss();
                            this.setState({
                                data: {} as ProfileImage
                            })

                        }}
                        text="Kaydet"
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}

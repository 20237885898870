import * as React from "react";
import { BaseFieldProps } from "./types";
import { Icon } from "office-ui-fabric-react";
import {
    SchemaNode
} from "app/library/layout-builder";
import { getActiveAccordion } from "app/redux/selectors";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "app/redux/reducer";
import { setPanelAccordion } from "app/redux/designer/actions";
import { PureProductField } from "./Pure/PureProductField";
import { toggleModal } from "app/redux/modal/actions";

export interface ProductDateFieldProps {
    node: SchemaNode;
}

function mapStateToProps(state: RootState) {
    return {
        activeAccordions: state.designer.activeAccordions
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            setPanelAccordion,
            toggleModal
        },
        dispatch
    );
}

type AllProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    ProductDateFieldProps &
    BaseFieldProps;

// Kendisine gelen Property ismine ve değerine göre Accordion Item'ları render eder.

class ProductDataField extends React.Component<AllProps> {

    onChangeProperty(value: any, index: number) {
        this.props.value[index] = value;
        console.log("data", value);
        this.props.onChange(this.props.value);
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        console.log("this", this.props);

        return (
            <div className="external-items">
                <div className="fa-Accordion">
                    {(this.props.value || []).map((item: any, key: number) => (
                        <div
                            key={key}
                            className={
                                getActiveAccordion(
                                    this.props.activeAccordions,
                                    this.props.property.name + key
                                ) ===
                                    this.props.property.name + key
                                    ? "fa-Accordion-item active"
                                    : "fa-Accordion-item"
                            }
                        >
                            <div className="title row">
                                <div
                                    onClick={() => {
                                        this.setState(
                                            {
                                                iconPanelActive: ""
                                            },
                                            () => {
                                                this.props.setPanelAccordion(
                                                    this.props.property.name + key
                                                );
                                            }
                                        );
                                    }}
                                    className="text col no-padding"
                                >
                                    {"Ürün " + (key + 1)}
                                </div>
                                <Icon
                                    onClick={() => {
                                        this.props.value.splice(key, 1);
                                        this.props.onChange(this.props.value);
                                    }}
                                    className="m-left-auto remove-accordion"
                                    iconName="ChromeClose"
                                />
                            </div>
                            {getActiveAccordion(
                                this.props.activeAccordions,
                                this.props.property.name + key
                            ) ===
                                this.props.property.name + key && (
                                    <div className="content">
                                        <PureProductField
                                            value={item}
                                            onToggleProductPicker={this.props.toggleModal}
                                            onChange={(data) => {
                                                this.onChangeProperty(data, key)
                                            }}
                                        />
                                    </div>
                                )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDataField);

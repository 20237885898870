import { Component } from "react";
import { connect } from "react-redux";
import * as _yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";
import { History } from "history";

import { RootState } from "app/redux/reducer";
import { setModalData, toggleModal } from "app/redux/modal/actions";
import {
  Checkbox,
  CompoundButton,
  DefaultButton,
  DialogFooter,
  Panel,
  PrimaryButton,
  TextField,
} from "office-ui-fabric-react";
import AbsoluteLoader from "../Loader/AbsoluteLoader";
import { castThunkAction } from "app/utils/casting";
import { MessageType } from "app/redux/system/types";
import { showMessage } from "app/redux/system/actions";
import { slugify } from "app/shared/functions";
import {
  createRole,
  updateRole,
  getRolePermissionList,
  RoleItemEntity,
  RoleTypeEnum,
  RoleModalTypeEnum,
  updateRolePermission,
  SpecialPermissionItemEntity,
} from "app/services/identity";
import BasicTable from "../BasicTable";

interface OwnProps {
  history: History<any>;
}

function mapStateToProps(state: RootState) {
  return {
    ...state.modal.role,
    language: state.system.language,
    modalLoading: state.system.modalLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setModalData,
        toggleModal,
        showMessage,
        updateRole,
        createRole,
        getRolePermissionList,
        updateRolePermission,
      },
      dispatch
    ),
  };
}

type RoleModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface RoleModalState {
  showInnerModal: boolean;
  modalTitle: string;
  modalContent: string;
  type: string;
}

class RoleModal extends Component<RoleModalProps, RoleModalState> {
  state = {
    showInnerModal: false,
    modalTitle: "",
    modalContent: "",
    type: RoleTypeEnum.permissions,
  };

  UNSAFE_componentWillReceiveProps(nextProps: RoleModalProps) {
    // const modalType = nextProps.data.modalType || RoleModalTypeEnum.add;
    if (nextProps.show && nextProps.show !== this.props.show) {
      this.props.getRolePermissionList(nextProps.data.id);
    }
  }

  onClose(updated?: boolean) {
    if (this.props.callback) {
      this.props.callback(updated);
    }
    this.setState({ showInnerModal: !this.state.showInnerModal });
    this.props.toggleModal("role", {});
  }

  onValidate() {
    const data = _.cloneDeep(this.props.data);

    return new Promise((resolve) => {
      let validationSchema = _yup.object<object>({
        name: _yup.string().required("Bir rol adı girmelisiniz"),
      });

      let formErrors = {};

      validationSchema
        .validate(data, {
          abortEarly: false,
        })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          err.inner.forEach((e: any) => {
            const { path, message } = e;

            formErrors[path] = message;
          });

          resolve(formErrors);
        });
    });
  }

  onSave() {
    const modalType = this.props.data.modalType || RoleModalTypeEnum.add;

    this.onValidate().then((resolved) => {
      if (typeof resolved === "boolean" && resolved) {
        if (modalType === RoleModalTypeEnum.permission) {
          castThunkAction(this.props.updateRolePermission()).then((res) => {
            if (res) {
              this.onClose(true);
            }
          });
        } else {
          castThunkAction(
            this.props.data.id
              ? this.props.updateRole() // update method
              : this.props.createRole() // create method
          ).then((res) => {
            if (res) {
              this.onClose(true);
            }
          });
        }
      } else {
        this.props.showMessage(
          "Aşağıdaki bilgilerde eksik ya da hatalı bilgiler bulunmaktadır. Lütfen bilgilerinizi kontrol ediniz.",
          "Eksik veya hatalı bilgiler mevcut",
          MessageType.ERROR,
          () => {
            return (
              <div className="error-list">
                {_.keys(resolved).map((item, index) => (
                  <div key={index} className="error-item">
                    {(resolved as object)[item]}
                  </div>
                ))}
              </div>
            );
          }
        );
      }
    });
  }

  onChangeData(key: string, value: any) {
    this.props.setModalData("role", {
      ...this.props.data,
      [key]: value,
    });
  }

  onChangeModalData(name: string, key: string, value: any, keyType: string) {
    const modalData = this.props.data.modalData;
    if (modalData) {
      const modalDataEdit: RoleItemEntity[] = Object.assign(
        modalData[keyType],
        []
      );

      const findData = modalDataEdit.filter(
        (x: RoleItemEntity) => x.name === name
      );

      console.log(modalDataEdit, "modalDataEdit", findData, {
        ...this.props.data.modalData,
        [keyType]: {
          ...modalDataEdit,
          ...findData,
          [key]: value,
        },
      });

      this.props.setModalData("role", {
        ...this.props.data,
        // modalData: {
        //   ...this.props.data.modalData,
        //   [keyType]: {
        //     ...modalDataEdit,
        //     ...findData,
        //     [key]: value,
        //   },
        // },
      });
    }
  }

  renderForm() {
    const modalType = this.props.data.modalType || RoleModalTypeEnum.add;
    return (
      <div className="modal-form">
        <div className="row reverse-margin">
          <div className="item col-md-12">
            <TextField
              placeholder="Rol Adı"
              value={this.props.data.name || ""}
              label="Rol Adı"
              required
              className="custom-textfield border"
              onChange={(event: any) =>
                this.props.setModalData("role", {
                  ...this.props.data,
                  name: event.target.value,
                  code: slugify(event.target.value),
                })
              }
            />
          </div>
          <div className="item col-md-12">
            <TextField
              placeholder="Rol Kodu"
              value={this.props.data.code || ""}
              disabled
              label="Rol Kodu"
              className="custom-textfield border"
            />
          </div>
          {modalType === RoleModalTypeEnum.permission ? (
            <>
              <div className="item col-md-12">
                <CompoundButton
                  className="custom-compound"
                  secondaryText="Detaylı izin ayarlamalarını gerçekleştirebilirsiniz."
                  onClick={() => {
                    this.setState({
                      showInnerModal: true,
                      modalTitle: "İzin Yönetimi",
                      modalContent:
                        "Detaylı izin ayarlamalarını gerçekleştirebilirsiniz.",
                      type: RoleTypeEnum.permissions,
                    });
                  }}
                >
                  İzin Yönetimi
                </CompoundButton>
              </div>
              <div className="item col-md-12">
                <CompoundButton
                  className="custom-compound"
                  secondaryText="Detaylı özel izin ayarlamalarını gerçekleştirebilirsiniz."
                  onClick={() => {
                    this.setState({
                      showInnerModal: true,
                      modalTitle: "Özel İzin Yönetimi",
                      modalContent:
                        "Detaylı özel izin ayarlamalarını gerçekleştirebilirsiniz.",
                      type: RoleTypeEnum.specialPermissions,
                    });
                  }}
                >
                  Özel İzin Yönetimi
                </CompoundButton>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }

  renderInnerPanel() {
    if (!this.state.showInnerModal) return null;
    const { modalTitle, modalContent, type } = this.state;

    return (
      <Panel
        className="preferences-panel preferences-panel-medium"
        isOpen={this.state.showInnerModal}
        onDismiss={() => this.setState({ showInnerModal: false })}
        headerText={modalTitle}
        onOuterClick={() => {}}
        isLightDismiss
        isBlocking={false}
        style={{
          right: "640px",
        }}
      >
        <div className="sub-text">{modalContent}</div>
        {this.props.data &&
        this.props.data.modalData &&
        this.props.data.modalData[this.state.type] ? (
          <div className="row reverse-margin">
            {type === RoleTypeEnum.permissions && (
              <BasicTable
                className="w100"
                totalCount={0}
                columns={[
                  {
                    columnName: "name",
                    text: "Rol Adı",
                    col: "col-md-4",
                  },
                  {
                    columnName: "read",
                    text: "Okuma İzni",
                    col: "col-md-2",
                    onRender: (item: RoleItemEntity) => (
                      <Checkbox
                        label=""
                        checked={item.read}
                        onChange={(_, value) => {
                          this.onChangeModalData(
                            item.name,
                            "read",
                            value,
                            this.state.type
                          );
                        }}
                      />
                    ),
                  },
                  {
                    columnName: "delete",
                    text: "Silme İzni",
                    col: "col-md-2",
                    onRender: (item: RoleItemEntity) => (
                      <Checkbox
                        label=""
                        checked={item.delete}
                        onChange={(_, value) => {
                          this.onChangeModalData(
                            item.name,
                            "delete",
                            value,
                            this.state.type
                          );
                        }}
                      />
                    ),
                  },
                  {
                    columnName: "create",
                    text: "Ekleme İzni",
                    col: "col-md-2",
                    onRender: (item: RoleItemEntity) => (
                      <Checkbox
                        label=""
                        checked={item.create}
                        onChange={(_, value) => {
                          this.onChangeModalData(
                            item.name,

                            "create",
                            value,
                            this.state.type
                          );
                        }}
                      />
                    ),
                  },
                  {
                    columnName: "update",
                    text: "Güncelleme İzni",
                    col: "col-md-2",
                    onRender: (item: RoleItemEntity) => (
                      <Checkbox
                        label=""
                        checked={item.update}
                        onChange={(_, value) => {
                          this.onChangeModalData(
                            item.name,

                            "update",
                            value,
                            this.state.type
                          );
                        }}
                      />
                    ),
                  },
                ]}
                items={this.props.data.modalData[this.state.type]}
                showPager={false}
              />
            )}
            {type === RoleTypeEnum.specialPermissions && (
              <BasicTable
                className="w100"
                totalCount={0}
                columns={[
                  {
                    columnName: "name",
                    text: "Rol Adı",
                    col: "col-md-6",
                  },
                  {
                    columnName: "read",
                    text: "Açık mı?",
                    col: "col-md-6",
                    onRender: (item: SpecialPermissionItemEntity) => (
                      <Checkbox
                        label=""
                        checked={item.isActive}
                        onChange={(_, value) => {
                          this.onChangeModalData(
                            item.name,
                            "read",
                            value,
                            this.state.type
                          );
                        }}
                      />
                    ),
                  },
                  
                ]}
                items={this.props.data.modalData[this.state.type]}
                showPager={false}
              />
            )}
          </div>
        ) : null}
      </Panel>
    );
  }

  render() {
    const { data, show, modalLoading } = this.props;
    const modalType = this.props.data.modalType || RoleModalTypeEnum.add;

    if (!show) return null;

    return (
      <Panel
        className="preferences-panel"
        isOpen={show}
        onDismiss={() => this.onClose()}
        headerText={modalType === RoleModalTypeEnum.permission ?'Rol İzinlerini Düzenle' : data.id ? "Rol Düzenle" : "Yeni Rol Ekle"}
        onOuterClick={() => {}}
        onRenderFooter={() => {
          return (
            <DialogFooter>
              <DefaultButton onClick={() => this.onClose()} text="İptal" />
              <PrimaryButton
                onClick={() => this.onSave()}
                text={data.id ? "Değişiklikleri Kaydet" : "Rol Oluştur"}
                disabled={modalLoading ? true : false}
              />
            </DialogFooter>
          );
        }}
      >
        {this.renderInnerPanel()}
        <div className="sub-text">
          Aşağıdaki bilgileri doldurarak belirlediğiniz kriterlerde bir rol
          ekleyin ya da düzenleyin.
        </div>
        <AbsoluteLoader show={modalLoading} />
        {this.renderForm()}
      </Panel>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleModal);

import { ThunkAction } from "redux-thunk";
import { RootState } from "app/redux/reducer";
import { Action } from "redux";
import { showMessage, toggleLoader, toggleModalLoader } from "app/redux/system/actions";

import { MessageType } from 'app/redux/system/types';
import { setCategories } from 'app/redux/category/actions';
import { ICategory } from "./types";
import { mapCategoryFromEntity, mapCategoryFromModel } from "./mapping";
import { CategoryService } from "./service";
import { HttpStatusCode } from "app/shared";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getCategory(categoryId: string): ThunkResult<Promise<ICategory | null>> {
    return async (dispatch) => {
        const service = new CategoryService();
        dispatch(toggleModalLoader())
        const response = await service.getCategory(categoryId);
        dispatch(toggleModalLoader())
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapCategoryFromEntity(response.data.attributes["webCategory"] || {});
            return mapped;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return null;
    };
}

export function getCategories(contentTypeId: string, modal?: boolean): ThunkResult<Promise<boolean>> {
    return async (dispatch) => {
        const service = new CategoryService();
        if (modal) {
            dispatch(toggleModalLoader());
        }
        else {
            dispatch(toggleLoader());
        }
        const response = await service.getCategories(contentTypeId);
        if (modal) {
            dispatch(toggleModalLoader());
        }
        else {
            dispatch(toggleLoader());
        }
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map((item) =>
                mapCategoryFromEntity(item)
            );
            dispatch(setCategories({
                list: mapped,
                pageSize: response.data.attributes.pageSize,
                totalCount: response.data.attributes.total,
                pageCount: Math.ceil(response.data.attributes.total / response.data.attributes.pageSize)
            }));
            return true;
        }
        return false;
    };
}

export function getCategorySlug(slug: string): ThunkResult<Promise<string>> {
    return async () => {
        const service = new CategoryService();
        const response = await service.getCategorySlug(slug);
        if (response.status === HttpStatusCode.OK) {
            return response.data.attributes["url"];
        }
        return "";
    };
}

export function createCategory(request: ICategory): ThunkResult<Promise<ICategory | null>> {
    return async (dispatch) => {
        const service = new CategoryService();
        dispatch(toggleModalLoader())
        const response = await service.createCategory(mapCategoryFromModel(request));
        dispatch(toggleModalLoader())
        if (response.status === HttpStatusCode.OK) {
            dispatch(showMessage("Kategori başarıyla oluşturuldu. Liste üzerinden ilgili kategoriyi düzenleyebilirsiniz.", "Tebrikler!", MessageType.SUCCESS))
            return mapCategoryFromEntity(response.data.attributes["post"] || {});
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return null;
    };
}

export function updateCategory(request: ICategory): ThunkResult<Promise<boolean>> {
    return async (dispatch) => {
        const service = new CategoryService();
        dispatch(toggleLoader());
        const response = await service.updateCategory(mapCategoryFromModel(request));
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            dispatch(showMessage("Kategori başarıyla güncellendi. Liste üzerinden ilgili kategoriyi düzenleyebilirsiniz.", "Tebrikler!", MessageType.SUCCESS))
            return true;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}

export function deleteCategory(categoryId: string): ThunkResult<Promise<boolean>> {
    return async (dispatch) => {
        const service = new CategoryService();
        dispatch(toggleLoader());
        const response = await service.deleteCategory(categoryId);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}
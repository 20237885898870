import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { RootState } from "app/redux/reducer";
import {
  showMessage,
  toggleLoader,
  toggleModalLoader,
} from "app/redux/system/actions";
import { initialPageData } from "app/redux/page/actions";
import { MessageType } from "app/redux/system/types";
import { IUser, IUserFilter } from "./types";
import { IList } from "app/shared";
import { UserService } from "./service";
import { setModalData } from "app/redux/modal/actions";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;
const service = new UserService();

export function getUser(userId: string): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleModalLoader());
    const response = await service.getUser(userId);
    if (response.success) {
      const mapped = response.data || {}; // entity'ler ile birlikte mapping işlemi yapılacak!
      dispatch(setModalData("user", mapped));
      dispatch(toggleModalLoader());
      return true;
    }
    dispatch(toggleModalLoader());
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

export function getUsers(options: IUserFilter): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const response = await service.getUsers(options);

    dispatch(toggleLoader());
    if (response.success) {
      const mapped = response.data_list || []; // entity'ler ile birlikte mapping işlemi yapılacak!
      dispatch(
        initialPageData<IList<IUser[]>>({
          list: mapped,
          pageSize: options.pageSize,
          totalCount: response.data_count,
          pageCount: response.page_count,
        })
      );
      return true;
    }
    return false;
  };
}

export function createUser(request: IUser): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleModalLoader());
    const response = await service.createUser({ ...request, username: request.email });
    dispatch(toggleModalLoader());
    if (response.success) {
      dispatch(
        showMessage(
          "Geçerli kayıt sistem üzerine başarıyla kaydedilmiştir.",
          "Ekleme İşlemi Başarılı!",
          MessageType.SUCCESS
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          response.error_message,
          "Bir Hata Oluştu!",
          MessageType.ERROR
        )
      );
      return false;
    }
  };
}

export function updateUser(request: IUser): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleModalLoader());
    const response = await service.updateUser({ ...request, username: request.email });
    dispatch(toggleModalLoader());
    if (response.success) {
      dispatch(
        showMessage(
          "Geçerli kayıt sistem üzerinde başarıyla güncellenmiştir.",
          "Güncelleme İşlemi Başarılı!",
          MessageType.SUCCESS
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          response.error_message,
          "Bir Hata Oluştu!",
          MessageType.ERROR
        )
      );
      return false;
    }
  };
}

export function deleteUser(UserId: string): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const response = await service.deleteUser(UserId);
    dispatch(toggleLoader());
    if (response.success) {
      return true;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

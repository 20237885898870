import { SchemaNode } from 'app/library/layout-builder';
import { IUser } from 'app/services/user';
import { SystemActionTypes, MessageType, SHOW_MESSAGE, SET_PAGE_USER_INFO, HIDE_MESSAGE, TOGGLE_LOADER, SET_LANGUAGE, TOGGLE_VERIFICATION_LOADER, SET_USER_INFO, CLOSE_ALL_TAB, TabTypeEnum, CLOSE_TAB, OPEN_TAB, TOGGLE_ADVANCED, SET_LEFT_PANEL, TOKEN_CONTROL, ConfirmProps, TOGGLE_CONFIRM, TOGGLE_PANEL_LOADER, SET_LAYOUT, TOGGLE_MODAL_LOADER, COPY_COMPONENT, SET_DEFAULT_LANGUAGE, SET_ACCESS_TOKEN, SET_COUNTER } from "./types";

/**
 * Verilen Tiplere Göre Message Bar Gösterir.
 */
export function showMessage(content: string, title?: string, type?: MessageType, onRender?: () => React.ReactNode, hideFooter?: boolean): SystemActionTypes {
    return {
        type: SHOW_MESSAGE,
        content,
        title,
        messageType: type,
        onRender,
        hideFooter
    };
}

/** Message Kutusunu Gizler. */
export function hideMessage(): SystemActionTypes {
    return {
        type: HIDE_MESSAGE
    };
}

export function copyComponent(copiedComponent: SchemaNode): SystemActionTypes {
    return {
        type: COPY_COMPONENT,
        copiedComponent
    };
}

/** Loader Toggle Eder. */
export function toggleLoader(defaultValue?: boolean, loadingText?: string): SystemActionTypes {
    return {
        type: TOGGLE_LOADER,
        defaultValue,
        loadingText
    };
}

export function setAccessToken(token: string): SystemActionTypes {
    return {
        type: SET_ACCESS_TOKEN,
        token
    };
}

export function setCounter(key: string, value: number): SystemActionTypes {
    return {
        type: SET_COUNTER,
        key,
        value
    };
}

/** Modal Loader Toggle Eder. */
export function toggleModalLoader(): SystemActionTypes {
    return {
        type: TOGGLE_MODAL_LOADER
    };
}

/** Eğer ki layout page'indeyse isLayout değerini true yapan action'ı toggle eder. */
export function setLayout(isLayout: boolean): SystemActionTypes {
    return {
        type: SET_LAYOUT,
        isLayout
    };
}

/** Sistemdeki aktif olacak varsayılan dili set eder. */
export function setDefaultLanguage(defaultLanguage: string): SystemActionTypes {
    return {
        type: SET_DEFAULT_LANGUAGE,
        defaultLanguage
    };
}

/** Panel Loader Toggle Eder. */
export function togglePanelLoader(): SystemActionTypes {
    return {
        type: TOGGLE_PANEL_LOADER
    };
}

/** Doğrulama Modal'ını Toggle Eder. */
export function toggleConfirmDialog(show: boolean, properties?: ConfirmProps): SystemActionTypes {
    return {
        type: TOGGLE_CONFIRM,
        show,
        properties
    }
}

/** Advanced Mod'u toggle eder. */
export function toggleAdvanced(): SystemActionTypes {
    return {
        type: TOGGLE_ADVANCED
    };
}

/** Auth Control Anında Verfication Loader Toggle Eder. */
export function toggleVerificationLoader(): SystemActionTypes {
    return {
        type: TOGGLE_VERIFICATION_LOADER
    };
}

/** Sistemin Dilini Set Eder. */
export function setLanguage(language: string): SystemActionTypes {
    return {
        type: SET_LANGUAGE,
        language
    };
}

/** Sistemin Sol Panelini Set Eder. */
export function setLeftPanel(leftPanel?: string): SystemActionTypes {
    return {
        type: SET_LEFT_PANEL,
        leftPanel
    };
}

/** Sistem Kullanıcı Bilgilerini Set Eder. */
export function setUserInfo(userInfo: IUser): SystemActionTypes {
    return {
        type: SET_USER_INFO,
        userInfo
    };
}

/** Tab Tipi ve DatasourceId'ye göre, tab'i aktifleştirir. */
export function openTab(id: string, tabType: TabTypeEnum, datasourceId: string | null = null, reference?: any): SystemActionTypes {
    return {
        type: OPEN_TAB,
        id,
        tabType,
        datasourceId,
        reference
    };
}

/** İlgili tab'i kapatır. */
export function closeTab(id: string, tabType: TabTypeEnum): SystemActionTypes {
    return {
        type: CLOSE_TAB,
        id,
        tabType
    };
}

/** Var olan tüm tableri kapatır. */
export function closeAllTab(): SystemActionTypes {
    return {
        type: CLOSE_ALL_TAB
    }
}


/** Token'in geçerlilik durmunu kontrol eder. */
export function tokenControl(tokenStatus: boolean): SystemActionTypes {
    return {
        type: TOKEN_CONTROL,
        tokenStatus
    }
}


/** sayfadaki user belirler */
export function setPageUserInfo(pageUserInfo: IUser): SystemActionTypes {
    return {
        type: SET_PAGE_USER_INFO,
        pageUserInfo
    }
}
// import React from "react";
import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import {
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { RedirectionProps } from "./types";

export class RedirectionDefinitions extends CmsReactComponent<RedirectionProps> {

    static schemaName = "redirect";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "page",
            text: "URL",
            mapping: {
                type: PropertyMappingType.LinkWizard
            },
        }
    ];
}

import React from 'react'
import { Helmet } from 'react-helmet';

import Logo from 'assets/images/logo.svg';

import { AuthLayoutProps } from './types'

export default class AuthLayout extends React.Component<AuthLayoutProps> {
  componentDidMount() {
    const div = document.getElementsByClassName('auth-page')[0]

    setTimeout(() => {
      div.classList.add('current')
    }, 10);
  }

  render() {
    const {
      children,
      maxWidth = 450,
      navs,
      title,
    } = this.props

    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div className="auth-page-target">
          <div className="auth-page" style={{ maxWidth }}>
            <div className="auth-page-header">
              <img src={Logo} alt="Saha Expo" />
              <div className="auth-page-brand">
                İÇERİK YÖNETİM SİSTEMİ
              </div>
            </div>
            {navs ? (
              <React.Fragment>
                {navs()}
              </React.Fragment>
            ) : null}
            {React.Children.toArray(children).length ? (
              <div className="auth-page-content">
                {title && (
                  <div className="auth-page-title">{title}</div>
                )}
                {children}
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  }
}

import { MetaTypeEnum, ModelBase, EntityBase, ImageSourceSettings, ILinkWizard } from "app/shared";
import { CategoryEntity, ICategory } from "../category";

export enum MetaBindingObjectTypes {
    ContentType = "content-type",
    Specification = "specification",
    CustomService = "custom-service",
    Custom = "custom"
}

export interface IContentType extends ModelBase {
    id?: string;
    title: string;
    key: string;
    removable: boolean;
    layout: string | undefined;
    metaFields: IMeta[];
    specifications: ISpec[];
    filters: IContentTypeFilter[];
    quickFilters: IContentTypeFilter[];
    categories: ICategory[];
}

export interface IContentTypeFilter {
    name: string;
    title: string;
    expanded: boolean;
    order: number;
    type: MetaTypeEnum;
}

export interface ISpec<T = any> {
    type: MetaTypeEnum;
    title: string;
    name: string;
    column: number;
    bindings?: IMetaBindingObject;
    data?: T;
}

export interface IMetaBindingObject {
    type?: MetaBindingObjectTypes;
    contentTypeId?: string;
    specificationTypeId?: string;
    specificationType?: string;
    endpointName?: string;
    customOptions?: { key: string | number; text: string; }[];
}

export interface IMeta<T = any> {
    type: MetaTypeEnum;
    title: string;
    name: string;
    column: number;
    default?: T;
    contentType?: string;
    bindings?: IMetaBindingObject;
    data?: T;
}

export interface ContentTypeEntity extends EntityBase {
    id: string;
    title: string;
    key: string;
    removable: boolean;
    defaultLayoutId: string;
    metaFields: MetaEntity[];
    specifications: SpecEntity[];
    filters: ContentTypeFilterEntity[];
    quickFilters: ContentTypeFilterEntity[];
    categories?: CategoryEntity[];
}

export interface ContentTypeFilterEntity {
    name: string;
    title: string;
    expanded: boolean;
    order: number;
    type: number;
}

export interface MetaEntity {
    type: number;
    title: string;
    name: string;
    column: number;
    bindings: { [key: string]: any; };
    default?: any;
}

export interface SpecEntity {
    type: number;
    title: string;
    name: string;
    column: number;
    bindings: { [key: string]: any; };
}

export interface ContentTypeTree {
    id: string;
    title: string;
    posts: { id: string; title: string; url: string; }[]
}

export interface IMetaIconListItem {
    icon: string;
    text: string;
}

export interface IMetaImage {
    src: string;
    alt: string;
    title?: string;
    srcset?: ImageSourceSettings[];
}

export interface IMetaFile {
    src: string;
    mobile?: string;
    title?: string;
    alt?: string;
    type?: number;
}

export interface IMetaDate {
    day: number;
    month: number;
    year: number;
    fullDate: string;
}

export interface IMetaIcon {
    icon: string;
    text: string;
}

export interface IMetaGallery extends IMetaImage {
    thumbnail: string;
    caption: string;
    subcaption: string;
    external: boolean;
}

export interface IMetaSliderGallery {
    title: string;
    image: IMetaImage;
    thumbnail?: IMetaImage;
    anchor?: ILinkWizard;
}

export interface IMetaDropdown {
    id: string;
    title: string;
    url?: string;
}

export interface IMetaContributor {
    role: IMetaDropdown;
    contributor: IMetaDropdown;
}

export interface IMetaLink extends ILinkWizard { }

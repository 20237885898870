import { convertToPureSchema } from "app/shared/functions";
import { ILayout, LayoutEntity } from "./types";

/**
 * Layout entity schema convert to model
 */
export function mapLayoutFromEntity(layoutEntity: LayoutEntity): ILayout {
    return {
        id: layoutEntity.id,
        title: layoutEntity.title,
        description: layoutEntity.description,
        schema: layoutEntity.designSchemaAdmin,
        status: layoutEntity.status,
        theme: layoutEntity.theme,
        queryIds: layoutEntity.queryIds || [],
        dynamics: layoutEntity.dynamics,
        createdBy: layoutEntity.createdBy,
        language: layoutEntity.language || "en",
        relationId: layoutEntity.languageRelationId || "",
        updatedBy: layoutEntity.modifiedBy,
        createdAt: layoutEntity.createdOn || layoutEntity.CreatedOn,
        updatedAt: layoutEntity.modifiedOn || layoutEntity.ModifiedOn
    }
}

/**
 * Layout model schema convert to entity
 */
export function mapLayoutFromModel(layoutModel: ILayout): LayoutEntity {
    return {
        id: layoutModel.id || "",
        status: layoutModel.status,
        title: layoutModel.title,
        theme: layoutModel.theme || "dark",
        language: layoutModel.language || "en",
        queryIds: layoutModel.queryIds || [],
        languageRelationId: layoutModel.relationId || null,
        dynamics: layoutModel.dynamics,
        description: layoutModel.description,
        designSchemaAdmin: layoutModel.schema,
        designSchemaPureUi: convertToPureSchema(layoutModel.schema)
    }
}


import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";

export class HomeHighlightsDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "home-highlights";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "title",
            text: "Ana Başlık",
            mapping: {
                type: PropertyMappingType.String
            },
        }, {
            name: "highlights",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "description",
                        text: "Metin",
                        mapping: {
                            type: PropertyMappingType.String,
                        }
                    }
                ]
            },
            default: []
        }
    ];
}
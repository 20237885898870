import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";

import { ImageChildrenDefinitions } from 'components/cms/definitions';
import { MetaTypeEnum } from 'app/shared';

export class ExhibitorDetailDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "exhibitor-detail";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: 'companyname',
            text: 'Firma Adı',
            mapping: {
                type: PropertyMappingType.String
            }
        },
        {
            name: 'companylogo',
            text: 'Firma Logosu',
            mapping: {
                type: PropertyMappingType.ParentObject,
                children: ImageChildrenDefinitions
            }
        },
        {
            name: 'aboutthecompany',
            text: 'Firma Hakkında',
            mapping: {
                type: PropertyMappingType.Textarea
            }
        },
        {
            name: 'companyfieldsofactivity',
            text: 'Firma Faaliyet Alanları',
            mapping: {
                type: PropertyMappingType.ArrayData
            }
        },
        {
            name: 'hallno',
            text: 'Salon No',
            mapping: {
                type: PropertyMappingType.String
            }
        },
        {
            name: 'standno',
            text: 'Stant No',
            mapping: {
                type: PropertyMappingType.String
            }
        },
        {
            name: 'web',
            text: 'Web Sitesi',
            mapping: {
                type: PropertyMappingType.LinkWizard
            }
        },
        {
            name: 'phone',
            text: 'Firma Telefonu',
            mapping: {
                type: PropertyMappingType.Number
            }
        },
        {
            name: 'email',
            text: 'Firma E-posta',
            mapping: {
                type: PropertyMappingType.String
            }
        },
        {
            name: 'address',
            text: 'Firma Adres',
            mapping: {
                type: PropertyMappingType.String
            }
        },
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.Text,
                MetaTypeEnum.ComboBox,
                MetaTypeEnum.Editor,
                MetaTypeEnum.Link,
                MetaTypeEnum.SingleImage,
                MetaTypeEnum.SingleVideo
            ]
        }
    };

    // static options: EntryOptions = {
    //     meta: {
    //         use: false,
    //         allowedTypes: [],
    //     },
    //     mappingFields: [
    //         {
    //             name: 'companyname',
    //             text: 'Firma Adı',
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //         {
    //             name: 'companylogo',
    //             text: 'Firma Logosu',
    //             types: [MetaTypeEnum.SingleImage],
    //         },
    //         {
    //             name: 'aboutthecompany',
    //             text: 'Firma Hakkında',
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //         {
    //             name: 'companyfieldsofactivity',
    //             text: 'Firma Faaliyet Alanları',
    //             types: [MetaTypeEnum.ComboBox],
    //         },
    //         {
    //             name: 'hallno',
    //             text: 'Salon No',
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //         {
    //             name: 'standno',
    //             text: 'Stant No',
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //         {
    //             name: 'web',
    //             text: 'Web Sitesi',
    //             types: [MetaTypeEnum.Link],
    //         },
    //         {
    //             name: 'phone',
    //             text: 'Firma Telefonu',
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //         {
    //             name: 'email',
    //             text: 'Firma E-posta',
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //         {
    //             name: 'address',
    //             text: 'Firma Adres',
    //             types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
    //         },
    //     ],
    // };
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { TextField, PrimaryButton } from "office-ui-fabric-react";
import { RouteComponentProps } from "react-router-dom";

import { IList } from "app/shared";
import { IUser, IUserFilter, LoginType, UserType } from "app/services/user";
import { deleteUser, getUser, getUsers } from "app/services/user/thunk";
import {
  deleteRecovery,
  getRecoveries,
  updateRecovery,
} from "app/services/recovery";
import { RootState } from "app/redux/reducer";
import { toggleConfirmDialog } from "app/redux/system/actions";
import { toggleModal } from "app/redux/modal/actions";
import { setPageData } from "app/redux/page/actions";
import BasicTable from "components/customs/BasicTable";
import PageTitle from "components/customs/PageTitle";
import { castThunkAction } from "app/utils/casting";

import PageLayout from "containers/PageLayout";
import { isSystemUser } from "app/utils/service";

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
  return {
    ...ownProps,
    language: state.system.language,
    pageData: state.page.pageData as IList<IUser[]>,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      toggleConfirmDialog,
      getRecoveries,
      updateRecovery,
      deleteRecovery,
      toggleModal,
      setPageData,
      getUsers,
      getUser,
      deleteUser
    },
    dispatch
  );
}

type IListUserProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface IListState {
  filterObject: IUserFilter;
}

class ListUser extends Component<IListUserProps, IListState> {
  state = {
    filterObject: {
      name: "",
      pageIndex: 0,
      pageSize: 10,
      userType: UserType.SuperAdminUser,
      surname: "",
      username: "",
      email: "",
    },
  };

  componentDidMount() {
    if (!isSystemUser()) {
      this.props.history.push("/");
    }
    else {
      this.makeRequest(this.state.filterObject);
      window.addEventListener("keydown", this.onEnterSearch, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onEnterSearch, true);
  }

  onEnterSearch = (e: any) => {
    if (e.key === "Enter") {
      this.searchList();
    }
  };

  makeRequest(options: IUserFilter) {
    this.props.getUsers(options);
  }

  searchList() {
    this.setState(
      {
        filterObject: {
          ...this.state.filterObject,
          pageIndex: 0,
          pageSize: 10,
        },
      },
      () => {
        this.makeRequest(this.state.filterObject);
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-2"
          label="Ad İle Arama"
          value={this.state.filterObject?.name || ""}
          onChange={(event: any) => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                name: event.target.value,
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Soyad İle Arama"
          value={this.state.filterObject?.surname || ""}
          onChange={(event: any) => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                surname: event.target.value,
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="E-Posta İle Arama"
          value={this.state.filterObject?.email || ""}
          onChange={(event: any) => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                email: event.target.value,
              },
            });
          }}
        />
        {/* <Dropdown
          options={USER_TYPES}
          className="custom-dropdown col-md-2"
          label="Kullanıcı Rolüne Göre Arama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterObject?.userType}
          onChange={(event: any, option: any) => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                userType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        /> */}
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                pageIndex: 0
              }
            }, () => {
              this.makeRequest(this.state.filterObject)
            })
          }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "37px",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        totalCount={this.props.pageData.totalCount}

        columns={[
          {
            columnName: "name",
            text: "Kullanıcı Adı - Soyadı",
            col: "col-md-3",
            onRender: (item: IUser) => {
              return (
                <span>
                  {item.name} {item.surname}
                </span>
              );
            },
          },
          {
            columnName: "email",
            text: "Mail Adresi",
            col: "col-md-2",
          },
          {
            columnName: "phone_number",
            text: "Telefon",
            col: "col-md-2",
            onRender: (item) => (
              <span>{item?.phone_number || "-"}</span>
            )
          },
          {
            columnName: "created_date",
            text: "Oluşturulma Tarihi",
            col: "col-md-2",
          },
        ]}
        items={this.props.pageData.list}
        renderOperations={(item: IUser) => (
          <React.Fragment>
            <button
              className="more"
              onClick={() => {
                this.props.getUser(item.id as string);
                this.props.toggleModal("user", {}, (res: UserType) => {
                  this.setState({
                    filterObject: {
                      ...this.state.filterObject,
                      userType: res
                    }
                  }, () => this.makeRequest(this.state.filterObject))
                })
              }}
            >
              <i className="icon-edit-outline"></i>
              Düzenle
            </button>
            <button onClick={() => {
              this.props.toggleConfirmDialog(true, {
                callback: () => {
                  castThunkAction(this.props.deleteUser(item.id as string)).then(res => {
                    if (res) {
                      this.makeRequest(this.state.filterObject)
                    }
                  })
                }
              })
            }}><i className="icon-trash-outline"></i> Sil</button>
          </React.Fragment>
        )}
        showPager={true}
        pageCount={this.props.pageData.pageCount}
        onChangePageSize={(pageSize) => {
          this.setState(
            { filterObject: { ...this.state.filterObject, pageSize } },
            () => this.makeRequest(this.state.filterObject)
          );
        }}
        onChangePager={(pageNumber) => {
          this.setState(
            {
              filterObject: {
                ...this.state.filterObject,
                pageIndex: pageNumber - 1,
              },
            },
            () => this.makeRequest(this.state.filterObject)
          );
        }}
        pageIndex={this.state.filterObject.pageIndex}
      />
    );
  }

  render() {
    return (
      <PageLayout
        history={this.props.history}
        match={this.props.match}
        location={this.props.location}
      >
        <div className="page push-all">
          <PageTitle
            title="Kullanıcı Yönetimi"
            subTitle="Tüm kullanıcıları aşağıdan görüntüleyebilir, düzenleme yapabilir veya yeni bir kullanıcı ekleyebilirsiniz."
            backUrl="/"
            showBack
            history={this.props.history}
          />
          <PrimaryButton
            iconProps={{ iconName: "Add" }}
            className="absolute-create"
            text="Yeni Kullanıcı Ekle"
            onClick={() => this.props.toggleModal("user", {
              login_type: LoginType.Manuel,
              country_id: 1,
              user_type: UserType.SuperAdminUser
            }, (res: UserType) => {
              this.makeRequest({ ...this.state.filterObject, userType: res })
            })}
            styles={{
              root: {
                background: "#62a98e",
                borderColor: "#5b9e85",
                color: "#fff",
              },
              rootHovered: {
                background: "#5b9e85",
                borderColor: "#5b9e85",
              },
              rootDisabled: {
                background: "#ececec",
              },
            }}
          />
          {this.renderFilter()}
          {this.renderList()}
        </div>
      </PageLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListUser);

import * as React from "react";
import { PureStringField } from "./Pure/PureStringField";
import { BaseFieldProps } from './types';

// Kendisine gelen Property ismine ve değerine göre HTML Editor render eder ve değerleri basar.
export default class CssField extends React.Component<BaseFieldProps> {

    handleEditorChange = (content: string) => {
        this.props.onChange(content);
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        return (

            <PureStringField
                label="Css Style"
                value={this.props.value}
                multiline={true}
                onChange={value => this.props.onChange(value)}
                text={this.props.property.text as string}
                description="Sağ taraftan bir tasarımsal stil tanımlaması yapabilirsiniz."
            />
        );
    }
}
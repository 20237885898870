import { api } from 'app/utils/api';
import { convertToQueryString } from 'app/shared/functions';
import { HttpClient } from 'app/library/client';
import { IListFilter, ResponseBase, ResponseContentBase, ResponseListBase } from "app/shared";
import { ContentTypeEntity, ContentTypeTree } from './types';
import { getEndpoint } from 'app/utils/service';

export class ContentTypeService {

    /** It gets all content types. */
    async getContentTypes(options: IListFilter): Promise<ResponseBase<ResponseListBase<ContentTypeEntity[]>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.contentType.list) + `?${convertToQueryString(options)}`);
    }

    /** It gets all content types and posts. */
    async getContentTypePosts(options: IListFilter): Promise<ResponseBase<ResponseListBase<ContentTypeTree[]>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.contentType.postList) + `?${convertToQueryString(options)}`);
    }

    /** It get spesific content type by content type id */
    async getContentType(contentTypeId: string): Promise<ResponseBase<ResponseContentBase<ContentTypeEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.contentType.main) + contentTypeId);
    }

    /** It get spesific content type fields by content type id */
    async getPageFields(contentTypeId: string): Promise<ResponseBase<ResponseContentBase<ContentTypeEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.contentType.pageFields) + contentTypeId);
    }

    /** It creates content type by request */
    async createContentType(request: ContentTypeEntity): Promise<ResponseBase<ResponseContentBase<ContentTypeEntity>>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.contentType.main), request);
    }

    /** It updates spesific content type by id */
    async updateContentType(request: ContentTypeEntity): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().patch(getEndpoint(api.prefixes.contentType.main) + request.id, request);
    }

    /** It deletes spesific content type by id */
    async deleteContentType(contentTypeId: string): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().delete(getEndpoint(api.prefixes.contentType.main) + contentTypeId);
    }
}

import * as React from "react";
import { BaseFieldProps } from "./types";
import { PureToggleField } from "./Pure/PureToggleField";

interface ToggleFieldProps extends BaseFieldProps {
	onText?: string;
	offText?: string;
}

// Kendisine gelen Property ismine ve değerine göre Toggle Button render eder.
export default class ToggleField extends React.Component<ToggleFieldProps> {
	render() {
		if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
		return (
			<PureToggleField
				label={this.props.property.name}
				value={this.props.value}
				text={this.props.property.text as string}
				description={this.props.property.description as string}
				onChange={value => this.props.onChange(value)}
				onText={this.props.onText}
				offText={this.props.offText}
			/>
		);
	}
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { GridContainerProps } from "./types";
import {
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { ContainerSizesOneOf } from '../defaults';

export class GridContainerDefinitions extends CmsReactComponent<
    GridContainerProps
    > {
    static schemaName = "container";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "size",
            mapping: {
                type: PropertyMappingType.Object,
                oneOf: ContainerSizesOneOf,
            },
            text: "Boyutlandırma",
            default: ""
        }
    ];

}

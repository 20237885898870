import { IQueryBuilder } from 'app/services/query-builder';
import { StatusEnum } from 'app/shared';
import { QuerySortEnum } from './types';

export function constructLocalDataWizard(): IQueryBuilder {
    return {
        id: "",
        contentType: "",
        showcase: "",
        whereCriteria: null,
        mappingFields: [],
        orderFields: [],
        pageSize: 10,
        pageIndex: 0,
        status: StatusEnum.Published
    }
}

export const querySortOptions = [{
    key: QuerySortEnum.CreatedOn,
    text: "Eklenme Tarihi"
},
{
    key: QuerySortEnum.Order,
    text: "İçerik Sırası"
},
{
    key: QuerySortEnum.Title,
    text: "İçerik Başlığı"
},
{
    key: QuerySortEnum.Status,
    text: "İçerik Durumu"
},
{
    key: QuerySortEnum.Random,
    text: "Random"
}]
import { SystemStore, SystemActionTypes, SHOW_MESSAGE, MessageType, HIDE_MESSAGE, TOGGLE_LOADER, SET_LANGUAGE, TOGGLE_VERIFICATION_LOADER, SET_USER_INFO, OPEN_TAB, CLOSE_TAB, CLOSE_ALL_TAB, TOGGLE_ADVANCED, SET_LEFT_PANEL, TOKEN_CONTROL, TOGGLE_CONFIRM, TOGGLE_PANEL_LOADER, SET_LAYOUT, SET_PAGE_USER_INFO, TOGGLE_MODAL_LOADER, COPY_COMPONENT, SET_DEFAULT_LANGUAGE, SET_ACCESS_TOKEN, SET_COUNTER } from "./types";
import _ from "lodash";
import { SchemaNode } from "app/library/layout-builder";
import { IUser } from "app/services/user";

const initialState: SystemStore = {
    loading: false,
    defaultLanguage: localStorage.getItem("defaultLanguage") ? localStorage.getItem("defaultLanguage") as string : "en",
    loadingText: "",
    panelLoading: false,
    verificationLoading: false,
    userInfo: {} as IUser,
    message: {
        show: false,
        messageContent: ""
    },
    language: "en",
    advancedMode: true,
    tokenStatus: false,
    tabs: [],
    leftPanel: "",
    confirm: {
        show: false,
        properties: {}
    },
    isLayout: false,
    pageUserInfo: {} as IUser,
    modalLoading: false,
    copiedComponent: {} as SchemaNode,
    accessToken: "",
    counter: {
        hospital: 0,
        user: 0,
        ticket: 1
    }
};

export function systemReducer(state = initialState, action: SystemActionTypes): SystemStore {
    switch (action.type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                message: {
                    show: true,
                    messageContent: action.content,
                    messageTitle: action.title,
                    messageType: action.messageType || MessageType.SUCCESS,
                    onRender: action.onRender,
                    hideFooter: action.hideFooter
                }
            };
        case SET_DEFAULT_LANGUAGE:
            localStorage.setItem("defaultLanguage", action.defaultLanguage.toString())
            return {
                ...state,
                defaultLanguage: action.defaultLanguage
            };
        case HIDE_MESSAGE:
            return {
                ...state,
                message: {
                    show: false,
                    messageContent: "",
                }
            };
        case TOGGLE_LOADER:
            return {
                ...state,
                loading: typeof action.defaultValue === "boolean" ? action.defaultValue : !state.loading,
                loadingText: action.loadingText || ""
            };
        case TOGGLE_MODAL_LOADER:
            return {
                ...state,
                modalLoading: !state.modalLoading
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.token
            };
        case TOGGLE_PANEL_LOADER:
            return {
                ...state,
                panelLoading: !state.panelLoading
            };
        case TOGGLE_ADVANCED:
            return {
                ...state,
                advancedMode: !state.advancedMode
            };
        case SET_LAYOUT:
            return {
                ...state,
                isLayout: action.isLayout
            };
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            };
        case SET_LEFT_PANEL:
            return {
                ...state,
                leftPanel: action.leftPanel || ""
            };
        case SET_COUNTER:
            return {
                ...state,
                counter: {
                    ...state.counter,
                    [action.key]: action.value
                }
            };
        case COPY_COMPONENT:
            return {
                ...state,
                copiedComponent: action.copiedComponent
            }
        case TOGGLE_VERIFICATION_LOADER:
            return {
                ...state,
                verificationLoading: !state.verificationLoading
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            };
        case OPEN_TAB: {
            const tabs = _.cloneDeep(state.tabs);
            const foundIndex = tabs.findIndex(x => x.id === action.id && x.tabType === action.tabType);

            if (foundIndex >= 0) {
                tabs[foundIndex].active = true;
            } else {
                tabs.push({
                    active: true,
                    id: action.id,
                    tabType: action.tabType,
                    datasourceId: action.datasourceId,
                    reference: action?.reference || {}
                });
            }

            tabs.filter(x => x.id !== action.id).forEach(x => x.active = false);

            return {
                ...state,
                tabs
            };
        }
        case CLOSE_TAB: {
            const tabs = _.cloneDeep(state.tabs);
            const foundIndex = tabs.findIndex(x => x.id === action.id && x.tabType === action.tabType);

            tabs.splice(foundIndex, 1);

            return {
                ...state,
                tabs
            };
        };
        case CLOSE_ALL_TAB:
            return {
                ...state,
                tabs: []
            };
        case TOKEN_CONTROL:
            return {
                ...state,
                tokenStatus: action.tokenStatus
            };
        case TOGGLE_CONFIRM:
            return {
                ...state,
                confirm: {
                    show: action.show,
                    properties: action.properties
                }
            };
        case SET_PAGE_USER_INFO:
            return {
                ...state,
                pageUserInfo: action.pageUserInfo
            }
        default:
            return state;
    }
}
import React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';

import { GrouppedDataWizardProps, IGrouppedValue } from './types';
import GrouppedDataWizardModal from './GrouppedDataWizardModal';

interface GrouppedDataWizardState {
    showModal: boolean;
}

export class GrouppedDataWizardField extends React.Component<GrouppedDataWizardProps, GrouppedDataWizardState> {
    state = {
        showModal: false
    }

    handleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    onChange(data: IGrouppedValue) {
        this.props.onChange(data);
    }

    render() {
        return (
            <div className="data-wizard-field row">
                <GrouppedDataWizardModal
                    show={this.state.showModal}
                    contentTypes={this.props.contentTypes}
                    node={this.props.node}
                    entry={this.props.entry}
                    onChange={(data) => this.onChange(data)}
                    onDismiss={() => this.setState({ showModal: false })}
                    value={this.props.value}
                />
                <DefaultButton
                    iconProps={{ iconName: "LinkedDatabase" }}
                    className="custom-button"
                    text="Sihirbazı Aç"
                    styles={{
                        root: {
                            border: "1px solid #ccc",
                            height: "29px",
                            marginTop: "-1px"
                        }
                    }}
                    style={{
                        fontSize: 9
                    }}
                    onClick={() => this.handleModal()}
                />
            </div>
        )
    }
}
import * as React from "react";
import { BaseFieldProps } from "./types";
import { PureDropdownField } from "./Pure/PureDropdownField";

interface BrandFieldState {
    values: { key: string; text: string; }[];
}
// Kendisine gelen Property ismine ve değerine göre Brand field render eder.
export default class BrandField extends React.Component<BaseFieldProps, BrandFieldState> {

    state = {
        values: []
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
        console.log("this", this)
        return (
            <PureDropdownField
                label="Marka"
                value={this.props.value}
                onChange={value => this.props.onChange(value)}
                property={this.props.property}
                text={this.props.property.text as string}
                customOptions={this.state.values}
                description="Geçerli element'in hangi marka'ya ait olduğunu belirleyin."
            />
        );
    }
}

import ComponentDescription from "components/customs/ComponentDescription";
import ComponentHeader from "components/cms/ComponentHeader";
import { IconDefinitions } from "./IconDefinitions";

export class Icon extends IconDefinitions {
    render() {
        const { className, selectable, designing } = this.props;

        if (this.props.hideRuntime && !this.props.designing) return null;
        // const icon = this.props.schemaNode?.getProp("icon");

        return (
            <div
                data-schema-id={this.props.id}
                className={[
                    "fa-Icon",
                    selectable && "fa-draggable disable-inside",
                    designing && "designing",
                    className,
                ]
                    .filter((e) => !!e)
                    .join(" ")}
            >
                {designing && (
                    <ComponentHeader
                        componentEntry={this.props.entry}
                        componentSchemaId={this.props.id as string}
                    />
                )}
                <ComponentDescription
                    node={this.props.schemaNode}
                    entry={this.props.entry}
                />
            </div>
        );
    }
}

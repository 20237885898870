import ComponentDescription from "components/customs/ComponentDescription";
import ComponentHeader from "components/cms/ComponentHeader";
import { FairSessionDefinitions } from "./FairSessionDefinitions";

export class FairSession extends FairSessionDefinitions {
    render() {
        const { className, designing } = this.props;

        return (
            <div
                data-schema-id={this.props.id}
                className={["fa-card fa-draggable", designing && "designing", className]
                    .filter((e) => !!e)
                    .join(" ")}
            >
                {designing && (
                    <ComponentHeader
                        componentEntry={this.props.entry}
                        componentSchemaId={this.props.id as string}
                    />
                )}
                <ComponentDescription
                    node={this.props.schemaNode}
                    entry={this.props.entry}
                />
            </div>
        );
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { RowProps } from "./types";
import {
  PropertyDefinition,
  PropertyMappingType,
  PropertyDefinitionOneOf,
} from "app/library/layout-builder";

const alignments: PropertyDefinitionOneOf[] = [
  {
    key: "",
    text: "Varsayılan",
  }, {
    key: "start",
    text: "start",
  },
  {
    key: "center",
    text: "center",
  },
  {
    key: "end",
    text: "end",
  },
  {
    key: "top",
    text: "top",
  },
  {
    key: "middle",
    text: "middle",
  },
  {
    key: "bottom",
    text: "bottom",
  },
];
const gutters: PropertyDefinitionOneOf[] = [
  {
    key: "guttered",
    text: "Boşluklu",
  },
  {
    key: "no-gutter",
    text: "Boşluklsuz",
  },
  {
    key: "gutter-x",
    text: "Yatay'da Boşluklu",
  },
  {
    key: "gutter-y",
    text: "Dikey'de Boşluklu",
  },
  {
    key: "wide",
    text: "Geniş",
  }
];
const distributions: PropertyDefinitionOneOf[] = [
  {
    key: "",
    text: "Varsayılan",
  }, {
    key: "around",
    text: "around",
  },
  {
    key: "between",
    text: "between",
  },
];
export class RowDefinitions extends CmsReactComponent<RowProps> {
  static schemaName = "row";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "alignments",
      mapping: {
        type: PropertyMappingType.ParentObject,
        children: [
          {
            name: "lg",
            mapping: {
              type: PropertyMappingType.Object,
              oneOf: alignments,
            },
            text: "large",
          },
          {
            name: "md",
            mapping: {
              type: PropertyMappingType.Object,
              oneOf: alignments,
            },
            text: "Medium",
          },
          {
            name: "sm",
            mapping: {
              type: PropertyMappingType.Object,
              oneOf: alignments,
            },
            text: "Small",
          },
          {
            name: "xs",
            mapping: {
              type: PropertyMappingType.Object,
              oneOf: alignments,
            },
            text: "Xsmall",
          },
        ],
      },
      description: "Hizalamalar",
      // group: {
      //     key: "link",
      //     title: "Link Ayarları"
      // }

      default: { lg: "", xs: "", sm: "", md: "" },
    },
    {
      name: "distributions",
      mapping: {
        type: PropertyMappingType.ParentObject,
        children: [
          {
            name: "lg",
            mapping: {
              type: PropertyMappingType.Object,
              oneOf: distributions,
            },
            text: "Masaüstü Görünümü",
          },
          {
            name: "md",
            mapping: {
              type: PropertyMappingType.Object,
              oneOf: distributions,
            },
            text: "Tablet Görünümü",
          },
          {
            name: "sm",
            mapping: {
              type: PropertyMappingType.Object,
              oneOf: distributions,
            },
            text: "Mobil Yan Görünümü",
          },
          {
            name: "xs",
            mapping: {
              type: PropertyMappingType.Object,
              oneOf: distributions,
            },
            text: "Mobil Görünüm",
          },
        ],
      },
      description: "distributions",
      // group: {
      //     key: "link",
      //     title: "Link Ayarları"
      // }
    },
    {
      name: "gutter",
      mapping: {
        type: PropertyMappingType.Object,
        oneOf: gutters,
      },
      text: "Ara Boşluklar",
      default: "gutter-y"
    },
  ];
}

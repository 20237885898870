import { Dropdown, Icon } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

export interface TableColumnProps {
	columnName: string;
	text?: string;
	className?: string;
	col?: string;
	onRender?: (item: any) => {} | null;
}

interface OwnProps {
	className?: string;
	totalCount: number;
	columns: TableColumnProps[];
	notFoundText?: string;
	renderOperations?: (item: any) => JSX.Element;
	renderTotal?: () => JSX.Element;
	items: any[];
	showPager?: boolean;
	onChangePager?: (pageNumber: number) => void;
	pageCount?: number;
	pageIndex?: number;
	onChangePageSize?: (pageSize: number) => void;
	showExcel?: boolean;
	hidePagerFromBottom?: boolean;
	onDownload?: () => void;
}

interface OwnState {
	pageSize: number;
}

export default class BasicTable extends Component<OwnProps, OwnState> {

	state = {
		pageSize: localStorage.getItem("pageSize") ? parseInt(localStorage.getItem("pageSize") as string) : 10
	}

	findSuggestItem(columnKey: string) {
		return (this.props.items || []).find(item => item[columnKey]);
	}

	renderColumns() {
		return (
			(this.props.columns || []).map((item, index) => (
				<div key={index} className={item.col ? `list-th ${item.col}` : "col-md-2"}>
					{item.text}
				</div>
			))
		)
	}

	renderItems() {
		if (!this.props.items || this.props.items.length <= 0)
			return (
				<div className="table-not-found row">
					<i className="icon-info"></i>
					{this.props.notFoundText || "İlgili yönetim sayfası üzerinde geçerli bir kayıt bulunamadı."}
				</div>
			)
		return (
			this.props.items.map((item, index) => (
				<div key={index} className="item row">
					{(this.props.columns || []).map((column, columnIndex) => (
						<div key={columnIndex} className={`list-td row ${column.col || "col-md-2"} ${column.className || ""}`}>
							{column.onRender ? column.onRender(item) : item[column.columnName]}
						</div>
					))}
					{this.props.renderOperations && (
						<div className="operations full">
							{this.props.renderOperations(item)}
						</div>
					)}
				</div>
			))
		)

	}

	renderPager() {
		return (
			<ReactPaginate
				pageCount={this.props.pageCount || 1}
				pageRangeDisplayed={5}
				marginPagesDisplayed={5}
				containerClassName="table-pager"
				previousLabel="Önceki"
				nextLabel="Sonraki"
				forcePage={this.props.pageIndex || 0}
				onPageChange={(item) => {
					if (this.props.onChangePager) {
						this.props.onChangePager(item.selected + 1);
					}
				}}
			/>
		)
	}

	renderPageSize() {
		return (
			<div className="table-page-sizer row m-left-auto">
				<span className="total">
					Gösterilen toplam kayıt: <strong>{this.props.totalCount}</strong>
				</span>
				{this.props.renderTotal && this.props.renderTotal()}
				{this.props.showExcel && (
					<span onClick={() => this.props.onDownload ? this.props.onDownload() : {}} className="excel">
						<Icon iconName="ExcelDocument" />
                        Excel'e Çıkart
					</span>
				)}
				<span className="text">
					Sayfa başına kayıt
				</span>
				<Dropdown
					className="custom-dropdown"
					calloutProps={{
						className: "custom-dropdown-callout"
					}}
					options={[{
						key: 10,
						text: "10"
					},
					{
						key: 20,
						text: "20"
					},
					{
						key: 30,
						text: "30"
					},
					{
						key: 50,
						text: "50"
					},
					{
						key: 100,
						text: "100"
					}]}
					onChange={(_, opt) => {
						this.setState({
							pageSize: opt?.key as number
						}, () => {
							if (this.props.onChangePageSize)
								this.props.onChangePageSize(opt?.key as number)
						})
					}}
					selectedKey={this.state.pageSize}
				/>
			</div>
		)
	}

	renderFooter() {
		if (this.props.showPager && this.props.items?.length > 0) {
			return (
				<div className="table-footer">
					{this.renderPager()}
					{this.renderPageSize()}
				</div>
			)
		}
		return null;
	}

	render() {
		const { className } = this.props;
		return (
			<React.Fragment>
				{this.renderFooter()}
				<div className={className ? `table-list ${className}` : "table-list"}>
					<div className="table-head row">
						{this.renderColumns()}
					</div>
					<div className="table-body">
						{this.renderItems()}
					</div>
				</div>
				{!this.props.hidePagerFromBottom && (
					this.renderFooter()
				)}
			</React.Fragment>
		)
	}
}

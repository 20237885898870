import _ from "lodash";
import { CategoryEntity, ICategory } from "./types"

/**
 * Category entity schema convert to model
 */
export function mapCategoryFromEntity(categoryEntity: CategoryEntity): ICategory {
    return {
        id: categoryEntity.id,
        title: categoryEntity.title,
        parent: categoryEntity.parentId,
        contentType: categoryEntity.contentTypeId,
        brandId: categoryEntity.brandId,
        children: (categoryEntity.childrens || []).map(item => mapCategoryFromEntity(item)),
        status: categoryEntity.status
    }
}

/**
 * Category model schema convert to entity
 */
export function mapCategoryFromModel(categorySchema: ICategory): CategoryEntity {
    return {
        id: categorySchema?.id || "",
        title: categorySchema.title,
        contentTypeId: categorySchema.contentType,
        brandId: categorySchema.brandId,
        parentId: categorySchema?.parent || null,
        status: categorySchema.status
    }
}

/**
 * Category model recursive map for list
 */
export function mapCategoryForList(categories: ICategory[], fromChildren?: boolean): ICategory[] {
    let mapped = _.cloneDeep(categories) || [];
    return mapped.reduce((prev: ICategory[], current) => {
        if (fromChildren) {
            current.title = "--" + current.title;
            current.children = (current?.children || []).map(item => ({ ...item, title: "--" + item.title }))
        }
        return [...prev, current, ...mapCategoryForList(current.children as ICategory[], true)]
    }, [])
}
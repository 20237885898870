import { Component } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, Label, PrimaryButton, TextField, Toggle } from 'office-ui-fabric-react';
import FileManager from '../FileManager/FileManager';
import { IMetaGallery } from 'app/services/content-type';
import { FileTypes, IFile } from 'app/services/file-manager';
import { getFilePath } from 'app/utils/service';
import { validateUrl } from 'app/shared/functions';

type MetaPhotoType = "thumbnail" | "src" | "";

interface MetaGalleryModalProps {
    show: boolean;
    data: IMetaGallery | null;
    onSave: (data: IMetaGallery | null) => void;
    onDismiss: () => void;
}

interface MetaGalleryModalState {
    data: IMetaGallery | null;
    modalKey: MetaPhotoType;
}

export class MetaGalleryModal extends Component<MetaGalleryModalProps, MetaGalleryModalState> {

    state = {
        data: this.props.data || null,
        modalKey: "" as MetaPhotoType
    }

    renderFileManager() {
        return (
            <FileManager
                show={this.state.modalKey ? true : false}
                onDismiss={() => this.setState({ modalKey: "" })}
                activeFile={this.state.data ? { fileName: this.state.data[this.state.modalKey] } as IFile : {} as IFile}
                onSelectedFile={(file) => {
                    const key = this.state.modalKey as MetaPhotoType;
                    this.setState({
                        data: {
                            ...this.state.data,
                            [key]: file.fileName
                        } as IMetaGallery
                    })
                }}
                fileType={FileTypes.Image}
            />
        )
    }

    renderImage(imageKey: MetaPhotoType) {
        const image = this.state.data ? this.state.data[imageKey] : "";
        const splitted = image?.split(".");
        const imageSource = validateUrl(image) ? image : (getFilePath() + image + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=200&height=200" : ""));

        return (
            <div className="image-field row">
                {image && (
                    <img className="image-string-field" src={imageSource} alt="" />
                )}
                <TextField
                    className="custom-textfield col no-padding"
                    value={image}
                    onChange={(event: any) => {
                        this.setState({ data: { ...this.state.data, [imageKey]: event.target.value as string } as IMetaGallery })
                    }}
                />
                <DefaultButton
                    className="custom-button"
                    text="Görüntüle"
                    style={{ marginTop: 2.5 }}
                    disabled={!imageSource}
                    onClick={() => {
                        window.open(imageSource, "_blank")
                    }}
                />
                <PrimaryButton
                    className="custom-button"
                    text="Görsel Seç"
                    style={{ marginTop: 2.5 }}
                    disabled={this.state.data?.external}
                    onClick={() => {
                        this.setState({ modalKey: imageKey })
                    }}
                    styles={{
                        root: {
                            background: "#33926e !important",
                            borderColor: "#33926e !important"
                        },
                        rootDisabled: {
                            opacity: 0.3,
                            background: "#ececec",
                            color: "#000"
                        }
                    }}
                />
            </div>
        );
    }

    renderForm() {
        return (
            <div className="meta-form">
                <TextField
                    label="Fotoğraf Başlığı"
                    className="custom-textfield"
                    value={this.state.data?.caption}
                    onChange={(event: any) => {
                        this.setState({ data: { ...this.state.data, alt: event.target?.value as string, caption: event.target?.value as string } as IMetaGallery })
                    }}
                />
                <TextField
                    label="Fotoğraf Alt Başlığı"
                    className="custom-textfield"
                    multiline
                    value={this.state.data?.subcaption}
                    onChange={(event: any) => {
                        this.setState({ data: { ...this.state.data, subcaption: event.target?.value as string } as IMetaGallery })
                    }}
                />
                <Toggle
                    label="Fotoğraf Yolu Harici Bir URL mi?"
                    className="custom-toggle"
                    checked={this.state.data?.external as boolean}
                    onChange={(_, checked) => {
                        this.setState({ data: { ...this.state.data, external: checked, src: "", thumbnail: "" } as IMetaGallery })
                    }}
                />
                <Label>Fotoğraf Görseli</Label>
                {this.renderImage("src")}
                <Label>Fotoğraf Öne Çıkarılmış Görseli (Liste Görseli)</Label>
                {this.renderImage("thumbnail")}
            </div>
        )
    }

    render() {
        if (!this.props.show) return null;

        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Galeri Öğesini Düzenle",
                    closeButtonAriaLabel: 'Kapat',
                    subText: "Aşağıdan galeri öğesi özelliklerini güncelleyebilirsiniz. Galeri görselini ve öne çıkarılmış görseli girmek zorunludur.",
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: false,
                    styles: { main: { maxWidth: "1000px !important" } }
                }}
            >
                {this.renderForm()}
                {this.renderFileManager()}
                <DialogFooter>
                    <DefaultButton onClick={() => this.props.onDismiss()} text="İptal" />
                    <PrimaryButton
                        disabled={!this.state.data?.src || !this.state.data?.thumbnail}
                        onClick={() => {
                            this.props.onSave(this.state.data);
                            this.props.onDismiss();
                        }}
                        text="Kaydet"
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}

import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { History } from 'history';
import { Helmet } from 'react-helmet';
import { TextField } from 'office-ui-fabric-react';

import { RootState } from 'app/redux/reducer';
import { setPageData, setPageErrors } from 'app/redux/page/actions';
import { setUserInfo, tokenControl } from 'app/redux/system/actions';
import { connectToken, IdentityLoginRequest, IdentityLoginResponse } from 'app/services/identity';
import { SIGN_REQUEST_FIELDS } from 'app/services/identity/validation';
import AuthLayout from 'containers/AuthLayout';
import AbsoluteLoader from 'components/customs/Loader/AbsoluteLoader';
import { castThunkAction } from 'app/utils/casting';

interface OwnProps {
  history: History<any>;
}

function mapStateToProps(state: RootState) {
  return {
    pageData: state.page.pageData as IdentityLoginRequest,
    pageErrors: state.page.pageErrors,
    userInfo: state.system.userInfo
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    setPageData,
    setPageErrors,
    setUserInfo,
    tokenControl,
    connectToken
  }, dispatch);
}

type LoginProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

class Login extends React.Component<LoginProps> {

  state = {
    loginError: "",
    loginSuccess: "",
    loginLoader: false
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onEnterSubmit, true);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onEnterSubmit, true);
  }

  onEnterSubmit = (e: any) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  }

  async onLogin() {
    castThunkAction<IdentityLoginResponse>(this.props.connectToken({
      username: this.props.pageData.username || "",
      password: this.props.pageData.password,
    })).then(response => {
      if (response) {
        this.setState({
          loginError: "",
          loginLoader: false
        }, () => {
          this.props.history.push("/");
        })
      }
      else {
        this.setState({
          loginError: "Lütfen giriş yaptığınız bilgileri kontrol ediniz.",
          loginLoader: false
        })
      }
    })

  }

  /** Formu kontrol ederek giriş yapılacak servise yönlendirilir. */
  onSubmit = () => {
    const {
      pageData,
      setPageErrors
    } = this.props;
    const validationSchema = SIGN_REQUEST_FIELDS
    let formErrors = {};

    if (this.state.loginLoader)
      return;

    validationSchema
      .validate(pageData, {
        abortEarly: false
      })
      .then(() => {
        setPageErrors({});
        this.setState({
          loginLoader: true
        }, () => this.onLogin())
      })
      .catch(err => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setPageErrors(formErrors);
      });
  }


  render() {
    const { pageData, pageErrors, setPageData } = this.props;

    return (
      <AuthLayout isLogin>
        <Helmet>
          <title>Giriş Yap - Saha Expo - İçerik Yönetim Sistemi</title>
        </Helmet>
        <div className="form-wrapper">
          <div className={pageErrors.phoneNumber ? 'form-item error' : 'form-item'}>
            <label>
              Kullanıcı Adı
              <span>Sistemde kayıtlı kullanıcı adınızı giriniz</span>
            </label>
            <TextField
              onChange={(event: any) => setPageData('username', event.target.value)}
              value={pageData.username || ''}
              className="custom-textfield"
            />
          </div>
          <div className={pageErrors.phoneNumber ? 'form-item error' : 'form-item'}>
            <label>
              Parola
              <span>Sistemde kayıtlı hesabınızın parolasını giriniz</span>
            </label>
            <TextField
              onChange={(event: any) => setPageData('password', event.target.value)}
              value={pageData.password || ''}
              className="custom-textfield"
              type="password"
            />
          </div>
        </div>
        <div className="auth-page-footer">
          <AbsoluteLoader show={this.state.loginLoader} />
          <button className={this.state.loginLoader ? "passive" : ""} type="submit" onClick={this.onSubmit}>
            GİRİŞ YAP
          </button>
          {this.state.loginError && (
            <div className="login-message error active" style={{ marginBottom: 10 }}>
              {this.state.loginError}
            </div>
          )}
          {this.state.loginSuccess && (
            <div className="login-message success active" style={{ marginBottom: 10 }}>
              {this.state.loginSuccess}
            </div>
          )}
        </div>
      </AuthLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

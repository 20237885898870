import { UserRole, UserType } from "app/services/user";

export const USER_TYPES = [
    {
        key: UserType.SuperAdminUser,
        text: "Super Admin"
    },
    {
        key: UserType.FirstApprovalAuthority,
        text: "1. Onay Yetkilisi",
    },
    {
        key: UserType.SecondApprovalAuthority,
        text: "2. Onay Yetkilisi",
    },
]

export const USER_ROLES = [
    {
        key: UserRole.SuperAdminUser,
        text: "Super Admin"
    },
    {
        key: UserRole.FirstApprovalAuthority,
        text: "1. Onay Yetkilisi",
    },
    {
        key: UserRole.SecondApprovalAuthority,
        text: "2. Onay Yetkilisi",
    }
]

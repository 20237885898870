import { SchemaNode } from 'app/library/layout-builder';
import { IUser } from 'app/services/user';
import { ReactNode } from 'react';

export interface SystemStore {
    defaultLanguage: string;
    message: SystemStoreMessage;
    verificationLoading: boolean;
    language: string;
    userInfo: IUser;
    loading: boolean;
    loadingText: string;
    panelLoading: boolean;
    tabs: TabItem[];
    advancedMode: boolean;
    leftPanel: string;
    tokenStatus: boolean;
    accessToken: string;
    confirm: Confirm;
    isLayout: boolean;
    pageUserInfo: IUser;
    modalLoading: boolean;
    copiedComponent: SchemaNode;
    counter: ISystemCounter;
}

export interface ISystemCounter {
    hospital: number;
    user: number;
    ticket: number;
}


export enum MessageType {
    SUCCESS = 1,
    WARNING = 2,
    ERROR = 3
}

export interface SystemStoreMessage {
    show: boolean;
    messageTitle?: string;
    messageContent: string;
    messageType?: MessageType;
    onRender?: () => ReactNode;
    hideFooter?: boolean;
}

export enum TabTypeEnum {
    TABLE = 1,
    QUERY = 2,
    DIAGRAM = 3,
    QUERY_BUILDER = 4
}

export interface TabItem {
    id: string;
    tabType: TabTypeEnum;
    active: boolean;
    datasourceId: string | null;
    reference?: any;
}

export interface ConfirmProps {
    callback?: (data?: object) => any;
    title?: string;
    text?: string;
    button?: {
        approveText?: string;
        cancelText?: string;
    };
    reason?: boolean;
    contentRenderer?: () => JSX.Element | null;
    hideButtons?: boolean;
}

export interface Confirm {
    show: boolean;
    properties?: ConfirmProps
}

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const TOGGLE_MODAL_LOADER = 'TOGGLE_MODAL_LOADER';
export const SET_LAYOUT = "SET_LAYOUT";
export const TOGGLE_PANEL_LOADER = 'TOGGLE_PANEL_LOADER';
export const TOGGLE_ADVANCED = 'TOGGLE_ADVANCED';
export const TOGGLE_VERIFICATION_LOADER = 'TOGGLE_VERIFICATION_LOADER';
export const SET_LEFT_PANEL = 'SET_LEFT_PANEL';
export const SET_USER_INFO = 'SET_USER_INFO';
export const OPEN_TAB = 'OPEN_TAB';
export const CLOSE_TAB = 'CLOSE_TAB';
export const CLOSE_ALL_TAB = 'CLOSE_ALL_TAB';
export const SET_PAGE_USER_INFO = 'SET_PAGE_USER_INFO';
export const TOKEN_CONTROL = "TOKEN_CONTROL";
export const TOGGLE_CONFIRM = "TOGGLE_CONFIRM";
export const COPY_COMPONENT = "COPY_COMPONENT";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_COUNTER = 'SET_COUNTER';

interface ShowMessageAction {
    type: typeof SHOW_MESSAGE;
    content: string;
    messageType?: MessageType;
    title?: string;
    onRender?: () => ReactNode;
    hideFooter?: boolean;
}

interface HideMessageAction {
    type: typeof HIDE_MESSAGE;
}

interface SetCounterAction {
    type: typeof SET_COUNTER;
    key: string;
    value: number;
}

interface ToggleConfirmAction {
    type: typeof TOGGLE_CONFIRM;
    show: boolean;
    properties?: ConfirmProps
}

interface SetDefaultLanguageAction {
    type: typeof SET_DEFAULT_LANGUAGE;
    defaultLanguage: string;
}

interface SetAccessToken {
    type: typeof SET_ACCESS_TOKEN;
    token: string;
}

interface ToggleLoaderAction {
    type: typeof TOGGLE_LOADER;
    defaultValue?: boolean;
    loadingText?: string;
}

interface ToggleModalLoaderAction {
    type: typeof TOGGLE_MODAL_LOADER;
}

interface SetLayout {
    type: typeof SET_LAYOUT;
    isLayout: boolean;
}

interface TogglePanelLoaderAction {
    type: typeof TOGGLE_PANEL_LOADER;
}

interface ToggleAdvancedAction {
    type: typeof TOGGLE_ADVANCED;
}

interface ToggleVerificationLoaderAction {
    type: typeof TOGGLE_VERIFICATION_LOADER;
}

interface SetLanguageAction {
    type: typeof SET_LANGUAGE;
    language: string;
}

interface CopyComponentAction {
    type: typeof COPY_COMPONENT;
    copiedComponent: SchemaNode;
}

interface SetLeftPanel {
    type: typeof SET_LEFT_PANEL;
    leftPanel?: string;
}

interface SetUserInfo {
    type: typeof SET_USER_INFO;
    userInfo: IUser;
}

interface OpenTabAction {
    type: typeof OPEN_TAB;
    id: string;
    tabType: TabTypeEnum;
    datasourceId: string | null;
    reference: any;
}

interface CloseTabAction {
    type: typeof CLOSE_TAB;
    id: string;
    tabType: TabTypeEnum;
}

interface CloseAllTabAction {
    type: typeof CLOSE_ALL_TAB;
}

interface TokenControlAction {
    type: typeof TOKEN_CONTROL;
    tokenStatus: boolean;
}

interface SetPageUserInfo {
    type: typeof SET_PAGE_USER_INFO;
    pageUserInfo: IUser;
}

export type SystemActionTypes = ShowMessageAction | SetDefaultLanguageAction | SetAccessToken | SetLayout | TokenControlAction | TogglePanelLoaderAction | HideMessageAction | SetLeftPanel | ToggleConfirmAction | ToggleLoaderAction | ToggleModalLoaderAction | SetLanguageAction | ToggleVerificationLoaderAction | SetUserInfo | CloseTabAction | CloseAllTabAction | OpenTabAction | ToggleAdvancedAction | CopyComponentAction | SetPageUserInfo | SetCounterAction;
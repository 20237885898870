import * as React from "react";
import { Dropdown } from "office-ui-fabric-react";
import _ from "lodash";
import { PropertyDefinition } from "app/library/layout-builder";

export type OnChangeDropdownField = (value: any) => void;

export interface PureDropdownFieldProps {
    label: string;
    value: any;
    onChange: OnChangeDropdownField;
    property?: PropertyDefinition;
    customOptions?: { key: string | undefined, text: string }[];
    text: string;
    description: string;
    multiple?: boolean;
}

export class PureDropdownField extends React.Component<PureDropdownFieldProps> {

    renderDropdown() {
        if (this.props.multiple) {
            return (
                <Dropdown
                    selectedKey={this.props.value || undefined}
                    options={this.props.customOptions as { key: string, text: string }[] || this.props.property?.mapping.oneOf || []}
                    className="custom-dropdown"
                    calloutProps={{ className: "custom-dropdown-callout" }}
                    onChange={(e, option) => {
                        let values = _.cloneDeep(this.props.value) as any[] || [];
                        if (!_.isArray(values)) {
                            values = [];
                        }
                        const findIndex = values.findIndex(x => x === option?.key);
                        if (findIndex !== -1) {
                            values.splice(findIndex, 1);
                        }
                        else {
                            values.push(option?.key as string)
                        }
                        this.props.onChange(values);
                    }}
                    disabled={this.props.customOptions && this.props.customOptions.length === 0 ? true : false}
                />
            )
        }
        return (
            <Dropdown
                selectedKey={this.props.value || undefined}
                options={this.props.customOptions as { key: string, text: string }[] || this.props.property?.mapping.oneOf || []}
                className="custom-dropdown"
                calloutProps={{ className: "custom-dropdown-callout" }}
                onChange={(e, value) => this.props.onChange(value?.key)}
                disabled={this.props.customOptions && this.props.customOptions.length === 0 ? true : false}
            />
        )
    }

    render() {
        return (
            <div className="property-item row">
                <div title={this.props.label} className="property-text">
                    <div className="title">{this.props.text || this.props.label.split(/(?=[A-Z])/).map(item => item.charAt(0).toUpperCase() + item.slice(1) + " ")}</div>
                    <div className="sub-title">{this.props.description || this.props.label}</div>
                </div>
                <div className="col">
                    {this.renderDropdown()}
                </div>
            </div>
        );
    }
}

import React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { RootState } from 'app/redux/reducer';
import { bindActionCreators, Dispatch } from 'redux';
import { toggleModal } from 'app/redux/modal/actions';
import { connect } from 'react-redux';
import { LocalDataWizardModalData } from 'app/redux/modal/types';
import { constructLocalDataWizard } from './constants';
import { SchemaNode } from 'app/library/layout-builder';
import { MetaTypeEnum } from 'app/shared';
import { castThunkAction } from 'app/utils/casting';
import { api } from 'app/utils/api';
import { createQuery, IQueryBuilder } from 'app/services/query-builder';
import { getEndpoint } from 'app/utils/service';


interface OwnProps {
    queryId: string;
    node: SchemaNode;
    onChange: (queryId: string, isCriteria?: boolean) => void;
}

function mapStateToProps(state: RootState) {
    return {
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            toggleModal,
            createQuery
        },
        dispatch
    );
}

type LocalDataWizardFieldProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & OwnProps;

class LocalDataWizardField extends React.Component<LocalDataWizardFieldProps> {

    createQuery(data: IQueryBuilder) {
        castThunkAction<IQueryBuilder>(this.props.createQuery(data)).then(res => {
            this.props.onChange(res?.id as string, res?.whereCriteria?.field ? true : false);
            this.props.toggleModal("localDataWizard", {})
        })
    }

    render() {
        return (
            <div className="data-wizard-field row">
                <DefaultButton
                    iconProps={{ iconName: "LinkedDatabase" }}
                    className="custom-button"
                    text="Veri Sihirbazını Aç"
                    styles={{
                        root: {
                            border: "1px solid #ccc",
                            height: "29px",
                            marginTop: "-1px"
                        }
                    }}
                    style={{
                        fontSize: 9
                    }}
                    onClick={() => {
                        this.props.toggleModal<LocalDataWizardModalData>("localDataWizard", {
                            query: {
                                ...constructLocalDataWizard(),
                                mappingFields: (this.props.node.getOption("mappingFields") || []).map((item: { name: string, type: MetaTypeEnum }) =>
                                    ({ key: item.name, value: item.type === MetaTypeEnum.Link ? "post.url" : "" })
                                ),
                                id: this.props.queryId
                            },
                            node: this.props.node,
                            callback: (data) => {
                                this.createQuery(data)
                            }
                        })
                    }}
                />
                {this.props.queryId && (
                    <span className="filled row">
                        <i className="icon-checkmark"></i>
                        Sorgu Oluşturuldu!
                        <span onClick={() => window.open(`${getEndpoint(api.prefixes.queryBuilder.main)}${this.props.queryId}`, "_blank")} className="query-id">
                            ({this.props.queryId})
                        </span>
                    </span>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalDataWizardField);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  DefaultButton,
  Dropdown,
} from "office-ui-fabric-react";
import * as _yup from "yup";
import { MetaTypeEnum, StatusEnum } from "app/shared";
import _ from "lodash";
import { getCategories } from "app/services/category";
import { createPost, getPostSlug, IPost } from "app/services/post";
import { getContentType, IContentType, IMeta } from "app/services/content-type";
import { getLayoutsForLocal, ILayout } from "app/services/layout";
import { FileTypes } from "app/services/file-manager";
import { RootState } from "app/redux/reducer";
import {
  initialPageData,
  setPageData,
  setPageErrors,
} from "app/redux/page/actions";
import { toggleLoader } from "app/redux/system/actions";
import { toggleModal } from "app/redux/modal/actions";
import { castThunkAction } from "app/utils/casting";
import MetaField from "components/customs/MetaFields/MetaField";
import {
  createContentTypePostEditLink,
  createContentTypePostsLink,
} from "app/utils/linking";
import { seoMetaNames } from "../ThemeSettings/helpers";
import FormAccordion from "components/customs/FormAccordion";
import FileManager from "components/customs/FileManager/FileManager";
import PageTitle from "components/customs/PageTitle";
import { Designer } from "components/customs/Designer";
import Sidebar from "components/customs/Sidebar/Sidebar";
import PageLayout from "containers/PageLayout";
import { RouteComponentProps } from "react-router-dom";
import { DEFAULT_SCHEMA } from "app/shared/constants";
import { getCurrentSiteUi } from "app/utils/service";
import { slugify } from "app/shared/functions";

function mapStateToProps(
  state: RootState,
  ownProps: RouteComponentProps<{ contentTypeId: string }>
) {
  return {
    ...ownProps,
    defaultLanguage: state.system.defaultLanguage,
    contentTypeId: ownProps.match.params.contentTypeId,
    language: state.system.language,
    pageData: state.page.pageData as IPost,
    pageErrors: state.page.pageErrors,
    contentType: state.contentType.contentType,
    categories: state.category.categories,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setPageData,
        setPageErrors,
        initialPageData,
        createPost,
        getContentType,
        getCategories,
        getLayoutsForLocal,
        getPostSlug,
        toggleLoader,
        toggleModal,
      },
      dispatch
    ),
  };
}

type CreatePostProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface OwnState {
  messageType: MessageBarType;
  layoutData: ILayout[];
  showFileManager: boolean;
  postSlug: {
    editMode: boolean;
    loading: boolean;
    available: boolean | string;
    urlText: string;
  };
}

let debounceTimeout: number | NodeJS.Timeout = 0;

class CreatePost extends Component<CreatePostProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
    layoutData: [] as ILayout[],
    showFileManager: false,
    postSlug: {
      editMode: false,
      loading: false,
      available: "",
      urlText: "",
    },
  };

  UNSAFE_componentWillMount() {
    castThunkAction<IContentType>(
      this.props.getContentType(this.props.contentTypeId, true)
    ).then(() => {
      this.props.toggleLoader();
      castThunkAction<ILayout[]>(this.props.getLayoutsForLocal("en")).then(
        (layoutResponse) => {
          this.setState(
            {
              layoutData: layoutResponse,
            },
            () => {
              this.resetData();
              this.props.toggleLoader();
            }
          );
        }
      );
    });
  }

  componentDidMount() {
    this.props.getCategories(this.props.contentTypeId);
  }

  getDefaultMetaFields() {
    return (this.props.contentType?.metaFields || [])
      .filter((item) => item.default)
      .map((item) => ({
        ...item,
        data: item.default,
      }));
  }

  onSeoItemChange(index: number, key: string, value: string) {
    const clonedItems = _.cloneDeep(this.props.pageData?.seo || []);
    clonedItems[index] = {
      ...clonedItems[index],
      [key]: value,
    };
    this.props.setPageData("seo", clonedItems);
  }

  createPostSlug(fromSaveButton?: boolean, customUrl?: string) {
    if (debounceTimeout) clearTimeout(debounceTimeout as number);
    debounceTimeout = setTimeout(
      () => {
        const { pageData } = this.props;

        this.setState(
          {
            postSlug: {
              ...this.state.postSlug,
              available: false,
              loading: true,
            },
          },
          () => {
            const slug = customUrl || slugify(pageData.title);
            const splitted = slug.split("/");
            const url =
              pageData.language !== "en" && splitted[0] !== pageData.language
                ? `${pageData.language}/${slug}`
                : slug;
            castThunkAction<boolean>(this.props.getPostSlug(url)).then(
              (res) => {
                this.setState(
                  {
                    postSlug: {
                      loading: false,
                      available: true,
                      editMode: false,
                      urlText: "",
                    },
                  },
                  () => {
                    this.props.setPageData("url", res);
                  }
                );
              }
            );
          }
        );
      },
      fromSaveButton ? 0 : 500
    );
  }

  resetData() {
    this.setState(
      {
        messageType: MessageBarType.warning,
      },
      () => {
        this.props.initialPageData<IPost>({
          schema: DEFAULT_SCHEMA,
          layout: this.props.contentType?.layout || "",
          keywords: [],
          thumbnail: { src: "", alt: "", title: "", srcset: [] },
          contentType: this.props.contentTypeId,
          specFields: [],
          metaFields: this.getDefaultMetaFields(),
          title: "",
          queryIds: [],
          url: "",
          order: 20,
          status: StatusEnum.Published,
          seo: [],
          dynamics: [],
          isPrivate: false,
          language: this.props.defaultLanguage,
          relationId: "",
          searchArea: "",
        });
      }
    );
  }

  renderSpecFields() {
    return (
      <div style={{ margin: "0px -10px" }} className="meta-fields row">
        {(this.props.contentType?.specifications || []).map((item, index) => {
          const postSpec = (this.props.pageData?.specFields || []).find(
            (x) => x.name === item.name
          );
          return (
            <MetaField
              type={item.type as MetaTypeEnum}
              key={index}
              contentTypeId={this.props.contentTypeId}
              meta={
                {
                  ...item,
                  type: item.type as MetaTypeEnum,
                  data: postSpec?.data,
                } as IMeta
              }
              onChange={(data) => {
                const specFields = _.cloneDeep(
                  this.props.pageData?.specFields || []
                );
                const specFieldIndex = specFields.findIndex(
                  (x) => x.name === item.name
                );
                let specData = data;
                if (_.isArray(specData) && specData.length > 0) {
                  specData = specData.filter((x) => _.keys(x).length > 0);
                }
                if (!specFields[specFieldIndex]) {
                  specFields.push({
                    name: item.name,
                    type: item.type,
                    title: item.title,
                    data: specData,
                  });
                } else {
                  specFields[specFieldIndex].title = item.title;
                  specFields[specFieldIndex].data = specData;
                }
                this.props.setPageData("specFields", specFields);
              }}
              toggleModal={this.props.toggleModal}
              language={this.props.pageData?.language as string}
            />
          );
        })}
      </div>
    );
  }

  renderMetaFields() {
    return (
      <div style={{ margin: "0px -10px" }} className="meta-fields row">
        {(this.props.contentType?.metaFields || []).map((item, index) => {
          const postMeta = (this.props.pageData?.metaFields || []).find(
            (x) => x.name === item.name
          );
          return (
            <MetaField
              type={item.type}
              key={index}
              contentTypeId={this.props.contentTypeId}
              meta={
                {
                  ...item,
                  data: postMeta?.data,
                  contentType: postMeta?.contentType,
                } as IMeta
              }
              onChangeContentType={(contentType) => {
                const metaFields = _.cloneDeep(
                  this.props.pageData?.metaFields || []
                );
                const metaFieldIndex = metaFields.findIndex(
                  (x) => x.name === item.name
                );
                if (contentType) {
                  if (!metaFields[metaFieldIndex]) {
                    metaFields.push({
                      ...item,
                      data: item.data,
                      contentType,
                    });
                  } else {
                    metaFields[metaFieldIndex].contentType = contentType;
                  }
                  this.props.setPageData("metaFields", metaFields);
                }
              }}
              onChange={(data) => {
                const metaFields = _.cloneDeep(
                  this.props.pageData?.metaFields || []
                );
                const metaFieldIndex = metaFields.findIndex(
                  (x) => x.name === item.name
                );
                if (!metaFields[metaFieldIndex]) {
                  metaFields.push({
                    name: item.name,
                    type: item.type,
                    data,
                  });
                } else {
                  metaFields[metaFieldIndex].data = data;
                }
                this.props.setPageData("metaFields", metaFields);
              }}
              toggleModal={this.props.toggleModal}
              language={this.props.pageData?.language as string}
            />
          );
        })}
      </div>
    );
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir. Düzenleme sayfasına yönlendiriliyorsunuz...";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  onSubmit(isDraft?: boolean) {
    const { pageData, setPageErrors } = this.props;
    const validationSchema = _yup.object({
      title: _yup.string().required("Bu alan zorunludur!"),
      url: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(pageData, {
        abortEarly: false,
      })
      .then(() => {
        setPageErrors({});
        castThunkAction<IPost>(
          this.props.createPost({
            ...this.props.pageData,
            status: isDraft ? StatusEnum.Draft : StatusEnum.Published,
          })
        ).then((res) => {
          this.setState(
            {
              messageType: res?.url
                ? MessageBarType.success
                : MessageBarType.error,
            },
            () => {
              window.scrollTo({ top: 0 });
              if (res?.id) {
                setTimeout(() => {
                  this.props.history.push(
                    createContentTypePostEditLink(
                      this.props.contentTypeId,
                      res.url as string
                    )
                  );
                }, 1200);
              }
            }
          );
        });
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setPageErrors(formErrors);
      });
  }
  renderGeneralSettings() {
    return (
      <>
        <TextField
          className="custom-textfield"
          label="Arama Yapılacak Metin"
          placeholder="Arama Yapılacak Bir Metin Giriniz"
          value={this.props.pageData?.searchArea as string}
          onChange={(event: any) =>
            this.props.setPageData("searchArea", event.target.value as string)
          }
          multiline
          resizable={false}
        />
      </>
    );
  }

  renderSeoWrapper() {
    const items = [
      ...(this.props.pageData?.seo || []),
      { name: "", content: "" },
    ];
    return (
      <div className="meta-dropdown-values">
        {items.map((item, index) => (
          <div key={index} className="meta-dropdown-value row">
            <Dropdown
              className="custom-dropdown col"
              label="SEO Meta Adı"
              styles={{ label: { marginBottom: "1px !important" } }}
              options={seoMetaNames.map((x) => ({ key: x.key, text: x.key }))}
              selectedKey={item.name as string}
              onChange={(_, option) =>
                this.onSeoItemChange(index, "name", option?.key as string)
              }
            />
            <TextField
              className="custom-textfield"
              label="Arama Yapılacak Metin"
              placeholder="Arama Yapılacak Bir Metin Giriniz"
              value={this.props.pageData?.searchArea as string}
              onChange={(event: any) =>
                this.props.setPageData(
                  "searchArea",
                  event.target.value as string
                )
              }
              multiline
              resizable={false}
            />
            <TextField
              className="custom-textfield col"
              label="SEO Meta İçeriği"
              placeholder={
                seoMetaNames.find((x) => x.key === item.name)
                  ?.description as string
              }
              value={item.content as string}
              onChange={(event: any) =>
                this.onSeoItemChange(
                  index,
                  "content",
                  event.target.value as string
                )
              }
            />
            <i
              onClick={() => {
                const clonedItems = _.cloneDeep(this.props.pageData?.seo || []);
                clonedItems.splice(index, 1);
                this.props.setPageData("seo", clonedItems);
              }}
              className={
                index + 1 === items.length
                  ? "icon-close passive"
                  : "icon-close1"
              }
            />
          </div>
        ))}
      </div>
    );
  }

  renderTitle() {
    const { pageData, pageErrors, initialPageData, setPageData } = this.props;
    return (
      <div className="post-title">
        <TextField
          placeholder="İçerik Başlığı Giriniz"
          value={pageData.title || ""}
          className="custom-textfield border"
          errorMessage={pageErrors.title}
          onChange={(event: any) => {
            initialPageData({
              ...this.props.pageData,
              title: event.target.value,
              url: slugify(event.target.value),
            });
            this.createPostSlug();
          }}
        />
        {pageData.title && (
          <div className="post-url row">
            <span className="url-title">İçerik Adresi: </span>
            <span className="text">{getCurrentSiteUi()}/</span>
            <span className="url-content">
              {this.state.postSlug.editMode ? (
                <TextField
                  value={this.state.postSlug.urlText || ""}
                  className="custom-textfield border"
                  // errorMessage={pageData.url && typeof this.state.postSlug.available === "boolean" &&
                  //     !this.state.postSlug.available ? "Bu URL kullanımda!" : pageErrors.url}
                  onChange={(event: any) => {
                    this.setState({
                      postSlug: {
                        ...this.state.postSlug,
                        urlText: event.target.value,
                      },
                    });
                  }}
                />
              ) : (
                pageData.url || "-"
              )}
            </span>
            <div className="buttons row">
              {this.state.postSlug.editMode ? (
                <React.Fragment>
                  <DefaultButton
                    text="Kaydet"
                    disabled={this.state.postSlug.loading}
                    onClick={() => {
                      setPageData(
                        "url",
                        this.state.postSlug.urlText || slugify(pageData?.title)
                      );
                      this.createPostSlug(true);
                    }}
                  />
                  {!this.state.postSlug.loading && (
                    <span
                      onClick={() => {
                        this.setState({
                          postSlug: {
                            ...this.state.postSlug,
                            editMode: false,
                            urlText: "",
                          },
                        });
                      }}
                      className="cancel"
                    >
                      Vazgeç
                    </span>
                  )}
                </React.Fragment>
              ) : (
                <DefaultButton
                  text="Düzenle"
                  onClick={() => {
                    const splitted = pageData.url?.split("/");
                    const url =
                      pageData.url && pageData.language !== "en"
                        ? splitted.join("/").slice(splitted[0].length + 1) ||
                          pageData.url
                        : pageData.url;
                    this.setState({
                      postSlug: {
                        ...this.state.postSlug,
                        urlText: url,
                        editMode: true,
                      },
                    });
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderForm() {
    return (
      <div className="form-groups row">
         <div style={{ paddingTop: "0px" }} className="form-group col-md-12">
                    <FormAccordion showContent={true} title="Arama Ayarlamaları">
                        <div className="form-wrapper">
                            {this.renderGeneralSettings()}
                        </div>
                    </FormAccordion>
                </div>
        {this.props.contentType.metaFields &&
          this.props.contentType.metaFields.length > 0 && (
            <div style={{ paddingTop: "0px" }} className="form-group col-md-12">
              <FormAccordion title="Metalar">
                <div className="form-wrapper">{this.renderMetaFields()}</div>
              </FormAccordion>
            </div>
          )}
        <div style={{ paddingTop: "0px" }} className="form-group col-md-12">
          <FormAccordion showContent={false} title="SEO Ayarlamaları">
            <div className="form-wrapper">{this.renderSeoWrapper()}</div>
          </FormAccordion>
        </div>
      </div>
    );
  }

  renderFileManager() {
    return (
      <FileManager
        show={this.state.showFileManager}
        onDismiss={() => this.setState({ showFileManager: false })}
        activeFile={{
          id: "",
          fileName: this.props.pageData.thumbnail?.alt as string,
          fileExtension: "",
          fileSize: "",
        }}
        onSelectedFile={(file) => {
          this.props.setPageData("thumbnail", {
            src: file.fileName,
            alt: file.fileName,
            title: file.fileName,
          });
        }}
        fileType={FileTypes.Image}
      />
    );
  }

  render() {
    return (
      <PageLayout
        history={this.props.history}
        match={this.props.match}
        location={this.props.location}
      >
        <div className="page push-all">
          {this.renderFileManager()}
          <PageTitle
            title="Yeni İçerik Ekle"
            titleRenderer={() => (
              <span className="edit-title">
                <u
                  onClick={() =>
                    this.props.history.push(
                      createContentTypePostsLink(this.props.contentTypeId)
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {this.props.contentType?.title || "-"}
                </u>
                :<strong>Yeni İçerik Ekle</strong>
              </span>
            )}
            showBack
            backUrl={createContentTypePostsLink(this.props.contentTypeId)}
            history={this.props.history}
            subTitle="Yeni bir içerik eklemek için aşağıdaki bilgileri doldurunuz, dilerseniz taslak olarakta oluşturabilirsiniz."
          />
          <PrimaryButton
            onClick={() => {
              this.resetData();
            }}
            iconProps={{ iconName: "Add" }}
            className="absolute-create"
            text="Yeni İçerik Ekle"
            styles={{
              root: {
                background: "#62a98e",
                borderColor: "#5b9e85",
                color: "#fff",
              },
              rootHovered: {
                background: "#5b9e85",
                borderColor: "#5b9e85",
              },
              rootDisabled: {
                background: "#ececec",
              },
            }}
          />
          <div className="content row">
            <div className="left col no-padding">
              {this.renderMessageBar()}
              {this.renderTitle()}
              {this.renderForm()}
              <Designer />
            </div>
            <Sidebar
              categoryBar={{
                hide: true,
              }}
              publishBar={{
                onSave: (isDraft) => this.onSubmit(isDraft),
                disabledSaveButton:
                  this.state.messageType === MessageBarType.success ||
                  !this.state.postSlug.available ||
                  !this.props.pageData?.title
                    ? true
                    : false,
                hidePreviewButton: true,
                hideDeleteButton: true,
                showOrder: true,
                order: this.props.pageData?.order || 1,
                onChangeOrderIndex: (order) =>
                  this.props.setPageData("order", order),
                showIntegration: true,
                integrationId: this.props.pageData?.integrationId || "",
                onChangeIntegrationId: (value: string) =>
                  this.props.setPageData("integrationId", value),
              }}
              layoutBar={{
                layoutData: this.state.layoutData,
                layout: this.props.pageData.layout,
                onChangeLayout: (layout) => {
                  this.props.setPageData("layout", layout);
                },
              }}
              thumbnailBar={{
                onClickThumbnail: () =>
                  this.setState({ showFileManager: true }),
                onDeleteThumbnail: () =>
                  this.props.setPageData("thumbnail", { src: "", alt: "" }),
                onSave: (data) => this.props.setPageData("thumbnail", data),
                thumbnail: this.props.pageData.thumbnail,
              }}
              archiveBar={{
                hide: true,
              }}
            />
          </div>
        </div>
      </PageLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);

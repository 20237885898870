import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { RootState } from "app/redux/reducer";
import { MenuService } from "./service";
import { showMessage, toggleConfirmDialog, toggleLoader } from "app/redux/system/actions";
import { initialPageData } from "app/redux/page/actions";
import { MessageType } from "app/redux/system/types";
import { IMenu } from "./types";
import { HttpStatusCode, IList, IListFilter } from "app/shared";
import { mapMenuFromEntity, mapMenuFromModel } from "./mapping";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getMenu(menuId: string, language?: string, notFill?: boolean): ThunkResult<Promise<any>> {
    return async (dispatch, getState) => {
        const service = new MenuService();
        dispatch(toggleLoader());
        const response = await service.getMenu(menuId, language || getState().system.defaultLanguage);
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapMenuFromEntity(response.data.attributes["menu"] || {});
            if (!notFill) {
                dispatch(initialPageData<IMenu>(mapped));
                dispatch(toggleLoader());
            }
            return true;
        }
        dispatch(toggleLoader());
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}

export function getMenuByLanguage(pageData: IMenu, language: string, isOverride?: boolean): ThunkResult<Promise<any>> {
    return async (dispatch, getState) => {
        const service = new MenuService();
        dispatch(toggleLoader());
        const response = await service.getMenu(pageData.id as string, isOverride ? getState().system.defaultLanguage : language);
        if (response.status === HttpStatusCode.OK) {
            const mapped = mapMenuFromEntity(response.data.attributes["menu"] || {});
            const data = { ...mapped, language: language, id: isOverride ? pageData.id : mapped.id };
            dispatch(initialPageData<IMenu>(data));
            if (isOverride) {
                dispatch(updateMenu(data));
            }
            dispatch(toggleLoader());
            return true;
        }
        else {
            dispatch(toggleConfirmDialog(true, {
                title: "Uyarı!",
                text: `Geçerli dil'e (${language}) ait bir menü bulunamadı. Varsayılan dil üzerinden seçtiğiniz dile göre menü oluşturulsun mu?`,
                button: {
                    approveText: "Varsayılan Dil'den Oluştur"
                },
                callback: () => {
                    dispatch(createMenu({ ...pageData, id: "", title: `${pageData.title} (${language.toUpperCase()})`, language })).then(res => {
                        if (res) {
                            dispatch(getMenu(res.id as string, language));
                        }
                    });
                    return true;
                }
            }))
        }
        dispatch(toggleLoader());
        return false;
    };
}


export function getMenus(options: IListFilter): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new MenuService();
        dispatch(toggleLoader());
        const response = await service.getMenus(options);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response?.data?.attributes?.list || []).map((item) =>
                mapMenuFromEntity(item)
            );
            dispatch(initialPageData<IList<IMenu[]>>({
                list: mapped,
                pageSize: response.data.attributes.pageSize,
                totalCount: response.data.attributes.total,
                pageCount: Math.ceil(response.data.attributes.total / response.data.attributes.pageSize)
            }));
            return true;
        }
        return false;
    };
}

export function createMenu(request: IMenu): ThunkResult<Promise<IMenu | null>> {
    return async (dispatch, getState) => {
        const service = new MenuService();
        dispatch(toggleLoader());
        const response = await service.createMenu(mapMenuFromModel(request));
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return mapMenuFromEntity(response.data.attributes["menu"] || {});
        }
        return null;
    };
}

export function updateMenu(request: IMenu): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new MenuService();
        dispatch(toggleLoader());
        const response = await service.updateMenu(mapMenuFromModel(request));
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        return false;
    };
}

export function deleteMenu(menuId: string): ThunkResult<Promise<boolean>> {
    return async (dispatch, getState) => {
        const service = new MenuService();
        dispatch(toggleLoader());
        const response = await service.deleteMenu(menuId);
        dispatch(toggleLoader());
        if (response.status === HttpStatusCode.OK) {
            return true;
        }
        dispatch(showMessage("Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!", "Bir Hata Oluştu!", MessageType.ERROR))
        return false;
    };
}
import _ from "lodash";
import { PropertyDefinition, PureSchemaObject, SchemaObject, SchemaTree } from "app/library/layout-builder";
import { container } from "components/cms/container";

export function reduceNodeProps(distinctEntryProps: PropertyDefinition[], nodeProps: object, fromChildren?: boolean) {
    const clonedNodeProps = fromChildren ? nodeProps : _.cloneDeep(nodeProps)

    if (clonedNodeProps) {
        _.keys(clonedNodeProps).forEach(propKey => {
            let value = clonedNodeProps[propKey];
            const defaultValue = getDefaultValue(distinctEntryProps, propKey);
            if (defaultValue !== undefined && defaultValue === value) {
                delete clonedNodeProps[propKey];
            }
            if (typeof value === "object") {
                reduceNodeProps(distinctEntryProps, value, true);
                if (!_.keys(value)?.length) {
                    delete clonedNodeProps[propKey]
                }
            }
            if (propKey === "contentType" || propKey === "external" || propKey === "defaultElement" || (propKey === "target" && value === "_self") || (propKey === "hideRuntime" && value === false) || value === undefined || value === null) {
                delete clonedNodeProps[propKey];
            }
        })
    }

    return clonedNodeProps;
}

function getDefaultValue(entryProps: PropertyDefinition[], nodePropName: string) {
    return entryProps.find(item => item.name === nodePropName)?.default;
}

export function distinctEntryProperties(properties: PropertyDefinition[]) {
    let distinctProperties: PropertyDefinition[] = [];

    (properties || []).forEach(item => {
        distinctProperties.push(item);
        if (item.mapping.keys && item.mapping.keys.length > 0) {
            item.mapping.keys.forEach(child => {
                distinctProperties.push(child);
                if (child.mapping.keys) {
                    distinctProperties = [...distinctProperties, ...distinctEntryProperties(child.mapping.keys)]
                }
            })
        }
        if (item.mapping.children && item.mapping.children.length > 0) {
            item.mapping.children.forEach(child => {
                distinctProperties.push(child);
                if (child.mapping.children) {
                    distinctProperties = [...distinctProperties, ...distinctEntryProperties(child.mapping.children)]
                }
            })
        }
    })
    return distinctProperties.filter((data, index) => distinctProperties.indexOf(data) === index)
}

export function convertToPureSchema(schemaObject: SchemaObject[]): PureSchemaObject[] {
    const clonedSchema = _.cloneDeep(schemaObject);
    let pureSchema = clonedSchema.find(x => x.name === "layout-screen")?.children || clonedSchema;
    let schemaTree = SchemaTree.parse(pureSchema);
    const distinctNodes: SchemaObject[] = schemaTree.getDistinctNodes().filter(x => !x.getProp("hideRuntime"));

    distinctNodes.forEach(node => {
        const entry = container.entries.find(x => x.name === node.name)
        if (node.props) {
            node.props = reduceNodeProps(distinctEntryProperties(entry?.properties || []), node.props)
        }
        if (node.source) {
            _.keys(node.source).forEach(key => {
                const current = node.source ? _.cloneDeep(node.source[key]) : {};
                if (current.relation) {
                    delete current.relation
                }
                if (current.type && node.props) {
                    node.props[key] = current.criteria ? current.ref : current
                }
            })
        }
        delete node.source;

    })

    return schemaTree.toPureArray();
}

import { IContentType } from 'app/services/content-type';
import { IList } from 'app/shared';
import { ContentTypeActionTypes, ContentTypeStore, SET_CONTENT_TYPE, SET_CONTENT_TYPES } from './types';

const initialState: ContentTypeStore = {
    contentTypes: {} as IList<IContentType[]>,
    contentType: {} as IContentType
};

export function contentTypeReducer(state = initialState, action: ContentTypeActionTypes): ContentTypeStore {
    switch (action.type) {
        case SET_CONTENT_TYPES:
            return {
                ...state,
                contentTypes: action.contentTypes
            };
        case SET_CONTENT_TYPE:
            return {
                ...state,
                contentType: action.contentType
            };
        default:
            return state;
    }
}
import * as React from "react";
import { Toggle } from "office-ui-fabric-react";

export type OnChangeToggleField = (value: any) => void;
export type OnChangeWidgetToggleField = (value: any) => void;

export interface PureToggleFieldProps {
	label: string;
	value: any;
	onChange: OnChangeToggleField;
	text: string;
	description: string;
	onText?: string;
	offText?: string;
}

export class PureToggleField extends React.Component<PureToggleFieldProps> {

	render() {
		return (
			<div className="property-item row">
				<div title={this.props.label} className="property-text">
					<div className="title">{this.props.text || this.props.label.split(/(?=[A-Z])/).map(item => item.charAt(0).toUpperCase() + item.slice(1) + " ")}</div>
					<div className="sub-title">{this.props.description || this.props.label}</div>
				</div>
				<div className="col">
					<Toggle
						inlineLabel={true}
						className="custom-toggle align-right"
						checked={this.props.value}
						onChange={(_, checked) => this.props.onChange(checked)}
						onText={this.props.onText}
						offText={this.props.offText}
						styles={{ text: { top: "2px !important" } }}
					/>
				</div>
			</div>
		);
	}
}

import { GridContainer } from "./GridContainer";
import { Row } from "./Row";
import { Column } from "./Column";
import { Button } from "./Button";
import { Heading } from "./Heading";
import { Image } from "./Image";
import { Paragraph } from "./Paragraph";
import { Icon } from "./Icon";
import { LayoutContainer } from "./LayoutContainer";
import { Video } from "./Video";
import { Iframe } from "./Iframe";
import { Hr } from "./Hr";
import { Popup } from "./Popup";
import { Section } from "./Section";
import {
  Footer,
  Header,
  CardList,
  Card,
  PageHeader,
  CardSlider,
} from "./widgets";
import { Html } from "./Html";
import { LayoutScreen } from "./LayoutScreen";
import { IconList } from "./IconList";
import { PhotoGallery } from "./PhotoGallery";
import { PureContent } from "./PureContent";
import { Style } from "./Style";
import { Accordion } from "./Accordion";
import { Slideshow } from "./Slideshow";
import { Redirection } from "./Redirection";
import { Container, ContainerEntry } from "app/library/layout-builder";
import { Profile } from "./widgets/Profile";
import { Scrollbar } from "./Scrollbar";
import { MainSlider } from "./widgets/MainSlider";
import { ContentGrid } from "./widgets/ContentGrid/ContentGrid";
import { MediaList } from "./widgets/MediaList/MediaList";
import { Boards } from "./widgets/Boards";
import { BoardPanel } from "./widgets/BoardPanel";
import { BoardSidebar } from "./widgets/BoardSidebar";
import { News } from "./widgets/News";
import { Hero } from "./widgets/Hero";
import { ExhibitionCalendar } from "./widgets/ExhibitionCalendar";
import { FairSession } from "./widgets/FairSession";
import { StandEnquiryForm } from "./widgets/StandEnquiryForm";
import { MainSlideshow } from "./widgets/MainSlideshow";
import { HomeAboutUs } from "./widgets/HomeAboutUs";
import { HomeSahaMatch } from "./widgets/HomeSahaMatch";
import { HomeVirtualExhibition } from "./widgets/HomeVirtualExhibition";
import { HomeWhyExhibit } from "./widgets/HomeWhyExhibit";
import { HomeActivities } from "./widgets/HomeActivities";
import { HomeContactUs } from "./widgets/HomeContactUs";
import { HomeHighlights } from "./widgets/HomeHighlights";
import { HotelList } from "./widgets/HotelList/HotelList";
import { MaterialList } from "./widgets/MaterialList/MaterialList";
import { ExhibitorList } from "./widgets/ExhibitorList/ExhibitorList";
import { ExhibitorDetail } from "./widgets/ExhibitorDetail/ExhibitorDetail";
import { VisitingHours } from "./widgets/VisitingHours/VisitingHours";
import { VenueList } from "./widgets/VenueList/VenueList";
import { GallerySlideshow } from "./widgets/GallerySlideshow/GallerySlideshow";
import { PresentationForm } from "./widgets/PresentationForm/PresentationForm";
import { SupporterList } from "./widgets/SupporterList/SupporterList";
import { SupporterListForAll } from "./widgets/SupporterListForAll/SupporterListForAll"
import { SupporterListWithSlider } from "./widgets/SupporterListWithSlider/SupporterListWithSlider"
import { SupporterForm } from "./widgets/SupporterForm/SupporterForm";
import { SponsorList } from "./widgets/SponsorList/SponsorList";
import { SponsorshipListForAll } from "./widgets/SponsorshipListForAll/SponsorshipListForAll"
import { SponsorshipListForPress } from "./widgets/SponsorshipListForPress/SponsorshipListForPress"
import { SponsorshipListWithTabs } from "./widgets/SponsorshipListWithTabs/SponsorshipListWithTabs"
import { ContactForm } from "./widgets/ContactForm/ContactForm";
import { PressList } from "./widgets/PressList/PressList";
import { Features } from "./widgets/Features/Features";
import { Breadcrumbs } from "./widgets/Breadcrumbs/Breadcrumbs";
import { HomeMainSponsor } from "./widgets/HomeMainSponsor";
import { PhotoGalleryWithTabs } from "./widgets/PhotoGalleryWithTabs";
import { SponsorListWithTabs } from "./widgets/SponsorListWithTabs";
import { HallList } from "./widgets/HallList/HallList";
import { SpecialPressNews } from "./widgets/SpecialPressNews";
import { SignatureEvent } from "./widgets/SignatureEvent";


export const container = new Container([
  ContainerEntry.buildFromComponent(Accordion),
  ContainerEntry.buildFromComponent(BoardPanel),
  ContainerEntry.buildFromComponent(BoardSidebar),
  ContainerEntry.buildFromComponent(Boards),
  ContainerEntry.buildFromComponent(Button),
  ContainerEntry.buildFromComponent(Card),
  ContainerEntry.buildFromComponent(CardList),
  ContainerEntry.buildFromComponent(CardSlider),
  ContainerEntry.buildFromComponent(Column),
  ContainerEntry.buildFromComponent(ContentGrid),
  ContainerEntry.buildFromComponent(Footer),
  ContainerEntry.buildFromComponent(GridContainer),
  ContainerEntry.buildFromComponent(Header),
  ContainerEntry.buildFromComponent(Heading),
  ContainerEntry.buildFromComponent(Hr),
  ContainerEntry.buildFromComponent(Html),
  ContainerEntry.buildFromComponent(Icon),
  ContainerEntry.buildFromComponent(IconList),
  ContainerEntry.buildFromComponent(Iframe),
  ContainerEntry.buildFromComponent(Image),
  ContainerEntry.buildFromComponent(LayoutContainer),
  ContainerEntry.buildFromComponent(LayoutScreen),
  ContainerEntry.buildFromComponent(MediaList),
  ContainerEntry.buildFromComponent(MainSlider),
  ContainerEntry.buildFromComponent(News),
  ContainerEntry.buildFromComponent(PageHeader),
  ContainerEntry.buildFromComponent(Paragraph),
  ContainerEntry.buildFromComponent(PhotoGallery),
  ContainerEntry.buildFromComponent(Profile),
  ContainerEntry.buildFromComponent(PureContent),
  ContainerEntry.buildFromComponent(Redirection),
  ContainerEntry.buildFromComponent(Row),
  ContainerEntry.buildFromComponent(Scrollbar),
  ContainerEntry.buildFromComponent(Section),
  ContainerEntry.buildFromComponent(Popup),
  ContainerEntry.buildFromComponent(Slideshow),
  ContainerEntry.buildFromComponent(Style),
  ContainerEntry.buildFromComponent(Video),
  ContainerEntry.buildFromComponent(Hero),
  ContainerEntry.buildFromComponent(ExhibitionCalendar),
  ContainerEntry.buildFromComponent(FairSession),
  ContainerEntry.buildFromComponent(SignatureEvent),
  ContainerEntry.buildFromComponent(StandEnquiryForm),
  ContainerEntry.buildFromComponent(MainSlideshow),
  ContainerEntry.buildFromComponent(HomeAboutUs),
  ContainerEntry.buildFromComponent(HomeSahaMatch),
  ContainerEntry.buildFromComponent(HomeVirtualExhibition),
  ContainerEntry.buildFromComponent(HomeWhyExhibit),
  ContainerEntry.buildFromComponent(HomeContactUs),
  ContainerEntry.buildFromComponent(HomeActivities),
  ContainerEntry.buildFromComponent(HomeHighlights),
  ContainerEntry.buildFromComponent(HotelList),
  ContainerEntry.buildFromComponent(MaterialList),
  ContainerEntry.buildFromComponent(ExhibitorList),
  ContainerEntry.buildFromComponent(ExhibitorDetail),
  ContainerEntry.buildFromComponent(VisitingHours),
  ContainerEntry.buildFromComponent(VenueList),
  ContainerEntry.buildFromComponent(GallerySlideshow),
  ContainerEntry.buildFromComponent(PresentationForm),
  ContainerEntry.buildFromComponent(SupporterList),
  ContainerEntry.buildFromComponent(SupporterListForAll),
  ContainerEntry.buildFromComponent(SupporterListWithSlider),
  ContainerEntry.buildFromComponent(SupporterForm),
  ContainerEntry.buildFromComponent(SponsorList),
  ContainerEntry.buildFromComponent(SponsorshipListForAll),
  ContainerEntry.buildFromComponent(SponsorshipListForPress),
  ContainerEntry.buildFromComponent(SponsorshipListWithTabs),
  ContainerEntry.buildFromComponent(ContactForm),
  ContainerEntry.buildFromComponent(PressList),
  ContainerEntry.buildFromComponent(Features),
  ContainerEntry.buildFromComponent(Breadcrumbs),
  ContainerEntry.buildFromComponent(HomeMainSponsor),
  ContainerEntry.buildFromComponent(PhotoGalleryWithTabs),
  ContainerEntry.buildFromComponent(SponsorListWithTabs),
  ContainerEntry.buildFromComponent(HallList),
  ContainerEntry.buildFromComponent(SpecialPressNews),
]);

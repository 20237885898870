import { Component } from 'react';
import { MetaTypeEnum } from 'app/shared';
import { getMeta } from './Fields/constants';
import { BaseMetaFieldsProps } from './Fields/types';
import MetaEditor from './Fields/MetaEditor';
import MetaMultiImage from './Fields/MetaMultiImage';
import MetaSingleFile from './Fields/MetaSingleFile';
import MetaDate from './Fields/MetaDate';
import MetaTextField from './Fields/MetaTextField';
import MetaCheckbox from './Fields/MetaCheckbox';
import MetaLinkWizard from './Fields/MetaLinkWizard';
import MetaDropdown from './Fields/MetaDropdown';
import MetaColor from './Fields/MetaColor';
import MetaMultiText from './Fields/MetaMultiText';
import MetaComboBox from './Fields/MetaComboBox';
import MetaPhotoGallery from './Fields/MetaPhotoGallery';
import MetaMultiFile from './Fields/MetaMultiFile';
import MetaFile from './Fields/MetaFile';
import MetaPhotoSlider from './Fields/MetaPhotoSlider';

export default class MetaField extends Component<BaseMetaFieldsProps> {

    renderMetaFieldByType() {
        switch (this.props.type) {
            case MetaTypeEnum.Text:
            case MetaTypeEnum.TextArea:
            case MetaTypeEnum.Number:
                return (
                    <MetaTextField {...this.props} />
                );
            case MetaTypeEnum.Editor:
                return (
                    <MetaEditor {...this.props} />
                );
            case MetaTypeEnum.SingleImage:
            case MetaTypeEnum.SingleVideo:
                return (
                    <MetaSingleFile {...this.props} />
                );
            case MetaTypeEnum.File:
                return (
                    <MetaFile {...this.props} />
                );
            case MetaTypeEnum.MultiImage:
                return (
                    <MetaMultiImage {...this.props} />
                );
            case MetaTypeEnum.MultiFile:
                return (
                    <MetaMultiFile {...this.props} />
                );
            case MetaTypeEnum.PhotoSlider:
                return (
                    <MetaPhotoSlider {...this.props} />
                );
            case MetaTypeEnum.Date:
                return (
                    <MetaDate {...this.props} />
                );
            case MetaTypeEnum.Checkbox:
                return (
                    <MetaCheckbox {...this.props} />
                );
            case MetaTypeEnum.Link:
                return (
                    <MetaLinkWizard {...this.props} />
                );
            // case MetaTypeEnum.Icon:
            //     return (
            //         <MetaIcon {...this.props} />
            //     );
            case MetaTypeEnum.PhotoGallery:
                return (
                    <MetaPhotoGallery {...this.props} />
                );
            case MetaTypeEnum.Dropdown:
                return (
                    <MetaDropdown {...this.props} />
                );
            case MetaTypeEnum.ComboBox:
                return (
                    <MetaComboBox {...this.props} />
                );
            // case MetaTypeEnum.IconList:
            //     return (
            //         <MetaIconList {...this.props} />
            //     );
            case MetaTypeEnum.Color:
                return (
                    <MetaColor {...this.props} />
                );
            case MetaTypeEnum.MultiText:
                return (
                    <MetaMultiText {...this.props} />
                );
            // case MetaTypeEnum.Pricing:
            //     return (
            //         <MetaPricing {...this.props} />
            //     );
            default:
                return null;
        }
    }

    // renderInsertionModal() {
    //     switch(this.props.meta.type) {
    //         default:
    //             return null;
    //     }
    // }

    render() {
        return (
            <div className={`meta-field col-md-${this.props.meta.column || 4}`}>
                <div className="title row">
                    <div className="left-title">
                        <i className={getMeta(this.props.type as MetaTypeEnum)?.icon}></i>
                        {this.props.meta.title}
                        <div className="sub-title">{this.props.meta.name}</div>
                    </div>
                </div>
                <div className="content">
                    {this.renderMetaFieldByType()}
                </div>
            </div>
        )
    }
}

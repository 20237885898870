import React from 'react';

interface CountDownProps {
  onTimeout: () => void;
}

class CountDown extends React.Component<CountDownProps> {
  state = {
    loginError: false,
    loginLoader: false
  }

  interval = 0
  spanRef = React.createRef<HTMLSpanElement>()

  componentDidMount() {
    const datetime = new Date(Date.now() + 1000 * 60 * 2).getTime()
    const interval = setInterval(() => {
      const now = new Date().getTime()
      const distance = datetime - now

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (this.spanRef.current) {
        this.spanRef.current.innerText = `${minutes > 9 ? minutes : `0${minutes}` }:${seconds > 9 ? seconds : `0${seconds}`}`
      }

      if (distance <= 0) {
        if (this.spanRef.current) {
          this.spanRef.current.innerText = "Süre dolmuştur!";
        }
        clearInterval(interval);
        this.props.onTimeout();
      }
    }, 1500);
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return <h3 style={{ fontWeight: 400, margin: 0 }}>Kalan süre: <span ref={this.spanRef}>2:00</span></h3>;
  }
}

export default CountDown;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  DefaultButton,
  Dropdown,
} from "office-ui-fabric-react";
import qs from "query-string";
import * as _yup from "yup";
import { MetaTypeEnum, StatusEnum } from "app/shared";
import _ from "lodash";
import moment from "moment";
import { getCategories } from "app/services/category";
import {
  createPost,
  deletePost,
  duplicatePost,
  getPost,
  getPostByLanguage,
  getPostSlug,
  IPost,
  updatePost,
} from "app/services/post";
import { getContentType, IContentType, IMeta } from "app/services/content-type";
import { getLayoutsForLocal, ILayout } from "app/services/layout";
import { FileTypes } from "app/services/file-manager";
import { RootState } from "app/redux/reducer";
import {
  initialPageData,
  setPageData,
  setPageErrors,
} from "app/redux/page/actions";
import { toggleConfirmDialog } from "app/redux/system/actions";
import { toggleModal } from "app/redux/modal/actions";
import { castThunkAction } from "app/utils/casting";
import {
  createContentTypePostEditLink,
  createContentTypePostNewLink,
  createContentTypePostsLink,
} from "app/utils/linking";
import MetaField from "components/customs/MetaFields/MetaField";
import { seoMetaNames } from "../ThemeSettings/helpers";
import FormAccordion from "components/customs/FormAccordion";
import FileManager from "components/customs/FileManager/FileManager";
import PageTitle from "components/customs/PageTitle";
import { Designer } from "components/customs/Designer";
import Sidebar from "components/customs/Sidebar/Sidebar";
import { RouteComponentProps } from "react-router-dom";
import { getCurrentSiteUi } from "app/utils/service";
import PageLayout from "containers/PageLayout";
import { IThemeSettings, getConfiguration } from "app/services/configuration";
import TranslatorPostBar from "components/customs/TranslatorBar/TranslatorPostBar";
import { slugify } from "app/shared/functions";

function mapStateToProps(
  state: RootState,
  ownProps: RouteComponentProps<{ contentTypeId: string; postId: string }>
) {
  return {
    ...ownProps,
    defaultLanguage: state.system.defaultLanguage,
    contentTypeId: ownProps.match.params.contentTypeId,
    postId: ownProps.match.params.postId,
    language: state.system.language,
    pageData: state.page.pageData as IPost,
    pageErrors: state.page.pageErrors,
    categories: state.category.categories,
    contentType: state.contentType.contentType,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setPageData,
        setPageErrors,
        initialPageData,
        updatePost,
        getContentType,
        getLayoutsForLocal,
        getPost,
        toggleConfirmDialog,
        deletePost,
        getPostSlug,
        createPost,
        duplicatePost,
        toggleModal,
        getCategories,
        getPostByLanguage,
        getConfiguration,
      },
      dispatch
    ),
  };
}

type EditPostProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface OwnState {
  messageType: MessageBarType;
  layoutData: ILayout[];
  showFileManager: boolean;
  configurationData: IThemeSettings;
  postSlug: {
    editMode: boolean;
    loading: boolean;
    available: boolean | string;
    urlText: string;
  };
}

class EditPost extends Component<EditPostProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
    layoutData: [] as ILayout[],
    configurationData: {} as IThemeSettings,
    showFileManager: false,
    postSlug: {
      editMode: false,
      loading: false,
      available: "",
      urlText: "",
    },
  };

  UNSAFE_componentWillMount() {
    castThunkAction<IContentType>(
      this.props.getContentType(this.props.contentTypeId, true)
    ).then(() => {
      const qString = qs.parse(this.props.history.location.search);
      if (qString && qString.url) {
        castThunkAction(
          this.props.getPost(qString.url as string, this.getDefaultMetaFields())
        ).then(() => {
          castThunkAction<ILayout[]>(
            this.props.getLayoutsForLocal(this.props.pageData.language)
          ).then((layoutResponse) => {
            this.setState({
              layoutData: layoutResponse,
            });
          });
          this.getThemeSettings();
        });
      }
    });
  }

  async getThemeSettings() {
    castThunkAction<IThemeSettings>(this.props.getConfiguration(true)).then(
      (configuration) => {
        if (configuration) {
          this.setState({
            configurationData: configuration,
          });
        }
      }
    );
  }

  getDefaultMetaFields() {
    return (this.props.contentType?.metaFields || []).map((item) => ({
      ...item,
      data: item.default,
    }));
  }

  onChangeLanguage(language: string) {
    if (language !== this.props.pageData.language) {
      this.props.getPostByLanguage(this.props.pageData, language);
      castThunkAction<ILayout[]>(this.props.getLayoutsForLocal(language)).then(
        (layoutResponse) => {
          this.setState({
            layoutData: layoutResponse,
          });
        }
      );
    }
  }

  onCloneLanguage() {
    this.props.getPostByLanguage(
      this.props.pageData,
      this.props.pageData.language,
      true
    );
  }

  onDuplicatePost() {
    castThunkAction<IPost>(
      this.props.duplicatePost(this.props.pageData?.url)
    ).then((res) => {
      if (res) {
        this.props.history.push(
          createContentTypePostEditLink(res.contentType, res.url)
        );
        this.props.initialPageData(res);
      }
    });
  }

  onSeoItemChange(index: number, key: string, value: string) {
    const clonedItems = _.cloneDeep(this.props.pageData?.seo || []);
    clonedItems[index] = {
      ...clonedItems[index],
      [key]: value,
    };
    this.props.setPageData("seo", clonedItems);
  }

  createPostSlug() {
    const urlText =
      this.props.pageData.language !== "en"
        ? `${this.props.pageData.language}/${
            this.state.postSlug.urlText || slugify(this.props.pageData.title)
          }`
        : this.state.postSlug.urlText;

    this.setState(
      {
        postSlug: {
          ...this.state.postSlug,
          available: false,
          loading: true,
          urlText,
        },
      },
      () => {
        if (this.props.pageData.initalUrl !== this.state.postSlug.urlText) {
          castThunkAction<string>(
            this.props.getPostSlug(
              this.state.postSlug.urlText ||
                `${
                  this.props.pageData.language !== "en"
                    ? this.props.pageData.language + "/"
                    : ""
                }${slugify(this.props.pageData.title)}`
            )
          ).then((res) => {
            const responseUrl =
              this.props.pageData.language === "en"
                ? res
                : res.replace(`${this.props.pageData.language}/`, "");
            this.setState(
              {
                postSlug: {
                  loading: false,
                  available: true,
                  editMode: false,
                  urlText: "",
                },
              },
              () => {
                this.props.setPageData(
                  "url",
                  responseUrl ? res : this.props.pageData?.initalUrl
                );
              }
            );
          });
        } else {
          this.setState(
            {
              postSlug: {
                loading: false,
                available: true,
                editMode: false,
                urlText: "",
              },
            },
            () => {
              this.props.setPageData("url", this.props.pageData?.initalUrl);
            }
          );
        }
      }
    );
  }

  renderGeneralSettings() {
    return (
      <>
        <TextField
          className="custom-textfield"
          label="Arama Yapılacak Metin"
          placeholder="Arama Yapılacak Bir Metin Giriniz"
          value={this.props.pageData?.searchArea as string}
          onChange={(event: any) =>
            this.props.setPageData("searchArea", event.target.value as string)
          }
          multiline
          resizable={false}
        />
      </>
    );
  }

  renderSpecFields() {
    return (
      <div style={{ margin: "0px -10px" }} className="meta-fields row">
        {(this.props.contentType?.specifications || []).map((item, index) => {
          const postSpec = (this.props.pageData?.specFields || []).find(
            (x) => x.name === item.name
          );
          return (
            <MetaField
              type={item.type as MetaTypeEnum}
              key={index}
              contentTypeId={this.props.contentTypeId}
              meta={
                {
                  ...item,
                  type: item.type as MetaTypeEnum,
                  data: postSpec?.data,
                } as IMeta
              }
              onChange={(data) => {
                const specFields = _.cloneDeep(
                  this.props.pageData?.specFields || []
                );
                const specFieldIndex = specFields.findIndex(
                  (x) => x.name === item.name
                );
                let specData = data;
                if (_.isArray(specData) && specData.length > 0) {
                  specData = specData.filter((x) => _.keys(x).length > 0);
                }
                if (!specFields[specFieldIndex]) {
                  specFields.push({
                    name: item.name,
                    type: item.type,
                    title: item.title,
                    data: specData,
                  });
                } else {
                  specFields[specFieldIndex].title = item.title;
                  specFields[specFieldIndex].data = specData;
                }
                this.props.setPageData("specFields", specFields);
              }}
              toggleModal={this.props.toggleModal}
              language={this.props.pageData?.language as string}
            />
          );
        })}
      </div>
    );
  }

  renderMetaFields() {
    return (
      <div style={{ margin: "0px -10px" }} className="meta-fields row">
        {(this.props.contentType?.metaFields || []).map((item, index) => {
          const postMeta = (this.props.pageData?.metaFields || []).find(
            (x) => x.name === item.name
          );

          return (
            <MetaField
              type={item.type}
              key={index}
              contentTypeId={this.props.contentTypeId}
              meta={
                {
                  ...item,
                  data: postMeta?.data,
                  contentType: postMeta?.contentType,
                } as IMeta
              }
              onChangeContentType={(contentType) => {
                const metaFields = _.cloneDeep(
                  this.props.pageData?.metaFields || []
                );
                const metaFieldIndex = metaFields.findIndex(
                  (x) => x.name === item.name
                );
                if (contentType) {
                  if (!metaFields[metaFieldIndex]) {
                    metaFields.push({
                      ...item,
                      data: item.data,
                      contentType,
                    });
                  } else {
                    metaFields[metaFieldIndex].contentType = contentType;
                  }
                  this.props.setPageData("metaFields", metaFields);
                }
              }}
              onChange={(data) => {
                const metaFields = _.cloneDeep(
                  this.props.pageData?.metaFields || []
                );
                const metaFieldIndex = metaFields.findIndex(
                  (x) => x.name === item.name
                );
                if (!metaFields[metaFieldIndex]) {
                  metaFields.push({
                    name: item.name,
                    type: item.type,
                    data: data,
                  });
                } else {
                  metaFields[metaFieldIndex].data = data;
                }
                this.props.setPageData("metaFields", metaFields);
              }}
              toggleModal={this.props.toggleModal}
              language={this.props.pageData?.language as string}
            />
          );
        })}
      </div>
    );
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Aşağıdaki değerler üzerinden düzenleme yapabilirsiniz.";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  renderTranslatorBar() {
    return (
      <TranslatorPostBar
        languages={this.state.configurationData?.config?.languages?.map(
          (item) => item.code?.split("-")[0]
        )}
        onChange={(language) => this.onChangeLanguage(language)}
        toggleDialog={this.props.toggleConfirmDialog}
        current={this.props.pageData.language}
        onClone={() => this.onCloneLanguage()}
        default={this.props.defaultLanguage}
      />
    );
  }

  onDelete() {
    this.props.toggleConfirmDialog(true, {
      callback: () => {
        let postData = this.props.pageData;
        if (this.props.pageData.language !== this.props.defaultLanguage) {
          castThunkAction<IPost>(
            this.props.getPost(
              this.props.pageData.url as string,
              this.getDefaultMetaFields(),
              this.props.defaultLanguage,
              true
            )
          ).then((response) => {
            postData = response;
            castThunkAction(
              this.props.deletePost(this.props.pageData.id as string)
            ).then((res) => {
              if (res) {
                this.props.initialPageData(postData);
              }
            });
          });
        } else {
          castThunkAction(
            this.props.deletePost(this.props.pageData.id as string)
          ).then((res) => {
            if (res) {
              this.props.history.push(
                createContentTypePostsLink(this.props.contentTypeId)
              );
            }
          });
        }
      },
    });
  }
  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  onSubmit(isDraft?: boolean) {
    const { pageData, setPageErrors } = this.props;
    const validationSchema = _yup.object({
      title: _yup.string().required("Bu alan zorunludur!"),
      url: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(pageData, {
        abortEarly: false,
      })
      .then(() => {
        setPageErrors({});
        castThunkAction(this.props.updatePost({ ...this.props.pageData, status: isDraft ? StatusEnum.Draft : StatusEnum.Published }, this.props.history)).then(res => {
          window.scrollTo({ top: 0 });
          this.setState({
            messageType: res ? MessageBarType.success : MessageBarType.error,
          });
        });
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setPageErrors(formErrors);
      });
  }

  renderSeoWrapper() {
    const items = [
      ...(this.props.pageData?.seo || []),
      { name: "", content: "" },
    ];
    return (
      <div className="meta-dropdown-values">
        {items.map((item, index) => (
          <div key={index} className="meta-dropdown-value row">
            <Dropdown
              className="custom-dropdown col"
              label="SEO Meta Adı"
              styles={{ label: { marginBottom: "1px !important" } }}
              options={seoMetaNames.map((x) => ({ key: x.key, text: x.key }))}
              selectedKey={item.name as string}
              onChange={(_, option) =>
                this.onSeoItemChange(index, "name", option?.key as string)
              }
            />
            <TextField
              className="custom-textfield"
              label="Arama Yapılacak Metin"
              placeholder="Arama Yapılacak Bir Metin Giriniz"
              value={this.props.pageData?.searchArea as string}
              onChange={(event: any) =>
                this.props.setPageData(
                  "searchArea",
                  event.target.value as string
                )
              }
              multiline
              resizable={false}
            />
            <TextField
              className="custom-textfield col"
              label="SEO Meta İçeriği"
              placeholder="ÖR: Go Turkey"
              value={item.content as string}
              onChange={(event: any) =>
                this.onSeoItemChange(index, "content", event.target.value)
              }
            />
            <i
              onClick={() => {
                const clonedItems = _.cloneDeep(this.props.pageData?.seo || []);
                clonedItems.splice(index, 1);
                this.props.setPageData("seo", clonedItems);
              }}
              className={
                index + 1 === items.length
                  ? "icon-close passive"
                  : "icon-close1"
              }
            />
          </div>
        ))}
      </div>
    );
  }

  renderTitle() {
    const { pageData, pageErrors, setPageData } = this.props;
    return (
      <div className="post-title">
        <TextField
          placeholder="İçerik Başlığı Giriniz"
          value={pageData.title || ""}
          className="custom-textfield border"
          errorMessage={pageErrors.title}
          onChange={(event: any) => {
            setPageData("title", event.target.value);
          }}
        />
        <div className="post-url row">
          <span className="url-title">İçerik Adresi: </span>
          <div
            onClick={() =>
              !this.state.postSlug.editMode
                ? window.open(
                    getCurrentSiteUi() + "/" + this.props.pageData?.url,
                    "_blank"
                  )
                : {}
            }
            className={
              !this.state.postSlug.editMode
                ? "url-wrapper row align-center active"
                : "url-wrapper row align-center"
            }
          >
            <span className="text">{getCurrentSiteUi()}/</span>
            <span className="url-content">
              {this.state.postSlug.editMode ? (
                <TextField
                  value={this.state.postSlug.urlText || ""}
                  className="custom-textfield border"
                  onChange={(event: any) => {
                    this.setState({
                      postSlug: {
                        ...this.state.postSlug,
                        urlText: event.target.value,
                      },
                    });
                  }}
                />
              ) : (
                pageData.url || "-"
              )}
            </span>
          </div>
          <div className="buttons row">
            {this.state.postSlug.editMode ? (
              <React.Fragment>
                <DefaultButton
                  text="Kaydet"
                  disabled={this.state.postSlug.loading}
                  onClick={() => {
                    this.createPostSlug();
                  }}
                />
                {!this.state.postSlug.loading && (
                  <span
                    onClick={() => {
                      this.setState({
                        postSlug: {
                          ...this.state.postSlug,
                          editMode: false,
                          urlText: "",
                        },
                      });
                    }}
                    className="cancel"
                  >
                    Vazgeç
                  </span>
                )}
              </React.Fragment>
            ) : (
              <DefaultButton
                text="Düzenle"
                onClick={() => {
                  const splitted = pageData.url?.split("/");
                  const url =
                    pageData.url && pageData.language !== "en"
                      ? splitted.join("/").slice(splitted[0].length + 1) ||
                        pageData.url
                      : pageData.url;
                  this.setState({
                    postSlug: {
                      ...this.state.postSlug,
                      urlText: url,
                      editMode: true,
                    },
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="form-groups row">
         <div style={{ paddingTop: "0px" }} className="form-group col-md-12">
                    <FormAccordion showContent={true} title="Arama Ayarlamaları">
                        <div className="form-wrapper">
                            {this.renderGeneralSettings()}
                        </div>
                    </FormAccordion>
                </div>
        {this.props.contentType?.specifications &&
          this.props.contentType.specifications.length > 0 && (
            <div style={{ paddingTop: "0px" }} className="form-group col-md-12">
              <FormAccordion title="Spesifikasyonlar">
                <div className="form-wrapper">{this.renderSpecFields()}</div>
              </FormAccordion>
            </div>
          )}
        {this.props.contentType?.metaFields &&
          this.props.contentType.metaFields.length > 0 && (
            <div style={{ paddingTop: "0px" }} className="form-group col-md-12">
              <FormAccordion title="Metalar">
                <div className="form-wrapper">{this.renderMetaFields()}</div>
              </FormAccordion>
            </div>
          )}
        <div style={{ paddingTop: "0px" }} className="form-group col-md-12">
          <FormAccordion showContent={false} title="SEO Ayarlamaları">
            <div className="form-wrapper">{this.renderSeoWrapper()}</div>
          </FormAccordion>
        </div>
      </div>
    );
  }

  renderFileManager() {
    return (
      <FileManager
        show={this.state.showFileManager}
        onDismiss={() => this.setState({ showFileManager: false })}
        activeFile={{
          id: "",
          fileName: this.props.pageData.thumbnail?.alt as string,
          fileExtension: "",
          fileSize: "",
        }}
        onSelectedFile={(file) => {
          this.props.setPageData("thumbnail", {
            src: file.fileName,
            alt: file.fileName,
            title: file.fileName,
          });
        }}
        fileType={FileTypes.Image}
      />
    );
  }

  render() {
    const { pageData } = this.props;
    return (
      <PageLayout
        history={this.props.history}
        match={this.props.match}
        location={this.props.location}
      >
        <div className="page push-all">
          {this.renderFileManager()}
          <PageTitle
            title="İçerik Düzenle"
            titleRenderer={() => (
              <span className="edit-title">
                <u
                  onClick={() =>
                    this.props.history.push(
                      createContentTypePostsLink(this.props.contentTypeId)
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {this.props.contentType?.title || "-"}
                </u>{" "}
                / Düzenle:
                <strong>{this.props.pageData?.title}</strong>
              </span>
            )}
            showBack
            backUrl={createContentTypePostsLink(this.props.contentTypeId)}
            history={this.props.history}
            subTitle="Aşağıdan geçerli içeriği düzenleyebilirsiniz."
          />
          <div className="absolute-create absolute-wrapper row">
            <DefaultButton
              onClick={() => this.onDuplicatePost()}
              iconProps={{ iconName: "CPlusPlusLanguage" }}
              text="İçeriği Klonla"
            />
            <PrimaryButton
              onClick={() =>
                this.props.history.push(
                  createContentTypePostNewLink(this.props.contentTypeId)
                )
              }
              iconProps={{ iconName: "Add" }}
              text="Yeni İçerik Ekle"
              styles={{
                root: {
                  background: "#33926e",
                  borderColor: "#33926e",
                  color: "#fff",
                },
                rootHovered: {
                  background: "#5b9e85",
                  borderColor: "#5b9e85",
                },
                rootDisabled: {
                  background: "#ececec",
                },
              }}
            />
          </div>
          {this.renderTranslatorBar()}
          <div className="content row">
            <div className="left col no-padding">
              {this.renderMessageBar()}
              {this.renderTitle()}
              {this.renderForm()}
              <Designer />
            </div>
            <Sidebar
              categoryBar={{
                hide: true,
              }}
              archiveBar={{
                hide: true,
              }}
              publishBar={{
                onSave: (isDraft) => this.onSubmit(isDraft),
                onDelete: () => this.onDelete(),
                onClickPreview: () =>
                  window.open(
                    getCurrentSiteUi() + "/" + this.props.pageData?.url,
                    "_blank"
                  ),
                status: pageData.status,
                isEdit: true,
                userLabel: pageData.updatedBy
                  ? "Ekleyen Kullanıcı:"
                  : "Düzenleyen Kullanıcı:",
                publishDateText: pageData.updatedBy
                  ? "Yayınlanma Tarihi:"
                  : "Eklenme Tarihi:",
                dateText: moment(
                  pageData.updatedAt ? pageData.updatedAt : pageData.createdAt
                ).format("DD.MM.YYYY, HH:mm"),
                showOrder: true,
                order: this.props.pageData?.order || 1,
                onChangeOrderIndex: (order) =>
                  this.props.setPageData("order", order),
                showIntegration: true,
                integrationId: this.props.pageData?.integrationId || "",
                onChangeIntegrationId: (value: string) =>
                  this.props.setPageData("integrationId", value),
              }}
              layoutBar={{
                layoutData: this.state.layoutData,
                layout: this.props.pageData.layout,
                onChangeLayout: (layout) => {
                  this.props.setPageData("layout", layout);
                },
              }}
              thumbnailBar={{
                onClickThumbnail: () =>
                  this.setState({ showFileManager: true }),
                onDeleteThumbnail: () =>
                  this.props.setPageData("thumbnail", { src: "", alt: "" }),
                onSave: (data) => this.props.setPageData("thumbnail", data),
                thumbnail: this.props.pageData.thumbnail,
              }}
            />
          </div>
        </div>
      </PageLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPost);
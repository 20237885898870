import {
	DimensionOptions,
	DesignerActionTypes,
	SET_DESIGNER_DIMENSION,
	SET_ACTIVE_ELEMENT,
	SET_PREVIEW_DIMENSON, SET_PANEL_ACCORDION, SET_DESIGNER_MODE
} from "./types";

export function setDesignerDimension(
	dimension: DimensionOptions
): DesignerActionTypes {
	return {
		type: SET_DESIGNER_DIMENSION,
		dimension
	};
}

export function setPreviewDimension(
	dimension: DimensionOptions
): DesignerActionTypes {
	return {
		type: SET_PREVIEW_DIMENSON,
		dimension
	};
}

export function setDesignerMode(
	designerMode: boolean
): DesignerActionTypes {
	return {
		type: SET_DESIGNER_MODE,
		designerMode
	};
}

export function setActiveElement(id?: string | any): DesignerActionTypes {
	return {
		type: SET_ACTIVE_ELEMENT,
		id
	};
}


export function setPanelAccordion(value: string, single?: boolean): DesignerActionTypes {
	return {
		type: SET_PANEL_ACCORDION,
		value,
		single
	};
}

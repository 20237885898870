import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { HeadingProps } from "./types";
import {
  EntryOptions,
  PropertyDefinition,
  PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

export class HeadingDefinitions extends CmsReactComponent<HeadingProps> {

  static schemaName = "heading";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "children",
      mapping: {
        type: PropertyMappingType.String,
      },
      text: "Başlık Yazısı",
      default: "Heading 1",
    },
    {
      name: "nodeType",
      text: "Başlık Tipi",
      mapping: {
        type: PropertyMappingType.Object,
        oneOf: [
          {
            key: "h1",
            text: "H1",
          },
          {
            key: "h2",
            text: "H2",
          },
          {
            key: "h3",
            text: "H3",
          },
          {
            key: "h4",
            text: "H4",
          },
          {
            key: "h5",
            text: "H5",
          },
          {
            key: "h6",
            text: "H6",
          },
        ],
      },
      default: "h1",
    },
    {
      name: "align",
      mapping: {
        type: PropertyMappingType.Alignment,
      },
      default: "left",
    },
  ];

  static options: EntryOptions = {
    meta: {
      use: true,
      allowedTypes: [
        MetaTypeEnum.Text,
        MetaTypeEnum.TextArea
      ]
    }
  }
}

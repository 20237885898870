import { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from "app/redux/reducer";
import { Dispatch, bindActionCreators } from 'redux';
import PageTitle from 'components/customs/PageTitle';
import { TextField, MessageBar, MessageBarType, PrimaryButton, Toggle } from 'office-ui-fabric-react';
import { RouteComponentProps } from 'react-router-dom';

import { setPageData, setPageErrors, initialPageData } from 'app/redux/page/actions';
import * as _yup from "yup";
import { castThunkAction } from 'app/utils/casting';
import { setContentType } from 'app/redux/content-type/actions';
import { setLayout } from 'app/redux/system/actions';
import { createLayout, ILayout } from 'app/services/layout';
import { IContentType } from 'app/services/content-type';
import { StatusEnum } from 'app/shared';
import { DEFAULT_SCHEMA } from 'app/shared/constants';
import FormAccordion from 'components/customs/FormAccordion';
import { Designer } from 'components/customs/Designer';
import Sidebar from 'components/customs/Sidebar/Sidebar';
import PageLayout from 'containers/PageLayout';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
    return {
        ...ownProps,
        defaultLanguage: state.system.defaultLanguage,
        language: state.system.language,
        pageData: state.page.pageData as ILayout,
        pageErrors: state.page.pageErrors,
        isLayout: state.system.isLayout
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            setPageData,
            setPageErrors,
            initialPageData,
            createLayout,
            setContentType,
            setLayout
        }, dispatch)
    };
}

type CreateLayoutProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface OwnState {
    messageType: MessageBarType;
}

class CreateLayout extends Component<CreateLayoutProps, OwnState> {

    state = {
        messageType: MessageBarType.warning
    }

    componentDidMount() {
        this.props.setLayout(true);
        this.resetData();
    }

    componentWillUnmount() {
        this.props.setLayout(false);
    }

    resetData() {
        this.setState({
            messageType: MessageBarType.warning
        }, () => {
            this.props.initialPageData({
                schema: DEFAULT_SCHEMA,
                theme: "dark",
                language: this.props.defaultLanguage
            });
            this.props.setContentType({} as IContentType);
        })
    }

    renderTextByMessageType() {
        switch (this.state.messageType) {
            case MessageBarType.success:
                return "Kaydetme işlemi başarıyla gerçekleşmiştir. Düzenleme sayfasına yönlendiriliyorsunuz..."
            case MessageBarType.error:
                return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin."
            default:
                return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!"
        }
    }

    renderMessageBar() {
        return (
            <MessageBar
                messageBarType={this.state.messageType}
                isMultiline={false}
            >
                {this.renderTextByMessageType()}
            </MessageBar>
        )
    }

    /**
     * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
     * formErrors Objesini Dolduracak Action'u Tetikler.
     */
    onSubmit(isDraft?: boolean) {
        const {
            pageData,
            setPageErrors
        } = this.props;
        const validationSchema = _yup.object({
            title: _yup.string().required(
                "Bu alan zorunludur!"
            )
        });
        let formErrors = {};
        validationSchema
            .validate(pageData, {
                abortEarly: false
            })
            .then(() => {
                setPageErrors({});
                castThunkAction<ILayout>(this.props.createLayout({
                    ...this.props.pageData,
                    status: isDraft ? StatusEnum.Draft : StatusEnum.Published
                })).then(res => {
                    this.setState({
                        messageType: res?.id ? MessageBarType.success : MessageBarType.error
                    }, () => {
                        if (res?.id) {
                            setTimeout(() => {
                                this.props.history.push(`/layout/edit/${res?.id}`)
                            }, 1200)
                        }
                    })
                })

            })
            .catch(err => {
                err.inner.forEach((e: any) => {
                    const { path, message } = e;
                    formErrors[path] = message;
                });
                setPageErrors(formErrors);
            });
    };


    renderForm() {
        const { pageData, pageErrors, setPageData } = this.props;
        return (
            <div className="form-groups row">
                <div className="form-group col-md-12">
                    <FormAccordion title="Genel Bilgiler">
                        <div className="form-wrapper">
                            <div className="item row">
                                <TextField
                                    placeholder="Layout Adı"
                                    value={pageData.title || ""}
                                    label="Layout Adı"
                                    className="custom-textfield border"
                                    errorMessage={pageErrors.title}
                                    onChange={(event: any) => setPageData("title", event.target.value)}
                                />
                            </div>
                            <div className="item row">
                                <TextField
                                    placeholder="Layout Açıklaması"
                                    value={pageData.description || ""}
                                    label="Layout Açıklaması"
                                    multiline
                                    resizable={false}
                                    errorMessage={pageErrors.description}
                                    className="custom-textfield border"
                                    onChange={(event: any) => setPageData("description", event.target.value)}
                                />
                            </div>
                            <div className="item row">
                                <Toggle
                                    checked={pageData.theme === "dark" ? true : false}
                                    label="Layout Teması"
                                    className="custom-toggle"
                                    onText="Koyu Tema"
                                    offText="Açık Tema"
                                    onChange={(event: any, checked) => setPageData("theme", checked ? "dark" : "light")}
                                />
                            </div>
                        </div>
                    </FormAccordion>
                </div>
            </div>
        )
    }

    render() {
        return (
            <PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
                <div className="page push-all">
                    <PageTitle
                        title="Yeni Layout Ekle"
                        titleRenderer={
                            () => (
                                <span className="edit-title">
                                    Yönet:
                                    <strong>Yeni Layout Ekle</strong>
                                </span>
                            )
                        }
                        showBack
                        backUrl="/layouts"
                        history={this.props.history}
                        subTitle="Yeni bir layout eklemek için aşağıdaki bilgileri doldurunuz, dilerseniz taslak olarakta oluşturabilirsiniz."
                    />
                    <PrimaryButton
                        onClick={() => { this.resetData() }}
                        iconProps={{ iconName: "Add" }}
                        className="absolute-create"
                        text="Yeni Layout Ekle"
                        styles={{
                            root: {
                                background: "#62a98e",
                                borderColor: "#5b9e85",
                                color: "#fff"
                            },
                            rootHovered: {
                                background: "#5b9e85",
                                borderColor: "#5b9e85"
                            },
                            rootDisabled: {
                                background: "#ececec"
                            }
                        }}
                    />
                    <div className="content row">
                        <div className="left col no-padding">
                            {this.renderMessageBar()}
                            {this.renderForm()}
                            <Designer />
                        </div>
                        <Sidebar
                            categoryBar={{
                                hide: true
                            }}
                            publishBar={{
                                onSave: (isDraft) => this.onSubmit(isDraft),
                                disabledSaveButton: this.state.messageType === MessageBarType.success ? true : false,
                                hideDeleteButton: true,
                                hidePreviewButton: true
                            }}
                            layoutBar={{
                                hide: true
                            }}
                            archiveBar={{
                                hide: true
                            }}
                            thumbnailBar={{
                                hide: true
                            }}
                        />
                    </div>

                </div>
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLayout);
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "app/redux/reducer";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";
import { History } from "history";
import { setModalData, toggleModal } from "app/redux/modal/actions";
import {
  DefaultButton,
  DialogFooter,
  Dropdown,
  Panel,
  PrimaryButton,
  TextField,
} from "office-ui-fabric-react";
import AbsoluteLoader from "../Loader/AbsoluteLoader";
import { castThunkAction } from "app/utils/casting";
import { createUser, getUsers, updateUser, UserType } from "app/services/user";
import MaskInput from "../MaskInput";
import { USER_TYPES } from "app/utils/constants";
import { USER_REQUIRED_FIELDS } from "app/services/user/validations";
import { MessageType } from "app/redux/system/types";
import { showMessage } from "app/redux/system/actions";

interface OwnProps {
  history: History<any>;
}

function mapStateToProps(state: RootState) {
  return {
    ...state.modal.user,
    language: state.system.language,
    modalLoading: state.system.modalLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setModalData,
        toggleModal,
        createUser,
        updateUser,
        getUsers,
        showMessage
      },
      dispatch
    ),
  };
}

type UserModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface UserModalState {
  showModal: boolean;
}

class UserModal extends Component<UserModalProps, UserModalState> {
  state = {
    showModal: false,
  };

  onClose(updated?: UserType) {
    if (this.props.callback) {
      this.props.callback(updated);
    }
    this.props.toggleModal("user", {});
  }

  onValidate() {
    const data = _.cloneDeep(this.props.data);

    return new Promise((resolve) => {
      let validationSchema = USER_REQUIRED_FIELDS;
      let formErrors = {};

      validationSchema.validate(data, {
        abortEarly: false
      }).then(() => {
        resolve(true);
      })
        .catch(err => {
          err.inner.forEach((e: any) => {
            const { path, message } = e;

            formErrors[path] = message;
          });

          resolve(formErrors);
        });
    })
  }

  onSave() {
    this.onValidate().then((resolved) => {
      if (typeof resolved === "boolean" && resolved) {
        castThunkAction(
          this.props.data.id
            ? this.props.updateUser(this.props.data)
            : this.props.createUser(this.props.data)
        ).then((res) => {
          if (res) {
            this.onClose(this.props.data.user_type);
          }
        });
      }
      else {
        this.props.showMessage(
          "Aşağıdaki bilgilerde eksik ya da hatalı bilgiler bulunmaktadır. Lütfen bilgilerinizi kontrol ediniz.",
          "Eksik veya hatalı bilgiler mevcut",
          MessageType.ERROR,
          () => {
            return (
              <div className="error-list">
                {_.keys(resolved).map((item, index) => (
                  <div key={index} className="error-item">
                    {(resolved as object)[item]}
                  </div>
                ))}
              </div>
            );
          }
        )
      }
    })
  }

  onChangeData(key: string, value: any) {
    this.props.setModalData("user", {
      ...this.props.data,
      [key]: value,
    });
  }

  renderForm() {
    return (
      <div className="modal-form">
        <div className="row reverse-margin">
          <div className="item col-md-6">
            <TextField
              placeholder="Adı"
              value={this.props.data.name || ""}
              label="Adı"
              required
              className="custom-textfield border"
              onChange={(event: any) =>
                this.onChangeData("name", event.target.value)
              }
            />
          </div>
          <div className="item col-md-6">
            <TextField
              placeholder="Soyadı"
              value={this.props.data.surname || ""}
              label="Soyadı"
              required
              className="custom-textfield border"
              onChange={(event: any) =>
                this.onChangeData("surname", event.target.value)
              }
            />
          </div>
          <div className="item col-md-12">
            <TextField
              required
              placeholder="Mail Adresi"
              value={this.props.data.email || ""}
              label="Mail Adresi"
              className="custom-textfield border"
              onChange={(event: any) =>
                this.onChangeData("email", event.target.value)
              }
            />
          </div>
          <div className="item col-md-6">
            <MaskInput
              label="Telefon Numarası"
              value={this.props.data.phone_number || ""}
              maskTemplate="(999) 999 99 99"
              className="custom-textfield border"
              onChange={(value: string) =>
                this.onChangeData("phone_number", value)
              }
            />
          </div>
          <Dropdown
            options={USER_TYPES}
            className="custom-dropdown col-md-6"
            label="Kullanıcı Tipi"
            selectedKey={this.props.data.user_type || UserType.SuperAdminUser}
            onChange={(event: any, option: any) =>
              this.onChangeData("user_type", option.key)
            }
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { data, show, modalLoading } = this.props;

    if (!show) return null;

    return (
      <Panel
        className="preferences-panel"
        isOpen={show}
        onDismiss={() => this.onClose()}
        headerText={data.id ? "Kullanıcı Düzenle" : "Yeni Kullanıcı Ekle"}
        onOuterClick={() => { }}
        onRenderFooter={() => {
          return (
            <DialogFooter>
              <DefaultButton onClick={() => this.onClose()} text="İptal" />
              <PrimaryButton
                onClick={() => this.onSave()}
                text={data.id ? "Değişiklikleri Kaydet" : "Kullanıcı Oluştur"}
                disabled={modalLoading ? true : false}
              />
            </DialogFooter>
          )
        }}
      >
        <div className="sub-text">
          Aşağıdaki bilgileri doldurarak belirlediğiniz kriterlerde bir kullanıcı ekleyin ya da düzenleyin.
        </div>
        <AbsoluteLoader show={modalLoading} />
        {this.renderForm()}
      </Panel>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);

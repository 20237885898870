const IconValues = {
  Genel: [
    "icon-menu",
    "icon-accreditation",
    "icon-arrow-down",
    "icon-arrow-left",
    "icon-arrow-up",
    "icon-arrow-right",
    "icon-body",
    "icon-bone",
    "icon-brain",
    "icon-call-center",
    "icon-check-2",
    "icon-check",
    "icon-chevron-down",
    "icon-chevron-left",
    "icon-chevron-right",
    "icon-chevron-up",
    "icon-close",
    "icon-dolar",
    "icon-dubble-arrow",
    "icon-edit",
    "icon-email",
    "icon-embryo",
    "icon-error",
    "icon-eye",
    "icon-facebook",
    "icon-facebook-filled",
    "icon-filter",
    "icon-headphones",
    "icon-heart",
    "icon-hearth-beat",
    "icon-help",
    "icon-home",
    "icon-horizontal-list",
    "icon-info",
    "icon-instagram",
    "icon-insurance",
    "icon-language",
    "icon-linkedin",
    "icon-medicine",
    "icon-message",
    "icon-more",
    "icon-navigation",
    "icon-phone",
    "icon-plus",
    "icon-ribbon",
    "icon-right-arrow",
    "icon-search",
    "icon-spinner",
    "icon-state",
    "icon-stethoscope",
    "icon-success",
    "icon-teeth",
    "icon-trash",
    "icon-twitter",
    "icon-upload",
    "icon-vertical-list",
    "icon-whatsapp",
    "icon-web",
    "icon-warning",
    "icon-youtube",
  ],
};

export default IconValues;

import { Component } from 'react';

interface OwnProps {
    showContent?: boolean;
    onChange: () => void;
    title: string;
}

export default class MenuAccordion extends Component<OwnProps> {

    render() {
        return (
            <div className="form-accordion">
                <div
                    className={this.props.showContent ? "title row passive" : "title row"}
                    onClick={() => {
                        this.props.onChange()
                    }}
                >
                    {this.props.title}
                    <i className="fas fa-angle-up"></i>
                </div>
                {this.props.showContent && (
                    <div className="accordion-content">
                        {this.props.children}
                    </div>
                )}
            </div>
        )
    }
}

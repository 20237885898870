import React from 'react'
import { CmsReactComponent } from 'components/cms/CmsReactComponent'

import { LayoutScreenDesignerProps } from './types'
import Droppable from 'components/customs/Designer/Droppable';
import ComponentDescription from 'components/customs/ComponentDescription';

export default class LayoutScreenDesigner extends CmsReactComponent<LayoutScreenDesignerProps> {

    renderInformation() {
        if (this.props.children && this.props.children.length > 0) {
            return null;
        }
        return (
            <ComponentDescription
                node={this.props.schemaNode}
                entry={this.props.entry}
            />
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderInformation()}
                <div style={{ left: 0 }} className="component-header row">
                    <div className="title">
                        Sahne (Root)
                    </div>
                </div>
                <Droppable
                    style={{ alignContent: "baseline", paddingBottom: "50px", minHeight: "300px" }}
                    elementId={this.props.id as string}
                    dragDropContext={(this.props as any).dragDropContext}
                    className={this.props.children && this.props.children.length > 0 ? "fa-droppable-row" : "fa-droppable-row row"}
                    groupName="layout"
                    elementType="column"
                >
                    {this.props.children}
                </Droppable>
            </React.Fragment>
        )
    }
}

import { Component } from 'react';

interface OwnProps {
	showContent?: boolean;
	title: string;
}

interface OwnState {
	showContent: boolean;
}

export default class FormAccordion extends Component<OwnProps, OwnState> {

	state = {
		showContent: typeof this.props.showContent === "boolean" ? this.props.showContent : true
	}

	render() {
		return (
			<div className="form-accordion">
				<div
					className={this.state.showContent ? "title row passive" : "title row"}
					onClick={() => {
						this.setState(prevState => ({
							showContent: !prevState.showContent
						}))
					}}
				>
					{this.props.title}
					<i className="fas fa-angle-up"></i>
				</div>
				{this.state.showContent && (
					<div className="accordion-content">
						{this.props.children}
					</div>
				)}
			</div>
		)
	}
}

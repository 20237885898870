const prefixes = {
    auth: {
        sign: "/auth/sign-as-admin",
        checkToken: "/auth/check-token",
        info: "/admin/"
    },
    admin: {
        admin: "/admin/",
        list: '/admin/search'
    },
    contentType: {
        list: "/content-type/search",
        main: "/content-type/",
        postList: "/content-type/post-list",
        pageFields: "/content-type/page-fields/"
    },
    layout: {
        list: "/layout/search",
        main: "/layout/"
    },
    configuration: {
        main: "/configuration",
        admin: "/configuration/admin"
    },
    post: {
        list: "/post/search",
        main: "/post/",
        duplicate: "/post/duplicate",
        admin: "/post/admin",
        url: "/post/find-available-url?url=",
        multiple: "/post/by-id-list?idList="
    },
    category: {
        list: "/web-category/all/",
        main: "/web-category/",
        url: "/web-category/find-available-url?url="
    },
    menu: {
        list: "/menu/search",
        main: "/menu/"
    },
    storage: {
        main: "/",
        upload: "/upload",
        list: "/file-list",
        buckets: "/bucket-list"
    },
    queryBuilder: {
        main: "/query-builder/",
        run: "/query-builder/run-query?ids="
    },
    recovery: {
        list: "/recovery/search/",
        delete: "/recovery/",
        restore: "/recovery/restore",
    },
    user: {
        main: "user/",
        bulk: "user/bulk",
        list: "user",
        detail: "user/get-by-id?id=",
        locale: "user/locale/"
    }
};


export const api = {
    prefixes
};


import { PureContentDefinitions } from './PureContentDefinitions';
import PureContentDesigner from './PureContentDesigner';

export class PureContent extends PureContentDefinitions {
  render() {
    return (
      <div
        data-schema-id={this.props.id}
        className={`fa-draggable fa-Grid row ${this.props.designing ? "designing" : ""
          }`}
        style={{
          minHeight: this.props.designing ? "100px" : "auto",
          position: "relative",
        }}
      >
        {this.props.designing ? (
          <PureContentDesigner {...this.props} />
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "app/redux/reducer";
import { bindActionCreators, Dispatch } from "redux";
import PageTitle from "components/customs/PageTitle";
import { RouteComponentProps } from "react-router-dom";
import BasicTable from "components/customs/BasicTable";
import { TextField, PrimaryButton } from "office-ui-fabric-react";
import { castThunkAction } from "app/utils/casting";
import { toggleConfirmDialog } from "app/redux/system/actions";
import {
  getRoles,
  deleteRole,
  IRole,
  RoleModalTypeEnum,
} from "app/services/identity";
import { IList, IListFilter, IListState } from "app/shared";
import PageLayout from "containers/PageLayout";
import { toggleModal } from "app/redux/modal/actions";

function mapStateToProps(state: RootState, ownProps: RouteComponentProps) {
  return {
    ...ownProps,
    language: state.system.language,
    pageData: state.page.pageData as IList<IRole[]>,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getRoles,
      toggleConfirmDialog,
      deleteRole,
      toggleModal,
    },
    dispatch
  );
}

type IListRoleProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class ListRole extends Component<IListRoleProps, IListState> {
  state = {
    type: "role",
    filterObject: {
      name: "",
      status: 1,
      createdOn: "",
      language: "",
    },
    pagerObject: {
      page: 1,
      descending: false,
      pageSize: 10,
    },
  };

  componentDidMount() {
    this.makeRequest({ ...this.state.pagerObject, ...this.state.filterObject });
  }

  makeRequest(options: IListFilter) {
    castThunkAction(this.props.getRoles(options)).then(() => {
      this.setState({
        activeStatus: this.state.filterObject.status,
      });
    });
  }

  searchList() {
    this.setState(
      {
        pagerObject: {
          page: 1,
          descending: false,
          pageSize: 10,
        },
      },
      () => {
        this.makeRequest({
          ...this.state.pagerObject,
          ...this.state.filterObject,
        });
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-4"
          label="Ad İle Arama"
          value={this.state.filterObject?.name || ""}
          onChange={(event: any) => {
            this.setState({
              filterObject: {
                ...this.state.filterObject,
                name: event.target.value,
              },
            });
          }}
        />

        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() =>
            this.makeRequest({
              ...this.state.pagerObject,
              ...this.state.filterObject,
            })
          }
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "37px",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        totalCount={this.props.pageData.totalCount}
        columns={[
          {
            columnName: "name",
            text: "Rol Adı",
            col: "col-md-6",
          },
          {
            columnName: "code",
            text: "Rol Kodu",
            col: "col-md-6",
          },
        ]}
        items={this.props.pageData.list}
        renderOperations={(item: IRole) => (
          <>
            <div
              className="more"
              onClick={() =>
                this.props.toggleModal(
                  "role",
                  {
                    modalType: RoleModalTypeEnum.edit,
                    id: item.id,
                    name: item.name,
                    code: item.code,
                  },
                  () => {
                    this.makeRequest({
                      ...this.state.pagerObject,
                      ...this.state.filterObject,
                    });
                  }
                )
              }
            >
              <i className="icon-edit-outline"></i> Düzenle
            </div>
            <div
              className="more"
              onClick={() =>
                this.props.toggleModal(
                  "role",
                  {
                    modalType: RoleModalTypeEnum.permission,
                    id: item.id,
                    name: item.name,
                    code: item.code,
                  },
                  () => {
                    this.makeRequest({
                      ...this.state.pagerObject,
                      ...this.state.filterObject,
                    });
                  }
                )
              }
            >
              <i className="icon-edit-outline"></i> Rol İzinlerini Düzenle
            </div>
            <button
              onClick={() => {
                this.props.toggleConfirmDialog(true, {
                  callback: () => {
                    castThunkAction(this.props.deleteRole(item.id)).then(
                      (res) => {
                        if (res) {
                          this.makeRequest({
                            ...this.state.pagerObject,
                            ...this.state.filterObject,
                          });
                        }
                      }
                    );
                  },
                });
              }}
            >
              <i className="icon-trash-outline"></i> Sil
            </button>
          </>
        )}
        showPager={true}
        pageCount={this.props.pageData.pageCount}
        onChangePageSize={(pageSize) => {
          this.setState(
            { pagerObject: { ...this.state.pagerObject, pageSize } },
            () =>
              this.makeRequest({
                ...this.state.pagerObject,
                ...this.state.filterObject,
                pageSize,
              })
          );
        }}
        onChangePager={(pageNumber) => {
          this.setState(
            { pagerObject: { ...this.state.pagerObject, page: pageNumber } },
            () =>
              this.makeRequest({
                ...this.state.pagerObject,
                ...this.state.filterObject,
                page: pageNumber,
              })
          );
        }}
        pageIndex={this.state.pagerObject.page - 1}
      />
    );
  }

  render() {
    return (
      <PageLayout
        history={this.props.history}
        match={this.props.match}
        location={this.props.location}
      >
        <div className="page push-all">
          <PageTitle
            title="Rol Yönetimi"
            subTitle="Tüm rolleri aşağıdan görüntüleyebilir, yeni rol tanımlayabilir, tanımlanmış rol üzerinde, rol izinlerini yapılandırabilirsiniz."
            backUrl="/"
            showBack
            history={this.props.history}
          />
          <PrimaryButton
            onClick={() =>
              this.props.toggleModal(
                "role",
                { modalType: RoleModalTypeEnum.add },
                () => {
                  this.makeRequest({
                    ...this.state.pagerObject,
                    ...this.state.filterObject,
                  });
                }
              )
            }
            iconProps={{ iconName: "Add" }}
            className="absolute-create"
            text="Yeni Rol Ekle"
            styles={{
              root: {
                background: "#62a98e",
                borderColor: "#5b9e85",
                color: "#fff",
              },
              rootHovered: {
                background: "#5b9e85",
                borderColor: "#5b9e85",
              },
              rootDisabled: {
                background: "#ececec",
              },
            }}
          />
          {this.renderFilter()}
          {this.renderList()}
        </div>
      </PageLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListRole);

// import React from "react";
import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { ButtonProps } from "./types";
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from 'app/shared';
import { ButtonAppearanceOneOf, ButtonSpacingOneOf } from '../defaults';

export class ButtonDefinitions extends CmsReactComponent<ButtonProps> {

    static schemaName = "button";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "appearance",
            mapping: {
                type: PropertyMappingType.Object,
                oneOf: ButtonAppearanceOneOf,
            },
            text: "Görünüm Tipi",
            default: "default",
        },
        {
            name: "anchor",
            text: "URL",
            mapping: {
                type: PropertyMappingType.LinkWizard,
            },
        },
        {
            name: "children",
            mapping: {
                type: PropertyMappingType.String
            },
            text: "Buton Yazısı",
            default: "Button Text"
        },
        {
            name: "spacing",
            mapping: {
                type: PropertyMappingType.Object,
                oneOf: ButtonSpacingOneOf,
            },
            text: "Boşluklandırma",
            default: "default"
        },
        {
            name: "isLoading",
            mapping: {
                type: PropertyMappingType.Boolean,
            },
            text: "Yükleniyor Mu?",
            default: false,
        }
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [MetaTypeEnum.Text, MetaTypeEnum.TextArea]
        }
    }
}

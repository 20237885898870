import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { HtmlProps } from "./types";
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

export class HtmlDefinitions extends CmsReactComponent<HtmlProps> {

    static schemaName = "html";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "children",
            text: "HTML İçerik",
            mapping: {
                type: PropertyMappingType.Html
            },
            default: "",
        }
    ];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [MetaTypeEnum.Editor, MetaTypeEnum.Text, MetaTypeEnum.TextArea]
        },
    };
}

import React, { Component } from 'react'
import { TextField, Callout, ColorPicker, DirectionalHint } from 'office-ui-fabric-react';
import PropTypes from "prop-types";
import _ from "lodash";
export default class MSColorPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPicker: false
        };
        this.updateColor = _.debounce(this.updateColor, 200);
    }

    state = {
        showPicker: false
    };

    static propTypes = {
        color: PropTypes.string,
        onChangeColor: PropTypes.func,
        inputProps: PropTypes.object,
        className: PropTypes.string,
        pickerProps: PropTypes.object
    };

    static defaultProps = {
        onChangeColor: color => {
            console.log("color", color);
        }
    };

    updateColor = (color) => {
        this.props.onChangeColor(color);
    }

    onTogglePicker = () => {
        this.setState(prevState => ({
            showPicker: !prevState.showPicker
        }))
    };

    render() {
        const { showPicker } = this.state;
        return (
            <div
                ref={e => this.colorPickerRef = e}
                className="custom-color-picker">
                <TextField
                    placeholder="color"
                    onClick={this.onTogglePicker}
                    className="custom-textfield"
                    value={this.props.color}
                    {...this.props.inputProps}
                />
                {showPicker && (
                    <Callout
                        gapSpace={0}
                        alignTargetEdge={true}
                        target={this.colorPickerRef}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        onDismiss={this.onTogglePicker}
                        setInitialFocus={true}
                        beakWidth={7}
                        preventDismissOnLostFocus={false}
                        preventDismissOnResize={true}
                        preventDismissOnScroll={true}
                    >
                        <ColorPicker
                            color={this.props.color}
                            onChange={(e, color) => this.updateColor(color.str)}
                            alphaSliderHidden={false}
                            showPreview={true}
                            {...this.props.pickerProps}
                        />
                    </Callout>
                )}

            </div>
        )
    }
}

import React, { Component } from "react";
import moment from "moment";
import { Label } from "office-ui-fabric-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { DateUtils } from "react-day-picker";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";

export default class CustomDayPickerInput extends Component {
  static defaultProps = {
    locale: "tr",
    format: "dd.MM.yyyy",
  };

  state = {
    from: undefined,
    to: undefined,
    fromFormat: undefined,
    toFormat: undefined,
  };

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), "months") < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    this.setState(
      {
        from,
        fromFormat: `${convertCallbackDate(from)}`,
      },
      () => {
        this.props.onChange(
          `${this.state.fromFormat},${convertCallbackDate(new Date())}`
        );
      }
    );
  }
  handleToChange(to) {
    this.setState(
      {
        to,
        toFormat: convertCallbackDate(to),
      },
      () => {
        this.showFromMonth();
        this.props.onChange(`${this.state.fromFormat},${this.state.toFormat}`);
      }
    );
  }
  render() {
    const { from, to } = this.state;
    return (
      <div className="InputFromTo col-md-4">
        <div>
          <Label className="ms-Label ms-Dropdown-label">Tarihe Göre Arama</Label>
        </div>
        <div className="datepicker-display-block">
          <DayPickerInput
            ref={(el) => (this.from = el)}
            value={from}
            placeholder="Başlangıç Tarihi"
            format={this.props.format}
            inputProps={{
              readOnly: false,
            }}
            onDayChange={(from) => this.handleFromChange(from)}
            dayPickerProps={{
              locale: this.props.locale,
              months: MONTHS[this.props.locale],
              weekdaysLong: WEEKDAYS_LONG[this.props.locale],
              weekdaysShort: WEEKDAYS_SHORT[this.props.locale],
              firstDayOfWeek: FIRST_DAY_OF_WEEK[this.props.locale],
              labels: LABELS[this.props.locale],
              disabledDays: {
                after: new Date(),
              },
            }}
            parseDate={parseDate}
            formatDate={formatDate}
          />
        </div>
        <span style={{ padding: 5 }}> - </span>
        <div className="datepicker-display-block">
          <DayPickerInput
            ref={(el) => (this.to = el)}
            value={to}
            numberOfMonths={2}
            placeholder={`${dateFnsFormat(new Date(), this.props.format)}`}
            format={this.props.format}
            locale="tr"
            dayPickerProps={{
              locale: this.props.locale,
              months: MONTHS[this.props.locale],
              weekdaysLong: WEEKDAYS_LONG[this.props.locale],
              weekdaysShort: WEEKDAYS_SHORT[this.props.locale],
              firstDayOfWeek: FIRST_DAY_OF_WEEK[this.props.locale],
              labels: LABELS[this.props.locale],
              disabledDays: {
                before: this.state.from ? this.state.from : null,
                after: new Date(),
              },
            }}
            parseDate={parseDate}
            formatDate={formatDate}
            onDayChange={(to) => this.handleToChange(to)}
          />
        </div>
      </div>
    );
  }
}

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date());
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format);
}

function convertCallbackDate(date) {
  return moment(date).format("YYYY-MM-DD");
}

const WEEKDAYS_SHORT = {
  tr: ["Pz", "Pt", "Sa", "Ca", "Pe", "Cu", "Ct"],
};
const MONTHS = {
  tr: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
};

const WEEKDAYS_LONG = {
  tr: [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ],
};

const FIRST_DAY_OF_WEEK = {
  tr: 1,
};

const LABELS = {
  tr: { nextMonth: "Sonraki Hafta", previousMonth: "Önceki Hafta" },
};

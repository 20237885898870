import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { LayoutContainerProps } from "./types";
import { EntryOptions, PropertyDefinition } from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

export class LayoutContainerDefinitions extends CmsReactComponent<
    LayoutContainerProps
    > {
    static schemaName = "page-content";

    static propertyDefinitions: PropertyDefinition[] = [];

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.Text,
                MetaTypeEnum.TextArea
            ]
        }
    }
}

import * as React from "react";
import MSColorPicker from "../../MSColorPicker";

export type OnChangeColorField = (value: any) => void;

export interface PureColorFieldProps {
	label: string;
	value: any;
	onChange: OnChangeColorField;
	text: string;
	description: string;
}

export class PureColorField extends React.Component<PureColorFieldProps> {
	render() {
		return (
			<div className="property-item row">
				<div title={this.props.label} className="property-text">
					<div className="title">{this.props.text || this.props.label.split(/(?=[A-Z])/).map(item => item.charAt(0).toUpperCase() + item.slice(1) + " ")}</div>
					<div className="sub-title">{this.props.description || this.props.label}</div>
				</div>
				<div className="col">
					<MSColorPicker
						onChangeColor={(color: string) => this.props.onChange(color)}
						color={this.props.value || ""}
					/>
				</div>
			</div>
		);
	}
}

import * as React from "react";

import { SchemaNode, Container, ContainerEntry } from 'app/library/layout-builder';

export interface CmsReactComponentProps {
    id?: string;
    designing?: boolean;
    className?: string;
    schemaNode?: SchemaNode;
    container?: Container;
    entry?: ContainerEntry;
    style?: any;
    hideRuntime?: boolean;
    hideOnMobile?: boolean;
    hideOnTablet?: boolean;
}

export class CmsReactComponent<T1 extends CmsReactComponentProps, T2 = any> extends React.Component<T1, T2> {

    checkDimension(dimension: "mobile" | "tablet" | "desktop") {
        if (dimension === "mobile") {
            return (window.innerWidth > 240 && window.innerWidth <= 767);
        }
        else if (dimension === "tablet") {
            return (window.innerWidth >= 768 && window.innerWidth <= 1199);
        }
        else return true;
    }

}
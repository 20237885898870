import { store } from "app/redux";
import { tokenControl } from "app/redux/system/actions";
import cookie from "react-cookies";

export function signOut() {
    cookie.remove("accessToken");
    cookie.remove("refreshToken");
    store.dispatch(tokenControl(false));

    window.location.href = "/login";
}

import { PageActionTypes, INITIAL_PAGE_DATA, SET_PAGE_DATA, SET_PAGE_ERRORS, RESET_PAGE_DATA } from "./types";

/**
 * Page Data Objesini, Direkt Olarak Obje ile Set Eder.
 */
export function initialPageData<T>(pageData: T): PageActionTypes {
	return {
		type: INITIAL_PAGE_DATA,
		pageData
	}
}

/**
 * Page Data Objesini, Dışarıdan Gelen Key ve Value'ya Göre Set Eder.
 */
export function setPageData(key: string, value: any): PageActionTypes {
	return {
		type: SET_PAGE_DATA,
		key,
		value
	}
}

/**
 * Page Errors Objesini, Direkt Olarak Error'ları Barındıran Obje ile Set Eder.
 */
export function setPageErrors(pageErrors: any): PageActionTypes {
	return {
		type: SET_PAGE_ERRORS,
		pageErrors
	}
}

/**
 * Page Data ve Page Errors Objelerini Temizlemeyi Set Eder.
 */
export function resetPage(): PageActionTypes {
	return {
		type: RESET_PAGE_DATA
	}
}
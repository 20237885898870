import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";

export class HomeAboutUsDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "home-about-us";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "title",
            text: "Başlık",
            mapping: {
                type: PropertyMappingType.String
            },
        },
        {
            name: "description",
            text: "Metin",
            mapping: {
                type: PropertyMappingType.Textarea
            },
        },
        {
            name: "slogan",
            text: "Slogan",
            mapping: {
                type: PropertyMappingType.String
            },
        },
        {
            name: "video",
            text: "Video",
            mapping: {
                type: PropertyMappingType.Video
            },
        },
        {
            name: "mobilevideo",
            text: "Mobile Video",
            mapping: {
                type: PropertyMappingType.Video
            }
        },
        {
            name: "videoposter",
            text: "Video Poster",
            mapping: {
                type: PropertyMappingType.Image
            },
        },
        {
            name: "anchor",
            description: "URL",
            mapping: {
                type: PropertyMappingType.LinkWizard
            },
            default: null,
        },
        {
            name: "title2",
            text: "Başlık2",
            mapping: {
                type: PropertyMappingType.String
            },
        },
        {
            name: "description2",
            text: "Metin2",
            mapping: {
                type: PropertyMappingType.Textarea
            },
        },
        {
            name: "slogan2",
            text: "Slogan2",
            mapping: {
                type: PropertyMappingType.String
            },
        },
        {
            name: "video2",
            text: "Video2",
            mapping: {
                type: PropertyMappingType.Video
            },
        },
        {
            name: "mobilevideo2",
            text: "Mobile Video2",
            mapping: {
                type: PropertyMappingType.Video
            }
        },
        {
            name: "videoposter2",
            text: "Video Poster2",
            mapping: {
                type: PropertyMappingType.Image
            },
        },
        {
            name: "anchor2",
            description: "URL2",
            mapping: {
                type: PropertyMappingType.LinkWizard
            },
            default: null,
        },
    ];
}

import * as _yup from 'yup';
import { IdentityLoginRequest, IVerifyRequest } from "./types";
import validations from 'app/shared/validations';

export const VERIFY_REQUEST_FIELDS = _yup.object<IVerifyRequest>({
    email: validations.email
});

export const SIGN_REQUEST_FIELDS = _yup.object<IdentityLoginRequest>({
    username: _yup.string().required(
        "E-Mail adresini boş bırakamazsınız"
    ),
    password: _yup.string().required(
        "Doğrulama kodunu boş bırakamazsınız"
    )
});

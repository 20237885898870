import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { ScrollbarProps } from "./types";
import {
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";

export class ScrollbarDefinitions extends CmsReactComponent<ScrollbarProps> {
    static schemaName = "scrollbar";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "maxHeight",
            text: "Maksimum Yükseklik",
            mapping: {
                type: PropertyMappingType.Number,
            },
        },
        {
            name: "maxWidth",
            text: "Maksimum Genişlik",
            mapping: {
                type: PropertyMappingType.Number,
            },
        },
        {
            name: "position",
            text: "Pozisyon",
            mapping: {
                type: PropertyMappingType.Object,
                oneOf: [{
                    key: "x",
                    text: "Yatay"
                },
                {
                    key: "y",
                    text: "Dikey"
                },
                {
                    key: "xy",
                    text: "Yatay ve Dikey"
                }]
            },
            default: "y"
        }
    ];

}



import { Component } from 'react';
import { Toggle } from 'office-ui-fabric-react';

import { BaseMetaFieldsProps } from './types';

export default class MetaCheckbox extends Component<BaseMetaFieldsProps<boolean>> {
    render() {
        return (
            <Toggle
                placeholder={this.props.meta.title}
                checked={this.props.meta?.data || false}
                className="custom-toggle"
                onText="Açık"
                offText="Kapalı"
                onChange={(event, checked) => this.props.onChange(checked as boolean)}
            />
        )
    }
}

import _ from 'lodash'

export function encodedParams(object: any) {
    const params = new URLSearchParams();

    _.keys(object).forEach(key => {
        params.append(key, object[key]);
    });

    return params;
}

export function getPhone(phone: string) {
    let regex = phone.replace(/\D/g, "").match(/(\d{2})(\d{3})(\d{3})(\d{4})/);

    if (!regex) return "-";

    return `+${regex[1]} (${regex[2]}) ${regex[3]} ${regex[4]}`;
}

export function getPhoneWithoutBaseCode(phone: string) {
    let regex = phone.replace(/\D/g, "").match(/(\d{3})(\d{3})(\d{4})/);

    if (!regex) return "-";

    return "(" + regex[1] + ") " + regex[2] + " " + regex[3];
}

export function slugify(text: string) {
    var trMap = {
        'çÇ': 'c',
        'ğĞ': 'g',
        'şŞ': 's',
        'üÜ': 'u',
        'ıİ': 'i',
        'öÖ': 'o'
    };
    for (var key in trMap) {
        text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
    }
    return text
        .trim()
        .replace(/\s/gi, "-") // convert spaces to dashes
        .replace(/&/gi, "")
        .replace(/'|’/gi, "")
        .replace(/[-]+/gi, "-") // trim repeated dashes
        .toLowerCase();

}

export function camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export function validateUrl(value: string) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value) || value === "#";
}

export function formatSize(n: number) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "KB";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "MB";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "GB";
    return ""
};

export function decodeHistoryString(str: string) {
    const element = document.createElement('div');
    element.innerHTML = str;

    return element.innerHTML.replace(/&amp;/g, "&").replace(/(<([^>]+)>)/gi, " ").replace(/&nbsp;/g, " ").replace("true", "Evet").replace("false", "Hayır");
}


export function apiUrlCreator(url: string, suffix: string = ""): string {
    if (url) {
        const convertedUrl = (url).replace(/\/$/, '');
        return convertedUrl + "/" + suffix;
    }
    return "";
}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function convertToQueryString(obj: Object): string {
    return _.keys(obj).filter(key => obj[key] !== undefined).map(key => key + '=' + obj[key]).join('&');
}

export function filterByKeys(object: object, keys: string[]) {
    return Object.keys(object)
        .filter(key => keys.includes(key))
        .reduce((obj, key) => {
            obj[key] = object[key];
            return obj;
        }, {});
}

export function excludeByKeys(object: object, keys: string[]) {
    return Object.keys(object)
        .filter(key => !keys.includes(key))
        .reduce((obj, key) => {
            obj[key] = object[key];
            return obj;
        }, {});
}

export async function asyncForEach(array: any[], callback: Function) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}
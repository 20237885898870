import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Dropdown,
    Label,
    PrimaryButton,
    TextField,
} from "office-ui-fabric-react";
import React, { Component } from "react";
import { MetaTypeEnum } from "app/shared";
import _ from "lodash";
import { metaList } from '../MetaFields/Fields/constants';
import MetaField from '../MetaFields/MetaField';
import { toggleModal } from 'app/redux/modal/actions';
import { IContentType, IMeta, IMetaBindingObject, MetaBindingObjectTypes } from "app/services/content-type";

interface MetaModalProps {
    show: boolean;
    onSave: (data: IMeta) => void;
    onDismiss: () => void;
    isUpdateMode?: boolean;
    data?: IMeta | null;
    contentTypes?: IContentType[];
    toggleModal: typeof toggleModal
}

interface MetaModalState {
    data: IMeta;
    disabledColumn?: boolean;
}

export default class MetaModal extends Component<
    MetaModalProps,
    MetaModalState
> {
    state = {
        data: { type: MetaTypeEnum.Text, column: 4 } as IMeta,
        disabledColumn: false,
    };

    UNSAFE_componentWillReceiveProps(nextProps: MetaModalProps) {
        if (nextProps.show && nextProps.show !== this.props.show) {
            this.setState({
                data: nextProps.data || { type: MetaTypeEnum.Text, column: 4 } as IMeta,
            })
        }
    }

    onFieldChange(index: number, key: string, value: string) {
        const clonedItems = _.cloneDeep(this.state.data.bindings?.customOptions || []);
        clonedItems[index] = {
            ...clonedItems[index],
            [key]: value
        }
        this.setState({
            data: {
                ...this.state.data,
                bindings: {
                    ...this.state.data.bindings,
                    customOptions: clonedItems
                } as IMetaBindingObject
            }
        })
    }

    renderForm() {
        return (
            <div className="modal-form">
                <div className="item">
                    <TextField
                        placeholder="Meta Başlığı"
                        value={this.state.data.title || ""}
                        label="Meta Başlığı"
                        className="custom-textfield border"
                        onChange={(event: any) => {
                            this.setState({
                                data: {
                                    ...this.state.data,
                                    title: event.target.value,
                                    name: _.camelCase(event.target.value),
                                },
                            });
                        }}
                    />
                </div>
                <div className="item">
                    <Dropdown
                        placeholder="Gözükecek Sütun Sayısı (1-12)"
                        disabled={this.getColumn(this.state.data.type as MetaTypeEnum) as boolean}
                        label="Gözükecek Sütun Sayısı"
                        className="custom-dropdown border"
                        selectedKey={this.state.data.column}
                        calloutProps={{ className: "custom-dropdown-callout" }}
                        onChange={(_, opt) => {
                            this.setState({
                                data: {
                                    ...this.state.data,
                                    column: opt?.key as number,
                                },
                            });
                        }}
                        options={[
                            {
                                key: 1,
                                text: "1/12",
                            },
                            {
                                key: 2,
                                text: "2/12",
                            },
                            {
                                key: 3,
                                text: "3/12",
                            },
                            {
                                key: 4,
                                text: "4/12",
                            },
                            {
                                key: 5,
                                text: "5/12",
                            },
                            {
                                key: 6,
                                text: "6/12",
                            },
                            {
                                key: 7,
                                text: "7/12",
                            },
                            {
                                key: 8,
                                text: "8/12",
                            },
                            {
                                key: 9,
                                text: "9/12",
                            },
                            {
                                key: 10,
                                text: "10/12",
                            },
                            {
                                key: 11,
                                text: "11/12",
                            },
                            {
                                key: 12,
                                text: "12/12",
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }

    getColumn(type: MetaTypeEnum): number | boolean {
        const meta = metaList.find(x => x.type === type);
        if (meta?.column) {
            return meta.column;
        }
        return false;
    }

    renderMetaList() {
        return (
            <div className="meta-constant-list row">
                {metaList.map((item, index) => (
                    <div key={index} className="meta-item">
                        <div
                            className={
                                item.type === this.state.data.type
                                    ? "meta-item-wrapper row active"
                                    : "meta-item-wrapper row"
                            }
                            onClick={() => {
                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        type: item.type,
                                        default: null,
                                        column: this.getColumn(item.type) as number || 4
                                    },
                                    disabledColumn:
                                        item.type === MetaTypeEnum.Editor ? true : false,
                                });
                            }}
                        >
                            <div className="icon">
                                <div className="default">
                                    <i className={`${item.icon}`}></i>
                                </div>
                                <div className="checkmark">
                                    <i className="icon-checkmark"></i>
                                </div>
                            </div>
                            <div className="text">
                                {item.title}
                                <div className="sub-text">
                                    {item.type === this.state.data.type
                                        ? "Seçildi"
                                        : "Seçmek için tıkla"}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    renderDropdownWithContentType() {
        return (
            <Dropdown
                options={(this.props.contentTypes || []).map(item => ({ key: item.id as string, text: item.title }))}
                className="custom-dropdown"
                calloutProps={{ className: "custom-dropdown-callout" }}
                label="Seçim Kutusunu Dolduracak İçerik Tipi Seçiniz"
                onChange={(_, opt) => {
                    this.setState({
                        data: {
                            ...this.state.data,
                            bindings: {
                                ...this.state.data.bindings,
                                contentTypeId: opt?.key as string
                            } as IMetaBindingObject
                        }
                    })
                }}
                selectedKey={this.state.data?.bindings?.contentTypeId}
            />
        )
    }

    renderDropdownWithCustomOptions() {
        const items = [...(this.state.data.bindings?.customOptions || []), { key: "", text: "" }];
        return (
            <div className="meta-dropdown-values">
                { items.map((item, index) => (
                    <div className="meta-dropdown-value row">
                        <TextField
                            className="custom-textfield col"
                            label="Öğe Değeri"
                            placeholder="ÖR: deger1"
                            value={item.key as string}
                            onChange={(event: any) => this.onFieldChange(index, "key", event.target.value)}
                        />
                        <TextField
                            className="custom-textfield col"
                            label="Öğe Başlığı"
                            placeholder="ÖR: Değer 1"
                            value={item.text as string}
                            onChange={(event: any) => this.onFieldChange(index, "text", event.target.value)}
                        />
                        <i
                            onClick={() => {
                                const clonedItems = _.cloneDeep(this.state.data.bindings?.customOptions || []);
                                clonedItems.splice(index, 1);
                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        bindings: {
                                            ...this.state.data.bindings,
                                            customOptions: clonedItems
                                        } as IMetaBindingObject
                                    }
                                })
                            }}
                            className={(index + 1) === items.length ? "icon-close passive" : "icon-close1"}
                        />

                    </div>
                ))}
            </div>
        )
    }

    renderDropdownFillOptions() {
        if (this.state.data?.type !== MetaTypeEnum.Dropdown && this.state.data?.type !== MetaTypeEnum.ComboBox) return null;
        return (
            <React.Fragment>
                <Dropdown
                    options={[{
                        key: MetaBindingObjectTypes.ContentType,
                        text: "İçerik Tiplerinden Doldur"
                    },
                    {
                        key: MetaBindingObjectTypes.Specification,
                        text: "Spesifikasyonlardan Doldur"
                    },
                    {
                        key: MetaBindingObjectTypes.Custom,
                        text: "Özel Olarak Doldur"
                    }]}
                    className="custom-dropdown"
                    calloutProps={{ className: "custom-dropdown-callout" }}
                    label="Seçim Kutusu Doldurma Tipi"
                    selectedKey={this.state.data?.bindings?.type}
                    onChange={(_, opt) => {
                        this.setState({
                            data: {
                                ...this.state.data,
                                bindings: {
                                    ...this.state.data.bindings,
                                    type: opt?.key as MetaBindingObjectTypes,
                                    contentTypeId: ""
                                }
                            }
                        })
                    }}
                />
                {this.state.data?.bindings?.type === MetaBindingObjectTypes.ContentType && (
                    this.renderDropdownWithContentType()
                )}
                {this.state.data?.bindings?.type === MetaBindingObjectTypes.Custom && (
                    this.renderDropdownWithCustomOptions()
                )}
            </React.Fragment >
        )
    }

    renderMetaDefaults() {
        return (
            <div className="meta-defaults meta-fields">
                <Label style={{ marginTop: "5px" }}>Önizleme & Varsayılan Değer / Değerler</Label>
                <MetaField
                    type={this.state.data.type}
                    meta={{ ...this.state.data, data: this.state.data.default } as IMeta}
                    onChange={(data) => this.setState({ data: { ...this.state.data, default: data } })}
                    toggleModal={this.props.toggleModal}
                />
            </div>
        )
    }

    render() {
        if (!this.props.show) return null;
        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.props.isUpdateMode ? "Meta Düzenle" : "Yeni Meta Ekle",
                    closeButtonAriaLabel: "Kapat",
                    subText:
                        "Geçerli içerik tipine sahip içeriklerde kullanılmak üzere, belirlediğiniz tipte ve isimde bir meta (özel alan) ekleyin!",
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: false,
                    styles: { main: { maxWidth: "1250px !important" } },
                }}
            >
                <div className="meta-scrollable">
                    <Label style={{ marginTop: "2.5px" }}>Meta Tipi</Label>
                    {this.renderMetaList()}
                    {this.renderForm()}
                    {this.renderDropdownFillOptions()}
                    {this.renderMetaDefaults()}
                </div>
                <DialogFooter>
                    <DefaultButton
                        onClick={() =>
                            this.setState(
                                { data: { type: MetaTypeEnum.Text, column: 4 } as IMeta },
                                () => this.props.onDismiss()
                            )
                        }
                        text="İptal"
                    />
                    <PrimaryButton
                        onClick={() => {
                            this.props.onSave(this.state.data);
                            this.setState({
                                data: { type: MetaTypeEnum.Text, column: 4 } as IMeta,
                            });
                        }}
                        text={this.props.isUpdateMode ? "Güncelle" : "Ekle"}
                        disabled={
                            !this.state.data.title || !this.state.data.name ? true : false
                        }
                    />
                </DialogFooter>
            </Dialog>
        );
    }
}

import * as React from "react";
import { BaseFieldProps } from './types';
import { PureStringField } from './Pure/PureStringField';

export interface StringFieldProps {
	multiline?: boolean;
	type?: string;
}
// Kendisine gelen Property ismine ve değerine göre TextField render eder.
export default class StringField extends React.Component<BaseFieldProps & StringFieldProps> {
	render() {
		if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;
		return (
			<PureStringField
				label={this.props.property.text || this.props.property.name}
				value={this.props.value}
				multiline={this.props.multiline}
				onChange={value => this.props.onChange(value)}
				type={this.props.type}
				text={this.props.property.text as string}
				description={this.props.property.description as string}
			/>
		);
	}
}
import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";
import { MetaTypeEnum } from "app/shared";

import { ImageChildrenDefinitions } from 'components/cms/definitions';

export class SupporterListWithSliderDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "supporter-list-with-slider";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: 'title',
            text: 'Başlık',
            mapping: {
                type: PropertyMappingType.String
            }
        },
        {
            name: "supporters",
            description: "Supporter Elemanları",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: 'title',
                        text: 'Başlık',
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: 'description',
                        text: 'Açıklama',
                        mapping: {
                            type: PropertyMappingType.String
                        }
                    },
                    {
                        name: 'image',
                        text: 'İkon',
                        mapping: {
                            type: PropertyMappingType.ParentObject,
                            children: ImageChildrenDefinitions
                        }
                    },
                    {
                        name: 'position',
                        text: 'Pozisyon',
                        mapping: {
                            type: PropertyMappingType.Object,
                        }
                    },
                    {
                        name: 'anchor',
                        text: 'Link',
                        mapping: {
                            type: PropertyMappingType.LinkWizard
                        }
                    },
                ],
            },
            group: {
                key: "data",
                title: "Veriler",
                withObject: false,
            }
        },
    ];

    static options: EntryOptions = {
        meta: {
            use: false,
            allowedTypes: [],
        },
        mappingFields: [
            {
                name: "title",
                text: "Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: 'description',
                text: 'Açıklama',
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: 'position',
                text: 'Pozisyon',
                types: [MetaTypeEnum.Dropdown],
            },
            {
                name: "image",
                text: "Logo",
                types: [MetaTypeEnum.SingleImage],
            },
            {
                name: 'anchor',
                text: 'Link',
                types: [MetaTypeEnum.Link],
            },
        ],
    };
}

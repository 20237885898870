import { CmsReactComponent, CmsReactComponentProps } from 'components/cms/CmsReactComponent';
import {
    PropertyDefinition,
} from "app/library/layout-builder";

export class ContactFormDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "contact-form";

    static propertyDefinitions: PropertyDefinition[] = [];
}

import { FileTypes } from "app/services/file-manager";

export const fileTypes = [
    {
        key: FileTypes.All,
        text: "Tümü"
    },
    {
        key: FileTypes.Image,
        text: "Görsel"
    },
    {
        key: FileTypes.Video,
        text: "Video"
    },
    {
        key: FileTypes.File,
        text: "Dosya"
    }
]
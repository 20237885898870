import * as React from "react";
import { ContentTypeService, IContentType, mapContentTypeFromEntity } from "app/services/content-type";
import { IPost, mapPostFromEntity, PostService } from "app/services/post";
import { FilterField, HttpStatusCode, ILinkWizard } from 'app/shared';
import { convertToQueryString } from "app/shared/functions";
import BaseDynamicFilter from "../DynamicFilter/BaseDynamicFilter";
import { PureDropdownField } from './PureDropdownField';
import { PureStringField } from './PureStringField';
import { PureComboboxField } from "./PureComboboxField";

const targetTypes = [{
    key: "_self",
    text: "Bağlantı aynı pencere içerisinde açılır."
},
{
    key: "_blank",
    text: "Bağlantı yeni bir pencerede açılır."
},
{
    key: "_parent",
    text: "Bağlantı o anda açık sayfayı oluşturmuş bir ana sayfa varsa onun yerine konur."
},
{
    key: "_top",
    text: "Bağlantı aynı pencere içerisinde en üstten itibaren açılır."
}];

interface LinkFieldState {
    contentTypes: IContentType[];
    post: IPost;
    posts: IPost[];
}

interface PureLinkFieldProps {
    anchor: ILinkWizard | null;
    onChange: (anchor: ILinkWizard | null) => void;
    contentType?: string;
    language?: string;
    onChangeContentType?: (contentType: string) => void;
    label?: string;
}

// Link sihirbazı objesini yönetir.
export default class PureLinkField extends React.Component<PureLinkFieldProps, LinkFieldState> {

    state = {
        contentTypes: [] as IContentType[],
        post: {} as IPost,
        posts: [] as IPost[]
    }

    UNSAFE_componentWillMount() {
        this.getContentTypes();
        if ((this.props.contentType || this.props.anchor?.contentType) && this.props.anchor?.href) {
            this.getPosts(this.props.contentType || this.props.anchor?.contentType)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: PureLinkFieldProps) {
        const currValue = this.props.anchor as ILinkWizard;
        const nextValue = nextProps.anchor as ILinkWizard;
        if (!nextValue?.external && nextValue?.external !== currValue?.external) {
            this.getContentTypes();
        }
    }

    async getPosts(contentType: string) {
        const service = new PostService();
        const response = await service.getPosts({ page: 1, pageSize: 100, descending: false, language: this.props.language || "en" }, contentType);

        if (response.status === HttpStatusCode.OK) {
            const mapped = (response.data.attributes?.list || []).map(x => mapPostFromEntity(x));
            this.setState({
                posts: mapped
            })
        }
    }

    getFilterQueryString(data: FilterField[]): string {
        let url = "";
        let params = {};

        (data || []).forEach(item => {
            if (params[item.name]) {
                params[item.name] += `,${item.value}`
            }
            else {
                params[item.name] = item.value;
            }
        })
        url = convertToQueryString(params);

        return url;
    }

    async getContentTypes() {
        const service = new ContentTypeService();
        const response = await service.getContentTypes({ page: 1, pageSize: 100, descending: false });
        if (response.status === HttpStatusCode.OK) {
            const mapped = (response.data.attributes?.list || []).map(x => mapContentTypeFromEntity(x));
            this.setState({
                contentTypes: mapped
            })
        }
    }

    renderExternal() {
        const value = this.props.anchor || {} as ILinkWizard;
        return (
            <React.Fragment>
                <PureStringField
                    label="Link Yazısı"
                    value={value.children}
                    onChange={x => this.props.onChange({ ...value, children: x })}
                    text="Link Yazısı"
                    description="Buton ile verilen URL'lerin gözükecek yazılarını belirleyebilirsiniz."
                />
                <PureStringField
                    label="Harici URL"
                    value={value.href}
                    onChange={x => this.props.onChange({ ...value, href: x })}
                    text="Harici URL"
                    description="İçeriğinizin yönleneceği URL'i belirleyin. (ÖR: http://www.google.com.tr)"
                />
                <PureDropdownField
                    label="Link Hedefi"
                    value={value.target}
                    onChange={x => this.props.onChange({ ...value, target: x })}
                    customOptions={targetTypes}
                    text="Link Hedefi"
                    description="İçeriğinizin linkini harici bir siteye veya site içi bir kayıta yönlendirebilirsiniz."
                />
            </React.Fragment>
        )
    }

    renderPageLink() {
        const value = this.props.anchor || {} as ILinkWizard;
        return (
            <React.Fragment>
                <PureStringField
                    label="Link Yazısı"
                    value={value.children}
                    onChange={x => this.props.onChange({ ...value, children: x })}
                    text="Link Yazısı"
                    description="Buton ile verilen URL'lerin gözükecek yazılarını belirleyebilirsiniz."
                />

                <PureDropdownField
                    label="İçerik Tipi Seçin"
                    value={value.contentType}
                    onChange={x => {
                        this.props.onChange({ ...value, contentType: x, href: "" });
                        this.getPosts(x);
                    }}
                    customOptions={(this.state.contentTypes || []).map(x => ({ key: x.id as string, text: x.title }))}
                    text="İçerik Tipi Seçin"
                    description="Bir içerik kategorisi seçiniz."
                />
                <PureComboboxField
                    label="İçerik Seçin"
                    value={value.href}
                    onChange={x => this.props.onChange({ ...value, href: x })}
                    customOptions={(this.state.posts || []).map(x => ({ key: x.url as string, text: x.title }))}
                    text="İçerik Seçin"
                    description="Geçerli içeriğinizin yönleneceği içeriği seçiniz."
                />
                <PureDropdownField
                    label="Link Hedefi"
                    value={value.target}
                    onChange={x => this.props.onChange({ ...value, target: x })}
                    customOptions={targetTypes}
                    text="Link Hedefi"
                    description="İçeriğinizin linkini harici bir siteye veya site içi bir kayıta yönlendirebilirsiniz."
                />
            </React.Fragment>
        )
    }

    renderDynamicFilters() {
        const value = this.props.anchor || {} as ILinkWizard;

        return (
            <React.Fragment>
                <PureStringField
                    label="Link Yazısı"
                    value={value.children}
                    onChange={x => this.props.onChange({ ...value, children: x })}
                    text="Link Yazısı"
                    description="Buton ile verilen URL'lerin gözükecek yazılarını belirleyebilirsiniz."
                />
                <PureDropdownField
                    label="İçerik Tipi Seçin"
                    value={value.contentType}
                    onChange={x => {
                        this.props.onChange({ ...value, contentType: x, href: "" });
                        this.getPosts(x);
                    }}
                    customOptions={(this.state.contentTypes || []).map(x => ({ key: x.id as string, text: x.title }))}
                    text="İçerik Tipi Seçin"
                    description="Bir içerik kategorisi seçiniz."
                />
                <PureComboboxField
                    label="İçerik Seçin"
                    value={value.href}
                    onChange={x => this.props.onChange({ ...value, href: x })}
                    customOptions={(this.state.posts || []).map(x => ({ key: x.url as string, text: x.title }))}
                    text="İçerik Seçin"
                    description="Geçerli içeriğinizin yönleneceği içeriği seçiniz."
                />
                <div
                    style={{
                        padding: 10,
                        background: "rgb(249,249,249)",
                        pointerEvents: value?.href ? "auto" : "none",
                        opacity: value?.href ? 1 : 0.3
                    }}>
                    <BaseDynamicFilter
                        value={value.filter?.data || []}
                        onChange={(data) => {
                            const queryString = this.getFilterQueryString(data);
                            this.props.onChange({ ...this.props.anchor, params: queryString, filter: { show: true, data } } as ILinkWizard)
                        }}
                        hideTitle
                    />
                </div>
            </React.Fragment>
        )
    }

    render() {
        const value = this.props.anchor || {} as ILinkWizard;
        return (
            <div className="anchor-field">
                <PureDropdownField
                    label={this.props?.label || "Link Tipi"}
                    value={value?.filter?.show ? "filter" : typeof value?.external === "boolean" ? value?.external ? "external" : "site" : ""}
                    onChange={x => {
                        if (!x) {
                            this.props.onChange(null);
                        }
                        else {
                            this.props.onChange({ external: x === "external" ? true : x === "site" ? false : "", target: "_self" } as ILinkWizard)
                        }
                    }}
                    customOptions={[{
                        key: "",
                        text: "Link Yok"
                    },
                    {
                        key: "external",
                        text: "Harici Link"
                    },
                    {
                        key: "site",
                        text: "Site İçi Link"
                    }]}
                    text={this.props?.label || "Link Tipi"}
                    description="İçeriğinizin linkini harici bir siteye veya site içi bir kayıta yönlendirebilirsiniz."
                />
                {value?.filter?.show ? this.renderDynamicFilters() : typeof value.external === "boolean" ? value.external ? this.renderExternal() : this.renderPageLink() : null}
            </div>

        );
    }
}

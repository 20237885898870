import { IContentType } from 'app/services/content-type';
import { IList } from 'app/shared';
import { ContentTypeActionTypes, SET_CONTENT_TYPE, SET_CONTENT_TYPES } from './types';

/**
 * Global olarak bulunan Content Type Datasını Set Eder.
 */
export function setContentTypes(contentTypes: IList<IContentType[]>): ContentTypeActionTypes {
    return {
        type: SET_CONTENT_TYPES,
        contentTypes
    }
}

/** 
 * Global olarak bulunan Content Type Objesini Set Eder.
 */
export function setContentType(contentType: IContentType): ContentTypeActionTypes {
    return {
        type: SET_CONTENT_TYPE,
        contentType
    }
}
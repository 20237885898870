import { Component } from 'react';
import { History } from "history";

export type TitleRenderer = () => void;

export interface OwnProps {
	title: string;
	subTitle: string;
	showBack?: boolean;
	titleRenderer?: TitleRenderer,
	history?: History<any>,
	backUrl?: string;
}

class PageTitle extends Component<OwnProps> {
	render() {
		const { showBack, title, history, subTitle, backUrl } = this.props;
		return (
			<div className={showBack ? "page-title show-back row" : "page-title row"}>
				{showBack && (
					<button onClick={() => backUrl ? history?.push(backUrl) : history?.go(-1)}>
						<span className="line"></span>
						<span className="line"></span>
					</button>
				)}
				<div className="title">
					{this.props.titleRenderer ? this.props.titleRenderer() : title}
					<div className="text">
						{subTitle}
					</div>
				</div>
			</div>
		)
	}
}

export default PageTitle;
import { PageActionTypes, PageStore, INITIAL_PAGE_DATA, SET_PAGE_ERRORS, RESET_PAGE_DATA, SET_PAGE_DATA } from './types';

const initialState: PageStore = {
	pageData: {},
	pageErrors: {}
};

export function pageReducer(state = initialState, action: PageActionTypes): PageStore {
	switch (action.type) {
		case INITIAL_PAGE_DATA:
			return {
				pageData: action.pageData,
				pageErrors: {}
			};
		case SET_PAGE_ERRORS:
			return {
				...state,
				pageErrors: action.pageErrors
			};
		case RESET_PAGE_DATA:
			return {
				pageData: {},
				pageErrors: {},
			};
		case SET_PAGE_DATA:
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.key]: action.value
				}
			}
		default:
			return state;
	}
}
import * as React from "react";
import { BaseFieldProps } from "./types";
import { PureAlignField } from "./Pure/PureAlignField";

// Kendisine gelen Property ismine ve değerine göre Align Field render eder.
export default class AlignField extends React.Component<BaseFieldProps> {

  render() {
    if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

    return (
      <PureAlignField
        label={this.props.property.name}
        value={this.props.value}
        onChange={value => this.props.onChange(value)}
        text={this.props.property.text as string}
        description={this.props.property.description as string}
      />
    );
  }
}

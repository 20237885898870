import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from "app/redux/reducer";
import { Dispatch, bindActionCreators } from 'redux';
import PageTitle from 'components/customs/PageTitle';
import { TextField, MessageBar, MessageBarType, PrimaryButton, Checkbox, DefaultButton } from 'office-ui-fabric-react';
import * as _yup from "yup";
import { RouteComponentProps } from 'react-router-dom';

import { setPageData, setPageErrors, initialPageData } from 'app/redux/page/actions';
import { toggleConfirmDialog } from 'app/redux/system/actions';
import { StatusEnum } from 'app/shared';
import { castThunkAction } from 'app/utils/casting';
import _ from "lodash";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { v4 as uuidv4 } from "uuid";
import moment from "moment"
import { deleteMenu, getMenu, getMenuByLanguage, IMenu, IMenuItem, updateMenu } from 'app/services/menu';
import { getPostsForPanel, IPost } from 'app/services/post';
import MenuAccordion from 'components/customs/MenuAccordion';
import AbsoluteLoader from 'components/customs/Loader/AbsoluteLoader';
import MenuItemModal from 'components/customs/Modals/MenuItemModal';
import Sidebar from 'components/customs/Sidebar/Sidebar';
import PageLayout from 'containers/PageLayout';
import { getConfiguration, IThemeSettings } from 'app/services/configuration';
import TranslatorPostBar from 'components/customs/TranslatorBar/TranslatorPostBar';

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<{ menuId: string }>) {
    return {
        ...ownProps,
        defaultLanguage: state.system.defaultLanguage,
        menuId: ownProps.match.params.menuId,
        language: state.system.language,
        pageData: state.page.pageData as IMenu,
        pageErrors: state.page.pageErrors,
        contentTypes: state.contentType.contentTypes.list
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators({
            setPageData,
            setPageErrors,
            initialPageData,
            updateMenu,
            deleteMenu,
            getPostsForPanel,
            toggleConfirmDialog,
            getMenu,
            getMenuByLanguage,
            getConfiguration
        }, dispatch)
    };
}

type EditMenuProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface OwnState {
    messageType: MessageBarType;
    activeAccordion: string;
    accordionLoader: string;
    accordionData: IPost[];
    configurationData: IThemeSettings;
    customLink: {
        title: string;
        url: string;
    };
    selectedAccordionItems: IMenuItem[];
    menuItemModal: {
        show: boolean;
        data: IMenuItem;
        parentId: string;
    };
}

class EditMenu extends Component<EditMenuProps, OwnState> {

    state = {
        messageType: MessageBarType.warning,
        activeAccordion: "custom-link",
        accordionLoader: "",
        accordionData: [] as IPost[],
        configurationData: {} as IThemeSettings,
        customLink: {
            title: "",
            url: ""
        },
        selectedAccordionItems: [] as IMenuItem[],
        menuItemModal: {
            show: false,
            data: {} as IMenuItem,
            parentId: ""
        }
    }

    UNSAFE_componentWillMount() {
        castThunkAction<IThemeSettings>(this.props.getConfiguration(true)).then(configuration => {
            if (configuration) {
                this.setState({
                    configurationData: configuration
                })
            }
            this.props.getMenu(this.props.menuId);
        })
    }

    getAccordionPosts(contentTypeId: string, language?: string) {
        this.setState({
            accordionLoader: contentTypeId,
            activeAccordion: contentTypeId,
            selectedAccordionItems: []
        }, () => {
            castThunkAction<IPost[]>(this.props.getPostsForPanel(contentTypeId, language || "en")).then(response => {
                if (response) {
                    this.setState({
                        accordionLoader: "",
                        accordionData: response || []
                    })
                }
            })
        })

    }

    getExpandedMenuItems(menuItems: IMenuItem[] = []): IMenuItem[] {
        return menuItems.map(item => ({
            ...item,
            expanded: true,
            children: this.getExpandedMenuItems(item.children)
        }))
    }

    onCloseMenuItemModal() {
        this.setState({
            menuItemModal: {
                show: false,
                data: {} as IMenuItem,
                parentId: ""
            }
        })
    }

    deleteMenuItem(id: string, parentId: string) {
        const items = _.cloneDeep(this.props.pageData.items || []);
        if (!parentId) {
            const findIndex = this.props.pageData.items.findIndex(x => x.id === id);
            items.splice(findIndex, 1);
            this.props.setPageData("items", items);
        }
        else {
            const parentMenuItem = this.findMenuItem(items, parentId);
            parentMenuItem?.children.splice(parentMenuItem.children.findIndex((x) => x.id === id), 1);
            this.props.setPageData("items", items);
        }
    }

    updateMenuItem(id: string, parentId: string, data: IMenuItem) {
        const items = _.cloneDeep(this.props.pageData.items || []);
        if (!parentId) {
            const findIndex = this.props.pageData.items.findIndex(x => x.id === id);
            items[findIndex] = data;
            this.props.setPageData("items", items);
        } else {
            const parentMenuItem = this.findMenuItem(items, parentId);
            if (parentMenuItem) {
                const nodeIndex = parentMenuItem?.children.findIndex((x) => x.id === id);
                parentMenuItem.children[nodeIndex] = {
                    ...parentMenuItem.children[nodeIndex],
                    ...data
                };
                this.props.setPageData("items", items);
            }
        }
    }

    findMenuItem(items: IMenuItem[], id: string): IMenuItem | undefined {
        const finded = items.find(x => x.id === id);
        if (finded)
            return finded;

        for (let i = 0; i < items.length; i++) {
            const curr = this.findMenuItem(items[i].children, id);

            if (curr)
                return curr;
        }

        return undefined;
    }

    /** Özel bağlantı ya da sayfaları, global şemaya ekelr. */
    _addToMenu(fromCustomLink?: boolean) {
        let menuItems: IMenuItem[] = _.cloneDeep(this.props.pageData.items);
        if (fromCustomLink) {
            menuItems.push({
                id: uuidv4(),
                title: this.state.customLink.title,
                url: this.state.customLink.url,
                isBlank: false,
                icon: "",
                className: "",
                children: []
            });
        }
        else {
            menuItems = [...menuItems, ...this.state.selectedAccordionItems];
        }
        this.setState({
            customLink: { url: "", title: "" },
            selectedAccordionItems: []
        }, () => {
            this.props.setPageData("items", menuItems);
        })
    }

    resetData() {
        this.setState({
            messageType: MessageBarType.warning
        }, () => {
            this.props.initialPageData<IMenu>({
                title: "",
                status: StatusEnum.Published,
                url: "/",
                items: [],
                language: this.props.defaultLanguage,
                relationId: ""
            })
        })
    }

    renderTextByMessageType() {
        switch (this.state.messageType) {
            case MessageBarType.success:
                return "Kaydetme işlemi başarıyla gerçekleşmiştir."
            case MessageBarType.error:
                return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin."
            default:
                return "Aşağıdaki değerler üzerinden düzenleme yapabilirsiniz."
        }
    }

    renderMessageBar() {
        if (this.state.messageType !== MessageBarType.warning)
            return (
                <MessageBar
                    messageBarType={this.state.messageType}
                    isMultiline={false}
                >
                    {this.renderTextByMessageType()}
                </MessageBar>
            )
        return null;
    }

    onChangeLanguage(language: string) {
        if (language !== this.props.pageData.language) {
            this.setState({
                activeAccordion: "custom-link"
            }, () => {
                this.props.getMenuByLanguage(this.props.pageData, language);
            })
        }
    }

    onCloneLanguage() {
        this.setState({
            activeAccordion: "custom-link"
        }, () => {
            this.props.getMenuByLanguage(this.props.pageData, this.props.pageData.language, true)
        })
    }

    renderTranslatorBar() {
        if (!this.state.configurationData?.config?.languages || this.state.configurationData?.config?.languages.length <= 1) return null;

        return (
            <TranslatorPostBar
                languages={this.state.configurationData?.config?.languages?.map(item => item.code?.split("-")[0])}
                onChange={(language) => this.onChangeLanguage(language)}
                toggleDialog={this.props.toggleConfirmDialog}
                current={this.props.pageData.language}
                onClone={() => this.onCloneLanguage()}
                default={this.props.defaultLanguage}
            />
        )
    }

    onDelete() {
        this.props.toggleConfirmDialog(true, {
            callback: () => {
                let menuData = this.props.pageData;
                if (this.props.pageData.language !== this.props.defaultLanguage) {
                    castThunkAction<IMenu>(this.props.getMenu(this.props.pageData.id as string, this.props.defaultLanguage, true)).then(response => {
                        menuData = response;
                        castThunkAction(this.props.deleteMenu(this.props.pageData.id as string)).then(res => {
                            if (res) {
                                this.props.initialPageData(menuData)
                            }
                        });
                    });
                }
                else {
                    castThunkAction(this.props.deleteMenu(this.props.pageData.id as string)).then(res => {
                        if (res) {
                            this.props.history.push("/menus")
                        }
                    })
                }

            }
        })
    }

    /**
     * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
     * formErrors Objesini Dolduracak Action'u Tetikler.
     */
    onSubmit(isDraft?: boolean) {
        const {
            pageData,
            setPageErrors
        } = this.props;
        const validationSchema = _yup.object({
            title: _yup.string().required(
                "Bu alan zorunludur!"
            )
        });
        let formErrors = {};
        validationSchema
            .validate(pageData, {
                abortEarly: false
            })
            .then(() => {
                setPageErrors({});
                castThunkAction(this.props.updateMenu(this.props.pageData)).then(res => {
                    window.scrollTo({ top: 0 });
                    this.setState({
                        messageType: res ? MessageBarType.success : MessageBarType.error
                    })
                })
            })
            .catch(err => {
                err.inner.forEach((e: any) => {
                    const { path, message } = e;
                    formErrors[path] = message;
                });
                setPageErrors(formErrors);
            });
    };


    renderForm() {
        const { pageData, pageErrors, setPageData } = this.props;
        return (
            <div className="form-groups">
                <div style={{ paddingTop: 0, paddingRight: 0 }} className="form-group">
                    <div style={{ background: "#f5f5f5" }} className="title-form row">
                        <TextField
                            placeholder="Menü Adı"
                            value={pageData.title || ""}
                            label="Menü Adı"
                            className="custom-textfield col-md-12 border"
                            errorMessage={pageErrors.title}
                            onChange={(event: any) => setPageData("title", event.target.value)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderCustomLink() {
        // const isValidateUrl = validateUrl(this.state.customLink.url) ? true : false;
        return (
            <MenuAccordion
                showContent={this.state.activeAccordion === "custom-link" ? true : false}
                title="Özel Bağlantı Ekle"
                onChange={() => {
                    this.setState({
                        activeAccordion: "custom-link"
                    })
                }}
            >
                <TextField
                    placeholder="Başlık"
                    value={this.state.customLink.title || ""}
                    label="Başlık"
                    className="custom-textfield border"
                    onChange={(event: any) => this.setState({ customLink: { ...this.state.customLink, title: event.target.value } })}
                />
                <TextField
                    placeholder="URL"
                    value={this.state.customLink.url || ""}
                    label="URL"
                    className="custom-textfield border"
                    // errorMessage={this.state.customLink.url && !isValidateUrl ? "Lütfen geçerli bir URL giriniz!" : ""}
                    onChange={(event: any) => this.setState({ customLink: { ...this.state.customLink, url: event.target.value } })}
                />
                <DefaultButton
                    text="Menüye Ekle"
                    disabled={!this.state.customLink.title}
                    style={{
                        fontSize: 11,
                        padding: "0px 10px",
                        marginLeft: "auto",
                        marginTop: 7.5,
                        float: "right"
                    }}
                    onClick={() => this._addToMenu(true)}
                />
            </MenuAccordion>
        )
    }

    renderContentTypes() {
        if (!this.props.contentTypes || this.props.contentTypes.length === 0) return null;
        return (
            (this.props.contentTypes || []).map((item, index) => (
                <MenuAccordion
                    showContent={this.state.activeAccordion === item.id ? true : false}
                    key={index}
                    title={item.title}
                    onChange={() => this.getAccordionPosts(item.id as string, this.props.pageData.language)}
                >
                    <div className="form-wrapper">
                        {this.state.accordionData.length > 0 ? (
                            <React.Fragment>
                                <div className="menu-items">
                                    {this.state.accordionData.map((menuItem, menuIndex) => {
                                        const selectedIndex = this.state.selectedAccordionItems.findIndex(x => x.url === menuItem.url);
                                        return (
                                            <div key={menuIndex} className="menu-item row">
                                                <Checkbox
                                                    className="custom-checkbox"
                                                    label={menuItem.title}
                                                    onChange={(ev, checked) => {
                                                        const selectedItems = _.cloneDeep(this.state.selectedAccordionItems);
                                                        if (!checked) {
                                                            selectedItems.splice(selectedIndex, 1)
                                                        }
                                                        else {
                                                            selectedItems.push({ url: menuItem.url, title: menuItem.title, id: uuidv4(), children: [], isBlank: false, className: "", icon: "" })
                                                        }
                                                        this.setState({ selectedAccordionItems: selectedItems })
                                                    }}
                                                    checked={selectedIndex !== -1 ? true : false}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="menu-items-footer row">
                                    <span
                                        onClick={() => this.setState({ selectedAccordionItems: (this.state.accordionData || []).map(x => ({ id: uuidv4(), url: x.url, title: x.title, icon: "", className: "", isBlank: false, children: [] })) })}
                                        className="select-all"
                                    >
                                        Tümünü Seç
                                    </span>
                                    <DefaultButton
                                        text="Menüye Ekle"
                                        disabled={this.state.selectedAccordionItems.length === 0}
                                        style={{
                                            fontSize: 11,
                                            padding: "0px 10px",
                                            marginLeft: "auto",
                                            marginTop: 7.5
                                        }}
                                        onClick={() => this._addToMenu()}
                                    />
                                </div>

                            </React.Fragment>
                        ) : (
                            <div className="menu-not-found row">
                                <i className="icon-info"></i>
                                <div className="text">
                                    Geçerli içerik tipine ait bir içerik bulunamadı.
                                    </div>
                            </div>
                        )}

                    </div>
                    <AbsoluteLoader className="absolute accordion" show={this.state.accordionLoader && this.state.accordionLoader === item.id ? true : false} />
                </MenuAccordion>
            ))
        )
    }

    renderMenuItems() {
        return (
            <div className="menu-tree-designer">
                <div className="menu-info">
                    <i className="icon-info"></i>
                    Menü'ye eklenmiş olan elemanları aşağıdan görebilirsiniz. Sol taraftan bir sayfa ya da özel bir bağlantı'yı menü'ye ekleyebilirsiniz.
                </div>
                <MenuItemModal
                    show={this.state.menuItemModal.show ? true : false}
                    data={this.state.menuItemModal.data}
                    onDismiss={() => this.onCloseMenuItemModal()}
                    onSave={() => {
                        this.updateMenuItem(this.state.menuItemModal.data.id as string, this.state.menuItemModal.parentId, this.state.menuItemModal.data);
                        this.onCloseMenuItemModal();
                    }}
                    onDelete={() => {
                        this.deleteMenuItem(this.state.menuItemModal.data.id as string, this.state.menuItemModal.parentId);
                        this.onCloseMenuItemModal();
                    }}
                    onChange={(key: string, value) => {
                        this.setState({
                            menuItemModal: {
                                ...this.state.menuItemModal,
                                data: {
                                    ...this.state.menuItemModal.data,
                                    [key]: value
                                }
                            }
                        })
                    }}
                />
                {this.props.pageData.items && this.props.pageData.items.length > 0 && (
                    <SortableTree
                        treeData={this.getExpandedMenuItems(this.props.pageData.items || [])}
                        onChange={treeData => this.props.setPageData("items", treeData)}
                        isVirtualized={false}
                        rowHeight={45}

                        scaffoldBlockPxWidth={20}
                        generateNodeProps={rowInfo => {
                            return ({
                                buttons: [
                                    <button onClick={() => this.setState({ menuItemModal: { show: true, data: rowInfo.node as IMenuItem, parentId: rowInfo.parentNode?.id || "" } })}>
                                        <i className="icon-setting" />
                                    </button>
                                ]
                            })
                        }}
                    />
                )}
            </div>
        )
    }

    renderMenuDesigner() {
        return (
            <div className="menu-designer row">
                <div className="col-md-3 menu-designer-panel">
                    <div className="form-groups menu-item-group row">
                        {this.renderContentTypes()}
                        {this.renderCustomLink()}
                    </div>
                </div>
                <div className="col-md-9 menu-designer-items">
                    {this.renderForm()}
                    {this.renderMenuItems()}
                </div>
            </div>
        )
    }

    render() {
        const { pageData } = this.props;
        return (
            <PageLayout history={this.props.history} match={this.props.match} location={this.props.location}>
                <div className="page push-all">
                    <PageTitle
                        title="Düzenle"
                        titleRenderer={
                            () => (
                                <span className="edit-title">
                                    Düzenle:
                                    <strong>{this.props.pageData?.title || "-"}</strong>
                                </span>
                            )
                        }
                        showBack
                        backUrl="/menus"
                        history={this.props.history}
                        subTitle="Aşağıdan geçerli menü'yü düzenleyebilirsiniz."
                    />
                    <PrimaryButton
                        onClick={() => { this.resetData() }}
                        iconProps={{ iconName: "Add" }}
                        className="absolute-create"
                        text="Yeni Menü Ekle"
                        styles={{
                            root: {
                                background: "#62a98e",
                                borderColor: "#5b9e85",
                                color: "#fff"
                            },
                            rootHovered: {
                                background: "#5b9e85",
                                borderColor: "#5b9e85"
                            },
                            rootDisabled: {
                                background: "#ececec"
                            }
                        }}
                    />
                    {this.renderTranslatorBar()}
                    <div className="content row">
                        <div className="left col no-padding">
                            {this.renderMessageBar()}
                            {this.renderMenuDesigner()}
                        </div>
                        <Sidebar
                            publishBar={{
                                onSave: (isDraft) => this.onSubmit(isDraft),
                                onDelete: () => this.onDelete(),
                                status: pageData.status,
                                hidePreviewButton: true,
                                isEdit: true,
                                userLabel: pageData.updatedBy ? 'Ekleyen Kullanıcı:' : 'Düzenleyen Kullanıcı:',
                                publishDateText: pageData.updatedBy ? 'Yayınlanma Tarihi:' : 'Eklenme Tarihi:',
                                dateText: moment((pageData.updatedAt ? pageData.updatedAt : pageData.createdAt)).format('DD.MM.YYYY, HH:mm')

                            }}
                            categoryBar={{
                                hide: true
                            }}
                            archiveBar={{
                                hide: true
                            }}

                            layoutBar={{
                                hide: true
                            }}
                            thumbnailBar={{
                                hide: true
                            }}
                        />
                    </div>
                </div>
            </PageLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMenu);
import React from "react";
import ComponentDescription from "components/customs/ComponentDescription";
import ComponentHeader from "components/cms/ComponentHeader";
import { HeadingDefinitions } from "./HeadingDefinitions";

export class Heading extends HeadingDefinitions {
    render() {
        const { className, designing } = this.props;

        if (this.props.hideRuntime && !this.props.designing) return null;
        const text = this.props.schemaNode?.getProp("children");
        return (
            <React.Fragment>
                <div
                    data-schema-id={this.props.id}
                    className={[
                        "fa-Heading fa-draggable disable-inside",
                        designing && "designing",
                        className
                    ]
                        .filter((e) => !!e)
                        .join(" ")}
                >
                    {designing && (
                        <ComponentHeader
                            componentEntry={this.props.entry}
                            componentSchemaId={this.props.id as string}
                        />
                    )}
                    <ComponentDescription
                        text={text || "Başlık"}
                        node={this.props.schemaNode}
                        entry={this.props.entry}
                    />
                </div>
            </React.Fragment>
        );
    }
}


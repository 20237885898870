import { Component } from 'react'
import { Dropdown } from 'office-ui-fabric-react'
import FormAccordion from '../FormAccordion'

export default class ArchiveBar extends Component {

	render() {
		return (
			<div className="bar-category">
				<FormAccordion title="Arşiv Listesi">
					<div className="content">
						<Dropdown
							className="custom-dropdown"
							calloutProps={{ className: "custom-dropdown-callout" }}
							selectedKey="1"
							options={[
								{
									key: "1",
									text: "Arşiv 1"
								},
								{
									key: "2",
									text: "Arşiv 2"
								},
								{
									key: "3",
									text: "Arşiv 3"
								}
							]}
						/>
					</div>
				</FormAccordion>
			</div>
		)
	}
}

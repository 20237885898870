import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import {
    EntryOptions,
    PropertyDefinition,
} from "app/library/layout-builder";
import { CardProps } from "./types";
import { MetaTypeEnum } from "app/shared";
import { CardPropertyDefinitions } from 'components/cms/definitions';

export class CardDefinitions extends CmsReactComponent<CardProps> {
    static defaultProps = {};

    static schemaName = "card";

    static propertyDefinitions: PropertyDefinition[] = CardPropertyDefinitions;

    static options: EntryOptions = {
        meta: {
            use: true,
            allowedTypes: [
                MetaTypeEnum.Text,
                MetaTypeEnum.TextArea,
                MetaTypeEnum.SingleImage,
                MetaTypeEnum.Editor,
            ],
        },
    };
}

import { IRoute, RouteKey } from "./shared";

// Pages
import Home from 'pages/Home';
import { ListPost, CreatePost, EditPost } from 'pages/Post';
import { CreateContentType, EditContentType, ListContentType } from 'pages/ContentType';
import { CreateMenu, EditMenu, ListMenu } from 'pages/Menu';
import { ListRole } from 'pages/Role';
import ThemeSettings from 'pages/ThemeSettings';
import { Login, Verify, ClarificationText, UndertakingText, CookiePolicy } from "pages/Identity";
import { ListCategory } from 'pages/Category';
import { ListLayout, CreateLayout, EditLayout } from 'pages/Layout';
import { ListUser } from 'pages/User';

// Modals
import { PropertiesModal, CategoryModal, BucketModal, UserModal, RoleModal } from 'components/customs/Modals';
import LocalDataWizardModal from 'components/customs/LocalDataWizard/LocalDataWizardModal';
import CustomServiceWizardModal from 'components/customs/CustomServiceWizard/CustomServiceWizardModal';

export const ROUTES: IRoute[] = [{
    exact: true,
    path: "/",
    component: Home
},
{
    exact: true,
    path: "/categories/:contentTypeId",
    component: ListCategory,
    role: RouteKey.ContentTypes
},
{
    exact: true,
    path: "/posts/:contentTypeId",
    component: ListPost,
    role: RouteKey.ContentTypes
},
{
    exact: true,
    path: "/post/:contentTypeId/create",
    component: CreatePost,
    role: RouteKey.ContentTypes
},
{
    exact: true,
    path: "/post/:contentTypeId/edit",
    component: EditPost,
    role: RouteKey.ContentTypes
},
{
    exact: true,
    path: "/content-types",
    component: ListContentType,
    role: RouteKey.ContentTypeManagement
},
{
    exact: true,
    path: "/content-type/create",
    component: CreateContentType,
    role: RouteKey.ContentTypeManagement
},
{
    exact: true,
    path: "/content-type/edit/:contentTypeId",
    component: EditContentType,
    role: RouteKey.ContentTypeManagement
},
{
    exact: true,
    path: "/layouts",
    component: ListLayout,
    role: RouteKey.Layout
},
{
    exact: true,
    path: "/layout/create",
    component: CreateLayout,
    role: RouteKey.Layout
},
{
    exact: true,
    path: "/layout/edit/:layoutId",
    component: EditLayout,
    role: RouteKey.Layout
},
{
    exact: true,
    path: "/menus",
    component: ListMenu,
    role: RouteKey.Menu
},
{
    exact: true,
    path: "/menu/create",
    component: CreateMenu,
    role: RouteKey.Menu
},
{
    exact: true,
    path: "/menu/edit/:menuId",
    component: EditMenu,
    role: RouteKey.Menu
},
{
    exact: true,
    path: "/roles",
    component: ListRole,
    role: RouteKey.Roles
},
{
    exact: true,
    path: "/user",
    component: ListUser,
    role: RouteKey.Users
},
{
    exact: true,
    path: "/theme-settings",
    component: ThemeSettings,
    role: RouteKey.ThemeSettings
},
{
    exact: true,
    path: "/login",
    component: Login,
    public: true
},
{
    exact: true,
    path: "/verify",
    component: Verify,
    public: true
},
{
    exact: true,
    path: "/clarification-text",
    component: ClarificationText,
    public: true
},
{
    exact: true,
    path: "/cookie-policy",
    component: CookiePolicy,
    public: true
},
{
    exact: true,
    path: "/undertaking-text",
    component: UndertakingText,
    public: true
},
{
    exact: false,
    path: "/",
    component: PropertiesModal
},
{
    exact: false,
    path: "/",
    component: PropertiesModal
},
{
    exact: false,
    path: "/",
    component: CategoryModal
},
{
    exact: false,
    path: "/",
    component: UserModal
},
{
    exact: false,
    path: "/",
    component: CustomServiceWizardModal
},
{
    exact: false,
    path: "/",
    component: LocalDataWizardModal
},
{
    exact: false,
    path: "/",
    component: BucketModal
},
{
    exact: false,
    path: "/",
    component: RoleModal
}];

import ComponentDescription from "components/customs/ComponentDescription";
import ComponentHeader from "components/cms/ComponentHeader";
import { SupporterListDefinitions } from "./SupporterListDefinitions";

export class SupporterList extends SupporterListDefinitions {
  render() {
    const { designing } = this.props;

    const style = designing
      ? {
        minHeight: "100px",
        padding: "0px",
      }
      : {
        minHeight: "auto",
        padding: "10px",
      };

    return (
      <div
        data-schema-id={this.props.id}
        className={`fa-draggable fa-CardList ${designing ? "designing" : ""}`}
        style={{ ...style, position: "relative" }}
      >
        {designing && (
          <ComponentHeader
            componentEntry={this.props.entry}
            componentSchemaId={this.props.id as string}
          />
        )}
        <ComponentDescription
          node={this.props.schemaNode}
          entry={this.props.entry}
        />
      </div>
    );
  }
}

import { MenuProperty, RouteKey } from "../enums";
import { IMenuItem } from "../models";

export const MENU_ITEMS: IMenuItem[] = [
    {
        key: RouteKey.Home,
        icon: "fas fa-home",
        url: "/",
        title: "Anasayfa",
        text: "Yönetilebilecek tüm alanları sol menüde görüntüleyebilirsiniz. Aşağıdan da hızlı işlemlere göz atabilirsiniz."
    },
    {
        key: RouteKey.ContentTypeManagement,
        icon: "fab fa-accusoft",
        url: "/content-types",
        title: "İçerik Tipi Yönetimi",
        text:
            "Dinamik olarak yönetebileceğiniz içerik tiplerini görüntüleyebilirsiniz.",
        properties: [MenuProperty.Frequently]
    },
    {
        key: RouteKey.ContentTypes,
        icon: "fas fa-mask",
        url: "",
        title: "İçerik Tipleri",
        text: "Eklenilen içerik tiplerini aşağıdan yönetin.",
        properties: [MenuProperty.ContentType],
        children: []
    },
    {
        key: RouteKey.Layout,
        icon: "fas fa-columns",
        url: "/layouts",
        title: "Layout Yönetimi",
        text:
            "İçerik'lerin sahip olduğu varsayılan tasarım yerleşimini ayarlayabilirsiniz. ",
        properties: [MenuProperty.Frequently]
    },
    {
        key: RouteKey.Menu,
        icon: "fas fa-compass",
        url: "/menus",
        title: "Menü Yönetimi",
        text:
            "İçerik'lerin sahip olduğu varsayılan tasarım yerleşimini ayarlayabilirsiniz. ",
        properties: [MenuProperty.Frequently]
    },
    {
        key: RouteKey.Users,
        icon: "fas fa-users",
        url: "/user",
        title: "Kullanıcı Yönetimi",
    },
    {
        key: RouteKey.Roles,
        icon: "fas fa-user-shield",
        url: "/roles",
        title: "Rol Yönetimi",
    },
    {
        key: "permission",
        icon: "fab fa-affiliatetheme",
        url: "/permission",
        title: "Rol Yönetimi",
        properties: [MenuProperty.Frequently],
    },
    {
        key: RouteKey.ThemeSettings,
        icon: "fab fa-affiliatetheme",
        url: "/theme-settings",
        title: "Tema Ayarları",
        text:
            "Geçerli uygulamanın tüm tema ayarlarını ve görsel özelliklerini yönetebilirsiniz.",
        properties: [MenuProperty.Frequently],
    },
    {
        key: RouteKey.FileManagement,
        icon: "fas fa-cloud",
        url: "",
        title: "Dosya Yönetimi",
        text: "",
        properties: [MenuProperty.FileManager]
    }
]
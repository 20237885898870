import React from 'react'
import { CmsReactComponent } from 'components/cms/CmsReactComponent'

import { PopupProps } from './types'
import ComponentHeader from 'components/cms/ComponentHeader';
import Droppable from 'components/customs/Designer/Droppable';
import ComponentDescription from 'components/customs/ComponentDescription';

export default class SectionDesigner extends CmsReactComponent<PopupProps> {

    renderInformation() {
        if ((this.props.children && this.props.children.length > 0) || !this.props.defaultElement) {
            return null;
        }
        return (
            <ComponentDescription
                node={this.props.schemaNode}
                entry={this.props.entry}
            />
        )
    }

        render() {
            return (
                <React.Fragment>
                    {this.renderInformation()}
                    <ComponentHeader
                        componentEntry={this.props.entry}
                        key={this.props.id}
                        componentSchemaId={this.props.id as string}
                        componentIsColumn={!this.props.defaultElement}
                        componentIsDefault={this.props.defaultElement}
                    />
                    <Droppable
                        elementId={this.props.id as string}
                        elementType="column"
                        dragDropContext={(this.props as any).dragDropContext}
                        style={{ alignContent: "baseline", paddingBottom: "50px" }}
                        className="fa-droppable-act row">
                        {this.props.children}
                    </Droppable>
                </React.Fragment>
            )
        }
}

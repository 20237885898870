import { Component } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField, Toggle } from 'office-ui-fabric-react';
import { IMenuItem } from 'app/services/menu';
import IconPanel from '../IconPanel';

interface MenuItemModalProps {
    show: boolean;
    data: IMenuItem;
    onSave: () => void;
    onDelete: () => void;
    onChange: (key: string, value: any) => void;
    onDismiss: () => void;
}

interface MenuItemModalState {
    showModal: boolean;
}

export default class MenuItemModal extends Component<MenuItemModalProps, MenuItemModalState> {

    state = {
        showModal: false
    };

    renderIconManager() {
        return (
            <IconPanel
                show={this.state.showModal}
                onDismiss={() => this.setState({ showModal: false })}
                onSave={(icon) => {
                    this.props.onChange("icon", icon);
                }}
            />
        );
    }

    renderForm() {
        return (
            <div className="modal-form">
                <div className="item">
                    <TextField
                        placeholder="Başlık"
                        value={this.props.data.title || ""}
                        label="Başlık"
                        className="custom-textfield border"
                        onChange={(event: any) => {
                            this.props.onChange("title", event.target.value)
                        }}
                    />
                </div>
                <div className="item">
                    <TextField
                        placeholder="URL"
                        value={this.props.data.url || ""}
                        label="URL"
                        className="custom-textfield border"
                        onChange={(event: any) => {
                            this.props.onChange("url", event.target.value)
                        }}
                    />
                </div>
                <div className="item">
                    <TextField
                        placeholder="Sınıf Adı (className)"
                        value={this.props.data.className || ""}
                        label="Sınıf Adı"
                        className="custom-textfield border"
                        onChange={(event: any) => {
                            this.props.onChange("className", event.target.value)
                        }}
                    />
                </div>
                <div className="item">
                    <div style={{ marginTop: 7.5, marginBottom: 5 }} className="ms-Label">İkon</div>
                    <div style={{ marginLeft: "auto" }} className="property-alignment row">
                        {this.props.data.icon && (
                            <i style={{ background: "rgb(51 146 110)", padding: "8px", color: "#fff" }} className={this.props.data.icon}></i>
                        )}
                        <TextField
                            placeholder="İkon"
                            value={this.props.data.icon || ""}
                            className="custom-textfield border col no-padding"
                            onChange={(event: any) => {
                                this.props.onChange("icon", event.target.value)
                            }}
                        />
                        {this.renderIconManager()}
                        <DefaultButton
                            className="custom-button"
                            text="İkon Seç"
                            onClick={() => {
                                this.setState({
                                    showModal: true
                                });
                            }}
                            styles={{
                                root: {
                                    height: "30px !important"
                                }
                            }}
                        />
                    </div>
                </div>
                <div style={{ marginLeft: "-5px" }} className="item">
                    <Toggle
                        checked={this.props.data.isBlank || false}
                        label="Yeni Sekmede Aç"
                        className="custom-toggle border"
                        inlineLabel
                        onChange={(event: any, checked) => {
                            this.props.onChange("isBlank", checked)
                        }}
                    />
                </div>
            </div>
        )
    }

    render() {
        if (!this.props.show) return null;
        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Menü Öğesi Düzenle",
                    closeButtonAriaLabel: 'Kapat',
                    subText: "Geçerli menü öğesinin değerlerini aşağıdan güncelleyebilirsiniz.",
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: false,
                    styles: { main: { maxWidth: "1000px !important" } }
                }}
            >
                {this.renderForm()}
                <DialogFooter>
                    <span onClick={() => {
                        this.props.onDelete();
                    }}
                        className="remove"
                    >
                        Öğe'yi Sil
                    </span>
                    <DefaultButton onClick={() => this.props.onDismiss()} text="İptal" />
                    <PrimaryButton onClick={() => this.props.onSave()}
                        text="Güncelle"
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}

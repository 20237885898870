import React from 'react';

class CookiePolicy extends React.Component {
  render() {
    return (
      <div className="page" style={{ margin: '0 auto', maxWidth: '1100px', minHeight: '100vh', background: "#fff" }}>
        <div className="auth-page-header">
          <div className="auth-page-brand">ÇEREZ POLİTİKASI</div>
        </div>
        <style>{`
          p {
            font-size: 13.5px;
            line-height: 1.4;
          }
        `}</style>
        <br />
        <p>USHAŞ Uluslararası Sağlık Hizmetleri Anonim Şirketi (&ldquo;USHAŞ&rdquo;) olarak, www.healthturkey.com.tr web sitemizden en verimli şekilde faydalanabilmeniz ve en iyi deneyimi yaşamanız amacıyla &ccedil;erez kullanmaktayız.</p>
        <p>Web sitemizdeki &ccedil;erez kullanımı en başta 6698 Sayılı Kişisel Verilerin Korunması Kanunu (KVKK) olmak &uuml;zere tabi olduğumuz yasal mevzuata uygun şekilde ger&ccedil;ekleştirilmektedir. İşbu &ccedil;erez politikası ile web sitemizde kullanılan &ccedil;erezlerin t&uuml;rleri, kullanım ama&ccedil;ları ile bu &ccedil;erezlerin y&ouml;netilmesi ile ilgili tarafınızı bilgilendirmek ve haklarınızı hatırlatmak isteriz.</p>
        <h3><strong> </strong><strong>&Ccedil;EREZ NEDİR?</strong></h3>
        <p>&Ccedil;erezler, tarayıcınız aracılığı ile internet ağ sunucusu tarafından bilgisayarınıza yerleştirilen k&uuml;&ccedil;&uuml;k veri dosyalarıdır. Tarayıcınız ile sunucu arasında bir bağlantı sağlandığında site, &ccedil;erezler aracılığıyla sizi tanır. &Ccedil;erezlerin kullanılma amacı, internet sitesini ziyaret eden kullanıcıya kolaylık sağlamaktır.</p>
        <h3><strong> </strong><strong>&Ccedil;EREZLER ARACILIĞI İLE İŞLENEN KİŞİSEL VERİLERİNİZ NELERDİR?</strong></h3>
        <p>&Ccedil;erezler vasıtasıyla web sitemiz &uuml;zerindeki kullanım ve ziyaret alışkanlıklarınız, oturum bilgileriniz, ge&ccedil;miş işlemleriniz ve gezinme bilgileriniz işlenmektedir.</p>
        <p>USHAŞ tarafından kullanılan &ccedil;erezler bilgisayarınıza zarar vermez ve vir&uuml;s i&ccedil;ermez.</p>
        <h3><strong> </strong><strong>&Ccedil;EREZ T&Uuml;RLERİ</strong></h3>
        <p>&Ccedil;erezler farklı sınıflandırma &ccedil;eşitleri uyarınca, farklı t&uuml;rlerde g&ouml;r&uuml;nebilirler.</p>
        <p><strong>Oturum &Ccedil;erezleri:</strong> Oturum &Ccedil;erezleri, internet sitesini kullanımınız sırasında ge&ccedil;erli olan &ccedil;erezlerdir ve bu &ccedil;erezler web tarayıcı kapatılıncaya kadar ge&ccedil;erliliklerini korurlar.</p>
        <p><strong>Zorunlu &Ccedil;erezler:</strong> Zorunlu &Ccedil;erezler; İnternet sitesinin d&uuml;zg&uuml;n bir şekilde &ccedil;alışabilmesi, kullanıcıların sitede gezinme ve site &ouml;zelliklerinden yararlanması i&ccedil;in kullanımı mecburi olan &ccedil;erezlerdir. Zorunlu &ccedil;erezler anonim niteliktedirler.</p>
        <p><strong>Kalıcı &Ccedil;erezler:</strong> Kalıcı &Ccedil;erezler, tarayıcınızda saklanan ve tarafınızdan silininceye dek veya son kullanım tarihine kadar ge&ccedil;erliliğini koruyan &ccedil;erezlerdir.</p>
        <p><strong>İşlevsel ve Analitik &Ccedil;erezler:</strong>&nbsp; İşlevsel ve Analitik &Ccedil;erezler, Tercihlerinizin hatırlanması, internet sitesinin etkin şekilde kullanılması, sitenin isteklerinize cevap verecek şekilde optimize edilmesi vb. ama&ccedil;larla kullanılan ve siteyi nasıl kullandığınız hakkında verileri i&ccedil;eren &ccedil;erezlerdir. Nitelikleri gereği bu t&uuml;rdeki &ccedil;erezler kullanıcı adı ve benzeri kişisel verilerinizi i&ccedil;erebilir.</p>
        <p><strong>Ticari &Ccedil;erezler:</strong> Ticari &Ccedil;erezler; ilgi alanlarınız ve se&ccedil;imleriniz doğrultusunda hedeflediğiniz &uuml;r&uuml;n/i&ccedil;eriğe benzer nitelikli olanların sunulması ve daha gelişmiş, kişiselleştirilmiş bir reklam portf&ouml;y&uuml; sunarak kullanım deneyiminizi artırmaya yarar.</p>
        <p><strong>&Uuml;&ccedil;&uuml;nc&uuml; Taraf &Ccedil;erezler:</strong> &Uuml;&ccedil;&uuml;nc&uuml; taraf hizmet sağlayıcılar size &ouml;zel reklamları sunabilmek i&ccedil;in kendi &ccedil;erezlerini yerleştirmektedirler. &Uuml;&ccedil;&uuml;nc&uuml; taraf tarafından yerleştirilen &ccedil;erezler internet sitelerinde ziyaret&ccedil;ilerin gezinme bilgilerini toplar, işler ve nasıl kullandıklarını analiz eder.</p>
        <h3><strong> </strong><strong>WEB SİTEMİZDE KULLANILAN &Ccedil;EREZLERE İLİŞKİN BİLGİLER</strong></h3>
        <p>USHAŞ tarafından kullanılan &ccedil;erezler, ilgili kişinin temel hak ve &ouml;zg&uuml;rl&uuml;klerine zarar vermemek kaydıyla, KVKK&rsquo;nın 5. Maddesi kapsamında işlenmektedir.</p>
        <p>&Ccedil;erezlerin kullanımına ilişkin bilgilendirme, <a href="http://www.healthturkey.com.tr" target="_blank" rel="noreferrer">www.healthturkey.com.tr</a> &nbsp;web sitesine yapılacak ilk ziyarette a&ccedil;ılır pencere ile verilecek olup, bu pencereyi kapattığınızda işbu &Ccedil;erez Politikası&rsquo;nı kabul etmiş sayılacaksınız.</p>
        <table width="0" style={{ width: "100%", borderCollapse: "collapse" }} className="basic">
          <tbody>
            <tr>
              <td>
                <p><strong>&Ccedil;erez Servis Sağlayıcı</strong></p>
              </td>
              <td>
                <p><strong>&Ccedil;erez İsmi</strong></p>
              </td>
              <td>
                <p><strong>&Ccedil;erez Kullanım Amacı</strong></p>
              </td>
              <td width="113">
                <p><strong>&Ccedil;erez Tipi</strong></p>
              </td>
              <td width="79">
                <p><strong>&Ccedil;erez S&uuml;resi</strong></p>
              </td>
            </tr>
            <tr>
              <td width="127">
                <p>STP</p>
              </td>
              <td width="147">
                <p>STPauthcookie</p>
              </td>
              <td width="195">
                <p>Bu &ccedil;erez; sisteme giriş yapan kullanıcının bilgilerini tutmak amacıyla kullanılmaktadır.</p>
              </td>
              <td width="113">
                <p>Session Cookies</p>
              </td>
              <td width="79">
                <p>20 dakika</p>
              </td>
            </tr>
            <tr>
              <td width="127">
                <p>STP</p>
              </td>
              <td width="147">
                <p>language</p>
              </td>
              <td width="195">
                <p>Bu &ccedil;erez; uygulamanın g&ouml;sterim dili bilisini tutmak amacıyla kullanılmaktadır.</p>
              </td>
              <td width="113">
                <p>Persistent Cookies</p>
              </td>
              <td width="79">
                <p>S&uuml;resiz</p>
              </td>
            </tr>
            <tr>
              <td width="127">
                <p>STP</p>
              </td>
              <td width="147">
                <p>currency</p>
              </td>
              <td width="195">
                <p>Bu &ccedil;erez uygulamanın se&ccedil;ilen para birimi bilgisini tutmak i&ccedil;in kullanılmaktadır.</p>
              </td>
              <td width="113">
                <p>Persistent Cookies</p>
              </td>
              <td width="79">
                <p>S&uuml;resiz</p>
              </td>
            </tr>
            <tr>
              <td width="127">
                <p>STP</p>
              </td>
              <td width="147">
                <p>UpCookieKurulusCounter</p>
              </td>
              <td width="195">
                <p>Bu &ccedil;erez; sistem &uuml;zerinde g&ouml;sterilen kuruluşların g&uuml;nl&uuml;k g&ouml;sterim sayılarının hesaplanması amacıyla kullanılmaktadır.</p>
              </td>
              <td width="113">
                <p>Persistent Cookies</p>
              </td>
              <td width="79">
                <p>S&uuml;resiz</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <h3><strong> </strong><strong>&Ccedil;EREZ Y&Ouml;NETİMİ, ETKİN VE DEVRE DIŞI BIRAKMA</strong></h3>
        <p>Tarayıcınız aracılığıyla &ccedil;erezleri engellemeniz m&uuml;mk&uuml;n ise de bu durumda web sitemizdeki bazı &ouml;zellikleri kullanamayabilir ve web sitemiz tam olarak işlevini g&ouml;steremeyecektir. &nbsp;Tarayıcınızın &ccedil;erezleri y&ouml;netmenize olanak sağlayıp sağlamadığını &ouml;ğrenmek tarayıcınıza g&ouml;re aşağıdaki adımların takip edilmesi gerekmektedir.</p>
        <ul>
          <li><strong>Internet Explorer</strong></li>
        </ul>
        <ol>
          <li>Masa&uuml;st&uuml;n&uuml; a&ccedil;ın ve g&ouml;rev &ccedil;ubuğunda Internet Explorer simgesine dokunun veya tıklayın.</li>
          <li>Ara&ccedil;lar d&uuml;ğmesine ve İnternet se&ccedil;enekleri'ne dokunun veya tıklayın.</li>
        </ol>
        <p>3.Gizlilik sekmesine dokunun veya tıklayın, ardından t&uuml;m tanımlama bilgilerini engellemek i&ccedil;in Ayarlar'ın altında bulunan kaydırıcıyı yukarıya hareket ettirin ve Tamam d&uuml;ğmesine dokunun veya tıklayın.</p>
        <ul>
          <li><strong>Microsoft Edge</strong></li>
        </ul>
        <ol>
          <li>Microsoft Edge tarayıcınızın sağ &uuml;st k&ouml;şesinden &uuml;&ccedil; nokta işareti olan b&ouml;l&uuml;me tıklayın ve Ayarlar b&ouml;l&uuml;m&uuml;ne gelin.</li>
        </ol>
        <p>2.Karşınıza gelen yeni pencereden Temizlenecek &Ouml;ğeleri Se&ccedil; dedikten sonra karşınıza gelen pencereden temizlemek istediğiniz b&ouml;l&uuml;mleri se&ccedil;in.</p>
        <p>3.Burada bir&ccedil;ok b&ouml;l&uuml;m bulunmaktadır. İstediğinizi se&ccedil;erek temizleme işlemine başlayabilirsiniz.</p>
        <ul>
          <li><strong>Google Chrome</strong></li>
        </ul>
        <ol>
          <li>Bilgisayarınızda Chrome'u a&ccedil;ın.</li>
          <li>Sağ &uuml;stte Diğer Ayarlar'ı tıklayın.</li>
          <li>Altta Gelişmiş'i tıklayın.</li>
          <li>"Gizlilik ve g&uuml;venlik"in altında İ&ccedil;erik ayarları'nı tıklayın.</li>
          <li>&Ccedil;erezler'i tıklayın.</li>
          <li>"T&uuml;m &ccedil;erezler ve site verileri"nin altında Web Sitesi'nin adını arayın.</li>
          <li>Sitenin sağındaki Kaldır simgesini tıklayın</li>
        </ol>
        <ul>
          <li><strong>Mozilla Firefox</strong></li>
        </ul>
        <ol>
          <li>Firefox Men&uuml; d&uuml;ğmesine tıklayın ve Se&ccedil;enekler'i se&ccedil;in.</li>
          <li>Gizlilik ve G&uuml;venlik panelini se&ccedil;in ve Ge&ccedil;miş b&ouml;l&uuml;m&uuml;ne gidin.</li>
          <li>Firefox ayarını ge&ccedil;miş i&ccedil;in &ouml;zel ayarları kullansın olarak değiştirin.</li>
          <li>&Ccedil;erezleri g&ouml;ster... d&uuml;ğmesine tıklayın. &Ccedil;erezler penceresi g&ouml;r&uuml;necektir.</li>
          <li>Arama: alanına, &ccedil;erezlerini silmek istediğiniz sitenin adını yazın. Aramanızla eşleşen &ccedil;erezler g&ouml;r&uuml;nt&uuml;lenecektir.</li>
          <li>Silmek istediğiniz &ccedil;erez(ler)i se&ccedil;ip Se&ccedil;ilenleri sil d&uuml;ğmesine tıklayın.</li>
          <li>Kapat d&uuml;ğmesine tıklayarak &Ccedil;erezler penceresini kapatın. Sonra da about:preferences sayfasını kapatın</li>
        </ol>
        <ul>
          <li><strong>Safari</strong></li>
        </ul>
        <ol>
          <li>Safari &gt; Tercihler'i se&ccedil;in.</li>
          <li>Gizlilik &ouml;ğesini tıklayın.</li>
          <li>Web Sitesi Verilerini tıklayın.</li>
          <li>Bir veya daha fazla web sitesi se&ccedil;in ve sonra Sil veya T&uuml;m&uuml;n&uuml; Sil'e tıklayın.</li>
        </ol>
        <p>&nbsp;</p>
        <h3><strong> </strong><strong>DEĞİŞİKLİK </strong></h3>
        <p>USHAŞ&rsquo; ın işbu Politika&rsquo;da kullanıcılarına veya internet sitesi ziyaret&ccedil;ilerine bildirmeksizin değişiklik yapma hakkı saklıdır</p>
        <h3><strong> </strong><strong>İLETİŞİM </strong></h3>
        <p>&nbsp;Politika ile ilgili t&uuml;m soru ve g&ouml;r&uuml;şlerinizi bize iletmek i&ccedil;in bizimle aşağıdaki adreste belirtilen iletişim yollarından birini kullanabilirsiniz.</p>
        <p>&nbsp;</p>
        <ul>
          <li>Yazılı ve ıslak imzalı olarak elden, postayla ya da noter kanalıyla Sağlık Bakanlığı Bilkent Yerleşkesi &Uuml;niversiteler Mah. Dumlupınar Bul. 6001. Cad. No: 9 Kat: 8 &Ccedil;ankaya 06800 Ankara, T&uuml;rkiye adresine ulaşabilir,</li>
          <li>ushas@hs01.kep.tr adresine g&uuml;venli elektronik ya da mobil imzalı olarak, kayıtlı elektronik posta adresi yolu ile ulaşabilirsiniz.</li>
        </ul>
        <p>&nbsp;</p>
      </div>
    );
  }
}

export default CookiePolicy;

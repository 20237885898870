import React from 'react'
import { ComboBox, Dialog, DialogType, Dropdown, DropdownMenuItemType, IDropdownOption } from 'office-ui-fabric-react';
import _ from "lodash";

import { GrouppedDataWizardProps } from './types';
import { MetaTypeEnum } from 'app/shared';

export default class GrouppedDataWizardModal extends React.Component<GrouppedDataWizardProps> {

    getContentTypeMetaFields(id: string) {
        return (this.props.contentTypes || []).find(x => x.id === id)?.metaFields || []
    }

    renderBindings() {
        const { value } = this.props;
        const filteredMetaList = this.getContentTypeMetaFields(value.contentTypeId).filter(x => x.type === MetaTypeEnum.Dropdown);
        let mappedMetaFields: IDropdownOption[] = [{ key: "meta", text: "Meta Alanları", itemType: DropdownMenuItemType.Header }];
        console.log("source", value);

        mappedMetaFields = [...mappedMetaFields, ...this.getContentTypeMetaFields(value.contentTypeId).map((item) => ({ key: "metaFields." + item.name, text: item.title }))];
        mappedMetaFields = [...mappedMetaFields, {
            key: "post",
            text: "İçerik Alanları",
            itemType: DropdownMenuItemType.Header
        }, {
            key: "post.title",
            text: "İçerik Başlığı",
            data: {
                group: "post"
            }
        },
        {
            key: "post.profileImage",
            text: "İçerik Görseli",
            data: {
                group: "post"
            }
        },
        {
            key: "post.profileImageMedium",
            text: "İçerik Görseli (Medium)",
            data: {
                group: "post"
            }
        },
        {
            key: "post.profileImageSmall",
            text: "İçerik Görseli (Small)",
            data: {
                group: "post"
            }
        },
        {
            key: "post.url",
            text: "İçerik URL",
            data: {
                group: "post"
            }
        }]
        return (
            <div className="groupped-wizard">
                <div className="property-item row">
                    <div title="Veri İşlemleri" className="property-text">
                        <div className="title">İşlem Yapılacak İçerik Tipi</div>
                        <div className="sub-title">
                            Bir içerik tip seçin.
                        </div>
                    </div>
                    <div className="col">
                        <Dropdown
                            className="custom-dropdown"
                            calloutProps={{ className: "custom-dropdown-callout" }}
                            options={(this.props.contentTypes || []).map((item) => ({ key: item.id as string, text: item.title }))}
                            onChange={(_, opt) => {
                                this.props.onChange({
                                    ...value,
                                    contentTypeId: opt?.key as string
                                })
                            }}
                            selectedKey={value.contentTypeId}
                        />
                    </div>
                </div>
                {!this.props.entry.options?.wizard?.single && (
                    <div className="property-item row">
                        <div title="Veri İşlemleri" className="property-text">
                            <div className="title">Kategorilerin Gruplanacağı Meta</div>
                            <div className="sub-title">
                                Kategorilerin gruplanacağı metayı seçiniz.
                    </div>
                        </div>
                        <div className="col">
                            <Dropdown
                                className="custom-dropdown"
                                calloutProps={{ className: "custom-dropdown-callout" }}
                                options={(filteredMetaList || []).map((item) => ({ key: item.name as string, text: item.title }))}
                                disabled={!filteredMetaList || filteredMetaList.length === 0}
                                onChange={(_, opt) => {
                                    this.props.onChange({
                                        ...value,
                                        meta: opt?.key as string
                                    })
                                }}
                                selectedKey={value?.meta || ""}
                            />
                        </div>
                    </div>
                )}
                <div className="property-item row">
                    <div title="Veri İşlemleri" className="property-text">
                        <div className="title">İçeriklerin Gruplanacağı Meta</div>
                        <div className="sub-title">
                            İçeriklerin gruplanacağı metayı seçiniz.
                        </div>
                    </div>
                    <div className="col">
                        <Dropdown
                            className="custom-dropdown"
                            calloutProps={{ className: "custom-dropdown-callout" }}
                            options={(filteredMetaList || []).map((item) => ({ key: item.name as string, text: item.title }))}
                            disabled={!filteredMetaList || filteredMetaList.length === 0}
                            onChange={(_, opt) => {
                                this.props.onChange({
                                    ...value,
                                    postMeta: opt?.key as string
                                })
                            }}
                            selectedKey={value?.postMeta || ""}
                        />
                    </div>
                </div>
                <div className="property-item row">
                    <div title="Veri İşlemleri" className="property-text">
                        <div className="title">Gözükecek Alanlar</div>
                        <div className="sub-title">
                            Veri gruplandıktan sonra, veriler üzerinde gözükecek alanları seçebilirsiniz.
                        </div>
                    </div>
                    <div className="col">
                        <ComboBox
                            className="custom-combobox"
                            multiSelect
                            allowFreeform
                            autoComplete="on"
                            calloutProps={{ className: "custom-combobox-callout" }}
                            options={mappedMetaFields}
                            onChange={(ev, opt) => {
                                const fields = _.cloneDeep(value.fields) || [];
                                if (opt?.selected) {
                                    fields.push(opt?.key as string)
                                }
                                else {
                                    const findIndex = fields.findIndex((x: string) => x === opt?.key);
                                    if (findIndex !== -1) {
                                        fields.splice(findIndex, 1)
                                    }
                                }
                                this.props.onChange({
                                    ...value,
                                    fields
                                })
                            }}
                            selectedKey={value.fields}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderModal() {
        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss ? this.props.onDismiss() : {}}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Gruplanmış Veri Sihirbazı",
                    closeButtonAriaLabel: "Kapat",
                    subText:
                        "İlgili verilerin gruplanmış ve dinamik olarak getirilmesini sağlayacağınız ayarları aşağıdan yapılandırabilirsiniz."
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: false,
                    styles: { main: { maxWidth: "1100px !important", minWidth: "900px !important" } },
                }}
            >
                {this.renderBindings()}
            </Dialog>
        )
    }

    render() {

        if (!this.props.show) return null;

        return this.renderModal()
    }
}

import { Component } from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";


export interface VerificationLoaderProps {
    show: boolean;
}

export default class VerificationLoader extends Component<VerificationLoaderProps> {

    static defaultProps = {
        show: false,
        label: "",
        className: ""
    };

    render() {
        const { show } = this.props;
        return (
            <div
                className={[show && "active", "loader verification-loader"].filter(e => !!e).join(" ")}>
                <Spinner
                    {...this.props}
                    size={SpinnerSize["large"]}
                    label="Kullanıcı doğrulanıyor, lütfen bekleyiniz.."
                />
            </div>
        );
    }
}


import { getEndpoint, getIdentityEndpoint } from 'app/utils/service';
import { HttpClient, IdentityClient } from 'app/library/client';
import { convertToQueryString, encodedParams } from 'app/shared/functions';
import { IdentityLoginRequest, IdentityLoginResponse, IdentityUserResponse, IVerifyRequest, RoleEntity, IPermissionSpecialPermissionResponse, RolePermissionEntity, UserSignInResponseEntity } from './types';
import { IListFilter, ResponseBase, ResponseIdentityListBase, ResponseInventoryContentBase } from 'app/shared';
import { api } from 'app/utils/api';

export class IdentityService {

    /** It sends request to user login service. */
    async connectToken(request: IdentityLoginRequest): Promise<ResponseInventoryContentBase<IdentityLoginResponse>> {
        return IdentityClient.getEncodedClient().post(getIdentityEndpoint("connect/token"), encodedParams(request));
    }

    /** It sends request to user login service. */
    async signIn(request: IdentityLoginRequest): Promise<ResponseBase<UserSignInResponseEntity>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.auth.sign), request);
    }

    /** It check user token for authentication. */
    async checkToken(): Promise<ResponseBase<boolean>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.auth.checkToken));
    }

    async sendVerification(request: IVerifyRequest): Promise<ResponseBase<ResponseInventoryContentBase<object>>> {
        return IdentityClient.getClient().post(getEndpoint("application-establishment-user/send-verification"), request);
    }

    /** user info */
    async getUserInfo(): Promise<IdentityUserResponse> {
        return IdentityClient.getClient().get(getEndpoint("/connect/userinfo"));
    }

    /** role list */
    async getRoles(options: IListFilter): Promise<ResponseIdentityListBase<RoleEntity[]>> {
        return IdentityClient.getClient().get(getIdentityEndpoint(`role?${convertToQueryString(options)}`));
    }

    /** role getbyid */
    async getRoleById(roleId: number): Promise<ResponseInventoryContentBase<RoleEntity>> {
        return IdentityClient.getClient().get(getIdentityEndpoint(`role/getbyid?id=${roleId}`));
    }

    /** role create */
    async createRole(request: RoleEntity): Promise<ResponseInventoryContentBase<boolean>> {
        return IdentityClient.getClient().post(getIdentityEndpoint(`role`), request, false);
    }

    /** update role */
    async updateRole(request: RoleEntity): Promise<ResponseInventoryContentBase<boolean>> {
        return IdentityClient.getClient().put(getIdentityEndpoint(`role`), request);
    }

    /** rolepermission list */
    async getRolePermissionList(roleId: number): Promise<IPermissionSpecialPermissionResponse> {
        return IdentityClient.getClient().get(getIdentityEndpoint(`rolepermission?roleId=${roleId}`));
    }

    /** rolepermission */
    async updateRolePermission(request: RolePermissionEntity): Promise<ResponseInventoryContentBase<boolean>> {
        return IdentityClient.getClient().post(getIdentityEndpoint(`rolepermission`), request, false);
    }

    /** It deletes spesific role by id */
    async deleteRole(roleId: number): Promise<ResponseInventoryContentBase<boolean>> {
        return IdentityClient.getClient().delete(getIdentityEndpoint(`role/${roleId}`))
    }

}

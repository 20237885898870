import * as React from "react";
import { BaseFieldProps } from "./types";
import { Icon } from "office-ui-fabric-react";
import { SchemaNode, PropertyMappingType, PropertyDefinition } from "app/library/layout-builder";
import { StringField, VideoField, ImageField, IconField, ToggleField, ColorField, AlignField, DropdownField } from '.';
//@ts-ignore
import { ReactSortable } from "react-sortablejs";
import ParentObjectField from './ParentObjectField';
import LinkField from './LinkField';
import HTMLField from './HTMLField';
import SrcSetField from './SrcSetField';
import { PureProductField } from './Pure/PureProductField';
import { toggleModal } from 'app/redux/modal/actions';
import FileField from "./FileField";

export interface FormItemsFieldProps {
    node: SchemaNode;
    toggleModal: typeof toggleModal;
}

// Kendisine gelen Property ismine ve değerine göre Bazı Form Item Nesnelerini render eder.
export default class FormItemsField extends React.Component<
    FormItemsFieldProps & BaseFieldProps
> {

    onChangeProperty(key: string, value: any, index: number) {
        this.props.value[index][key] = value;
        this.props.onChange(this.props.value);
    }

    renderField(
        prop: PropertyDefinition,
        item: object,
        node: SchemaNode,
        index: number,
        innerPropKey: number
    ) {
        switch (prop.mapping.type) {
            case PropertyMappingType.String:
            case PropertyMappingType.Number:
            case PropertyMappingType.Any:
            case PropertyMappingType.Textarea:
                return (
                    <StringField
                        property={prop}
                        value={item[prop.name]}
                        multiline={prop.mapping.type === PropertyMappingType.Textarea}
                        key={innerPropKey}
                        node={node}
                        index={innerPropKey}
                        schema={this.props.schema}
                        onChange={value => this.onChangeProperty(prop.name, value, index)}
                        type={prop.mapping.type === PropertyMappingType.Number ? "number" : "text"}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Video:
                return (
                    <VideoField
                        property={prop}
                        node={node}
                        value={item[prop.name]}
                        key={innerPropKey}
                        index={innerPropKey}
                        schema={this.props.schema}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Image:
                return (
                    <ImageField
                        property={prop}
                        value={item[prop.name]}
                        node={node}
                        key={innerPropKey}
                        index={innerPropKey}
                        schema={this.props.schema}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.File:
                return (
                    <FileField
                        property={prop}
                        value={item[prop.name]}
                        node={node}
                        key={innerPropKey}
                        index={innerPropKey}
                        schema={this.props.schema}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Icon:
                return (
                    <IconField
                        property={prop}
                        value={item[prop.name]}
                        node={node}
                        index={innerPropKey}
                        schema={this.props.schema}
                        key={innerPropKey}
                        onChange={value => this.onChangeProperty(prop.name, value, index)}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Boolean:
                return (
                    <ToggleField
                        property={prop}
                        value={item[prop.name]}
                        node={node}
                        index={innerPropKey}
                        key={innerPropKey}
                        schema={this.props.schema}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Color:
                return (
                    <ColorField
                        property={prop}
                        node={node}
                        value={item[prop.name]}
                        key={innerPropKey}
                        index={innerPropKey}
                        schema={this.props.schema}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.LinkWizard:
                return (
                    <LinkField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Html:
                return (
                    <HTMLField
                        property={prop}
                        value={item[prop.name]}
                        index={innerPropKey}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={this.props.node}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Alignment: {
                return (
                    <AlignField
                        property={prop}
                        value={item[prop.name]}
                        node={node}
                        key={innerPropKey}
                        index={innerPropKey}
                        schema={this.props.schema}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        language={this.props.language}

                    />
                );
            }
            case PropertyMappingType.Object: {
                return (
                    <DropdownField
                        property={prop}
                        value={item[prop.name]}
                        node={node}
                        key={innerPropKey}
                        index={innerPropKey}
                        schema={this.props.schema}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        language={this.props.language}

                    />
                );
            }
            case PropertyMappingType.ParentObject:
                return (
                    <ParentObjectField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={node}
                        index={innerPropKey}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.SrcSet:
                return (
                    <SrcSetField
                        property={prop}
                        value={item[prop.name]}
                        key={innerPropKey}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                        schema={this.props.schema}
                        node={node}
                        index={innerPropKey}
                        language={this.props.language}

                    />
                );
            case PropertyMappingType.Product:
                return (
                    <PureProductField
                        value={item[prop.name]}
                        onToggleProductPicker={this.props.toggleModal}
                        onChange={value =>
                            this.onChangeProperty(prop.name, value, index)
                        }
                    />
                );
            default:
                return null;
        }
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        return (
            <div className="external-items single-line">
                <ReactSortable
                    list={this.props.value || []}
                    setList={(newValue: any[]) => this.props.onChange(newValue)}
                    animation={150}
                >
                    {(this.props.value || []).map((item: object, index: number) => {
                        return (
                            <div className="external-item row" key={index}>
                                {this.props.property?.mapping?.keys?.map((innerProp, innerPropIndex) => {
                                    if (innerProp.mapping.type === PropertyMappingType.Custom)
                                        return innerProp?.mapping.fieldRenderer && innerProp.mapping.fieldRenderer({ fieldProps: this.props, item, property: innerProp, index })
                                    return (
                                        this.renderField(
                                            innerProp,
                                            item,
                                            this.props.node,
                                            index,
                                            innerPropIndex
                                        )
                                    );
                                })}
                                <span
                                    className="remove"
                                    style={{
                                        marginTop: 0
                                    }}
                                    onClick={() => {
                                        this.props.value.splice(index, 1);
                                        this.props.onChange(this.props.value);
                                    }}
                                >
                                    <Icon iconName="ChromeClose" />
                                    <div className="text">Kaldır</div>
                                </span>
                            </div>
                        );
                    })}
                </ReactSortable>
            </div>
        );
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { HeaderProps } from "./types";
import {
  PropertyDefinition, PropertyMappingType,
} from "app/library/layout-builder";
import { getMenuDefinitions, ImageChildrenDefinitions } from 'components/cms/definitions';

export class HeaderDefinitions extends CmsReactComponent<HeaderProps> {
  static defaultProps = {};

  static schemaName = "header";
  static schemaType = "navigation-component";

  static propertyDefinitions: PropertyDefinition[] = [
    {
      name: "contentTypeIds",
      text: "Aranacak İçerik Tipleri",
      mapping: {
        type: PropertyMappingType.ContentTypes
      }
    },
    {
      name: 'potrtlogo',
      text: 'Cumhurbaşkanlığı logo',
      mapping: {
        type: PropertyMappingType.ParentObject,
        children: ImageChildrenDefinitions
      },
    },
    {
      name: 'potrtlogoApp',
      text: 'Aplikasyon İçin Eklenen Cumhurbaşkanlığı logo',
      mapping: {
        type: PropertyMappingType.ParentObject,
        children: ImageChildrenDefinitions
      },
    },
    {
      name: 'ifmlogo',
      text: 'İstanbul Fuar Merkezi logo',
      mapping: {
        type: PropertyMappingType.ParentObject,
        children: ImageChildrenDefinitions
      },
    },
    ...getMenuDefinitions("expoNav", "Expo"),
    ...getMenuDefinitions("exhibitorNav", "Exhibitor"),
    ...getMenuDefinitions("visitorNav", "Visitor"),
    ...getMenuDefinitions("featuresNav", "Features"),
    ...getMenuDefinitions("sponsorshipNav", "Sponsorship"),
    ...getMenuDefinitions("galleryNav", "Gallery"),
    ...getMenuDefinitions("pressReleaseNav", "Press Release"),
    ...getMenuDefinitions("contactNav", "Contact"),
  ];
}

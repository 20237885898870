import { Component } from 'react'
import { Dialog, DialogType, Dropdown, IDropdownOption, Label, TextField } from 'office-ui-fabric-react';
import { ContainerEntry, PropertyDefinition, PropertyMappingType, SchemaNode, SchemaSourceOptions, SchemaSourceTypes } from 'app/library/layout-builder';
import { DynamicFilterTypes, IDynamicFilter, MetaTypeEnum } from 'app/shared';
import LocalDataWizardField from '../LocalDataWizard/LocalDataWizardField';
import { FilterWizardField } from '../FilterWizard';
import CustomServiceWizardField from '../CustomServiceWizard/CustomServiceWizardField';
import { IContentType, IMeta, ISpec } from 'app/services/content-type';
import { toggleModal } from 'app/redux/modal/actions';
import AutoComplete from '../AutoComplete';
import { GrouppedDataWizardField } from '../GrouppedDataWizard/GrouppedDataWizardField';
import { IGrouppedValue } from '../GrouppedDataWizard/types';

interface MappingModalProps {
    show: boolean;
    isLayout: boolean;
    metaFields: IMeta[];
    specFields: ISpec[];
    contentTypes: IContentType[];
    node: SchemaNode;
    entry: ContainerEntry;
    dynamics: IDynamicFilter[];
    toggleModal: typeof toggleModal;
    onChangeDynamics: (data: IDynamicFilter, prop: string) => void;
    onChangeSource: (prop: string, key: "type" | "ref" | "relation", value: any) => void;
    onChangeSourceObject: (prop: string, object: SchemaSourceOptions) => void;
    onDismiss: () => void;
}

interface MappingField {
    text: string,
    name: string
}

export default class MappingModal extends Component<MappingModalProps> {

    getMetaFields(metaFields: IMeta[]) {
        const filteredMetaFields = metaFields.filter(x => (this.props.entry.options?.meta?.allowedTypes || []).includes(x.type));
        console.log("meta", filteredMetaFields);

        let fields = filteredMetaFields.map(x => ({ name: x.name, type: x.type }));
        fields.forEach(item => {
            if (item.type === MetaTypeEnum.Dropdown) {
                fields = [...fields, { name: `${item.name}.id`, type: item.type }, { name: `${item.name}.title`, type: item.type }]
            }
            if (item.type === MetaTypeEnum.SingleImage) {
                fields = [
                    ...fields,
                    { name: `${item.name}.src`, type: item.type },
                    { name: `${item.name}.alt`, type: item.type },
                    { name: `${item.name}.title`, type: item.type },
                    { name: `${item.name}.srcset`, type: item.type }
                ]
            }
        })
        return fields.map(item => item.name);
    }

    getSpecFields(specFields: ISpec[]) {
        const filteredSpecFields = specFields.filter(x => (this.props.entry.options?.meta?.allowedTypes || []).includes(x.type));
        let fields = filteredSpecFields.map(x => ({ name: x.name, type: x.type }));
        fields.forEach(item => {
            if (item.type === MetaTypeEnum.Dropdown) {
                fields = [...fields, { name: `${item.name}.id`, type: item.type }, { name: `${item.name}.title`, type: item.type }]
            }
        })
        return fields.map(item => item.name);
    }

    getContentTypeMetaFields(id: string, pure?: boolean): IMeta[] | string[] {
        const contentType = (this.props.contentTypes || []).find(x => x.id === id);
        if (contentType) {
            if (pure) {
                return contentType.metaFields;
            }
            return this.getMetaFields(contentType.metaFields);
        }
        return []
    }

    getContentTypeSpecFields(id: string, pure?: boolean): ISpec[] | string[] {
        const contentType = (this.props.contentTypes || []).find(x => x.id === id);
        if (contentType) {
            if (pure) {
                return contentType.specifications;
            }
            return this.getSpecFields(contentType.specifications);
        }
        return []
    }

    getMappingFields(): MappingField[] {
        let mappingFields: MappingField[] = [];
        const propertyDefinitions = this.props.entry.properties || [];
        propertyDefinitions.forEach(item => {
            mappingFields.push({
                text: item.name,
                name: item.name
            })
        })
        return mappingFields;
    }

    getMappingValues(name: string, type: SchemaSourceTypes) {
        let postFields = [
            { type: MetaTypeEnum.Text, text: "title" },
            { type: MetaTypeEnum.SingleImage, text: "profileImage" },
            { type: MetaTypeEnum.SingleImage, text: "profileImage.src" },
            { type: MetaTypeEnum.Text, text: "profileImage.title" },
            { type: MetaTypeEnum.Text, text: "profileImage.alt" },
            { type: MetaTypeEnum.SingleImage, text: "profileImage.srcset" },
            { type: MetaTypeEnum.Pricing, text: "pricing" },
            { type: MetaTypeEnum.Link, text: "url" }];
        let metaFields = this.getMetaFields(this.props.metaFields);
        let specFields = this.getSpecFields(this.props.specFields);
        if (this.props.isLayout) {
            metaFields = this.getContentTypeMetaFields(this.getProperty(name)?.relation as string) as string[];
            specFields = this.getContentTypeSpecFields(this.getProperty(name)?.relation as string) as string[];
        }
        if (type === SchemaSourceTypes.Meta) {
            return metaFields;
        }
        else if (type === SchemaSourceTypes.Spec) {
            return specFields;
        }
        else {
            return postFields.filter(x => (this.props.entry.options?.meta?.allowedTypes || []).includes(x.type)).map(item => item.text);
        }
    }

    getBindingTypes(property?: PropertyDefinition): IDropdownOption[] {

        let options: IDropdownOption[] = [{ key: "", text: "Statik" }];

        if (this.props.node.getOption("meta")?.use) {
            options = [...options, { key: SchemaSourceTypes.Post, text: "İçerik (Post)" }, { key: SchemaSourceTypes.Meta, text: "Meta" }, { key: SchemaSourceTypes.Spec, text: "Spesifikasyon" },]
        }

        if (property && (property.mapping.type === PropertyMappingType.Array || property.mapping.type === PropertyMappingType.ArrayData || property.mapping.type === PropertyMappingType.ProductData)) {
            options = [...options, { key: SchemaSourceTypes.LocalData, text: "İç Veri Sorgusu" }, { key: SchemaSourceTypes.Groupped, text: "Gruplanmış Veri" }]
        }

        return options;

    }

    getProperty(name: string) {
        return this.props.node.getSource()[name] || {}
    }

    getDynamicFilter(prop: string) {
        const property = this.getProperty(prop);
        if (property.ref) {
            return this.props.dynamics.find(x => x.id === property.ref) || { id: "", params: [], orders: [], fields: [] };
        }
        return {
            id: "",
            params: [
                {
                    name: "pageSize",
                    type: DynamicFilterTypes.Static,
                    ref: 20
                },
                {
                    name: "pageIndex",
                    type: DynamicFilterTypes.Static,
                    ref: 0
                }
            ],
        } as IDynamicFilter;
    }

    renderBinding(item: MappingField) {
        const isLayout = this.getProperty(item.name)?.type !== "local-data" && this.getProperty(item.name)?.type !== "dynamics" && this.props.isLayout;
        return (
            <div className="col no-padding">
                <div className="binding row">
                    <Dropdown
                        options={this.getBindingTypes(this.props.entry?.properties?.find(x => x.name === item.name))}
                        label="Eşleme Tipi"
                        className="custom-dropdown col-md-3"
                        calloutProps={{ className: "custom-dropdown-callout" }}
                        style={{ marginTop: "-1px" }}
                        selectedKey={this.getProperty(item.name)?.type || ""}
                        onChange={(_, option) => {
                            this.props.onChangeSource(item.name, "type", option?.key as string)
                        }}
                    />
                    {isLayout && (
                        <div style={{ paddingLeft: 0 }} className="col">
                            <Dropdown
                                label="İşlem Yapılacak İçerik Tipi"
                                className="custom-dropdown"
                                calloutProps={{ className: "custom-dropdown-callout" }}
                                options={(this.props.contentTypes || []).map((item) => ({ key: item.id as string, text: item.title }))}
                                onChange={(_, opt) =>
                                    this.props.onChangeSource(item.name, "relation", opt?.key as string)
                                }
                                selectedKey={this.getProperty(item.name)?.relation}
                            />
                        </div>
                    )}
                    <div className="col no-padding">
                        <Label style={{ marginBottom: "1px !important" }}>Eşlenecek Değer</Label>
                        {this.getProperty(item.name)?.type === SchemaSourceTypes.LocalData ? (
                            <LocalDataWizardField
                                node={this.props.node}
                                onChange={(value, criteria) => {
                                    if (criteria) {
                                        this.props.onChangeSourceObject(item.name, {
                                            ref: value,
                                            criteria: true
                                        })
                                    }
                                    else {
                                        this.props.onChangeSource(item.name, "ref", value)
                                    }
                                }}
                                queryId={this.getProperty(item.name)?.ref as string}
                            />
                        ) :
                            this.getProperty(item.name)?.type === SchemaSourceTypes.Groupped ? (
                                <GrouppedDataWizardField
                                    contentTypes={this.props.contentTypes}
                                    entry={this.props.entry}
                                    node={this.props.node}
                                    onChange={(data) => {
                                        this.props.onChangeSource(item.name, "ref", data)
                                    }}
                                    value={this.props.node.source[item.name]?.ref as IGrouppedValue || {}}
                                />
                            ) :
                                this.getProperty(item.name)?.type === SchemaSourceTypes.Dynamics ? (
                                    <FilterWizardField
                                        filter={this.getDynamicFilter(item.name)}
                                        node={this.props.node}
                                        onChange={(data) => {
                                            this.props.onChangeDynamics(data, item.name)
                                        }}
                                    />
                                ) :
                                    this.getProperty(item.name)?.type === SchemaSourceTypes.CustomService ? (
                                        <CustomServiceWizardField
                                            data={{ node: this.props.node, mapping: [], service: "" }}
                                            toggleModal={this.props.toggleModal}
                                        />
                                    ) :
                                        (
                                            <AutoComplete
                                                placeholder="Eşlenecek bir değer seçin"
                                                selectedValue={this.getProperty(item.name)?.ref as string || ""}
                                                onSelected={(value) => {
                                                    this.props.onChangeSource(item.name, "ref", value)
                                                }}
                                                disabled={isLayout ? (!this.getProperty(item.name)?.type && !this.getProperty(item.name)?.relation) : !this.getProperty(item.name)?.type}
                                                filterData={this.getMappingValues(item.name, this.getProperty(item.name)?.type as SchemaSourceTypes)}
                                            />
                                        )}

                    </div>
                </div>
            </div>
        )
    }

    renderMapping() {
        return (
            <div className="wizard-item mapping-list">
                <div style={{ paddingTop: 15 }} className="wizard-item-content">
                    {this.getMappingFields().map((item, index: number) => {
                        return (
                            <div key={index} className="mapping row">
                                <TextField
                                    className="small-element custom-textfield col-md-3 no-padding"
                                    label={`Özellik Adı (${item.text})`}
                                    readOnly
                                    value={item.name}
                                />
                                <div className="col">
                                    {this.renderBinding(item)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderModal() {
        return (
            <Dialog
                hidden={!this.props.show}
                onDismiss={() => this.props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Veri Eşleme",
                    closeButtonAriaLabel: "Kapat",
                    subText:
                        "İgili elementin geçerli özelliklerini; meta, spesifikasyon ya da sorgular ile doldurabilirsiniz. Eşleme işlemi için bir eşleme tipi ve değer karşılıklarını seçin."
                }}
                modalProps={{
                    className: "confirm",
                    isBlocking: false,
                    styles: { main: { maxWidth: "1100px !important", minWidth: "1000px !important" } },
                }}
            >
                {this.renderMapping()}
            </Dialog>
        )
    }

    render() {

        if (!this.props.show) return null;

        console.log("this props", this.props);

        return this.renderModal()
    }
}

import { CmsReactComponent } from 'components/cms/CmsReactComponent';
import { StyleProps } from "./types";
import {
    PropertyDefinition,
    PropertyMappingType,
} from "app/library/layout-builder";

export class StyleDefinitions extends CmsReactComponent<StyleProps> {

    static schemaName = "style";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "content",
            mapping: {
                type: PropertyMappingType.Css
            }
        }
    ];
}

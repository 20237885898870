import { MetaTypeEnum } from 'app/shared';
import { IMetaDefinition } from './types';

export const metaList: IMetaDefinition[] = [
    {
        type: MetaTypeEnum.Text,
        icon: "fas fa-font",
        title: "Yazı Kutusu",
        color: "orange",
    },
    {
        type: MetaTypeEnum.MultiText,
        icon: "fas fa-text-width",
        title: "Çoklu Yazı",
        color: "orange",
    },
    {
        type: MetaTypeEnum.Number,
        icon: "fas fa-sort-numeric-up-alt",
        title: "Değer Kutusu",
        color: "orange",
    },
    {
        type: MetaTypeEnum.TextArea,
        icon: "fas fa-text-height",
        title: "Geniş Yazı",
        color: "green"
    },
    {
        type: MetaTypeEnum.Dropdown,
        icon: "fas fa-caret-square-down",
        title: "Seçim Kutusu",
        color: "softPurple",
    },
    {
        type: MetaTypeEnum.ComboBox,
        icon: "fas fa-grip-lines",
        title: "Çoklu Seçim",
        color: "softPurple",
    },
    {
        type: MetaTypeEnum.Checkbox,
        icon: "fas fa-check-square",
        title: "Onay Kutusu",
        color: "blue",
    },
    {
        type: MetaTypeEnum.Date,
        icon: "fas fa-calendar",
        title: "Tarih Kutusu",
        color: "blue",
    },
    {
        type: MetaTypeEnum.Editor,
        icon: "fas fa-toolbox",
        title: "Yazı Editörü",
        color: "purple",
        column: 12
    },
    {
        type: MetaTypeEnum.SingleImage,
        icon: "fas fa-image",
        title: "Tekli Görsel",
        color: "yellow",
    },
    {
        type: MetaTypeEnum.MultiImage,
        icon: "fas fa-images",
        title: "Çoklu Görsel",
        color: "softGreen",
        column: 12
    },
    {
        type: MetaTypeEnum.PhotoSlider,
        icon: "fas fa-atom",
        title: "Görsel Slider",
        color: "softGreen",
        column: 12
    },
    {
        type: MetaTypeEnum.File,
        icon: "fas fa-file",
        title: "Dosya",
        color: "softGreen"
    },
    {
        type: MetaTypeEnum.MultiFile,
        icon: "fas fa-photo-video",
        title: "Çoklu Dosya",
        color: "softGreen",
        column: 12
    },
    {
        type: MetaTypeEnum.SingleVideo,
        icon: "fas fa-play",
        title: "Tekli Video",
        color: "yellow",
    },
    {
        type: MetaTypeEnum.Link,
        icon: "fas fa-link",
        title: "Link Sihirbazı",
        color: "softPurple",
    },
    {
        type: MetaTypeEnum.Color,
        icon: "icon-colorpicker",
        title: "Renk Seçici",
        color: "softPurple",
    },
    {
        type: MetaTypeEnum.PhotoGallery,
        icon: "fas fa-photo-video",
        title: "Fotoğraf Galerisi",
        color: "softPurple",
    }
];

export function getMeta(type: MetaTypeEnum): IMetaDefinition {
    return metaList.find((x) => x.type === type) || {} as IMetaDefinition;
}

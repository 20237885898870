import { PropertyDefinition, PropertyMappingType } from "app/library/layout-builder";
import { CmsReactComponent, CmsReactComponentProps } from "components/cms/CmsReactComponent";

export class HomeWhyExhibitDefinitions extends CmsReactComponent<CmsReactComponentProps> {
    static schemaName = "home-why-exhibit";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "reasons",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        text: "Ana Başlık",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                    },
                    {
                        name: "reasonlists",
                        mapping: {
                            type: PropertyMappingType.ArrayData,
                            keys: [
                                {
                                    name: "title",
                                    text: "Başlık",
                                    mapping: {
                                        type: PropertyMappingType.String,
                                    },
                                },
                                {
                                    name: "description",
                                    text: "Metin",
                                    mapping: {
                                        type: PropertyMappingType.Textarea,
                                    },
                                },
                            ]
                        },
                        default: []
                    }
                ]
            },
            default: []
        }, {
            name: "slides",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "title",
                        text: "Başlık",
                        mapping: {
                            type: PropertyMappingType.String,
                        },
                    },
                    {
                        name: "description",
                        text: "Metin",
                        mapping: {
                            type: PropertyMappingType.Textarea,
                        },
                    },
                    {
                        name: "image",
                        text: "Resim",
                        mapping: {
                            type: PropertyMappingType.Image,
                        },
                    },
                    
                ]
            },
            default: []
        }
    ];
}

export interface IGlobalDataWizardMapping {
    [x: string]: any;
}

export enum QuerySortEnum {
    CreatedOn = "CreatedOn",
    Status = "status",
    Title = "title",
    Order = "order",
    Random = "random"
}
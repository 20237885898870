import ComponentHeader from "../ComponentHeader";
import { PopupDefinitions } from "./PopupDefinitions";
import PopupDesigner from "./PopupDesigner";

export class Popup extends PopupDefinitions {
    render() {
        const { className, designing } = this.props;
        return (
            <div
                data-schema-id={this.props.id}
                className={[
                    "fa-draggable fa-CardList",
                    designing && "designing",
                    className,
                ]
                    .filter((e) => !!e)
                    .join(" ")}
            >
                {designing && (
                    <ComponentHeader
                        componentEntry={this.props.entry}
                        componentSchemaId={this.props.id as string}
                    />
                )}
                {this.props.designing ? (
                    <PopupDesigner {...this.props} />
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}

// import React from "react";
import { AccordionProps } from './types';
import {
    EntryOptions,
    PropertyDefinition, PropertyMappingType
} from "app/library/layout-builder";
import { MetaTypeEnum } from 'app/shared';
import { CmsReactComponent } from '../CmsReactComponent';

export class AccordionDefinitions extends CmsReactComponent<AccordionProps> {

    static schemaName = "accordion";

    static propertyDefinitions: PropertyDefinition[] = [
        {
            name: "data",
            text: "Öğe Verileri",
            mapping: {
                type: PropertyMappingType.ArrayData,
                keys: [
                    {
                        name: "form",
                        mapping: {
                            type: PropertyMappingType.Boolean
                        },
                        text: "Ziyaretçi Başvuru Formu"
                    },
                    {
                        name: "expanded",
                        mapping: {
                            type: PropertyMappingType.Boolean
                        },
                        text: "Aktif Mi?"
                    },
                    {
                        name: "title",
                        mapping: {
                            type: PropertyMappingType.String
                        },
                        text: "Başlık"
                    },
                    {
                        name: "iconBefore",
                        mapping: {
                            type: PropertyMappingType.Icon
                        },
                        text: "İkon (Önce)"
                    },
                    {
                        name: "iconAfter",
                        mapping: {
                            type: PropertyMappingType.Icon
                        },
                        text: "İkon (Sonra)"
                    },
                    {
                        name: "content",
                        text: "İçerik Açıklaması",
                        mapping: {
                            type: PropertyMappingType.Html
                        }
                    },
                ]
            }
        },
        {
            name: "toggle",
            text: "Tetikle",
            mapping: {
                type: PropertyMappingType.Boolean
            }
        }
    ];

    static options: EntryOptions = {
        mappingFields: [
            {
                name: "title",
                text: "Başlık",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "content",
                text: "Açıklama",
                types: [MetaTypeEnum.Text, MetaTypeEnum.TextArea],
            },
            {
                name: "expanded",
                text: "Aktif Mı?",
                types: [MetaTypeEnum.Checkbox]
            },
            {
                name: "form",
                text: "Ziyaretçi Başvuru Formu",
                types: [MetaTypeEnum.Checkbox]
            }
        ],
    };
}


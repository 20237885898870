import React from 'react';

class ClarificationText extends React.Component {
  render() {
    return (
      <div className="page" style={{ margin: '0 auto', maxWidth: '1100px', minHeight: '100vh', background: "#fff" }}>
        <div className="auth-page-header">
          <div className="auth-page-brand">6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA
          AYDINLATMA METNİ</div>
        </div>
        <br />
        <style>{`
            li, p {
              font-size: 14px;
              margin-top: 6px;
              line-height: 1.5;
            }
        `}</style>
        <p>T&uuml;m hakları USHAŞ Uluslararası Sağlık Hizmetleri Anonim Şirketi (USHAŞ)&rsquo;a ait www.healthturkey.com.tr web sitesi &uuml;zerinden ve 0850 622 75 75 numaralı &ccedil;ağrı merkezi &uuml;zerinden tarafımızca işlenen kişiler verileriniz, 6698 sayılı Kişisel Verilerin Korunması Kanunu (&ldquo;Kanun&rdquo;) ve ilgili mevzuat kapsamında Veri Sorumlusu sıfatıyla Sağlık Bakanlığı d&uuml;zenlemeleri ve sair mevzuata uygun olarak, kişisel verilerinizin g&uuml;venliğine azami hassasiyet g&ouml;stermek suretiyle tarafımızca işlenebilecektir.</p>
        <p>&nbsp;</p>
        <h3>1. KİŞİSEL VERİLERİN TOPLANMASI, İŞLENMESİ VE İŞLENME AMA&Ccedil;LARI</h3>
        <p>&nbsp;</p>
        <p>Kişisel verileriniz www.healthturkey.com.tr web sitesi ve 0850 622 75 75 &nbsp;numaralı &ccedil;ağrı merkezi&nbsp; &uuml;zerinden &nbsp;s&ouml;zl&uuml;, yazılı, g&ouml;rsel ya da elektronik ortamda, &ccedil;ağrı merkezi, internet sitesi ve benzeri kanallar aracılığıyla ve internet sitemiz tarafından yaratılan &Ccedil;erezler/Cookieler ile &uuml;&ccedil;&uuml;nc&uuml; taraflarca yaratılan Takip &Ccedil;erezleri/Tracking Cookieler ve www.healthturkey.com.tr web sitesine kayıtlı olan ve sağlık hizmeti faaliyeti y&uuml;r&uuml;ten &ouml;zel hastaneler, T&uuml;rkiye Cumhuriyeti Sağlık Bakanlığı&rsquo;na bağlı Devlet Hastaneleri ile Şehir Hastaneleri tarafından sisteme yapılan y&uuml;klemeler sonucunda elde edilmektedir.</p>
        <p>Tarafımızla paylaşılan kişisel verileriniz, aşağıda belirlenenlerle sınırlı olmaksızın Kanun&rsquo;un 5 ve 6 ncı maddelerinde belirtilen işlenme şartlarına uygun şekilde, sınırlı ve &ouml;l&ccedil;&uuml;l&uuml; olarak işlenebilmektedir:</p>
        <ul className="disc">
          <li>Adınız, Soyadınız, Doğum Yeri, Doğum Tarihi, Cinsiyet, Medeni Hal, Pasaport Numarası, Pasaport g&ouml;rseli ve benzeri diğer kimlik bilgileriniz,</li>
          <li>Elektronik posta adresi, Telefon numarası, Adres Bilgisi gibi iletişim bilgileriniz,</li>
          <li>Sağlık hizmetlerine aracılık edilmesi hizmeti kapsamında tarafımıza sunduğunuz ya da www.healthturkey.com.tr web sitesine kayıtlı olan ve sağlık hizmeti faaliyeti y&uuml;r&uuml;ten &ouml;zel hastaneler ve T&uuml;rkiye Cumhuriyeti Sağlık Bakanlığı&rsquo;na bağlı Devlet Hastaneleri ile Şehir Hastaneleri tarafından sisteme y&uuml;klenen sağlık sigortası bilgileriniz, laboratuvar ve g&ouml;r&uuml;nt&uuml;leme sonu&ccedil;larınız, sağlık raporlarınız, hastalık ve tedavi ge&ccedil;mişi bilgileriniz, kullanılan ila&ccedil; bilgileriniz, kronik hastalık bilgileriniz, alerji ge&ccedil;irmisşiniz ile benzeri diğer sağlık ve cinsel hayata ilişkin verileriniz,</li>
          <li>Hizmetlerimizi değerlendirmek amacı ile paylaştığınız yanıt ve yorumlarınız,</li>
          <li>&Ccedil;ağrı Merkezimiz ile iletişime ge&ccedil;tiğiniz takdirde tutulan sesli g&ouml;r&uuml;şme kayıtlarınız,</li>
          <li>Web sitemizin kullanımı sırasında elde edilen gezinme bilgileri, IP adresi, tarayıcı bilgileri, cihaz bilgileri, Google Analytics tarafından toplanan data bilgileri, Google Ads tarafından toplanan data bilgileri, Facebook Pixel tarafından toplanan data bilgileri ve kendi rızanız ile ilettiğiniz tıbbi belgeler, anketler, form bilgileriniz ve konum verileriniz.</li>
        </ul>
        <p>&nbsp;</p>
        <p>&Ouml;zel nitelikli kişisel verileriniz de d&acirc;hil olmak &uuml;zere her t&uuml;rl&uuml; kişisel veriniz aşağıdaki ama&ccedil;lar ile ve fakat bunlarla sınırlı olmaksızın işlenebilecektir:</p>
        <ul className="disc">
          <li>USHAŞ tarafından www.healthturkey.com.tr web sitesi &uuml;zerinden sunulan &uuml;r&uuml;n ve hizmetlerin sunulmasına ve ifasına ilişkin s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,</li>
          <li>Tıbb&icirc; teşhis, tedavi ve bakım hizmetlerinin y&uuml;r&uuml;t&uuml;lmesi, sağlık hizmetleri ile finansmanının planlanması, y&ouml;netimi ve aracılık faaliyetleri,</li>
          <li>USHAŞ&rsquo;ın 663 sayılı Kanun H&uuml;km&uuml;nde Kararname, 6102 sayılı T&uuml;rk Ticaret Kanunu ve sair yasal d&uuml;zenlemeden doğan idari ve ticari faaliyetlerinin yerine getirilmesi,</li>
          <li>USHAŞ tarafından sunulan &uuml;r&uuml;n ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve İhtiya&ccedil;larına g&ouml;re &ouml;zelleştirilerek ilgili kişilere &ouml;nerilmesi i&ccedil;in gerekli olan aktivitelerin planlanması ve icrası</li>
          <li>Kimliğin teyit edilmesi,</li>
          <li>Sunulan sağlık ve finansal hizmetlere ilişkin verilerinin mevzuata uygun olarak arşivlenmesi /saklanması,</li>
          <li>Muhasebe ve finans s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi,</li>
          <li>Talep ve şik&acirc;yetlerin alınması ve değerlendirilmesi,</li>
          <li>Hasta ilişkileri s&uuml;re&ccedil;lerinin planlanması,</li>
          <li>Sağlık Bakanlığı ve diğer kamu kurum ve kuruluşları ile talep edilen bilgilerin paylaşılması</li>
          <li>Risk y&ouml;netimi ve kalite geliştirme aktivitelerinin yerine getirilmesi,</li>
          <li>Yasal ve d&uuml;zenleyici gereksinimlerin yerine getirilmesi,</li>
          <li>Hukuki işlemlerin takibi ve y&uuml;r&uuml;t&uuml;lmesi,</li>
          <li>Resmi kurum taleplerinin yerine getirilmesi,</li>
          <li>Bilgi g&uuml;venliği faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi,</li>
          <li>Denetim ve etik faaliyetlerinin ger&ccedil;ekleştirilmesi,</li>
          <li>Mevcut kampanyalara katılım veya olası kampanyalar i&ccedil;in bilgi verilmesi, somut ve soyut faydaların tasarlanması ve iletilebilmesi.</li>
        </ul>
        <p>&nbsp;</p>
        <p>USHAŞ tarafından mevzuata uygun olarak elde edilen ve işlenen kişisel verileriniz, kanun kapsamında belirlenen azami g&uuml;venlik &ouml;nlemlerine uygun olarak gerek fiziki arşiv gerek ise bilişim sistemleri ortamında muhafaza edilebilecektir.</p>
        <p>&nbsp;</p>
        <p>&Ouml;zel nitelikli kişisel verileriniz, tarafınızca a&ccedil;ık rıza onayı verilmediği s&uuml;rece tarafımızca işlenmeyecektir.</p>
        <p>&nbsp;</p>
        <h3>2. KİŞİSEL VERİLERİN KİMLERE VE HANGİ AMA&Ccedil;LA AKTARABİLECEĞİ</h3>
        <p>&nbsp;</p>
        <p>USHAŞ tarafından elde edilen kişisel verileriniz yukarıda yer verilen ama&ccedil;lar doğrultusunda www.healthturkey.com.tr web sitesine kayıtlı olan ve sağlık hizmeti faaliyeti y&uuml;r&uuml;ten &ouml;zel hastaneler&nbsp; ve T&uuml;rkiye Cumhuriyeti Sağlık Bakanlığı&rsquo;na bağlı Devlet Hastaneleri, Şehir Hastaneleri, Sağlık Bakanlığı ile Bakanlığın bağlı ve ilgili Kurum ve Kuruluşları, Emniyet Genel M&uuml;d&uuml;rl&uuml;ğ&uuml; ve sair resmi kurumlar ile her t&uuml;rl&uuml; yargı makamı, sağlık hizmetinin y&uuml;r&uuml;t&uuml;lmesi kapsamında hizmet alınan &uuml;&ccedil;&uuml;nc&uuml; kişiler, temsilciler, avukatlar ve bunlarla sınırlı olmaksızın danışmanlık hizmeti alınan firmalar ve ortaklıklar ile d&uuml;zenleyici ve denetleyici kurumlara Kanun&rsquo;un 8 inci ve 9 uncu maddelerinde belirtilen kişisel verilerin aktarımına ilişkin kurallara riayet edilerek aktarılabilecektir.</p>
        <p><strong>&nbsp;</strong></p>
        <h3>3. KİŞİSEL VERİ TOPLAMANIN HUKUKİ SEBEBİ</h3>
        <p>&nbsp;</p>
        <p>USHAŞ tarafından kişisel verileriniz, yukarıda yer verilen ama&ccedil;lar ve USHAŞ&rsquo;ın faaliyet konusuna d&acirc;hil her t&uuml;rl&uuml; işin yasal &ccedil;er&ccedil;evede y&uuml;r&uuml;t&uuml;lebilmesi, bu kapsamda USHAŞ&rsquo;ın akdi ve kanuni y&uuml;k&uuml;ml&uuml;l&uuml;klerini tam ve gereği gibi ifa edebilmesi i&ccedil;in toplanmakta ve işlenmektedir.</p>
        <p>Kişisel verilerinizin toplanmasındaki hukuki sebep;</p>
        <ul className="disc">
          <li>6698 sayılı Kişisel Verilerin Korunması Kanunu,</li>
          <li>Kişisel Sağlık Verileri Hakkında Y&ouml;netmelik,</li>
          <li>3359 sayılı Sağlık Hizmetleri Temel Kanunu,</li>
          <li>663 sayılı Sağlık Alanında Bazı D&uuml;zenlemeler Hakkında Kararname,</li>
          <li>Uluslararası Sağlık Turizmi ve Turistin Sağlığı Hakkında Y&ouml;netmelik,</li>
          <li>Sağlık Bakanlığı D&uuml;zenlemeleri ve sair hukuki ve idari d&uuml;zenlemedir.</li>
        </ul>
        <p>&nbsp;</p>
        <h3>4. VERİ G&Uuml;VENLİĞİ</h3>
        <p>&nbsp;</p>
        <p>Yukarıda belirtilen ama&ccedil;lar ve hukuki sebepler doğrultusunda işlenen Kişisel Verilerinizin g&uuml;venliğini sağlamak amacıyla azami g&uuml;venlik &ouml;nlemi USHAŞ tarafından alınmakta olup;&nbsp; bilgi g&uuml;venliği standartları ve prosed&uuml;rleri gereğince mevzuat kapsamında alınması gereken t&uuml;m teknik ve idari g&uuml;venlik &ouml;nlemlerine uygun olarak kişisel verileriniz korunmaktadır.</p>
        <p>&nbsp;</p>
        <h3>5. KANUN&rsquo;UN 11. MADDESİNE G&Ouml;RE VERİ SAHİBİNİN HAKLARI</h3>
        <p>&nbsp;</p>
        <p>Kanun ve ilgili mevzuat kapsamında kişisel veri sahibi olarak aşağıda yer verilen haklara sahip olduğunuzu bildiririz;</p>
        <ul className="disc">
          <li>Kişisel verilerinizin işlenip işlenmediğini &ouml;ğrenme ve işlenmiş ise buna ilişkin bilgi talebinde bulunma</li>
          <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme,</li>
          <li>Kişisel verilerinizin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,</li>
          <li>Eksik veya yanlış işlenen kişisel verileriniz olması h&acirc;linde bunların d&uuml;zeltilmesini isteme ve işlemin kişisel verilerinizin aktarıldığı kişilere bildirilmesini isteme,</li>
          <li>Kişisel verinin işlenmesini gerektiren sebeplerin ortadan kalkması h&acirc;linde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı kişilere bildirilmesini isteme,</li>
          <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması h&acirc;linde zararın giderilmesini talep etme.</li>
        </ul>
        <p>&nbsp;</p>
        <p>Yukarıda yer verilen hakların kullanımı a&ccedil;ısından USHAŞ ile paylaştığınız verinin doğruluğu ve USHAŞ&rsquo;a doğru bi&ccedil;imde aktarımı b&uuml;y&uuml;k &ouml;nem arz etmektedir. Kişisel verileriniz ile ilgili bir değişiklik olması halinde USHAŞ&rsquo;a bildirimde bulunmanız gerekmektedir. G&uuml;ncel olmayan ya da hatalı veri bildiriminden doğacak sorumluluk, veriyi USHAŞ&rsquo;a ileten tarafa aittir.</p>
        <p>&nbsp;</p>
        <h3>6. İLETİŞİM VE ŞİK&Acirc;YET</h3>
        <p>&nbsp;</p>
        <p>Kanun kapsamındaki başvurularınız; a&ccedil;ık, anlaşır bir şekilde, kimlik ve adres bilgilerinizi tespit edici belgeleri de ekleyerek, yazılı ve ıslak imzalı olarak elden, postayla ya da noter kanalıyla internet sitesinde sunulan <u>Başvuru Formu</u> ile merkez adresimiz olan Sağlık Bakanlığı, Bilkent Yerleşkesi, &Uuml;niversiteler Mah. Dumlupınar Bulvarı 6001. Cad. No:9 Kat:8 &Ccedil;ankaya/Ankara 06800 adresine iletmeniz ya da <a target="_blank" rel="noreferrer" href="https://www.ushas.com.tr/iletisim/ushas@hs01.kep.tr">ushas@hs01.kep.tr</a> adresine g&uuml;venli elektronik ya da mobil imzalı olarak, kayıtlı elektronik posta adresi veya sistemimizde kayıtlı elektronik e-posta adresiniz aracılığıyla g&ouml;ndermeniz gerekmektedir.</p>
        <p>&nbsp;</p>
        <p>Başvurunuz, USHAŞ&rsquo;a ulaşmasını m&uuml;teakip 30 g&uuml;nl&uuml;k yasal s&uuml;re i&ccedil;erisinde &uuml;cretsiz olarak sonu&ccedil;landırılacaktır. İşlemin ayrıca maliyet gerektirdiği hallerde KVKK h&uuml;k&uuml;mleri uyarınca; USHAŞ&rsquo;ın 10 (on) sayfanın &uuml;zerindeki yazılı cevaplarda her sayfa i&ccedil;in 1 (bir) T&uuml;rk Lirası, CD, flash bellek gibi kayıt ortamlarında cevap verilmesi halinde s&ouml;z konusu kayıt ortamının maliyeti tutarındaki bedeli tarafınızdan talep etme hakkı saklıdır.</p>
        <p>&nbsp;</p>
        <p>USHAŞ&rsquo;ın KVKK ve sair mevzuat ile Kişisel Verileri Koruma Kurulu d&uuml;zenlemelerinde meydana gelebilecek değişiklikler neticesinde, işbu Aydınlatma Metninde değişiklik yapma hakkı saklıdır.</p>
        <p>&nbsp;</p>
        <h3>7. &Ccedil;EREZ POLİTİKASI</h3>
        <p>&nbsp;</p>
        <p>USHAŞ olarak, <a href="http://www.healthturkey.com.tr" target="_blank" rel="noreferrer">www.healthturkey.com.tr</a> &nbsp;web sitemizi ziyaretleriniz sırasında deneyiminizi geliştirmek i&ccedil;in &ccedil;erezlerden faydalanıyoruz.&nbsp;Bu teknolojilerin kullanımını 6698 sayılı KVVK ile tabi olduğumuz mevzuata uygun şekilde ger&ccedil;ekleştirmekteyiz. &Ccedil;erezler hakkında ayrıntılı bilgi almak i&ccedil;in <u>&Ccedil;erez Aydınlatma Metnini</u> inceleyebilirsiniz.</p>
      </div >
    );
  }
}

export default ClarificationText;

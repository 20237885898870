import { api } from 'app/utils/api';
import { getEndpoint } from 'app/utils/service';
import { HttpClient } from 'app/library/client';
import { ResponseBase, ResponseContentBase } from "app/shared";
import { QueryBuilderEntity } from './types';

export class QueryBuilderService {

    /** It get spesific query by query id */
    async getQuery(queryId: string): Promise<ResponseBase<ResponseContentBase<QueryBuilderEntity>>> {
        return HttpClient.getClient().get(getEndpoint(api.prefixes.queryBuilder.main) + queryId);
    }

    /** It creates new query by schema */
    async createQuery(request: QueryBuilderEntity): Promise<ResponseBase<ResponseContentBase<QueryBuilderEntity>>> {
        return HttpClient.getClient().post(getEndpoint(api.prefixes.queryBuilder.main), request);
    }
}

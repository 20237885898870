import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import cookie from "react-cookies";

import { RootState } from "app/redux/reducer";
import { IdentityService } from "./service";
import {
  IdentityLoginRequest,
  RolePermissionEntity,
  RoleEntity,
} from "./types";
import {
  setAccessToken,
  setUserInfo,
  showMessage,
  toggleLoader,
  toggleVerificationLoader,
  tokenControl,
} from "app/redux/system/actions";
import { UserRole, UserService, UserType } from "../user";
import { MessageType } from "app/redux/system/types";
import { initialPageData } from "app/redux/page/actions";
import { HttpStatusCode, IList, IListFilter } from "app/shared";
import { setModalData } from "app/redux/modal/actions";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;
const service = new IdentityService();

export function connectToken(
  request: IdentityLoginRequest
): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    const response = await service.signIn(request);
    if (response.status === HttpStatusCode.OK) {
      if (response.data && response.data?.attributes?.access_token) {
        cookie.save("accessToken", response.data.attributes.access_token, { path: "/" });
        // cookie.save("refreshToken", response.data.attributes.access_token, { path: "/" });
        dispatch(setAccessToken(response.data.attributes.access_token));
        cookie.save('userInformation', response.data.attributes.user, { path: '/' })
        dispatch(setUserInfo({
          id: response.data.attributes.user.id,
          name: response.data.attributes.user.fullName,
          surname: response.data.attributes.user.fullName,
          email: response.data.attributes.user.username,
          user_type: UserType.SuperAdminUser
        }));
        dispatch(tokenControl(true));
      }
      return true;
    }
    dispatch(tokenControl(false));
    return false;
  };
}

export function getUserInfo(): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleVerificationLoader());

    const response = await service.getUserInfo();

    if (response && response?.AdminUserId) {
      const userService = new UserService();
      const userResponse = await userService.getUser(response?.AdminUserId);

      if (userResponse.success) {

        userResponse.data.role =
          typeof response.role === "string"
            ? [response.role as UserRole]
            : (response.role as UserRole[]);

        dispatch(setAccessToken(cookie.load("accessToken")));
        dispatch(setUserInfo(userResponse.data));
        dispatch(tokenControl(true));
        cookie.save("userInformation", userResponse.data, { path: "/" });
        dispatch(toggleVerificationLoader());
        return true;
      }
    }
    dispatch(toggleVerificationLoader());
    dispatch(tokenControl(false));

    return false;
  };
}

export function checkToken(): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleVerificationLoader());
    const response = await service.checkToken();

    dispatch(toggleVerificationLoader());
    if (response.status === HttpStatusCode.OK) {
      dispatch(tokenControl(true));
      return true;
    }
    dispatch(tokenControl(false));
    return false;
  };
}

export function getRoles(options: IListFilter): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const response = await service.getRoles(options);
    dispatch(toggleLoader());
    if (response.success) {
      dispatch(
        initialPageData<IList<RoleEntity[]>>({
          list: response.dataList,
          pageSize: response.pageSize,
          totalCount: response.dataCount,
          pageCount: response.pageCount,
        })
      );

      return true;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

export function getRoleById(roleId: number): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const response = await service.getRoleById(roleId);
    dispatch(toggleLoader());
    if (response.success) {
      dispatch(initialPageData(response.data));
      return true;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

export function getRolePermissionList(
  roleId: number
): ThunkResult<Promise<any>> {
  return async (dispatch, getState) => {
    dispatch(toggleLoader());
    const response = await service.getRolePermissionList(roleId);
    dispatch(toggleLoader());
    if (response.success) {
      const modalData: RolePermissionEntity = {
        roleId,
        permissions: response.permissions,
        specialPermissions: response.specialPermissions,
      };

      const modal = getState().modal.role.data;

      dispatch(
        setModalData("role", {
          ...modal,
          modalData,
        })
      );
      return true;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

export function updateRolePermission(): ThunkResult<Promise<boolean>> {
  return async (dispatch, getState) => {
    const request = getState().modal.role.data.modalData;

    if (request) {
      dispatch(toggleLoader());
      const response = await service.updateRolePermission(request);
      dispatch(toggleLoader());
      if (response.success) {
        return true;
      }
      dispatch(
        showMessage(
          "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
          "Bir Hata Oluştu!",
          MessageType.ERROR
        )
      );
    }

    return false;
  };
}

export function createRole(): ThunkResult<Promise<boolean>> {
  return async (dispatch, getState) => {
    const request = getState().modal.role.data;
    dispatch(toggleLoader());
    const response = await service.createRole({
      id: request.id,
      name: request.name,
      code: request.code,
    });
    dispatch(toggleLoader());
    if (response.success) {
      return true;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

export function updateRole(): ThunkResult<Promise<boolean>> {
  return async (dispatch, getState) => {
    const request = getState().modal.role.data;
    dispatch(toggleLoader());
    const response = await service.updateRole({
      id: request.id,
      name: request.name,
      code: request.code,
    });
    dispatch(toggleLoader());
    if (response.success) {
      return true;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

export function deleteRole(roleId: number): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const response = await service.deleteRole(roleId);
    dispatch(toggleLoader());
    if (response.success) {
      return true;
    }
    dispatch(
      showMessage(
        "Bir hata oluştu. Servis sağlayıcınızla iletişime geçin ya da daha sonra tekrar deneyiniz!",
        "Bir Hata Oluştu!",
        MessageType.ERROR
      )
    );
    return false;
  };
}

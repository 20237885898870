export const SET_ACTIVE_ELEMENT = "SET_ACTIVE_ELEMENT";
export const SET_DESIGNER_DIMENSION = "SET_DESIGNER_DIMENSION";
export const SET_DESIGNER_MODE = "SET_DESIGNER_MODE";
export const SET_PREVIEW_DIMENSON = "SET_PREVIEW_DIMENSON";
export const SET_PANEL_ACCORDION = "SET_GROUP_ACCORDION";

export type DimensionOptions = "desktop" | "tablet" | "phone";

interface SetActiveElementAction {
	type: typeof SET_ACTIVE_ELEMENT;
	id?: string;
}

interface SetDesignerDimensionAction {
	type: typeof SET_DESIGNER_DIMENSION;
	dimension: DimensionOptions;
}

interface SetDesignerMode {
	type: typeof SET_DESIGNER_MODE;
	designerMode: boolean;
}

interface SetPreviewDimensionAction {
	type: typeof SET_PREVIEW_DIMENSON;
	dimension: DimensionOptions;
}

export interface DesignerState {
	designerDimension: DimensionOptions;
	previewDimension: DimensionOptions;
	activeElement: string;
	designerMode: boolean;
	activeAccordions: any[];
}

interface SetPanelAccordionAction {
	type: typeof SET_PANEL_ACCORDION;
	value: string;
	single?: boolean;
}

export type DesignerActionTypes =
	| SetActiveElementAction
	| SetPreviewDimensionAction
	| SetDesignerDimensionAction | SetPanelAccordionAction | SetDesignerMode;
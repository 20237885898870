import { IMenu, IMenuItem, MenuEntity, MenuItemEntity } from "./types";

/**
 * Menu entity schema convert to model
 */
export function mapMenuFromEntity(menuEntity: MenuEntity): IMenu {
    return {
        id: menuEntity.id,
        title: menuEntity.title,
        url: menuEntity.url,
        items: (menuEntity.items || []).map(x => mapMenuItemFromEntity(x)),
        status: menuEntity.status,
        language: menuEntity.language || "en",
        relationId: menuEntity.languageRelationId || "",
        createdBy: menuEntity.createdBy,
        updatedBy: menuEntity.modifiedBy,
        createdAt: menuEntity.createdOn || menuEntity.CreatedOn,
        updatedAt: menuEntity.modifiedOn || menuEntity.ModifiedOn
    }
}

/**
 * Menu item entity schema convert to model
 */
export function mapMenuItemFromEntity(menuItemEntity: MenuItemEntity): IMenuItem {
    return {
        title: menuItemEntity.title,
        url: menuItemEntity.url,
        className: menuItemEntity.className,
        icon: menuItemEntity.icon || "",
        isBlank: menuItemEntity.isBlank,
        children: (menuItemEntity.children || []).map(x => mapMenuItemFromEntity(x)),
        id: menuItemEntity.id
    }
}

/**
 * Menu model schema convert to entity
 */
export function mapMenuFromModel(menuModel: IMenu): MenuEntity {
    return {
        id: menuModel.id || "",
        title: menuModel.title,
        url: menuModel.url,
        status: menuModel.status,
        language: menuModel.language || "en",
        languageRelationId: menuModel.relationId || null,
        items: (menuModel.items || []).map(x => mapMenuItemFromModel(x))
    }
}

/**
 * Menu item model schema convert to entity schema
 */
export function mapMenuItemFromModel(menuModel: IMenuItem): MenuItemEntity {
    return {
        id: menuModel.id,
        isBlank: menuModel.isBlank,
        className: menuModel.className,
        icon: menuModel.icon || undefined,
        title: menuModel.title,
        url: menuModel.url,
        children: (menuModel.children || []).map(x => mapMenuItemFromEntity(x))
    }
}
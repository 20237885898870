import { ScrollbarDefinitions } from "./ScrollbarDefinitions";
import ScrollbarDesigner from "./ScrollbarDesigner";

export class Scrollbar extends ScrollbarDefinitions {
    render() {
        return (
            <div
                data-schema-id={this.props.id}
                className={`fa-draggable fa-Section row ${this.props.designing ? "designing" : ""}`}
                style={{
                    minHeight: this.props.designing ? "300px" : "auto",
                    position: "relative",
                }}
            >
                {this.props.designing ? (
                    <ScrollbarDesigner {...this.props} />
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}

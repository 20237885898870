import * as React from "react";
import { BaseFieldProps } from './types';
import { ImageSourceSettings } from 'app/shared';
import { DefaultButton, Dropdown, Label, PrimaryButton, TextField } from 'office-ui-fabric-react';
import FileManager from '../FileManager/FileManager';
import _ from 'lodash';
import { FileTypes, IFile } from "app/services/file-manager";
import { validateUrl } from "app/shared/functions";
import { SRC_SIZES } from "app/shared/constants";
import { getFilePath } from "app/utils/service";

interface ImageFieldState {
    activeModalIndex: number;
}

// Kendisine gelen Property ismine ve değerine göre Image Field render eder.
export default class SrcSetField extends React.Component<
    BaseFieldProps,
    ImageFieldState
> {

    state = {
        activeModalIndex: -1,
    };

    getFileByIndex(index: number): string {
        return this.props.value ? (this.props.value || [])[index]?.src : ""
    }

    renderFileManager() {
        return (
            <FileManager
                show={this.state.activeModalIndex !== -1 ? true : false}
                onDismiss={() => this.setState({ activeModalIndex: -1 })}
                activeFile={this.props.value ? { fileName: this.getFileByIndex(this.state.activeModalIndex) } : {} as IFile}
                onSelectedFile={(file) => {
                    const list = _.cloneDeep(this.props.value) || [];
                    if (list) {
                        if (!list[this.state.activeModalIndex]) {
                            list[this.state.activeModalIndex] = { src: "", width: "" }
                        }
                        list[this.state.activeModalIndex]["src"] = file.fileName;
                    }
                    this.props.onChange(list)
                }}
                fileType={FileTypes.Image}
            />
        )
    }

    renderItems() {
        return (this.props.value as ImageSourceSettings[] || []).map((item, index) => {
            return (
                <div
                    key={index}
                    className="meta-form row"
                >
                    <Dropdown
                        label="Görsel Genişliği (ÖR: 1024px)"
                        className="custom-dropdown col no-padding"
                        selectedKey={item.width}
                        onChange={(event, option) => {
                            const list = _.cloneDeep(this.props.value) || [];

                            if (list) {
                                if (!list[index]) {
                                    list[index] = { width: "", src: "" }
                                }
                                list[index]["width"] = option?.key as string;
                                this.props.onChange(list)
                            }
                        }}
                        calloutProps={{ className: "custom-dropdown-callout" }}
                        options={SRC_SIZES}
                    />
                    <div className="col-md-9">
                        <Label>Görsel Adresi</Label>
                        {this.renderImage(index)}
                    </div>
                </div>
            )
        })
    }

    renderImage(index: number) {
        const image = this.props.value ? (this.props.value || [])[index]?.src : "";
        const splitted = image?.split(".");
        const imageSource = validateUrl(image) ? image : (getFilePath() + image + (!splitted || splitted[splitted?.length - 1] !== "svg" ?
            "?width=50&height=50" : ""));
        return (
            <div style={{ marginTop: "-2px" }} className="image-field source-field row">
                {image && (
                    <img className="image-string-field" src={imageSource} alt="" />
                )}
                <TextField
                    className="custom-textfield col no-padding"
                    value={image}
                    onChange={(event: any) => {
                        const list = _.cloneDeep(this.props.value) || [];
                        if (list) {
                            if (!list[index]) {
                                list[index] = { width: "", src: "" }
                            }
                            list[index]["src"] = event.target.value;
                            this.props.onChange(list)
                        }
                    }}
                />
                <PrimaryButton
                    className="custom-button"
                    text="Görsel Seç"
                    style={{ marginTop: 2.5 }}
                    onClick={() => {
                        this.setState({ activeModalIndex: index })
                    }}
                    styles={{
                        root: {
                            background: "#33926e !important",
                            borderColor: "#33926e !important"
                        },
                        rootDisabled: {
                            opacity: 0.3,
                            background: "#ececec",
                            color: "#000"
                        }
                    }}
                />
                <span
                    onClick={() => {
                        const list = _.cloneDeep(this.props.value) || [];
                        if (list) {
                            list.splice(index, 1)
                            this.props.onChange(list)
                        }
                    }}
                    className="active remove"
                >
                    <i className="icon-delete"></i>
                </span>
            </div>
        );
    }

    renderButton() {
        return (
            <DefaultButton
                text="Çözünürlük & Görsel Ekle"
                style={{ marginTop: "7.5px" }}
                onClick={() => {
                    const list = this.props.value || [];
                    this.props.onChange([...list, { src: "", width: "" }])
                }}
            />
        )
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        return (
            <div className="property-item row">
                <div title={this.props.property.name} className="property-text">
                    <div className="title">{this.props.property.text || this.props.property.name.split(/(?=[A-Z])/).map(item => item.charAt(0).toUpperCase() + item.slice(1) + " ")}</div>
                    <div className="sub-title">{this.props.property.description || this.props.property.name}</div>
                </div>
                <div style={{ marginTop: "-5px" }} className="col">
                    {this.renderItems()}
                    {this.renderButton()}
                    {this.renderFileManager()}
                </div>
            </div>
        );
    }
}
